import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ModalSelectList } from "./modalSelectList";

export const ScopeComponent = (props) => {
  const [placeHolder, setPlaceHolder] = useState("");
  useEffect(() => {
    if (props.selected) {
      if (props.selected.length > 0) {
        let concat = "";

        if (
          props.item.register === "merchant" ||
          props.item.register === "pointOfSale" ||
          props.item.register === "department" ||
          props.item.register === "products" ||
          props.item.register === "city" ||
          props.item.register === "sellers"
        ) {
          props.selected.map((item) => {
            props.setValue(`select.${props.item.register}.${item.label}`, true);
            concat += item.label + ",";
          });
        } else {
          props.selected.map((item) => {
            props.setValue(`select.${props.item.register}.${item}`, true);
            item += ",";
            concat += item;
          });
        }

        setPlaceHolder(concat);
      } else {
        setPlaceHolder("TODOS");
      }
    }
  }, [props.selected]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <p className="label__text-default"> {props.item.label}</p>
      </Grid>
      <Grid item xs={6}>
        <OutlinedInput
          value={placeHolder}
          disabled
          sx={{
            border: "1px solid #8C8D9D",
            borderRadius: "8px",
            height: "3rem !important",
          }}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <ModalSelectList
          data={props.data}
          item={props.item}
          control={props.control}
          setValue={props.setValue}
          getValues={props.getValues}
          setSelected={props.setSelected}
          selected={props.selected}
          loading={props.loading}
        />
      </Grid>
    </Grid>
  );
};

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import { MasterSelectorTraders } from "./masterSelector";
import { DownloadMastersTraders } from "./downloadMasters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useTranslation } from "react-i18next";
import { UploadMastersTraders } from "./uploadMasters";
import { useState } from "react";

const UnifiedMastersTraders = () => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * use State
   */

  const [currentMaster, setCurrentMaster] = useState("2");

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item className="display-large__primary-one">
        {t("App.traders.masters.title")}
      </Grid>
      <Grid item className="full-width">
        <MasterSelectorTraders
          setCurrentMaster={setCurrentMaster}
          currentMaster={currentMaster}
        />
      </Grid>
      {currentMaster !== "2" && (
        <Grid item className="full-width">
          <Accordion className="accordion__upload">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={2}
              >
                <Grid item>
                  <FileDownloadOutlinedIcon className="accordion__upload__downloadIcon" />
                </Grid>
                <Grid item md={9} sm={9} xs={9}>
                  <p className="display-small__primary-one">
                    {t("App.masters.download")}
                  </p>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className="accordion__upload__detail">
              <DownloadMastersTraders currentMaster={currentMaster} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {currentMaster !== "2" && (
        <Grid item className="full-width">
          <Accordion className="accordion__upload">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={2}
              >
                <Grid item>
                  <FileUploadOutlinedIcon className="accordion__upload__uploadIcon" />
                </Grid>
                <Grid item md={9} sm={9} xs={9}>
                  <p className="display-small__primary-one">
                    {t("App.masters.upload")}
                  </p>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className="accordion__upload__detail">
              <UploadMastersTraders currentMaster={currentMaster} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
};

export default UnifiedMastersTraders;

import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UploadRateMaster from "./uploadRateMaster";
import DownloadRateMaster from "./downloadRateMaster";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import {
  fetchBusinessGroup,
  getIsBusinessGroupStatus,
} from "../../../parts/businessGroup/businessGroupSlice";
import { useEffect } from "react";

const RateMaster = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const businessGroupStatus = useSelector(getIsBusinessGroupStatus);
  const userInfo = useSelector(getUser);

  /**
   *  Obtener si la cuenta del usuario pertenece a
   * un grupo empresarial
   */
  useEffect(() => {
    try {
      switch (businessGroupStatus) {
        case "fetch":
          if (userInfo) {
            dispatch(
              fetchBusinessGroup({
                country: userInfo.company.country,
                eanProvider: userInfo.company.eanCompany,
                email: userInfo.email,
                typeOption: 1,
              })
            );
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(
        "==============Error rateMaster.jsx fetchBusinessGroup ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, [businessGroupStatus, dispatch, userInfo]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
      style={{ width: "100%" }}
    >
      <Grid item>
        <p className="display-large__primary-one">
          {t("App.rateMaster.title")}
        </p>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Accordion className="accordion__upload" key="download">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid container direction="row">
              <Grid item>
                <FileDownloadOutlinedIcon className="accordion__upload__downloadIcon" />
              </Grid>
              <Grid item className="col-flex">
                <p
                  className="display-small__primary-one"
                  style={{ paddingLeft: "1rem" }}
                >
                  {t("App.rateMaster.download")}
                </p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordion__upload__detail">
            <DownloadRateMaster />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item style={{ width: "100%", marginBottom: "3%" }}>
        <Accordion className="accordion__upload" key="upload">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid container direction="row">
              <Grid item>
                <FileUploadOutlinedIcon className="accordion__upload__uploadIcon" />
              </Grid>
              <Grid item className="col-flex">
                <p
                  className="display-small__primary-one"
                  style={{ paddingLeft: "1rem" }}
                >
                  {t("App.rateMaster.upload")}
                </p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordion__upload__detail">
            <UploadRateMaster />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default RateMaster;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import { CODES } from "../../../consts/codes";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Button,
  DialogActions,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DetailTraceability } from "../../../services/user/tracebility/traceabilityService";

const ModalSeeTraceability = ({
  currentFile,
  userInfo,
  open,
  setOpen,
  setOpenErrorModal,
}) => {
  const [hasFoundData, setHasFoundData] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [textReportType, setTextReportType] = useState("");
  const [textDocType, setTextDocType] = useState("");

  const { t } = useTranslation();

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "month",
      label: t("App.traceabilityExternal.window.table.month"),
      options: {
        filter: false,
        hint: t("App.traceabilityExternal.window.table.toolTip.month"),
      },
    },
    {
      name: "quantity",
      label: t("App.traceabilityExternal.window.table.cant"),
      options: {
        filter: false,
        hint: t("App.traceabilityExternal.window.table.toolTip.cant"),
      },
    },
    {
      name: "totalValue",
      label: t("App.traceabilityExternal.window.table.totalVal"),
      options: {
        filter: false,
        hint: t("App.traceabilityExternal.window.table.toolTip.totalVal"),
      },
    },
  ];

  //Columnnas cuando el reporte es sobre ventas e inventarios
  const columnsSalesStock = [
    {
      name: "month",
      label: t("App.traceabilityExternal.window.table.month"),
      options: {
        filter: false,
        hint: t("App.traceabilityExternal.window.table.toolTip.month"),
      },
    },
    {
      name: "type",
      label: t("App.traceabilityExternal.window.table.typeDoc"),
      options: {
        filter: false,
      },
    },
    {
      name: "quantity",
      label: t("App.traceabilityExternal.window.table.cant"),
      options: {
        filter: false,
        hint: t("App.traceabilityExternal.window.table.toolTip.cant"),
      },
    },
    {
      name: "totalValue",
      label: t("App.traceabilityExternal.window.table.totalVal"),
      options: {
        filter: false,
        hint: t("App.traceabilityExternal.window.table.toolTip.totalVal"),
      },
    },
  ];

  /**
   * Mostrar un skeleton mientras se obtienen los datos
   */
  const skeletonLine = [
    {
      month: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      type: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      quantity: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      totalValue: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: false,
    searchPlaceholder: t("App.requestsPurchasecurrentFile.searchText"),
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Obtener los datos de la trazabilidad
   */
  useEffect(() => {
    if (open) fetchTraceabilities();
  }, [open]);

  /**
   * Invocar el cambio de texto para el tipo de documento
   */
  useEffect(() => {
    changeDocumentTypeTexts();
  }, [currentFile]);
  /**
   * Función para obtener los productos de la orden de compra
   */
  const fetchTraceabilities = () => {
    if (userInfo) {
      getDetailTracebility();
    }
  };

  /**
   * Función para obtener los productos de la orden de compra
   */
  const getDetailTracebility = async () => {
    try {
      setIsLoading(true);

      const obj = {
        pk: currentFile.pk,
        sk: currentFile.sk,
      };
      const {
        status,
        data: { responseMessage, responseCode },
      } = await DetailTraceability(obj);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          setHasFoundData(true);
          setData(setDataStructure(responseMessage));
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      setOpen(false);
      console.log("---- ERROR getTracebilities ----");
      console.log(error);
    }
    setIsLoading(false);
  };

  /**
   * Función para ajustar la estructura de los datos
   * @param {*} responseMessage datos de la trazabilidad
   */
  const setDataStructure = (responseMessage) => {
    const dataMessage = [];
    responseMessage.map((traceability) => {
      switch (currentFile?.documentType) {
        case "sales_stock":
          // Información del mes para ventas
          dataMessage.push({
            month: traceability?.month ?? "",
            type: t("App.traceabilityExternal.typeDoc.sales"),
            quantity: traceability?.totalSalesQuantity ?? "",
            totalValue: traceability?.totalSalesUnitPrice ?? "",
          });
          // Información del mes para inventarios

          dataMessage.push({
            month: traceability?.month ?? "",
            type: t("App.traceabilityExternal.typeDoc.inventories"),
            quantity: traceability?.totalStockQuantity ?? "",
            totalValue: traceability?.totalStockUnitPrice ?? "",
          });
          break;
        case "sales":
          dataMessage.push({
            month: traceability?.month ?? "",
            quantity: traceability?.totalSalesQuantity ?? "0",
            totalValue: traceability?.totalSalesUnitPrice ?? "0",
          });
          break;
        default:
          dataMessage.push({
            month: traceability?.month ?? "",
            quantity: traceability?.totalStockQuantity ?? "0",
            totalValue: traceability?.totalStockUnitPrice ?? "0",
          });
          break;
      }
    });
    return dataMessage;
  };

  /**
   * Función para ajustar el texto para el tipo de documento del reporte
   * y el tipo de documento del registro seleccionado
   */
  const changeDocumentTypeTexts = () => {
    let textReportType = "";
    let textDocType = "";
    switch (currentFile?.reportType) {
      case "sales":
        textReportType = t(
          "App.traceabilityExternal.window.documentType.sales"
        );
        break;
      case "stock":
        textReportType = t(
          "App.traceabilityExternal.window.documentType.stock"
        );
        break;
      default:
        textReportType = t(
          "App.traceabilityExternal.window.documentType.salesStock"
        );
        break;
    }
    switch (currentFile?.documentType) {
      case "sales_stock":
        textDocType = t("App.traceabilityExternal.typeDoc.salesStock");
        break;
      case "sales":
        textDocType = t("App.traceabilityExternal.typeDoc.sales");
        break;
      default:
        textDocType = t("App.traceabilityExternal.typeDoc.inventories");
        break;
    }
    setTextReportType(textReportType);

    setTextDocType(textDocType);
  };

  /**
   * Función para cerrar el modal
   */
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      aria-labelledby="dialog-title"
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(85,115,185,0.2)",
        },
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            boxShadow: "none",
            width: "80%",
            maxWidth: "900px",
            bcurrentFileRadius: "22px",
            padding: "2rem",
          },
        },
      }}
    >
      <Grid container gap={2}>
        <Grid item justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid container item direction="column">
          <h1 className="display-large__primary-one">
            {t("App.traceabilityExternal.window.title", {
              nameReport: textReportType,
              snrf: currentFile?.traceabilityId,
            })}{" "}
            <Tooltip
              title={t("App.traceabilityExternal.window.table.toolTip.id")}
            >
              <InfoOutlinedIcon sx={{ fontSize: "22px", color: "#543AB4" }} />{" "}
            </Tooltip>
          </h1>
          <br />
          {currentFile?.documentType !== "sales_stock" && (
          <>
          <label>
            {t("App.traceabilityExternal.window.subtitle")}{" "}
            <span className="state-colors__primary-color-one">
              {currentFile?.trader}
            </span>
          </label>
          <br />
            </>
          )}
          <label>
            {t("App.traceabilityExternal.window.typeDoc", {
              typeDoc: textDocType,
            })}
          </label>
        </Grid>
      </Grid>

      <DialogContent className="scroll-table full-width">
        <Grid container direction="column">
          <Grid item className="full-width">
            {hasFoundData ? (
              <MUIDataTable
                className="dataTable__salesForce full-width"
                data={isLoading ? skeletonLine : data}
                columns={
                  currentFile?.documentType === "sales_stock"
                    ? columnsSalesStock
                    : columns
                }
                options={options}
              />
            ) : (
              <Grid item sx={{ width: "100%" }}>
                <NoMatchImage />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          gap={1}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={12} sm={12} md={2} lg={1}>
            <Button
              className={"btn__applyFilter full-width"}
              onClick={handleCloseModal}
            >
              {t("App.buttonLabels.return")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default ModalSeeTraceability;

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useDispatch } from "react-redux";
import { setCurrentAction } from "../../../parts/deleteAndReload/deleteAndReloadSlice";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "12px",
  bgcolor: "white",
  padding: "1rem !important",

  p: 4,
};

export const ModalChangeAction = (props) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleAccept = () => {
    props.setOpen(false);
    dispatch(setCurrentAction({ newAction: props.desiredType }));
  };
  const { t } = useTranslation();

  return (
    <div>
      <Modal open={props.open} onClose={handleClose}>
        <Box sx={style} className="modal__responsive">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton onClick={handleClose} component="span">
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <ErrorOutlineOutlinedIcon
                className="state-colors__secondary-color-two"
                sx={{ fontSize: "4rem" }}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent="flex-start"
              className="heading-secondary__md"
            >
              <p> {t("App.deleteAndReload.modalTitle")}</p>
            </Grid>

            <Grid item style={{ marginBottom: "1rem" }}>
              <p className="default-text_black">
                {t("App.deleteAndReload.modalText")}
              </p>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ marginTop: "0.5%" }}
            >
              <Grid item>
                <Button
                  type="button"
                  disableRipple
                  className="btn__filled__gradient"
                  onClick={handleClose}
                >
                  {t("App.buttonLabels.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleAccept}
                  disableRipple
                  className="btn__filled__blue"
                >
                  {t("App.buttonLabels.accept")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Utilizar el servicio para obtener el listado de números de OC
 * @param {*} data
 * @returns
 */
export async function GetOCNumbers(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/numbers`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *  Utilizar el servicio para Obtener el listado de OC disponibles para tener excepción
 * @param {*} data
 * @returns
 */
export async function GetListOrders(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/administrations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para Obtener el listado de productos relacionados a la OC
 * disponibles para excepción
 * @param {*} data
 * @returns
 */
export async function GetProductsOC(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/products`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para utilizar el servicio para Rechazar solicitud Excepcion OC
 * @param {*} data
 * @returns
 */
export async function RequestExceptionOC(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para Obtener un listado de las ordenes de compra para el comercio
 * @param {*} data
 * @returns
 */
export async function GetRequestsOC(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests/providers`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener los productos relacionados
 * con la OC
 * @param {*} data
 * @returns
 */
export async function GetProductsRequestOC(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests/products`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

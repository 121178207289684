import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoopIcon from "@mui/icons-material/Loop";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { FilterSalesForce } from "../../../../components/common/filterSalesForce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import UploadFile from "../../../../components/common/UploadFile/uploadFile";
import { CODES } from "../../../../consts/codes";
import ModalInfo from "../../../../components/common/Modals/modalInfo";
import DownloadGuide from "../../../../components/common/UploadFile/downloadGuide";
import DownloadTemplate from "../../../../components/common/UploadFile/downloadTemplate";
import { useNavigate } from "react-router-dom";
import {
  fetchAllLoadGeographies,
  getGeographiesLoad,
  getStatusGeographiesLoad,
  resetGeographiesLoadState,
} from "../../../../parts/geographies/geographiesSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import {
  DownloadGeographyGuide,
  DownloadGeographyLogFile,
  DownloadGeographyTemplate,
  UploadGeography,
} from "../../../../services/admin/geographies/geographiesService";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { fetchUser, getUser, getUserStatus } from "../../../userSlice";
import NoMatchImage from "../../../../components/common/NoMatchImage/noMatchImage";
import { NoInfoComponent } from "../../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../../components/common/fileState";
import { DownloadSalesForceFile } from "../../../../services/user/salesForceServices";
import { DownloadTableFile } from "../../../../services/user/fileServices";

const CreateGeography = () => {
  /**
   * Use Translation
   * */
  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Table
   * */

  const columns = [
    {
      name: "startDate",
      label: t("App.catalogue.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.catalogue.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.catalogue.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.catalogue.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value.fileName}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  handleDownloadGeographyFile(
                    value.fileName,
                    value.pathFileName
                  );
                }}
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "errors",
      label: t("App.catalogue.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              value.awsUploadLog ? (
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) => setOpenErrorModal(true)}
                >
                  ERROR
                </a>
              ) : (
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadGeographyLogFile(
                      value.pathLogFileName,
                      value.logFileName
                    )
                  }
                >
                  {value.logFileName}
                </a>
              )
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonLine = [
    {
      id: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      startDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      endDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      user: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      file: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      state: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      errors: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  const geographiesLoadStatus = useSelector(getStatusGeographiesLoad);
  const geographiesLoadList = useSelector(getGeographiesLoad);

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * UseState
   */

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterApply, setFilterApply] = useState(null);

  const [fileSelected, setFileSelected] = useState(null);

  const [responseLoadGeography, setResponseLoadGeography] = useState({});
  const [isOpenSuccessLoadModal, setIsOpenSuccessLoadModal] = useState(false);
  const [responseTitle, setResponseTitle] = useState(false);

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [noGeographies, setNoGeographies] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const fetchGeographiesLoad = async () => {
      try {
        if (userInfo) {
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
          };
          if (geographiesLoadStatus === "fetch") {
            dispatch(fetchAllLoadGeographies(obj));
          }

          let processedRows = geographiesLoadList.map((geographyLoad, i) => {
            return {
              startDate: !geographyLoad.starDate
                ? "  "
                : moment(geographyLoad.starDate).format("YYYY-MM-DD hh:mm"),
              endDate: !geographyLoad.endDate
                ? "  "
                : moment(geographyLoad.endDate).format("YYYY-MM-DD hh:mm"),
              user: !geographyLoad.userLoad ? "" : geographyLoad.userLoad,
              file: !geographyLoad.fileName
                ? " "
                : {
                    fileName: geographyLoad.fileName,
                    pathFileName: geographyLoad.pathFileName,
                  },
              state: !geographyLoad.state ? " " : geographyLoad.state,
              errors: geographyLoad.awsUploadLog
                ? {
                    awsUploadLog: geographyLoad.awsUploadLog,
                  }
                : {
                    logFileName: geographyLoad.logFileName,
                    pathLogFileName: geographyLoad.pathLogFileName,
                  },
            };
          });

          if (geographiesLoadStatus === "succeeded") {
            setNoGeographies(processedRows.length > 0 ? false : true);
          }

          if (geographiesLoadStatus !== "loading") {
            setData(processedRows);
            setUnfilteredData(processedRows);
            setIsLoadingData(false);
          }
        }
      } catch (error) {
        console.log(
          "==============Error Get Geographies Load======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };
    setIsLoadingData(true);
    fetchGeographiesLoad();
  }, [dispatch, geographiesLoadStatus, userInfoStatus]);

  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  /**
   * Handles
   */

  //Refrescar tabla
  const updateGeographiesTable = () => {
    setNoGeographies(false);
    setIsLoadingData(true);
    setData([]);
    dispatch(resetGeographiesLoadState());
  };

  //Documentos
  const handleUploadFile = async () => {
    try {
      const obj = {
        file: fileSelected[0],
        companies: null,
        checkReplicateBusinessGroup: null,
      };

      const uploadGeographyRequest = await UploadGeography(obj);

      setFileSelected(null);
      const titleModal =
        uploadGeographyRequest.data.responseCode === 200 ? `` : "Error Sistema";
      dispatch(resetGeographiesLoadState());
      setResponseTitle(titleModal);
      setResponseLoadGeography(uploadGeographyRequest);
      setIsOpenSuccessLoadModal(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDownloadGuide = async () => {
    try {
      const downloadGeographyGuideRequest = await DownloadGeographyGuide();

      if (downloadGeographyGuideRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          downloadGeographyGuideRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          downloadGeographyGuideRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          fileDownload(
            decode(downloadGeographyGuideRequest.data.data.fileContent),
            downloadGeographyGuideRequest.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const downloadGeographyTemplateRequest =
        await DownloadGeographyTemplate();

      if (
        downloadGeographyTemplateRequest.status === CODES.COD_RESPONSE_HTTP_OK
      ) {
        if (
          downloadGeographyTemplateRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          downloadGeographyTemplateRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          fileDownload(
            decode(downloadGeographyTemplateRequest.data.data.fileContent),
            downloadGeographyTemplateRequest.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDownloadGeographyFile = async (fileName, pathFileName) => {
    const obj = {
      pathFileName,
      fileName,
    };

    try {
      const downloadGeographyRequest = await DownloadTableFile(obj);

      if (downloadGeographyRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          downloadGeographyRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          downloadGeographyRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          fileDownload(
            decode(downloadGeographyRequest.data.data.fileContent),
            downloadGeographyRequest.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDownloadGeographyLogFile = async (pathFileName, fileName) => {
    const obj = {
      pathLogFileName: pathFileName,
      logFileName: fileName,
    };

    try {
      const downloadGeographyLogRequest = await DownloadGeographyLogFile(obj);

      if (downloadGeographyLogRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          downloadGeographyLogRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          downloadGeographyLogRequest.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          fileDownload(
            decode(downloadGeographyLogRequest.data.data.fileContent),
            downloadGeographyLogRequest.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  //Regreso a geografias
  const handleBackGeographies = () => {
    navigate(-1);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <p className="display-large__moderate-blue">
          {t("App.geographies.load.title")}
        </p>
      </Grid>
      <Grid item className="back-container">
        <Grid container direction="row">
          <Grid item>
            <IconButton disableRipple onClick={handleBackGeographies}>
              <ArrowBackIosNewIcon className="display-large__moderate-blue" />
            </IconButton>
          </Grid>
          <Grid item className="col-start">
            <p className="display-small__moderate-blue">
              {" "}
              {t("App.geographies.load.back")}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className="u-marginB"
        spacing={2}
      >
        <DownloadTemplate
          helpText={t("App.geographies.load.templateHelpText")}
          buttonText={t("App.geographies.load.templateButton")}
          handleDownloadTemplate={handleDownloadTemplate}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          className="grid__container"
          style={{ padding: "1.5rem" }}
          direction="column"
        >
          <Grid item className="col-end">
            <DownloadGuide
              helpText={"App.salesForce.guide"}
              handleDownloadGuide={handleDownloadGuide}
            />
          </Grid>
          <Grid item>
            <UploadFile
              dragText={t("App.geographies.load.uploadHelpText")}
              handleUploadFile={handleUploadFile}
              fileSelected={fileSelected}
              setFileSelected={setFileSelected}
              setErrorModal={setIsOpenErrorModal}
              setErrorTitle={setErrorTitle}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: "1.5%" }}>
        <Grid container className="load-container" direction="row">
          <Grid item>
            <Button
              disableRipple
              startIcon={<LoopIcon />}
              className="btn__outlined__blue"
              onClick={updateGeographiesTable}
            >
              {t("App.geographies.load.update")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: "1%" }}>
        <p className="display-small__moderate-blue">
          {t("App.geographies.load.tableTitle")}
        </p>
      </Grid>
      <Grid item style={{ marginTop: "2%" }}>
        <Accordion style={{ width: "100%" }} className="accordion__filter">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />
            <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
              {" "}
              {t("App.catalogue.filterName")}
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <FilterSalesForce
              userInfo={userInfo}
              unfilteredData={unfilteredData}
              setData={setData}
              setFilterApply={setFilterApply}
              idFile={4}
              setIsLoadingData={setIsLoadingData}
              setFilterNotFound={setFilterNotFound}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      {!noGeographies ? (
        <Grid item style={{ marginTop: "2%", marginBottom: "2%" }}>
          {isLoadingData ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce"
            />
          ) : !filterNotFound ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={options}
            />
          ) : (
            <NoMatchImage />
          )}
        </Grid>
      ) : (
        <Grid container className="col-flex">
          <NoInfoComponent type="load" />
        </Grid>
      )}

      <ModalInfo
        title={responseTitle}
        responseData={responseLoadGeography}
        open={isOpenSuccessLoadModal}
        onClose={() => {
          setIsOpenSuccessLoadModal(false);
        }}
      />

      <ModalInfo
        title={t("App.states.systemError")}
        responseData={{
          data: {
            responseCode: 0,
            responseMessage: t("App.validationMessages.systemError"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />

      <ModalInfo
        title={errorTitle}
        responseData={{ status: 0 }}
        open={isOpenErrorModal}
        onClose={() => {
          setIsOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default CreateGeography;

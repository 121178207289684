import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";
import { GetGeographiesList } from "../../services/admin/geographies/geographiesService";
import {
  GetCompaniesByCountry,
  ListBusinessGroup,
} from "../../services/user/businessGroup";

export const businessGroupSlice = createSlice({
  name: "businessGroup",
  initialState: {
    isBusinessGroup: false,
    lstCompanies: [],
    businessGroupStatus: "fetch",
    countriesAndCompanies: [],
    countriesAndCompaniesStatus: "fetch",
    error: null,
  },
  reducers: {
    resetCountriesAndCompaniesState(state, action) {
      state.countriesAndCompaniesStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCountriesAndCompanies.pending, (state, action) => {
        state.countriesAndCompaniesStatus = "loading";
      })
      .addCase(fetchCountriesAndCompanies.fulfilled, (state, action) => {
        state.countriesAndCompaniesStatus = "succeeded";
        state.countriesAndCompanies = action.payload;
      })
      .addCase(fetchCountriesAndCompanies.rejected, (state, action) => {
        state.countriesAndCompaniesStatus = "failed";
        state.countriesAndCompanies = [];
      })
      .addCase(fetchBusinessGroup.pending, (state, action) => {
        state.businessGroupStatus = "loading";
      })
      .addCase(fetchBusinessGroup.fulfilled, (state, action) => {
        const { isBusinessGroup, lstCompanies } = action.payload;

        state.businessGroupStatus = "succeeded";
        state.isBusinessGroup = isBusinessGroup;
        state.lstCompanies = lstCompanies;
      })
      .addCase(fetchBusinessGroup.rejected, (state, action) => {
        state.businessGroupStatus = "failed";
        state.isBusinessGroup = false;
        state.lstCompanies = [];
      });
  },
});

export const getIsBusinessGroup = (state) =>
  state.businessGroup.isBusinessGroup;

export const getLstCompanies = (state) => state.businessGroup.lstCompanies;

export const getIsBusinessGroupStatus = (state) =>
  state.businessGroup.businessGroupStatus;

export const getCountriesAndCompanies = (state) =>
  state.businessGroup.countriesAndCompanies;

export const getCountriesAndCompaniesStatus = (state) =>
  state.businessGroup.countriesAndCompaniesStatus;

export const { resetCountriesAndCompaniesState } = businessGroupSlice.actions;

export const fetchBusinessGroup = createAsyncThunk(
  "businessGroup/fetchBusinessGroup",
  async ({ country, eanProvider, email, typeOption }) => {
    const listService = await ListBusinessGroup({
      country: country,
      eanProvider: eanProvider,
      email: email,
      typeOption: typeOption,
    });

    const responseCode = Number(listService.data.responseCode);

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return {
          isBusinessGroup: true,
          lstCompanies: listService.data.objectData,
        };

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return { isBusinessGroup: false, lstCompanies: [] };

      default:
        return { isBusinessGroup: false, lstCompanies: [] };
    }
  }
);

export const fetchCountriesAndCompanies = createAsyncThunk(
  "businessGroup/fetchCountriesAndCompanies",
  async () => {
    const requestCountries = await GetGeographiesList();

    const responseCodeCountries = requestCountries.data.responseCode;

    switch (responseCodeCountries) {
      case CODES.COD_RESPONSE_SUCCESS:
        const countries = requestCountries.data.responseMessage;

        let countriesAndCompanies = [];

        for (const country of countries) {
          let lstCompanies = [];

          const requestCompaniesByCountry = await GetCompaniesByCountry({
            country: country.country,
            lstEansProviders: null,
          });

          const responseCodeCompany =
            requestCompaniesByCountry.data.responseCode;

          if (responseCodeCompany === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
            let companies = requestCompaniesByCountry.data.responseMessage;
            lstCompanies = companies;
          }

          countriesAndCompanies.push({
            ...country,
            lstCompanies: lstCompanies,
          });
        }

        return countriesAndCompanies;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];
    }
  }
);

export default businessGroupSlice.reducer;

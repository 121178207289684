import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../pages/userSlice";
import geographiesSlice from "../parts/geographies/geographiesSlice";
import salesForcesSlice from "../parts/saleForces/salesForcesSlice";
import catalogueSlice from "../parts/catalogue/catalogueSlice";
import storesSlice from "../parts/stores/storesSlice";
import mastersSlice from "../parts/masters/mastersSlice";
import dynamicReportSlice from "../parts/dynamicReport/dynamicReportSlice";
import listUsersSlice from "../parts/listUsers/listUsersSlice";
import deleteAndReloadSlice from "../parts/deleteAndReload/deleteAndReloadSlice";
import businessGroupSlice from "../parts/businessGroup/businessGroupSlice";
import rateSlice from "../parts/rate/rateSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    geographies: geographiesSlice,
    salesForce: salesForcesSlice,
    catalogue: catalogueSlice,
    stores: storesSlice,
    masters: mastersSlice,
    dynamicReport: dynamicReportSlice,
    listUsers: listUsersSlice,
    deleteAndReload: deleteAndReloadSlice,
    businessGroup: businessGroupSlice,
    rate: rateSlice,
  },
});

import { Grid } from "@mui/material";
import { ChannelsAccordion } from "../../components/common/HelpCenter/ChannelsAccordion";
import { QuestionsAccordion } from "../../components/common/HelpCenter/QuestionsAccordion";
import { TutorialsAccordion } from "../../components/common/HelpCenter/TutorialsAccordion";
import { UserManualAccordion } from "../../components/common/HelpCenter/UserManualAccordion";
import { useEffect, useState } from "react";
import ChannelsInformation from "../../data/help-info/channels-info.json";
import TutorialsInformation from "../../data/help-info/tutorials-info.json";
import QuestionsInfo from "../../data/help-info/questions-info";
import { useTranslation } from "react-i18next";
import ModalInfo from "../../components/common/Modals/modalInfo";

const HelpCenter = () => {
  /**
   * Use Translation
   */
  const { i18n, t } = useTranslation();

  /**
   * Responsive
   */

  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  /**
   * Actualiza el tamaño de pantalla
   * @returns
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect to update the state of isDesktop based on the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * use State
   */

  //Información de ayuda
  const [channelsInfo, setChannelsInfo] = useState([]);
  const [tutorialsInfo, setTutorialsInfo] = useState([]);
  const [questionsInfo, setQuestionsInfo] = useState([]);

  //Modal info

  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [modalInfoContent, setModalInfoContent] = useState({ data: {} });

  /**
   * UseEffect
   */

  //Settea la información de ayuda en sus componentes correspondientes
  useEffect(() => {
    handleSetHelpInformation();
  }, []);

  /**
   * Handles
   */

  /**
   * Función que settea la información de los json en los estados correspondientes de información
   */
  const handleSetHelpInformation = () => {
    //Determinamos el idioma primero
    const currentLanguage = i18n.language.includes("es") ? "es" : "en";
    const questionsComponent = QuestionsInfo();

    //Setteamos la información de los json
    setChannelsInfo(ChannelsInformation[currentLanguage]);
    setQuestionsInfo(questionsComponent[currentLanguage]);
    setTutorialsInfo(TutorialsInformation[currentLanguage]);
  };

  return (
    <Grid container direction="column" rowSpacing={3} pt={1.5} pb={5}>
      <Grid item>
        <Grid
          container
          direction="row"
          className="grid__container__filled__rounded col-flex"
          justifyContent={{
            xl: "center !important",
            lg: "space-evenly !important",
          }}
          p={0}
          columnSpacing={{ xl: 1, lg: 1 }}
        >
          <Grid
            item
            xl={3}
            lg={4.9}
            sm={12}
            xs={12}
            order={{ lg: 1, md: 1, sm: 2, xs: 2 }}
          >
            <Grid container className="col-flex" rowSpacing={2}>
              <Grid item className="display-x-large__primary-one">
                {t("App.helpCenter.helpTitle")}
              </Grid>
              <Grid
                item
                sx={{
                  textAlign: !isDesktop ? "center" : "",
                  padding: !isDesktop ? "10%" : "",
                }}
                lineHeight={1.5}
                className="display-medium__text-grey"
              >
                {t("App.helpCenter.helpDesc")}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={3}
            lg={4}
            sm={1}
            xs={1}
            order={{ xl: 2, lg: 4, sm: 1, xs: 1 }}
            className="col-flex"
          >
            {isDesktop ? (
              <img src="/assets/helpResources/help_ilustration.svg" />
            ) : (
              <img
                style={{ width: "230px" }}
                src="/assets/helpResources/help_ilustration.svg"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ChannelsAccordion channelsInfo={channelsInfo} isDesktop={isDesktop} />
      </Grid>
      <Grid item>
        <QuestionsAccordion
          t={t}
          isDesktop={isDesktop}
          questionsInfo={questionsInfo}
        />
      </Grid>
      <Grid item>
        <TutorialsAccordion t={t} tutorialsInfo={tutorialsInfo} />
      </Grid>
      <Grid item>
        <UserManualAccordion
          t={t}
          isDesktop={isDesktop}
          setIsOpenModalInfo={setIsOpenModalInfo}
          setModalInfoContent={setModalInfoContent}
        />
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={modalInfoContent}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
      />
    </Grid>
  );
};

export default HelpCenter;

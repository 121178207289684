import { React, useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { CODES } from "../../consts/codes";
import { GetMenuOptions } from "../../services/admin/menuOptionsServices";
import ModalCreateOptions from "../../components/common/modalCreateOptions";
import ModalEditOptions from "../../components/common/modalEditOptions";
import ModalPreviewMenu from "../../components/common/modalPreviewMenu";
import FormControlLabel from "@mui/material/FormControlLabel";
import ModalInfo from "../../components/common/Modals/modalInfo";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import { getUser } from "../userSlice";
import { useSelector } from "react-redux";

export const ListMenuOptions = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalInfoTitle, setModalInfoTitle] = useState("");

  const [createdOption, setCreatedOption] = useState(false);

  const { t } = useTranslation();

  const [isOpenCreateMenu, setIsOpenCreateMenu] = useState(false);
  const [createMenuResponseData, setCreateMenuResponseData] = useState({});

  const userInfo = useSelector(getUser);
  const userName = userInfo.firstName + " " + userInfo.lastName;

  const columns = [
    {
      name: "name",
      label: t("App.listMenuOptions.table.name"),
      options: {
        filter: false,
        sort: true,
        search: true,
      },
    },
    {
      name: "description",
      label: t("App.listMenuOptions.table.description"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "product",
      label: t("App.listMenuOptions.table.product"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "url",
      label: t("App.listMenuOptions.table.url"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "level",
      label: t("App.listMenuOptions.table.level"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "order",
      label: t("App.listMenuOptions.table.order"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "parent",
      label: t("App.listMenuOptions.table.parent"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "status",
      label: t("App.listMenuOptions.table.status"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <p
                className={
                  value === "Activo"
                    ? "dataTable__active"
                    : "dataTable__inactive"
                }
              >
                {value}
              </p>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "edit",
      label: t("App.listMenuOptions.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: "Buscar por nombre de la opción o opción padre",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted) {
          const usersService = await GetMenuOptions();
          if (usersService.status === CODES.COD_RESPONSE_HTTP_OK) {
            setLoading(false);
            if (
              usersService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const responseMessage = usersService.data.responseMessage;

              const ProcessRows = responseMessage.map((item, idx) => {
                return {
                  name: !item.menuOptionName ? " - " : item.menuOptionName,
                  description: !item.description ? " - " : item.description,
                  level: !item.level ? " - " : item.level,
                  product:
                    item.productType === 0
                      ? "No"
                      : item.productType === 1
                      ? t("App.buttonLabels.yes")
                      : "-",
                  order: !item.orderMenu ? " - " : item.orderMenu,
                  url: !item.url ? " - " : item.url,
                  parent: !item.parentMenuOptionName
                    ? " - "
                    : item.parentMenuOptionName,
                  status: item.state ? "Activo" : "Inactivo",

                  edit: (
                    <IconButton component="span">
                      <ModalEditOptions
                        menuOption={item}
                        setCreatedOption={setCreatedOption}
                        createdOption={createdOption}
                        setResponseData={setCreateMenuResponseData}
                        openModal={setIsOpenCreateMenu}
                        userName={userName}
                        setModalInfoTitle={setModalInfoTitle}
                      />
                    </IconButton>
                  ),
                };
              });

              setData(ProcessRows);
            }
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [createdOption]);

  const loader = () => {
    const options = {
      selectableRows: "none",
      responsive: "simple",
      print: false,
      filter: false,
      download: false,
      search: false,
      elevation: 0,
      viewColumns: false,

      rowsPerPageOptions: [10, 15, 20],
      textLabels: {
        body: {
          noMatch: <Skeleton />,
          toolTip: "Sort",

          columnHeaderTooltip: (column) =>
            t("App.buttonLabels.order", { label: column.label }),
        },
        pagination: {
          next: t("App.userDetail.next"),
          previous: t("App.userDetail.previous"),
          rowsPerPage: t("App.userDetail.resultsPerPage"),
          displayRows: t("App.userDetail.of"),
        },
      },
    };

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={2}
        style={{ padding: "1rem" }}
      >
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.listMenuOptions.title")}
          </p>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={9}>
              <p className="default-text__blue">
                {t("App.listMenuOptions.available")}
                {!data ? " - " : data.length}
              </p>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid className="modalPreviewMenu-principalBox">
                  <ModalPreviewMenu />
                  <ModalCreateOptions
                    createdOption={createdOption}
                    setCreatedOption={setCreatedOption}
                    userName={userName}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
    );
  };
  if (loading) {
    return loader();
  } else {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={2}
        style={{ padding: "1rem" }}
      >
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.listMenuOptions.title")}
          </p>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={9}>
              <p className="default-text__blue">
                {t("App.listMenuOptions.available")}
                {!data ? " - " : data.length}
              </p>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid className="modalPreviewMenu-principalBox">
                  <ModalPreviewMenu />
                  <ModalCreateOptions
                    createdOption={createdOption}
                    setCreatedOption={setCreatedOption}
                    setResponseData={setCreateMenuResponseData}
                    openModal={setIsOpenCreateMenu}
                    userName={userName}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <MUIDataTable
            className="dataTable__base"
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>

        <ModalInfo
          title={modalInfoTitle}
          responseData={createMenuResponseData}
          open={isOpenCreateMenu}
          onClose={() => {
            setIsOpenCreateMenu(false);
            setModalInfoTitle("");
          }}
        />
      </Grid>
    );
  }
};

import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import { EditCompany as Edit } from "../../../services/user/adminCompany";
import {
  GetCitiesPerCountryList,
  GetGeographiesList,
} from "../../../services/admin/geographies/geographiesService";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../Modals/modalInfo";
import { getCompaniesAdmin } from "../../../services/admin/adminServices";
import { REGEXP } from "../../../consts/regexp";

const EditCompany = (props) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [openEditCompany, setOpenEditCompany] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [editCompany, setEditCompany] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");

  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  const { t } = useTranslation();

  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const handleOpen = () => {
    async function handleCountries() {
      try {
        const countriesService = await GetGeographiesList();

        if (
          countriesService.status === CODES.COD_RESPONSE_HTTP_OK &&
          countriesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const countries = countriesService.data.responseMessage.map(
            (country) => {
              const countryCapitalized =
                country.countryName[0] +
                country.countryName.slice(1).toLowerCase();
              return {
                value: country.country,
                label: countryCapitalized,
              };
            }
          );

          setCountriesList(countries);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    handleCountries();
    setOpenEditCompany(true);
  };

  const handleCities = async (country) => {
    try {
      const citiesService = await GetCitiesPerCountryList(country);

      if (
        citiesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        citiesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const cities = citiesService.data.responseMessage.map((city) => {
          const cityCapitalized =
            city.level4[0] + city.level4.slice(1).toLowerCase();
          return {
            value: city.idLevel4,
            label: cityCapitalized,
          };
        });

        setCitiesList(cities);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleClose = () => {
    setOpenEditCompany(false);
  };

  const schema = yup.object().shape({
    idCompany: yup
      .string()
      .max(50, t("App.validationMessages.maxCharacters", { number: 50 }))
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    checkDigit: yup
      .number()
      .typeError(t("App.validationMessages.onlyNumber"))
      .integer(t("App.validationMessages.integerNumber"))
      .min(0, t("App.validationMessages.positiveNumber"), { number: 0 })
      .max(9, t("App.validationMessages.maxCharacters", { number: 1 }))
      .nullable(),
    companyName: yup
      .string()
      .max(255, t("App.validationMessages.maxCharacters", { number: 255 }))
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA2,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    eanCompany: yup
      .string()
      .max(50, t("App.validationMessages.maxCharacters", { number: 50 }))
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    companyType: yup.string().required(t("App.validationMessages.required")),
    status: yup.string().required(t("App.validationMessages.required")),
    city: yup
      .object()
      .required(t("App.validationMessages.required"))
      .shape({
        value: yup.string().required(t("App.validationMessages.required")),
        label: yup.string(),
      })
      .typeError(t("App.validationMessages.required")),
    adress: yup
      .string()
      .max(255, t("App.validationMessages.maxCharacters", { number: 255 }))
      .nullable()
      // .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.LETTER_NUMBERS_AND_EMPTY,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    contactName: yup
      .string()
      .max(255, t("App.validationMessages.maxCharacters", { number: 255 }))
      // .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.LETTER_NUMBERS_AND_EMPTY,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    phone: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(
        REGEXP.LETTER_NUMBERS_AND_EMPTY,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    email: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  useEffect(() => {
    const company = props.currentCompany;
    const objectCity = {
      label: company.cityName ? company.cityName : "",
      value: company.cityCode ? company.cityCode : "",
    };

    const countryName = findCountryNameById(company.country);
    const objectCountry = {
      label: countryName ? countryName : "",
      value: company.country ? company.country : "",
    };

    setValue("idCompany", company.companyIdentificationNumber);
    setValue("checkDigit", company.checkDigit);
    setValue("companyName", company.companyName);
    setValue("eanCompany", company.eanCompany);
    setValue("companyType", company.typeCompany);
    setValue("status", company.state);
    setValue("country", objectCountry);
    setValue("city", objectCity);
    setValue("adress", company.adress);
    setValue("contactName", company.contactName ? company.contactName : "");
    setValue("phone", company.phone ? company.phone : "");
    setValue("email", company.email ? company.email : "");

    handleCities(company.country);
  }, [props.currentCompany, countriesList]);

  const setModalInfoTitle = (responseCode) => {
    if (responseCode === 1007) {
      return t("App.createAndEditCompany.modalInfoTitles.title1");
    } else if (responseCode === 1005) {
      return t("App.createAndEditCompany.modalInfoTitles.title2");
    } else {
      return t("App.createAndEditCompany.modalInfoTitles.title3");
    }
  };

  const handleEditCompany = async (data) => {
    try {
      const obj = {
        pk: props.currentCompany.pk,
        sk: props.currentCompany.sk,
        typeCompany: data.companyType,
        eanCompany: data.eanCompany,
        companyIdentificationNumber: data.idCompany,
        checkDigit: Number(data.checkDigit),
        companyName: data.companyName,
        country: props.currentCompany.country,
        cityCode: data.city.value || props.currentCompany.cityCode,
        cityName: data.city.label || props.currentCompany.cityName,
        adress: data.adress ? data.adress : "",
        contactName: data.contactName ? data.contactName : "",
        phone: data.phone ? data.phone : "",
        email: data.email,
        state: data.status,
        userModification: fullNameUser,
      };

      setIsLoading(true);

      const editService = await Edit(obj);

      setIsLoading(false);

      if (
        editService.status !== CODES.COD_RESPONSE_HTTP_OK ||
        editService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setTitle(setModalInfoTitle(editService.data.responseCode));
        setError(true);
        setEditCompany(editService);
        reset();
        return;
      }

      updateDatatableVisually(obj);
      setEditCompany(editService);
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  const updateDatatableVisually = (newCompany) => {
    props.setIsLoadingData(true);
    props.setData([]);
    const dataArray = setDataStructure(props.data);
    let rowArray = [];
    rowArray.push(newCompany);
    const newRow = setDataStructure(rowArray);
    let newDataArray = dataArray.filter((item) => item.sk !== newRow[0].sk);
    newDataArray.unshift(newRow[0]);

    const finalArray = newDataArray.map((item) => item);

    props.setData(finalArray);
    props.setNoContent(false);
    props.setIsLoadingData(false);
  };

  const setDataStructure = (data) => {
    const processedRows = data.map((comp) => {
      return {
        pk: comp.typeCompany
          ? `company_type#${comp.typeCompany}`
          : props.currentCompany.pk,
        sk: comp.country
          ? `country#${comp.country}#ean_company#${comp.eanCompany}`
          : props.currentCompany.sk,
        companyType: comp.typeCompany || comp.companyType,
        eanCompany: comp.eanCompany,
        companyId:
          comp.companyIdentificationNumber +
          (comp.checkDigit ? "-" + comp.checkDigit : ""),
        companyName: comp.companyName,
        city: comp.cityName || comp.city,
        address: comp.adress || comp.address,
        contact: comp.contactName || comp.contact,
        phone: comp.phone,
        status: comp.state === 1 || comp.status === 1 ? "activo" : "inactivo",
        edit: (
          <EditCompany
            currentCompany={comp}
            setData={props.setData}
            data={props.data}
            filterApply={props.filterApply}
            setIsLoadingData={props.setIsLoadingData}
            setNoContent={props.setNoContent}
          />
        ),
      };
    });

    return processedRows;
  };

  const handleFilters = async () => {
    try {
      const filter = props.filterApply;
      const currentCompany = props.currentCompany;

      const obj = {
        country: currentCompany.country,
        typeCompany: currentCompany.typeCompany === "C" ? "C" : "F",
        eanCompany: filter.eanCompany,
        companyIdentificationNumber: filter.companyId,
        companyName: filter.companyName,
        state: filter.status,
      };

      // console.log(obj);

      const filterService = await getCompaniesAdmin(obj);
      // console.log(filterService);

      if (filterService.status !== CODES.COD_RESPONSE_HTTP_OK) return;
      if (
        filterService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      )
        return;

      //Se settean la data en la tabla
      const dataStructure = setDataStructure(
        filterService.data.responseMessage
      );

      props.setData(dataStructure);
    } catch (error) {
      console.log(error);
    }
  };

  const findCountryNameById = (countryId) => {
    const country = countriesList.find(
      (country) => country.value === countryId
    );
    if (country) {
      return country.label;
    } else {
      return "";
    }
  };

  const handleDisabledButtonEdit = () => {
    if (
      watch("idCompany") &&
      watch("companyName") &&
      watch("status") !== "" &&
      watch("city") &&
      watch("email")
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
      </IconButton>

      <Modal
        open={openEditCompany}
        onClose={handleClose}
      >
        <Box className="scroll-y modal-box-createEditCompany">
          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton component="span" onClick={handleClose}>
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.createAndEditCompany.title2")}
              </h1>
            </Grid>

            <Grid item>
              <form onSubmit={handleSubmit(handleEditCompany)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.createAndEditCompany.subtitle1")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="idCompany"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.idCompany
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.idCompany ? errors.idCompany.message : ""
                            }
                            label={t("App.createAndEditCompany.idCompany")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue={null}
                        name="checkDigit"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.checkDigit
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.checkDigit ? errors.checkDigit.message : ""
                            }
                            label={t("App.createAndEditCompany.checkDigit")}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "checkDigit",
                                watch("checkDigit") ? watch("checkDigit") : null
                              );
                            }}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="companyName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.companyName
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.companyName
                                ? errors.companyName.message
                                : ""
                            }
                            label={t("App.createAndEditCompany.companyName")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="eanCompany"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled={true}
                            className={`${
                              errors.eanCompany
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.eanCompany ? errors.eanCompany.message : ""
                            }
                            label={t("App.createAndEditCompany.eanCompany")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel">
                          {t("App.createAndEditCompany.companyType")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="companyType"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              disabled={true}
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.companyType
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.createAndEditCompany.companyType")}
                              </MenuItem>
                              <MenuItem value="C">
                                {t("App.adminCompanies.filters.merchant")}
                              </MenuItem>
                              <MenuItem value="F">
                                {t("App.adminCompanies.filters.maker")}
                              </MenuItem>
                            </Select>
                          )}
                        />

                        <FormHelperText
                          sx={{
                            margin: "-8px 0 1px 0px",
                            color: "#e52900",
                            fontSize: 10,
                          }}
                        >
                          {errors.companyType ? errors.companyType.message : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel">
                          {t("App.listUsers.status")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.status
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.createAndEditCompany.status")}
                              </MenuItem>
                              <MenuItem value={1}>
                                {t("App.createRole.active")}
                              </MenuItem>
                              <MenuItem value={0}>
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />

                        <FormHelperText
                          sx={{
                            margin: "-8px 0 1px 0px",
                            color: "#e52900",
                            fontSize: 10,
                          }}
                        >
                          {errors.status ? errors.status.message : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.createAndEditCompany.subtitle2")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            isOptionEqualToValue={(option, value) => {
                              return option.label === value.label;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.country
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            disabled={true}
                            filterOptions={filterOptions}
                            options={countriesList}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                                label={t("App.createAndEditCompany.country")}
                              />
                            )}
                            onChange={(event, values) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.city
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            filterOptions={filterOptions}
                            options={citiesList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => {
                              return option.label === value.label;
                            }}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.value}>
                                  {option.label}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                                // FormHelperTextProps={{
                                //   style: {
                                //     margin: "-5px 0 1px 0px",
                                //     color: "#e52900",
                                //     fontSize: 10,
                                //   },
                                // }}
                                // helperText={
                                //   errors.city ? errors.city.message : ""
                                // }
                                label={t("App.createAndEditCompany.city")}
                              />
                            )}
                            onChange={(event, values, reason) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xs={12}>
                      <Controller
                        defaultValue={null}
                        name="adress"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.adress
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.adress ? errors.adress.message : ""
                            }
                            label={t("App.createAndEditCompany.address")}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "adress",
                                watch("adress") ? watch("adress") : null
                              );
                            }}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.createAndEditCompany.subtitle3")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue={null}
                        name="contactName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.contactName
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.contactName
                                ? errors.contactName.message
                                : ""
                            }
                            label={t("App.createAndEditCompany.contactName")}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactName",
                                watch("contactName")
                                  ? watch("contactName")
                                  : null
                              );
                            }}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue={null}
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.phone
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.phone ? errors.phone.message : ""
                            }
                            label={t("App.createAndEditCompany.phone")}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "phone",
                                watch("phone") ? watch("phone") : null
                              );
                            }}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} xs={12}>
                      <Controller
                        defaultValue=""
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            // type="email"
                            className={`${
                              errors.email
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={
                              errors.email ? errors.email.message : ""
                            }
                            label={t("App.createAndEditCompany.email")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  className="buttons-container"
                  spacing={2}
                  direction={isDesktop ? "row" : "column-reverse"}
                  justifyContent={isDesktop ? "flex-end" : "center"}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} lg={1} className="full-width">
                    <Button
                      className="btn__filled__gradient full-width"
                      onClick={handleClose}
                    >
                      {t("App.buttonLabels.cancel")}
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5.5}
                    lg={2.5}
                    className="full-width editCompany__container"
                  >
                    <Button
                      disabled={handleDisabledButtonEdit()}
                      className={`${
                        !handleDisabledButtonEdit()
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width editCompany__button`}
                      type="submit"
                      startIcon={!isLoading ? <CheckIcon /> : <></>}
                    >
                      {!isLoading ? (
                        t("App.buttonLabels.confirmChange")
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {success && (
        <ModalInfo
          responseData={editCompany}
          open={openEditCompany}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpenEditCompany(false);
          }}
        />
      )}

      {error && (
        <ModalInfo
          title={title}
          responseData={editCompany}
          open={openEditCompany}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpenEditCompany(false);
          }}
        />
      )}
    </>
  );
};

export default EditCompany;

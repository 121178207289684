/* eslint-disable default-case */
import { React, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ModalChooseGenerationDay from "../../../components/common/DynamicReport/modalChooseGenerationDay";
import TextField from "@mui/material/TextField";
import { ToolTipDayOfGeneration } from "../../../components/common/DynamicReport/toolTipDayOfGeneration";
import { TooltipAmountOfPeriods } from "../../../components/common/DynamicReport/tooltipAmountOfPeriods";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DynamicCheckBoxList } from "../../../components/common/DynamicReport/dynamicCheckBoxList";
import { ScopeList } from "../../../components/common/DynamicReport/scopeList";
import { ModalChangeType } from "../../../components/common/DynamicReport/modalChangeType";
import { ModalClearInfo } from "../../../components/common/DynamicReport/modalClearInfo";
import { ModalExceedEmails } from "../../../components/common/DynamicReport/modalExceedEmails";
import { ModalInvalidMail } from "../../../components/common/DynamicReport/modalInvalidMail";
import { ModalExceedPeriod } from "../../../components/common/DynamicReport/modalExceedPeriod";
import { ToolTipPeriodSummary } from "../../../components/common/DynamicReport/toolTipPeriodSummary";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../userSlice";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import {
  getTradersSelected,
  getEanPointSaleSelected,
  getPointSalesSelected,
  getFormatsSelected,
  getDepartmentsSelected,
  getChannelsSelected,
  getCitiesSelected,
  getCategoriesSelected,
  getProductsSelected,
  getSalesForceSelected,
  getBrandsSelected,
  getColorsSelected,
  getSizesSelected,
  getSalesManSelected,
  resetSelectedes,
} from "../../../parts/dynamicReport/dynamicReportSlice";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { GenerateDynamicReport } from "../../../services/user/dynamicReport/dynamicReportServices";
import { CODES } from "../../../consts/codes";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import { ModalContactsReport } from "../../../components/common/Modals/modalContactsReport";
import ClearIcon from "@mui/icons-material/Clear";
import { TypeSelectorReport } from "../../../components/common/DynamicReport/typeSelector";

export const CreateDynamicPeriodicReport = () => {
  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  const dispatch = useDispatch();

  /**
   * Use Navigate
   */
  const navigate = useNavigate();

  //Selectores Redux

  const userInfo = useSelector(getUser);

  const tradersSelected = useSelector(getTradersSelected);

  const eanPointSaleSelected = useSelector(getEanPointSaleSelected);

  const pointSalesSelected = useSelector(getPointSalesSelected);

  const formatsSelected = useSelector(getFormatsSelected);

  const departmentsSelected = useSelector(getDepartmentsSelected);

  const citiesSelected = useSelector(getCitiesSelected);

  const channelsSelected = useSelector(getChannelsSelected);

  const categoriesSelected = useSelector(getCategoriesSelected);

  const productsSelected = useSelector(getProductsSelected);

  const salesForceSelected = useSelector(getSalesForceSelected);

  const brandsSelected = useSelector(getBrandsSelected);

  const colorsSelected = useSelector(getColorsSelected);

  const sizesSelected = useSelector(getSizesSelected);

  const salesManSelected = useSelector(getSalesManSelected);

  //Estadoss

  const [startForm, setStartForm] = useState(false);

  const [reportType, setReportType] = useState(0);

  const [fileName, setFileName] = useState("");

  const [generationdayDisabled, setGenerationDisabled] = useState(true);

  const [frequency, setFrequency] = useState(0);
  const [status, setStatus] = useState(1);

  const [period, setPeriod] = useState(null);

  const [groupingLimit, setGroupingLimit] = useState(null);

  const [grouping, setGrouping] = useState("0");

  const [periodNumber, setPeriodNumber] = useState(1);

  const [openModalChangeType, setOpenModalChangeType] = useState(false);

  const [openModalExceedEmails, setOpenModalExceedLimits] = useState(false);

  const [openModalInvalidMail, setOpenModalInvalidMail] = useState(false);

  const [endDate, setEndDate] = useState("");

  const [startDate, setStartDate] = useState("");

  const [responseModalInfo, setResponseModalInfo] = useState({});

  const [openModalInfo, setModalInfo] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [responseOK, setResponseOK] = useState(false);

  const [openModalExceedPeriodLimit, setOpenModalExceedPeriodLimit] =
    useState(false);

  const [desiredType, setDesiredType] = useState(null);

  const [openModalContacts, setOpenModalContacts] = useState(false);
  const [openErrorContacts, setOpenErrorContacts] = useState(false);

  const [showValidationChecks, setShowValidationChecks] = useState(false);

  const [generationdayDayList, setGenerationDayList] = useState({
    label: t("App.dynamicPeriodicReport.when.noDay"),
    value: "",
  });
  const [limitReport, setLimitReport] = useState(false);

  const [openLoadingType, setOpenLoadingType] = useState(false);
  const [isBusinessGroupReport, setIsBusinessGroupReport] = useState(false);

  const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  /**
   * Schema para la validación de los campos
   */
  const schema = yup.object().shape({
    type: yup.string().required(t("App.validationMessages.required")),
    frecuency: yup.string().required(t("App.validationMessages.required")),
    status: yup.string().required(t("App.validationMessages.required")),
    grouping: yup.string().required(t("App.validationMessages.required")),
    numberOfPeriods: yup
      .number()
      .positive(t("App.validationMessages.greaterZero"))
      .required()
      .typeError(t("App.validationMessages.required")),
    fileName: yup
      .string()
      .max(50, t("App.validationMessages.maxCharacters", { number: 50 }))
      .required(t("App.validationMessages.required")),

    accounts: yup.string().required(t("App.validationMessages.required")),
  });

  /**
   *
   * Función que acciona el modal de cambio al usuario en caso de hacer cambio del tipo de reporte dinámico
   *
   * @param {*} event el evento del select de tipo de reporte
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;

    if (desiredType === null) {
      setReportType(id);
    }

    if (id !== 0) {
      setStartForm(true);

      setDesiredType(id);
    }

    if (desiredType !== null) {
      setOpenModalChangeType(true);
    }
  };

  /**
   * Función para retroceder a la página anterior
   */
  const handleBackReports = () => {
    navigate(-1);
  };

  /**
   *
   * Función para colocar los valores correspondientes a los respectivos campos cuando hay un cambio en el campo de frequency
   *
   * @param {*} event evento del campo de frequency
   */
  const handleFrecuencyChange = (event) => {
    const value = event.target.value;
    setFrequency(value);
    setPeriod(value);
    setGrouping(value);
    setValue("frecuency", value);
    setValue("grouping", value);

    if (value === 0 || value === "0") {
      setGenerationDisabled(true);
      setGenerationDayList({
        label: t("App.dynamicPeriodicReport.when.noDay"),
        value: "",
      });
    } else {
      setGenerationDisabled(false);
      setGenerationDayList({
        label: "",
        value: "",
      });
    }
  };

  /**
   *  Modificar el valor del estado del reporte
   */
  const handleStatusChange = (event) => {
    const value = event.target.value;
    setValue("satus", value);
    setStatus(value);
  };

  /**
   *
   * Función que coloca los valores de período y grupo a sus respectivos state cada vez que hay un cambio en el campo de grouping
   *
   * @param {*} event evento del campo de grouping
   */
  const handlePeriodChange = (event) => {
    const value = event.target.value;

    setPeriod(value);
    setGrouping(value);
    setValue("grouping", value);
  };

  /**
   *
   * Función que aplica una expresión regular en el nombre del archivo para verificar que no tenga carácteres especiales
   * si no hay carácteres especiales se procede a colocar el valor al state de fileName
   *
   * @param {*} e evento del campo de fileName
   */
  const onChangeFileName = (e) => {
    const newValue = e.target.value;

    if (!newValue.match(/[%<>\\$'";=]/)) {
      setFileName(newValue);
    }
  };

  /**
   * Función que cambia el valor del state de periodNumber cada vez que hay un cambio en el campo de numberOfPeriods
   *
   * @param {*} e evento del campo de numberOfPeriods
   */
  const onChangePeriodNumber = (e) => {
    const newValue = e.target.value;

    setPeriodNumber(newValue);
  };

  /**
   *
   * Función que elige la función correspondiente dependiendo del tipo de reporte
   *
   * @param {*} data data que se envía desde el formulario
   * @returns la función correspondiente al tipo de reporte
   */
  const exceedsPeriodLimit = (data) => {
    const groupingType = data.grouping;
    const periodNumbers = data.numberOfPeriods;

    if (reportType === "1") {
      return exceedsPeriodLimitInventories(periodNumbers, groupingType);
    } else {
      return exceedsPeriodLimitSales(periodNumbers, groupingType);
    }
  };

  /**
   *
   * Función que verifica si se excedieron los límites del número de periodos en inventarios
   *
   * @param {*} periodNumbers número de periodos
   * @param {*} groupingType tipo de agrupación
   * @returns true en caso de sobrepasarese del límite, false si es lo contrario
   */
  const exceedsPeriodLimitInventories = (periodNumbers, groupingType) => {
    if (groupingType === "0" && periodNumbers > 31) {
      setGroupingLimit(31);
      return true;
    } else if (
      (groupingType === "1" || groupingType === "4") &&
      periodNumbers > 26
    ) {
      setGroupingLimit(26);
      return true;
    } else if (groupingType === "2" && periodNumbers > 6) {
      setGroupingLimit(6);
      return true;
    } else if (groupingType === "3" && periodNumbers > 1) {
      setGroupingLimit(1);
      return true;
    } else {
      return false;
    }
  };

  /**
   *
   * Función que verifica si se excedieron los límites del número de periodos en ventas
   *
   * @param {*} periodNumbers número de periodos
   * @param {*} groupingType tipo de agrupación
   * @returns true en caso de sobrepasarese del límite, false si es lo contrario
   */
  const exceedsPeriodLimitSales = (periodNumbers, groupingType) => {
    if (groupingType === "0" && periodNumbers > 31) {
      setGroupingLimit(31);
      return true;
    } else if (
      (groupingType === "1" || groupingType === "4") &&
      periodNumbers > 52
    ) {
      setGroupingLimit(52);
      return true;
    } else if (groupingType === "2" && periodNumbers > 12) {
      setGroupingLimit(12);
      return true;
    } else if (groupingType === "3" && periodNumbers > 2) {
      setGroupingLimit(2);
      return true;
    } else {
      return false;
    }
  };

  /**
   *
   * Función para validar si el día de generación fue seleccionado, esto solo aplica para frecuencia de generación diferente a diario
   *
   * @returns true dependiendo de la frecuencia y si se seleccionó el día de generación, false si es lo contrario
   */
  const handleValidateGenerationDay = () => {
    if (frequency !== "0") {
      if (generationdayDayList.label === "") {
        return true;
      } else {
        return false;
      }
    }
  };

  /**
   *
   * Valida si el formulario tiene al menos un checkboxes seleccionado
   *
   * @param {} data campos del formulario
   * @returns true si hay un checkboxes seleccionado, false si es lo contrario
   */
  const validateAtLeastOneCheck = (data) => {
    const objToArray = Object.entries(data);

    const checkAtLeastOne = objToArray.filter((element) => element[1] === true);

    if (checkAtLeastOne.length > 0) {
      setShowValidationChecks(false);
      return false;
    } else {
      setShowValidationChecks(true);
      return true;
    }
  };

  /**
   * Función para abrir un modal para seleccionar los contactos
   */
  const handleOpenContacts = () => {
    setOpenModalContacts(true);
  };

  /**
   *
   * Función para crear un reporte dinámico
   *
   * @param {Object} data objeto con la data enviado desde el formulario
   */ // TODO: Revisar para selección contactos usuario pertenece BG
  const handleCreateReport = async (data) => {
    try {
      const exceeds = exceedsPeriodLimit(data);
      const oneSelected = validateAtLeastOneCheck(data);
      let limitAccounts = false;

      // Validar que el usuario no hay seleccionado más de 10 contactos
      if (data.accounts?.split(",")?.length > 10) {
        setOpenModalExceedLimits(true);
        limitAccounts = true;
      }

      if (exceeds) {
        setOpenModalExceedPeriodLimit(true);
      }

      let isValidateGenerationDay;
      if (handleValidateGenerationDay()) {
        isValidateGenerationDay = true;
        return;
      } else {
        isValidateGenerationDay = false;
      }

      if (
        !limitAccounts &&
        !exceeds &&
        !oneSelected &&
        !isValidateGenerationDay
      ) {
        setShowValidationChecks(false);
        setIsLoading(true);

        const emails = data.accounts ? data.accounts.split(",") : [];
        const cleanEmails = emails.map((email) => {
          return email.trim();
        });

        //GRUPO EMPRESARIAL: Dejamos listo el obj de las empresas seleccionadas
        //En caso de que este no tenga grupo, se envía arreglo vacío
        const finalCompanies = isBusinessGroupReport
          ? data.lstCompanies.map((company) => ({
              eanCompany: company.value,
              nameCompany: company.label.split("-")[1],
              country: company.country,
            }))
          : [];

        //LOS DATOS DE LA EMPRESA SI DEBEN SER LOS DEL USUARIO LOGUEADO (eanProvider, provider, country)
        const obj = {
          eanProvider: userInfo.company.eanCompany,
          provider: userInfo.company.companyName,
          country: userInfo.company.country,
          typeOfReport: data.type === "1" ? "inventarios" : "ventas",
          generationFrequency: getGenerationFrecuency(data.frecuency),
          generationDay: generationdayDayList.value,
          reportStatus: data.status === "1" ? "ACTIVO" : "INACTIVO",
          typeOfDataGrouping: getGenerationGrouping(data.grouping),
          amountOfPeriodsToGenerate: parseInt(data.numberOfPeriods),
          fileName: data.fileName,
          emails: cleanEmails.join(","),
          comparePreviousPeriod: data.compareCheck ? 1 : 0,
          crossSalesOrInventories: data.matchCheck ? 1 : 0,

          lstFilterTraders: transformArray(tradersSelected, "trader"),
          lstFilterEansPointsales: eanPointSaleSelected,
          lstFilterPointSales: transformArray(pointSalesSelected, "pointSale"),
          lstFilterFormats: formatsSelected,
          lstFilterLevel1Geographies: transformArray(
            departmentsSelected,
            "department"
          ),
          lstFilterLevel4Geographies: transformArray(citiesSelected, "city"),
          lstFilterChannels: channelsSelected,
          lstFilterLevel1Categories: categoriesSelected,
          lstFilterProducts: transformArray(productsSelected, "product"),
          lstLevel1Jeraquies: salesForceSelected,
          lstFilterSalesMan: transformArray(salesManSelected, "salesman"),
          lstFilterBrands: brandsSelected,
          lstFilterColors: colorsSelected,
          lstFilterSizes: sizesSelected,
          lstCompanies: finalCompanies,
          checkCompany: data.company ? 1 : 0,
          checkTrader: data.merchant ? 1 : 0,
          checkPointSale: data.pointOfSale ? 1 : 0,
          checkChanel: data.channel ? 1 : 0,
          checkFormat: data.format ? 1 : 0,
          checkDepartamentState: data.department ? 1 : 0,
          checkCity: data.city ? 1 : 0,
          checkSalesForceHierarchy: data.hierarchy ? 1 : 0,
          checkSalesMan: data.seller ? 1 : 0,
          checkCategoriesProduct: data.tree ? 1 : 0,
          checkProduct: data.product ? 1 : 0,
          checkBrand: data.brand ? 1 : 0,
          checkColor: data.colour ? 1 : 0,
          checkSize: data.size ? 1 : 0,
          checkDispatchUnit: data.dispatch ? 1 : 0,
          checkStandardUnit: data.standard ? 1 : 0,
          checkWeight: data.weight ? 1 : 0,
          checkVolume: data.volume ? 1 : 0,
          idUser: `${userInfo.firstName.substring(
            0,
            3
          )}${userInfo.lastName.substring(0, 3)}`,
          creationUser: userInfo.firstName + " " + userInfo.lastName,
        };

        const dynamicReportService = await GenerateDynamicReport(obj);
        if (
          // Valdar si se cuenta con el número máximo de reportes en estado activo
          dynamicReportService.status === CODES.COD_RESPONSE_HTTP_OK &&
          dynamicReportService.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_CREATE
        ) {
          setLimitReport(true);
        } else if (dynamicReportService.status === CODES.COD_RESPONSE_HTTP_OK) {
          setLimitReport(false);
          setResponseOK(true);
        } else {
          setLimitReport(false);
        }

        setResponseModalInfo(dynamicReportService);

        setModalInfo(true);

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { lstCompanies: [] },
  });

  /**
   * Función para limpiar los campos el formulario
   */
  const handleClear = () => {
    reset();
    setFileName("");
    setPeriodNumber(1);

    handleFrecuencyChange({
      target: {
        value: "0",
      },
    });
    // reiniciar el valor del estado
    handleStatusChange({
      target: {
        value: "1",
      },
    });
    // Limpiar campos alcance reporte y portafolio
    dispatch(resetSelectedes());
  };

  /**
   *
   * Función para obtener el string correspondiente del tipo de frecuencia de generación
   *
   * @param {*} data
   * @returns string corrspondiente al tipo de frecuencia de generación
   */
  const getGenerationFrecuency = (data) => {
    switch (Number(data)) {
      case 0:
        return "Diario";
      case 1:
        return "Semanal";
      case 2:
        return "Mensual";
      case 3:
        return "Anual";
    }
  };

  /**
   *
   * Función para obtener el string correspondiente del tipo de agrupación
   *
   * @param {*} data
   * @returns el string respectivo al número de agrupación
   */
  const getGenerationGrouping = (data) => {
    switch (data) {
      case "0":
        return "Diario";
      case "1":
        return "Semanal";
      case "2":
        return "Mensual";
      case "3":
        return "Anual";
    }
  };

  /**
   *
   * Función para transformar los valores seleccionados de los checkboxes en un arreglo de objetos
   *
   * @param {*} data
   * @param {*} type
   * @returns el arreglo con los objeto creados apartir de los valores de los checkboxes
   */
  const transformArray = (data, type) => {
    switch (type) {
      case "trader":
        return data.map((item) => {
          return { trader: item.label, eanTrader: item.ean };
        });
      case "pointSale":
        return data.map((item) => {
          return { pointSaleName: item.label, eanPointSale: item.ean };
        });
      case "department":
        return data.map((item) => {
          return { levelOne: item.label, idLevelOne: item.ean };
        });
      case "city":
        return data.map((item) => {
          return { levelFour: item.label, idLevelFour: item.ean };
        });
      case "product":
        return data.map((item) => {
          return { productName: item.label, eanProduct: item.ean };
        });
      case "salesman":
        return data.map((item) => {
          return { salesMan: item.label, salesManCode: item.ean };
        });
    }
  };

  /**
   * Función para eliminar los contactos seleccionados
   */
  const handleDeleteAccounts = () => {
    setValue("accounts", "");
  };

  return (
    <form onSubmit={handleSubmit(handleCreateReport)}>
      <ModalChangeType
        open={openModalChangeType}
        setOpen={setOpenModalChangeType}
        setReportType={setReportType}
        desiredType={desiredType}
        setValue={setValue}
      />

      <ModalExceedEmails
        open={openModalExceedEmails}
        setOpen={setOpenModalExceedLimits}
      />

      <ModalInvalidMail
        open={openModalInvalidMail}
        setOpen={setOpenModalInvalidMail}
      />

      <ModalExceedPeriod
        open={openModalExceedPeriodLimit}
        setOpen={setOpenModalExceedPeriodLimit}
        grouping={grouping}
        limit={groupingLimit}
      />

      <ModalInfo
        title={
          limitReport
            ? t("App.dynamicPeriodicReport.limit")
            : responseOK
            ? t("App.dynamicPeriodicReport.success")
            : t("App.dynamicPeriodicReport.title")
        }
        responseData={responseModalInfo}
        open={openModalInfo}
        onClose={() => {
          setModalInfo(false);
          navigate(-1);
        }}
      />

      <ModalContactsReport
        open={openModalContacts}
        setOpen={setOpenModalContacts}
        setValue={setValue}
        watch={watch}
        userInfo={userInfo}
        setOpenModalError={setOpenErrorContacts}
      />

      <ModalInfo
        open={openErrorContacts}
        title={t("App.adminContacts.modals.case1005")}
        responseData={{
          data: { responseMessage: t("App.validationMessages.systemError2") },
        }}
        onClose={() => {
          setOpenErrorContacts(false);
        }}
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.dynamicPeriodicReport.title")}
          </p>
        </Grid>

        <Grid item className="back-container">
          <Grid container direction="row">
            <Grid item>
              <IconButton disableRipple onClick={handleBackReports}>
                <ArrowBackIosNewIcon className="display-large__moderate-blue" />
              </IconButton>
            </Grid>

            <Grid item className="col-start">
              <p className="display-small__moderate-blue">
                {t("App.dynamicPeriodicReport.goBack")}
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="grid__container__fullW">
          <TypeSelectorReport
            componentName={"dynamicReport"}
            reportType={reportType}
            setReportType={setReportType}
            control={control}
            handleTypeChange={handleTypeChange}
            typeOfDocument={"type"}
            errors={errors}
            setValue={setValue}
            setStartForm={setStartForm}
            setDesiredType={setDesiredType}
            setIsBusinessGroupReport={setIsBusinessGroupReport}
            handleCleanForm={handleClear}
            getValues={getValues}
          />
        </Grid>

        {startForm && (
          <>
            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className="grid__container__filled__rounded"
              >
                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.dynamicPeriodicReport.when.subtitle")}
                  </p>
                </Grid>

                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={8}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={4}>
                              <p className="label__text-default">
                                {t("App.dynamicPeriodicReport.when.frecuency")}
                              </p>
                            </Grid>

                            <Grid item xs={4} sm={8}>
                              <Select
                                {...register("frecuency")}
                                onChange={handleFrecuencyChange}
                                className={
                                  errors.frecuency
                                    ? "modalCreateUsers__form-input--error"
                                    : "modalCreateUsers__form-input"
                                }
                                value={frequency}
                                id="frecuency"
                                name="frecuency"
                                labelId="frecuency"
                              >
                                <MenuItem value="0">
                                  <em>
                                    {t("App.dynamicPeriodicReport.when.daily")}
                                  </em>
                                </MenuItem>

                                <MenuItem value="1">
                                  <em>
                                    {t("App.dynamicPeriodicReport.when.weekly")}
                                  </em>
                                </MenuItem>

                                <MenuItem value="2">
                                  <em>
                                    {t(
                                      "App.dynamicPeriodicReport.when.monthly"
                                    )}
                                  </em>
                                </MenuItem>

                                <MenuItem value="3">
                                  <em>
                                    {t("App.dynamicPeriodicReport.when.yearly")}
                                  </em>
                                </MenuItem>
                              </Select>

                              <FormHelperText
                                sx={{
                                  margin: "-8px 0 1px 0",
                                  color: "#e52900",
                                  fontSize: 10,
                                }}
                              >
                                {errors.frecuency
                                  ? errors.frecuency.message
                                  : ""}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={4}>
                              <p className="label__text-default">
                                {t(
                                  "App.dynamicPeriodicReport.when.generationDay"
                                )}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item>
                                  <TextField
                                    onChange={handleFrecuencyChange}
                                    className={
                                      handleValidateGenerationDay()
                                        ? "modalCreateUsers__form-input--error"
                                        : "modalCreateUsers__form-input"
                                    }
                                    value={generationdayDayList.label}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    FormHelperTextProps={{
                                      style: {
                                        margin: "-5px 0 1px 0px",
                                        color: "#e52900",
                                        fontSize: 10,
                                      },
                                    }}
                                    helperText={
                                      handleValidateGenerationDay()
                                        ? t("App.validationMessages.required")
                                        : ""
                                    }
                                    id="generationDay"
                                    name="generationDay"
                                    disabled={generationdayDisabled}
                                  />
                                </Grid>

                                <Grid item>
                                  <ModalChooseGenerationDay
                                    generationdayDisabled={
                                      generationdayDisabled
                                    }
                                    setGenerationDayList={setGenerationDayList}
                                    generationdayDayList={generationdayDayList}
                                    frequency={frequency}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={4}>
                              <p className="label__text-default">
                                {t("App.dynamicPeriodicReport.when.status")}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                              <Select
                                {...register("status")}
                                className={
                                  errors.status
                                    ? "modalCreateUsers__form-input--error"
                                    : "modalCreateUsers__form-input"
                                }
                                value={status}
                                id="status"
                                name="status"
                                onChange={handleStatusChange}
                              >
                                <MenuItem value="1">
                                  <em>
                                    {t("App.dynamicPeriodicReport.when.active")}
                                  </em>
                                </MenuItem>

                                <MenuItem value="0">
                                  <em>
                                    {t(
                                      "App.dynamicPeriodicReport.when.inactive"
                                    )}
                                  </em>
                                </MenuItem>
                              </Select>

                              <FormHelperText
                                sx={{
                                  margin: "-8px 0 1px 0",
                                  color: "#e52900",
                                  fontSize: 10,
                                }}
                              >
                                {errors.status ? errors.status.message : ""}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <ToolTipDayOfGeneration
                        generationdayDayList={generationdayDayList}
                        frequency={frequency}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className="grid__container__filled__rounded"
              >
                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.dynamicPeriodicReport.period.subtitle")}
                  </p>
                </Grid>

                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={8}>
                      <Grid
                        container
                        direction="column"
                        justifyContent={{ xs: "flex-start", sm: "center" }}
                        alignItems="flex-start"
                      >
                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={4}>
                              <p className="label__text-default">
                                {t(
                                  "App.dynamicPeriodicReport.period.typeOfGroup"
                                )}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                              <Controller
                                name="grouping"
                                onChange={handlePeriodChange}
                                control={control}
                                defaultValue="0"
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    className={
                                      errors.grouping
                                        ? "modalCreateUsers__form-input--error"
                                        : "modalCreateUsers__form-input"
                                    }
                                    id="grouping"
                                    name="grouping"
                                    labelId="grouping"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      handlePeriodChange(e);
                                    }}
                                  >
                                    <MenuItem value="0">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.daily"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="1">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.period.isoWeek"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="2">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.monthly"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="3">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.yearly"
                                        )}
                                      </em>
                                    </MenuItem>
                                  </Select>
                                )}
                              />

                              <FormHelperText
                                sx={{
                                  margin: "-8px 0 1px 0",
                                  color: "#e52900",
                                  fontSize: 10,
                                }}
                              >
                                {errors.groupin ? errors.groupin.message : ""}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={4}>
                              <p className="label__text-default">
                                {t(
                                  "App.dynamicPeriodicReport.period.numberPeriods"
                                )}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item>
                                  <Controller
                                    name="numberOfPeriods"
                                    control={control}
                                    defaultValue="1"
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <TextField
                                        inputProps={{
                                          style: {
                                            margin: "-5px 0 0 0",
                                            fontSize: 12,
                                          },
                                        }}
                                        type="number"
                                        className={
                                          errors.numberOfPeriods
                                            ? "modalCreateUsers__form-input--error__small"
                                            : "modalCreateUsers__form-input__small"
                                        }
                                        FormHelperTextProps={{
                                          style: {
                                            margin: "-5px 0 1px 0px",
                                            color: "#e52900",
                                            fontSize: 10,
                                          },
                                        }}
                                        helperText={
                                          value
                                            ? errors.amountOfPeriodsToGenerate
                                              ? errors.amountOfPeriodsToGenerate
                                                  .message
                                              : value < 1
                                              ? t(
                                                  "App.validationMessages.greaterZero"
                                                )
                                              : ""
                                            : t(
                                                "App.validationMessages.greaterZero"
                                              )
                                        }
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          onChangePeriodNumber(e);
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item>
                                  <TooltipAmountOfPeriods />
                                </Grid>

                                <Grid item>
                                  {(period === null || period === "0") && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.backDays"
                                      )}
                                    </p>
                                  )}

                                  {period === "1" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.backIsoWeeks"
                                      )}
                                    </p>
                                  )}

                                  {period === "2" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.backMonths"
                                      )}
                                    </p>
                                  )}

                                  {period === "3" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.backYears"
                                      )}
                                    </p>
                                  )}

                                  {period === "4" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.backWeeks"
                                      )}
                                    </p>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item>
                                  {(period === null || period === "0") && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.lastPeriodDay"
                                      )}
                                    </p>
                                  )}

                                  {period === "1" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.lastPeriodIsoWeek"
                                      )}
                                    </p>
                                  )}

                                  {period === "2" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.lastPeriodMonth"
                                      )}
                                    </p>
                                  )}

                                  {period === "3" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.lastPeriodYear"
                                      )}
                                    </p>
                                  )}

                                  {period === "4" && (
                                    <p className="subheading__primary-one">
                                      {t(
                                        "App.dynamicPeriodicReport.period.lastPeriodWeek"
                                      )}
                                    </p>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <ToolTipPeriodSummary
                        numberPeriods={watch}
                        generationdayDayList={generationdayDayList}
                        grouping={grouping}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        amountOfPeriods={periodNumber}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className="grid__container__filled__rounded"
              >
                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.dynamicPeriodicReport.config.subtitle")}
                  </p>
                </Grid>

                <Grid item className="grid__container__fullW ">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={9}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid
                          item
                          className="u-marginT grid__container__fullW  u-marginB__responsive"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={3}>
                              <p className="label__text-default">
                                {t("App.dynamicPeriodicReport.config.fileName")}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={9}>
                              <TextField
                                {...register("fileName")}
                                value={fileName}
                                className={
                                  errors.fileName
                                    ? "modalCreateUsers__form-input--error label__text-grey"
                                    : "modalCreateUsers__form-input label__text-grey"
                                }
                                inputProps={{ maxLength: 50 }}
                                id="fileName"
                                name="fileName"
                                helperText={t(
                                  "App.dynamicPeriodicReport.config.fileHelper"
                                )}
                                FormHelperTextProps={{
                                  className: "caption__blue3",
                                  style: {
                                    margin: "6px 0 1px 0px",
                                  },
                                }}
                                onChange={onChangeFileName}
                              />

                              <FormHelperText
                                sx={{
                                  margin: "-8px 0 1px 0px",
                                  color: "#e52900",
                                  fontSize: 10,
                                }}
                              >
                                {errors.fileName ? errors.fileName.message : ""}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          className="u-marginT grid__container__fullW   u-marginB__responsive"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="center"
                          >
                            <Grid item xs={12} md={3}>
                              <p className="label__text-default">
                                {t("App.dynamicPeriodicReport.config.accounts")}
                              </p>
                            </Grid>

                            <Grid item xs={12} sm={9}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Grid item>
                                  <TextField
                                    disabled
                                    {...register("accounts")}
                                    defaultValue=""
                                    autoComplete="off"
                                    className={
                                      errors.accounts
                                        ? "modalCreateUsers__form-input--error__large label__text-grey"
                                        : "modalCreateUsers__form-input__large label__text-grey"
                                    }
                                    FormHelperTextProps={{
                                      className: "caption__blue3",
                                      style: {
                                        margin: "6px 0 1px 0px",
                                      },
                                    }}
                                    id="accounts"
                                    name="accounts"
                                    helperText={t(
                                      "App.dynamicPeriodicReport.config.accountsHelper"
                                    )}
                                    InputProps={{
                                      endAdornment: watch("accounts") && (
                                        <IconButton
                                          className="btn__outlined__blue"
                                          sx={{
                                            color: "white",
                                            cursor: "pointer",
                                            border: "none !important",
                                            marginBottom: "3%",
                                          }}
                                          onClick={handleDeleteAccounts}
                                        >
                                          <ClearIcon sx={{ fontSize: 16 }} />
                                        </IconButton>
                                      ),
                                    }}
                                  />

                                  <FormHelperText
                                    sx={{
                                      margin: "-8px 0 1px 0",
                                      color: "#e52900",
                                      fontSize: 10,
                                    }}
                                  >
                                    {errors.accounts
                                      ? errors.accounts.message
                                      : ""}
                                  </FormHelperText>
                                </Grid>

                                <Grid
                                  item
                                  className="col-flex"
                                  style={{ marginTop: "1%" }}
                                >
                                  <IconButton
                                    className="btn__filled__blue"
                                    sx={{
                                      fontSize: 26,
                                      color: "white",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleOpenContacts}
                                  >
                                    <AddIcon sx={{ fontSize: 20 }} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name={"compareCheck"}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label={t("App.dynamicPeriodicReport.config.compare")}
                        />

                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name={"matchCheck"}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label={
                            reportType == "2"
                              ? t(
                                  "App.dynamicPeriodicReport.config.inventoryMatch"
                                )
                              : t("App.dynamicPeriodicReport.config.salesMatch")
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  className=" grid__container__fullW grid__container__boxShadow"
                  style={{ marginTop: "3rem" }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <p className="label__text-default">
                            {t("App.dynamicPeriodicReport.config.informpt1")}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className="grid__container__filled__rounded"
              >
                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.dynamicPeriodicReport.scope.subtitle")}
                  </p>
                </Grid>

                <Grid item className="grid__container__fullW ">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item xs={12}>
                              <ScopeList
                                control={control}
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className="grid__container__filled__rounded"
              >
                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.dynamicPeriodicReport.fields.subtitle")}
                  </p>
                </Grid>

                <Grid item className="grid__container__fullW ">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item xs={12}>
                              <p className="label__text-default bold">
                                {t("App.dynamicPeriodicReport.fields.select")}
                              </p>
                            </Grid>

                            {showValidationChecks && (
                              <Grid
                                item
                                xs={12}
                                className="caption__error-color"
                              >
                                {t("App.specialFilesClient.oneSelected")}
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <DynamicCheckBoxList
                                control={control}
                                register={register}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <p className="label__text-default">
                                {t(
                                  "App.dynamicPeriodicReport.fields.description"
                                )}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW u-marginB">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                className="grid__container__filled__rounded"
              >
                <Grid item className="grid__container__fullW ">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item className="u-marginT grid__container__fullW">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <ModalClearInfo handleClear={handleClear} />
                            </Grid>

                            <Grid item>
                              <Button
                                className="btn__filled__blue"
                                type="submit"
                              >
                                {!isLoading ? (
                                  t("App.buttonLabels.save")
                                ) : (
                                  <CircularProgress size={16} color="inherit" />
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Modal open={openLoadingType} className="col-flex">
          <CircularProgress size={80} sx={{ color: "#4DCAFA" }} />
        </Modal>
      </Grid>
    </form>
  );
};

import { React, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { FilterUserDetail } from "../../components/common/filtersUserDetail";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { ModalExceededLimit } from "../../components/common/modalExceededLimit";
import { GetUsersByCompany } from "../../services/admin/adminServices";
import { CODES } from "../../consts/codes";
import ModalInfo from "../../components/common/Modals/modalInfo";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage.jsx";
import { fetchUser, getUser, getUserStatus } from "../userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyByEanCompany,
  getCurrentCompany,
  getCurrentCompanyStatus,
  getDataKeyFilters,
  setDataKeyFilters,
} from "../../parts/listUsers/listUsersSlice";
import { NoInfoComponent } from "../../components/common/NoInfoImage/noInfoComponent";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CreateUser from "../../components/common/AdminUsersClient/createUser";
import EditUser from "../../components/common/AdminUsersClient/editUser";
import ModalSeeCompanies from "../../components/common/AdminUsersClient/modalSeeCompanies";
import jwt_decode from "jwt-decode";
import { getUserPermissions } from "../../services/user/consultRolesService.js";

export const UserDetail = (props) => {
  /**
   * Tomando el rol por el token
   */
  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    role = jwtDecodeTokenInfo["custom:Role"];
  }

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [company, setCompany] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [actives, setActives] = useState(null);
  const [exceededLimit, setExcedeedLimit] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { country } = useParams();
  const [openCreateUser, setOpenCreateUser] = useState(false);

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);
  const dataKeyFilters = useSelector(getDataKeyFilters);

  const [openEditUser, setOpenEditUser] = useState(false);
  const [currentUserEdit, setCurrentUserEdit] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    permissions: [],
  });

  const currentCompany = useSelector(getCurrentCompany);
  const currentCompanyStatus = useSelector(getCurrentCompanyStatus);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * UseState
   */

  const [noInfo, setNoInfo] = useState(false);

  const [openModalNotificacion, setOpenModalNotification] = useState(false);

  /**
   * Traer información de los productos y sus roles
   *
   */

  useEffect(() => {
    // Obtener la información del filtro realizado por el usuario
    const dataKeyFiltersStorage = JSON.parse(
      localStorage.getItem("dataKeyFilters")
    );
    const { nameCompany, product } = dataKeyFiltersStorage.filterKeys;
    const filterKeys = {
      country: {
        country:
          dataKeyFilters.country.country !== ""
            ? dataKeyFilters.country?.country
            : country,
        countryName:
          dataKeyFilters.country.countryName !== ""
            ? dataKeyFilters.country.countryName
            : dataKeyFiltersStorage.filterKeys.country.countryName,
      },

      nameCompany: dataKeyFilters.nameCompany
        ? dataKeyFilters.nameCompany
        : nameCompany,
      eanCompany: dataKeyFilters.eanCompany
        ? dataKeyFilters.eanCompany
        : companyId,
      product: dataKeyFilters.product ? dataKeyFilters.product : product,
      typeCompany:
        dataKeyFilters.typeCompany.value !== ""
          ? dataKeyFilters.typeCompany
          : dataKeyFiltersStorage.filterKeys.typeCompany,
      navigateUserDetalis: true,
    };

    dispatch(setDataKeyFilters({ datakey: filterKeys }));
  }, [dispatch]);

  /**
   * Traer información de la compañia
   */

  useEffect(() => {
    try {
      if (currentCompanyStatus === "fetch") {
        dispatch(
          getCompanyByEanCompany({ eanCompany: companyId, country: country })
        );
      }
    } catch (error) {
      console.log("==============Error Get CompanyInfo======================");
      console.log(error);
      console.log("====================================");
    }
  }, [dispatch, currentCompanyStatus]);

  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

/**
   * Función que formatea los permisos del usuario
   * @param {*} user usuario
   * @param {*} rawData lista de usuarios
   * @returns lista de permisos del usuario
   */
const definePermissionsArray = async (user) => {
  try {
    const eanCompany = dataKeyFilters.eanCompany
          ? dataKeyFilters.eanCompany
          : companyId;
    const country = dataKeyFilters.country.country !== ""
          ? dataKeyFilters.country?.country
          : country;
    const email = user.email

    const permissionService = await getUserPermissions({ eanCompany: eanCompany, country:country, email:email });
    const { responseCode, responseMessage } = permissionService.data;

    if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        const finalPermissions = responseMessage.map((userPermission) => {
          return {
            idMenuOption: userPermission.idMenuOption,
            menuOptionName: userPermission.menuOptionName,
            companyList: userPermission.lstCompanies,
            roleList: `{"idRole": "${userPermission.idRole}", "nameRole": "${userPermission.nameRole}"}`,
            state: userPermission.state,
            editRecord: userPermission.editRecord
          };
        });
        return finalPermissions;
    } else {
      return [];
    }
  }catch (error) {
    console.log("====================================");
    console.log(error);
    console.log("====================================");
    return [];
  }
  

};

  const handleClose = () => {
    setOpenModalNotification(false);
  };
  const handleChangeDataKey = () => {
    navigate(-1);
  };

  const columns = [
    {
      name: "firstName",
      label: t("App.userDetail.name"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: t("App.userDetail.lastName"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: t("App.userDetail.email"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "phone",
      label: t("App.userDetail.phone"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "listCompanies",
      label: t("App.manageBusinessGroup.table.provider"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "menuOptionName",
      label: t("App.userDetail.product"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nameRole",
      label: t("App.userDetail.role"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "state",
      label: t("App.userDetail.status"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "edit",
      label: t("App.userDetail.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: noInfo ? <NoInfoComponent type="user" /> : <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };
  /* Hook que trae la info de los usuarios */
  useEffect(() => {
    fetchUsers();
  }, []);

  /**
   * Fetch
   */

  const fetchUsers = async () => {
    try {
      const obj = {
        eanCompany: companyId,
        country: country,
      };
      const usersService = await GetUsersByCompany(obj);

      if (usersService.status === CODES.COD_RESPONSE_HTTP_OK) {
        setLoading(false);
        if (usersService.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
          const responseMessage = usersService.data.responseMessage;
          const actives = responseMessage.filter((item) => item.active);
          setActives(actives.length);

          const processRows = setDataStructureArray(responseMessage);

          setUnfilteredData(processRows);
          setData(processRows);
        } else {
          setNoInfo(true);
        }
      }
    } catch (error) {
      setNoInfo(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Modal create functions
   */

  /**
   * Sets current user information to edit
   * @param {*} currentUser current user to edit
   */
  const handleOpenEdit = async (currentUser) => {
    setCurrentUserEdit({ ...currentUser,permissions: await definePermissionsArray(currentUser), randomIdTrigger: generateId() });
    setOpenEditUser(true);
  };

  /**
   * funcion setDataStructureArray - procesar y transfomar los datos recibidos
   * @param {Array} data - datos que se deben traer
   * @returns {Array} - array de los datos actualizados
   */
  const setDataStructureArray = (data) => {
    const formattedData = data.map((clientUser) => {
      const itemForEdit = {
        ...clientUser,
      };

      return {
        firstName: clientUser.firstName,
        lastName: clientUser.lastName,
        email: clientUser.email,
        phone: clientUser.phone,
        listCompanies:
          clientUser.lstCompanies?.length > 1 ? (
            <ModalSeeCompanies
              data={clientUser.lstCompanies}
              userName={`${clientUser.firstName} ${clientUser.lastName}`}
            />
          ) : (
            clientUser.lstCompanies[0].renameCompany
          ),
        nameRole: clientUser.nameRole,
        menuOptionName: clientUser.menuOptionName,
        state:
          clientUser.state === 1 ? (
            <p className="state-colors__primary-color-one">
              {t("App.userDetail.filter.active")}
            </p>
          ) : (
            <p className="state-colors__tertiary-color-one">
              {t("App.userDetail.filter.inactive")}
            </p>
          ),
        edit: (
          <IconButton
            onClick={() => {
              handleOpenEdit(itemForEdit);
            }}
          >
            <EditOutlinedIcon fontSize={"24px"} style={{ color: "#543ab4" }} />
          </IconButton>
        ),
      };
    });

    return formattedData;
  };

  /**
   * Function to generate a generic id for the permissions registers
   * @returns generic id
   */
  const generateId = () => {
    const id = Date.now().toString(32) + Math.random().toString(32);
    return id;
  };

  /**
   * Abrir el modal para crear usuario
   */
  const handleOpen = () => {
    setOpenCreateUser(true);
  };

  const loader = () => {
    const options = {
      filterType: "dropdown",
      download: false,
      print: "false",
      selectableRows: "none",
      responsive: "standard",
      searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
      elevation: 0,
      viewColumns: false,
      search: false,
      filter: false,
      searchOpen: true,
      rowsPerPageOptions: [10, 15, 20],
      textLabels: {
        body: {
          noMatch: <Skeleton />,
          toolTip: "Sort",

          columnHeaderTooltip: (column) =>
            t("App.buttonLabels.order", { label: column.label }),
        },
        pagination: {
          next: t("App.userDetail.next"),
          previous: t("App.userDetail.previous"),
          rowsPerPage: t("App.userDetail.resultsPerPage"),
          displayRows: t("App.userDetail.of"),
        },
      },
    };

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={2}
        style={{ padding: "1rem" }}
      >
        <ModalInfo
          title={modalTitle}
          responseData={responseData}
          open={openModalNotificacion}
          onClose={handleClose}
        />
        <ModalExceededLimit
          actives={actives}
          company={company}
          open={exceededLimit}
          setOpen={setExcedeedLimit}
        />
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.userDetail.title") +
              " " +
              (!currentCompany ? " " : currentCompany.companyName)}
          </p>
        </Grid>
        <Grid className="modalCreateUsers-principalBox"></Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <IconButton className="btn__back" onClick={handleChangeDataKey}>
                <ArrowBackOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <p className="subheading__blue3">{t("App.userDetail.goBack")}</p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Accordion className="accordion__filter">
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />
              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <FilterUserDetail
                setDataStructureArray={setDataStructureArray}
                data={data}
                setData={setData}
                unfilteredData={unfilteredData}
                company={companyId}
                setUnfilteredData={setUnfilteredData}
                setLoading={setLoading}
                setFilterNotFound={setFilterNotFound}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Skeleton>
                <Chip
                  label={`${t("App.userDetail.country")}: ${
                    !currentCompany ? " - " : currentCompany.country
                  } `}
                  variant="outlined"
                />
              </Skeleton>
            </Grid>

            <Grid item>
              <Skeleton>
                <Chip
                  label={`${t("App.userDetail.enterprise")}: ${
                    !currentCompany ? " - " : currentCompany.companyName
                  } `}
                  variant="outlined"
                />
              </Skeleton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {!filterNotFound ? (
            <MUIDataTable columns={columns} options={options} />
          ) : (
            <NoMatchImage />
          )}
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return loader();
  } else {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={2}
        style={{ padding: "1rem" }}
      >
        <ModalInfo
          title={modalTitle}
          responseData={responseData}
          open={openModalNotificacion}
          onClose={handleClose}
        />
        <ModalExceededLimit
          actives={actives}
          company={company}
          open={exceededLimit}
          setOpen={setExcedeedLimit}
        />
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.userDetail.title") +
              " " +
              (!currentCompany ? " - " : currentCompany.companyName)}
          </p>
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <Grid item>
              <IconButton className="btn__back" onClick={handleChangeDataKey}>
                <ArrowBackOutlinedIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <p className="subheading__blue3">{t("App.userDetail.goBack")}</p>
            </Grid>
          </Grid>
          <Grid
            sx={{ width: "100%", display: "flex" }}
            className="modalCreateUsers-principalBox"
            item
            xl={1.5}
            lg={2}
            sm={4}
            xs={12}
          >
            <Grid width="100%">
              <Button
                startIcon={<AddIcon sx={{ fontSize: 22 }} />}
                className="btn__filled__blue-contact dimentions-btn__applyFilter"
                onClick={handleOpen}
              >
                {t("App.adminClientUsers.buttonCreateUsuario")}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />
              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <FilterUserDetail
                setDataStructureArray={setDataStructureArray}
                data={data}
                setData={setData}
                unfilteredData={unfilteredData}
                company={companyId}
                setLoading={setLoading}
                setUnfilteredData={setUnfilteredData}
                setFilterNotFound={setFilterNotFound}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Chip
                label={`${t("App.userDetail.country")}: ${
                  !currentCompany ? " - " : currentCompany.country
                } `}
                variant="outlined"
              />
            </Grid>

            <Grid item>
              <Chip
                label={`${t("App.userDetail.enterprise")}: ${
                  !currentCompany ? " - " : currentCompany.companyName
                } `}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {!filterNotFound ? (
            <MUIDataTable
              className="dataTable__base"
              data={data}
              columns={columns}
              options={options}
            />
          ) : (
            <NoMatchImage />
          )}
        </Grid>
        <CreateUser
          currentCompany={currentCompany}
          openCreateUser={openCreateUser}
          setOpenCreateUser={setOpenCreateUser}
          isAdmin={true}
          role={role}
          companyId={companyId}
          country={country}
          fetchUsers={fetchUsers}
        />
        <EditUser
          isAdmin={true}
          currentCompany={currentCompany}
          openEditUser={openEditUser}
          setOpenEditUser={setOpenEditUser}
          clientUser={currentUserEdit}
          role={role}
          companyId={companyId}
          country={country}
          fetchUsers={fetchUsers}
        />
      </Grid>
    );
  }
};

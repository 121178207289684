import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function FilterReports(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreports/filter",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListReports(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreports/provider",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadReports(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreports/downloads",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadLogs(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreports/downloads/logs",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const TutorialsAccordion = ({ tutorialsInfo, t }) => {
  /**
   * Use State
   */
  const [tutorialsList, setTutorialsList] = useState([]);

  const [originalTutorialsList, setOriginalTutorialsList] = useState([]);

  const [isNotFound, setIsNotFound] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    if (tutorialsInfo.length > 0) {
      setTutorialsList(tutorialsInfo);
      setOriginalTutorialsList(tutorialsInfo);
    }
  }, [tutorialsInfo]);

  /**
   * Handles
   */

  /**
   * Maneja la búsqueda de videos basados en palabras claves en título y contenido
   * @param {} e evento que contiene la búsqueda actual
   */
  const handleSearch = (e) => {
    const currentSearch = e.target.value;

    const filteredTutorials = originalTutorialsList.filter((tutorial) => {
      let searchLowered = currentSearch.toLowerCase();
      let titleLowered = tutorial.title.toLowerCase();
      let contentLowered = tutorial.content.toLowerCase();
      return (
        titleLowered.includes(searchLowered) ||
        contentLowered.includes(searchLowered)
      );
    });

    if (filteredTutorials.length > 0) {
      setIsNotFound(false);
      setTutorialsList(filteredTutorials);
    } else {
      setIsNotFound(true);
    }
  };

  /**
   * Abre el video de tutorial
   * @param {*} videoLink link del video
   */
  const handleClickWatchVideo = (videoLink) => {
    window.open(videoLink, "_blank");
  };

  return (
    <Accordion className="accordion__help">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon fontSize="large" className="preview-modal__arrows" />
        }
      >
        <Grid container columnSpacing={1} className="col-start">
          <Grid item>
            <IconButton disableRipple className="accordion__help__icon-title">
              <img
                src={`/assets/helpResources/digitalTutorials.svg`}
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
          </Grid>
          <Grid item className="display-small__primary-one bold">
            {t("App.helpCenter.tutorialsTitle")}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="accordion__help__details full-width">
        <Grid container direction="column" rowSpacing={3}>
          <Grid
            item
            className="subheading__text-black-2"
            pr={{ xl: 10, lg: 10, sm: 2, xs: 1 }}
          >
            {t("App.helpCenter.tutorialsDesc")}
          </Grid>
          <Grid item mb={2}>
            <TextField
              placeholder={t("App.helpCenter.tutorialsSearch")}
              className="select-contact__filters__grey"
              onChange={handleSearch}
              InputProps={{
                autoComplete: "none",
                sx: {
                  height: "39px",
                  width: "100% !important",
                },
                startAdornment: (
                  <React.Fragment>
                    <SearchIcon sx={{ color: "#9CA3AF" }} fontSize="large" />
                  </React.Fragment>
                ),
              }}
            />
          </Grid>
          <Grid item maxHeight={320} pb={2} className="only-scroll-y">
            {!isNotFound ? (
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
                className="col-start"
              >
                {tutorialsList.map((tutorial, idx) => (
                  <Grid item xl={5.7} lg={5.7} sm={12} key={idx}>
                    <Card
                      sx={{ padding: "2%", minHeight: "235px", width: "100%" }}
                    >
                      <CardContent>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                          <Grid
                            item
                            xl={4}
                            lg={6}
                            sm={5}
                            xs={12}
                            className="accordion__help__tutorials-image"
                          >
                            <img
                              style={{
                                width: "185px",
                                height: "185px",
                              }}
                              src={`/assets/helpResources/${tutorial.icon}`}
                            />
                          </Grid>
                          <Grid item xl={6} lg={6} sm={6}>
                            <Grid container direction="column" rowSpacing={2}>
                              <Grid item className="display-small__text-black">
                                {tutorial.title}
                              </Grid>
                              <Grid
                                item
                                className="subheading__text-black-2"
                                sx={{
                                  height: "110px",
                                }}
                              >
                                {tutorial.content}
                              </Grid>
                              <Grid item>
                                <Button
                                  className="btn__outlined__clean__md"
                                  endIcon={
                                    <ChevronRightIcon fontSize="large" />
                                  }
                                  onClick={() => {
                                    handleClickWatchVideo(tutorial.link);
                                  }}
                                >
                                  {t("App.helpCenter.buttonWatch")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item className="col-flex">
                <div style={{ height: "50%" }}>
                  <NoMatchImage />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const CODES = {
  //Response app success_codes
  COD_RESPONSE_SUCCESS: 200,
  COD_RESPONSE_SUCCESS_REQUEST: 0,

  //Response app error codes
  COD_RESPONSE_ERROR_CREATE: 1001,
  COD_RESPONSE_ERROR_UPDATE: 1002,
  COD_RESPONSE_ERROR_DELETE: 1003,
  COD_RESPONSE_ERROR_LIST: 1004,
  COD_RESPONSE_ERROR_LOGIN: 1005,
  COD_RESPONSE_ERROR_UNAUTHORIZED: 1006,
  COD_RESPONSE_ERROR_SHOW: 1007,
  COD_INVALID_FILE: 1008,
  COD_RESPONSE_ERROR_RESET_PASSWORD: 1009,
  COD_RESPONSE_ERROR_VALIDATE: 1010,
  COD_RESPONSE_ERROR_UPLOAD_FILE: 1011,
  COD_RESPONSE_ERROR_GENERATE_FILE: 1012,
  COD_RESPONSE_ERROR_SEND_EMAIL: 1013,
  COD_RESPONSE_LOGIN_FIRST_TIME: 1014,
  COD_NO_INFO_FOUND: 1015,

  //HTTP Response codes
  COD_RESPONSE_HTTP_OK: 200,
  COD_RESPONSE_HTTP_CREATED: 201,
  COD_RESPONSE_HTTP_BAD_REQUEST: 400,
  COD_RESPONSE_HTTP_UNAUTHORIZED: 401,
  COD_RESPONSE_HTTP_FORBIDDEN: 403,
  COD_RESPONSE_HTTP_NOT_FOUND: 404,
  COD_RESPONSE_HTTP_ERROR: 500,

  //IDs TypeOFFile
  BUDGETS: 14,
  PRODUCTSREFERENCES: 13,
  CATALOG: 1,
  RATES: 19,
  SALESFORCE: 2,
  SALESPOINT: 3,
  GEOGRAPHY: 4,
  CHANNEL: 5,
  CATALOGCOMMERCE: 21,
  OBJECTIVES: 23,
  PENALTIES: 24,
};

import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Función de request para crear el mapeo de campos
 * @param {*} data información del mapeo
 * @returns respuesta sistema
 */
export async function CreateFieldMapping(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    url: `mappings/externals/files`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función de request para validar si se puede crear el mapeo de campos
 * @param {*} data información de mapeo
 * @returns respuesta sistema
 */
export async function ValidateCreateFieldMapping(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    url: `mappings/externals/files/validations`,
    baseURL: env.REACT_APP_API_URL,
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función de request para crear el mapeo de campos
 * @param {*} data información del mapeo
 * @returns respuesta sistema
 */
export async function EditFieldMapping(data) {
  const token = await GetToken();

  return axios({
    method: "PUT",
    url: `mappings/externals/files`,
    baseURL: env.REACT_APP_API_URL,
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

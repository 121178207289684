import { useTranslation } from "react-i18next";

const RequestState = ({ value }) => {
  const { t } = useTranslation();

  const transformValue = value ? value.toLowerCase() : "";
  let text = "";
  let textColor = "";

  if (transformValue === "finalizado") {
    text = t("App.applicationDeleteAndReload.states.finished");
    textColor = "state-colors__success";
  } else if (
    transformValue === "procesando" ||
    transformValue === "en proceso" ||
    transformValue === "encolado por recargar" ||
    transformValue === "pendiente envio"
  ) {
    text = t("App.applicationDeleteAndReload.states.inProcess");
    textColor = "state-colors__very_dark_grayish_blue";
  } else if (transformValue === "pendiente") {
    text = t("App.applicationDeleteAndReload.states.pending");
    textColor = "state-colors__tertiary-color-one";
  } else if (
    transformValue === "pendiente cancelado" ||
    transformValue === "cancelado"
  ) {
    text = t("App.applicationDeleteAndReload.states.cancelled");
    textColor = "state-colors__error";
  } else {
    text = t("App.applicationDeleteAndReload.states.error");
    textColor = "state-colors__error";
  }

  return (
    <p className={`${textColor} text-transform__capitalize word-break__keep`}>
      {text}
    </p>
  );
};

export default RequestState;

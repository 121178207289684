import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 *
 * Función para consumir el servicio que devuelve el listado de comercios por país
 *
 * @param {*} data
 * @returns resultado de la petición de axios (lista de comercios)
 */
export async function GetTradersService(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `companies/traders/countries/${data.country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Función para consumir el servicio que devuelve la lista de proveedores por país
 *
 * @param {*} data
 * @returns resultado de la petición de axios (lista de proveedores)
 */
export async function GetSuppliersService(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `companies/providers/countries/${data.country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function FilterLoadAndReloadService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `loadaudit/filter`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DeleteService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `requests/savedelete`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ReloadSaveService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `requests/savereload`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * RELOAD OF ALLIES
 */

/**
 * Servicio para crear una nueva solicitud de recarga de aliados
 * @param {*} data información de recarga de aliados
 * @returns promesa de recarga de aliados
 */
export async function ReloadAlliesService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `allies/reloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Servicio para filtrar las solicitudes de recarga de aliados
 * @param {*} data información de filtro de recarga de aliados
 * @returns promesa de filtro de recarga de aliados
 */
export async function ListAlliesRequestService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `allies/reloads/administrations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}
/**
 * Servicio para cancelar una solicitud de recarga de aliados
 * @param {*} data información de la solicitud a cancelar
 * @returns promesa de cancelar solicitud
 */
export async function CancelApplicationAllies(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `allies/reloads/cancellations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Servicio que trae la lista de aliados de la plataforma
 * @returns lista de aliados
 */
export async function GetAlliesList() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `allies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Servicio que consulta los comercios de un país y aliado seleccionado
 * @param {*} data país y aliado seleccionados
 * @returns comercios de país y aliado seleccionados
 */
export async function GetAlliesTraders(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `allies/countries/traders`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Servicio que consulta los proveedores de un país y aliado seleccionado
 * @param {*} data país y aliado seleccionados
 * @returns proveedores de país y aliado seleccionados
 */
export async function GetAlliesSuppliers(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `allies/countries/providers`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

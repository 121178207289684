import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { StackedLineChart } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";

export const ChannelsAccordion = ({ channelsInfo, isDesktop }) => {
  /**
   * Componentes auxiliares
   */
  const SelectionButton = ({ channel }) => {
    const isSelected = selectedChannel.id === channel.id;
    return (
      <Button
        disableRipple
        className={isSelected ? "btn__help__selected" : "btn__help"}
        onClick={() => {
          handleClick(channel.id);
        }}
      >
        <Grid container className="col-start" columnSpacing={1} minWidth={180}>
          <Grid item>
            <IconButton
              disableRipple
              className={isSelected ? "icon__help__selected" : "icon__help"}
            >
              <StackedLineChart />
            </IconButton>
          </Grid>
          <Grid item className="col-flex">
            {channel.name}
          </Grid>
        </Grid>
      </Button>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use State
   */

  const [selectedChannel, setSelectedChannel] = useState({
    id: "1",
    content: { value: "", value: [] },
  });

  //Responsive
  const [isMobile, setIsMobile] = useState(false);

  //Navegación mobile
  const [showRightNavigation, setShowRightNavigation] = useState(true);
  const [showLeftNavigation, setShowLeftNavigation] = useState(false);
  const [currentButtonIndex, setCurrentButtonIndex] = useState(0);

  /**
   * Use Effect
   */

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 420);
  };

  /**
   * useEffect que determina el tipo de vista
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Use effect que settea el primer canal de comunicación y cambia el componente si es mobile
   */
  useEffect(() => {
    //Carga los botones si es el caso de que sea mobile
    updateMedia();
    if (isMobile) handleShowNavigations(0);

    //Settea los canales
    if (channelsInfo.length > 0) setSelectedChannel(channelsInfo[0]);
  }, [channelsInfo]);

  /**
   * Handles
   */

  /**
   * Función que maneja la selección de canal de comunicación
   * @param {*} id
   */
  const handleClick = (id) => {
    const currentButton = channelsInfo.find((channel) => channel.id === id);
    setSelectedChannel(currentButton);
  };

  /**
   * Maneja la navegación de los botones de los canales hacia la derecha
   */
  const handleRightNavigation = () => {
    const nextIndex = currentButtonIndex + 1;

    if (nextIndex <= channelsInfo.length) {
      let newCurrentIndex = currentButtonIndex + 1;
      setCurrentButtonIndex(newCurrentIndex);
    }

    handleClick(nextIndex + 1 + "");
    handleShowNavigations(nextIndex);
  };

  /**
   * Maneja la navegación de botones de los canales a la izquierda
   */
  const handleLeftNavigation = () => {
    const nextIndex = currentButtonIndex - 1;

    if (nextIndex >= 0) {
      let newCurrentIndex = currentButtonIndex - 1;
      setCurrentButtonIndex(newCurrentIndex);
    }
    handleClick(nextIndex + 1 + "");
    handleShowNavigations(nextIndex);
  };

  /**
   * Función que settea si los botones de navegación deben visualizarle o no
   * @param {*} nextIndex el siguiente index a mostrar
   */
  const handleShowNavigations = (nextIndex) => {
    if (nextIndex === channelsInfo.length - 1) {
      setShowRightNavigation(false);
    } else {
      setShowRightNavigation(true);
    }

    if (nextIndex === 0) {
      setShowLeftNavigation(false);
    } else {
      setShowLeftNavigation(true);
    }
  };

  /**
   * Abre el link del portal PQRS
   */
  const handleOpenArandaPortal = () => {
    window.open(selectedChannel?.content?.value);
  };

  return (
    <Accordion className="accordion__help">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon fontSize="large" className="preview-modal__arrows" />
        }
      >
        <Grid container columnSpacing={1} className="col-start">
          <Grid item>
            <IconButton disableRipple className="accordion__help__icon-title">
              <img
                src={"/assets/helpResources/communicationChannels.svg"}
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
          </Grid>
          <Grid item className="display-small__primary-one bold">
            {t("App.helpCenter.channelsTitle")}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="accordion__help__details full-width">
        <Grid
          container
          direction="column"
          rowSpacing={3}
          sx={{ paddingLeft: !isDesktop ? "10px" : 0 }}
        >
          <Grid item>
            <Grid
              container
              columnSpacing={2}
              rowSpacing={2}
              justifyContent={{
                xl: "flex-start",
                lg: "flex-start",
                sm: "flex-start",
                xs: "center",
              }}
            >
              {isMobile && (
                <Grid item className="col-flex" lg={0.3} sm={0.5} xs={0.7}>
                  {showLeftNavigation && (
                    <IconButton disableRipple onClick={handleLeftNavigation}>
                      <ArrowBackIosIcon
                        className="icon__help__selected"
                        sx={{ fontSize: 12 }}
                      />
                    </IconButton>
                  )}
                </Grid>
              )}
              {isMobile &&
                channelsInfo.map(
                  (channel, index) =>
                    index === currentButtonIndex && (
                      <Grid item key={channel.id}>
                        <SelectionButton channel={channel} />
                      </Grid>
                    )
                )}

              {isMobile && (
                <Grid item className="col-flex" lg={0.3} sm={0.5} xs={0.7}>
                  {showRightNavigation && (
                    <IconButton disableRipple onClick={handleRightNavigation}>
                      <ArrowForwardIosIcon
                        className="icon__help__selected"
                        sx={{ fontSize: 12 }}
                      />
                    </IconButton>
                  )}
                </Grid>
              )}

              {!isMobile &&
                channelsInfo.map((channel) => (
                  <Grid item key={channel.id}>
                    <SelectionButton channel={channel} />
                  </Grid>
                ))}
            </Grid>
          </Grid>

          {/** Lineas telefónicas */}
          {selectedChannel.id === "2" && (
            <Grid item>
              <Grid
                container
                columnSpacing={{ xl: 1, lg: 2 }}
                rowSpacing={{ sm: 3, xs: 3 }}
              >
                {selectedChannel.content?.value?.map((countryLine, index) => (
                  <Grid item xl={3} lg={3.5} sm={12} xs={12} key={index}>
                    <Grid container direction="column" rowSpacing={1}>
                      <Grid item className="display-small__text-default">
                        <Grid container columnSpacing={1}>
                          <Grid item>
                            <img src={countryLine.flagUrl} alt="Flag" />
                          </Grid>
                          <Grid item>{countryLine.country}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container rowSpacing={1}>
                          {countryLine.linesArray.map((line, index) => (
                            <Grid item className="full-width" key={index}>
                              <Grid container>
                                <Grid
                                  item
                                  lg={countryLine.md}
                                  sm={2}
                                  xs={3.5}
                                  className="heading__text-default"
                                  sx={{
                                    fontWeight: "400 !important",
                                  }}
                                >
                                  {line.lineName}
                                </Grid>
                                <Grid
                                  item
                                  className="heading__text-grey"
                                  sx={{
                                    fontWeight: "400 !important",
                                  }}
                                >
                                  {line.lineNumber}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {/** Portal PRQS */}
          {selectedChannel.id === "1" && (
            <Grid item>
              <Grid container direction="column" rowSpacing={2}>
                <Grid item className="display-small__text-default">
                  {selectedChannel?.content?.key}
                </Grid>
                <Grid
                  item
                  sx={{
                    fontWeight: "400 !important",
                    cursor: "pointer",
                    textDecoration: "underline",
                    paddingBottom: !isDesktop ? "10px" : 0,
                  }}
                  className="heading__text-default"
                  onClick={handleOpenArandaPortal}
                >
                  Aranda Service Desk Web Edition
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";

export const DataTable = ({
  loading,
  data,
  columns,
  skeletonOptions,
  options,
}) => {
  return (
    <Grid item className="u-marginB" style={{ width: "100%" }}>
      {loading ? (
        <>
          <MUIDataTable
            className="dataTable__salesForce"
            data={data}
            columns={columns}
            options={skeletonOptions}
          />
        </>
      ) : (
        <MUIDataTable
          className="dataTable__salesForce"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </Grid>
  );
};

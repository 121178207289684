import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import ModalFailService from "../../../components/common/modalFailService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import FileState from "../../../components/common/fileState";
import FilterTraceExternal from "./filterTracerExternal";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import MUIDataTable from "mui-datatables";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ModalSeeTraceability from "../../../components/common/Modals/modalSeeTraceability";
import { DownloadFile } from "../../../services/user/tracebility/traceabilityService";
import { CODES } from "../../../consts/codes";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
const TraceabilityExternal = () => {
  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  //modal info
  const [modalTitle, setModalTitle] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  const userInfo = useSelector(getUser);
  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "uploadDate",
      label: t("App.traceabilityExternal.table.uploadDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.traceabilityExternal.table.uploadDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "trader",
      label: t("App.traceabilityExternal.partner"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "documentType",
      label: t("App.traceabilityExternal.docType"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "fileName",
      label: t("App.traceabilityExternal.table.uploadFile"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              label=""
              value={value}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadFile(value.fileName, value.pathFileName)
                  }
                >
                  {value.fileName}
                </a>
              }
            />
          );
        },
      },
    },
    {
      name: "state",
      label: t("App.traceabilityExternal.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container justifyContent="space-around" alignItems="center">
              <FileState value={value} />
            </Grid>
          );
        },
      },
    },
    {
      name: "logError",
      label: t("App.traceabilityExternal.table.logErrors"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              label=""
              value={value}
              control={
                value !== "Error" ? (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) => {
                      handleDownloadFile(
                        value.logFileName,
                        value.pathLogFileName
                      );
                    }}
                  >
                    {value.logFileName}
                  </a>
                ) : (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) => {
                      setModalTitle(
                        t("App.traceabilityExternal.modals.error.title")
                      );
                      setModalContent({
                        status: CODES.COD_RESPONSE_HTTP_ERROR,
                        data: {
                          responseMessage: t(
                            "App.traceabilityExternal.modals.error.message"
                          ),
                        },
                      });
                      setOpenErrorModal(true);
                    }}
                  >
                    ERROR
                  </a>
                )
              }
            />
          );
        },
      },
    },
    {
      name: "seeDetail",
      label: t("App.consultRoles.table.seeDetails"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const skeletonLine = [
    {
      uploadDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      trader: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      documentType: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      fileName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      logError: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      state: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      seeDetail: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  const columnsSkeleton = [
    {
      name: "uploadDate",
      label: t("App.traceabilityExternal.table.uploadDate"),
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.traceabilityExternal.table.uploadDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "trader",
      label: t("App.traceabilityExternal.partner"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "documentType",
      label: t("App.traceabilityExternal.docType"),
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "fileName",
      label: t("App.traceabilityExternal.table.uploadFile"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "state",
      label: t("App.traceabilityExternal.table.state"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "logError",
      label: t("App.traceabilityExternal.table.logErrors"),
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
    },

    {
      name: "seeDetail",
      label: t("App.consultRoles.table.seeDetails"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: false,
    search: false,
    searchOpen: false,
    filter: false,
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Skeleton para simular carga cuando se busca nueva información para la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: false,
    search: false,
    searchOpen: false,
    filter: false,
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  const [hasFilter, setHasFilter] = useState(false);
  const [hasFound, setHasFound] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [data, setData] = useState([]);
  //Estados para tabla
  const [open, setOpen] = useState(false);
  const [textState, setTextState] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalLogError, setOpenModalLogError] = useState(false);

  // Estado para el registro actual a editar / eliminar
  const [currentFile, setCurrentFile] = useState({
    pk: "",
    sk: "",
    eanProvider: "",
    traceabilityId: "",
    uploadDate: "",
    trader: "",
    documentType: "",
    fileName: "",
    state: "",
    logError: "",
  });

  /**
   * Establece el registro actual para abrir el modal
   * @param {*} file registro a ver
   */
  const handleOpenModal = (file) => {
    setCurrentFile(file);
    setOpen(true);
  };

  /**
   *
   * Función para descargar el archivo que fue cargado (subido)
   * Solo si el servicio responde correctamente se descarga el archivo
   * caso contrario no descargará nada
   *
   * @param {string} fileName: nombre del archivo
   * @param {string} pathFileName : ruta del archivo
   *
   */
  const handleDownloadFile = async (fileName, pathFileName = null) => {
    try {
      const obj = {
        fileName: fileName,
        pathFileName: pathFileName,
      };
      const {
        data: { data, responseCode },
        status,
      } = await DownloadFile(obj);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(decode(data.fileContent), data.fileName);
        } else {
          setModalTitle(t("App.validationMessages.error"));
          setModalContent({
            status: CODES.COD_RESPONSE_HTTP_ERROR,
            data: {
              responseMessage: t("App.validationMessages.systemError2"),
            },
          });
          setOpenErrorModal(true);
        }
      } else {
        setModalTitle(t("App.validationMessages.error"));
        setModalContent({
          status: CODES.COD_RESPONSE_HTTP_ERROR,
          data: {
            responseMessage: t("App.validationMessages.systemError2"),
          },
        });
        setOpenErrorModal(true);
      }
    } catch (error) {
      setModalTitle(t("App.validationMessages.error"));
      setModalContent({
        status: CODES.COD_RESPONSE_HTTP_ERROR,
        data: {
          responseMessage: t("App.validationMessages.systemError2"),
        },
      });
      setOpenErrorModal(true);
      console.log(" ---------- ERROR ----------");
      console.log(error);
      console.log("-----------------------------");
    }
  };
  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <h1 className="display-large__primary-one">
          {t("App.traceabilityExternal.title")}
        </h1>
      </Grid>

      <Grid
        container
        item
        className="companies_info_container full-width"
        direction="column"
        gap={2}
      >
        <Grid item container xs={12}>
          <Accordion
            className="accordion__filter full-width"
            defaultExpanded={true}
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterTraceExternal
                userInfo={userInfo}
                setHasContent={setHasContent}
                setHasFound={setHasFound}
                setHasFilter={setHasFilter}
                setData={setData}
                handleOpenModal={handleOpenModal}
                setOpenModalLogError={setOpenModalLogError}
                setIsLoading={setIsLoading}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid container item gap={1} className="full-width">
          {!hasFilter && (
            <Grid item sx={{ width: "100%" }}>
              <NoSelectionComponent
                type="dynamic"
                text={t("App.traceabilityExternal.noSelectionComponent")}
              />
            </Grid>
          )}

          {!hasFound && hasFilter && (
            <Grid item sx={{ width: "100%" }}>
              <NoMatchImage />
            </Grid>
          )}

          {hasFound && hasFilter && (
            <>
              <Grid container item justifyContent="flex-end" gap={2}></Grid>

              <MUIDataTable
                className="dataTable__salesForce datatable-style full-width"
                columns={isLoading ? columnsSkeleton : columns}
                options={isLoading ? skeletonOptions : options}
                data={isLoading ? skeletonLine : data}
              />
            </>
          )}
        </Grid>
      </Grid>

      <br />
      <ModalSeeTraceability
        userInfo={userInfo}
        currentFile={currentFile}
        open={open}
        setOpen={setOpen}
        setOpenErrorModal={setOpenErrorModal}
      />
      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={modalTitle}
        responseData={modalContent}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default TraceabilityExternal;

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  withStyles,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import {
  GetListCausales,
  GetGuideCausales,
} from "../../../services/user/adminPurchaseOrder/Causales";
import { CODES } from "../../../consts/codes";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const ChooseCausalComponent = ({
  state,
  setState,
  contentModal,
  isModalView,
  isGeneral,
  setIsGeneral,
  isProduct,
  setIsProduct,
  hasSelectedRows
}) => {

  const [open, setOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);
  const [causalOptions, setCausalOptions] = useState([]);

  /**
   * Handles para abrir o cerrar el modal
   */
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * useEffect que carga la lista de cuasales y verifica si el tamaño de la pantalla es mobile
   */
  useEffect(() => {
    if (userInfo) {
      getCausales();
    }
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 1000);
      setIsMobile(window.innerWidth < 400)
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userInfo]);

  /**
   * Funcion para obtener la lista de causales
   */
  const getCausales = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListCausales();
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          let newCausalOptions = [];
          if (t("App.adminPurchaseOrder.request") === "Solicitar") {
            newCausalOptions = responseMessage.map((item) => ({
              value: item.idCausal,
              label: item.causalName,
            }));
          } else {
            newCausalOptions = responseMessage.map((item) => ({
              value: item.idCausal,
              label: item.causalNameEnglish,
            }));
          }
          // Ordenar los datos alfabéticamente
          newCausalOptions.sort((a, b) => a.label.localeCompare(b.label));
          setCausalOptions(newCausalOptions);
        } else {
          setCausalOptions([]);
        }
      } else {
        setCausalOptions([]);
      }
    } catch (error) {
      console.error("Error fetching causales: ", error);
      setCausalOptions([]);
    }
  };

  /**
   * Función para descargar la guia de causales
   */
  const handleDownloadTemplate = async () => {
    try {
      const templateService = await GetGuideCausales();

      if (templateService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          templateService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(templateService.data.responseMessage.fileContent),
            templateService.data.responseMessage.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Comportamiento de los checkbox para saber si es general o producto
   * @param {*} type
   */
  const handleCheckboxChange = (type) => {
    if (type === "general") {
      setIsGeneral(isGeneral === "NO" ? "SI" : "NO");
      setIsProduct("NO");
    } else {
      setIsProduct(isProduct === "NO" ? "SI" : "NO");
      setIsGeneral("NO");
    }
  };

  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={isScreenSmall ? {} : { display: "flex", flexWrap: "nowrap" }}
        className={isScreenSmall ? "container-causal" : ""}
        mb={2}
        mt={2}
      >
        {isModalView && (
          <>
            <p className="state-colors__orange" style={{ marginLeft: "1rem" }}>
              <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', whiteSpace: 'nowrap'}}>{t("App.adminPurchaseOrder.seeDetailModal.causalBy")}:{" "}</Typography>
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isProduct === "SI"}
                  onChange={() => handleCheckboxChange("product")}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                />
              }
              label={<Typography style={{ fontSize: '1.2rem', fontWeight: 'bold'}}>{t("App.listUsers.product")}</Typography>}
              className="label__primary-one"
              style={{ marginLeft: "0.1rem"}}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGeneral === "SI"}
                  onChange={() => handleCheckboxChange("general")}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                />
              }
              label={<Typography style={{ fontSize: '1.2rem', fontWeight: 'bold'}}>{t("App.listUsers.general")}</Typography>}
              className="label__primary-one"
            />
          </>
        )}

        {(!isModalView || (isModalView && isGeneral === "SI")) && (
          <Grid container 
          alignItems="center" 
          spacing={1}
          style={isScreenSmall ? {marginTop:"2rem"} : {whiteSpace: 'nowrap'}}
          >
            {!isGeneral && (
              <Grid item ml={isMobile ? 1 : isScreenSmall ? 0 : 13} mb={isMobile ? 0 : isScreenSmall ? 3.5 : 0} >
                <IconButton
                  onClick={handleOpen}
                  style={{ padding: 0, marginRight: "5px" }}
                >
                  <HelpOutlineIcon style={{ color: "#553AB6", fontSize: "20px" }} />
                </IconButton>
              </Grid>
            )}
            {isGeneral && (
            <Grid item md={4.2} xs={0}>
            </Grid>)}
            {isGeneral && (
              <Grid item >
                <Tooltip
                  title={
                    <span style={{ textAlign: "left" }}>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProOne")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProTwo")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProThree")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProFour")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProFive")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProSix")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProSeven")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProEight")}</p>
                      <p>{t("App.adminPurchaseOrder.modalInfo.contentProNine")}</p>
                    </span>
                  }
                  placement={isScreenSmall ? "bottom" : "right"}
                  className="tooltip-causales"
                >
                    <ErrorOutlineOutlinedIcon style={{ color: "#553AB6" , fontSize: "22px"}} />
                </Tooltip>
              </Grid>
            )}
            <Grid item md={5} xs={8}>
              <Autocomplete
                noOptionsText={t("App.listUsers.noOptions")}
                className={isModalView ? "filters-causal-total" : "filters-causal"}
                options={causalOptions}
                disabled={!hasSelectedRows}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                renderInput={(params) => (
                  <TextField
                    label={t("App.adminPurchaseOrder.seeDetailModal.placeHolderCausal")}
                    sx={{
                      label: {
                        fontFamily: "Roboto !important",
                        fontSize: "14px !important",
                        translate: "0px -8px",
                        transformOrigin: "-45px -3px",
                        "&.Mui-focused": {
                          color: "#8C8D9D",
                        },
                      },
                      input: {
                        transform: "translateY(-2px)",
                      },
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { transform: "translateY(-2px)" },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                onChange={(event, value, reason) => {
                  setState(value);
                  if (reason === "clear") {
                    setState(null);
                  }
                }}
                value={state || null}
              />
            </Grid>
            {isGeneral && (
              <Grid item sm={2} xs={12}>
                  <div
                    className={isScreenSmall ? "col-end" : "col-start"}
                    style={isMobile ? {
                      alignItems: "center",
                      justifyContent: "flex-end",
                      paddingRight: "35px"
                    } : {
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <a
                      onClick={handleDownloadTemplate}
                      style={{
                        marginTop: "5px",
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                        whiteSpace: 'nowrap',
                        textAlign:"left"
                      }}
                    >
                      {t("App.adminPurchaseOrder.modalInfo.link")}
                    </a>
                  </div>

              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Dialog
        className="modal__notification"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <div style={{ padding: "2rem" }}>
          <DialogTitleContainer
            onClose={handleClose}
            className="heading-secondary__md"
          >
            <label>{t("App.adminPurchaseOrder.modalInfo.title")}</label>
          </DialogTitleContainer>

          <DialogContent>
            <p className="default-text_black" style={{display: "inline-block", fontSize: 18}}>
              {contentModal}
              <a
                onClick={handleDownloadTemplate}
                style={{
                  marginTop: "5px",
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                  whiteSpace: 'nowrap'
                }}
              >
                {t("App.adminPurchaseOrder.modalInfo.link")}
              </a>
            </p>
          </DialogContent>

          <DialogActions>
            <div>
              <Button
                variant="contained"
                className="btn__filled__blue"
                onClick={handleClose}
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ChooseCausalComponent;

import { React, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Grid, Skeleton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { FiltersListUser } from "../../components/common/filtersListUser";
import { useNavigate } from "react-router-dom";
import ModalCreateProduct from "../../components/common/modalCreateProduct";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompany } from "../../parts/listUsers/listUsersSlice";
import jwt_decode from "jwt-decode";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import { ModalEditProduct } from "../../components/common/modalEditProduct";
import { FiltersAdminProducts } from "../../components/common/filterAdminProducts";
import ModalCreateProductCommerce from "../../components/common/modalCreateProductCommerce";
import { ModalEditProductCommerce } from "../../components/common/modalEditProductCommerce";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { CODES } from "../../consts/codes";

export const ListUsers = () => {
  const { t } = useTranslation();

  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    role = jwtDecodeTokenInfo["custom:Role"];
  }

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtersInfo, setFiltersInfo] = useState([]);
  const [foundResults, setFoundResults] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const [isNavigateToUsers, setIsNavigateToUsers] = useState(false);
  const [objfilters, setObjfilters] = useState([]);
  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  // Error modal
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const columns = [
    {
      name: "country",
      label: t("App.listUsers.countryN"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("App.listUsers.company"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "product",
      label: t("App.listUsers.product"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contractedUsers",
      label: t("App.listUsers.contractedUsersN"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "activeUsers",
      label: t("App.listUsers.activeUsers"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "availableUsers",
      label: t("App.listUsers.availableUsers"),
      options: {
        filter: false,
        sort: false,
      },
    },
    // {
    //   name: "limitNumber",
    //   label: t("App.listUsers.limit"),
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: "status",
      label: t("App.editUser.status"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "edit",
      label: t("App.listUsers.edit"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value.trader ? (
            <ModalEditProductCommerce
              company={value.item}
              setDataStructure={setDataStructure}
              setData={setData}
              data={data}
              setFoundResults={setFoundResults}
              filters={filters}
              getSellersOrEanCommerces={getSellersOrEanCommerces}
            />
          ) : (
            <ModalEditProduct
              company={value.item}
              setDataStructure={setDataStructure}
              setData={setData}
              data={data}
              setFoundResults={setFoundResults}
              filters={filters}
              getSellersOrEanCommerces={getSellersOrEanCommerces}
            />
          ),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        // noMatch: t("App.validationMessages.noMatchFilter"),
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const navigate = useNavigate();

  /**
   * Función para redirigir a la página de los usuarios relacionados con la empresa
   */
  const navigateToUsers = () => {
    if (data) {
      let idCompany = "";
      let country = "";
      if (data.length > 0) {
        dispatch(
          setCurrentCompany({
            company: data[0],
            typeCompany: filters?.typeCompany,
          })
        );
        idCompany = data[0].eanCompany;
        country = data[0].country;
      } else {
        idCompany = filters.eanCompany;
        country = filters.country;
        dispatch(
          setCurrentCompany({
            company: {
              name: filters.nameCompany.companyName,
              country: filters.country,
              eanCompany: filters.eanCompany,
              typeCompany: filters?.typeCompany,
            },
          })
        );
      }

      if (role === "user") {
        navigate(`/user/listUsers/${country}/${idCompany}`);
      } else {
        navigate(`/admin/listUsers/${country}/${idCompany}`);
      }
    }
  };

  /**
   *
   * Función para estructurar la infomración de manera adecuada para mostrarlá en la tabla
   *
   * @param {Array} dataToStructure
   */
  const setDataStructure = (dataToStructure, trader = false) => {
    const ProcessRows = dataToStructure.map((item) => {
      return {
        country: !item.country ? " - " : item.country,
        name: !item.nameCompany ? " - " : item.nameCompany,
        product: !item.menuOptionName ? " - " : item.menuOptionName,
        contractedUsers: !item.contractedUsers ? " - " : item.contractedUsers,
        activeUsers: !item.activeUsers ? " - " : item.activeUsers,
        availableUsers: !item.availableUsers ? " - " : item.availableUsers,
        status: item.state ? (
          <p className="state-colors__primary-color-one">
            {t("App.userDetail.filter.active")}
          </p>
        ) : (
          <p className="state-colors__tertiary-color-one">
            {t("App.userDetail.filter.inactive")}
          </p>
        ),
        idOptionType: !item.idOptionType ? " - " : item.idOptionType,
        eanTraders: !item.eanTraders ? " - " : item.eanTraders,
        edit: { item: item, trader: trader },
        eanCompany: !item.eanCompany ? " - " : item.eanCompany,
      };
    });

    setFoundResults(true);
    setData(ProcessRows);
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todo' cuando se envía la data del formulario para crear o editar un producto
   *
   * @param {Array} sellersOrCommerces
   * @param {Number} idOptionType
   * @returns objeto con las lista de vendedores y/o eans de los comercios
   */
  const getSellersOrEanCommerces = (sellersOrCommerces, idOptionType) => {
    const eanTraders =
      idOptionType === 1
        ? sellersOrCommerces.filter((ean) =>
            ean.label
              ? ean.label !== t("App.downloadSalesForce.selectAll")
              : ean !== t("App.downloadSalesForce.selectAll")
          )
        : [];

    const eanTradersList = eanTraders.map((commerce) => commerce.value);

    const sellers =
      idOptionType === 4
        ? sellersOrCommerces.filter(
            (seller) => seller.label !== t("App.downloadSalesForce.selectAll")
          )
        : [];

    const sellerFormatObject = sellers.map((seller) => {
      return { salesMan: seller.label, salesManCode: seller.value };
    });

    return {
      eanTraders: eanTradersList[0] !== undefined ? eanTradersList : eanTraders,
      sellers: sellerFormatObject[0] !== undefined ? sellerFormatObject : [],
    };
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 912);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
      style={{ padding: "1rem" }}
    >
      <Grid item>
        <p className="display-large__primary-one">{t("App.listUsers.title")}</p>
      </Grid>

      <Grid item className="full-width">
        <Grid className="accordion__filter">
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />

            <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
              {t("App.listUsers.filter")}
            </p>
          </AccordionSummary>

          <AccordionDetails>
            <FiltersAdminProducts
              setOpenErrorModal={setOpenErrorModal}
              setFilters={setFilters}
              setFiltersInfo={setFiltersInfo}
              setObjfilters={setObjfilters}
              objfilters={objfilters}
              isNavigateToUsers={isNavigateToUsers}
              setData={setData}
              setFoundResults={setFoundResults}
              filters={filters}
              data={data}
              setDataStructure={setDataStructure}
              setFilterNotFound={setFilterNotFound}
              setIsLoadingData={setIsLoadingData}
            />
          </AccordionDetails>
        </Grid>
      </Grid>

      <Grid
        item
        container
        gap={2}
        // lg={12}
        direction="column"
      >
        {foundResults && (
          <>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
              className="full-width"
            >
              <Grid
                item
                xs={12}
                sm={3.5}
                lg={3.5}
                xl={2.5}
                className="text-center"
              >
                <p className="subheading__text-default">
                  {t("App.listUsers.textInfo")}
                </p>
              </Grid>

              <Grid item xs={12} sm={4} lg={2} xl={1.8} className="full-width">
                {filtersInfo?.typeCompany === "trader" ? (
                  <ModalCreateProductCommerce
                    data={data}
                    setData={setData}
                    filters={filters}
                    setDataStructure={setDataStructure}
                    getSellersOrEanCommerces={getSellersOrEanCommerces}
                  />
                ) : (
                  <ModalCreateProduct
                    data={data}
                    setData={setData}
                    filters={filters}
                    setDataStructure={setDataStructure}
                    getSellersOrEanCommerces={getSellersOrEanCommerces}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={3.5}
                lg={1.8}
                xl={1.5}
                className="full-width"
              >
                <Button
                  onClick={navigateToUsers}
                  className="btn__users full-width"
                >
                  {t("App.listUsers.goToUsersBut")}
                </Button>
              </Grid>
            </Grid>

            <Grid item className="full-width" lg={12}>
              {!filterNotFound ? (
                <MUIDataTable
                  className="dataTable__base"
                  data={data}
                  columns={columns}
                  options={isLoadingData ? skeletonOptions : options}
                />
              ) : (
                <NoMatchImage />
              )}
            </Grid>
          </>
        )}
        {/* Modal para fallo de servicios */}
        <ModalInfo
          title={t("App.validationMessages.error")}
          responseData={{
            status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
            data: {
              responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
              responseMessage: t("App.validationMessages.systemError2"),
            },
          }}
          open={openErrorModal}
          onClose={() => {
            setOpenErrorModal(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

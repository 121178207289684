import { Chip, Grid, Skeleton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterAdminBusinessRules from "../../../components/common/Filters/filterAdminBusinessRules";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import EditBusinessRule from "../../../components/common/BusinessRules/editBusinessRule";
import InfoIcon from "@mui/icons-material/Info";

const AdminBusinessRules = () => {
  const [data, setData] = useState([]);
  const [filterApply, setFilterApply] = useState({ country: "" });
  const [table, setTable] = useState("");
  const [noFilter, setNoFilter] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [noFound, setNoFound] = useState(false);

  /**
   * t de useTranstalation para las traducciones
   */
  const { t } = useTranslation();

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "eanCommerce",
      label: t("App.businessRules.table.eanCommerce"),
      options: { filter: false, sort: false, searchable: false },
    },
    {
      name: "commerce",
      label: t("App.businessRules.table.commerce"),
      options: { filter: false, sort: false, searchable: false },
    },
    {
      name: "modificationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column3"),
      options: {
        filter: false,
        searchable: false,
        hint: t("App.businessRules.table.tooltips.modificationDate"),
        customHeadLabelRender: () => {
          const modificationDate = (
            <div>
              <h4>
                {t("App.listDynamicPeriodicReport.table.columns.column3")}
              </h4>
              <h5 className="subheading-datatable">
                {t("App.homeDashboard.client.table.columns.formatDate")}
              </h5>
            </div>
          );

          return modificationDate;
        },
      },
    },
    {
      name: "modificationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column4"),
      options: {
        filter: false,
        sort: false,
        hint: t("App.businessRules.table.tooltips.modificationUser"),
        customHeadLabelRender: () => {
          const modificationUser = (
            <Grid item container alignItems="center" justifyContent="center">
              <h4>
                {t("App.listDynamicPeriodicReport.table.columns.column4")}
              </h4>
            </Grid>
          );

          return modificationUser;
        },
      },
    },
    {
      name: "ruleCode",
      label: t("App.businessRules.table.ruleCode"),
      options: { filter: false, searchable: false },
    },
    {
      name: "rule",
      label: t("App.businessRules.table.rule"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customHeadLabelRender: () => {
          const rule = (
            <Grid item container alignItems="center" justifyContent="center">
              <Grid item>
                <h4>{t("App.businessRules.table.rule")}</h4>
              </Grid>

              <Grid item>
                <Tooltip title={table}>
                  <InfoIcon />
                </Tooltip>
              </Grid>
            </Grid>
          );

          return rule;
        },
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: { filter: false, sort: false, searchable: false },
    },
  ];

  /**
   * Opciones de configuración para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.businessRules.table.searchable"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Opciones de skeleton para simular carga de información en la tabla cuando se realiza una nueva busqueda de los datos
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.businessRules.table.searchable"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * useEffect para generar la tabla para el tooltip de la columna de regla
   */
  useEffect(() => {
    const tableRuleTooltip = () => (
      <>
        <p className="display-small__primary-one text-left">
          {t("App.businessRules.table.tooltips.rule")}
        </p>

        <div className="border-radius-table">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th">
                  {t("App.businessRules.table.tooltips.tableTooltip.column1")}
                </th>
                <th className="th">
                  {t("App.businessRules.table.tooltips.tableTooltip.column2")}
                </th>
                <th className="th" style={{ borderRight: "0" }}>
                  {t("App.businessRules.table.tooltips.tableTooltip.column3")}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="td">1</td>
                <td className="td">{t("App.businessRules.add")}</td>
                <td>{t("App.businessRules.replace")}</td>
              </tr>

              <tr>
                <td className="td">2</td>
                <td className="td">{t("App.businessRules.replace")}</td>
                <td>{t("App.businessRules.add")}</td>
              </tr>

              <tr>
                <td className="td">3</td>
                <td className="td">{t("App.businessRules.replace")}</td>
                <td>{t("App.businessRules.replace")}</td>
              </tr>

              <tr>
                <td className="td">4</td>
                <td className="td">{t("App.businessRules.add")}</td>
                <td>{t("App.businessRules.add")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );

    setTable(tableRuleTooltip());
  }, []);

  /**
   *
   * Función para estructurar la información de manera adecuada para la tabla
   *
   * @param {Array} data
   */
  const setDataStructure = (data) => {
    if (data) {
      const processedRows = data.map((rule) => {
        return {
          country: rule.country,
          eanCommerce: rule.eanTrader,
          commerce: rule.traderName,
          modificationDate: rule.modificationDate,
          modificationUser: rule.modificationUser,
          ruleCode: rule.idBusinessRule,
          rule: rule.businnessRuleName,
          edit: (
            <EditBusinessRule
              rule={rule}
              setData={setData}
              dataArray={data}
              setDataStructure={setDataStructure}
              setIsLoadingData={setIsLoadingData}
              setFilterApply={setFilterApply}
              setNoFilter={setNoFilter}
            />
          ),
        };
      });

      setData(processedRows);
    }
  };

  return (
    <Grid>
      <Grid container="row">
        <Grid item style={{ marginTop: "2%" }}>
          <h1 className="display-large__primary-one">
            {t("App.businessRules.title")}
          </h1>
          <br /> <br />
        </Grid>
      </Grid>

      <Grid
        className="companies_info_container"
        container
        style={{ columnGap: "2%" }}
      >
        <Grid item className="full-width">
          <FilterAdminBusinessRules
            setData={setData}
            setDataStructure={setDataStructure}
            setIsLoadingData={setIsLoadingData}
            setFilterApply={setFilterApply}
            setNoFilter={setNoFilter}
            setNoFound={setNoFound}
          />
        </Grid>

        <Grid item container style={{ marginTop: "15px" }}>
          {filterApply.country && (
            <Grid item>
              <Chip
                className="chip__filter"
                label={`${t("App.listUsers.countryN")}: ${filterApply.country}`}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          spacing={1}
          style={{ marginTop: "2%" }}
        >
          <Grid item>
            <h3 className="heading__primary-one">
              {t("App.businessRules.subTitle")}
            </h3>
          </Grid>

          <Grid item>
            <Tooltip title={t("App.businessRules.tooltipText")}>
              <InfoIcon style={{ color: "#553AB6" }} />
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item style={{ marginTop: "10px" }} className="full-width">
          {!noFilter ? (
            !noFound ? (
              isLoadingData ? (
                <MUIDataTable
                  className="dataTable__salesForce datatable-style"
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                />
              ) : (
                <MUIDataTable
                  className="dataTable__salesForce datatable-style"
                  data={data}
                  columns={columns}
                  options={options}
                />
              )
            ) : (
              <NoMatchImage />
            )
          ) : (
            <NoSelectionComponent type="buesinessRules" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminBusinessRules;

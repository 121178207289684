import { Card, CardContent, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

export const BoardsSection = ({ t, requestInfo }) => {
  /**
   * Mock
   */

  const emptyBar = {
    labels: [],
    datasets: [
      {
        label: t("App.homeReception.boardSuccess"),
        data: [],
        backgroundColor: "#FCB045",
      },
      {
        label: t("App.homeReception.boardError"),
        data: [],
        backgroundColor: "#8D51DA",
      },
    ],
  };

  /**
   * Opciones para gráfica de barras
   */
  const optionsBarChart = {
    indexAxis: "x",
    plugins: {
      datalabels: {
        display: true,
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
      },
    },
    title: {
      display: false,
    },
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  /**
   * useState
   */

  const [processedDocumentsInfo, setProcessedDocumentsInfo] =
    useState(emptyBar);

  /**
   * Use Effect
   */

  useEffect(() => {
    handleSetQtyProcessed(requestInfo.quantityProcessedBar);
  }, [requestInfo]);
  /**
   * Handles
   */

  /**
   * Settea la información de la cantidad de documentos procesados con Éxito y error
   * @param {*} qtyProcessed obj que contiene información sobre los documentos procesados mes a mes
   */
  const handleSetQtyProcessed = (qtyProcessed) => {
    const qtyProcessedSuccess = qtyProcessed.success;
    const qtyProcessedError = qtyProcessed.error;
    const labels = qtyProcessed.labels;

    const newQty = {
      labels: labels,
      datasets: [
        {
          label: t("App.homeReception.boardSuccess"),
          data: qtyProcessedSuccess,
          backgroundColor: "#8D51DA",
        },
        {
          label: t("App.homeReception.boardError"),
          data: qtyProcessedError,
          backgroundColor: "#FCB045",
        },
      ],
    };

    setProcessedDocumentsInfo(newQty);
  };

  return (
    <Card className="border-radius ">
      <CardContent>
        <Grid container direction="column" rowSpacing={4} className="col-flex">
          <Grid item className="display-small__text-default text-center">
            {t("App.homeReception.titleBoard")}
          </Grid>
          <Grid item className="bar-width">
            <Bar options={optionsBarChart} data={processedDocumentsInfo} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../pages/userSlice";

import {
  fetchBusinessGroup,
  getIsBusinessGroupStatus,
  getLstCompanies,
} from "../../../../parts/businessGroup/businessGroupSlice";

const FilterBusinessGroupUniqueWB = ({
  title,
  setShowUpload,
  setCompanySelected,
}) => {
  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * t for translation
   */
  const { t } = useTranslation();

  /**
   * Use Selector
   */

  //Grupo empresarial
  const businessGroupStatus = useSelector(getIsBusinessGroupStatus);
  const lstCompanies = useSelector(getLstCompanies);

  //Info usuario
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  //Info de campos
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  /**
   * useForm
   */
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company: { eanCompany: "", nameCompany: "", renameCompany: "" },
      ean: { eanCompany: "" },
    },
  });

  /**
   * Función para actualizar tamaño de pantalla
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect para actualizar el tamaño de pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * useEffect para traer la información de grupo empresarial
   */

  useEffect(() => {
    try {
      switch (businessGroupStatus) {
        case "succeeded":
          setDataToFields();
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(
        "==============Error filterUnique.jsx fetchBusinessGroup ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, [businessGroupStatus, dispatch, userInfo]);

  /**
   * Opciones de autocompletes
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Settea la información de las companías sobre los campos de compania y eanCompany
   */
  const setDataToFields = () => {
    const companyName = lstCompanies.map((company) => {
      return {
        eanCompany: company.eanCompany,
        nameCompany: company.nameCompany,
        country: company.country,
        renameCompany: company.renameCompany,
      };
    });

    const eanCompany = lstCompanies.map((company) => {
      return {
        eanCompany: company.eanCompany,
      };
    });

    setCompanyList(companyName);
    setEanList(eanCompany);
  };

  /**
   *
   * Función que gestiona cambios sobre el filtro cuando el campo empresa cambia
   *
   * @param {Array} values compañia seleccionada
   * @param {String} reason tipo de acción del usuario
   */
  const handleChangeCompany = (values, reason) => {
    if (reason === "clear") {
      setValue("ean", { eanCompany: "" });
      setShowUpload(false);
    } else {
      setCompanySelected(values);
      setValue("ean", { eanCompany: values.eanCompany });
      setShowUpload(true);
    }
  };

  /**
   *
   * Función que gestiona cambios sobre el filtro cuando el campo ean cambia
   *
   * @param {Array} values ean seleccionado
   * @param {String} reason tipo de acción del usuario
   */
  const handleChangeEan = (values, reason) => {
    if (reason === "clear") {
      setShowUpload(false);
      setValue("company", {
        nameCompany: "",
        eanCompany: "",
        renameCompany: "",
      });
    } else {
      const findCompany = companyList.find(
        (company) => company.eanCompany === values.eanCompany
      );
      setCompanySelected(findCompany);
      setValue("company", findCompany);
      setShowUpload(true);
    }
  };

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      disableGutters
      style={{ borderRadius: "8px" }}
    >
      <Grid item>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <SearchIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {title}
          </p>
        </AccordionSummary>

        <AccordionDetails className="filter_company">
          <Grid container direction="row" alignItems="center">
            <Grid md={8} sm={12} xs={12} item>
              <form id="hook-form">
                <Grid
                  container
                  direction="row"
                  justifyContent={isDesktop ? "space-between" : "center"}
                  alignItems="center"
                >
                  <Grid item lg={5.5} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.company")}*
                        </label>
                      </Grid>

                      <Grid item className="full-width">
                        <Controller
                          name="company"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              filterOptions={filterOptions}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={`${
                                errors.company
                                  ? "select__filters__errors__full"
                                  : "select__filters__full"
                              } full-width`}
                              style={{ height: "auto" }}
                              options={companyList}
                              getOptionLabel={(option) => option.renameCompany}
                              isOptionEqualToValue={(option, value) => {
                                return option.eanCompany === value.eanCompany;
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.company")}
                                    {...params}
                                  />
                                );
                              }}
                              onChange={(event, values, reason, detail) => {
                                onChange(values);
                                handleChangeCompany(values, reason);
                              }}
                              value={value}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.ean")}
                        </label>
                      </Grid>

                      <Grid item className="full-width">
                        <Controller
                          name="ean"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={`${
                                errors.ean
                                  ? "select__filters__errors__full"
                                  : "select__filters__full"
                              }`}
                              style={{ height: "auto" }}
                              options={eanList}
                              getOptionLabel={(option) => option.eanCompany}
                              isOptionEqualToValue={(option, value) => {
                                return option.eanCompany === value.eanCompany;
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.ean")}
                                    {...params}
                                  />
                                );
                              }}
                              onChange={(event, values, reason, detail) => {
                                onChange(values);
                                handleChangeEan(values, reason);
                              }}
                              value={value}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Grid>
    </Accordion>
  );
};

export default FilterBusinessGroupUniqueWB;

import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import DownloadBox from "../../../../components/common/Boxes/downloadBox";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import FileState from "../../../../components/common/fileState";
import MUIDataTable from "mui-datatables";
import NoMatchImage from "../../../../components/common/NoMatchImage/noMatchImage";
import { NoInfoComponent } from "../../../../components/common/NoInfoImage/noInfoComponent";
import {
  DownloadCatalogueFile,
  DownloadCatalogueOnDownload,
} from "../../../../services/user/catalogueService";
import { CODES } from "../../../../consts/codes";
import LoopIcon from "@mui/icons-material/Loop";
import ModalInfo from "../../../../components/common/Modals/modalInfo";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import {
  DownloadTradersCatalog,
  GetTradersCatalogDownload,
} from "../../../../services/user/traders/catalogTradersService";
import { useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../../userSlice";

export const DownloadCatalogTraders = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Handles of table
   */

  /**
   *
   * Función para descargar el archivo de catálogo que fue cargado (subido)
   *
   * @param {String} fileName nombre del archivo
   * @param {String} pathFileName path del archivo
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    try {
      const obj = {
        pathFileName: pathFileName,
        fileName: fileName,
      };

      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await DownloadCatalogueFile(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        fileDownload(decode(data.fileContent), data.fileName);
      } else {
        setModalTitle(handleSetTitle(CODES.COD_RESPONSE_ERROR_RESET_PASSWORD));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalInfo(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "initialUploadDate",
      label: t("App.downloadCatalogue.downloadTable.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "finalUploadDate",
      label: t("App.downloadCatalogue.downloadTable.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.downloadCatalogue.downloadTable.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "file",
      label: t("App.downloadCatalogue.downloadTable.downloadFile"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: value.state === "FINALIZADO" ? "pointer" : "default",
                }}
                className={
                  value.state === "FINALIZADO"
                    ? "link__primary-two"
                    : "label__text-grey"
                }
                onClick={(event) => {
                  if (value.state === "FINALIZADO") {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <Skeleton />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * Use Selector
   */

  //Usuario
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  /**
   * Use State
   */

  //Si hay catálogo en la grilla
  const [isCatalogToDownload, setIsCatalogToDownload] = useState(true);

  //Carga petición de descarga
  const [isLoadingDownloadRequest, setIsLoadingDownloadRequest] =
    useState(false);

  //Carga de datos grilla
  const [isLoadingData, setIsLoadingData] = useState(false);

  //Datos de grilla
  const [data, setData] = useState([]);

  //Modal de info
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState({});

  /**
   * Use Effect
   */

  /**
   * Carga la tabla de descarga inicial
   */
  useEffect(() => {
    if (userInfo) fetchData();
  }, [userInfo]);

  /**
   * Handles
   */

  /**
   * Trae los datos de descarga de catálogo para la grilla de descarga
   */
  const fetchData = async () => {
    try {
      setIsLoadingData(true);
      setIsCatalogToDownload(true);
      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: 22,
      };
      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await GetTradersCatalogDownload(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        //Settea los datos de catálogos descarga en la tabla
        if (data.length > 0) {
          setData(setDataStructure(data));
        } else {
          setIsCatalogToDownload(false);
        }
      } else {
        setIsCatalogToDownload(false);
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
      console.log("====== error fetchData downloadCatalog.jsx =====");
      console.log(error);
      console.log("================================================");
    }
  };

  /**
   * Genera una nueva solicitud de descarga de catálogo
   */
  const handleDownloadCatalog = async () => {
    try {
      setIsLoadingDownloadRequest(true);

      const obj = {
        country: userInfo.company.country,
        eanTrader: userInfo.company.eanCompany,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        emailUser: userInfo.email,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
      };

      const {
        status,
        data: { responseMessage, responseCode },
      } = await DownloadTradersCatalog(obj);

      setIsLoadingDownloadRequest(false);

      //Levanto modal respuesta de la descarga
      setModalTitle(handleSetTitle(responseCode));
      setModalContent({ data: { responseMessage: responseMessage } });
      setIsOpenModalInfo(true);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        //Solo si se logra la descarga se actualiza la tabla
        await fetchData();
        setIsCatalogToDownload(true);
      }
    } catch (error) {
      setIsLoadingDownloadRequest(false);
      console.log(
        "====== error handleDownloadCatalog downloadCatalog.jsx ====="
      );
      console.log(error);
      console.log("================================================");
    }
  };

  /**
   * Refresca la tabla de solicitudes de descarga
   */
  const handleRefreshDownload = async () => {
    try {
      const previousData = data;
      setData([]);
      setIsLoadingData(true);
      setIsCatalogToDownload(true);

      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: 22,
      };
      const {
        status,
        data: { responseCode, responseMessage, data: responseData },
      } = await GetTradersCatalogDownload(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setData(setDataStructure(responseData));
      } else {
        setData(previousData);
        //Abrimos modal error general
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({ data: { responseMessage: responseMessage } });
        setIsOpenModalInfo(true);
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log("====== error refreshDownload downloadCatalog.jsx =====");
      console.log(error);
      console.log("================================================");
    }
  };

  /**
   * Aplica la estructura de tabla a los datos entrantes
   * @param {*} lstDownloads lista de solicitudes de descarga
   * @returns lista de solicitudes formateada
   */
  const setDataStructure = (lstDownloads) => {
    return lstDownloads.map((catalogue) => ({
      initialUploadDate: !catalogue.starDate ? " " : catalogue.starDate,
      finalUploadDate: !catalogue.endDate ? "  " : catalogue.endDate,
      user: !catalogue.userLoad ? "" : catalogue.userLoad,
      file: !catalogue.fileName
        ? ""
        : {
            fileName: catalogue.fileName,
            pathFileName: catalogue.pathFileName,
            state: !catalogue.state ? "" : catalogue.state,
          },
      state: !catalogue.state ? "" : catalogue.state,
    }));
  };

  /**
   * Settea el título de modal según el código de respuesta
   * @param {*} responseCode código de respuesta
   * @returns título del modal
   */
  const handleSetTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        //Descarga exitosa
        return t("App.downloadCatalogue.downloadModal.initDownload");
      case CODES.COD_RESPONSE_ERROR_UNAUTHORIZED:
        //Descarga pendiente
        return t("App.downloadCatalogue.downloadModal.errorDownload");
      case CODES.COD_RESPONSE_ERROR_LIST:
        //No catálogo
        return t("App.downloadCatalogue.downloadModal.noRegistry");
      default:
        //Error general
        return t("App.validationMessages.error");
    }
  };

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item className="full-width">
        <DownloadBox
          isLoading={isLoadingDownloadRequest}
          handleDownload={handleDownloadCatalog}
          description={t("App.downloadCatalogue.downloadBox.boxDescription")}
          buttonText={t("App.downloadCatalogue.downloadBox.boxButton")}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" columnSpacing={5}>
          <Grid item md={3} sm={4}>
            <Grid
              container
              direction="row"
              columnSpacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
              </Grid>
              <Grid item>
                <p className="display-small__primary-one">
                  {t("App.downloadSalesForce.subtitle")}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={9} sm={8}>
            {isCatalogToDownload && (
              <Grid
                container
                columnSpacing={1}
                rowSpacing={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item md={9} sm={8} xs={12}>
                  <p className="download-explanation">
                    {t("App.downloadCatalogue.updateBox.description")}
                  </p>
                </Grid>
                <Grid item md={2} sm={3} xs={12}>
                  <Grid container>
                    <Button
                      onClick={handleRefreshDownload}
                      disableRipple
                      disabled={isLoadingData ? true : false}
                      startIcon={!isLoadingData ? <LoopIcon /> : <></>}
                      className="btn__outlined__blue full-width"
                    >
                      {!isLoadingData ? (
                        t("App.downloadCatalogue.updateBox.button")
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="download-table full-width">
        {isCatalogToDownload ? (
          isLoadingData ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce"
            />
          ) : (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={options}
              className="dataTable__salesForce"
            />
          )
        ) : (
          <NoInfoComponent type="download" />
        )}
      </Grid>
      <ModalInfo
        title={modalTitle}
        responseData={modalContent}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
      />
    </Grid>
  );
};

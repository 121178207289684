import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function FilterContacts(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `contacts/filter`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}
export async function CreateContact(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `contacts`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

export async function UpdateContact(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    data: data,
    url: `contacts`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

export async function DeleteContact(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `contacts/inactivate`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

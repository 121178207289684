import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Grid } from "@mui/material";
import MenuOption from "../menuOption";
/**
 * Componente que renderiza las opciones de menú de nivel 2 con sus opciones hijas de nivel 3 si las tiene
 * Éstas opciones se deben agregar desde la sección de opciones de menú en la plataforma por parte del administrador
 *    -------------------------- PROPS ---------------------
 * selectedOption: opción de menú seleccionada
 * i18n: objeto con las traducciones
 * navigate: función que permite navegar a la ruta seleccionada
 * fullNameUser: nombre completo del usuario en sesión
 * emailUser: correo del usuario en sesión
 * excludeUrl: función que permite excluir una url de las opciones de menú
 * homeRoutes: rutas de inicio de la aplicación
 * setOpen: función que permite abrir o cerrar el menú para ver las opciones
 * openQlikSense: función que permite abrir Qlik Sense
 * idToken: token de autenticación
 * fetchTeamcore: función que permite iniciar sesión en Teamcore
 * role: rol del usuario en sesión
 * isHD: booleano que indica si la pantalla es HD o no
 */
const MenuOptionsLvl2Desktop = ({
  selectedOption,
  i18n,
  navigate,
  fullNameUser,
  emailUser,
  excludeUrl,
  homeRoutes,
  setOpen,
  openQlikSense,
  idToken,
  fetchTeamcore,
  role,
  overFlowLengthDescription,
  isHD,
}) => {
  // Función para modificar la altura del menú si la descripción
  // es muy larga
  const modifyHeight = (isOverFlow) => {
    let height = "";
    if (isOverFlow) {
      height = isHD ? "70rem" : "32.4rem";
    } else {
      height = isHD ? "74rem" : "36.8rem";
    }
    return height;
  };

  return (
    <Grid container>
      <Grid
        item
        height={modifyHeight(overFlowLengthDescription(selectedOption))}
        className="scroll-sidebar"
      >
        -
        {selectedOption.levels &&
          i18n &&
          selectedOption.levels.map((item, idx) => {
            if (item.levels && item.levels.length > 0) {
              //Renderiza lvl2 con hijos
              return (
                <Grid item key={idx} width="100%">
                  {/**
                   * Accordion para opciones NIVEL 2 con opciones hijas (nivel 3)
                   * */}
                  <Accordion className="accordion__menuParent__rounded">
                    <AccordionSummary
                      className="accordion__menuParent"
                      expandIcon={<ExpandMoreIcon sx={{ color: "#2E3043" }} />}
                    >
                      <Button
                        className="sideBar__button__options"
                        disableElevation={true}
                        disableRipple={true}
                        disableFocusRipple={true}
                      >
                        {i18n.language.includes("es")
                          ? item.menuOptionName
                          : item.nameEnglish}
                      </Button>
                    </AccordionSummary>
                    <AccordionDetails className="accordion__menuParent__content">
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        {/**
                         * Botones de NIVEL 3
                         */}
                        {item?.levels
                          ?.filter(
                            (item) => excludeUrl(item.url, homeRoutes) === false
                          )

                          .map((item, idx) => {
                            return (
                              <MenuOption
                                key={idx}
                                idx={idx}
                                item={item}
                                setOpen={setOpen}
                                navigate={navigate}
                                i18n={i18n}
                                fullNameUser={fullNameUser}
                                emailUser={emailUser}
                                openQlikSense={openQlikSense}
                                role={role}
                              />
                            );
                          })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            } else {
              return (
                <Grid item key={idx}>
                  {/**
                   * Botones NIVEL 2 SIN hijos
                   */}
                  {!item?.url?.includes("teamcore") ? (
                    excludeUrl(item.url, homeRoutes) == false ? (
                      <MenuOption
                        key={idx}
                        idx={idx}
                        item={item}
                        setOpen={setOpen}
                        navigate={navigate}
                        i18n={i18n}
                        fullNameUser={fullNameUser}
                        emailUser={emailUser}
                        openQlikSense={openQlikSense}
                        role={role}
                      />
                    ) : null
                  ) : (
                    <form
                      action="https://auth.teamcore.net/1/public/session"
                      method="POST"
                      id="teamcore-form"
                      target="_blank"
                    >
                      <Button
                        className="sideBar__button__options"
                        disableElevation={true}
                        disableRipple={true}
                        disableFocusRipple={true}
                        onClick={fetchTeamcore}
                      >
                        <input
                          type="hidden"
                          name="id_token"
                          id="id_token"
                          value={idToken}
                        />

                        {i18n.language.includes("es")
                          ? item.menuOptionName
                          : item.nameEnglish}
                      </Button>
                    </form>
                  )}
                </Grid>
              );
            }
          })}
      </Grid>
    </Grid>
  );
};

export default MenuOptionsLvl2Desktop;

import { Card, CardContent, Grid, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CardsSection = ({ t, requestInfo }) => {
  /**
   * Use State
   */

  const [lstUpdate, setLstUpdate] = useState("");
  const [cardSource, setCardSource] = useState("");
  const [documentsQty, setDocumentsQty] = useState("");
  const [emailsReceived, setEmailsReceived] = useState("");

  /**
   * Use Effect
   */

  useEffect(() => {
    handleSetGeneralInfo(requestInfo.generalInfo);
    handleSetEmailsInfo(requestInfo.generalInfo);
  }, [requestInfo]);

  /**
   * Handles
   */

  /**
   * Settea la información de la card de información general
   * @param {} generalInfo Información general
   */
  const handleSetGeneralInfo = (generalInfo) => {
    setLstUpdate(generalInfo.lstUpdate);
    setCardSource(generalInfo.source);
    setDocumentsQty(generalInfo.documentsQty);
  };

  /**
   * Settea la información de la card de correos recibidos
   * @param {*} generalInfo información general
   */
  const handleSetEmailsInfo = (generalInfo) => {
    setEmailsReceived(generalInfo.emailsReceived);
  };

  return (
    <Grid container direction="column" rowSpacing={1.5}>
      <Grid item>
        <Card className="border-radius">
          <CardContent>
            <Grid
              container
              direction="column"
              rowSpacing={1}
              textAlign={{ md: "end", sm: "center", xs: "center" }}
            >
              <Grid item>
                <Grid container rowSpacing={1} direction="column">
                  <Grid item className="display-large__primary-one">
                    {t("App.CENDashboard.billCENClient.table.columns.column2")}
                  </Grid>
                  <Grid item className="heading__text-default">
                    {lstUpdate}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="heading__text-default">
                {`${t(
                  "App.CENDashboard.billCENClient.table.columns.column1"
                )}: ${cardSource}`}
              </Grid>
              <Grid item className="heading__text-default">
                <Grid
                  container
                  columnSpacing={1}
                  justifyContent={{
                    md: "flex-end",
                    sm: "center",
                    xs: "center",
                  }}
                >
                  <Grid item>
                    {t("App.CENDashboard.billCENClient.table.columns.column3")}
                  </Grid>
                  <Grid item>
                    <Tooltip title={t("App.homeReception.cards.tooltipOne")}>
                      <InfoOutlinedIcon
                        sx={{ fontSize: "20px", color: "#543AB4" }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>: {documentsQty}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card className="border-radius">
          <CardContent>
            <Grid
              container
              direction="column"
              rowSpacing={2}
              className="col-flex card-docs"
            >
              <Grid item className="display-small__text-default text-center">
                {t("App.homeReception.cards.titleTwo")}
              </Grid>
              <Grid item className="display-small__primary-one text-center">
                {emailsReceived}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

import jwt_decode from "jwt-decode";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CODES } from "../../consts/codes";
import {
  ApplyFiltersProduct,
  GetActiveProduct,
  getCompanyByEan,
  GetRolesByProduct,
} from "../../services/admin/adminServices";

const infoToken = localStorage.getItem("infoToken");

let role = "";
// Validar si hay un token
if (infoToken) {
  const jwtDecodeTokenInfo = jwt_decode(infoToken);

  role = jwtDecodeTokenInfo["custom:Role"];
}

export const listUsersSlice = createSlice({
  name: "listUsers",
  initialState: {
    products: [],
    productsStatus: "fetch",
    error: null,
    productsAndRoles: [],
    productsAndRolesStatus: "fetch",
    productsAllAndRoles: [],
    productsAllAndRolesStatus: "fetch",
    errorRoles: null,
    currentCompany: {
      companyName: "",
      eanCompany: "",
      country: "",
      typeCompany: {
        value: "",
        label: "",
      },
    },
    currentCompanyStatus: "fetch",
    errorCompany: null,
    dataKeyFilters: {
      nameCompany: "",
      eanCompany: "",
      country: {
        country: "",
        countryName: "",
      },
      product: "",
      typeCompany: {
        value: "",
        label: "",
      },
    },
    dataKeyFiltersStatus: "fetch",
    errorDataKey: null,
  },
  reducers: {
    setCurrentCompany(state, action) {
      const { company, typeCompany } = action.payload;
      const modifiedCompany = {
        ...company,
        companyName: company.name,
        typeCompany: typeCompany,
      };
      state.currentCompany = modifiedCompany;
      state.currentCompanyStatus = "succeeded";
    },

    resetCurrentCompany(state, action) {
      state.currentCompany = {
        companyName: "",
        eanCompany: "",
        country: "",
        typeCompany: {},
      };
      state.currentCompanyStatus = "clean";
    },

    setDataKeyFilters(state, action) {
      const { datakey } = action.payload;
      state.dataKeyFilters = datakey;
      state.dataKeyFiltersStatus = "succeeded";
    },

    resetProductsAndRolesStatus(state, action) {
      state.productsAndRolesStatus = "fetch";
    },
    resetProductsAllAndRolesStatus(state, action) {
      state.productsAllAndRolesStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCompanyByEanCompany.pending, (state, action) => {
        state.currentCompanyStatus = "loading";
        state.dataKeyFiltersStatus = "loading";
      })
      .addCase(getCompanyByEanCompany.fulfilled, (state, action) => {
        state.productsStatus = "succeeded";
        const payload = action.payload;
        state.currentCompany = payload;
        const keyFilters = {
          country: payload?.country
            ? payload.country
            : {
                country: "",
                countryName: "",
              },
          nameCompany: payload?.companyName ? payload.companyName : "",
          eanCompany: payload?.eanCompany ? payload.eanCompany : "",
          product: "",
          typeCompany: payload?.typeCompany
            ? payload.typeCompany
            : {
                value: "",
                label: "",
              },
          navigateUserDetalis: false,
        };
        state.dataKeyFilters = keyFilters;
      })
      .addCase(getCompanyByEanCompany.rejected, (state, action) => {
        state.currentCompanyStatus = "failed";
        state.dataKeyFiltersStatus = "failed";
        state.errorDataKey = action.error.message;
        state.errorCompany = action.error.message;
      })
      .addCase(fetchProducts.pending, (state, action) => {
        state.productsStatus = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsStatus = "succeeded";
        // Add any fetched posts to the array
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.productsStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const getProducts = (state) => state.listUsers.products;

export const getProductsStatus = (state) => state.listUsers.productsStatus;

export const getProductsAndRoles = (state) => state.listUsers.productsAndRoles;

export const getProductsAndRolesStatus = (state) =>
  state.listUsers.productsAndRolesStatus;

export const getProductsAllAndRoles = (state) =>
  state.listUsers.productsAllAndRoles;

export const getProductsAllAndRolesStatus = (state) =>
  state.listUsers.productsAllAndRolesStatus;

export const getCurrentCompany = (state) => state.listUsers.currentCompany;

export const getCurrentCompanyStatus = (state) =>
  state.listUsers.currentCompanyStatus;

export const getDataKeyFilters = (state) => state.listUsers.dataKeyFilters;

export const getDataKeyFiltersStatus = (state) =>
  state.listUsers.dataKeyFiltersStatus;

export const {
  setCurrentCompany,
  resetProductsAndRolesStatus,
  resetProductsAllAndRolesStatus,
  setDataKeyFilters,
  resetCurrentCompany,
} = listUsersSlice.actions;

export const getCompanyByEanCompany = createAsyncThunk(
  "listUsers/currentCompany",
  async ({ eanCompany, country }) => {
    const getCompany = await getCompanyByEan({
      eanCompany: eanCompany,
      country: country,
    });

    if (getCompany.status === CODES.COD_RESPONSE_HTTP_OK) {
      if (
        getCompany.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
        getCompany.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        return getCompany.data.responseMessage;
      }
    }
  }
);

export const fetchProducts = createAsyncThunk(
  "listUsers/products",
  async ({ eanCompany, country }) => {
    const productsService = await GetActiveProduct({
      eanCompany: eanCompany,
      country: country,
    });
    if (productsService.status === CODES.COD_RESPONSE_HTTP_OK) {
      if (
        productsService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST ||
        productsService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        return productsService.data.responseMessage;
      }
    }
  }
);

export default listUsersSlice.reducer;

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WelcomeHome = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const { t } = useTranslation();

  return (
    <div className="container-welcome">
      <Grid container spacing={2.5} alignItems="center" justifyContent="center">
        <Grid item lg={6} xs={12}>
          <h1
            className={`${
              isDesktop ? "" : "text-center"
            } display-x-large__primary-one`}
          >
            {t("App.home.welcomeMessage")}
          </h1>

          <p className={`${isDesktop ? "" : "text-center"} label__text-grey`}>
            {t("App.home.text")}
          </p>
        </Grid>

        <Grid item lg={6} xs={12}>
          <img
            src="/assets/welcomeHome/Girl.svg"
            alt="Girl image"
            className="img-girl"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WelcomeHome;

import { FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

const ScopeReport = ({
  t,
  control,
  errors,
  register,
  getValues,
  setValue,
  mapScope,
  setMapScope,
  setMapScopeValue,
  mapScopeValue,
  type,
  isEdit = false,
}) => {
  // Opciones para el campo de comunidad carvajal
  const optionsCarvajal = [
    {
      value: 0,
      label: "TODOS",
    },
  ];
  // Opciones para el campo de tipo documento
  const optionsDocument = [
    { value: 0, label: "TODOS" },
    {
      value: 1,
      label: "Factura de Venta",
    },
    {
      value: 2,
      label: "Nota de Crédito",
    },
    {
      value: 3,
      label: "Factura de Contingencia",
    },
    {
      value: 4,
      label: "Nota Débito",
    },
    {
      value: 5,
      label: "Factura de Exportación",
    },
    {
      value: 6,
      label: "Factura de Contingencia DIAN",
    },
  ];

  // Opciones para el campo de tipo de impuesto
  const optionsTax = [
    { value: 0, label: "TODOS" },
    { value: 1, label: "IVA" },
    { value: 2, label: "INC Bolsas" },
    { value: 3, label: "ReteICA" },
    { value: 4, label: "IBUA" },
    { value: 5, label: "IC" },
    { value: 6, label: "INCarbono" },
    { value: 7, label: "IC Porcentual" },
    { value: 8, label: "ICUI" },
    { value: 9, label: "ICA" },
    { value: 10, label: "INCombustibles" },
    { value: 11, label: "FtoHorticultura" },
    { value: 12, label: "ADVC" },
    { value: 13, label: "INC" },
    { value: 14, label: "Sobretasa Combustibles" },
    { value: 15, label: "Timbre" },
    { value: 16, label: "Nombre de la figura tributaria" },
    {
      value: 17,
      label: "ReteIVA",
    },
    {
      value: 18,
      label: "Sordicom",
    },
    {
      value: 19,
      label: "ICL",
    },
    {
      value: 20,
      label: "ReteRenta",
    },
    {
      value: 21,
      label: "IC Datos",
    },
    {
      value: 22,
      label: "INPP",
    },
  ];

  // Opciones para el campo de tipo de referencia
  const optionsReference = [
    { value: 0, label: "TODOS" },
    { value: 1, label: "Orden de Compra" },
    { value: 2, label: "Aviso de Recibo" },
    { value: 3, label: "Remisión" },
    { value: 4, label: "RF2" },
    { value: 5, label: "RF3" },
    { value: 6, label: "RF4" },
  ];

  // Opciones para el campo de estado comercial
  const optionsCommercial = [
    {
      value: 0,
      label: "TODOS",
    },
    {
      value: 1,
      label: "Publicado",
    },
    {
      value: 2,
      label: "Acuse de Recibo",
    },
    {
      value: 3,
      label: "Aceptación Tácita",
    },
    {
      value: 4,
      label: "Enviado",
    },
    {
      value: 5,
      label: "Recibo Bienes/Servicio",
    },
    {
      value: 6,
      label: "Reclamo",
    },
    {
      value: 7,
      label: "Aceptación expresa",
    },
  ];

  // Opciones para el campo de moneda extranjera
  const optionsForeignCurrency = [
    {
      value: 0,
      label: "TODOS",
    },
    {
      value: 1,
      label: "EXT - Valor bruto antes de tributos",
    },
    {
      value: 2,
      label: "EXT - Campo para informar el total bruto",
    },
    {
      value: 3,
      label: "EXT - ImpOtroCop",
    },
    {
      value: 4,
      label: "EXT - Recargo en pesos",
    },
    {
      value: 5,
      label: "EXT - Retelca en pesos",
    },
    {
      value: 6,
      label: "EXT - Sub Total",
    },
    {
      value: 7,
      label: "EXT - Campo para informar el total IVA",
    },
    {
      value: 8,
      label: "EXT - MntImpCop",
    },
    {
      value: 9,
      label: "EXT - Valor a pagar en pesos",
    },
    {
      value: 10,
      label: "EXT - Total anticipos en pesos",
    },
    {
      value: 11,
      label: "EXT - Campo para informar el total de los descuentos",
    },
    {
      value: 12,
      label: "EXT - Campo para informar el total INC",
    },
    {
      value: 13,
      label: "EXT - Valor total a pagar",
    },
    {
      value: 14,
      label: "EXT - ReteFuente en pesos",
    },
    {
      value: 15,
      label: "EXT - Campo para informar el total de los recargos",
    },
    {
      value: 16,
      label: "EXT - TotBolCop",
    },
    {
      value: 17,
      label: "EXT - Descuento en pesos",
    },
    {
      value: 18,
      label: "EXT - ReteIva en pesos",
    },
  ];

  // Opciones de fecha de documento
  const optionsDocumentDate = [
    {
      value: 0,
      label: "TODOS",
    },
    {
      value: 1,
      label: "Fecha y Hora de Creacion",
    },
    {
      value: 2,
      label: "Fecha y Hora Firma",
    },
    {
      value: 3,
      label: "Fecha y Hora de Emision",
    },
    {
      value: 4,
      label: "Fecha de Vencimiento",
    },
  ];

  // Opciones de fecha de evento
  const optionsEventDate = [
    {
      value: 0,
      label: "TODOS",
    },
    {
      value: 1,
      label: "Fecha y Hora de Creacion",
    },
    {
      value: 2,
      label: "Fecha y Hora Firma",
    },
    {
      value: 3,
      label: "Fecha y Hora de Emision",
    },
    {
      value: 4,
      label: "Fecha de Vencimiento",
    },
  ];

  /**
   * Función para buscar el valor de la opción seleccionada
   * @param {*} value
   * @param {*} options
   * @returns
   */
  const findOptionValue = (value, options) => {
    return options.filter((option) => option.label === value)[0].value;
  };

  /**
   * Función para verificar si es emisión
   * poner en null la fecha del evento
   */
  useEffect(() => {
    if (type !== "receiver") {
      setMapScope((prevState) => ({
        ...prevState,
        dateEvent: null,
      }));
    }
  }, [type]);

  /**
   * Función para actualizar el valor del campo
   * @param {*} field nombre del campo a cambiar
   * @param {*} event evento
   * @param {*} options lista de opciones para campo
   */
  const changeValueField = (field, event, options) => {
    const value = event.target.value;
    // Actualizar el valor del campo en el estado
    setMapScope((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Obtener la opción seleccionada
    let getOption = options.filter((option) => option.value === value)[0];

    // Actualizar el valor del campo en el estado
    setMapScopeValue((prevState) => ({
      ...prevState,
      [field]: getOption.label,
    }));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Grid
          container
          direction="row"
          width="100%"
          justifyContent="flex-start"
          gap={2}
          paddingBottom={2}
        >
          {/* Comunidad Carvajal */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.listDynamicPeriodicReport.cen.create.scope.carvajal")}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) =>
                    changeValueField("carvajal", e, optionsCarvajal)
                  }
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(
                          mapScopeValue["carvajal"],
                          optionsCarvajal
                        )
                      : mapScope["carvajal"]
                  }
                  id="carvajal"
                  name="carvajal"
                  labelId="carvajal"
                >
                  {optionsCarvajal.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Tipo de documento */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.listDynamicPeriodicReport.cen.create.scope.document")}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) =>
                    changeValueField("docType", e, optionsDocument)
                  }
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(
                          mapScopeValue["docType"],
                          optionsDocument
                        )
                      : mapScope["docType"]
                  }
                  id="docType"
                  name="docType"
                  labelId="docType"
                >
                  {optionsDocument.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Tipo de impuesto */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.listDynamicPeriodicReport.cen.create.scope.tax")}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) => changeValueField("tax", e, optionsTax)}
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(mapScopeValue["tax"], optionsTax)
                      : mapScope["tax"]
                  }
                  id="tax"
                  name="tax"
                  labelId="tax"
                >
                  {optionsTax.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Tipo de referencia */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t(
                    "App.listDynamicPeriodicReport.cen.create.scope.reference"
                  )}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) =>
                    changeValueField("reference", e, optionsReference)
                  }
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(
                          mapScopeValue["reference"],
                          optionsReference
                        )
                      : mapScope["reference"]
                  }
                  id="tax"
                  name="tax"
                  labelId="tax"
                >
                  {optionsReference.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Estado comerical */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t(
                    "App.listDynamicPeriodicReport.cen.create.scope.commercial"
                  )}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) =>
                    changeValueField("commercial", e, optionsCommercial)
                  }
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(
                          mapScopeValue["commercial"],
                          optionsCommercial
                        )
                      : mapScope["commercial"]
                  }
                  id="commercial"
                  name="commercial"
                  labelId="commercial"
                >
                  {optionsCommercial.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Moneda Extranjera */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.listDynamicPeriodicReport.cen.create.scope.currency")}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) =>
                    changeValueField("currency", e, optionsForeignCurrency)
                  }
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(
                          mapScopeValue["currency"],
                          optionsForeignCurrency
                        )
                      : mapScope["currency"]
                  }
                  id="currency"
                  name="currency"
                  labelId="currency"
                >
                  {optionsForeignCurrency.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Fecha Documento */}
          <Grid
            item
            xl={3.915}
            lg={3.862}
            md={5.85}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.listDynamicPeriodicReport.cen.create.scope.dateDoc")}
                </label>
              </Grid>
              <Grid item>
                <Select
                  onChange={(e) =>
                    changeValueField("dateDoc", e, optionsDocumentDate)
                  }
                  className={"select-contact__filters"}
                  value={
                    isEdit
                      ? findOptionValue(
                          mapScopeValue["dateDoc"],
                          optionsDocumentDate
                        )
                      : mapScope["dateDoc"]
                  }
                  id="dateDoc"
                  name="dateDoc"
                  labelId="dateDoc"
                >
                  {optionsDocumentDate.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Fecha evento */}
          {type === "receiver" && (
            <Grid
              item
              xl={3.915}
              lg={3.862}
              md={5.85}
              sm={5.82}
              xs={12}
              width={"100%"}
            >
              <Grid container direction="column">
                <Grid item>
                  <label className="form__label label__text-grey">
                    {t(
                      "App.listDynamicPeriodicReport.cen.create.scope.dateEvent"
                    )}
                  </label>
                </Grid>
                <Grid item>
                  <Select
                    onChange={(e) =>
                      changeValueField("dateEvent", e, optionsEventDate)
                    }
                    className={"select-contact__filters"}
                    value={
                      isEdit
                        ? findOptionValue(
                            mapScopeValue["dateEvent"],
                            optionsEventDate
                          )
                        : mapScope["dateEvent"]
                    }
                    id="dateEvent"
                    name="dateEvent"
                    labelId="dateEvent"
                  >
                    {optionsEventDate.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScopeReport;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import FilterApplicationDeleteAndReload from "../../../components/common/Filters/filterApplicationDeleteAndReload";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { CODES } from "../../../consts/codes";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { DownloadLogs } from "../../../services/user/deleteAndReload/applicationDeleteAndReload";
import RequestState from "../../../components/common/requestState";
import MultiBuzonInfo from "../../../components/common/multiBuzónInfo";

const ApplicationDeleteAndReload = () => {
  const navigate = useNavigate();

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   *
   * Function to download the log of the error
   *
   * @param {String} fileName
   * @param {String} path
   */
  const handleDownloadLog = async (fileName, path) => {
    const obj = {
      pathLogFileName: path,
      logFileName: fileName,
    };

    try {
      const fileService = await DownloadLogs(obj);

      if (
        fileService.status === CODES.COD_RESPONSE_HTTP_OK &&
        (fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST)
      ) {
        fileDownload(
          decode(fileService.data.responseMessage.fileContent),
          `${fileService.data.responseMessage.fileName}`
        );
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Configurations for the columns of the table
   */
  const columns = [
    {
      name: "eanProviderMain",
      label: "EAN Provider Main",
      options: {
        display: false,  // Esto hace que la columna sea invisible en la tabla
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "nameProviderMain",
      label: "Name Provider Main",
      options: {
        display: false,  // También ocultamos esta columna
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "eanProvider",
      label: "eanProvider",
      options: {
        display: false,  // También ocultamos esta columna
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "applicationId",
      label: t("App.applicationDeleteAndReload.table.applicationId"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "applicationDate",
      label: t("App.applicationDeleteAndReload.table.applicationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "initialDate",
      label: t("App.applicationDeleteAndReload.table.initialDate"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "finalDate",
      label: t("App.applicationDeleteAndReload.table.finalDate"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "user",
      label: t("App.applicationDeleteAndReload.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },

    {
      name: "applicationType",
      label: t("App.applicationDeleteAndReload.table.applicationType"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "documentType",
      label: t("App.applicationDeleteAndReload.table.documentType"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "channel",
      label: t("App.applicationDeleteAndReload.table.font"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "commerce",
      label: t("App.applicationDeleteAndReload.table.partner"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "supplier",
      label: t("App.applicationDeleteAndReload.table.supplier"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const eanProviderMain = rowData[0]; // Ajusta este índice según tu estructura de datos
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{value}</span>
              {eanProviderMain !== null && (
                <MultiBuzonInfo
                  data={[
                    { company: rowData[1], eanMain: rowData[0], eanSecondary: rowData[2] },
                  ]}
                />
              )}
            </div>
          );
        }
      },
    },
    {
      name: "state",
      label: t("App.applicationDeleteAndReload.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value) => <RequestState value={value} />,
      },
    },
    {
      name: "errorDetail",
      label: t("App.applicationDeleteAndReload.table.errorDetail"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: "pointer",
                }}
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  if (value.state === "ERROR") {
                    handleDownloadLog(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.state === "ERROR"
                  ? `${value.idRequest}_${value.fileName}`
                  : ""}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "cancel",
      label: t("App.applicationDeleteAndReload.table.cancel"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  /**
   * Configurations for the table
   */
  const options = {
    download: false,
    print: "false",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.applicationDeleteAndReload.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Configurations of skeleton
   */
  const optionsSkeleton = {
    download: false,
    print: "false",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.applicationDeleteAndReload.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [hasFiltersTriggered, setHasFiltersTriggered] = useState(false);
  const [noFound, setNoFound] = useState(false);
  const [filters, setFilters] = useState({});
  const [loadingFilters, setLoadingFilters] = useState(false);

  // console.log(filters);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <p className="display-large__primary-one">
          {t("App.applicationDeleteAndReload.title")}
        </p>
      </Grid>

      <Grid
        item
        container
        spacing={1}
        alignItems="center"
        style={{ marginBottom: "20px" }}
      >
        <Grid item>
          <IconButton
            className="btn__back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <p className="subheading__blue3">
            {t("App.applicationDeleteAndReload.text")}
          </p>
        </Grid>
      </Grid>

      <Grid
        item
        container
        className="full-width background-colors__white border-radius__default"
        style={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Accordion
            className="accordion__filter"
            expanded={true}
            style={{ width: "98%" }}
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ cursor: "auto !important" }}
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterApplicationDeleteAndReload
                data={data}
                setData={setData}
                unfilteredData={unfilteredData}
                setHasFiltersTriggered={setHasFiltersTriggered}
                setFilters={setFilters}
                setNoFound={setNoFound}
                // requestType={"recarga"}
                setLoadingFilters={setLoadingFilters}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {hasFiltersTriggered && (
          <>
            <Grid item style={{ margin: "3% 0% 3% 0%" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Chip
                    label={`${t("App.userDetail.country")}: ${
                      filters.country ? filters.country : "-"
                    } `}
                    variant="outlined"
                  />
                </Grid>

                <Grid item>
                  <Chip
                    label={`${t(
                      "App.applicationDeleteAndReload.filters.applicationInitialDate"
                    ).replace("*", "")}: ${
                      filters.requestStartDate ? filters.requestStartDate : "-"
                    } `}
                    variant="outlined"
                  />
                </Grid>

                <Grid item>
                  <Chip
                    label={`${t(
                      "App.applicationDeleteAndReload.filters.applicationFinalDate"
                    ).replace("*", "")}: ${
                      filters.requestEndDate ? filters.requestEndDate : "-"
                    } `}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid item style={{ marginBottom: "10px", width: "98%" }}>
          {hasFiltersTriggered ? (
            !noFound ? (
              loadingFilters ? (
                <MUIDataTable
                  className="dataTable__base"
                  data={data}
                  columns={columns}
                  options={optionsSkeleton}
                />
              ) : (
                <MUIDataTable
                  className="dataTable__base"
                  data={data}
                  columns={columns}
                  options={options}
                />
              )
            ) : (
              <NoMatchImage />
            )
          ) : (
            <NoSelectionComponent type="deleteAndReloadRequest" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApplicationDeleteAndReload;

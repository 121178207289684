import { Grid, IconButton } from "@mui/material";

export const NavigationCard = ({
  title,
  icon,
  iconColor,
  padding = "10%",
  handleClick,
}) => {
  return (
    <Grid
      container
      columnSpacing={{ md: 2, sm: 2, xs: 2 }}
      justifyContent={"flex-start"}
      alignItems={"center"}
      style={{ paddingLeft: "0", cursor: "pointer" }}
      className="grid__container__filled__soft-rounded container-home-buttons"
      onClick={handleClick}
    >
      <Grid item md={2}>
        <IconButton
          disableRipple
          disabled
          style={{
            backgroundColor: iconColor,
            borderRadius: "4.8px",
            padding: padding,
          }}
        >
          {icon}
        </IconButton>
      </Grid>
      <Grid item className="display-small__primary-one">
        {title}
      </Grid>
    </Grid>
  );
};

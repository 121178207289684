import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";
import {
  GetEanPointSales,
  GetPointSales,
  GetTraders,
  GetFormats,
  GetDepartments,
  GetChannels,
  GetCities,
  GetCategories,
  GetProducts,
  GetSalesForceLevelOne,
  GetBrands,
  GetColors,
  GetSizes,
  GetSalesMan,
} from "../../services/user/dynamicReport/dynamicReportServices";

export const dynamicReportSlice = createSlice({
  name: "dynamicReport",
  initialState: {
    traders: [],
    tradersSelected: [],
    tradersStatus: "fetch",

    eanPointSale: [],
    eanPointSaleSelected: [],
    eanPointSalesStatus: "fetch",

    pointSales: [],
    pointSalesSelected: [],
    pointSalesStatus: "fetch",

    formats: [],
    formatsSelected: [],
    formatStatus: "fetch",

    departments: [],
    departmentsSelected: [],
    departmentsStatus: "fetch",

    cities: [],
    citiesSelected: [],
    citiesStatus: "fetch",

    channels: [],
    channelsSelected: [],
    channelsStatus: "fetch",

    categories: [],
    categoriesSelected: [],
    categoriesStatus: "fetch",

    products: [],
    productsSelected: [],
    productsStatus: "fetch",

    salesForceLevelOne: [],
    salesForceLevelOneSelected: [],
    salesForceLevelOneStatus: "fetch",

    brands: [],
    brandsSelected: [],
    brandsStatus: "fetch",

    colors: [],
    colorsSelected: [],
    colorsStatus: "fetch",

    sizes: [],
    sizesSelected: [],
    sizesStatus: "fetch",

    salesMan: [],
    salesManSelected: [],
    salesManStatus: "fetch",

    error: null,
  },
  reducers: {
    resetTradersLoadState(state, action) {
      state.tradersStatus = "fetch";
    },
    resetEanPointSaleLoadState(state, action) {
      state.eanPointSalesStatus = "fetch";
    },
    resetPointSalesLoadState(state, action) {
      state.pointSalesStatus = "fetch";
    },
    resetFormatsLoadState(state, action) {
      state.formatStatus = "fetch";
    },
    resetDepartmentsLoadState(state, action) {
      state.departmentsStatus = "fetch";
    },
    resetCitiesLoadState(state, action) {
      state.citiesStatus = "fetch";
    },
    resetChannelsLoadState(state, action) {
      state.channelsStatus = "fetch";
    },
    resetCategoriesLoadState(state, action) {
      state.categoriesStatus = "fetch";
    },
    resetProductsLoadState(state, action) {
      state.productsStatus = "fetch";
    },
    resetSalesForceLevelOneLoadState(state, action) {
      state.salesForceLevelOneStatus = "fetch";
    },
    resetBrandsLoadState(state, action) {
      state.brandsStatus = "fetch";
    },
    resetColorsSaleLoadState(state, action) {
      state.colorsStatus = "fetch";
    },
    resetSizesLoadState(state, action) {
      state.sizesStatus = "fetch";
    },
    resetSalesManStatusState(state, action) {
      state.salesManStatus = "fetch";
    },
    resetSelectedes(state, action) {
      state.tradersSelected = [];
      state.tradersStatus = "clear";
      state.eanPointSaleSelected = [];
      state.eanPointSalesStatus = "clear";
      state.pointSalesSelected = [];
      state.pointSalesStatus = "clear";
      state.formatsSelected = [];
      state.formatStatus = "clear";
      state.departmentsSelected = [];
      state.departmentsStatus = "clear";
      state.citiesSelected = [];
      state.citiesStatus = "clear";
      state.channelsSelected = [];
      state.channelsStatus = "clear";
      state.categoriesSelected = [];
      state.categoriesStatus = "clear";
      state.productsSelected = [];
      state.productsStatus = "clear";
      state.salesForceLevelOneSelected = [];
      state.salesForceLevelOneStatus = "clear";
      state.brandsSelected = [];
      state.brandsStatus = "clear";
      state.colorsSelected = [];
      state.colorsStatus = "clear";
      state.sizesSelected = [];
      state.sizesStatus = "clear";
      state.salesManSelected = [];
      state.salesManStatus = "clear";
    },
    setSelectedTrader(state, action) {
      let addedTraders = action.payload;
      let final = addedTraders.map((item) => {
        return { label: item.label, ean: item.ean };
      });

      if (addedTraders.length === 0) {
        state.tradersSelected = [];
      } else {
        state.tradersSelected = final;
      }
    },
    setSelectedEanPointSale(state, action) {
      let addedEanPointSale = action.payload;
      let final = addedEanPointSale.map((item) => {
        return item.label;
      });
      if (addedEanPointSale.length === 0) {
        state.eanPointSaleSelected = [];
      } else {
        state.eanPointSaleSelected = final;
      }
    },
    setSelectedPointSale(state, action) {
      let addedPointSale = action.payload;
      let final = addedPointSale.map((item) => {
        return { label: item.label, ean: item.ean };
      });
      if (addedPointSale.length === 0) {
        state.pointSalesSelected = [];
      } else {
        state.pointSalesSelected = final;
      }
    },
    setSelectedFormats(state, action) {
      let addedFormats = action.payload;
      let final = addedFormats.map((item) => {
        return item.label;
      });
      if (addedFormats.length === 0) {
        state.formatsSelected = [];
      } else {
        state.formatsSelected = final;
      }
    },
    setSelectedDepartments(state, action) {
      let addedDepartments = action.payload;

      let final = addedDepartments.map((item) => {
        return { label: item.label, ean: item.ean };
      });
      if (addedDepartments.length === 0) {
        state.departmentsSelected = [];
      } else {
        state.departmentsSelected = final;
      }
    },
    setSelectedCities(state, action) {
      let addedCities = action.payload;

      let final = addedCities.map((item) => {
        return { label: item.label, ean: item.ean };
      });
      if (addedCities.length === 0) {
        state.citiesSelected = [];
      } else {
        state.citiesSelected = final;
      }
    },
    setSelectedChannel(state, action) {
      let addedChannels = action.payload;

      let final = addedChannels.map((item) => {
        return item.label;
      });
      if (addedChannels.length === 0) {
        state.channelsSelected = [];
      } else {
        state.channelsSelected = final;
      }
    },
    setSelectedCategories(state, action) {
      let addedCategories = action.payload;

      let final = addedCategories.map((item) => {
        return item.label;
      });
      if (addedCategories.length === 0) {
        state.categoriesSelected = [];
      } else {
        state.categoriesSelected = final;
      }
    },
    setSelectedProducts(state, action) {
      let addedProducts = action.payload;

      let final = addedProducts.map((item) => {
        return { label: item.label, ean: item.ean };
      });
      if (addedProducts.length === 0) {
        state.productsSelected = [];
      } else {
        state.productsSelected = final;
      }
    },

    setSelectedSalesForceLevelOne(state, action) {
      let addedSales = action.payload;

      let final = addedSales.map((item) => {
        return item.label;
      });
      if (addedSales.length === 0) {
        state.salesForceLevelOneSelected = [];
      } else {
        state.salesForceLevelOneSelected = final;
      }
    },
    setSelectedBrands(state, action) {
      let brands = action.payload;

      let final = brands.map((item) => {
        return item.label;
      });
      if (brands.length === 0) {
        state.brandsSelected = [];
      } else {
        state.brandsSelected = final;
      }
    },
    setSelectedColors(state, action) {
      let colors = action.payload;

      let final = colors.map((item) => {
        return item.label;
      });
      if (colors.length === 0) {
        state.colorsSelected = [];
      } else {
        state.colorsSelected = final;
      }
    },
    setSelectedSizes(state, action) {
      let sizes = action.payload;

      let final = sizes.map((item) => {
        return item.label;
      });
      if (sizes.length === 0) {
        state.sizesSelected = [];
      } else {
        state.sizesSelected = final;
      }
    },

    setSelectedSalesMan(state, action) {
      let salesMan = action.payload;

      let final = salesMan.map((item) => {
        return { label: item.label, ean: item.ean };
      });
      if (salesMan.length === 0) {
        state.salesManSelected = [];
      } else {
        state.salesManSelected = final;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTraders.fulfilled, (state, action) => {
        state.traders = action.payload;
        state.tradersStatus = "succeeded";
      })
      .addCase(fetchEanPointSale.fulfilled, (state, action) => {
        state.eanPointSale = action.payload;
        state.eanPointSalesStatus = "succeeded";
      })
      .addCase(fetchPointSales.fulfilled, (state, action) => {
        state.pointSales = action.payload;
        state.pointSalesStatus = "succeeded";
      })
      .addCase(fetchFormats.fulfilled, (state, action) => {
        state.formats = action.payload;
        state.formatStatus = "succeeded";
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.departments = action.payload;
        state.departmentsStatus = "succeeded";
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.channels = action.payload;
        state.channelsStatus = "succeeded";
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.citiesStatus = "succeeded";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.categoriesStatus = "succeeded";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.productsStatus = "succeeded";
      })
      .addCase(fetchSalesForce.fulfilled, (state, action) => {
        state.salesForceLevelOne = action.payload;
        state.salesForceLevelOneStatus = "succeeded";
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.brands = action.payload;
        state.brandsStatus = "succeeded";
      })
      .addCase(fetchColors.fulfilled, (state, action) => {
        state.colors = action.payload;
        state.colorsStatus = "succeeded";
      })
      .addCase(fetchSizes.fulfilled, (state, action) => {
        state.sizes = action.payload;
        state.sizesStatus = "succeeded";
      })
      .addCase(fetchSalesMan.fulfilled, (state, action) => {
        state.salesMan = action.payload;
        state.salesManStatus = "succeeded";
      });
  },
});

//Actions
export const {
  setSelectedTrader,
  setSelectedEanPointSale,
  setSelectedPointSale,
  setSelectedFormats,
  setSelectedDepartments,
  setSelectedChannel,
  setSelectedCities,
  setSelectedCategories,
  setSelectedProducts,
  setSelectedSalesForceLevelOne,
  setSelectedBrands,
  setSelectedColors,
  setSelectedSizes,
  setSelectedSalesMan,
  resetTradersLoadState,
  resetEanPointSaleLoadState,
  resetPointSalesLoadState,
  resetFormatsLoadState,
  resetDepartmentsLoadState,
  resetCitiesLoadState,
  resetChannelsLoadState,
  resetCategoriesLoadState,
  resetProductsLoadState,
  resetSalesForceLevelOneLoadState,
  resetBrandsLoadState,
  resetColorsSaleLoadState,
  resetSizesLoadState,
  resetSalesManStatusState,
  resetSelectedes,
} = dynamicReportSlice.actions;

//Traders
export const getTraders = (state) => state.dynamicReport.traders;

export const getTradersSelected = (state) =>
  state.dynamicReport.tradersSelected;

export const getTradersLoadStatus = (state) =>
  state.dynamicReport.tradersStatus;
export const fetchTraders = createAsyncThunk(
  "filters/dynamicReport/fetchTraders",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansPointSales: object.lstEansPointSales,
      lstPointSales: object.lstPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstChannels: object.lstChannels,
      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestTraders = await GetTraders(obj);
    if (requestTraders.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestTraders.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestTraders.data.responseMessage;
      }
    }
  }
);

//Ean Point Sales

export const getEanPointSale = (state) => state.dynamicReport.eanPointSale;
export const getEanPointSaleSelected = (state) =>
  state.dynamicReport.eanPointSaleSelected;
export const getEanPointSaleLoadStatus = (state) =>
  state.dynamicReport.eanPointSalesStatus;

export const fetchEanPointSale = createAsyncThunk(
  "filters/dynamicReport/fetchEanPointSale",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstChannels: object.lstChannels,
      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestEanPointSales = await GetEanPointSales(obj);
    if (requestEanPointSales.status === CODES.COD_RESPONSE_SUCCESS) {
      if (
        requestEanPointSales.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        return requestEanPointSales.data.responseMessage;
      }
    }
  }
);

//Ean Point Sales

export const getPointSales = (state) => state.dynamicReport.pointSales;
export const getPointSalesSelected = (state) =>
  state.dynamicReport.pointSalesSelected;
export const getPointSalesLoadStatus = (state) =>
  state.dynamicReport.pointSalesStatus;

export const fetchPointSales = createAsyncThunk(
  "filters/dynamicReport/fetchPointSales",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstChannels: object.lstChannels,
      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestPointSales = await GetPointSales(obj);
    if (requestPointSales.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestPointSales.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestPointSales.data.responseMessage;
      }
    }
  }
);

//Formats

export const getFormats = (state) => state.dynamicReport.formats;
export const getFormatsSelected = (state) =>
  state.dynamicReport.formatsSelected;
export const getFormatsLoadStatus = (state) => state.dynamicReport.formatStatus;

export const fetchFormats = createAsyncThunk(
  "filters/dynamicReport/fetchFormats",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstChannels: object.lstChannels,
      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestFormats = await GetFormats(obj);
    if (requestFormats.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestFormats.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestFormats.data.responseMessage;
      }
    }
  }
);

//Departments

export const getDepartments = (state) => state.dynamicReport.departments;
export const getDepartmentsSelected = (state) =>
  state.dynamicReport.departmentsSelected;
export const getDepartmentsStatus = (state) =>
  state.dynamicReport.departmentsStatus;

export const fetchDepartments = createAsyncThunk(
  "filters/dynamicReport/departments",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstChannels: object.lstChannels,

      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestFormats = await GetDepartments(obj);
    if (requestFormats.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestFormats.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestFormats.data.responseMessage;
      }
    }
  }
);

//Channels

export const getChannels = (state) => state.dynamicReport.channels;
export const getChannelsSelected = (state) =>
  state.dynamicReport.channelsSelected;
export const getChannelsStatus = (state) => state.dynamicReport.channelsStatus;

export const fetchChannels = createAsyncThunk(
  "filters/dynamicReport/channels",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestChannels = await GetChannels(obj);
    if (requestChannels.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestChannels.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestChannels.data.responseMessage;
      }
    }
  }
);

//Cities

export const getCities = (state) => state.dynamicReport.cities;
export const getCitiesSelected = (state) => state.dynamicReport.citiesSelected;
export const getCitiesStatus = (state) => state.dynamicReport.citiesStatus;

export const fetchCities = createAsyncThunk(
  "filters/dynamicReport/cities",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstEanProducts: object.lstEanProducts,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestCities = await GetCities(obj);
    if (requestCities.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestCities.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestCities.data.responseMessage;
      }
    }
  }
);

//Categories

export const getCategories = (state) => state.dynamicReport.categories;
export const getCategoriesSelected = (state) =>
  state.dynamicReport.categoriesSelected;
export const getCategoriesStatus = (state) =>
  state.dynamicReport.categoriesStatus;

export const fetchCategories = createAsyncThunk(
  "filters/dynamicReport/categories",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstEanProducts: object.lstEanProducts,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestCategories = await GetCategories(obj);
    if (requestCategories.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestCategories.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestCategories.data.responseMessage;
      }
    }
  }
);

//Products

export const getProducts = (state) => state.dynamicReport.products;
export const getProductsSelected = (state) =>
  state.dynamicReport.productsSelected;
export const getProductsStatus = (state) => state.dynamicReport.productsStatus;

export const fetchProducts = createAsyncThunk(
  "filters/dynamicReport/products",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestProducts = await GetProducts(obj);
    if (requestProducts.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestProducts.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestProducts.data.responseMessage;
      }
    }
  }
);

//SalesForce

export const getSalesForce = (state) => state.dynamicReport.salesForceLevelOne;
export const getSalesForceSelected = (state) =>
  state.dynamicReport.salesForceLevelOneSelected;
export const getSalesForceStatus = (state) =>
  state.dynamicReport.salesForceLevelOneStatus;

export const fetchSalesForce = createAsyncThunk(
  "filters/dynamicReport/salesForce",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstEanProducts: object.lstEanProducts,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestSalesForce = await GetSalesForceLevelOne(obj);
    if (requestSalesForce.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestSalesForce.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestSalesForce.data.responseMessage;
      }
    }
  }
);

//Brands

export const getBrands = (state) => state.dynamicReport.brands;
export const getBrandsSelected = (state) => state.dynamicReport.brandsSelected;
export const getBrandsStatus = (state) => state.dynamicReport.brandsStatus;

export const fetchBrands = createAsyncThunk(
  "filters/dynamicReport/brands",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstColors: object.lstColors,
      lstSizes: object.lstSizes,
      lstEanProducts: object.lstEanProducts,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestBrands = await GetBrands(obj);
    if (requestBrands.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestBrands.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestBrands.data.responseMessage;
      }
    }
  }
);

//Colors

export const getColors = (state) => state.dynamicReport.colors;
export const getColorsSelected = (state) => state.dynamicReport.colorsSelected;
export const getColorsStatus = (state) => state.dynamicReport.colorsStatus;

export const fetchColors = createAsyncThunk(
  "filters/dynamicReport/colors",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstBrands: object.lstBrands,
      lstSizes: object.lstSizes,
      lstEanProducts: object.lstEanProducts,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestColors = await GetColors(obj);
    if (requestColors.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestColors.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestColors.data.responseMessage;
      }
    }
  }
);

//Sizes

export const getSizes = (state) => state.dynamicReport.sizes;
export const getSizesSelected = (state) => state.dynamicReport.sizesSelected;
export const getSizesStatus = (state) => state.dynamicReport.sizesStatus;

export const fetchSizes = createAsyncThunk(
  "filters/dynamicReport/sizes",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstEanProducts: object.lstEanProducts,
      lstSalesManCode: object.lstSalesManCode,
    };
    const requestSizes = await GetSizes(obj);
    if (requestSizes.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestSizes.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestSizes.data.responseMessage;
      }
    }
  }
);

//Sizes

export const getSalesMan = (state) => state.dynamicReport.salesMan;
export const getSalesManSelected = (state) =>
  state.dynamicReport.salesManSelected;
export const getSalesManStatus = (state) => state.dynamicReport.salesManStatus;

export const fetchSalesMan = createAsyncThunk(
  "filters/dynamicReport/salesman",
  async (object) => {
    const obj = {
      eanProvider: object.eanProvider,
      country: object.country,
      lstEansTraders: object.lstEansTraders,
      lstPointSales: object.lstPointSales,
      lstEansPointSales: object.lstEansPointSales,
      lstFormats: object.lstFormats,
      lstGeographiesLevelOne: object.lstGeographiesLevelOne,
      lstChannels: object.lstChannels,
      lstLevelOneCategories: object.lstLevelOneCategories,
      lstGeographiesLevelFour: object.lstGeographiesLevelFour,
      lstLevelOneSalesForce: object.lstLevelOneSalesForce,
      lstBrands: object.lstBrands,
      lstColors: object.lstColors,
      lstEanProducts: object.lstEanProducts,
      lstSizes: object.lstSizes,
    };
    const requestSalesMan = await GetSalesMan(obj);
    if (requestSalesMan.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestSalesMan.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestSalesMan.data.responseMessage;
      }
    }
  }
);

export default dynamicReportSlice.reducer;

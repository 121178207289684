import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";

export async function GenerateDynamicReportCEN(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `dynamicreportsCEN`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListReportsCEN(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreportsCEN/providers",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListReportsCENByFilter(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreportsCEN/filter",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListReportsCENByPKSK(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "dynamicreportsCEN/configurations",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const UserInfoComponent = ({ control, errors, t }) => {
  return (
    <Grid container alignItems="center" rowSpacing={1}>
      <Grid item className="full-width">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label={t("App.createUser.name")}
                  className={`${
                    errors.name
                      ? "modalEditRoles__form-input--error"
                      : "modalCreateRoles__form-input"
                  } full-width`}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  helperText={errors.name ? errors.name.message : ""}
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              name="lastNames"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label={t("App.createUser.lastname")}
                  className={`${
                    errors.lastNames
                      ? "modalEditRoles__form-input--error"
                      : "modalCreateRoles__form-input"
                  } full-width`}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  helperText={errors.lastNames ? errors.lastNames.message : ""}
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="full-width">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label={t("App.createUser.email")}
                  className={`${
                    errors.email
                      ? "modalEditRoles__form-input--error"
                      : "modalCreateRoles__form-input"
                  } full-width`}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  helperText={errors.email ? errors.email.message : ""}
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Controller
              defaultValue=""
              name="confirmEmail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label={t("App.createUser.confirmEmail")}
                  className={`${
                    errors.confirmEmail
                      ? "modalEditRoles__form-input--error"
                      : "modalCreateRoles__form-input"
                  } full-width`}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  helperText={
                    errors.confirmEmail ? errors.confirmEmail.message : ""
                  }
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={(event) => {
                    if (event.target.value != "") {
                      onChange(event);
                    } else {
                      onChange(null);
                    }
                  }}
                  value={value}
                  label={t("App.createUser.cellPhone")}
                  className="modalCreateRoles__form-input full-width"
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  helperText={errors.phone ? errors.phone.message : ""}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { useSelector } from "react-redux";
import { getLstCompanies } from "../../../parts/businessGroup/businessGroupSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModalSeeCompanies from "../AdminUsersClient/modalSeeCompanies";

const UploadFile = ({
  handleUploadFile,
  dragText,
  fileSelected,
  setFileSelected,
  setErrorModal,
  setErrorTitle,
  type = false,
}) => {
  /**
   * use Translation
   */
  const { t } = useTranslation();

  /**
   * Use State
   */
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  /**
   * Manejo cuando el archivo (draggable) entra a la zona target de drop
   * @param {*} e evento de drag
   */
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  /**
   * Manejo cuando el archivo (draggeable) sale de la zona target de drop
   * @param {*} e evento de drag
   */
  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  /**
   * Manejo cuando el archivo (draggable) está encima de la zona target de drop
   * @param {} e evento de drag
   */
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Manejo cuando el archivo (draggable) es droppeado en la zona target de drop
   * @param {*} e evento de drag
   */
  const handleDrop = (e) => {
    e.preventDefault();
    const {
      dataTransfer: { files },
    } = e;
    let extension = files[0].name.split(".").pop().toLowerCase();
    let size = files[0].size;
    let validate = true;

    // validar si es archivo externo
    if (type) {
      validate = validateTypeDoc(extension);
    }
    if (
      validate === false ||
      (extension !== "csv" && type === false) ||
      size > 10485760
    ) {
      if (type) {
        setErrorTitle(t("App.genericUpload.typeNotAllowed2"));
        setFileSelected(null);
      } else if (size > 10485760) {
        setErrorTitle(t("App.genericUpload.exceededLimit"));
      } else {
        setErrorTitle(t("App.genericUpload.typeNotAllowed"));
      }
      setFileSelected(null);
      setErrorModal(true);
    } else {
      setFileSelected([files[0]]);
    }
  };

  /**
   *  Valida que el archivo tenga la extensión correcta
   * @param {*} extension
   * @returns
   */
  const validateTypeDoc = (extension) => {
    const typesFile = ["xls", "xlsx", "txt", "csv"];
    return typesFile.includes(extension);
  };

  /**
   * Maneja la selección de archivo por medio de botón, verificando si este tiene
   * las especificaciones correctas, almacenando el archivo localmente si este satisface los requerimientos
   * @param {*} e archivo seleccionado
   */
  const onChangeFile = (e) => {
    let extension = e.target.files[0].name.split(".").pop().toLowerCase();
    let size = e.target.files[0].size;
    let validate = true;

    // validar si es archivo externo
    if (type) {
      validate = validateTypeDoc(extension);
    }
    if (
      validate === false ||
      (extension !== "csv" && type === false) ||
      size > 10485760
    ) {
      if (type) {
        setErrorTitle(t("App.genericUpload.typeNotAllowed2"));
        setFileSelected(null);
      } else if (size > 10485760) {
        setErrorTitle(t("App.genericUpload.exceededLimit"));
      } else {
        setErrorTitle(t("App.genericUpload.typeNotAllowed"));
      }

      setFileSelected(null);
      setErrorModal(true);
    } else {
      if (fileSelected) {
        setFileSelected(fileSelected.concat(Array.from(e.target.files)));
      } else {
        setFileSelected(Array.from(e.target.files));
      }
    }
  };

  /**
   * Realiza las acciones cuando el usuario acepta cargar el archivo
   * @param {*} event evento
   */
  const handleUploadFileInternally = async (event) => {
    event.preventDefault();
    let extension = fileSelected[0].name.split(".").pop().toLowerCase();
    let size = fileSelected[0].size;

    let validate = true;
    // validar si es archivo externo

    if (type) {
      validate = validateTypeDoc(extension);
    }
    if (
      validate === false ||
      (extension !== "csv" && type === false) ||
      size > 10485760
    ) {
      if (size > 10485760) {
        setErrorTitle(t("App.genericUpload.exceededLimit"));
      } else {
        setErrorTitle(t("App.genericUpload.typeNotAllowed"));
      }
      setFileSelected(null);

      setErrorModal(true);
    } else {
      setIsLoadingUpload(true);
      await handleUploadFile();
      setIsLoadingUpload(false);
    }
  };

  return (
    <Grid
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="grid__container__dotted"
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      {!fileSelected && (
        <Grid item>
          <div
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ padding: "0" }}
            >
              <Grid item>
                <label htmlFor="selectedFile" style={{ cursor: "pointer" }}>
                  <UploadFileIcon sx={{ fontSize: 60, color: "#8C8D9D" }} />
                </label>
              </Grid>

              <Grid item>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="selectedFile"
                  id="selectedFile"
                  onChange={onChangeFile}
                />
                <label
                  htmlFor="selectedFile"
                  className="heading_one__primary-two text-center d-block u-marginB"
                >
                  {dragText}
                </label>
                <label
                  htmlFor="selectedFile"
                  className="heading_one__primary-two text-center d-block u-marginB"
                >
                  {t("App.salesForce.or")}
                </label>
                <label
                  style={{ alignContent: "center" }}
                  htmlFor="selectedFile"
                  className="button__primary button__text-white u-marginB d-block text-center"
                >
                  {t("App.catalogue.select")}
                </label>
                <label
                  htmlFor="selectedFile"
                  className={"subheading-one__primary-two text-center d-block"}
                >
                  {type
                    ? t("App.externalFiles.requirements")
                    : t("App.catalogue.requirements")}
                </label>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      {fileSelected && (
        <Grid item xs={3} sm={12} md={12} lg={12}>
          <form onSubmit={handleUploadFileInternally}>
            <div>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TaskOutlinedIcon
                    sx={{
                      fontSize: 60,
                      color: "#543AB4",
                    }}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item xs={1} sm={1} md={12}>
                    {!fileSelected ? (
                      ""
                    ) : !isDesktop ? (
                      <Typography
                        variant="p"
                        className="label-Primary__xs heading__text-black"
                      >
                        {fileSelected[0].name.substring(
                          0,
                          fileSelected[0].name.length / 2
                        )}
                        <br />
                        {fileSelected[0].name.substring(
                          fileSelected[0].name.length / 2
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        variant="p"
                        className="label-Primary__xs heading__text-black"
                      >
                        {fileSelected[0].name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={10}
                    sm={4}
                    md={2}
                    lg={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      width="100%"
                      type="submit"
                      className="label-Primary__uploadButton"
                    >
                      {!isLoadingUpload ? (
                        t("App.catalogue.uploadFile")
                      ) : (
                        <CircularProgress size={28} color="inherit" />
                      )}
                    </Button>
                  </Grid>

                  {!isLoadingUpload ? (
                    <Grid item xs={10} sm={4} md={2} lg={2}>
                      <Button
                        width="100%"
                        className="label-Primary__cancelButton"
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                        onClick={() => {
                          setFileSelected(null);
                        }}
                      >
                        {t("App.catalogue.cancel")}
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                {isLoadingUpload && (
                  <Grid container className="proccessing">
                    <p className="subheading__moderate-blue">
                      {t("App.catalogue.paragraphUpload")}
                    </p>
                  </Grid>
                )}
                {!isLoadingUpload && (
                  <Grid item className=" u-marginT" xs={12} sm={12} md={12}>
                    {" "}
                    <label className={"subheading__primary-one u-marginT"}>
                      {type
                        ? t("App.externalFiles.requirements")
                        : t("App.catalogue.requirements")}{" "}
                    </label>
                  </Grid>
                )}
              </Grid>
            </div>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default UploadFile;

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  Dialog,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { CODES } from "../../../consts/codes";
import ConfigurationByCommerce from "./configurationByCommerce";
import {
  CreateAllyRoute,
  GetCommerceByCompany,
  GetProviderByAlly,
} from "../../../services/admin/sendAlliesService";
import ModalInfo from "../Modals/modalInfo";
import EditSendAlly from "./editSendAlly";
import FileState from "../fileState";
import moment from "moment/moment";

const CreateSendAlly = ({
  handleInfoText,
  fullNameUser,
  countryList,
  setIsLoadingData,
  allyList,
  handleFilters,
  handleConfigurationsArray,
}) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [allyId, setAllyId] = useState("");
  const [title, setTitle] = useState("");
  const [createResponse, setCreateResponse] = useState({});
  const [commerceList, setCommerceList] = useState([]);
  const [sizeCommerceList, setSizeCommerceList] = useState(0);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Schema para el formulario de crear configuración
   */
  const schema = yup.object().shape({
    configurations: yup.array().of(
      yup.object().shape({
        commerceList: yup
          .object()
          .nullable()
          .required(t("App.validationMessages.required")),
        dailyPath: yup.string().required(t("App.validationMessages.required")),
        state: yup.string().required(t("App.validationMessages.required")),
      })
    ),
  });

  /**
   * useForm para resolver la validación del schema
   */
  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * useFieldArray para el manejo dinámico de registros
   */
  const {
    fields: configurationsFields,
    append: configurationsAppend,
    remove: configurationsRemove,
    update: configurationsUpdate,
  } = useFieldArray({
    control,
    name: "configurations",
  });

  /**
   * Función para agregar un nuevo registro
   */
  const handleAddNewConfiguration = () => {
    const configuration = {
      selected: false,
      key: generateId(),
    };

    configurationsAppend(configuration);
  };

  /**
   *
   * Función para eliminar un registro y actualizar el selected del comercio a false
   *
   * @param {Number} index
   */
  const handleRemoveConfiguration = (index) => {
    const previousValue = getValues(`configurations.${index}.commerceList`);

    if (previousValue) {
      resetCommerceState(previousValue);
    }

    configurationsRemove(index);
  };

  /**
   *
   * Función para reiniciar el valor de los campos de las configuraciones por comercio
   *
   * @param {Number} index
   */
  const resetValuesOfFields = (index) => {
    //Reseteo los campos de ruta diaria, ruta historica, ruta recarga y estado
    setValue(`configurations.${index}.dailyPath`, "");
    setValue(`configurations.${index}.historicalPath`, "");
    setValue(`configurations.${index}.reloadPath`, "");
    setValue(`configurations.${index}.state`, "");
  };

  /**
   *
   * Función para actualizar el valor del selected del comercio a false
   *
   * @param {Object} value
   */
  const resetCommerceState = (value) => {
    const updatedCommerceList = commerceList.map((item) => {
      const eanCommerce = item.eanCommerce;

      if (eanCommerce === value.eanCommerce) {
        return { ...item, selected: false };
      }
      return item;
    });
    setCommerceList(updatedCommerceList);
  };

  /**
   *
   * Función para actualizar el selected a false de un comercio cuando es eliminado del campo
   * también se reinicia los valores de los campos de configuraciones por comercio
   *
   * @param {Object} value
   * @param {Number} index
   */
  const resetCommerce = (value, index) => {
    resetValuesOfFields(index);

    resetCommerceState(value);
  };

  /**
   *
   * Función para manejar los comercios para que no aparezcan después de ser seleccionados
   *
   * @param {Object} value
   * @param {Number} index
   */
  const handleCommerceChange = (value, index) => {
    resetValuesOfFields(index);

    // Esto permite que se actualice el array y se habiliten los campos
    // de ruta diaria y estado
    const configuration = getValues("configurations.0");
    configurationsUpdate(0, configuration);

    const updatedCommerceList = commerceList.map((item) => {
      const eanCommerce = item.eanCommerce;

      if (eanCommerce === value.eanCommerce) {
        return { ...item, selected: true };
      }

      return item;
    });

    setCommerceList(updatedCommerceList);
  };

  /**
   *
   * Función para generar un id genérico para los registros de configuraciones por comercio
   *
   * @returns id genérico
   */
  const generateId = () => {
    const id = Date.now().toString(32) + Math.random().toString(32);

    return id;
  };

  /**
   * Función para abrir el modal y agregar el primer registro
   */
  const handleOpen = () => {
    reset();
    handleAddNewConfiguration();
    setOpenCreate(true);
  };

  /**
   * Función para reiniciar los valores del state de commerceList
   */
  const resetCommerceList = () => {
    const resetList = commerceList.map((item) => {
      return { ...item, selected: false };
    });

    setCommerceList(resetList);
  };

  /**
   * Función para cerrar el modal y reiniciar sus campos
   */
  const handleClose = async () => {
    await resetCommerceList();
    setOpenCreate(false);
    reset();
  };

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect para actualizar el state de isDesktop en base al tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Función para reiniciar los campos de las configuraciones por comercio
   */
  const resetFields = () => {
    configurationsFields.forEach((item, index) => {
      setValue(`configurations.${index}.commerceList`, "");
      setValue(`configurations.${index}.dailyPath`, "");
      setValue(`configurations.${index}.historicalPath`, "");
      setValue(`configurations.${index}.reloadPath`, "");
      setValue(`configurations.${index}.state`, "");
    });
  };

  /**
   * Función para traer los proveedores de acuerdo al país y aliado seleccionado
   */
  const fetchSuppliers = async () => {
    try {
      const obj = {
        country: watch("country").country,
        idAllied: watch("ally").idAlly,
      };

      const suppliersService = await GetProviderByAlly(obj);

      if (
        suppliersService.status === CODES.COD_RESPONSE_HTTP_OK &&
        suppliersService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = suppliersService.data.responseMessage;

        let suppliersList = responseMessage.map((item) => {
          return {
            nameCompany: item.companyName,
            eanCompany: item.eanCompany,
          };
        });

        setCompanyList(suppliersList);
      }
    } catch (error) {
      console.log(
        "============== ERROR createSendAlly.jsx function fetchSuppliers ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para traer los comercios en base al aliado seleccionado
   *
   * @param {Object} data objeto de la empresa seleccionada
   */
  const fetchCommerceByCompany = async (data) => {
    try {
      const obj = {
        country: watch("country").country,
        idAllied: watch("ally").idAlly,
        eanProvider: data.eanCompany,
        lstEansTraders: [],
      };

      const commercesService = await GetCommerceByCompany(obj);

      if (
        commercesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        commercesService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = commercesService.data.responseMessage;

        let commercesList = responseMessage.map((item) => {
          return {
            commerceName: item.companyName,
            eanCommerce: item.eanCompany,
          };
        });

        setCommerceList(commercesList);
        setSizeCommerceList(commercesList.length);
      } else {
        setCommerceList([]);
        setSizeCommerceList(0);
      }
    } catch (error) {
      console.log(
        "============== ERROR createSendAlly.jsx function fetchCommerceByCompany ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para cambiar los valores del state de proveedores de acuerdo al país seleccionado
   *
   * @param {Object} country
   */
  const handleChangeCountryOrAlly = () => {
    setValue("company", null);
    resetFields();
    setCompanyList([]);
    setCommerceList([]);
  };

  /**
   *
   * Función para crear una configuración
   *
   * @param {Object} data
   */
  const handleCreateSendAlly = async (data) => {
    try {
      const obj = {
        country: data.country.country,
        eanProvider: data.company.eanCompany,
        provider: data.company.nameCompany,
        idAllied: data.ally.idAlly,
        allied: data.ally.allyName,
        lstTraders: handleConfigurationsArray(data.configurations),
        user: fullNameUser,
      };

      setLoadingCreate(true);

      const createAllyService = await CreateAllyRoute(obj);

      if (
        createAllyService.status === CODES.COD_RESPONSE_HTTP_OK &&
        createAllyService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        handleFilters(obj);
        setTitle(t("App.adminSendAllies.modals.okCreate"));
      } else {
        setTitle(t("App.validationMessages.error"));
      }
      setCreateResponse(createAllyService);
      setOpenModalInfo(true);

      setLoadingCreate(false);
    } catch (error) {
      console.log(
        "============== ERROR createSendAlly.jsx function handleCreateSendAlly ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para renderizar el componente del título del contenedor
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Método para habilitar o deshabilitar el botón de crear configuración
   */
  const handleDisableButton = () => {
    if (
      watch("country") &&
      watch("company") &&
      watch("ally") &&
      disabledButton
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Button
        startIcon={<AddIcon sx={{ fontSize: 22 }} />}
        className="btn__filled__blue btn__createConfig"
        onClick={handleOpen}
      >
        {t("App.adminSendAllies.newConfiguration")}
      </Button>
      <Dialog
        onClose={handleClose}
        open={openCreate}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "85%",
              height: "auto",
              maxWidth: "1284px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <>
          <DialogTitleContainer onClose={handleClose}>
            <Grid container direction="column" rowSpacing={1}>
              <Grid item className="display-large__primary-one">
                {t("App.adminSendAllies.modals.titleCreate")}
              </Grid>
            </Grid>
          </DialogTitleContainer>

          <DialogContent className="only-scroll-y">
            <form
              id="form-configurations"
              onSubmit={handleSubmit(handleCreateSendAlly)}
            >
              <Grid container item direction="column" spacing={1}>
                <Grid item>
                  {!isDesktop && handleInfoText()}

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={3}>
                      <Controller
                        name="ally"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.ally
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            options={allyList}
                            getOptionLabel={(option) => option.allyName}
                            isOptionEqualToValue={(option, value) =>
                              option.idAlly === value.idAlly
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.adminSendAllies.filter.ally")}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  FormHelperTextProps={{
                                    style: {
                                      margin: "-5px 0 1px 0px",
                                      color: "#e52900",
                                      fontSize: 10,
                                    },
                                  }}
                                  helperText={
                                    errors.ally ? errors.ally.message : ""
                                  }
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              handleChangeCountryOrAlly();
                              setAllyId(values ? values.idAlly : "");

                              if (watch("country")) {
                                fetchSuppliers();
                              }

                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                      <Controller
                        name="country"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.country
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            options={countryList}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) =>
                              option.country === value.country
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.adminSendAllies.filter.country"
                                  )}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  FormHelperTextProps={{
                                    style: {
                                      margin: "-5px 0 1px 0px",
                                      color: "#e52900",
                                      fontSize: 10,
                                    },
                                  }}
                                  helperText={
                                    errors.country ? errors.country.message : ""
                                  }
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              );
                            }}
                            onChange={(event, values) => {
                              setCountryName(values ? values.country : "");
                              onChange(values);
                              handleChangeCountryOrAlly();

                              if (values) {
                                fetchSuppliers();
                              }
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                      <Controller
                        name="company"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.company
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            options={companyList}
                            isOptionEqualToValue={(option, values) =>
                              option.eanCompany === values.eanCompany
                            }
                            getOptionLabel={(option) =>
                              option?.nameCompany ?? ""
                            }
                            disabled={
                              watch("ally") && watch("country") ? false : true
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.eanCompany}>
                                  {option.nameCompany}
                                </li>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.adminSendAllies.modals.enterprise"
                                  )}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  FormHelperTextProps={{
                                    style: {
                                      margin: "-5px 0 1px 0px",
                                      color: "#e52900",
                                      fontSize: 10,
                                    },
                                  }}
                                  helperText={
                                    errors.company ? errors.company.message : ""
                                  }
                                  {...params}
                                />
                              );
                            }}
                            onInputChange={() => {
                              resetFields();
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              resetFields();

                              if (reason !== "clear") {
                                fetchCommerceByCompany(values);
                              }
                            }}
                            value={value || ""}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <h3 className="heading__text-grey">
                    {t("App.adminSendAllies.modals.configPerCommerce")}
                  </h3>
                </Grid>
                <Grid
                  item
                  container
                  className="permissionBox"
                  width={"100%"}
                  sx={{ overflowX: "hidden" }}
                  mt={2}
                >
                  <ConfigurationByCommerce
                    configurationsFields={configurationsFields}
                    handleAddNewConfiguration={handleAddNewConfiguration}
                    handleRemoveConfiguration={handleRemoveConfiguration}
                    resetCommerce={resetCommerce}
                    handleCommerceChange={handleCommerceChange}
                    setDisabledButton={setDisabledButton}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    commerceList={commerceList}
                    sizeCommerceList={sizeCommerceList}
                    errors={errors}
                    watch={watch}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>

          <DialogActions>
            <Grid
              container
              direction={isDesktop ? "row" : "column-reverse"}
              // className="col-end"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
              sx={{ paddingBottom: "2rem" }}
              marginTop={2}
            >
              <Grid
                item
                className={isDesktop ? "" : "full-width"}
                xs={isDesktop ? 0 : 12}
              >
                <Button
                  className="btn__filled__gradient full-width"
                  onClick={handleClose}
                >
                  {t("App.buttonLabels.cancel")}
                </Button>
              </Grid>

              <Grid
                item
                className={isDesktop ? "" : "full-width"}
                xs={isDesktop ? 0 : 12}
              >
                {/* Checkpoint */}
                <Button
                  disabled={handleDisableButton()}
                  className={
                    watch("country") &&
                    watch("company") &&
                    watch("ally") &&
                    disabledButton
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                  type="submit"
                  form="form-configurations"
                  startIcon={!loadingCreate ? <AddIcon /> : <></>}
                >
                  {!loadingCreate ? (
                    t("App.adminSendAllies.modals.titleCreate")
                  ) : (
                    <CircularProgress size={21} color="inherit" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>

          {isDesktop && handleInfoText()}
        </>
      </Dialog>
      <ModalInfo
        open={openModalInfo}
        title={title}
        responseData={
          createResponse?.data?.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST || createResponse === undefined
            ? {
                data: {
                  responseCode: CODES.COD_RESPONSE_SUCCESS_REQUEST,
                },
                status: CODES.COD_RESPONSE_HTTP_OK,
              }
            : createResponse
        }
        onClose={() => {
          setOpenCreate(false);
          setOpenModalInfo(false);
        }}
      />
    </>
  );
};

export default CreateSendAlly;

import { Chip, FormControlLabel, Grid, Skeleton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileState from "../../components/common/fileState";
import { FilterAdminSpecialFilesCTyS } from "../../components/common/Filters/filterAdminSpecialFilesCTyS";
import { NoInfoComponent } from "../../components/common/NoInfoImage/noInfoComponent";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import { CODES } from "../../consts/codes";
import CreateSpecialFile from "../../components/common/SpecialFilesCTyS/createSpecialFile";
import EditSpecialFile from "../../components/common/SpecialFilesCTyS/editSpecialFile";
import { NoSelectionComponent } from "../../components/common/noImages/noSelectionComponent";
import PeriodicReportState from "../../components/common/DynamicReport/periodicReportState";

const AdminSpecialFiles = () => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Table
   */

  const columns = [
    {
      name: "creationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column1"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "creationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column2"),
      options: {
        filter: true,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "modificationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column3"),
      options: {
        filter: false,
        hint: t("App.specialFilesClient.modificationDateTooltip"),
        sort: true,
        searchable: false,
        customHeadLabelRender: () => {
          const modificationDate = (
            <div>
              <h4>
                {t("App.listDynamicPeriodicReport.table.columns.column3")}
              </h4>
              <h5 className="subheading-datatable">
                {t("App.homeDashboard.client.table.columns.formatDate")}
              </h5>
            </div>
          );

          return modificationDate;
        },
      },
    },
    {
      name: "modificationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column4"),
      options: {
        filter: false,
        hint: t("App.specialFilesClient.userModificationTooltipAdminSend"),
        sort: false,
        searchable: false,
      },
    },
    {
      name: "eanCompany",
      label: t("App.listUsers.ean"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "company",
      label: t("App.listUsers.enterpriseN"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "url",
      label: t("App.createOption.url"),
      options: {
        filter: false,
        hint: t("App.specialFilesCTyS.toolTipUrl"),
        sort: false,
        searchable: false,
        customHeadLabelRender: () => {
          const url = (
            <Grid item container alignItems="center" justifyContent="center">
              <h4>{t("App.createOption.url")}</h4>
            </Grid>
          );

          return url;
        },
        customBodyRender: (value) => (
          <a
            className="link__primary-one"
            style={{ cursor: "pointer" }}
            target="_blank"
            href={value}
          >
            {value}
          </a>
        ),
      },
    },
    {
      name: "state",
      label: t("App.adminCompanies.table.status"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value) => <PeriodicReportState value={value} />,
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.specialFilesCTyS.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use State
   */

  const [data, setData] = useState([]);
  const [filterApply, setFilterApply] = useState({ country: "" });

  const [noFilter, setNoFilter] = useState(true);
  const [noContent, setNoContent] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [forceFiltersForEdit, setForceFiltersForEdit] = useState(false);

  /**
   * Use Effect
   */

  /**
   * Handles
   */

  /**
   * Sets data structure table for one (1) element
   * @param {obj} data specialFile
   * @returns data formatted
   */
  const setDataStructure = (data) => {
    if (data) {
      let specialFile = data;
      return {
        eanCompany: specialFile.eanProvider || specialFile.eanProvider,
        company: specialFile.provider || specialFile.company,
        url: specialFile.url,
        creationUser: specialFile.creationUser,
        creationDate: specialFile.creationDate,
        country: specialFile.country,
        modificationDate: specialFile.modificationDate,
        modificationUser: specialFile.modificationUser,
        state: specialFile.state,
        edit: (
          <EditSpecialFile
            specialFile={specialFile}
            setData={setData}
            dataArray={data}
            setDataStructureArray={setDataStructureArray}
            setIsLoadingData={setIsLoadingData}
            setFilterApply={setFilterApply}
            setNoFilter={setNoFilter}
          />
        ),
      };
    }
  };

  /**
   * Sets data structure table for an array of elements
   * @param {array} data
   * @returns array formatted
   */
  const setDataStructureArray = (data) => {
    if (data) {
      const proccessedRows = data.map((specialFile) => {
        return {
          eanCompany: specialFile.eanProvider || specialFile.eanProvider,
          company: specialFile.provider || specialFile.company,
          url: specialFile.url,
          creationUser: specialFile.creationUser,
          creationDate: specialFile.creationDate,
          country: specialFile.country,
          modificationDate: specialFile.modificationDate,
          modificationUser: specialFile.modificationUser,
          state: specialFile.state,
          edit: (
            <EditSpecialFile
              specialFile={specialFile}
              setData={setData}
              dataArray={data}
              setDataStructureArray={setDataStructureArray}
              setIsLoadingData={setIsLoadingData}
              setFilterApply={setFilterApply}
              setNoFilter={setNoFilter}
            />
          ),
        };
      });

      return proccessedRows;
    }
  };

  /**
   * Updates data table information by adding one new row.
   * This function generates visual update on data table to visualize the new special file.
   * @param {obj} specialFile
   */
  const updateDatatableVisually = (specialFile) => {
    //Loads table
    setIsLoadingData(true);
    setData([]);

    //Sets structure for the current created special file
    const newRow = setDataStructure(specialFile);
    let newDataArray = data;

    //Adds the registry at the beginning of the list
    newDataArray.unshift(newRow);
    const finalArray = newDataArray.map((item) => item);

    //Sets unshifted array as data
    setData(finalArray);

    setNoFilter(false);
    setIsLoadingData(false);
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <h1 className="display-large__primary-one">
          {t("App.specialFilesCTyS.title")}
        </h1>
      </Grid>

      <Grid item container justifyContent="flex-end">
        <CreateSpecialFile
          setData={setData}
          setIsLoadingData={setIsLoadingData}
          data={data}
          updateDatatableVisually={updateDatatableVisually}
        />
      </Grid>

      <Grid
        item
        className="companies_info_container full-width"
        style={{ marginBottom: "20px" }}
      >
        <Grid container direction="column" gap={2}>
          <Grid item className="filter-container__specialFiles full-width">
            <FilterAdminSpecialFilesCTyS
              setData={setData}
              setDataStructure={setDataStructureArray}
              setIsLoadingData={setIsLoadingData}
              setFilterApply={setFilterApply}
              setNoFilter={setNoFilter}
              setNoContent={setNoContent}
            />
          </Grid>

          <Grid item>
            <Grid container direction="row" style={{ columnGap: "2%" }}>
              {filterApply.country && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t("App.listUsers.countryN")}: ${
                      filterApply.country
                    }`}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item className="full-width">
            {!noFilter ? (
              !noContent ? (
                isLoadingData ? (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    data={data}
                    columns={columns}
                    options={skeletonOptions}
                  />
                ) : (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )
              ) : (
                <NoMatchImage />
              )
            ) : (
              <NoSelectionComponent type="specialFiles" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminSpecialFiles;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/mapping/mappingService";

export const FilterLoadMapping = ({
  filterData,
  setHasFilter,
  unFilteredData,
  setData,
  setObjFilter,
}) => {
  /**
   * Const
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Datos tipos de documento de prueba
   */
  const documentTypeList = [
    {
      label: t("App.downloadSalesForce.selectAll"),
      value: t("App.downloadSalesForce.selectAll"),
    },
    { label: t("App.loadMapping.table.typeDoc.type.sales"), value: "sales" },
    {
      label: t("App.loadMapping.table.typeDoc.type.inventory"),
      value: "stock",
    },
    {
      label: t("App.loadMapping.table.typeDoc.type.saleStock"),
      value: "sales_stock",
    },
  ];

  const [issuersList, setIssuersList] = useState([]);
  const [receiversList, setReceiverList] = useState([]);

  /**
   * Schema para la validación del formulario de los filtros
   */
  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({ countryName: yup.string(), country: yup.string() })
      .required(),
  });

  /**
   * Función que valida si se deben deshabilitar los campos dependientes de los filtros obligatorios
   */
  const handleDisableField = () => {
    return !(
      watch("country")?.country &&
      watch("documentType") &&
      watch("documentType").length > 0
    );
  };
  /**
   * Use Form
   */

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use State
   */

  const [countryList, setCountryList] = useState([]);

  /**
   * Use Effect
   */

  /**
   * Función que consulta los países registrados en la plataforma
   */
  useEffect(() => {
    const getCountriesRequest = async () => {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetGeographiesList();
      if (status === 200) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
          setCountryList(responseMessage);
        } else {
          setCountryList([]);
        }
      }
    };
    getCountriesRequest();
  }, []);

  /**
   * Obtener receptores del país seleccionado
   * @param {*} country  país seleccionado
   */
  const getReceivers = async (country) => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetSuppliersService(country);
    if (status === CODES.COD_RESPONSE_SUCCESS) {
      if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        let suppliers = responseMessage.map((supplier) => {
          return {
            label: supplier.companyName,
            eanCompany: supplier.eanCompany,
          };
        });
        suppliers =
          suppliers.length > 0
            ? [
                {
                  label: t("App.downloadSalesForce.selectAll"),
                },
                ...suppliers,
              ]
            : [];
        setReceiverList(suppliers);
      }
    }
  };

  /**
   * Obtener emisores del país seleccionado
   * @param {*} country  país seleccionado
   */
  const getIssuers = async (country) => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetTradersService(country);
    if (status === CODES.COD_RESPONSE_SUCCESS) {
      if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        let traders = responseMessage.map((trader) => {
          return {
            label: trader.companyName,
            eanCompany: trader.eanCompany,
          };
        });
        traders =
          traders.length > 0
            ? [
                {
                  label: t("App.downloadSalesForce.selectAll"),
                },
                ...traders,
              ]
            : [];
        setIssuersList(traders);
      }
    }
  };

  /**
   * Handles
   */

  /**
   * Request
   */

  /**
   * Función que maneja cambios cuando el país del formulario cambia
   * @param {*} currentCountry país seleccionado
   */
  const handleCountryChange = async (event, values, reason, detail) => {
    setValue("country", values);
    cleanDependentFields();
    if (values !== null && values.country !== null) {
      await getIssuers(values.country);
      await getReceivers(values.country);
    }
    if (reason === "clear") {
      setValue("country", { countryName: "", country: "" });
    }
  };

  /**
   * Manejor los cambios del campo emisor
   * @param {*} event
   * @param {*} values valores seleccionados
   * @param {*} reason tipo de acción
   * @param {*} detail
   */
  const onIssuerChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const issuer of values) {
      if (issuer.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeIssuers = issuersList.filter(
      (issuer) => issuer.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (issuer) => issuer.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeIssuers && reason === "selectOption")
    ) {
      setValue("issuers", issuersList);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === issuersList.length - 1) {
      setValue(
        "issuers",
        values.filter(
          (issuer) => issuer.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("issuers", values);
    }

    if (
      (detail?.option.label === t("App.downloadSalesForce.selectAll") &&
        reason === "removeOption") ||
      reason === "clear"
    ) {
      setValue("issuers", []);
    }
  };

  /**
   * Manejor los cambios del campo receptor
   * @param {*} event evento
   * @param {*} values valores seleccionados
   * @param {*} reason  tipo de acción
   * @param {*} detail  detalle
   */
  const onReceiverChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const receiver of values) {
      if (receiver.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeReceivers = receiversList.filter(
      (receiver) => receiver.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (receiver) => receiver.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeReceivers && reason === "selectOption")
    ) {
      setValue("receivers", receiversList);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === receiversList.length - 1) {
      setValue(
        "receivers",
        values.filter(
          (receiver) => receiver.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("receivers", values);
    }

    if (
      (detail?.option.label === t("App.downloadSalesForce.selectAll") &&
        reason === "removeOption") ||
      reason === "clear"
    ) {
      setValue("receivers", []);
    }
  };

  /**
   * Se ejecuta cuando se cambia el tipo de documento
   * @param {*} event
   * @param {*} values valores seleccionados
   * @param {*} reason tipo de acción
   * @param {*} detail
   */
  const onTypeDocChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const typeDoc of values) {
      if (typeDoc.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeTypeDoc = documentTypeList.filter(
      (typeDoc) => typeDoc.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (typeDoc) => typeDoc.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeTypeDoc && reason === "selectOption")
    ) {
      setValue("documentType", documentTypeList);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === documentTypeList.length - 1) {
      setValue(
        "documentType",
        values.filter(
          (typeDoc) => typeDoc.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("documentType", values);
    }

    if (
      (detail?.option.label === t("App.downloadSalesForce.selectAll") &&
        reason === "removeOption") ||
      reason === "clear"
    ) {
      setValue("documentType", []);

      cleanDependentFields();
    }
  };

  /**
   *Limpia los campos dependientes de los filtros obligatorios
   */
  const cleanDependentFields = () => {
    setValue("creationDate", null);
    setValue("creationDate", "");
    setValue("issuers", []);
    setValue("receivers", []);
  };
  /**
   * Función que recibe los datos del formulario y registra los datos en el sistema
   * @param {*} data información del formulario
   */
  const handleFilters = async (data) => {
    const obj = {
      countryName: data.country.countryName,
      country: data.country.country,
      lstDocumentTypes: data.documentType
        // Eliminar el valor 'Seleccionar todo' de la selección de tipos de documento
        .filter(
          (typeDoc) => typeDoc.label !== t("App.downloadSalesForce.selectAll")
        )
        .map((typeDoc) => typeDoc.value),
      lstEanSenders: data.issuers
        ? data.issuers.length < issuersList.length
          ? data.issuers.map((issuer) => issuer.eanCompany)
          : []
        : null,
      lstEanReceives: data.receivers
        ? data.receivers.length < receiversList.length
          ? data.receivers.map((receiver) => receiver.eanCompany)
          : []
        : null,
      creationDate:
        data.creationDate && formatDate(data.creationDate) !== ""
          ? formatDate(data.creationDate)
          : null,
    };
    setHasFilter(true);
    // Guardar datos del filtro
    setObjFilter(obj);
    await filterData(obj);
  };

  /**
   * Formatear la fecha
   */
  const formatDate = (date) => {
    let aux = new Date(date);
    return moment(aux).format("YYYY-MM-DD");
  };

  /**
   * Función que limpia los filtros
   */
  const handleCleanFilters = () => {
    reset();
    setHasFilter(false);
    setData(unFilteredData);
    setObjFilter({});
  };

  return (
    <Accordion defaultExpanded
    sx={{
      boxShadow: "none !important"
    }}
    >
      <AccordionSummary className="accordion__filter">
        <Grid container direction="row" columnSpacing={1}>
          <Grid item>
            {" "}
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />
          </Grid>
          <Grid item>
            <p className="navbar__text">{t("App.listUsers.filter")}</p>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
            padding:{ 
              sm: "2rem  2.6rem !important",
              lg: "2rem  2.4rem !important",
            },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          rowSpacing={1}
        >
          <Grid
            xl={9}
            lg={8}
            md={9}
            sm={8}
            xs={12}
            item
            className="side-line-contact__verticalPadding"
          >
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                width={"98%"}
                sx={{
                  width: { lg: '96%', xl: '98%' }, 
                }}
              >
                <Grid
                  container
                  direction="row"
                  rowSpacing={4}
                  width="100%"
                  paddingBottom={4}
                  spacing={2}
                >
                  <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          noOptionsText={t("App.listUsers.noOptions")}
                          className={
                            errors.country 
                            ? "select-contact__filters__error" 
                            : "select-contact__filters"
                          }
                          options={countryList}
                          getOptionLabel={(option) => option.countryName}
                          isOptionEqualToValue={(option, value) =>
                            option.country === value.country
                          }
                          renderInput={(params) => {
                            return (
                              <TextField
                                label={t("App.deleteAndReload.filters.country")}
                                sx={{
                                  label: {
                                    fontFamily: "Roboto !important",
                                    fontSize: "14px !important",
                                    translate: "0px -5px",
                                    transformOrigin: "-45px -10px",
                                    "&.Mui-focused": {
                                      color: "#8C8D9D",
                                    },
                                  },
                                }}
                                {...params}
                              />
                            );
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.country}>
                              {option.countryName}
                            </li>
                          )}
                          onChange={handleCountryChange}
                          value={value || null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                    <Controller
                      name="documentType"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          noOptionsText={t(
                            "App.validationMessages.noMatchFilter"
                          )}
                          className={
                            errors.countryName
                              ? "select-contact__filters__error"
                              : "select-contact__filters"
                          }
                          multiple
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          id="checkboxes-tags-demo"
                          options={documentTypeList}
                          value={value || []}
                          disableCloseOnSelect
                          limitTags={3}
                          onChange={onTypeDocChange}
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                className="autoComplete__checkbox"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          renderTags={(value) => {
                            const numTags = value.length;
                            const limitTags = 2;

                            return (
                              <>
                                {value
                                  .slice(0, limitTags)
                                  .map((option, index) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "25%",
                                      }}
                                      key={index}
                                      size="small"
                                      label={option.label}
                                    />
                                  ))}

                                {numTags > limitTags &&
                                  ` +${numTags - limitTags}`}
                              </>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              label={t("App.loadMapping.filter.docType") + "*"}
                              sx={{
                                label: {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D",
                                  },
                                },
                              }}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  rowSpacing={4}
                  spacing={2}
                  width="100%"
                >
                  <Grid item lg={3.9} sm={12} md={4} xs={12} width={"100%"}>
                    <Controller
                      name="issuers"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          noOptionsText={t(
                            "App.validationMessages.noMatchFilter"
                          )}
                          className={"select-load-mapping__filters"}
                          multiple
                          disabled={handleDisableField()}
                          isOptionEqualToValue={(option, value) =>
                            option.eanCompany === value.eanCompany
                          }
                          id="checkboxes-tags-demo"
                          options={issuersList}
                          value={value || []}
                          disableCloseOnSelect
                          limitTags={2}
                          onChange={onIssuerChange}
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                className="autoComplete__checkbox"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          renderTags={(value) => {
                            const numTags = value.length;
                            const limitTags = 2;

                            return (
                              <>
                                {value
                                  .slice(0, limitTags)
                                  .map((option, index) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "25%",
                                      }}
                                      key={index}
                                      size="small"
                                      label={option.label}
                                    />
                                  ))}

                                {numTags > limitTags &&
                                  ` +${numTags - limitTags}`}
                              </>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              label={t("App.loadMapping.filter.issuer")}
                              sx={{
                                label: {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D",
                                  },
                                },
                              }}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={3.9} sm={12} md={4} xs={12}  width={"100%"}>
                    <Controller
                      name="receivers"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          noOptionsText={t(
                            "App.validationMessages.noMatchFilter"
                          )}
                          className={
                            errors.ean
                              ? "select-load-mapping__filters__error"
                              : "select-load-mapping__filters"
                          }
                          multiple
                          disabled={handleDisableField()}
                          isOptionEqualToValue={(option, value) =>
                            option.eanCompany === value.eanCompany
                          }
                          id="checkboxes-tags-demo"
                          options={receiversList}
                          value={value || []}
                          disableCloseOnSelect
                          limitTags={2}
                          onChange={onReceiverChange}
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                className="autoComplete__checkbox"
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          renderTags={(value) => {
                            const numTags = value.length;
                            const limitTags = 2;

                            return (
                              <>
                                {value
                                  .slice(0, limitTags)
                                  .map((option, index) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "25%",
                                      }}
                                      key={index}
                                      size="small"
                                      label={option.label}
                                    />
                                  ))}

                                {numTags > limitTags &&
                                  ` +${numTags - limitTags}`}
                              </>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              label={t("App.loadMapping.filter.receiver")}
                              sx={{
                                label: {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D",
                                  },
                                },
                              }}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={3.9} sm={12} md={4} xs={12}>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="creationDate"
                      render={({ field: { onChange, value } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className={
                              errors.ocDate
                                ? "select-contact__filters__error"
                                : "select-contact__filters"
                            }
                            disabled={handleDisableField()}
                            inputFormat="YYYY-MM-DD"
                            label={t("App.loadMapping.filter.creationDate")}
                            sx={{
                              translate: "0px -5px",
                            }}
                            value={
                              watch("creationDate")
                                ? watch("creationDate")
                                : null
                            }
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  input: {
                                    marginTop: "2px",
                                    padding: "8px 4px 7px 14px !important"
                                  },
                                  label: {
                                    fontFamily: "Roboto !important",
                                    fontSize: "14px !important",
                                    translate: "0px -4px",
                                    transformOrigin: "-45px -10px",
                                    color: "#8C8D9D !important",
                                    "&.Mui-focused": {
                                      color: "#8C8D9D",
                                    },
                                  },
                                }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xl={3} lg={4} md={3} sm={4} xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xl={4} lg={5} md={12} sm={8} xs={12} 
              width={"100%"}
              sx={{
                ml: { lg: 3 },
              }}
              >
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  disabled={!watch("country")?.countryName}
                  className={
                    getValues("country")?.countryName &&
                    getValues("documentType")?.length > 0
                      ? "btn__applyFilter-reload"
                      : "btn__applyFilter-reload-disabled"
                  }
                  type="submit"
                  form="hook-form"
                >
                  {t("App.userDetail.applyFilters")}
                </Button>
              </Grid>

              <Grid item xl={5} lg={5} md={12} sm={7} xs={12} width="100%">
                <Button
                  onClick={handleCleanFilters}
                  disableRipple
                  className="btn__deleteFilter-reload"
                >
                  {t("App.userDetail.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import DownloadGuide from "../../../components/common/UploadFile/downloadGuide";
import DownloadTemplate from "../../../components/common/UploadFile/downloadTemplate";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../userSlice";
import { useTranslation } from "react-i18next";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import CircularProgress from "@mui/material/CircularProgress";
import LoopIcon from "@mui/icons-material/Loop";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FilterSalesForce } from "../../../components/common/filterSalesForce";
import MUIDataTable from "mui-datatables";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import {
  DownloadMastersErrorLog,
  DownloadMastersFile,
  GetMastersGuide,
  GetMastersTemplate,
  UploadMastersFile,
} from "../../../services/user/masters/mastersService";
import {
  fetchMasters,
  getCurrentMaster,
  getMasters,
  getMastersStatus,
  resetMastersStatus,
} from "../../../parts/masters/mastersSlice";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import FileState from "../../../components/common/fileState";
import FilterBusinessGroupWB from "../../../components/common/Filters/businessGroup/filterWithoutButtons";
import UploadFileBG from "../../../components/common/UploadFile/uploadFileBusinessGroup";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import ModalSeeCompanies from "../../../components/common/AdminUsersClient/modalSeeCompanies";
import UploadFile from "../../../components/common/UploadFile/uploadFile";
import FilterBusinessGroupUniqueWB from "../../../components/common/Filters/businessGroup/filterUniqueWithoutButton";

const UploadMasters = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Table
   */

  /**Table */

  /**
   * Definición estructura columnas de la tabla
   * name: nombre de la columna
   * label: nombre de la columna que se muestra en la tabla
   * options: establecer la personalización de la columna
   *     - filter: determina si la columna se puede filtrar
   *     - sort: determina si la columna se puede ordenar
   *     - searchable: determina si la columna se puede buscar
   *     - customBodyRender:  permite personalizar la visualización de la celdas de la columna
   *                     Parámetros:
   *                      * value: valor de la celda en la fila actual
   *                      * tableMeta: metadatos sobre la tabla (númrero de filas y columnas,..)
   *                      * updateValue: función que permite actualizar el valor de la celda
   *                     Usos:
   *                      1. Columna file: permite mostrar un enlace para descargar el archivo
   *                      2. Columna status: muestra el estado de la carga del archivo
   *                      3. Columna errors: muestra el enlace para descargar el log de errores  solo
   *                         si los datos no están cargando y el archivo de resgistro de errores no se ha cargado
   *
   */

  const columns = [
    {
      name: "startDate",
      label: t("App.catalogue.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.catalogue.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.catalogue.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "companies",
      label: t("App.listUsers.enterpriseN"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.catalogue.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value.fileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadFile(value.fileName, value.pathFileName)
                  }
                >
                  {value.fileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FileState
              value={value}
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            // <FormControlLabel
            //   label=""
            //   value={value}
            //   control={<p>{value}</p>}
            //   onChange={(event) => updateValue(event.target.value)}
            // />
            <>{value}</>
          ),
      },
    },
    {
      name: "errors",
      label: t("App.catalogue.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value}
              control={
                value.awsUploadLog ? (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) => setOpenErrorModal(true)}
                  >
                    ERROR
                  </a>
                ) : (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) =>
                      handleDownloadLog(
                        value.logFileName,
                        value.pathLogFileName
                      )
                    }
                  >
                    {value.logFileName}
                  </a>
                )
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
  ];

  /**
   * Configutació opciones de la tabla
   * filterType: tipo de filtro a utilizar
   * download: determina si se puede descargar la tabla
   * print: determina si se puede imprimir la tabla
   * selectableRows: determina si se puede seleccionar filas y el tipo de selección
   * responsive: determina la forma de mostrar la tabla en diferentes pantallas
   * searchPlaceholder: texto a mostrar en el campo de búsqueda cuando no hay nada escrito
   * elevation: sombreado de la tabla
   * viewColumns: habilita la opción para que el usuario pueda elegir las columnas que desea ver
   * search: habilita/deshabilita opción la búsqueda
   * filter: habilita/deshabilita opción el filtro por parte del usuario
   * searchOpen: determina si el campo de búsqueda está abierto por defecto
   * rowsPerPageOptions: determina el número de filas a mostrar por página
   * textLabels: personalización de textos a utilizar en la tabla
   *          body: definir textos para el cuerpo de la tabla
   *               noMatch: componente a mostrar cuando no hay resultados
   *               tooTip: texto a mostrar cuando el cursor pasa por el encabezado de una columna
   *               columnHeaderTooltip: texto a mostrar en el tooltip del encabezado de la columna
   *         pagination: definir textos a utilizafr para la paginación de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Configuración opciones de la tabla
   * filterType: tipo de filtro a utilizar
   * download: determina si se puede descargar la tabla
   * print: determina si se puede imprimir la tabla
   * selectableRows: determina si se puede seleccionar filas y el tipo de selección
   * responsive: determina la forma de mostrar la tabla en diferentes pantallas
   * searchPlaceholder: texto a mostrar en el campo de búsqueda cuando no hay nada escrito
   * elevation: sombreado de la tabla
   * viewColumns: habilita la opción para que el usuario pueda elegir las columnas que desea ver
   * search: habilita/deshabilita opción la búsqueda
   * filter: habilita/deshabilita opción el filtro por parte del usuario
   * searchOpen: determina si el campo de búsqueda está abierto por defecto
   * rowsPerPageOptions: determina el número de filas a mostrar por página
   * textLabels: personalización de textos a utilizar en la tabla
   *          body: definir textos para el cuerpo de la tabla
   *               noMatch: componente a mostrar cuando no hay resultados
   *               tooTip: texto a mostrar cuando el cursor pasa por el encabezado de una columna
   *               columnHeaderTooltip: texto a mostrar en el tooltip del encabezado de la columna
   *         pagination: definir textos a utilizafr para la paginación de la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   *
   */
  //Usuario
  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  //Lista de masters en tabla
  const mastersList = useSelector(getMasters);
  const mastersStatus = useSelector(getMastersStatus);

  /**
   * 0 es para vendedor (budget) y 1 es para productos (productsreferences)
   */
  const master = useSelector(getCurrentMaster);

  // Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */

  //Upload file
  const [fileSelected, setFileSelected] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [responseLoadStores, setResponseLoadStores] = useState({});
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  //Data management
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterApply, setFilterApply] = useState(null);

  //Loadings
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);

  //No content
  const [noMasters, setNoMasters] = useState(false);

  //Refresh function
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  //Manejo de comportamientos y datos grupo empresarial
  const [showUpload, setShowUpload] = useState(null);
  const [checkedReplicate, setCheckedReplicate] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);

  //indica si se encontró un filtro
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   *
   * Sirve para obtener la lista de masters y actualizar la información de la tabla
   * o determinar si no hay masters para mostrar
   *   */

  useEffect(() => {
    const getMastersList = async () => {
      try {
        // Para obtener la lista de masters
        if (mastersStatus === "fetch") {
          dispatch(
            fetchMasters({
              data: {
                eanProvider: userInfo.company.eanCompany,
                country: userInfo.company.country,
                emailLoad: userInfo.email,
              },
              master: master,
            })
          );
        }
        switch (mastersStatus) {
          //Se procesa la lista de masters y se actualiza la información de tabla
          // Se verifica si hay masters para mostrar y se actualiza los estados de carga
          case "succeeded":
            const finalMasters = mastersList;

            let processedRows = finalMasters.map((master, i) => {
              return {
                startDate: !master.starDate
                  ? "  "
                  : moment(master.starDate).format("YYYY-MM-DD HH:mm:ss"),
                endDate: !master.endDate
                  ? "  "
                  : moment(master.endDate).format("YYYY-MM-DD HH:mm:ss"),
                user: !master.userLoad ? "" : master.userLoad,
                companies:
                  master.lstCompanies?.length > 1 ? (
                    <ModalSeeCompanies
                      data={master.lstCompanies}
                      userName={master.userLoad}
                    />
                  ) : (
                    master.lstCompanies !== null &&
                    master.lstCompanies.length > 0 &&
                    master.lstCompanies[0].renameCompany
                  ),
                file: !master.fileName
                  ? " "
                  : {
                      fileName: master.fileName,
                      pathFileName: master.pathFileName,
                    },
                state: !master.state ? " " : master.state,
                errors: master.awsUploadLog
                  ? {
                      awsUploadLog: master.awsUploadLog,
                    }
                  : {
                      logFileName: master.logFileName,
                      pathLogFileName: master.pathLogFileName,
                    },
              };
            });

            setNoMasters(processedRows.length > 0 ? false : true);
            setUnfilteredData(processedRows);
            setData(processedRows);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;

          case "loading":
            break;
          // Se actualiza el estado de carga y  no hay masters para mostrar
          case "failed":
            setNoMasters(true);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    };
    setLoading(true);
    if (userInfoStatus === "succeeded") {
      getMastersList();
    } else {
      setIsLoadingRefresh(false);
    }
  }, [dispatch, mastersStatus, userInfoStatus]);

  /**
   * Indicar que si hay contenido, se esta cargando la información, no hay archivos seleccionados
   * y no hay datos
   */
  useEffect(() => {
    setNoMasters(false);
    setLoading(true);
    setFileSelected(null);
    setData([]);
    dispatch(resetMastersStatus());
  }, [master]);

  /**
   * useEffect que muestra el componente por default si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [isBusinessGroup]);

  /**
   * useEffect que elimina el archivo previamente cargado si la carga se oculta
   */
  useEffect(() => {
    if (!showUpload) {
      setFileSelected(null);
      setCheckedReplicate(false);
    }
  }, [showUpload]);

  /**
   * Handles
   */

  /**
   * Descarga el archivo de la tabla de carga de maestros
   * Solo si el servicio responde correctamente se descarga el archivo
   * caso contrario no descargará nada
   *
   * @param {string} fileName: nombre del archivo
   * @param {string} pathFileName : ruta del archivo
   *
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      pathFileName: pathFileName,
      fileName: fileName,
    };
    try {
      const fileService = await DownloadMastersFile(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
-  * Permite descargar el archivo de registro de errores del maestro seleccionado, 
   * si el servicio responde correctamente se descarga el 
   * archivo, caso contrario no descargará nada
   * @param {string} logFileName: nombre del archivo
   * @param {string} pathLogFileName: ruta del archivo
   *
  
   *
   */
  const handleDownloadLog = async (logFileName, pathLogFileName) => {
    const obj = {
      pathLogFileName: pathLogFileName,

      logFileName: logFileName,
    };
    try {
      const logService = await DownloadMastersErrorLog(obj);

      if (logService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (logService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(logService.data.data.fileContent),
            logService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *  Descargar la plantilla del maestro seleccionado
   *     Si el servicio responde correctamente se descarga la plantilla
   *    caso contrario no descargará nada
   * @param {*} event: evento click del botón descargar plantilla
   
   */
  const handleDownloadTemplate = async (event) => {
    event.preventDefault();

    try {
      if (master !== "2") {
        let templateService = await GetMastersTemplate({ master: master });
        if (templateService.status === CODES.COD_RESPONSE_HTTP_OK) {
          if (
            templateService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
          ) {
            fileDownload(
              decode(templateService.data.data.fileContent),
              templateService.data.data.fileName
            );
          }
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Permite descargar la guía del maestro seleccionado
   *    Si el servicio responde correctamente se descarga la guía
   *   caso contrario no descargará nada
   * @param {*} event: evento click del botón descargar guía
   *
   */

  const handleDownloadGuide = async (event) => {
    event.preventDefault();

    try {
      if (master !== 2) {
        let guideService = await GetMastersGuide({ master: master });
        if (guideService.status === CODES.COD_RESPONSE_HTTP_OK) {
          if (
            guideService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
          ) {
            fileDownload(
              decode(guideService.data.data.fileContent),
              guideService.data.data.fileName
            );
          }
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Cargar archivo para el maestro seleccionado
   * Si el  servicio responde correctamente se muestra el modal de éxito
   * caso contrario no cargará el archivo
   */
  const handleUploadFile = async () => {
    try {
      const obj = {
        file: fileSelected[0],
        companies: companySelected,
        checkReplicateBusinessGroup: isBusinessGroup
          ? checkedReplicate
            ? 1
            : 0
          : 0,
      };

      if (master !== "2") {
        const mastersService = await UploadMastersFile({
          obj: obj,
          master: master,
        });
        setCheckedReplicate(false);
        if (mastersService.status === CODES.COD_RESPONSE_HTTP_OK) {
          dispatch(resetMastersStatus());
          setFileSelected(null);
          setResponseLoadStores(mastersService);
          setOpenSuccessLoadModal(true);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Permite actualizar el estado actual para el archivo maestro seleccionado,
   * ya sea en la carga o la descarga
   */
  const handleRefresh = () => {
    setNoMasters(false);
    setIsLoadingRefresh(true);
    setLoading(true);
    setData([]);
    dispatch(resetMastersStatus());
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        className="u-marginB template-container__general"
        spacing={2}
      >
        <DownloadTemplate
          helpText={t("App.mastersUpload.template")}
          buttonText={t("App.mastersUpload.templateButton")}
          handleDownloadTemplate={handleDownloadTemplate}
        />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Grid
          container
          className="grid__container"
          style={{ marginTop: "0.2%", marginBottom: "1%", padding: "0" }}
          direction="column"
        >
          <Grid item className="guide-file">
            <DownloadGuide
              helpText={"App.salesForce.guide"}
              handleDownloadGuide={handleDownloadGuide}
            />
          </Grid>
          {isBusinessGroup && (
            <Grid item>
              <FilterBusinessGroupUniqueWB
                title={t("App.manageBusinessGroup.filter.title4")}
                setCompanySelected={setCompanySelected}
                setShowUpload={setShowUpload}
              />
            </Grid>
          )}
          <Grid item className="upload-file">
            {isBusinessGroup ? (
              master === "1" ? (
                showUpload && (
                  <UploadFileBG
                    dragText={t("App.mastersUpload.dragging")}
                    handleUploadFile={handleUploadFile}
                    fileSelected={fileSelected}
                    setFileSelected={setFileSelected}
                    setErrorModal={setIsOpenErrorModal}
                    setErrorTitle={setErrorTitle}
                    userInfo={userInfo}
                    showUpload={showUpload}
                    setCheckedReplicate={setCheckedReplicate}
                  />
                )
              ) : (
                showUpload && (
                  <UploadFile
                    dragText={t("App.mastersUpload.dragging")}
                    handleUploadFile={handleUploadFile}
                    fileSelected={fileSelected}
                    setFileSelected={setFileSelected}
                    setErrorModal={setIsOpenErrorModal}
                    setErrorTitle={setErrorTitle}
                  />
                )
              )
            ) : (
              <UploadFile
                dragText={t("App.mastersUpload.dragging")}
                handleUploadFile={handleUploadFile}
                fileSelected={fileSelected}
                setFileSelected={setFileSelected}
                setErrorModal={setIsOpenErrorModal}
                setErrorTitle={setErrorTitle}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" className="prev-refresh-container">
        <Button
          disableRipple
          disabled={isLoadingRefresh ? true : false}
          startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
          className="refresh-other"
          onClick={handleRefresh}
        >
          {!isLoadingRefresh ? (
            t("App.catalogue.update")
          ) : (
            <CircularProgress size={23} color="inherit" />
          )}
        </Button>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="table-title"
      >
        <Grid item className="col-flex">
          <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
        </Grid>
        <Grid item className="col-start">
          <p className="display-small__primary-one">
            {t("App.salesForce.subtitle")}
          </p>
        </Grid>
      </Grid>
      {!noMasters && (
        <Grid
          item
          className="u-marginB grid__container__fullW"
          container
          style={{ marginTop: "2%" }}
        >
          <Accordion className="accordion__filter" style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />
              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.catalogue.filterName")}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <FilterSalesForce
                userInfo={userInfo}
                unfilteredData={unfilteredData}
                setData={setData}
                setFilterApply={setFilterApply}
                idFile={master === "0" ? 14 : 13}
                setFilterNotFound={setFilterNotFound}
                setIsLoadingData={setIsLoadingData}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      <Grid
        item
        className="u-marginB chips-container"
        style={{ width: "100%" }}
      >
        {filterApply && !noMasters && (
          <Chip className="chip__filter" label={filterApply.label} />
        )}
      </Grid>
      {!filterNotFound ? (
        !noMasters ? (
          <Grid item style={{ width: "100%" }}>
            {loading ? (
              <MUIDataTable
                style={{ width: "100% !important" }}
                data={data}
                columns={columns}
                options={skeletonOptions}
                className="dataTable__salesForce"
              />
            ) : (
              <MUIDataTable
                style={{ width: "100% !important" }}
                data={data}
                columns={columns}
                options={options}
                className="dataTable__salesForce"
              />
            )}
          </Grid>
        ) : (
          <Grid container className="col-flex">
            <NoInfoComponent type="load" />
          </Grid>
        )
      ) : (
        <NoMatchImage />
      )}

      <ModalInfo
        title={t("App.mastersUpload.title")}
        responseData={responseLoadStores}
        open={openSuccessLoadModal}
        onClose={() => {
          setOpenSuccessLoadModal(false);
        }}
      />
      <ModalInfo
        title={t("App.states.systemError")}
        responseData={{
          data: {
            responseCode: 0,
            responseMessage: t("App.validationMessages.systemError"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
      <ModalInfo
        title={errorTitle}
        responseData={{ status: 0 }}
        open={isOpenErrorModal}
        onClose={() => {
          setIsOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default UploadMasters;

import { FormHelperText, Grid, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { ModalContactsReport } from "../../../components/common/Modals/modalContactsReport";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import AddIcon from "@mui/icons-material/Add";

const ReportConfig = ({
  t,
  control,
  errors,
  fileName,
  setFileName,
  setValue,
  watch,
}) => {
  const userInfo = useSelector(getUser);

  const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  const [openModalExceedPeriodLimit, setOpenModalExceedPeriodLimit] =
    useState(false);
  const [openModalExceedEmails, setOpenModalExceedLimits] = useState(false);

  const [openModalContacts, setOpenModalContacts] = useState(false);

  const [openModalInvalidMail, setOpenModalInvalidMail] = useState(false);
  const [openErrorContacts, setOpenErrorContacts] = useState(false);

  /**
   *
   * Función que aplica una expresión regular en el nombre del archivo para verificar que no tenga carácteres especiales
   * si no hay carácteres especiales se procede a colocar el valor al state de fileName
   *
   * @param {*} e evento del campo de fileName
   */
  const onChangeFileName = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[%<>\\$'";=]/)) {
      setFileName(newValue);
      setValue("fileName", newValue);
    }
  };

  /**
   * Función para eliminar los contactos seleccionados
   */
  const handleDeleteAccounts = () => {
    setValue("accounts", "");
  };

  /**
   * Función para abrir un modal para seleccionar los contactos
   */
  const handleOpenContacts = () => {
    setOpenModalContacts(true);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={2}
    >
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Grid
          container
          direction="row"
          width="100%"
          justifyContent="flex-start"
          gap={2}
        >
          <Grid
            item
            xl={5.93}
            lg={5.89}
            md={5.86}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t(
                    "App.listDynamicPeriodicReport.cen.create.config.fileName"
                  )}
                </label>
              </Grid>
              <Grid item>
                <Controller
                  name="fileName"
                  control={control}
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          margin: "-5px 0 0 0",
                          fontSize: 12,
                        },
                      }}
                      className={
                        errors.fileName
                          ? "select-contact__filters__errors"
                          : "select-contact__filters"
                      }
                      value={fileName}
                      onChange={onChangeFileName}
                      FormHelperTextProps={{
                        className: "caption__blue3",
                        style: {
                          margin: "1px 0 1px 0px",
                        },
                      }}
                    />
                  )}
                />
                {errors.fileName && (
                  <FormHelperText
                    sx={{
                      margin: "0.5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    }}
                  >
                    {errors.fileName.message}
                  </FormHelperText>
                )}

                <FormHelperText
                  sx={{
                    margin: "3px 0 1px 0px",
                    color: "#a3a3a3",
                    fontSize: 11,
                  }}
                >
                  {t("App.dynamicPeriodicReport.config.fileHelper")}{" "}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xl={5.93}
            lg={5.89}
            md={5.86}
            sm={5.82}
            xs={12}
            width={"100%"}
          >
            <Grid
              container
              direction="row"
              width="100%"
              gap={{ sm: 2, xs: 1, md: 2 }}
            >
              <Grid
                item
                width="100%"
                xl={10.95}
                lg={10.75}
                md={10.1}
                sm={9.73}
                xs={9.5}
              >
                <Grid container direction="column">
                  <Grid item>
                    <label className="form__label label__text-grey">
                      {t(
                        "App.listDynamicPeriodicReport.cen.create.config.emails"
                      )}
                    </label>
                  </Grid>
                  <Grid item width="100%">
                    <Grid container direction="row" width="100%">
                      <Grid item width="100%">
                        <Controller
                          name="accounts"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled
                              defaultValue=""
                              autoComplete="off"
                              className={
                                errors.accounts
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              value={value || ""}
                              InputProps={{
                                style: {
                                  margin: "-5px 0 0 0",
                                  fontSize: 12,
                                },
                                endAdornment: watch("accounts") && (
                                  <IconButton
                                    className="btn__outlined__blue"
                                    sx={{
                                      color: "white",
                                      cursor: "pointer",
                                      border: "none !important",
                                    }}
                                    onClick={handleDeleteAccounts}
                                  >
                                    <ClearIcon sx={{ fontSize: 16 }} />
                                  </IconButton>
                                ),
                              }}
                            />
                          )}
                        />
                        {errors.accounts && (
                          <FormHelperText
                            sx={{
                              margin: "0.5px 0 1px 0px",
                              color: "#e52900",
                              fontSize: 10,
                            }}
                          >
                            {errors.accounts.message}
                          </FormHelperText>
                        )}

                        <FormHelperText
                          sx={{
                            margin: "3px 0 1px 0px",
                            color: "#a3a3a3",
                            fontSize: 11,
                          }}
                        >
                          {t("App.dynamicPeriodicReport.config.accountsHelper")}{" "}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={0.8}
                lg={0.83}
                paddingTop={{ md: 2.2, sm: 3.2, xs: 3.3 }}
              >
                <IconButton
                  className="btn__filled__blue"
                  sx={{
                    fontSize: 100,
                    color: "white",
                    width: "100%",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenContacts}
                >
                  <AddIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item width="100%">
        <Grid
          container
          direction="row"
          justifyContent={"left"}
          alignItems="center"
          className="tooltip__info"
        >
          <Grid
            item
            lg={0.35}
            md={0.7}
            sm={0.9}
            xs={1.7}
            height={"100%"}
            alignContent={"start"}
            textAlign={"start"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <InfoOutlinedIcon fontSize="large" />
          </Grid>

          <Grid
            item
            textAlign={"left"}
            lg={11.3}
            md={11.3}
            sm={11.1}
            xs={10.3}
            paddingY={0.6}
          >
            <p>
              {" "}
              {t("App.listDynamicPeriodicReport.cen.create.period.config")}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <ModalContactsReport
        open={openModalContacts}
        setOpen={setOpenModalContacts}
        setValue={setValue}
        watch={watch}
        userInfo={userInfo}
        setOpenModalError={setOpenErrorContacts}
      />
    </Grid>
  );
};

export default ReportConfig;

import {
  Chip,
  FormControlLabel,
  Grid,
  Skeleton,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileState from "../../../components/common/fileState";
import { FilterAdminSpecialFilesClient } from "../../../components/common/Filters/filterAdminSpecialFilesClient";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { CODES } from "../../../consts/codes";
import { ListSpecialFilesClient } from "../../../services/user/specialFilesClient";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { fetchUser, getUser, getUserStatus } from "../../userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadLogs,
  DownloadReports,
} from "../../../services/user/listDynamicReportService";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import PeriodicReportState from "../../../components/common/DynamicReport/periodicReportState";

const AdminSpecialFilesClient = () => {
  /**
   * Mocks
   */

  const mock = [
    {
      pk: "ean_provider#1234568",
      sk: "special_file#f9f2657d-df37-478c-a8db-efb8fb5c49ef",
      eanProvider: "1234568",
      generationFrecuency: "Mensual",
      generationDay: "12",
      typeOfDataGrouping: "Diario",
      fileName: null,
      pathFileName: null,
      logFileName: null,
      pathLogFileName: null,
      historicalExecutions: null,
      configurationSpecialFileDTO: {
        eanProvider: "1234568",
        country: "CO",
        typeOfReport: "sales",
        generationFrequency: "Mensual",
        generationDay: "12",
        typeOfDataGrouping: "Diario",
        amountOfPeriodsToGenerate: 1,
        checkTrader: 1,
        checkPointSale: 1,
        checkChanel: 1,
        checkFormat: 1,
        checkDepartamentState: 0,
        checkCity: 0,
        checkSalesForceHierarchy: 0,
        checkSalesMan: 0,
        checkCategoriesProduct: 0,
        checkProduct: 0,
        checkBrand: 0,
        checkColor: 0,
        checkSize: 0,
        checkDispatchUnit: 0,
        checkStandardUnit: 0,
        checkWeight: 0,
        checkVolume: 0,
        state: "ACTIVO",
        creationUser: null,
      },
      creationUser: "francisco Diaz",
      creationDate: "2023-03-23 10:49:00",
      modificationDate: null,
      modificationUser: null,
      state: "ACTIVO",
      procesingState: null,
    },
  ];

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Table helper functions
   */

  /**
   * Descarga el archivo especial
   * @param {*} fileName nombre del archivo
   * @param {*} path ubicación del archivo
   */
  const handleDownloadFile = async (fileName, path) => {
    const obj = {
      pathFileName: path,
      generatedFileName: fileName,
    };

    try {
      const fileService = await DownloadReports(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Descarga el log del archivo especial
   * @param {*} fileName nombre del archivo
   * @param {*} path ubicación del archivo
   */
  const handleDownloadLogs = async (fileName, path) => {
    const obj = {
      pathLogFileName: path,
      logFileName: fileName,
    };

    try {
      const fileService = await DownloadLogs(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Table
   */

  const columns = [
    {
      name: "creationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column1"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "userCreation",
      label: t("App.listDynamicPeriodicReport.table.columns.column2"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "modificationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column3"),

      options: {
        filter: false,
        hint: t("App.specialFilesClient.modificationDateTooltip"),
        sort: true,
        searchable: false,
      },
    },
    {
      name: "userModification",
      label: t("App.listDynamicPeriodicReport.table.columns.column4"),

      options: {
        filter: false,
        hint: t("App.specialFilesClient.userModificationTooltipAdmin"),
        sort: false,
        searchable: false,
      },
    },
    {
      name: "company",
      label: t("App.listUsers.enterpriseN"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "generationFrequency",
      label: t("App.listDynamicPeriodicReport.table.columns.column6"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "dataPooling",
      label: t("App.listDynamicPeriodicReport.table.columns.column7"),
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "file",
      label: t("App.listDynamicPeriodicReport.table.columns.column8"),

      options: {
        filter: false,
        hint: t("App.specialFilesClient.generatedFileTootip"),
        sort: false,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value.fileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) => {
                    if (value.pathFileName) {
                      handleDownloadFile(value.fileName, value.pathFileName);
                    }
                  }}
                >
                  {value.fileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value.fileName}</>
          ),
      },
    },
    {
      name: "state",
      label: t("App.adminCompanies.table.status"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <PeriodicReportState value={value} />
        ),
      },
    },
    {
      name: "logFile",
      label: t("App.listDynamicPeriodicReport.table.columns.column10"),

      options: {
        filter: false,
        hint: t("App.specialFilesClient.errorFileTooltip"),
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value.logFileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) => {
                    if (value.logFileName) {
                      handleDownloadLogs(
                        value.logFileName,
                        value.pathLogFileName
                      );
                    }
                  }}
                >
                  {value.logFileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value.fileName}</>
          ),
      },
    },

    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.specialFilesClient.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  /**
   * Use State
   */

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterApply, setFilterApply] = useState({
    creationDate: "",
    state: "",
  });

  const [noContent, setNoContent] = useState(false);
  const [noFilter, setNoFilter] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setNoContent(false);
        setIsLoadingData(true);
        const { eanCompany, country } = userInfo.company;
        const email = userInfo.email;
        const listSpecialFilesService = await ListSpecialFilesClient({
          eanProvider: eanCompany,
          country: country,
          email: email,
        });

        if (
          listSpecialFilesService.status === CODES.COD_RESPONSE_HTTP_OK &&
          listSpecialFilesService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const specialFilesList = listSpecialFilesService.data.responseMessage;
          const processedRows = dataStructure(specialFilesList);
          setData(processedRows);
          setUnfilteredData(processedRows);
        } else if (
          listSpecialFilesService.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_LOGIN
        ) {
          setNoContent(true);
        } else {
          setData([]);
          setUnfilteredData([]);
        }

        setIsLoadingData(false);
      } catch (error) {
        console.log(
          "==============Error Get SpecialFiles List======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };
    if (userInfo) {
      fetchData();
    }
  }, [dispatch, userInfoStatus]);

  /**
   * Handles
   */

  const dataStructure = (data) => {
    const processedRows = data.map((specialFile) => {
      return {
        creationDate: specialFile.creationDate,
        userCreation: specialFile.creationUser,
        modificationDate: specialFile.modificationDate,
        userModification: specialFile.modificationUser,
        generationFrequency: specialFile.generationFrecuency,
        company: specialFile.company,
        dataPooling: specialFile.typeOfDataGrouping,
        file: !specialFile.fileName
          ? " "
          : {
              fileName: specialFile.fileName,
              pathFileName: specialFile.pathFileName,
            },
        state: specialFile.state,
        logFile: specialFile.awsUploadLog
          ? {
              awsUploadLog: specialFile.awsUploadLog,
            }
          : {
              logFileName: specialFile.logFileName,
              pathLogFileName: specialFile.pathLogFileName,
            },
        edit: (
          <IconButton
            onClick={() =>
              handleEditSpecialFile(specialFile.pk, specialFile.sk)
            }
          >
            <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
          </IconButton>
        ),
      };
    });

    return processedRows;
  };

  const handleCreateSpecialFile = () => {
    navigate("/user/createSpecialFiles");
  };

  const handleEditSpecialFile = (pk, sk) => {
    const finalPk = pk.split("#")[1];
    const finalSk = sk.split(`#`)[1];
    navigate(`/user/editSpecialFiles/${finalPk}/${finalSk}`);
  };

  return (
    <Grid container direction="column">
      <Grid item style={{ marginTop: "2%" }}>
        <p className="display-large__primary-one">
          {t("App.specialFilesClient.titleSpecialFiles")}
        </p>
      </Grid>

      <Grid item className="full-width">
        <Grid container className="create-btn-container">
          <Grid item style={{ marginBottom: "2%" }}>
            <Button
              startIcon={<AddIcon sx={{ fontSize: 22 }} />}
              className="btn__filled__blue btn__applyFilter"
              onClick={handleCreateSpecialFile}
            >
              {t("App.specialFilesClient.buttonAdminSpecialFiles")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="companies_info_container full-width">
        <Grid container direction="column">
          <Grid item className="filter-container__specialFiles">
            <FilterAdminSpecialFilesClient
              userInfo={userInfo}
              unfilteredData={unfilteredData}
              setData={setData}
              dataStructure={dataStructure}
              setIsLoadingData={setIsLoadingData}
              setFilterApply={setFilterApply}
              setNoContent={setNoContent}
              setNoFilter={setNoFilter}
            />
          </Grid>

          <Grid item>
            <Grid container direction="row" style={{ columnGap: "2%" }}>
              {filterApply.creationDate && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t(
                      "App.listDynamicPeriodicReport.table.columns.column1"
                    )}: ${filterApply.creationDate}`}
                    variant="outlined"
                  />
                </Grid>
              )}

              {filterApply.state && (
                <Grid item>
                  {" "}
                  <Chip
                    className="chip__filter"
                    label={`${t("App.adminCompanies.filters.status")}: ${
                      filterApply.state
                    }`}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: "2%", width: "99%" }}>
            {!noContent ? (
              !noFilter ? (
                isLoadingData ? (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    data={data}
                    columns={columns}
                    options={skeletonOptions}
                  />
                ) : (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )
              ) : (
                <NoMatchImage />
              )
            ) : (
              <NoInfoComponent type="specialFiles" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminSpecialFilesClient;

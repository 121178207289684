import { React, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import usePagination from "../../../hooks/Pagination";
import { SkeletonModalSelectList } from "./skeletonModalSelectList";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  borderRadius: "12px",
  bgcolor: "white",
  padding: "1rem !important",

  p: 4,
};

export const ModalSelectList = (props) => {
  const dispatch = useDispatch();
  const PER_PAGE = 15;
  let [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const [listItem, setListItem] = useState([]);

  const [filteredListItem, setFilteredListItem] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const count = Math.ceil(filteredListItem.length / PER_PAGE);
  const _DATA = usePagination(filteredListItem, PER_PAGE);
  const [search, setSearch] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    listItem.forEach((item) =>
      props.setValue(`select.${props.item.register}.${item.label}`, false)
    );

    props.setValue(`select.${props.item.register}.all`, false);

    if (selectedItems.length > 0) {
      dispatch(props.setSelected([]));
    }
    setSelectedItems([]);
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);

    if (selectedItems.length > 0) {
      dispatch(props.setSelected(selectedItems));
    } else {
      dispatch(props.setSelected([]));
    }
    setSelectedItems([]);
  };
  const { t } = useTranslation();

  const selectAll = (checked) => {
    if (checked) {
      listItem.forEach((item) =>
        props.setValue(`select.${props.item.register}.${item.label}`, true)
      );

      setSelectedItems([]);
    } else {
      listItem.forEach((item) =>
        props.setValue(`select.${props.item.register}.${item.label}`, false)
      );

      setSelectedItems([]);
    }
  };

  const addselected = (checked, label) => {
    if (checked) {
      props.setValue(`select.${props.item.register}.${label}`, true);
      setSelectedItems((oldArray) => [...oldArray, label]);
    } else {
      handleRemove(label);
    }
  };

  const handleRemove = (label) => {
    const newArray = selectedItems.filter((item) => item.label !== label.label);

    setSelectedItems(newArray);
  };

  const handleSearch = (word) => {
    const searchKey = word;
    if (searchKey === "") {
      setFilteredListItem(listItem);
    } else {
      const filteredList = listItem.filter((item) => {
        if (item.label.toLowerCase().includes(word.toLowerCase())) {
          return item;
        }
      });
      setFilteredListItem(filteredList);
    }
  };
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    let dataItems = [];
    const propsData = props.data ? props.data : [];
    setSelectedItems([]);

    if (props.item.register === "merchant") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.trader,
          ean: item.eanTrader,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "pointOfSale") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.pointSaleName,
          ean: item.eanPointSale,
          selected: false,
        };

        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "department") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.levelOne,
          ean: item.idLevelOne,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "city") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.levelFour,
          ean: item.idLevelFour,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "products") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.productName,
          ean: item.eanProduct,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "sellers") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.salesMan,
          ean: item.salesManCode,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else {
      dataItems = propsData.map((item) => {
        let value = {
          label: item,
          selected: false,
        };

        if (props.item.register === "EanPointOfSale") {
        }

        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    }

    setListItem(dataItems);
    setFilteredListItem(dataItems);
  }, [props.data, props.selected]);

  useEffect(() => {
    let dataItems = [];
    const propsData = props.data ? props.data : [];
    setSelectedItems([]);

    if (props.item.register === "merchant") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.trader,
          ean: item.eanTrader,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "pointOfSale") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.pointSaleName,
          ean: item.eanPointSale,
          selected: false,
        };

        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "department") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.levelOne,
          ean: item.idLevelOne,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "city") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.levelFour,
          ean: item.idLevelFour,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "products") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.productName,
          ean: item.eanProduct,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else if (props.item.register === "sellers") {
      dataItems = propsData.map((item) => {
        let value = {
          label: item.salesMan,
          ean: item.salesManCode,
          selected: false,
        };
        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    } else {
      dataItems = propsData.map((item) => {
        let value = {
          label: item,
          selected: false,
        };

        if (props.item.register === "EanPointOfSale") {
        }

        if (props.getValues(`select.${props.item.register}.${value.label}`)) {
          addselected(true, value);
        }

        return value;
      });
    }

    setListItem(dataItems);
    setFilteredListItem(dataItems);
  }, [props.data, props.selected]);

  return (
    <div>
      <IconButton onClick={handleOpen} component="span">
        <AddOutlinedIcon
          sx={{
            fontSize: 25,
            backgroundColor: "#543ab4",
            color: "white",
            borderRadius: "3px",
          }}
        />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="modal__responsive">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton onClick={handleClose} component="span">
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid
              item
              container
              justifyContent="flex-start"
              className="heading-secondary__md"
            >
              <p> {t("App.dynamicPeriodicReport.scope.selectList")}</p>
            </Grid>

            <Grid
              xs={12}
              item
              container
              justifyContent="flex-start"
              className="u-marginT"
            >
              <Grid item xs={12}>
                <TextField
                  placeholder={t("App.dynamicPeriodicReport.scope.search")}
                  type="text"
                  sx={{
                    border: "1px solid #C5C7D0",
                    borderRadius: "4px",
                  }}
                  disabled={props.loading === "fetch"}
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: search && (
                      <IconButton
                        onClick={() => {
                          setSearch("");
                          setFilteredListItem(listItem);
                        }}
                      >
                        <ClearOutlinedIcon sx={{ color: "#543AB4" }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ padding: "1rem", border: "" }}>
                <FormControlLabel
                  control={
                    <Controller
                      defaultValue={false}
                      name={`select.${props.item.register}.all`}
                      control={props.control}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          checked={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                            selectAll(e.target.checked);
                          }}
                        />
                      )}
                    />
                  }
                  label={
                    <Typography sx={{ color: "black", fontSize: "13px" }}>
                      {t("App.dynamicPeriodicReport.scope.all")}
                    </Typography>
                  }
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sx={{ overflowY: "scroll", maxHeight: "30rem" }}
              >
                {filteredListItem &&
                  props.loading !== "fetch" &&
                  props.data &&
                  props.data.length > 0 &&
                  _DATA.currentData().map((item, idx) => {
                    return (
                      <Grid
                        key={item.label}
                        item
                        xs={12}
                        sx={{
                          padding: "1rem",
                          boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Controller
                              name={`select.${props.item.register}.${item.label}`}
                              defaultValue={item.selected}
                              control={props.control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) => {
                                    props.onChange(e.target.checked);
                                    addselected(e.target.checked, item);
                                  }}
                                />
                              )}
                            />
                          }
                          label={
                            <Typography
                              sx={{ color: "black", fontSize: "13px" }}
                            >
                              {item.label}
                            </Typography>
                          }
                        />
                      </Grid>
                    );
                  })}
                {props.loading === "fetch" && <SkeletonModalSelectList />}

                {props.loading !== "fetch" &&
                  props.data &&
                  props.data.length === 0 && (
                    <Grid
                      className="u-box-padding"
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <p className="heading__primary-one text-center">
                          {" "}
                          {t("App.dynamicPeriodicReport.scope.noMatch")}
                        </p>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className="u-marginT"
              spacing={2}
            >
              <Grid item>
                <p>
                  {t("App.dynamicPeriodicReport.scope.pagination", {
                    total: filteredListItem.length,
                    amount:
                      filteredListItem.length < PER_PAGE
                        ? filteredListItem.length
                        : PER_PAGE,
                  })}
                </p>
              </Grid>

              <Grid item>
                <Pagination
                  count={count}
                  size="large"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="center"
              spacing={2}
              style={{ paddingTop: "3rem" }}
            >
              <Grid item>
                <Button
                  type="button"
                  disableRipple
                  className="btn__filled__gradient"
                  onClick={handleClose}
                >
                  {t("App.buttonLabels.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleAccept}
                  disableRipple
                  className="btn__filled__blue"
                >
                  {t("App.buttonLabels.accept")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

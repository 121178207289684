import { Card, CardContent, Grid } from "@mui/material";
import { useEffect, useState } from "react";

export const CardsSection = ({ t, requestInfo }) => {
  /**
   * Componentes Auxiliares
   */

  const CardInfo = ({ titleTop, titleBottom, value }) => {
    return (
      <Card className="border-radius">
        <CardContent>
          <Grid
            container
            direction="column"
            rowSpacing={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item className="display-small__text-default text-center">
              <span>
                <p>{titleTop}</p>
                <p>{titleBottom}</p>
              </span>
            </Grid>
            <Grid item className="display-small__primary-one">
              {value}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  /**
   * Use State
   */

  // TARJETAS

  //Fila 1
  const [issuedDocuments, setIssuedDocuments] = useState("-");
  const [invoiceCreditPayment, setInvoiceCreditPayment] = useState("-");
  const [invoiceCashPayment, setInvoiceCashPayment] = useState("-");
  const [valueInvoiceIssue, setValueInvoiceIssue] = useState("-");

  //Fila 2
  const [invoiceWithEvents, setInvoiceWithEvents] = useState("-");
  const [invoiceTitleValue, setInvoiceTitleValue] = useState("-");
  const [invoiceClaimed, setInvoiceClaimed] = useState("-");
  const [debitCreditNotes, setDebitCreditNotes] = useState("-");

  /**
   * Use Effect
   */

  useEffect(() => {
    setIssuedDocuments(requestInfo.issuedDocuments);
    setInvoiceCreditPayment(requestInfo.invoiceCreditPayment);
    setInvoiceCashPayment(requestInfo.invoiceCashPayment);
    setValueInvoiceIssue(requestInfo.valueInvoiceIssue);

    setInvoiceWithEvents(requestInfo.invoiceWithEvents);
    setInvoiceTitleValue(requestInfo.invoiceTitleValue);
    setInvoiceClaimed(requestInfo.invoiceClaimed);
    setDebitCreditNotes(requestInfo.debitCreditNotes);
  }, [requestInfo]);

  return (
    <Grid container direction={"column"} rowSpacing={1}>
      <Grid item>
        <Grid container columnSpacing={2}>
          <Grid item md={6}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card1-one")}
              titleBottom={t("App.homeEmission.cards.card1-two")}
              value={issuedDocuments}
            />
          </Grid>
          <Grid item md={6}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card2-one")}
              titleBottom={t("App.homeEmission.cards.card3-two")}
              value={invoiceCashPayment}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={2}>
          <Grid item md={6}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card4-one")}
              titleBottom={t("App.homeEmission.cards.card4-two")}
              value={valueInvoiceIssue}
            />
          </Grid>
          <Grid item md={6}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card5-one")}
              titleBottom={t("App.homeEmission.cards.card5-two")}
              value={invoiceWithEvents}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={2}>
          <Grid item md={4}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card8-one")}
              titleBottom={t("App.homeEmission.cards.card8-two")}
              value={debitCreditNotes}
            />
          </Grid>
          <Grid item md={4}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card6-one")}
              titleBottom={t("App.homeEmission.cards.card6-two")}
              value={invoiceTitleValue}
            />
          </Grid>
          <Grid item md={4}>
            <CardInfo
              titleTop={t("App.homeEmission.cards.card6-one")}
              titleBottom={t("App.homeEmission.cards.card7-two")}
              value={invoiceClaimed}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

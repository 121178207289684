import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Obtener la lista de trazabilidades que coincidan con los filtros
 * @param {*} data información de los filtros
 * @returns
 */
export async function GetListTraceabilities(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `traceabilities`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Obtener el detalle de la trazabilidad
 * @param {*} data información de la trazabilidad
 * @returns
 */
export async function DetailTraceability(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `traceabilities/details`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Función para consumir el servicio que devuelve la lista de comerciantes
 * @returns resultado de la petición de axios (lista de proveedores)
 */
export async function GetListTrader() {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `companies/traders`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función para consumir el servicio que devuelve la lista de remitentes
 * @returns resultado de la petición de axios (lista de remitentes)
 */

export async function GetListReceiver() {
  //const token = await GetToken();
  return axios({
    method: "GET",
    url: `companies/supports`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    // headers: {
    //   Authorization: token,
    // },
  });
}

/**
 *  Funció para consumuir el servicio para descargar el archivo
 * @param {*} data
 * @returns
 */
export async function DownloadFile(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const StateComponent = ({ value }) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  const { cancelUser, modificationDate } = value;

  return (
    <>
      {value.state.toLowerCase() === "pendiente cancelado" ||
      value.state.toLowerCase() === "cancelado" ? (
        <Tooltip
          title={t("App.applicationDeleteAndReload.tooltip", {
            user: cancelUser,
            dateCancel: modificationDate
              ? value.modificationDate
              : "2023-11-11",
          })}
        >
          <VisibilityOutlinedIcon sx={{ color: "#553AB6", fontSize: 22 }} />
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export default StateComponent;

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function GetRoleList() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `roles`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio que retorna la lista de opciones de menús de acuerdo al producto seleccionado
 *
 * @param {Object} data
 * @returns petición de axios con la lista de opciones de menús
 */
export async function GetMenuByProduct(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "menus/products",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function FilterRole(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `roles/filter`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DeleteRole(data) {
  const token = await getToken();

  return axios({
    method: "DELETE",
    url: "roles",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateRole(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "roles",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditRole(data) {
  const token = await getToken();

  return axios({
    method: "PUT",
    url: `roles`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

const QuestionsInfo = () => {
  return {
    es: [
      {
        icon: "icono1",
        name: "Ventas e Inventarios",
        key: "salesstock",
        questions: [
          {
            title: "¿Cómo crear/configurar un usuario?",
            contentText: "hello world",
          },
        ],
      },
      {
        icon: "icono1",
        name: "Indicadores",
        key: "indicators",
        questions: [
          {
            title: "¿Cómo crear/configurar un producto?",
            contentText: "hello world",
          },
        ],
      },
      {
        icon: "icono1",
        name: "Devoluciones",
        key: "returns",
        questions: [
          {
            title: "¿Cómo crear/configurar una devolución?",
            contentText:
              "Debes seleccionar el botón + Crear usuario ubicado en la parte superior derecha en la pantalla “Administrar usuarios clientes” y diligenciar la información requerida en el formulario e ir seleccionando cada producto al que quieres que el usuario tenga acceso desde la opción más (+), asociando cada rol y el estado. Cuando hayas diligenciado correctamente el formulario, debes finalizar con el botón de Crear para que el usuario pueda recibir un correo electrónico con sus credenciales de acceso. Nota 1: Debes tener un cuenta que el correo debe quedar bien escrito dado que será el usuario con el que la persona ingresará a la plataforma. Nota 2: Si el usuario pertenece a un grupo empresarial, también podrás escoger las empresas del grupo a las personas que podrá acceder.",
            contentHTML: (
              <span>
                <p>
                  Debes seleccionar el botón <strong>+ Crear usuario</strong>{" "}
                  ubicado en la parte superior derecha en la pantalla
                  “Administrar usuarios clientes” y diligenciar la información
                  requerida en el formulario e ir seleccionando cada producto al
                  que quieres que el usuario tenga acceso desde la opción más{" "}
                  <strong>(+)</strong>, asociando cada rol y el estado. Cuando
                  hayas diligenciado correctamente el formulario, debes
                  finalizar con el botón de <strong>Crear</strong> para que el
                  usuario pueda recibir un correo electrónico con sus
                  credenciales de acceso.
                </p>
                <br />
                <p>
                  <strong>Nota 1:</strong> Debes tener un cuenta que el correo
                  debe quedar bien escrito dado que será el usuario con el que
                  la persona ingresará a la plataforma.
                  <br />
                  <strong>Nota 2:</strong> Si el usuario pertenece a un grupo
                  empresarial, también podrás escoger las empresas del grupo a
                  las personas que podrá acceder.
                </p>
              </span>
            ),
          },
          {
            title: "¿Cómo crear/configurar una personas nómina?",
            contentText: "hello usuario",
          },
          {
            title: "¿Cómo crear/configurar una personas factura?",
            contentText: "hello devolución",
            contentHTML: <span>hello devolucion</span>,
          },
          {
            title: "¿Cómo crear/configurar una devoluciones?",
            contentText: "hello factura",
            contentHTML: <span>hello factura</span>,
          },
          {
            title: "¿Cómo crear/configurar una factura hoy?",
            contentText: "hello venta",
            contentHTML: <span>hello venta</span>,
          },
          {
            title: "¿Cómo crear/configurar una nómina?",
            contentText: "hello world",
            contentHTML: <span>hello world</span>,
          },
          {
            title: "¿Cómo crear/configurar una venta?",
            contentText: "hello ventas",
            contentHTML: <span>hello ventas</span>,
          },
        ],
      },
      {
        icon: "icono1",
        name: "Factura",
        key: "invoice",
        questions: [
          {
            title: "¿Cómo crear/configurar una factura?",
            contentText: "hello world",
            contentHTML: <span>hello devolucion</span>,
          },
        ],
      },
      {
        icon: "icono1",
        name: "Nómina",
        key: "payroll",
        questions: [
          {
            title: "¿Cómo crear/configurar una nómina?",
            contentText: "hello world",
            contentHTML: <span>hello devolucion</span>,
          },
        ],
      },
    ],

    en: [
      {
        icon: "icono1",
        name: "Ventas e Inventarios",
        key: "salesstock",
        questions: [
          {
            title: "¿Cómo crear/configurar un usuario?",
            contentText: "hello world",
          },
        ],
      },
      {
        icon: "icono1",
        name: "Indicadores",
        key: "indicators",
        questions: [
          {
            title: "¿Cómo crear/configurar un producto?",
            contentText: "hello world",
          },
        ],
      },
      {
        icon: "icono1",
        name: "Devoluciones",
        key: "returns",
        questions: [
          {
            title: "¿Cómo crear/configurar una devolución?",
            contentText:
              "Debes seleccionar el botón + Crear usuario ubicado en la parte superior derecha en la pantalla “Administrar usuarios clientes” y diligenciar la información requerida en el formulario e ir seleccionando cada producto al que quieres que el usuario tenga acceso desde la opción más (+), asociando cada rol y el estado. Cuando hayas diligenciado correctamente el formulario, debes finalizar con el botón de Crear para que el usuario pueda recibir un correo electrónico con sus credenciales de acceso. Nota 1: Debes tener un cuenta que el correo debe quedar bien escrito dado que será el usuario con el que la persona ingresará a la plataforma. Nota 2: Si el usuario pertenece a un grupo empresarial, también podrás escoger las empresas del grupo a las personas que podrá acceder.",
            contentHTML: (
              <span>
                <p>
                  Debes seleccionar el botón <strong>+ Crear usuario</strong>{" "}
                  ubicado en la parte superior derecha en la pantalla
                  “Administrar usuarios clientes” y diligenciar la información
                  requerida en el formulario e ir seleccionando cada producto al
                  que quieres que el usuario tenga acceso desde la opción más{" "}
                  <strong>(+)</strong>, asociando cada rol y el estado. Cuando
                  hayas diligenciado correctamente el formulario, debes
                  finalizar con el botón de <strong>Crear</strong> para que el
                  usuario pueda recibir un correo electrónico con sus
                  credenciales de acceso.
                </p>
                <br />
                <p>
                  <strong>Nota 1:</strong> Debes tener un cuenta que el correo
                  debe quedar bien escrito dado que será el usuario con el que
                  la persona ingresará a la plataforma.
                  <br />
                  <strong>Nota 2:</strong> Si el usuario pertenece a un grupo
                  empresarial, también podrás escoger las empresas del grupo a
                  las personas que podrá acceder.
                </p>
              </span>
            ),
          },
          {
            title: "¿Cómo crear/configurar una personas nómina?",
            contentText: "hello usuario",
          },
          {
            title: "¿Cómo crear/configurar una personas factura?",
            contentText: "hello devolución",
            contentHTML: <span>hello devolucion</span>,
          },
          {
            title: "¿Cómo crear/configurar una devoluciones?",
            contentText: "hello factura",
            contentHTML: <span>hello factura</span>,
          },
          {
            title: "¿Cómo crear/configurar una factura hoy?",
            contentText: "hello venta",
            contentHTML: <span>hello venta</span>,
          },
          {
            title: "¿Cómo crear/configurar una nómina?",
            contentText: "hello world",
            contentHTML: <span>hello world</span>,
          },
          {
            title: "¿Cómo crear/configurar una venta?",
            contentText: "hello ventas",
            contentHTML: <span>hello ventas</span>,
          },
        ],
      },
      {
        icon: "icono1",
        name: "Factura",
        key: "invoice",
        questions: [
          {
            title: "¿Cómo crear/configurar una factura?",
            contentText: "hello world",
            contentHTML: <span>hello devolucion</span>,
          },
        ],
      },
      {
        icon: "icono1",
        name: "Nómina",
        key: "payroll",
        questions: [
          {
            title: "¿Cómo crear/configurar una nómina?",
            contentText: "hello world",
            contentHTML: <span>hello devolucion</span>,
          },
        ],
      },
    ],
  };
};

export default QuestionsInfo;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { es } from "date-fns/locale";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FilterSpecialFilesClient } from "../../../services/user/specialFilesClient";
import { CODES } from "../../../consts/codes";
import moment from "moment";
import "moment/locale/es";

export const FilterAdminSpecialFilesClient = ({
  userInfo,
  unfilteredData,
  setData,
  dataStructure,
  setIsLoadingData,
  setFilterApply,
  setNoContent,
  setNoFilter,
}) => {
  /**
   * Use Translation
   */

  const { t, i18n } = useTranslation();

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    creationDate: yup
      .date()
      .transform((value, originalValue) => {
        // Convert empty values to null
        if (value === "") {
          return null;
        }
        return originalValue;
      })
      .nullable()
      .optional(),
    state: yup.string(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { creationDate: null, state: "2" },
  });

  /**
   * Handles
   */

  const handleFilters = async (data) => {
    try {
      if (i18n.language.includes("es")) moment.locale("es");
      setData([]);
      setNoContent(false);
      setNoFilter(false);
      setIsLoadingData(true);

      const date =
        data.creationDate === null
          ? ""
          : moment(data.creationDate).format("YYYY-MM");
      const state =
        data.state === "1" ? "ACTIVO" : data.state === "0" ? "INACTIVO" : "";
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        email: userInfo.email,
        creationDate: date,
        state: state,
      };

      const filterFilesService = await FilterSpecialFilesClient(obj);

      const dateFilt =
        data.creationDate === null
          ? ""
          : moment(data.creationDate).format("MMMM YYYY");
      const dateCapitalized =
        dateFilt === ""
          ? dateFilt
          : dateFilt[0].toLocaleUpperCase() + dateFilt.slice(1).toLowerCase();
      setFilterApply({
        creationDate: dateCapitalized,
        state:
          data.state === "1" ? "Activo" : data.state === "0" ? "Inactivo" : "",
      });

      if (
        filterFilesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        filterFilesService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const filteredSpecialFiles = filterFilesService.data.responseMessage;
        const proccesedRows = dataStructure(filteredSpecialFiles);
        setData(proccesedRows);
      } else {
        setNoFilter(true);
        setData([]);
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log(
        "==============Error Filter Special Files======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  const handleCleanFilters = () => {
    reset();
    setData(unfilteredData);
    setNoFilter(false);
    setFilterApply({ creationDate: "", state: "" });
  };
  return (
    <Accordion defaultExpanded={true}>
      <Grid item className="">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.listUsers.filter")}
          </p>
        </AccordionSummary>

        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid
              md={8}
              sm={8}
              item
              className="side-line"
              style={{
                padding: "2%",
              }}
            >
              <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={2}
                  className="filter-container-adminSpecial"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t(
                            "App.listDynamicPeriodicReport.table.columns.column1"
                          )}
                        </label>
                      </Grid>

                      <Grid item md={6}>
                        <Controller
                          defaultValue=""
                          control={control}
                          name="creationDate"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            formState,
                          }) => (
                            <LocalizationProvider
                              dateFormats={"monthAndYear"}
                              dateAdapter={AdapterDateFns}
                              adapterLocale={es}
                            >
                              <DatePicker
                                views={["month", "year"]}
                                openTo="month"
                                inputFormat={`MMMM yyyy`}
                                className={
                                  errors.creationDate
                                    ? "filters__error filters-width"
                                    : "filters filters-width"
                                }
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    sx={{
                                      label: {
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "0px -4px",
                                        transformOrigin: "-45px -10px",
                                        color: "#8C8D9D !important",
                                        "&.Mui-focused": {
                                          color: "#8C8D9D",
                                        },
                                      },
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.adminCompanies.filters.status")}
                        </label>
                      </Grid>

                      <Grid item md={6}>
                        <Controller
                          defaultValue="2"
                          control={control}
                          name="state"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            formState,
                          }) => (
                            <Select
                              inputProps={{
                                style: {
                                  margin: "-5px 0 0 0 !important",
                                  fontSize: 12,
                                  border: "1px solid !important",
                                },
                              }}
                              value={value}
                              labelId="labelStatus"
                              label={t("App.adminCompanies.filters.status")}
                              className={
                                errors.state
                                  ? "filters__error filters-width"
                                  : "filters filters-width"
                              }
                              onChange={onChange}
                            >
                              <MenuItem disabled value={"2"}>
                                <em>{t("App.createUser.selectOption")}</em>
                              </MenuItem>
                              <MenuItem value={"1"}>
                                {t("App.adminCompanies.filters.active")}
                              </MenuItem>
                              <MenuItem value={"0"}>
                                {t("App.adminCompanies.filters.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid xs={4}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={2}
                className="confirmation-buttons"
              >
                <Grid item>
                  <Button
                    startIcon={
                      <TuneOutlinedIcon
                        sx={{
                          fontSize: 20,
                          color: "white",
                        }}
                      />
                    }
                    disableRipple
                    disabled={
                      watch("creationDate") || watch("state") !== "2"
                        ? false
                        : true
                    }
                    className={
                      watch("creationDate") || watch("state") !== "2"
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    }
                    type="submit"
                    form="hook-form"
                  >
                    {t("App.listUsers.applyFilters")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    disableRipple
                    disabled={
                      watch("creationDate") || watch("state") !== "2"
                        ? false
                        : true
                    }
                    className={
                      watch("creationDate") || watch("state") !== "2"
                        ? "btn__deleteFilter"
                        : "btn__deleteFilter__disabled"
                    }
                    onClick={handleCleanFilters}
                  >
                    {t("App.listUsers.cleanFilters")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Grid>
    </Accordion>
  );
};

import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { CODES } from "../../consts/codes";

import {
  ApplyFiltersDetail,
  ApplyFiltersProduct,
  GetRolesByProduct,
  GetUsersByCompany,
} from "../../services/admin/adminServices";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsAllAndRoles,
  getProductsAllAndRolesStatus,
  resetProductsAllAndRolesStatus,
} from "../../parts/listUsers/listUsersSlice";

import ModalInfo from "./Modals/modalInfo";
import { ModalExceededLimit } from "./modalExceededLimit";

export const FilterUserDetail = (props) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Const
   */
  const options = [
    { label: t("App.userDetail.filter.active"), value: 1 },
    { label: t("App.userDetail.filter.inactive"), value: 0 },
  ];

  /**
   * Use Params
   */

  const { companyId, country } = useParams();

  /**
   * Use State
   */
  const [roleList, setRoleList] = useState([]);
  const [productList, setProductsList] = useState([]);
  const [rolesMap, setRolesMap] = useState(new Map());

  const [actives, setActives] = useState(null);
  const [exceededLimit, setExcedeedLimit] = useState(false);

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    ean: yup.string().min(2),
    country: yup.string().min(2),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Carga los productos y sus roles
   */
  useEffect(() => {
    const fetchProducts = async () => {
      const finalRolesMap = new Map();
      const initialProductsList = await fetchProductsList(companyId, country);
      const finalProductsList = initialProductsList.map((product) => ({
        label: product.menuOptionName,
        value: product.idMenuOption,
      }));

      for (const product of finalProductsList) {
        const rolesList = await fetchRolesByIdMenuOption(product.value);

        const finalRolesList = rolesList.map((role) => ({
          label: role.nameRole,
          value: role.idRole,
        }));

        finalRolesMap.set(product.value, finalRolesList);
      }

      setProductsList(finalProductsList);
      setRolesMap(finalRolesMap);
    };
    fetchProducts();
  }, []);

  /**
   * Función trae los productos activos que tengan al menos un usuario disponible
   * @param {*} eanCompany ean de la empresa
   * @param {*} country país de la empresa
   * @returns lista de productos
   */
  const fetchProductsList = async (eanCompany, country) => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await ApplyFiltersProduct({
        eanCompany: eanCompany,
        country: country,
      });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        return responseMessage;
      } else {
        return [];
      }
    } catch (error) {
      console.log("============ Error filterUserDetail.jsx ============");
      console.log(error);
      console.log("========================================");
      return [];
    }
  };

  /**
   * Función que consulta la lista de roles según el idMenuOption
   * @param {*} idMenuOption opción de menú
   * @returns lista de roles
   */
  const fetchRolesByIdMenuOption = async (idMenuOption) => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetRolesByProduct({
        idMenuOption: idMenuOption,
        isClient: false,
      });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        return responseMessage;
      } else {
        return [];
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      return [];
    }
  };

  /**
   * Handles
   */

  /**
   * Filtra los usuarios según los filtros seleccionados
   * @param {*} data filtros seleccionados
   */
  const handleFilters = async (data) => {
    try {
      props.setFilterNotFound(false);
      props.setLoading(true);
      const obj = {
        country: country,
        eanCompany: props.company,
        state: data.state ? data.state.value : -1,
        idRole: data.role ? data.role.value : "",
        product: data.product ? data.product.value : "",
      };

      const companiesService = await ApplyFiltersDetail(obj);
      props.setLoading(false);

      if (
        companiesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        companiesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const responseMessage = companiesService.data.responseMessage;
        const processRows = props.setDataStructureArray(responseMessage);
        props.setData(processRows);

        if (processRows.length === 0) {
          props.setFilterNotFound(true);
        }
      } else {
        props.setData([]);
        props.setFilterNotFound(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función que retorna la lista de roles según el producto
   * @param {*} idMenuOption id del prducto
   * @returns lista de roles
   */
  const getRolesListByProduct = (idMenuOption) => {
    const rolesList = rolesMap.get(idMenuOption);

    return rolesList ? rolesList : [];
  };

  /**
   * Función que limpia los campos del filtro
   */
  const cleanFields = () => {
    setValue("product", null);
    setValue("state", null);
    setValue("role", null);
  };

  /**
   * Función que habilita el botón de aplicar filtro
   */

  const disableApplyFiltersBtn = () => {
    if (watch("product") && watch("state") && watch("role")) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        xl={10}
        lg={9}
        sm={8}
        xs={12}
        className="side-line-contact__bottomAlign"
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Grid item xl={3.9} lg={3.9} sm={6} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width="100%"
              >
                <Grid item>
                  {" "}
                  <label className="form__label">
                    {" "}
                    {t("App.userDetail.product") + "*"}
                  </label>
                </Grid>
                <Grid item width="100%">
                  <Controller
                    name={"product"}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={`${
                          errors.product
                            ? "select__filters__errors__full"
                            : "select__filters__full"
                        } full-width`}
                        options={productList}
                        renderInput={(params) => {
                          return (
                            <TextField
                              placeholder={t("App.userDetail.product")}
                              {...params}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                          if (values) {
                            setValue("role", null);
                            setValue("state", null);
                            setRoleList(getRolesListByProduct(values.value));
                          } else {
                            setRoleList([]);
                            cleanFields();
                          }
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={3.9} lg={3.9} sm={6} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  {" "}
                  <label className="form__label">
                    {" "}
                    {t("App.userDetail.status") + "*"}
                  </label>
                </Grid>
                <Grid item width="100%">
                  <Controller
                    name={"state"}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={`${
                          errors.status
                            ? "select__filters__errors_full"
                            : "select__filters__full"
                        } full-width`}
                        options={options}
                        renderInput={(params) => {
                          return (
                            <TextField
                              placeholder={t("App.userDetail.status")}
                              {...params}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={3.9} lg={3.9} sm={12} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  {" "}
                  <label className="form__label">
                    {" "}
                    {t("App.userDetail.role") + "*"}
                  </label>
                </Grid>
                <Grid item width="100%">
                  <Controller
                    name={"role"}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={`${
                          errors.errors
                            ? "select__filters__errors__full"
                            : "select__filters__full"
                        } full-width`}
                        options={roleList}
                        renderInput={(params) => {
                          return (
                            <TextField
                              placeholder={t("App.userDetail.role")}
                              {...params}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xl={2} lg={3} sm={3} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disabled={disableApplyFiltersBtn()}
              disableRipple
              className={`${
                disableApplyFiltersBtn()
                  ? "btn__applyFilter-disabled"
                  : "btn__applyFilter"
              } dimentions-btn__applyFilter`}
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                cleanFields();
                props.setData(props.unfilteredData);
                props.setFilterNotFound(false);
              }}
              disableRipple
              className="btn__deleteFilter dimentions-btn__cleanFilter"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ModalExceededLimit
        actives={actives}
        company={props.company}
        open={exceededLimit}
        setOpen={setExcedeedLimit}
      />
    </Grid>
  );
};

import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";

const PeriodicReportState = ({ value }) => {
  const { t } = useTranslation();

  const transformValue = value && !value.$$typeof ? value.toLowerCase() : "";
  let text = "";
  let tooltipColor = "";
  let tooltipText = "";
  let backgroundColor = "";

  if (
    transformValue === "activo" ||
    transformValue === "activo proximo a vencer"
  ) {
    backgroundColor =
      transformValue === "activo"
        ? "state-colors__primary-color-one"
        : "state-colors__secondary-color-two";

    text = t("App.userDetail.filter.active");
    tooltipColor = "#0fbbf1";
    tooltipText = t("App.listDynamicPeriodicReport.tooltips.nextToExpire");
  } else {
    backgroundColor =
      transformValue === "inactivo vencido"
        ? "state-colors__secondary-color-three"
        : "state-colors__tertiary-color-one";

    text = t("App.userDetail.filter.inactive");
    tooltipColor = "#d92fd2";
    tooltipText = t("App.listDynamicPeriodicReport.tooltips.expired");
  }

  return (
    <p
      className={`${backgroundColor} text-transform__capitalize word-break__keep`}
    >
      {text}{" "}
      {transformValue === "activo proximo a vencer" ||
      transformValue === "inactivo vencido" ? (
        <Tooltip title={tooltipText}>
          <InfoIcon style={{ color: tooltipColor }} />
        </Tooltip>
      ) : null}
    </p>
  );
};

export default PeriodicReportState;

import { Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { GetIndicators } from "../../../services/user/salesIndicatorsService";
import FilterBusinessGroup from "../../../components/common/Filters/businessGroup/filterWithButtons";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import { CardAccessInvoiceIndicators } from "../../../components/common/AccessIndicators/cardAccesInvoiceIndicators";

const PayrollIndicators = () => {
  /**
   * Mock
   */

  const mock = [
    {
      pk: "7702010000010",
      sk: "nomina#20221011112932",
      eanProvider: "7702010000010",
      indicatorType: "Nomina",
      name: "Comparación nómina",
      nameEnglish: "loren ipsun",
      icon: "TrendingUpOutlined.svg",
      description:
        "Muestra la nómina comparada contra el SAT con detalle de UUID",
      descriptionEnglish: "loren ipsun",
      url: "",
      indicatorOrder: 1,
      createUser: "Francisco Diaz",
      createDate: "2022-10-11 11:33:45",
      modificationUser: null,
      modificationDate: null,
      country: null,
    },
  ];

  /**
   * Use Translation
   */

  const { t, i18n } = useTranslation();

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  const [indicators, setIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handles
   */

  /**
   * Realiza la petición para traer las tarjetas de indicadores de nómina
   * @param {*} data información de la empresa
   */
  const handleRequestInventoriesIndicators = async (data) => {
    try {
      const obj = {
        eanProvider: data.ean.eanCompany,
        indicatorType: "payroll",
      };

      setIsLoading(true);

      const salesIndicator = await GetIndicators(obj);

      setIndicators(salesIndicator.data.responseMessage);

      setIsLoading(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };
  //hola smigos
  return (
    <Grid container rowGap={5}>
      <Grid item>
        <p className="display-large__primary-one">Indicadores de nómina</p>
      </Grid>
      <Grid item>
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title")}
          handleRequestIndicators={handleRequestInventoriesIndicators}
          setIsLoading={setIsLoading}
          typeOption={2}
        />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        {isLoading ? (
          <CustomSkeleton />
        ) : (
          <Grid container spacing={2} justifyContent="flex-start">
            {indicators.map((data, id) => {
              return (
                <Grid item lg={4} xs={12} sm={10}>
                  <CardAccessInvoiceIndicators
                    key={id}
                    icon={data.icon}
                    title={
                      i18n.language.includes("es")
                        ? data.name
                        : data.nameEnglish
                    }
                    text={
                      i18n.language.includes("es")
                        ? data.description
                        : data.descriptionEnglish
                    }
                    buttonText={t("App.salesIndicators.buttonText")}
                    link={data.url}
                    userInfo={userInfo}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PayrollIndicators;

import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getElementName } from "../../helpers/helpers";
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import InfoIcon from "@mui/icons-material/Info";

export default function RoleInformation(props) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    product: yup.string().required(t("App.validationMessages.required")),
    role: yup.string().required(t("App.validationMessages.required")),
    description: yup.string().required(t("App.validationMessages.required")),
    status: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    // register,
    handleSubmit,
    control,
    // getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const initialFormState = {
    idProduct: "",
    product: "",
    role: "",
    description: "",
    status: "",
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.onClose(open);
  };

  const nextStep = () => {
    props.nextStep(1);
  };

  const [roleInfo, setRoleInfo] = useState(initialFormState);
  props.pullDataInfo(roleInfo);

  useEffect(() => {
    const roleInfo = props.roleInfo;
    setValue("idProduct", roleInfo.idProduct ? roleInfo.idProduct : "");
    setValue("product", roleInfo.product ? roleInfo.product : "");
    setValue("role", roleInfo.role ? roleInfo.role : "");
    setValue("description", roleInfo.description ? roleInfo.description : "");
    setValue("status", roleInfo.status ? roleInfo.status : "");
  }, []);

  const [productName, setProductName] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const handleInformation = async (data, e) => {
    try {
      const obj = {
        idProduct: data.product,
        product: productName,
        role: data.role,
        description: data.description,
        status: data.status,
        clientType: checkBoxValue ? "SI" : "NO",
      };


      setRoleInfo(obj);
      props.pullDataInfo(obj);

      nextStep();
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <Grid>
      <p className="heading__text-grey">{t("App.createRole.information")}</p>

      <form onSubmit={handleSubmit(handleInformation)} name="form" id="form">
        <Grid className="modalEditRoles__form-box">
          <FormControl>
            <InputLabel className="modalCreateOptions__form__inputLabel ">
              {t("App.createRole.product")}
            </InputLabel>

            <Controller
              control={control}
              defaultValue=""
              name="product"
              render={({
                field: { onChange, onBlur, value, name, ref },
                formState,
              }) => (
                <Select
                  value={value}
                  labelId="labelProduct"
                  label={t("App.createRole.product")}
                  className={
                    errors.product
                      ? "modalCreateRoles__form-input--error"
                      : "modalCreateRoles__form-input"
                  }
                  onChange={onChange}
                  onClick={(e) => setProductName(getElementName(e.target))}
                >
                  <MenuItem disabled value="">
                    <em>{t("App.createRole.product")}</em>
                  </MenuItem>
                  {props.activeProducts.map((item) => {
                    return (
                      <MenuItem
                        sx={{
                          display: item.selected ? "none" : "",
                        }}
                        key={item.value}
                        value={item.value}
                        name={item.label}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />

            <FormHelperText
              sx={{
                margin: "-10px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.product ? errors.product.message : ""}
            </FormHelperText>
          </FormControl>

          <Controller
            defaultValue=""
            control={control}
            name="role"
            render={({
              field: { onChange, onBlur, value, name, ref },
              formState,
            }) => (
              <TextField
                className={
                  errors.role
                    ? "modalCreateRoles__form-input--error"
                    : "modalCreateRoles__form-input"
                }
                helperText={errors.role ? errors.role.message : ""}
                InputLabelProps={{
                  style: {
                    margin: "-5px 0 0 -10px",
                    padding: "0 0 0 5px",
                    color: "#8c8d9d",
                    fontSize: 13,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "-5px 0 0 0",
                    color: "#e52900",
                  },
                }}
                id={name}
                name={name}
                label={t("App.createRole.role")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
              />
            )}
          />

          <FormControl>
            <InputLabel className="modalCreateOptions__form__inputLabel ">
              {t("App.createRole.status")}
            </InputLabel>

            <Controller
              control={control}
              defaultValue=""
              name="status"
              render={({
                field: { onChange, onBlur, value, name, ref },
                formState,
              }) => (
                <Select
                  value={value}
                  labelId="labelStatus"
                  label={t("App.createRole.status")}
                  className={
                    errors.status
                      ? "modalCreateRoles__form-input--error"
                      : "modalCreateRoles__form-input"
                  }
                  onChange={onChange}
                >
                  <MenuItem disabled value="">
                    <em>{t("App.createRole.status")}</em>
                  </MenuItem>
                  <MenuItem value={1}>{t("App.createRole.active")}</MenuItem>
                  <MenuItem value={0}>{t("App.createRole.inactive")}</MenuItem>
                </Select>
              )}
            />

            <FormHelperText
              sx={{
                margin: "-10px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.status ? errors.status.message : ""}
            </FormHelperText>
          </FormControl>

          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({
              field: { onChange, onBlur, value, name, ref },
              formState,
            }) => (
              <TextField
                className={
                  errors.description
                    ? "modalCreateRoles__form-description--error"
                    : "modalCreateRoles__form-description"
                }
                helperText={
                  errors.description ? errors.description.message : ""
                }
                InputLabelProps={{
                  style: {
                    margin: "-5px 0 0 -10px",
                    padding: "0 0 0 5px",
                    color: "#8c8d9d",
                    fontSize: 13,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "-5px 0 0 0px",
                    color: "#e52900",
                  },
                }}
                id={name}
                name={name}
                label={t("App.createRole.description")}
                onChange={onChange}
                onBlur={onBlur} // notify when input is touched/blur
                value={value}
                inputRef={ref} // send input ref, so we can focus on input when error appear
              />
            )}
          />

          <Grid item container alignItems="center">
            <Grid item>
              <FormGroup
                style={{
                  padding: "0 !important",
                }}
              >
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#543ab4 !important",
                      fontFamily: "Roboto !important",
                      fontWeight: "100 !important",
                      cursor: "default",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#543ab4 !important",
                      }}
                      className="modalPreviewMenu__form-checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        setCheckBoxValue(e.target.checked);
                      }}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                    />
                  }
                  label={t("App.listRoles.clientType")}
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <Tooltip
                title={
                  <span>
                    <p>{t("App.createRole.tooltipTextOne")}</p>
                    <p>{t("App.createRole.tooltipTextTwo")}</p>
                    <p>{t("App.createRole.tooltipTextThree")}</p>
                    <p>{t("App.createRole.tooltipTextFour")}</p>
                  </span>
                }
              >
                <InfoIcon style={{ color: "#553AB6" }} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            className="modalCreateRoles__form__actions"
            spacing={2}
            item
            container
            justifyContent="center"
          >
            <Grid item>
              <Button className="btn__filled__gradient" onClick={handleClose}>
                {t("App.createRole.cancelButton")}
              </Button>
            </Grid>

            <Grid item>
              <Button
                autoFocus
                disabled={
                  watch("role") &&
                  watch("description") &&
                  watch("status") !== "" &&
                  watch("product")
                    ? false
                    : true
                }
                type="submit"
                className="modalCreateRoles__form__actions-buttonFilled"
              >
                {t("App.createRole.continueButton")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

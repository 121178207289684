import { Grid } from "@mui/material";
import { t } from "i18next";
import React from "react";

export const NoInfoComponent = (props) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="u-marginB u-marginT"
      spacing={2}
    >
      <Grid item className="text-center">
        <img
          className="img__noInfo"
          src={
            props.type === "user" || props.type === "contact"
              ? `/assets/components/noInfoUser.svg`
              : `/assets/components/noInfo.svg`
          }
        />
      </Grid>

      <Grid item className="text-center">
        <p className="display-large__primary-one text-center">
          {t("App.validationMessages.noInfo2")}
        </p>
      </Grid>

      <Grid item>
        {props.type === "load" ? (
          <p className="subheading__text-grey text-center">
            {t("App.unifiedSalesForce.noUpload")}
          </p>
        ) : props.type === "dynamicReport" ? (
          <p className="subheading__text-grey text-center">
            {t("App.listDynamicPeriodicReport.noReports")}
          </p>
        ) : props.type === "user" ? (
          <>
            <p className="subheading__text-grey text-center">
              {t("App.userDetail.noUserText1")}
            </p>
            <p className="subheading__text-black text-center u-marginT">
              {t("App.userDetail.noUserText2")}
            </p>
          </>
        ) : props.type === "specialFiles" ? (
          <p className="subheading__text-grey text-center">
            {t("App.specialFilesClient.noInfo")}
          </p>
        ) : props.type === "specialFilesCTyS" ? (
          <p className="subheading__text-grey text-center">
            {t("App.specialFilesCTyS.noInfo")}
          </p>
        ) : props.type === "contact" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminContacts.noContent")}
          </p>
        ) : props.type === "indicatorCards" ? (
          <p className="subheading__text-grey text-center">
            {t("App.indicatorCards.noInfoComponentText")}
          </p>
        ) : props.type === "transmittedValue" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminTransmittedValue.noInfoText")}
          </p>
        ) : props.type === "purchaseOrderCommerce" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminPurchaseOrderCommerce.noHasContentText")}
          </p>
        ) : props.type === "dynamic" ? (
          <p className="subheading__text-grey text-center">{props.text}</p>
        ) : (
          <p className="subheading__text-grey text-center">
            {t("App.unifiedSalesForce.noDownload")}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Popover,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Controller, useForm } from "react-hook-form";

import React, { useEffect, useState } from "react";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import { CODES } from "../../../consts/codes";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/mapping/mappingService";
import DatePicker from "react-datepicker";
import ReplayIcon from "@mui/icons-material/Replay";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { ModalDecision } from "../Modals/modalDecision";
import { CreateReprocessRequest } from "../../../services/user/reprocess/reprocessService";
import ModalInfo from "../Modals/modalInfo";

export const FilterCreateReprocess = ({
  t,
  i18n,
  handleRequestReprocessList,
}) => {
  /**
   * Const
   */

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const productsList = [
    { name: t("App.adminReprocess.productsList.allies"), key: "Aliados" },
    { name: t("App.adminReprocess.productsList.accumulatedAllie"), key: "Aliado acumulado" },
    {
      name: t("App.adminReprocess.productsList.specialFiles"),
      key: "Archivos especiales",
    },
    {
      name: t("App.adminReprocess.productsList.dynamicReport"),
      key: "Reporte periódico",
    },
  ];

  const companyTypeList = [
    { name: t("App.listUsers.retail"), key: "C" },
    { name: t("App.listUsers.supplier"), key: "F" },
  ];

  /**
   * Use Form
   */

  const { control, handleSubmit, watch, reset, setValue, getValues } = useForm({
    defaultValues: {
      productType: null,
      country: null,
      companyType: null,
      companies: [],
      eanList: [],
      reprocessDate: undefined,
    },
  });

  /**
   * Use State
   */

  //Estados de carga y deshabilitados
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isEanDisabled, setIsEanDisabled] = useState(false);
  const [isCompaniesDisabled, setIsCompaniesDisabled] = useState(false);

  //Estados de selección de campos
  const [isProductTypeSelected, setIsProductTypeSelected] = useState(false);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [isCompanyTypeSelected, setIsCompanyTypeSelected] = useState(false);

  //Listas de información de los campos
  const [countryList, setCountryList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [eanList, setEanList] = useState([]);

  //Información para el modal de decisión
  const [companiesModal, setCompaniesModal] = useState([]);
  const [productModal, setProductModal] = useState("");

  // Estado para anchor del popover de calendario
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  //Modal Decision
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);

  //Modal Info
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [modalInfoTitle, setModalInfoTitle] = useState("");
  const [modalInfoContent, setModalInfoContent] = useState({
    data: { responseMessage: "" },
  });

  //Estado de información de formulario para envío
  const [formInfo, setFormInfo] = useState({
    productType: null,
    country: null,
    companyType: null,
    companies: [],
    eanList: [],
    reprocessDate: undefined,
  });

  /**
   * Use Effect
   */

  //Carga los países activos en Prescriptiva
  useEffect(() => {
    const getCountriesRequest = async () => {
      try {
        const {
          status,
          data: { responseMessage, responseCode },
        } = await GetGeographiesList();
        if (
          status === CODES.COD_RESPONSE_SUCCESS &&
          responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          setCountryList(responseMessage);
        } else {
          setCountryList([]);
        }
      } catch (error) {
        console.log(
          "================= ERROR at FilterCreateReprocess.jsx ================="
        );
        console.log(error);
        console.log("===============================================");
        setCountryList([]);
      }
    };
    getCountriesRequest();
  }, []);

  /**
   * Handles
   */

  /**
   * Función que trae la lista de companías según el tipo de empresa y país seleccionado
   * @param {} currentCompanyType tipo de empresa seleccionado
   */
  const getCompaniesList = async (currentCompanyType) => {
    try {
      setIsLoadingCompanies(true);
      setCompaniesList([]);
      setEanList([]);
      const currentCountry = getValues("country");

      let finalCompaniesList = [];
      let finalEanList = [];

      const CompanyListService =
        currentCompanyType.key === "C"
          ? GetTradersService
          : GetSuppliersService;

      const {
        status,
        data: { responseCode, responseMessage },
      } = await CompanyListService(currentCountry.country);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        finalCompaniesList = responseMessage.map((company) => ({
          eanCompany: company.eanCompany,
          companyName: company.companyName,
        }));
        finalEanList = responseMessage.map((company) => ({
          eanCompany: company.eanCompany,
          companyName: company.companyName,
        }));
      }

      setCompaniesList(handleAddSelectAll(finalCompaniesList));
      setEanList(handleAddSelectAll(finalEanList));
      setIsLoadingCompanies(false);
    } catch (error) {
      console.log(
        "================= ERROR at FilterCreateReprocess.jsx ================="
      );
      console.log(error);
      console.log("===============================================");
      setCompaniesList([]);
      setEanList([]);
      setIsLoadingCompanies(false);
    }
  };

  /**
   * Función que agrega el selectAll si aplica el caso
   * @param {*} currentList lista a agregar el selectAll
   * @returns lista transformada
   */
  const handleAddSelectAll = (currentList) => {
    if (currentList.length > 1) {
      return [
        {
          companyName: t("App.downloadSalesForce.selectAll"),
          eanCompany: t("App.downloadSalesForce.selectAll"),
        },
        ...currentList,
      ];
    } else {
      return currentList;
    }
  };

  /**
   * Función que gestiona cambios en el formulario cuando el campo tipo producto es modificado
   * @param {*} currentProduct producto seleccionado
   */
  const handleProductTypeChange = (currentProduct) => {
    setValue("country", null);
    setValue("companyType", null);
    setValue("companies", []);
    setValue("eanList", []);
    setValue("reprocessDate", undefined);
    setCurrentDate(new Date());

    if (currentProduct !== null) {
      setIsProductTypeSelected(true);
    } else {
      setIsProductTypeSelected(false);
    }
    setIsCountrySelected(false);
    setIsCompanyTypeSelected(false);
  };

  /**
   * Función que gestiona cambios en el formulario cuando el campo país es modificado
   * @param {*} currentCountry país seleccionado
   */
  const handleCountryChange = (currentCountry) => {
    setValue("companyType", null);
    setValue("companies", []);
    setValue("eanList", []);
    setValue("reprocessDate", undefined);
    setCurrentDate(new Date());

    if (currentCountry !== null) {
      setIsCountrySelected(true);
    } else {
      setIsCountrySelected(false);
    }
    setIsCompanyTypeSelected(false);
  };

  /**
   * Función que gestiona los cambios sobre el formulario cuando el campo Tipo empresa es modificado
   * @param {*} currentCompanyType tipo de empresa seleccionado
   */
  const handleCompanyTypeChange = async (currentCompanyType) => {
    setValue("companies", []);
    setValue("eanList", []);
    setValue("reprocessDate", undefined);
    setCurrentDate(new Date());

    if (currentCompanyType !== null) {
      setIsCompanyTypeSelected(true);
      await getCompaniesList(currentCompanyType);
      setIsEanDisabled(false);
      setIsCompaniesDisabled(false);
    } else {
      setIsCompanyTypeSelected(false);
    }
  };

  /**
   * Función que maneja el comportamiento del campo Empresa cuando es modificado
   * @param {*} values empresas seleccionadas
   * @param {*} reason tipo de selección
   * @param {*} detail selección previa a la actual
   */
  const handleCompaniesChange = (values, reason, detail) => {
    setValue("reprocessDate", undefined);
    setCurrentDate(new Date());

    let includeSelectAll = false;
    for (const company of values) {
      if (company.eanCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeCompanies = companiesList.filter(
      (company) => company.eanCompany !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (company) => company.eanCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCompanies && reason === "selectOption")
    ) {
      if (sizeValues === 1 && !includeSelectAll) {
        //Si tenemos una lista de 1 empresa para seleccionar, el comportamiento es normal
        setIsEanDisabled(false);
        setValue("eanList", [
          {
            eanCompany: values[0].eanCompany,
            companyName: values[0].companyName,
          },
        ]);
      } else {
        setIsEanDisabled(true);
        setValue("eanList", []);
      }
      setValue("companies", companiesList);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === companiesList.length - 1) {
      setValue(
        "companies",
        values.filter(
          (companies) =>
            companies.companyName !== t("App.downloadSalesForce.selectAll")
        )
      );
      //Caso lista con una sola empresa
      setValue("eanList", []);
    } else {
      //Si se selecciona una sola empresa, se selecciona el ean
      if (sizeValues === 1) {
        setIsEanDisabled(false);
        setValue("eanList", [
          {
            eanCompany: values[0].eanCompany,
            companyName: values[0].companyName,
          },
        ]);
      } else if (sizeValues > 1) {
        //Si se selecciona más de una empresa, se settea vacía lista ean y y se deshabilita el campo ean
        setValue("eanList", []);
        setIsEanDisabled(true);
      } else {
        //Si no hay selección, se settea la lista vacía y se habilita el campo ean
        setValue("eanList", []);
        setIsEanDisabled(false);
      }
      setValue("companies", values);
    }

    //Caso en que se deseleccione selectAll
    if (
      (detail?.option.companyName === t("App.downloadSalesForce.selectAll") &&
        reason === "removeOption") ||
      reason === "clear"
    ) {
      setValue("companies", []);
      setIsEanDisabled(false);
    }
  };

  /**
   * Función que gestiona los cambios en el formulario cuando el campo EAN Empresa es modificado
   * @param {*} values lista de eans elegidos
   * @param {*} reason tipo de selección
   * @param {*} detail selección previa
   */
  const handleEanChange = (values, reason, detail) => {
    setValue("reprocessDate", undefined);
    setCurrentDate(new Date());

    let includeSelectAll = false;
    for (const company of values) {
      if (company.eanCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeCompanies = eanList.filter(
      (company) => company.eanCompany !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (company) => company.eanCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCompanies && reason === "selectOption")
    ) {
      if (sizeValues === 1 && !includeSelectAll) {
        //Si tenemos una lista de 1 empresa para seleccionar, el comportamiento es normal
        setIsCompaniesDisabled(false);
        setValue("companies", [
          {
            eanCompany: values[0].eanCompany,
            companyName: values[0].companyName,
          },
        ]);
      } else {
        setIsCompaniesDisabled(true);
        setValue("companies", []);
      }
      setValue("eanList", eanList);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === companiesList.length - 1) {
      setValue(
        "eanList",
        values.filter(
          (companies) =>
            companies.eanCompany !== t("App.downloadSalesForce.selectAll")
        )
      );
      //Caso lista con una sola empresa
      setValue("companies", []);
    } else {
      //Si se selecciona una sola empresa, se selecciona el empresa
      if (sizeValues === 1) {
        setIsCompaniesDisabled(false);
        setValue("companies", [
          {
            eanCompany: values[0].eanCompany,
            companyName: values[0].companyName,
          },
        ]);
      } else if (sizeValues > 1) {
        //Si se selecciona más de una empresa, se settea vacía lista ean y y se deshabilita el campo empresa
        setValue("companies", []);
        setIsCompaniesDisabled(true);
      } else {
        //Si no hay selección, se settea la lista vacía y se habilita el campo empresa
        setValue("companies", []);
        setIsCompaniesDisabled(false);
      }
      setValue("eanList", values);
    }

    //Caso en que se deseleccione selectAll
    if (
      (detail?.option.companyName === t("App.downloadSalesForce.selectAll") &&
        reason === "removeOption") ||
      reason === "clear"
    ) {
      setValue("eanList", []);
      setIsCompaniesDisabled(false);
    }
  };

  /**
   * Función que gestiona los cambios cuando el campo reprocessDate es modificado
   * @param {*} date fecha seleccionada
   */
  const handleReprocessDateChange = (date) => {
    setValue("reprocessDate", moment(date).format("YYYY-MM-DD"));
    setCurrentDate(date);
    handleClose();
  };

  /**
   * Función que deshabilita el campo Empresa en el formulario
   * @returns true si se deshabilita, false si no
   */
  const isCompaniesListDisabled = () => {
    if (isCompaniesDisabled) {
      return true;
    }
    return !(isCompanyTypeSelected && !isLoadingCompanies);
  };

  /**
   * Función que desactiva el campo Ean Empresa en el formulario
   * @returns true si se deshabilita, false si se habilita
   */
  const isEanListDisabled = () => {
    if (isEanDisabled) {
      return true;
    }

    return !(isCompanyTypeSelected && !isLoadingCompanies);
  };

  /**
   * Función que observa si el campo companies ha sido rellenado para habilitar el campo reprocessDate
   * @returns true si el campo fue rellenado, false si no
   */
  const handleIsCompaniesFilled = () => {
    const companiesField = watch("companies");
    const eanField = watch("eanList");

    return companiesField.length > 0 || eanField.length > 0;
  };

  /**
   * Función que desactiva el botón de reprocesar
   * @returns true si se deshabilita, false si no
   */
  const handleDisableReprocessButton = () => {
    const allFields = watch();
    const productType = allFields.productType !== null;
    const country = allFields.country !== null;
    const companyType = allFields.companyType !== null;
    const companies = allFields.companies.length > 0;
    const eanList = allFields.eanList.length > 0;
    const reprocessDate = allFields.reprocessDate !== undefined;

    return !(
      productType &&
      country &&
      companyType &&
      (companies || eanList) &&
      reprocessDate
    );
  };

  /**
   * Funcion que gestiona las acciones después de que el cliente da click a botón de Reprocesar
   */
  const handleReprocessButton = (data) => {
    //Guarda la información del formulario
    setFormInfo(data);

    //Formatea la información de las listas para modal decision
    const listCompanies =
      data.companies.length > 0 ? data.companies : data.eanList;

    const companiesFiltered = listCompanies.filter(
      (company) => company.companyName !== t("App.downloadSalesForce.selectAll")
    );

    let companiesString = "";
    if (companiesFiltered.length > 1) {
      companiesString = companiesFiltered.reduce(
        (accumulator, currentValue) => {
          let fixedAccumulator = accumulator.companyName
            ? accumulator.companyName
            : accumulator;
          return fixedAccumulator + ", " + currentValue.companyName;
        }
      );
    } else {
      companiesString = companiesFiltered[0].companyName;
    }

    //Settea la información para el modal
    setCompaniesModal(companiesString);
    setProductModal(data.productType.name);
    setIsOpenModalDecision(true);
  };

  /**
   * Settea el título del modal info según el responseCode
   * @param {*} responseCode responseCode de la petición
   * @returns título del modal
   */
  const handleModalInfoTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return t("App.adminReprocess.modal.infoTitleOK");
      default:
        return t("App.validationMessages.error");
    }
  };

  /**
   * Función que procesa la solicitud de reproceso
   * @param {*} formInfo información de reproceso
   */
  const handleReprocessRequest = async (formInfo) => {
    const rawListCompanies =
      formInfo.companies.length > 0 ? formInfo.companies : formInfo.eanList;

    const isSelectAll =
      rawListCompanies.length > 0
        ? rawListCompanies[0].companyName ===
          t("App.downloadSalesForce.selectAll")
          ? "SI"
          : "NO"
        : "NO";
    

    // Generación de lstCompanies con el formato requerido
    const lstCompanies = rawListCompanies
      .filter(
        (company) =>
          company.companyName !== t("App.downloadSalesForce.selectAll") &&
          company.eanCompany !== t("App.downloadSalesForce.selectAll")
      )
      .map((company) => ({
        companyName: company.companyName,
        eanCompany: company.eanCompany,
      }));

    let dateType = ""
    if(formInfo.productType.key==="Aliados"){
      dateType="load"
    }else if (formInfo.productType.key==="Aliado acumulado"){
      dateType="accumulated"
    } else{
      dateType = ""
    }
    const obj = {
      productType: formInfo.productType.key,
      country: formInfo.country.country,
      companyType: formInfo.companyType.key,
      lstCompanies: lstCompanies,
      reprocessDate: formInfo.reprocessDate,
      dateTypeReprocess: dateType,
      reprocessAll: isSelectAll,
    };
    try {
      const {
        status,
        data: { responseMessage, responseCode },
      } = await CreateReprocessRequest(obj);

      setModalInfoTitle(handleModalInfoTitle(responseCode));
      setModalInfoContent({
        status: status,
        data: { responseMessage: responseMessage, responseCode: responseCode },
      });
      setIsOpenModalInfo(true);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        await handleRequestReprocessList();
      }
    } catch (error) {
      setModalInfoTitle(
        handleModalInfoTitle(CODES.COD_RESPONSE_HTTP_NOT_FOUND)
      );
      setModalInfoContent({
        data: { responseMessage: t("App.validationMessages.systemError2") },
      });
      setIsOpenModalInfo(true);
      console.log(
        "================= ERROR at FilterCreateReprocess.jsx ================="
      );
      console.log(error);
      console.log("===============================================");
    }
  };

  /**
   * Función para cuando el usuario da click en aceptar para el modalDecision
   */
  const handleAgree = async () => {
    setIsOpenModalDecision(false);
    await handleReprocessRequest(formInfo);
  };

  /**
   * Función para cuando el usuario da click en cancelar para el modalDecision
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  /**
   * Función que determina si un campo ha sido rellenado o no
   * @param {*} fieldName nombre del campo
   * @returns true si fue rellenado, false si no
   */
  const isFieldFilled = (fieldName) => {
    const currentField = watch(fieldName);

    switch (fieldName) {
      case "companies":
        return currentField.length !== 0;
      case "eanList":
        return currentField.length !== 0;
      case "reprocessDate":
        return currentField !== undefined;
      default:
        return currentField !== null;
    }
  };

  /**
   * Asigna el anchor para el popover de calendario
   * @param {*} event elemento actual
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  /**
   * Elimina el anchor para el popover de calendario
   */
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  /**
   * Función que limpia el formulario
   */
  const handleCleanForm = () => {
    setCurrentDate(new Date());
    setIsProductTypeSelected(false);
    setIsCountrySelected(false);
    setIsCompanyTypeSelected(false);
    setIsCompaniesDisabled(true);
    setIsEanDisabled(true);

    reset();
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary>
        <Grid container columnSpacing={2}>
          <Grid item>
            {" "}
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />
          </Grid>
          <Grid item>
            <p className="navbar__text">
              {t("App.adminReprocess.fields.title")}
            </p>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2}>
          <Grid
            item
            xl={9}
            lg={9}
            sm={8}
            xs={12}
            className="side-line-contact__bottomAlign"
          >
            <form
              id="reprocess-form"
              onSubmit={handleSubmit(handleReprocessButton)}
            >
              <Grid container direction="column" rowSpacing={4}>
                <Grid item className="full-width">
                  <Grid container columnSpacing={2} rowSpacing={4}>
                    <Grid item lg={3.5} sm={11} xs={12}>
                      <Controller
                        name="productType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              isFieldFilled("productType")
                                ? "filters"
                                : "filters__not-filled"
                            }
                            options={productsList}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.key === value.key
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.adminReprocess.fields.productType"
                                  )}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "13px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option.key}>
                                {option.name}
                              </li>
                            )}
                            value={value || null}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleProductTypeChange(values);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={3.5} sm={11} xs={12}>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              isProductTypeSelected
                                ? isFieldFilled("country")
                                  ? "filters"
                                  : "filters__not-filled"
                                : "filters__disabled"
                            }
                            disabled={!isProductTypeSelected}
                            options={countryList}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) =>
                              option.country === value.country
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.createAndEditCompany.country")}
                                  sx={{
                                    label: {
                                      color: "#a3a3a3",
                                      fontFamily: "Roboto !important",
                                      fontSize: "13px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option.country}>
                                {option.countryName}
                              </li>
                            )}
                            value={value || null}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleCountryChange(values);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={3.5} sm={11} xs={12}>
                      <Controller
                        name="companyType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              isCountrySelected
                                ? isFieldFilled("companyType")
                                  ? "filters"
                                  : "filters__not-filled"
                                : "filters__disabled"
                            }
                            disabled={!isCountrySelected}
                            options={companyTypeList}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.key === value.key
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.createAndEditCompany.companyType"
                                  )}
                                  sx={{
                                    label: {
                                      color: "#a3a3a3",
                                      fontFamily: "Roboto !important",
                                      fontSize: "13px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option.key}>
                                {option.name}
                              </li>
                            )}
                            value={value || null}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleCompanyTypeChange(values);
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container columnSpacing={2} rowSpacing={4}>
                    <Grid item lg={3.5} sm={11} xs={12}>
                      <Controller
                        control={control}
                        name="companies"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled={isCompaniesListDisabled()}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              !isCompaniesListDisabled()
                                ? isFieldFilled("companies")
                                  ? "filters"
                                  : "filters__not-filled"
                                : "filters__disabled"
                            }
                            disableCloseOnSelect
                            options={companiesList}
                            multiple
                            limitTags={1}
                            isOptionEqualToValue={(option, value) =>
                              option.eanCompany === value.eanCompany
                            }
                            getOptionLabel={(option) => option.companyName}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.eanCompany + ""}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.companyName}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              handleCompaniesChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.indicadors.company")}
                                  sx={{
                                    label: {
                                      color: "#a3a3a3",
                                      fontFamily: "Roboto !important",
                                      fontSize: "13px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isLoadingCompanies ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const numTags = value.length;
                              const limitTags = 1;
                              return (
                                <>
                                  {value
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "50%",
                                        }}
                                        key={option.eanCompany}
                                        size="small"
                                        label={`${option.companyName}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={3.5} sm={11} xs={12}>
                      <Controller
                        control={control}
                        name="eanList"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled={isEanListDisabled()}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              !isEanListDisabled()
                                ? isFieldFilled("eanList")
                                  ? "filters"
                                  : "filters__not-filled"
                                : "filters__disabled"
                            }
                            disableCloseOnSelect
                            options={eanList}
                            multiple
                            limitTags={1}
                            isOptionEqualToValue={(option, value) =>
                              option.eanCompany === value.eanCompany
                            }
                            getOptionLabel={(option) => option.eanCompany}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.eanCompany + ""}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />

                                {option.eanCompany}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              handleEanChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.adminCompanies.filters.eanCompany"
                                  )}
                                  sx={{
                                    label: {
                                      color: "#a3a3a3",
                                      fontFamily: "Roboto !important",
                                      fontSize: "13px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isLoadingCompanies ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const numTags = value.length;
                              const limitTags = 1;
                              return (
                                <>
                                  {value
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "50%",
                                        }}
                                        key={option.eanCompany}
                                        size="small"
                                        label={`${option.eanCompany}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={3.5} sm={11} xs={12} className="full-width">
                      <Controller
                        name={"reprocessDate"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Button
                              disabled={!handleIsCompaniesFilled()}
                              disableRipple
                              disableFocusRipple
                              sx={{
                                justifyContent: "flex-start",
                                textAlign: "left",
                                paddingLeft: "5%",
                              }}
                              endIcon={
                                <CalendarMonthOutlinedIcon
                                  sx={{
                                    fontSize: "8",
                                    color: handleIsCompaniesFilled()
                                      ? isFieldFilled("reprocessDate")
                                        ? "#553ab6"
                                        : "#a3a3a3"
                                      : "#d4d4d4",
                                  }}
                                />
                              }
                              onClick={handleClick}
                              className={
                                handleIsCompaniesFilled()
                                  ? isFieldFilled("reprocessDate")
                                    ? "filters fake-button"
                                    : "filters__not-filled fake-button"
                                  : "filters__disabled fake-button"
                              }
                            >
                              <Grid container>
                                <Grid item>
                                  {value === undefined && (
                                    <p
                                      className={
                                        handleIsCompaniesFilled()
                                          ? isFieldFilled("reprocessDate")
                                            ? "text-colors__very_dark_grayish_blue"
                                            : "text-colors__text-color-disabled"
                                          : "text-colors__text-color-fully-disabled"
                                      }
                                      style={{ fontSize: "13px" }}
                                    >
                                      {t(
                                        "App.adminReprocess.fields.reprocessDate"
                                      )}
                                    </p>
                                  )}
                                  {value}
                                </Grid>
                              </Grid>
                            </Button>
                            <Popover
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              PaperProps={{
                                style: {
                                  minWidth: "320px",
                                  minHeight: "300px",
                                  marginTop: "0.5%",
                                },
                              }}
                            >
                              <Grid container style={{ padding: "5%" }}>
                                <DatePicker
                                  selected={currentDate}
                                  locale={
                                    i18n.language.includes("es") ? "es" : ""
                                  }
                                  onChange={handleReprocessDateChange}
                                  inline
                                />
                              </Grid>
                            </Popover>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item lg={3} sm={4} xs={12} className="col-flex">
            <Grid
              container
              columnSpacing={2}
              rowSpacing={2}
              className="col-flex"
            >
              <Grid
                item
                lg={6}
                sm={12}
                xs={12}
                className="col-flex"
                mt={{ lg: 0, sm: 0, xs: 4 }}
              >
                <Button
                  type="submit"
                  form="reprocess-form"
                  disabled={handleDisableReprocessButton()}
                  className={
                    handleDisableReprocessButton()
                      ? "btn__filled__disabled full-width"
                      : "btn__filled__blue full-width"
                  }
                  startIcon={<ReplayIcon />}
                >
                  {t("App.adminReprocess.fields.reprocessButton")}
                </Button>
              </Grid>
              <Grid item lg={4} sm={12} xs={12} className="col-flex">
                <Button
                  onClick={handleCleanForm}
                  className="btn__outlined__clean"
                >
                  {t("App.catalogue.filter.cleanFilters2")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <ModalDecision
        title={t("App.adminReprocess.modal.decisionTitle", {
          productType: productModal,
        })}
        message={t("App.adminReprocess.modal.decisionMessage", {
          companiesList: companiesModal,
        })}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
      <ModalInfo
        title={modalInfoTitle}
        responseData={modalInfoContent}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
      />
    </Accordion>
  );
};

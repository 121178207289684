import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
} from "@mui/material";
import FilterRequestsPurchaseOrders from "../../../components/common/Filters/filterRequestsPurchaseOrders";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ModalSeeRequestsPurchaseOrderDetail from "../../../components/common/Modals/modalSeeRequestsPurchaseOrderDetail";
import FileState from "../../../components/common/fileState";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import ModalFailService from "../../../components/common/modalFailService";

const RequestsPurchaseOrders = () => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseMessage, setResponseMesssage] = useState({});
  const [hasContent, setHasContent] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFound, setHasFound] = useState(false);
  const [data, setData] = useState([]);

  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  /**
   * navigate para navegar entre páginas
   */
  const navigate = useNavigate();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "requestId",
      label: t("App.requestsPurchaseOrder.table.idRequest"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "requestDate",
      label: t("App.requestsPurchaseOrder.table.requestDate"),
      options: {
        filter: false,
        searchable: false,
        hint: t("App.requestsPurchaseOrder.requestDateTooltip"),
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.requestsPurchaseOrder.table.requestDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "eanTrader",
      label: t("App.businessRules.table.eanCommerce"),
      options: { filter: false },
    },
    {
      name: "trader",
      label: t("App.businessRules.table.commerce"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "ocDate",
      label: t("App.adminPurchaseOrder.table.ocDate"),
      options: {
        hint: t("App.adminPurchaseOrder.table.tooltipOcDate"),
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.adminPurchaseOrder.table.ocDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
        filter: false,
        searchable: false,
      },
    },
    {
      name: "ocNumber",
      label: t("App.adminPurchaseOrder.table.ocNumber"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "dateResponse",
      label: t("App.requestsPurchaseOrder.table.responseDate"),
      options: {
        hint: t("App.requestsPurchaseOrder.table.responseDateTooltip"),
        filter: false,
        searchable: false,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.requestsPurchaseOrder.table.responseDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "requestState",
      label: t("App.adminClientUsers.table.state"),
      options: {
        filter: false,
        customBodyRender: (value) => <FileState value={value} />,
      },
    },
    {
      name: "seeDetail",
      label: t("App.consultRoles.table.seeDetails"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    jumpToPage: true,
    searchPlaceholder: t("App.requestsPurchaseOrder.searchTextFilter"),
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Skeleton para simular carga cuando se busca nueva información para la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    responsive: "standard",
    searchPlaceholder: t("App.adminCompanies.table.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: false,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  const [title, setTitle] = useState("");

  const [openModalEdit, setOpenModalEdit] = useState(false);
  // modal error
  const [openErrorModal, setOpenErrorModal] = useState(false);
  // Estado para el oc actual a editar / eliminar
  const [currentOC, setCurrentOC] = useState({
    eanTrader: "",
    trader: "",
    ocDate: "",
    ocNumber: "",
    eanProvider: "",
    provider: "",
    requestState: "",
    requestId: "",
  });

  /**
   * Función para actualizar el estado de isOpenModalInfo y isOpenModalInfo, cómo también enviar la info para el modalInfo
   */
  const handleAgree = () => {
    const obj = {
      status: 200,
      data: {
        responseCode: 0,
        responseMessage:t("App.adminPurchaseOrder.modalInfoConsult"),
      },
    };

    setResponseMesssage(obj);
    setIsOpenModalInfo(true);
    setIsOpenModalDecision(false);
  };

  /**
   * Establece el contacto actual para editar y abre el modo de edición
   * @param {*} contact contacto a editar
   * @param {*} index posición del contacto en la tabla de datos
   */
  const handleOpenEdit = (order) => {
    setCurrentOC(order);
    setOpenModalEdit(true);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo a false
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <h1 className="display-large__primary-one">
          {t("App.requestsPurchaseOrder.title")}
        </h1>
      </Grid>

      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <IconButton
            className="btn__back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <p className="subheading__blue3">
            {t("App.requestsPurchaseOrder.backText")}
          </p>
        </Grid>
      </Grid>

      <Grid
        container
        item
        className="companies_info_container full-width"
        direction="column"
        gap={2}
      >
        <Grid item container xs={12}>
          <Accordion
            className="accordion__filter full-width"
            defaultExpanded={true}
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterRequestsPurchaseOrders
                setHasContent={setHasContent}
                setHasFound={setHasFound}
                setData={setData}
                setIsLoading={setIsLoading}
                userInfo={userInfo}
                handleOpenEdit={handleOpenEdit}
                setHasFilter={setHasFilter}
                setOpenErrorModal={setOpenErrorModal}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid container item gap={1} className="full-width">
          {hasFilter ? (
            hasContent ? (
              hasFound ? (
                <MUIDataTable
                  className="dataTable__salesForce datatable-style full-width"
                  columns={columns}
                  options={isLoading ? skeletonOptions : options}
                  data={data}
                />
              ) : (
                <Grid item sx={{ width: "100%" }}>
                  <NoMatchImage />
                </Grid>
              )
            ) : (
              <Grid item sx={{ width: "100%" }}>
                <NoInfoComponent
                  type="dynamic"
                  text={t("App.requestsPurchaseOrder.noData")}
                />
              </Grid>
            )
          ) : (
            <NoSelectionComponent type="purchaseOrder" />
          )}
        </Grid>
      </Grid>

      <br />

      <ModalDecision
        title={t("App.adminPurchaseOrder.decisionText")}
        message={""}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />

      <ModalInfo
        title='Esta solicitud quedará en estado "pendiente" hasta que el comercio la gestione'
        responseData={responseMessage}
        open={isOpenModalInfo}
        onClose={() => setIsOpenModalInfo(false)}
      />
      <ModalSeeRequestsPurchaseOrderDetail
        order={currentOC}
        open={openModalEdit}
        setOpen={setOpenModalEdit}
        userInfo={userInfo}
        setTitle={setTitle}
        setOpenErrorModal={setOpenErrorModal}
      />
      <ModalFailService open={openErrorModal} onClose={setOpenErrorModal} />
    </Grid>
  );
};

export default RequestsPurchaseOrders;

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function GetIndicators(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `cards/indicators`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

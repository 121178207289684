import { React, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CODES } from "../../consts/codes";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CancelApplication } from "../../services/user/deleteAndReload/applicationDeleteAndReload";
import ModalInfo from "./Modals/modalInfo";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getUser } from "../../pages/userSlice";
import { id } from "date-fns/locale";
import { CancelApplicationAllies } from "../../services/user/deleteAndReload/deleteAndReload";

/**
 * Título de modal
 * @param {*} props elementos para el modal
 * @returns título de el modal de cancelación
 */
export const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

const ModalCancelRequest = ({ data, allies, applyFilters }) => {
  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use State
   */

  //Abrir el modal
  const [open, setOpen] = useState(false);

  //Modal respuesta
  const [openModalResponse, setOpenModalResponse] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalResponseContent, setModalResponseContent] = useState({});

  //Carga de cancelación de solicitud
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  /**
   * Handles
   */

  /**
   * Función para abrir el modal
   */
  const handleOpen = () => setOpen(true);

  /**
   * Función para cerrar el modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Función para cancelar la solicitud de recarga
   */
  const cancelRequest = async () => {
    try {
      setIsLoadingCancel(true);
      const obj = {
        pk: data.pk,
        idRequest: data.idRequest,
        country: data.country,
        typeDocument: data.typeDocument,
        requestType: data.requestType,
        eanProvider: data.eanProvider,
        provider: data.provider,
        eanTrader: data.eanTrader,
        trader: data.trader,
        cancelUser: `${userInfo.firstName} ${userInfo.lastName}`,
      };

      let cancelService = "";

      if (allies) {
        cancelService = await CancelApplicationAllies(obj);
      } else {
        cancelService = await CancelApplication(obj);
      }

      if (cancelService.status === CODES.COD_RESPONSE_HTTP_OK) {
        const responseCode = cancelService.data.responseCode;
        setModalTitle(setModalInfoTitle(responseCode));
        setModalResponseContent(cancelService);
      } else {
        setModalTitle(setModalInfoTitle(CODES.COD_RESPONSE_ERROR_DELETE));
        setModalResponseContent({
          data: { responseMessage: t("App.validationMessages.systemError2") },
        });
      }

      setIsLoadingCancel(false);
      setOpenModalResponse(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para colocar título correspondiente según el código de errorx al modal info
   * @param {*} codeResponse código de respuesta
   * @returns título correspondiente
   */
  const setModalInfoTitle = (codeResponse) => {
    switch (codeResponse) {
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        //Error de cancelación
        return t("App.applicationDeleteAndReload.errorTitle");
      case CODES.COD_RESPONSE_ERROR_DELETE:
        //Error general
        return t("App.validationMessages.error");
      default:
        //Cancelación exitosa
        return t("App.validationMessages.modalCancel.title");
    }
  };

  /**
   * Cierra el modal de respuesta de la cancelación
   */
  const handleCloseResponse = () => {
    applyFilters();
    setOpen(false);
    setOpenModalResponse(false);
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <HighlightOffIcon
          sx={{
            fontSize: 22,
            color: "#543ab4",
          }}
        />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        className="btn__uploadNew"
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "52rem",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <DialogTitleContainer onClose={handleClose}>
          <ErrorOutlineOutlinedIcon
            sx={{
              color: "#0fbbf1",
              fontSize: "4rem",
            }}
          />
        </DialogTitleContainer>
        <DialogContent className="col-start">
          <Grid container direction="column" rowSpacing={1}>
            <Grid item>
              <label className="heading-secondary__md">
                {t("App.validationMessages.modalCancel.title")}
              </label>
            </Grid>

            <Grid item className="default-text_black">
              {t("App.validationMessages.modalCancel.text", {
                id: data.idRequest,
                commerce: data.trader,
                supplier: data.provider,
              }).replace("?", allies ? "" : "?")}{" "}
              {allies ? t("App.allies.request.cancelModal") : ""}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container className="col-end" columnSpacing={3}>
            <Grid item>
              <Button
                type="button"
                disableRipple
                className="btn__filled__gradient"
                onClick={() => {
                  handleClose();
                }}
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disableRipple
                type="submit"
                className="btn__applyFilter"
                onClick={cancelRequest}
              >
                {!isLoadingCancel ? (
                  t("App.buttonLabels.accept")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <ModalInfo
        title={modalTitle}
        responseData={modalResponseContent}
        open={openModalResponse}
        onClose={handleCloseResponse}
      />
    </div>
  );
};

export default ModalCancelRequest;

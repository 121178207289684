import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

axios.interceptors.response.use(
  (response) => {
    if (response.data.responseCode === 1016 || response.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/";
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function GetCompanies() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: "apiCompany",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetUsersByCompany(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `users/companies/${data.eanCompany}/countries/${data.country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function UpdateCompany(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    url: `contractedProduct`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetCompanyById(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `apiCompany/${data.companyId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetFiltersList() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: "companies",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateContractedProduct(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "contractedProduct",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetContractedProduct(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `contractedProduct/${data.eanCompany}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Servicio que trae los productos contratados con al menos un usuario disponible
 * @param {} data
 * @returns
 */
export async function GetActiveProduct(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `contractedProduct/active`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetAvailableProduct(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "menus/typeProduct/available",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetMenuLevel1() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: "menus/typeProduct",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ApplyFiltersProduct(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: `contractedProduct/filter`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ApplyFilters(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "apiCompany/filter",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ApplyFiltersDetail(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "users/filter",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateUsers(data) {
  const token = await getToken();
  if (env.REACT_APP_API_URL.toString().includes("dev")) {
    data["environment"] = "dev";
  } else if (env.REACT_APP_API_URL.toString().includes("test")) {
    data["environment"] = "test";
  } else if (env.REACT_APP_API_URL.toString().includes("stage")) {
    data["environment"] = "stage";
  } else {
    data["environment"] = "prod";
  }

  return axios({
    method: "POST",
    url: "users",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditUsers(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    url: `users`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ResendCredentials(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "auths/resend/passwords",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetRolesByProduct(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "roles/products",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function getCompanyByEan(data) {
  const token = await getToken();
  const { eanCompany, country } = data;
  return axios({
    method: "GET",
    url: `companies/providers/${eanCompany}/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function getCompaniesAdmin(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `companies/administrations`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateIndicatorCard(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "cards",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditIndicatorCard(data) {
  const token = await getToken();

  return axios({
    method: "PUT",
    url: "cards",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ApplyFiltersCards(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "cards/typeIndicator/state",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListOrderCard(indicator) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `cards/order/indicators/${indicator}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListIndicatorType() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: "cards/typeindicators",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function AdministrationAccessIndicators(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "cards/administrations",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function SaveUrlAccessIndicators(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "cards/indicators/providers/configurations",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

//Cambiar compañía de usuario soporte

export async function UpdateSupportCompany(data) {
  return axios({
    method: "PUT",
    url: `users/supports`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

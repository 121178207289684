import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FilterReports } from "../../../services/user/listDynamicReportService";
import { CODES } from "../../../consts/codes";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Grid,
  IconButton,
  Skeleton,
  TextField,
} from "@mui/material";
import DatePickerMonthYear from "./DatePickerMonthYear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const FilterPeriodicReportsCEN = ({
  unfilteredData,
  setData,
  data,
  setNoFound,
  setNoReports,
  setFilters,
  filterData,
  calculateRestDays,
  companiesList,
  setOpenErrorModal,
  setHasFilter,
  type,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { t } = useTranslation();
  const [companySelected, setCompanySelected] = useState([]);

  const [startDate, setStartDate] = useState(null);

  const minDate = new Date();

  const userInfo = useSelector(getUser);

  const schema = yup.object().shape({
    ean: yup.string().min(2),
    country: yup.string().min(2),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Función para realizar el filtro de los reportes
   * @param {*} data
   */
  const handleFilters = async (data) => {
    setData(skeletonLine);
    setNoReports(false);
    let numberMonth = "";
    let year = "";
    let statusLabel = "";
    try {
      let date = null;

      // Verificar si se seleccionó una fecha de expiración
      if (startDate) {
        numberMonth = startDate.getMonth() + 1;
        year = startDate.getFullYear();
        date = `${year}-${numberMonth < 10 ? "0" + numberMonth : numberMonth}`;
      }

      // Verificar si se seleccionó un estado
      if (data.status) {
        statusLabel = data.status.label;
      }

      const obj = {
        ...(data.status && { status: data.status.value }),
        company: companySelected.length > 0 ? companySelected : null,
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        emails: userInfo.email,
        ...(date && { expirationDate: date }),
        numberMonth: numberMonth - 1,
        year: year,
        statusLabel: statusLabel,
        type: type,
        date: date,
      };
      setFilters(obj);
      filterData(obj);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const skeletonLine = [
    {
      creationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      creationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      expirationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      generationFrequency: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      ),
      typeOfDataGrouping: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      fileName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      status: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      detailErrors: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Opciones para el select de estado
   */
  const StateOptions = [
    {
      label: t("App.listDynamicPeriodicReport.cen.state.active"),
      value: "ACTIVO",
    },
    {
      label: t("App.listDynamicPeriodicReport.cen.state.nextToExpire"),
      value: "ACTIVO PROXIMO A VENCER",
    },
    {
      label: t("App.listDynamicPeriodicReport.cen.state.inactive"),
      value: "INACTIVO",
    },
    {
      label: t("App.listDynamicPeriodicReport.cen.state.expired"),
      value: "INACTIVO VENCIDO",
    },
  ];

  /**
   * Función para limpiar los filtros
   */
  const handleCleanFilters = () => {
    setHasFilter(false);
    setValue("status", 0);
    setValue("expiration", null);
    setValue("company", []);
    setCompanySelected([]);
    setFilters({});
    setNoFound(false);
    setStartDate(null);
    setData(unfilteredData);
  };

  /**
   * Opciones para el autocomplete
   */
  /**
   * filterOptions para el autocomplete de compañías
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   *
   * Función que se acciona cuando se cambia el valor del autocomplete de compañías para cambiar el valor del state de companySelected que es lo que se muestra seleccionado en el autcomplete
   * También funciona para seleccionar todos los valores cuando se selecciona la opción de 'Seleccionar todos'
   *
   * @param {Event} event
   * @param {Values} values comercios
   * @param {String} reason tipo de selección; selectOption, removeOption
   * @param {Object} detail opción elegida
   */
  const OnCompanyChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const company of values) {
      if (company.renameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let AllCompanies = companiesList.filter(
      (company) =>
        company.renameCompany !== t("App.downloadSalesForce.selectAll")
    );

    let sizeCompanies = AllCompanies.length;
    let sizeValues = values.filter(
      (company) =>
        company.renameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCompanies && reason === "selectOption")
    ) {
      setCompanySelected(AllCompanies);

      setValue("company", companiesList);
    } else if (values.length === companiesList.length - 1) {
      setValue(
        "company",
        values.filter(
          (company) =>
            company.renameCompany !== t("App.downloadSalesForce.selectAll")
        )
      );
      setCompanySelected(
        values.filter(
          (company) =>
            company.renameCompany !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("company", values);
      setCompanySelected(values);
    }

    if (detail) {
      const option = detail.option;
      // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
      if (
        reason === "removeOption" &&
        option.renameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("company", []);
        setCompanySelected([]);
      }
    }
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={2}
      spacing={1}
    >
      <Grid
        xl={8.5}
        lg={8.5}
        md={8.5}
        sm={12}
        xs={12}
        item
        paddingRight={{ xs: "0%", sm: "0%", md: "1%", lg: "1%" }}
        className="side-line-contact__bottomAlign"
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            columnSpacing={1}
          >
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <label className="form__label label__text-grey">
                    {t("App.listDynamicPeriodicReport.expirationDate")}
                  </label>
                </Grid>

                <Grid item width={"100%"}>
                  <Controller
                    name={"expiration"}
                    render={({ field: { onChange, value } }) => (
                      <DatePickerMonthYear
                        className={`${
                          errors.status
                            ? "select-contact__filters__errors__xs"
                            : "select-contact__filters"
                        } form__label autoComplete__filter
                          `}
                        onChange={onChange}
                        minDate={minDate}
                        // value={value || null}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <label className="form__label label__text-grey">
                    {t("App.listDynamicPeriodicReport.state")}
                  </label>
                </Grid>

                <Grid item width={"100%"}>
                  <Controller
                    name={"status"}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className="select-contact__filters"
                        options={StateOptions}
                        renderInput={(params) => {
                          return (
                            <TextField
                              placeholder={t(
                                "App.listDynamicPeriodicReport.state"
                              )}
                              {...params}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width={"100%"}
              >
                <Grid item>
                  <label className="form__label label__text-grey">
                    {t("App.listDynamicPeriodicReport.cen.company")}
                  </label>
                </Grid>

                <Grid item width={"100%"}>
                  <Controller
                    name={"company"}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        sx={{ height: "39px", background: "white" }}
                        noOptionsText={t(
                          "App.validationMessages.noMatchFilter"
                        )}
                        className="select-contact__filters"
                        multiple
                        isOptionEqualToValue={(option, value) =>
                          option.renameCompany === value.renameCompany
                        }
                        filterOptions={filterOptions}
                        id="checkboxes-tags-demo"
                        value={value || []}
                        options={companiesList}
                        disableCloseOnSelect
                        limitTags={2}
                        onChange={OnCompanyChange}
                        getOptionLabel={(option) => option.renameCompany}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              className="autoComplete__checkbox"
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.renameCompany}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("App.userDetail.company")}
                            {...params}
                          />
                        )}
                        renderTags={(value, getTagProps) => {
                          const numTags = value.length;
                          const limitTags = 1;
                          return (
                            <>
                              {value
                                .slice(0, limitTags)
                                .map((option, index) => (
                                  <Chip
                                    className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                    sx={{
                                      width: "50%",
                                    }}
                                    key={index}
                                    size="small"
                                    label={option.renameCompany}
                                  />
                                ))}
                              {numTags > limitTags &&
                                ` +${numTags - limitTags}`}
                            </>
                          );
                        }}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid xl={3.5} lg={3.5} md={3.5} sm={12} xs={12} item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={2}
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              className={
                watch("status") || startDate || watch("company")?.length > 0
                  ? "btn__applyFilter-reload"
                  : "btn__applyFilter-reload-disabled"
              }
              disabled={
                watch("status") || startDate || watch("company")?.length > 0
                  ? false
                  : true
              }
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width="100%">
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className="btn__deleteFilter-reload"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterPeriodicReportsCEN;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectDeleteOrReload from "./selectDeleteOrReload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AdminReload from "./adminReload";
import AdminDelete from "./adminDelete";
import { useSelector } from "react-redux";
import { getCurrentAction } from "../../../parts/deleteAndReload/deleteAndReloadSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../../components/common/Loader";

const DeleteAndReload = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentAction = useSelector(getCurrentAction);
  // Mostrar Loader
  const [showLoader, setShowLoader] = useState(false);

  const handleRequest = () => {
    navigate("request");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
        className="titlee-section"
      >
        <Grid item className="title-padding">
          <p className="display-large__primary-one">
            {t("App.deleteAndReload.title")}
          </p>
        </Grid>
        <Grid item>
          <Button className="btn__filled__blue" onClick={handleRequest}>
            {" "}
            {t("App.deleteAndReload.request")}
          </Button>
        </Grid>
      </Grid>
      <Grid item className="grid__container__fullW">
        <SelectDeleteOrReload />
      </Grid>
      {currentAction !== "2" && (
        <>
          <Grid item className="grid__container__fullW">
            <Accordion className="accordion__upload" defaultExpanded="false">
              <AccordionSummary
                expanded="false"
                expandIcon={
                  <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Grid container direction="row">
                  <Grid item>
                    {currentAction === "1" ? (
                      <RefreshOutlinedIcon className="accordion__upload__downloadIcon" />
                    ) : (
                      <DeleteOutlinedIcon className="accordion__upload__deleteIcon" />
                    )}
                  </Grid>
                  <Grid item className="col-flex">
                    <p
                      className="display-small__primary-one"
                      style={{ paddingLeft: "1rem" }}
                    >
                      {currentAction === "1"
                        ? t("App.deleteAndReload.reload")
                        : t("App.deleteAndReload.deleted")}
                    </p>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className="accordion__upload__detail">
                {currentAction === "1" ? (
                  <AdminReload setShowLoader={setShowLoader} />
                ) : (
                  <AdminDelete setShowLoader={setShowLoader} />
                )}
              </AccordionDetails>
            </Accordion>
            <Loader showLoader={showLoader} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeleteAndReload;

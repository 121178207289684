import { Button, Grid, IconButton, Skeleton } from "@mui/material";
import React, { useState } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import DatePickerMonthYear from "./DatePickerMonthYear.jsx";
import { FilterReports } from "../../../services/user/listDynamicReportService";
import { getUser } from "../../../pages/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PeriodicReportState from "./periodicReportState";

export const FilterDynamicReports = (props) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(null);

  const minDate = new Date();

  const userInfo = useSelector(getUser);

  const schema = yup.object().shape({
    ean: yup.string().min(2),
    country: yup.string().min(2),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const navigate = useNavigate();

  const handleFilters = async (data) => {
    props.setData(skeletonLine);
    props.setNoReports(false);
    if (
      data.status.value === "ACTIVO PROXIMO A VENCER" ||
      data.status.value === "INACTIVO VENCIDO"
    ) {
      await props.fetchData(data.status.value);
    } else {
      try {
        let date = null;
        if (startDate) {
          const month = startDate.getMonth() + 1;
          date = `${startDate.getFullYear()}-${
            month < 10 ? "0" + month : month
          }`;
        }

        const obj = {
          ...(data.status && { reportStatus: data.status.value }),

          eanProvider: userInfo.company.eanCompany,
          country: userInfo.company.country,
          emails: userInfo.email,
          ...(date && { expirationDate: date }),
        };

        props.setFilters(obj);
        const reportService = await FilterReports(obj);

        if (
          reportService.status === CODES.COD_RESPONSE_HTTP_OK &&
          (reportService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
            reportService.data.responseCode === CODES.COD_RESPONSE_SUCCESS)
        ) {
          const responseMessage = reportService.data.responseMessage;
          const ProcessRows = responseMessage.map((item, idx) => {
            return {
              creationDate: !item.dynamicReportDTO.creationDate
                ? " - "
                : item.dynamicReportDTO.creationDate,
              creationUser: !item.dynamicReportDTO.creationUser
                ? " - "
                : item.dynamicReportDTO.creationUser,
              modificationDate: !item.dynamicReportDTO.modificationDate
                ? " - "
                : item.dynamicReportDTO.modificationDate,
              modificationUser: !item.dynamicReportDTO.modificationUser
                ? " - "
                : item.dynamicReportDTO.modificationUser,
              expirationDate: !item.dynamicReportDTO.expirationDate
                ? " - "
                : item.dynamicReportDTO.expirationDate,
              generationFrequency: !item.dynamicReportDTO.generationFrequency
                ? " - "
                : item.dynamicReportDTO.generationFrequency,
              typeOfDataGrouping: !item.dynamicReportDTO.typeOfDataGrouping
                ? " - "
                : item.dynamicReportDTO.typeOfDataGrouping,
              fileName: !item.dynamicReportDTO.fileName
                ? " - "
                : {
                    fileName: item.generatedFileName,
                    fileNameOrignal: item.dynamicReportDTO.fileName,
                    pathFileName: item.pathFileName,
                    // state: !master.state ? "" : master.state,
                  },
              detailErrors: !item.logFileName
                ? " - "
                : {
                    logFileName: item.logFileName,
                    pathLogFileName: item.pathLogFileName,
                    // state: !master.state ? "" : master.state,
                  },
              status: !item.state
                ? ""
                : props.calculateRestDays(
                    item.dynamicReportDTO.expirationDate,
                    item.state
                  ),
              edit: (
                <IconButton
                  onClick={() =>
                    navigate(
                      `/user/editDynamicPeriodicReport/${
                        item.pk.split("#")[1]
                      }/${item.sk.split("#")[1]}`
                    )
                  }
                >
                  <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
                </IconButton>
              ),
            };
          });

          if (responseMessage.length > 0) {
            props.setNoFound(false);
          } else {
            props.setNoFound(true);
          }

          // props.setData(ProcessRows);
          filterActiveInActive(data.status.value, ProcessRows);
        } else {
          props.setNoFound(true);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
  };

  /**
   * Permite filtrar los reportes por estado ACTIVO o INACTIVO
   * @param {*} status
   * @param {*} rows
   */
  const filterActiveInActive = (status, rows) => {
    props.setData(
      rows.filter((item) => {
        return item.status === status;
      })
    );
  };

  const skeletonLine = [
    {
      creationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      creationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      expirationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      generationFrequency: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      ),
      typeOfDataGrouping: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      fileName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      status: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      detailErrors: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  const options = [
    { label: t("App.userDetail.filter.active"), value: "ACTIVO" },
    { label: t("App.userDetail.filter.inactive"), value: "INACTIVO" },
    {
      label: `${t("App.userDetail.filter.active")} ${t(
        "App.listDynamicPeriodicReport.tooltips.nextToExpire"
      ).toLowerCase()}`,
      value: "ACTIVO PROXIMO A VENCER",
    },
    {
      label: `${t("App.userDetail.filter.inactive")} ${t(
        "App.listDynamicPeriodicReport.tooltips.expired"
      ).toLowerCase()}`,
      value: "INACTIVO VENCIDO",
    },
  ];

  const handleCleanFilters = () => {
    setValue("status", 0);
    setValue("expiration", null);
    props.setFilters({});
    props.setNoFound(false);
    setStartDate(null);
    props.setData(props.unfilteredData);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={2}
    >
      <Grid xs={12} md={8} item className="side-line">
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            columnSpacing={4}
            style={{ marginBottom: "2%" }}
          >
            <Grid item >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <label className="form__label label__text-grey">
                    {t("App.listDynamicPeriodicReport.expirationDate")}
                  </label>
                </Grid>

                <Grid item >
                  <Controller
                    name={"expiration"}
                    render={({ field: { onChange, value } }) => (
                      <DatePickerMonthYear
                        className={`${
                          errors.status
                            ? "select__filters__errors"
                            : "select__filters"
                        } form__label autoComplete__filter
                          padding-datepicker-monthyear`}
                        onChange={onChange}
                        minDate={minDate}
                        // value={value || null}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="field_container">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <label className="form__label label__text-grey">
                    {t("App.listDynamicPeriodicReport.state")}
                  </label>
                </Grid>

                <Grid item width={175}>
                  <Controller
                    name={"status"}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={`${
                          errors.status
                            ? "select__filters__errors"
                            : "select__filters"
                        } full-width autoComplete__filter`}
                        options={options}
                        renderInput={(params) => {
                          return (
                            <TextField
                              placeholder={t(
                                "App.listDynamicPeriodicReport.state"
                              )}
                              {...params}
                              onChange={onChange}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid xs={4} item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap={2}
          ml={3}
        >
          <Grid item>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              className={
                watch("status") || startDate
                  ? "btn__applyFilter"
                  : "btn__applyFilter-disabled"
              }
              disabled={watch("status") || startDate ? false : true}
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className="btn__deleteFilter"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

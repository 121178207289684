import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CODES } from "../consts/codes";
import { GetUserByEmail } from "../services/user/userServices";
import jwt_decode from "jwt-decode";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: "",
    status: "idle",
    error: null,
  },
  reducers: {
    resetUserStatus(state, action) {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, _) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.data.responseMessage;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// GETTERSS
export const getUser = (state) => state.user.user;
export const getUserStatus = (state) => state.user.status;

// ACTIONS

export const { resetUserStatus } = userSlice.actions;

//FETCHERS
export const fetchUser = createAsyncThunk("user/fetchuser", async () => {
  const jwtTokenInfo = localStorage.getItem("infoToken");
  let email = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    email = jwtDecodeTokenInfo.email;
  }
  const obj = {
    email: email,
  };
  const response = await GetUserByEmail(obj);

  if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
    return {
      status: response.status,
      data: response.data,
    };
  }
});

export default userSlice.reducer;

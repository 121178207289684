import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Utilizar el servicio para obtener las solicitudes OC de comercio
 * @param {*} data
 * @returns
 */
export async function GetListRequestOCCommerce(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests/traders`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para Aprobar solicitud Excepcion OC
 * @param {*} data
 * @returns
 */
export async function ApproveOCCommerce(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests/approvals`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para Rechazar solicitud Excepcion OC
 * @param {} data
 * @returns
 */
export async function DeclineOCCommerce(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests/cancellations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener los productos relacionados
 * con la OC
 * @param {*} data
 * @returns
 */
export async function GetProductsOCCommerce(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `purchases/orders/exceptions/requests/products`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

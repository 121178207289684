import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
} from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { DeleteService } from "../../../services/user/deleteAndReload/deleteAndReload";
import { CODES } from "../../../consts/codes";
import FilterDelete from "../../../components/common/Filters/filterDelete";
const AdminDelete = ({ setShowLoader }) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use State
   */

  const [data, setData] = useState([]);

  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [responseMessageModalInfo, setResponseMessageModalInfo] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});

  /**
   * Aplicar el servicio de borrado cuando se confirma
   */
  const handleAgreeDelete = async () => {
    setIsOpenDelete(false);

    try {
      setShowLoader(true);

      const deleteservice = await DeleteService(deleteObject);
      if (
        deleteservice.status === CODES.COD_RESPONSE_HTTP_OK ||
        deleteservice.data.responseCode === CODES.COD_RESPONSE_ERROR_CREATE
      ) {
        handleSetTitle(deleteservice.data.responseCode);
        setOpenModalInfo(true);
        setResponseMessageModalInfo(deleteservice);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
    setShowLoader(false);
  };

  /**
   * Cerrar el modal cuando se cancela el borrado
   */
  const handleDisagreeDelete = () => {
    setIsOpenDelete(false);
  };

  /**
   * Cambiar el titulo del modal de acuerdo al codigo de respuesta
   * @param {*} responseCode // código de respuesta
   *    */
  const handleSetTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        setConfirmationTitle(
          t("App.deleteAndReload.reloadChildren.reloadError")
        );
        break;
      case CODES.COD_RESPONSE_SUCCESS:
        setConfirmationTitle(
          t("App.deleteAndReload.deleteChildren.deleteResponse")
        );
        break;
      case CODES.COD_RESPONSE_ERROR_CREATE:
        setConfirmationTitle("");
        break;
      default:
        break;
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item style={{ width: "100%" }}>
        <Accordion className="accordion__filter" expanded={true}>
          <ModalInfo
            title={confirmationTitle}
            responseData={responseMessageModalInfo}
            open={openModalInfo}
            onClose={() => {
              setOpenModalInfo(false);
            }}
          />

          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ cursor: "auto !important" }}
          >
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />

            <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
              {t("App.deleteAndReload.filters.deleteHelper")}
            </p>
            <Tooltip
              title={t("App.deleteAndReload.filters.deleteWarning")}
              sx={{ marginLeft: 2 }}
            >
              <ErrorOutlineOutlinedIcon
                style={{ color: "#553AB6", fontSize: 20 }}
              />
            </Tooltip>
          </AccordionSummary>

          <AccordionDetails>
            <FilterDelete
              data={data}
              setData={setData}
              requestType={"BORRADO"}
              setIsOpenDelete={setIsOpenDelete}
              setDeleteObject={setDeleteObject}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <ModalDecision
        isOpen={isOpenDelete}
        setIsOpen={setIsOpenDelete}
        handleAgree={handleAgreeDelete}
        handleDisagree={handleDisagreeDelete}
        title={t("App.deleteAndReload.deleteChildren.deleteQuestionTitle")}
        message={t("App.deleteAndReload.deleteChildren.deleteQuestion")}
        agreeText={t("App.buttonLabels.accept")}
        disagreeText={t("App.buttonLabels.cancel")}
      />
    </Grid>
  );
};

export default AdminDelete;

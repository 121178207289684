import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../../pages/userSlice";
import React, { useEffect, useState } from "react";
import { CODES } from "../../../consts/codes";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const TypeSelectorReport = ({
  reportType,
  setReportType,
  control,
  handleTypeChange,
  typeOfDocument,
  errors,
  setValue,
  setStartForm,
  setDesiredType,
  setIsBusinessGroupReport,
  handleCleanForm,
  getValues,
  componentName,
  typeOption = 1,
}) => {
  /**
   * Const
   */

  //Checkbox
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  /**
   * Use State
   */

  //Lista de compañías
  const [companyList, setCompanyList] = useState([]);

  //Grupo empresarial
  const [isBusinessGroup, setIsBusinessGroup] = useState(false);
  const [isCompanySelected, setIsCompanySelected] = useState(false);

  //Cargas
  const [isLoading, setIsLoading] = useState(false);

  /**
   * useEffect que consulta las empresas
   */
  useEffect(() => {
    /**
     * Función que trae la data para settear los estados de companyList y eanList
     */
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const obj = {
          country: userInfo.company.country,
          eanProvider: userInfo.company.eanCompany,
          email: userInfo.email,
          typeOption: typeOption,
        };

        const listService = await ListBusinessGroup(obj);

        const { status, data } = listService;

        if (
          status === CODES.COD_RESPONSE_HTTP_OK &&
          Number(data.responseCode) === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const companyName = data.objectData.map((company) => {
            return {
              label: company.renameCompany,
              value: company.eanCompany,
              country: company.country,
            };
          });

          setCompanyList(companyName);
          setIsCompanySelected(false);
          setIsBusinessGroup(true);
          setIsBusinessGroupReport(true);
        } else {
          setValue("lstCompanies", [
            {
              label: `${userInfo.company.country}-${userInfo.company.companyName}`,
              value: userInfo.company.eanCompany,
              country: userInfo.company.country,
            },
          ]);
          setIsBusinessGroupReport(false);
          setIsBusinessGroup(false);
          setIsCompanySelected(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (userInfo) {
      fetchData();
    }
  }, [userStatus, userInfo]);

  /**
   * Handles
   */

  /**
   * Resetea el formulario y lo oculta
   */
  const handleResetForm = () => {
    setIsCompanySelected(false);
    setDesiredType(null);
    setValue(typeOfDocument, typeOfDocument === "type" ? 0 : "0");
    setReportType(typeOfDocument === "type" ? 0 : "0");
    setStartForm(false);
    handleCleanForm();
  };

  /**
   * Oculta y resetea el formulario cuando el usuario limpia el campo de empresa
   * @param {*} reason razón del cambio en el campo
   */
  const handleClearCompanyInput = (reason) => {
    if (reason === "clear") handleResetForm();
  };

  /**
   * Aplica cambios sobre el formulario cuando se cierra el campo empresa
   */
  const handleOnCloseCompanyInput = () => {
    const companies = getValues("lstCompanies");

    if (companies.length > 0) {
      setIsCompanySelected(true);
    } else {
      handleResetForm();
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="grid__container__filled__rounded"
    >
      <Grid item className="full-width"
      lg={4}
      mr={1}
      ml={2}
      >
        <Controller
          name="lstCompanies"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={isLoading ? true : isBusinessGroup ? false : true}
              noOptionsText={t("App.listUsers.noOptions")}
              disableCloseOnSelect
              className={`${
                errors.lstCompanies
                  ? "select__filters__errors"
                  : "select__filters"
              } full-width autoComplete__filter`}

              options={companyList}
              multiple
              limitTag={1}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              onChange={(event, values, reason, detail) => {
                onChange(values);
              }}
              onInputChange={(event, value, reason) => {
                handleClearCompanyInput(reason);
              }}
              onClose={(event, reason) => {
                handleOnCloseCompanyInput();
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <Checkbox
                    icon={icon}
                    className="autoComplete__checkbox"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder={t("App.indicadors.company")}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? (
                            <CircularProgress
                              sx={{ color: "#4DCAFA" }}
                              size={15}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const limitTags = 1;

                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => (
                      <Chip
                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                        sx={{
                          width: "55%",
                        }}
                        key={index}
                        size="small"
                        label={`${option.label}`}
                      />
                    ))}
                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              value={value}
            />
          )}
          control={control}
        />
      </Grid>

      <Grid item 
        lg={3}
        md={5}
        mr={1}
        ml={2}
        xs={12}
        mt={1}
      >
        <p className="heading__text-default">
          {componentName === "specialFile"
            ? t("App.specialFilesClient.choose")
            : t("App.dynamicPeriodicReport.choose")}
        </p>
      </Grid>

      <Grid item 
        className="full-width"
        lg={3}
        mb={2}
        md={5}
        xs={12}
        mt={2}
        mr={1}
        ml={2}
        >
        <Controller
          name={typeOfDocument}
          defaultValue={typeOfDocument === "type" ? 0 : "0"}
          render={({ field: { onChange, value } }) => (
            <Select
              disabled={isCompanySelected ? false : true}
              value={reportType}
              onChange={(e) => {
                onChange(e);
                handleTypeChange(e);
              }}
              className={`${
                errors.typeOfDocument
                  ? "select__filters__errors"
                  : "select__filters"
              } full-width autoComplete__filter`}
              id={typeOfDocument}
              name={typeOfDocument}
              labelId={typeOfDocument}
            >
              <MenuItem disabled value="0">
                <em> {t("App.dynamicPeriodicReport.type.placeholder")}</em>
              </MenuItem>
              <MenuItem value="1">
                <em> {t("App.dynamicPeriodicReport.type.inventory")}</em>
              </MenuItem>
              <MenuItem value="2">
                <em> {t("App.dynamicPeriodicReport.type.sales")}</em>
              </MenuItem>
            </Select>
          )}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const NoInfoDashboard = () => {
  
  //states para saber el tamaño de la pantalla y asi aplicar los Props para los grid
  const [isHD, setHD] = useState(window.innerWidth > 1900);
  const [isTablet, setTablet] = useState(window.innerWidth > 760);
  const [isCarvajal, setIsCarvajal] = useState(window.innerWidth > 1335);

  const updateMedia = () => {
    setHD(window.innerWidth > 1900);
    setTablet(window.innerWidth > 760);
    setIsCarvajal(window.innerWidth > 1335);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const { t } = useTranslation();

  let gridProps = {};
  let containerProps = {};

  if (isHD) {
    gridProps = { pr: 30, ml: 15 };
  } else if (isCarvajal) {
    gridProps = { pr: 10, ml: 5 };
    containerProps = { p: 7 };
  } else if (isTablet) {
    gridProps = { pr: 10, ml: 5 };
    containerProps = { p: 7 };
  } else {
    gridProps = { p: 3 };
  }

  return (
    <div className="container-welcome">
      <Grid
        container
        spacing={2.5}
        alignItems="center"
        justifyContent="center"
        {...containerProps}
      >
        <Grid item lg={5} xs={12} {...gridProps}>
          <h1 className="display-x-large-home__primary-one">
            {t("App.noInfoDashboard.title")}
          </h1>

          <span className="text-center-bigger">
            <p>{t("App.noInfoDashboard.text")}</p>
          </span>
        </Grid>

        <Grid item lg={5} xs={12}>
          <img
            src="/assets/noInfoDashboard/noInfoDashboard.svg"
            alt="Girl image"
            className="img-girl"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NoInfoDashboard;

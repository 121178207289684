import manWithPc from "../../../assets/common/manWithPc.svg";
import womanWithPc from "../../../assets/common/womanWithPc.svg";
import { useTranslation } from "react-i18next";
export const LoadingContent = () => {
  const { t } = useTranslation();
  return (
    <div className="container" style={{ margin: "auto" }}>
      <div className="container-circles">
        <div className="circle-woman">
          <img className="woman-pc" src={womanWithPc} alt="Mujer con un pc" />
          <div className="circle1"></div>
        </div>
        <div className="circle2"></div>
        <div className="circle3"></div>
        <div className="circle-man">
          <img className="man-pc" src={manWithPc} alt="Hombre con un pc" />
          <div className="circle4"></div>
        </div>
        <div className="circle5"></div>
        <div className="circle6"></div>
      </div>

      <h3>{t("App.loadingComponent.loading")}</h3>
      <p>{t("App.loadingComponent.wait")}</p>
    </div>
  );
};

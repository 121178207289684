import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";
import { convertFileToBase64 } from "../../../utils/functions";
import { CODES } from "../../../consts/codes";

//DESCARGA

/**
 * Petición que trae las solicitudes de descarga del comercio
 * @param {*} data información de la petición
 * @returns solicitudes de descarga comercio
 */
export async function GetTradersCatalogDownload(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Petición para generar una petición de descarga de un comercio
 * @param {*} data información de la petición
 * @returns confirmación de descarga
 */
export async function DownloadTradersCatalog(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `products/traders/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

//CARGA

/**
 * Petición que trae la plantilla de catálogo comercios
 * @returns plantilla codificada
 */
export async function DownloadTemplateTraders() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `products/traders/downloads/templates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Petición que trae la guía de catáloog de comercios
 * @returns guía codificada
 */
export async function DownloadGuideTraders() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `products/traders/downloads/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Petición que carga un nuevo catálogo del comercio
 * @param {*} data información del catálogo
 * @returns respuesta de carga
 */
export async function UploadCatalogTradersRequest(data) {
  const token = await getToken();

  const formData = new FormData();

  formData.append("file", data.file);

  const finalData = await convertFileToBase64(
    formData,
    CODES.CATALOGCOMMERCE,
    data
  );

  return axios({
    method: "POST",
    data: finalData,
    url: `masters/uploads/catalogs/traders`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Trae la lista de cargas de catálogo de comercio
 * @param {*} data información del comercio
 * @returns lista de cargas de catálogo
 */
export async function GetCatalogsTraders(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Petición que trae el preview de catálogo del comercio
 * @param {*} data información del comercio
 * @returns árbol de catálogo de productos
 */
export async function GetPreviewCatalogTraders(data) {
  const token = await getToken();
  const { eanTrader } = data;
  return axios({
    method: "GET",
    data: data,
    url: `products/traders/${eanTrader}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

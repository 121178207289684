import { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { CODES } from "../../../consts/codes";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { GetRoleMenu } from "../../../services/user/consultRolesService";

const ModalPreviewMenuRoles = (props) => {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const [open, setOpen] = useState(false);
  const [level1, setLevel1] = useState([]);
  const { t, i18n } = useTranslation();

  /**
   * Method to open the modal and fetch the data to set the info of the modal preview menu (see details)
   */
  const handleClickOpen = () => {
    setOpen(true);

    async function fetchData() {
      try {
        const obj = {
          pk: props.role.pk,
          sk: props.role.sk,
        };

        const previewMenuService = await GetRoleMenu(obj);

        // console.log(previewMenuService);

        if (
          previewMenuService.status !== CODES.COD_RESPONSE_HTTP_OK ||
          previewMenuService.data.responseCode !==
            CODES.COD_RESPONSE_SUCCESS_REQUEST
        )
          return;

        const Level1 = previewMenuService.data.responseMessage;
        setLevel1(Level1);
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
  };

  /**
   * Method to close the modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <IconButton onClick={handleClickOpen}>
        <SearchRoundedIcon fontSize={"24px"} style={{ color: "#543ab4" }} />
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxHeight: "80rem",
              maxWidth: "55rem",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
              "@media (max-width: 768px)": {
                maxHeight: "100rem",
                maxWidth: "60rem",
              },
            },
            "& .MuiDialog-paper": {
              height: "55%",
              width: "55%",
              "@media (max-width: 768px)": {
                height: "60%",
                width: "100%",
              },
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="title"
          className="display-large__primary-one"
          sx={{ padding: "3rem 1rem 2rem 1.5rem" }}
          onClose={handleClose}
        >
          {props.role.nameRole}
        </BootstrapDialogTitle>

        <BootstrapDialogTitle id="subtitle" className="label__text-default">
          {t("App.listUsers.product")}:{" "}
          <span className="state-colors__primary-color-one">
            {props.role.menuOptionName}
          </span>
        </BootstrapDialogTitle>

        <BootstrapDialogTitle
          id="paragraph"
          className="label__text-default labels_content"
        >
          {t("App.consultRoles.seeDetailsText")}
        </BootstrapDialogTitle>

        <DialogContent>
          <Grid>
            {level1.map((level) => {
              const level2 = level.levels;

              return (
                <Grid
                  key={level.pk}
                  className="modalPreviewMenu__form-box-level1"
                >
                  <Accordion
                    disableGutters
                    className="modalPreviewMenu__accordion"
                    defaultExpanded={level2.length > 0 ? true : false}
                  >
                    <AccordionSummary
                      className="modalPreviewMenu__accordion__summary"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p
                        className={`${
                          level2.length > 0
                            ? "label__primary-one"
                            : "label__text-default"
                        } labels_content`}
                      >
                        {i18n.language.includes("es")
                          ? level.menuOptionName
                          : level.nameEnglish}
                      </p>
                    </AccordionSummary>

                    <AccordionDetails className="modalPreviewMenu__accordion__detail">
                      <List disablePadding className="modalPreviewMenu__list">
                        {level2.map((level2, idxParent) => {
                          const level3 = level2.levels;
                          if (level3.length > 0) {
                            return (
                              <Grid
                                key={level2.pk}
                                className="modalPreviewMenu__form-box-level2"
                              >
                                <Accordion
                                  disableGutters
                                  className="modalPreviewMenu__accordion"
                                  defaultExpanded={
                                    level3.length > 0 ? true : false
                                  }
                                >
                                  <AccordionSummary
                                    className="modalPreviewMenu__accordion__summary"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <ListItem disablePadding>
                                      <p
                                        className={`${
                                          level3.length > 0
                                            ? "label__primary-one"
                                            : "label__text-default"
                                        } labels_content`}
                                      >
                                        {i18n.language.includes("es")
                                          ? level2.menuOptionName
                                          : level2.nameEnglish}
                                      </p>
                                    </ListItem>
                                  </AccordionSummary>

                                  <AccordionDetails className="modalPreviewMenu__accordion__detail">
                                    <List
                                      disablePadding
                                      className="modalPreviewMenu__list"
                                    >
                                      {level3.map((level3, idx) => {
                                        return (
                                          <Grid
                                            key={level3.pk}
                                            className="modalPreviewMenu__form-box-level3"
                                          >
                                            <ListItem disablePadding>
                                              <p className="label__text-default labels_content">
                                                {i18n.language.includes("es")
                                                  ? level3.menuOptionName
                                                  : level3.nameEnglish}
                                              </p>
                                            </ListItem>
                                          </Grid>
                                        );
                                      })}
                                    </List>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid
                                key={level2.pk}
                                className="modalPreviewMenu__form-box-level2"
                              >
                                <ListItem disablePadding>
                                  <p
                                    className={`${
                                      level3.length > 0
                                        ? "label__primary-one"
                                        : "label__text-default"
                                    }`}
                                  >
                                    {i18n.language.includes("es")
                                      ? level2.menuOptionName
                                      : level2.nameEnglish}
                                  </p>
                                </ListItem>

                                <List
                                  disablePadding
                                  className="modalPreviewMenu__list"
                                >
                                  {level3.map((level3, idx) => {
                                    return (
                                      <Grid
                                        key={level3.pk}
                                        className="modalPreviewMenu__form-box-level3"
                                      >
                                        <ListItem disablePadding>
                                          <p className="label__text-default">
                                            {i18n.language.includes("es")
                                              ? level3.menuOptionName
                                              : level3.nameEnglish}
                                          </p>
                                        </ListItem>
                                      </Grid>
                                    );
                                  })}
                                </List>
                              </Grid>
                            );
                          }
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <Grid className="modalPreviewMenu__form__actions">
          <Button
            autoFocus
            type="submit"
            className="modalPreviewMenu__form__actions-buttonFilled"
            onClick={handleClose}
          >
            {t("App.buttonLabels.return")}
          </Button>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default ModalPreviewMenuRoles;

import { Button, FormControlLabel, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoopIcon from "@mui/icons-material/Loop";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import DownloadBox from "../../../components/common/Boxes/downloadBox";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import {
  DownloadMasterFromTable,
  DownloadMastersBudget,
  DownloadMastersProductsReferences,
} from "../../../services/user/masters/mastersService";
import { CODES } from "../../../consts/codes";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";

import { getUser, getUserStatus } from "../../userSlice";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  getMastersDownloadStatus,
  fetchMastersDownload,
  getCurrentMaster,
  getMastersDownload,
  getMastersStatus,
  resetMastersDownloadStatus,
} from "../../../parts/masters/mastersSlice";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../components/common/fileState";
import FilterBusinessGroupWB from "../../../components/common/Filters/businessGroup/filterWithoutButtons";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import ModalInfoMasters from "../../../components/common/Modals/modalInfoMasters";

const DownloadMasters = () => {
  /**
   * Use Translation
   * */
  const { t } = useTranslation();

  //Estados para tabla
  const [open, setOpen] = useState(false);
  const [textState, setTextState] = useState("");

  /*
   * Table
   */
  const columns = [
    {
      name: "initialUploadDate",
      label: t("App.downloadCatalogue.downloadTable.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "finalUploadDate",
      label: t("App.downloadCatalogue.downloadTable.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.downloadCatalogue.downloadTable.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "company",
      label: t("App.listUsers.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.downloadCatalogue.downloadTable.downloadFile"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: value.state === "FINALIZADO" ? "pointer" : "default",
                }}
                className={
                  value.state === "FINALIZADO"
                    ? "default-text__linkBlue__sm"
                    : "default-text__linkBlue__sm__grey"
                }
                onClick={(event) => {
                  if (value.state === "FINALIZADO") {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container justifyContent="space-around" alignItems="center">
              {validateStates("ERROR SISTEMA") ? (
                <a style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                  <FileState
                    value={value}
                    onChange={(event) => updateValue(event.target.value)}
                  />
                </a>
              ) : (
                <FileState
                  value={value}
                  onChange={(event) => updateValue(event.target.value)}
                />
              )}

              {validateStates(value) && (
                <ModalInfoMasters
                  state={value}
                  text={textState}
                  onClose={() => setOpen(false)}
                  open={open}
                />
              )}
            </Grid>
          );
        },
      },
    },
  ];

  /**
   * Opciones de tabla
   */

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <Skeleton />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  //Archivos de master descargados
  const mastersDownload = useSelector(getMastersDownload);
  const mastersDownloadStatus = useSelector(getMastersDownloadStatus);

  //Master actual
  const master = useSelector(getCurrentMaster);

  //Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */

  //User
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  //Tabla
  const [data, setData] = useState([]);
  const [noDownloadMasters, setNoDownloadMasters] = useState(false);

  //Modal descarga
  const [downloadTitle, setDownloadTitle] = useState("");
  const [downloadResponseData, setDownloadResponseData] = useState({});

  //Cargas visuales de request
  const [isOpenDownloadedFile, setIsOpenDownloadedFile] = useState(false);
  const [isLoadingDownloadRequest, setIsLoadingDownloadRequest] =
    useState(false);

  //Cargas visuales de refrescar
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  //Grupo empresarial
  const [showDownload, setShowDownload] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  /**
   * Use Effect para traer la info de la tabla
   */
  useEffect(() => {
    try {
      if (userInfo) {
        handleUpdateTable();
      }

      setLoading(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }, [userStatus, dispatch, mastersDownloadStatus]);

  /**
   * UseEffect para cargar los masters de la tabla
   */
  useEffect(() => {
    setLoading(true);
    setData([]);
    dispatch(resetMastersDownloadStatus());
  }, [master]);

  /**
   * Use Effect para mostrar por default el componente de carga si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [isBusinessGroup]);

  /**
   * Estructura la información con la estructura de la tabla
   * @param {*} master celda de master
   * @returns master estructurado con formato de tabla
   */
  const cellStructure = (master) => {
    return {
      initialUploadDate: !master.starDate
        ? " "
        : moment(master.starDate).format("YYYY-MM-DD hh:mm:ss"),
      finalUploadDate: !master.endDate
        ? "  "
        : moment(master.endDate).format("YYYY-MM-DD hh:mm:ss"),
      user: !master.userLoad ? "" : master.userLoad,
      company: !master.company ? "" : master.company,
      file: !master.fileName
        ? ""
        : {
            fileName: master.fileName,
            pathFileName: master.pathFileName,
            state: !master.state ? "" : master.state,
          },
      state: !master.state ? "" : master.state,
    };
  };

  /**
   * Valida el status del master
   * @param {} state estado del master
   * @returns true si el estado es ERROR SISTEMA, false si no lo es
   */
  const validateStates = (state) => {
    const value = state.toLowerCase();

    if (value === "error sistema") {
      setTextState(t("App.masters.modalInfo.text"));
      return true;
    } else {
      return false;
    }
  };

  /**
   * Descarga el archivo master de la tabla
   * @param {*} fileName nombre del archivo
   * @param {*} path ubicación del archivo
   */
  const handleDownloadFile = async (fileName, path) => {
    const obj = {
      pathFileName: path,
      fileName: fileName,
    };

    try {
      const fileService = await DownloadMasterFromTable(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Carga la información de la tabla
   */
  const handleUpdateTable = async () => {
    try {
      if (mastersDownloadStatus === "fetch") {
        dispatch(
          fetchMastersDownload({
            data: {
              eanProvider: userInfo.company.eanCompany,
              country: userInfo.company.country,
              emailLoad: userInfo.email,
            },
            master,
          })
        );
      }

      switch (mastersDownloadStatus) {
        case "succeeded":
          let newData = [];
          const records = mastersDownload;

          if (records.length > 0) {
            const finalData = records.map((master) => {
              return cellStructure(master);
            });
            newData = finalData;
          }

          setNoDownloadMasters(newData.length > 0 ? false : true);

          setData(newData);
          setLoading(false);
          setIsLoadingRefresh(false);
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Refresca la información de la tabla
   */
  const handleRefresh = () => {
    setLoading(true);
    setIsLoadingRefresh(true);
    setData([]);
    dispatch(resetMastersDownloadStatus());
  };

  /**
   * Settea el título del modal basado en el responseCode de la petición
   * @param {*} response respuesta de la petición
   */
  const handleDisplayModal = (response) => {
    const { responseCode } = response.data;
    let title = "";

    switch (responseCode) {
      case 1005:
        title = t("App.masters.downloadTitles.title1");
        break;

      case 1006:
        title = t("App.masters.downloadTitles.title2");
        break;

      case 0:
        title = t("App.masters.downloadTitles.title3");
        break;
    }

    setDownloadTitle(title);
    setDownloadResponseData(response);
    setIsOpenDownloadedFile(true);
  };

  /**
   * Realiza la petición de descarga del maestro
   */
  const handleDownloadMaster = async () => {
    try {
      setIsLoadingDownloadRequest(true);

      const cleanSelectAll = selectedCompanies.filter(
        (company) => company.label !== t("App.downloadSalesForce.selectAll")
      );

      const obj = {
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        provider: userInfo.company.companyName,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        emailUser: userInfo.email,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        lstCompanies: cleanSelectAll,
      };

      let requestDownload = null;
      if (master === "0") {
        requestDownload = await DownloadMastersBudget(obj);
      } else {
        requestDownload = await DownloadMastersProductsReferences(obj);
      }

      dispatch(resetMastersDownloadStatus());

      handleDisplayModal(requestDownload);
      setIsLoadingDownloadRequest(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      setIsLoadingDownloadRequest(false);
    }
  };

  return (
    <Grid container spacing={2} direction="column">
      {isBusinessGroup && (
        <FilterBusinessGroupWB
          title={t("App.manageBusinessGroup.filter.title3")}
          setShowDownload={setShowDownload}
          setSelectedCompanies={setSelectedCompanies}
        />
      )}

      <br />
      <br />

      {showDownload && (
        <Grid item>
          <DownloadBox
            isLoading={isLoadingDownloadRequest}
            handleDownload={handleDownloadMaster}
            description={t("App.mastersDownload.description")}
            buttonText={t("App.downloadCatalogue.downloadBox.boxButton")}
          />
        </Grid>
      )}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="download__margin"
        item
        lg={4}
        sm={5}
        xs={12}
      >
        {/* <Grid item lg={4} sm={5} xs={12}> */}
        <Grid container item justifyContent="flex-start" alignItems="center">
          <Grid item className="col-flex">
            <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
          </Grid>

          <Grid
            item
            style={{
              marginLeft: "2%",
            }}
          >
            <p className="display-small__primary-one">
              {t("App.downloadSalesForce.subtitle")}
            </p>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {!noDownloadMasters && (
          <Grid
            item
            lg={12}
            // sm={7}
            // xs={8}
            container
            justifyContent="flex-end"
            alignItems="center"
            className="download__container"
            spacing={1.5}
          >
            {/* <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              className="download__container"
              spacing={1.5}
            > */}
            <Grid item className="download__description">
              {/* download-explanation */}
              <p className="heading__text-blue3">
                {t("App.downloadCatalogue.updateBox.description")}
              </p>
            </Grid>

            <Grid item className="download__container-grid-button">
              <Button
                disableRipple
                disabled={isLoadingRefresh ? true : false}
                startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                onClick={handleRefresh}
                className="btn__outlined__blue download__container-grid-button"
              >
                {!isLoadingRefresh ? (
                  t("App.downloadCatalogue.updateBox.button")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
              {/* </Grid> */}
            </Grid>
          </Grid>
        )}
      </Grid>

      {!noDownloadMasters ? (
        <Grid item style={{ width: "100%" }}>
          <>
            {loading ? (
              <MUIDataTable
                style={{ width: "100% !important" }}
                data={data}
                columns={columns}
                options={skeletonOptions}
                className="dataTable__salesForce"
              />
            ) : (
              <MUIDataTable
                style={{ width: "100% !important" }}
                data={data}
                columns={columns}
                options={options}
                className="dataTable__salesForce"
              />
            )}
          </>
        </Grid>
      ) : (
        <Grid container className="col-flex">
          <NoInfoComponent type="download" />
        </Grid>
      )}

      <ModalInfo
        title={downloadTitle}
        responseData={downloadResponseData}
        open={isOpenDownloadedFile}
        onClose={() => {
          setIsOpenDownloadedFile(false);
        }}
      />
    </Grid>
  );
};

export default DownloadMasters;

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function GetRolesList(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "roles/products/providers",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function FilterRoles(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "roles/providers/administrations",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetRoleMenu(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "roles/menus",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetRolesClientByProduct(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "roles/products/providers",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function FilterUsersClient(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "users/companies",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function getUserPermissions(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "users/permissions/emails",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function AvailableCompaniesPermissions(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "business/groups/companies",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import { Button, Checkbox, Grid, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { useSelector } from "react-redux";
import { getLstCompanies } from "../../../parts/businessGroup/businessGroupSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModalSeeCompanies from "../AdminUsersClient/modalSeeCompanies";

const UploadFileBG = ({
  handleUploadFile,
  dragText,
  fileSelected,
  setFileSelected,
  setErrorModal,
  setErrorTitle,
  userInfo,
  setCheckedReplicate,
  showUpload,
}) => {
  /**
   * use Translation
   */
  const { t } = useTranslation();

  const tooltipText = t("App.manageBusinessGroup.general.tooltip");

  /**
   * Use Selector
   */
  const lstCompanies = useSelector(getLstCompanies);

  /**
   * Use State
   */
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [legendPosition, setLegendPosition] = useState("right");

  useEffect(() => {
    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      // Leer el ancho del viewport
      const viewportWidth = window.innerWidth;

      // Determinar la posición de la leyenda según el tamaño del viewport
      if (viewportWidth <= 576) {
        setLegendPosition("bottom");
      } else {
        setLegendPosition("right");
      }
    };

    // Asignar la posición inicialmente al cargar el componente
    handleResize();

    // Agregar event listener para manejar los cambios de tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Manejo cuando el archivo (draggable) entra a la zona target de drop
   * @param {*} e evento de drag
   */
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  /**
   * Manejo cuando el archivo (draggeable) sale de la zona target de drop
   * @param {*} e evento de drag
   */
  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  /**
   * Manejo cuando el archivo (draggable) está encima de la zona target de drop
   * @param {} e evento de drag
   */
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * Manejo cuando el archivo (draggable) es droppeado en la zona target de drop
   * @param {*} e evento de drag
   */
  const handleDrop = (e) => {
    e.preventDefault();

    const {
      dataTransfer: { files },
    } = e;
    let extension = files[0].name.split(".").pop().toLowerCase();
    let size = files[0].size;

    if (extension !== "csv" || size > 10485760) {
      if (size > 10485760) {
        setErrorTitle(t("App.genericUpload.exceededLimit"));
      } else {
        setErrorTitle(t("App.genericUpload.typeNotAllowed"));
      }
      setErrorModal(true);
      setFileSelected(null);
    } else {
      setFileSelected([files[0]]);
    }
  };

  /**
   * Maneja la selección de archivo por medio de botón, verificando si este tiene
   * las especificaciones correctas, almacenando el archivo localmente si este satisface los requerimientos
   * @param {*} e archivo seleccionado
   */
  const onChangeFile = (e) => {
    let extension = e.target.files[0].name.split(".").pop().toLowerCase();
    let size = e.target.files[0].size;

    if (extension !== "csv" || size > 10485760) {
      if (size > 10485760) {
        setErrorTitle(t("App.genericUpload.exceededLimit"));
      } else {
        setErrorTitle(t("App.genericUpload.typeNotAllowed"));
      }
      setErrorModal(true);
      setFileSelected(null);
    } else {
      if (fileSelected) {
        setFileSelected(fileSelected.concat(Array.from(e.target.files)));
      } else {
        setFileSelected(Array.from(e.target.files));
      }
    }
  };

  /**
   * Realiza las acciones cuando el usuario acepta cargar el archivo
   * @param {*} event evento
   */
  const handleUploadFileInternally = async (event) => {
    event.preventDefault();
    let extension = fileSelected[0].name.split(".").pop().toLowerCase();
    let size = fileSelected[0].size;

    if (extension !== "csv" || size > 10485760) {
      if (size > 10485760) {
        setErrorTitle(t("App.genericUpload.exceededLimit"));
      } else {
        setErrorTitle(t("App.genericUpload.typeNotAllowed"));
      }

      setErrorModal(true);
      setFileSelected(null);
    } else {
      setIsLoadingUpload(true);
      await handleUploadFile();
      setIsLoadingUpload(false);
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="grid__container__dotted"
    >
      {!fileSelected && (
        <Grid item>
          <div
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              style={{ padding: "0" }}
            >
              <Grid item>
                <label htmlFor="selectedFile" style={{ cursor: "pointer" }}>
                  <UploadFileIcon sx={{ fontSize: 60, color: "#8C8D9D" }} />
                </label>
              </Grid>

              <Grid item>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="selectedFile"
                  id="selectedFile"
                  onChange={onChangeFile}
                />
                <label
                  htmlFor="selectedFile"
                  className="heading__primary-one text-center d-block u-marginB"
                >
                  {dragText}
                </label>
                <label
                  htmlFor="selectedFile"
                  className="heading__primary-one text-center d-block u-marginB"
                >
                  {t("App.salesForce.or")}
                </label>
                <label
                  htmlFor="selectedFile"
                  className="button__primary button__text-white u-marginB d-block text-center"
                >
                  {t("App.salesForce.select")}
                </label>
                <label
                  htmlFor="selectedFile"
                  className={"subheading__primary-one text-center d-block"}
                >
                  {t("App.salesForce.requirements")}
                </label>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      {fileSelected && (
        <Grid item>
          <form onSubmit={handleUploadFileInternally}>
            <div>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <TaskOutlinedIcon
                    sx={{
                      fontSize: 60,
                      color: "#543AB4",
                    }}
                  />
                </Grid>

                <Grid item>
                  <label className="label-Primary__xs heading__text-black">
                    {!fileSelected
                      ? ""
                      : fileSelected.map((item, idx) => {
                          return <p key={idx}>{item.name}</p>;
                        })}
                  </label>

                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={2}
                    style={{ marginBottom: "3%" }}
                  >
                    <Grid item>
                      <Grid container direction="row" columnSpacing={1}>
                        <Grid item>
                          <Checkbox
                            style={{ color: "#553AB6", padding: "1%" }}
                            onChange={(e) =>
                              setCheckedReplicate(e.target.checked)
                            }
                          />
                        </Grid>
                        <Grid item className="heading__text-black col-flex">
                          {t("App.manageBusinessGroup.general.check")}
                        </Grid>
                        <Grid item className="col-flex">
                          <Tooltip
                            title={
                              <span>
                                <p>
                                  {t(
                                    "App.manageBusinessGroup.general.tooltipOne"
                                  )}
                                </p>
                                <p>
                                  {t(
                                    "App.manageBusinessGroup.general.tooltipTwo"
                                  )}
                                </p>
                                <p>
                                  {t(
                                    "App.manageBusinessGroup.general.tooltipThree"
                                  )}
                                </p>
                                <p>
                                  {t(
                                    "App.manageBusinessGroup.general.tooltipFour"
                                  )}
                                </p>
                                <p>
                                  {t(
                                    "App.manageBusinessGroup.general.tooltipFive"
                                  )}
                                </p>
                                <p>
                                  {t(
                                    "App.manageBusinessGroup.general.tooltipSix"
                                  )}
                                </p>
                              </span>
                            }
                            placement={legendPosition}
                          >
                            <InfoOutlinedIcon style={{ color: "#553AB6" }} />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <ModalSeeCompanies
                        data={lstCompanies}
                        userName={`${userInfo.firstName} ${userInfo.lastName}`}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        disableRipple
                        type="submit"
                        className="label-Primary__uploadButton"
                      >
                        {!isLoadingUpload ? (
                          t("App.catalogue.uploadFile")
                        ) : (
                          <CircularProgress size={28} color="inherit" />
                        )}
                      </Button>
                    </Grid>

                    {!isLoadingUpload ? (
                      <Grid item>
                        <Button
                          className="label-Primary__cancelButton"
                          sx={{
                            ml: 1,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "transparent",
                            },
                          }}
                          onClick={() => {
                            setFileSelected(null);
                            setCheckedReplicate(false);
                          }}
                        >
                          {t("App.catalogue.cancel")}
                        </Button>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  {isLoadingUpload && (
                    <Grid container className="proccessing">
                      <p className="subheading__moderate-blue">
                        {t("App.catalogue.paragraphUpload")}
                      </p>
                    </Grid>
                  )}

                  {!isLoadingUpload && (
                    <Grid item className=" u-marginT">
                      {" "}
                      <label className={"subheading__primary-one u-marginT"}>
                        {t("App.salesForce.requirements")}
                      </label>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default UploadFileBG;

import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import jwt_decode from "jwt-decode";
import ButtonLogOut from "../common/buttonLogOut";
import { useTranslation } from "react-i18next";
import logo from "../../assets/common/logo.svg";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import MenuIcon from "@mui/icons-material/Menu";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate } from "react-router-dom";

const TopBar = ({ setShowMenu, showMenu }) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Navigate
   */
  const navigate = useNavigate();

  /**
   * Use State
   */
  const [isDesktop, setDesktop] = useState(window.innerWidth > 696);

  /**
   * Handles
   */

  /**
   * Función que redirecciona a la vista de ayuda al usuario
   */
  const handleNavigateHelpCenter = () => {
    const jwtTokenInfo = localStorage.getItem("infoToken");
    if (jwtTokenInfo) {
      const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);
      const user = jwtDecodeTokenInfo["custom:Role"];

      navigate(`/${user}/helpCenter`);
    }
  };

  return (
    <Grid container className="navbar">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          xl={6}
          lg={6}
          md={6}
          xs={6}
          sm={6}
          item
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid container direction="row">
            {!showMenu && (
              <Grid item xs={2} sm={2}>
                <IconButton
                  onClick={() => setShowMenu(true)}
                  className="hamburguer-menu"
                >
                  <MenuIcon
                    sx={{
                      fontSize: 22,
                      color: "black",
                    }}
                  />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={8} sm={8} md={12} lg={12} xl={12}>
              <img
                src={logo}
                alt="Logo Prescriptiva"
                className="navbar__logo-top"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xl={isDesktop ? 6 : 5}
          lg={isDesktop ? 6 : 5}
          md={isDesktop ? 6 : 5}
          xs={isDesktop ? 6 : 5}
          sm={isDesktop ? 6 : 5}
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
        >
          {isDesktop && (
            <Grid item xl={2.2} lg={3} sm={5}>
              <Button
                className="btn__filled__light-blue"
                sx={{
                  borderRadius: "8px",
                  minHeight: "39px",
                  minWidth: "140px",
                }}
                startIcon={<SupportAgentIcon />}
                onClick={handleNavigateHelpCenter}
              >
                {t("App.helpCenter.helpButton")}
              </Button>
            </Grid>
          )}

          {isDesktop && (
            <Grid item xs={1} sm={1} md={1} lg={0.7} xl={0.6} width={"100%"}>
              <IconButton>
                <NotificationsOutlinedIcon
                  width={"100%"}
                  sx={{ fontSize: 20, color: "#553ab6" }}
                />
              </IconButton>
            </Grid>
          )}

          <Grid
            item
            className="step-1"
            xs={8}
            sm={6}
            md={5.7}
            lg={4.85}
            xl={4.4}
            justifyContent="center"
            alignItems="center"
          >
            <ButtonLogOut />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopBar;

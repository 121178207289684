import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { getUser } from "../../../pages/userSlice";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";
import { CODES } from "../../../consts/codes";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GetPreviewSalesForce,
  GetTradersList,
} from "../../../services/user/salesForceServices";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { NoSelectionComponent } from "../noImages/noSelectionComponent";

const ModalPreviewSalesForce = ({ companySelected, isBusinessGroup }) => {
  /**
   * Render recursivo
   */

  const renderChildren = (children, paddingLeft) => {
    return (
      <>
        {children.map((c, id) => (
          <Accordion
            key={id}
            square
            elevation={0}
            className="preview-modal__secondLevel"
            sx={{
              "&:before": {
                display: "none",
              },
              minWidth: "17rem !important",
            }}
          >
            <AccordionSummary
              expandIcon={
                c.levels ? <ExpandMoreIcon fontSize="large" /> : <></>
              }
              sx={{
                "& .MuiAccordionSummary-content": {
                  flexGrow: "0",
                  marginRight: "1%",
                },
                justifyContent: "flex-start !important",
              }}
            >
              {c.nivel ? c.nivel : c.pointSaleName}
            </AccordionSummary>
            {c.levels ? (
              <AccordionDetails
                sx={{
                  backgroundColor: "white !important",
                  padding: "0 !important",
                  marginLeft: `${paddingLeft}% !important`,
                  border: "none !important",
                }}
              >
                {renderChildren(c.levels, paddingLeft)}
              </AccordionDetails>
            ) : (
              <></>
            )}
          </Accordion>
        ))}
      </>
    );
  };

  const renderParent = (parent) => {
    return (
      <Accordion
        square
        elevation={0}
        className="preview-modal__firstLevel"
        disableGutters
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className="preview-modal__arrows"
              fontSize="large"
            />
          }
          sx={{
            "& .MuiAccordionSummary-content": {
              flexGrow: "0",
              marginRight: "1%",
            },
            justifyContent: "flex-start !important",
          }}
        >
          {parent.nivel}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "white !important",
            padding: "0 !important",
            marginLeft: "3.5% !important",
            border: "none !important",
          }}
        >
          {renderChildren(parent.levels, 4)}
        </AccordionDetails>
      </Accordion>
    );
  };

  /**
   * Use State
   */
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [salesForce, setSalesForce] = useState([]);
  const [tradersList, setTradersList] = useState([]);
  const [commerce, setCommerce] = useState(0);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Form
   */
  const schema = yup.object().shape({
    commerce: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Trae las fuerzas de ventas basado en el comercio seleccionado
   * @param {*} event comercio seleccionado
   */
  const handleSearchCommerce = (event) => {
    event.preventDefault();
    async function fetchData() {
      setIsLoading(true);
      try {
        let mounted = true;
        if (mounted && userInfo) {
          const obj = {
            eanProvider: isBusinessGroup
              ? companySelected.eanCompany
              : userInfo.company.eanCompany,

            eanTrader: commerce,
          };
          const salesForcePreview = await GetPreviewSalesForce(obj);

          if (salesForcePreview.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              salesForcePreview.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              setSalesForce(salesForcePreview.data.responseMessage);
            }
          } else {
            setSalesForce([]);
          }

          setIsLoading(false);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();
  };

  /**
   * Settea el comercio seleccionado en la variable commerce
   * @param {*} event comercio seleccionado
   */
  const handleCommerceChange = (event) => {
    setCommerce(event.target.value);
  };

  /**
   * Cierra el modal de preview fuerza de ventas y resetea el formulario
   */
  const handleClose = () => {
    setOpen(false);
    setCommerce(0);
    setSalesForce([]);
  };

  /**
   * Abre el modal de preview fuerza de ventas,
   * consulta los comercios asociados a la empresa
   */
  const handleClickOpen = () => {
    setOpen(true);

    async function fetchData() {
      let mounted = true;
      try {
        if (mounted && userInfo) {
          const obj = {
            eanProvider: isBusinessGroup
              ? companySelected.eanCompany
              : userInfo.company.eanCompany,
          };
          const tradersService = await GetTradersList(obj);
          if (tradersService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              tradersService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const responseMessage = tradersService.data.data;

              const traderList = responseMessage.map((trader) => {
                return { trader: trader.nameCompany, ean: trader.eanCompany };
              });

              setTradersList(traderList);
            }
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();
  };

  return (
    <div>
      <Button
        disableRipple
        onClick={handleClickOpen}
        className="btn__filled__blue prev-refresh-buttons full-width"
      >
        {t("App.previewSalesForce.title")}
      </Button>
      <Dialog onClose={handleClose} open={open} maxWidth={"lg"}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <label className="display-large__primary-one">
            {" "}
            {t("App.previewSalesForce.title")}
          </label>
          <br />

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="u-marginT u-marginB"
          >
            <Grid item>
              <Select
                value={commerce}
                {...register("commerce")}
                onChange={handleCommerceChange}
                className={
                  errors.status
                    ? "modal__form-select--error"
                    : "modal__form-select"
                }
                defaultValue={0}
              >
                <MenuItem disabled value={0}>
                  <em>{t("App.previewSalesForce.select")}</em>
                </MenuItem>
                {tradersList.map((item) => {
                  return (
                    <MenuItem key={item.trader} value={item.ean}>
                      {item.trader}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item>
              {" "}
              <Button
                type="submit"
                onClick={handleSearchCommerce}
                disableRipple
                className={
                  commerce === 0 ? "btn__filled__disabled" : "btn__filled__blue"
                }
              >
                {t("App.buttonLabels.consult")}
              </Button>
            </Grid>
          </Grid>

          {salesForce.length > 0 ? (
            <>
              <Grid className="preview-modal__general-container">
                {isLoading ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className="display-small__text-grey"
                  >
                    <CircularProgress color="inherit" />
                  </Grid>
                ) : (
                  salesForce.map((parent) => <>{renderParent(parent)}</>)
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className="preview-modal__general-container display-small__text-grey"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <NoSelectionComponent type="salesForce" />
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <div className="u-marginB">
            <Button className="btn__outlined__blue" onClick={handleClose}>
              {t("App.catalogue.previewModal.close")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalPreviewSalesForce;

import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
const DatePickerMonthYear = ({
  className,
  setStartDate,
  startDate,
  minDate,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  return (
    <ReactDatePicker
      className={className}
      startDate={minDate}
      locale={i18n.language.includes("es") ? "es" : ""}
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
      }}
      dateFormat="MMMM 'de' yyyy"
      showMonthYearPicker
      placeholderText={t("App.listDynamicPeriodicReport.expirationDate")}
    />
  );
};

export default DatePickerMonthYear;

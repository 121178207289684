import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { openQlikSense } from "../../../helpers/helpers";

export const CardAccessSalesIndicators = ({
  icon,
  title,
  text,
  buttonText,
  link,
  userInfo,
}) => {
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;
  const emailUser = userInfo.email;

  return (
    <>
      <Card className="card-access-sales">
        <CardContent className="card-access-sales__content">
          <div className="div-icon-title">
            <IconButton className="icon-button">
              <img
                className="icon"
                src={`/assets/salesIndicatorsIcons/${icon}`}
              />
            </IconButton>
            <h2 className="display-small__text-black">{title}</h2>
          </div>

          <Grid container direction="column" className="info-access-sales">
            <Grid item>
              <p className="label__text-black text-justify">{text}</p>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                className="button__text-white button-access-sales"
                startIcon={<KeyboardArrowRightIcon />}
                onClick={() => openQlikSense(fullNameUser, emailUser, link)}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

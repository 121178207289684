import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function GetCharts(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `dashboards/home/providers/${data.eanCompany}/countries/${data.country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *  Función para obtener las compañias relacionadas
 * @param {Object} data - información de la compañia
 */
export async function BusinessRelations(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "dashboards/home/relations/traders",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetDataTableHome(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `dashboards/home/transmissions/providers/${data.eanCompany}/countries/${data.country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function IndicatorsChartHome(data) {
  const token = await getToken();

  const { eanProvider, country } = data;

  return axios({
    method: "GET",
    baseURL: env.REACT_APP_API_URL,
    url: `dashboards/home/providers/${eanProvider}/countries/${country}`,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListTransmissionsTable(data) {
  const token = await getToken();

  const { eanProvider, country } = data;

  return axios({
    method: "GET",
    baseURL: env.REACT_APP_API_URL,
    url: `dashboards/home/transmissions/providers/${eanProvider}/countries/${country}`,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}
export async function GetChartsCEN(data) {
  const token = await getToken();
  const { eanProvider, country } = data;
  return axios({
    method: "GET",
    url: `dashboards/home/providers/cen/${eanProvider}/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetDataTableHomeCEN(data) {
  const token = await getToken();
  const { eanProvider, country } = data;
  return axios({
    method: "GET",
    url: `dashboards/home/transmissions/providers/cen/${eanProvider}/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function IndicatorsChartHomeCEN(data) {
  const token = await getToken();
  const { eanProvider, country } = data;
  return axios({
    method: "GET",
    url: `dashboards/home/providers/cen/${eanProvider}/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListTransmissionsTableCEN(data) {
  const token = await getToken();
  const { eanProvider, country } = data;
  return axios({
    method: "GET",
    url: `dashboards/home/transmissions/providers/cen/${eanProvider}/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

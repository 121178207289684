import { Grid } from "@mui/material";
import { CardAccessInventoriesIndicators } from "../../../components/common/AccessIndicators/cardAccessInventoriesIndicators";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomSkeleton from "../../../components/common/CustomSkeleton";

// Service
import { getUser } from "../../userSlice";
import FilterBusinessGroup from "../../../components/common/Filters/businessGroup/filterWithButtons";
import { GetIndicators } from "../../../services/user/salesIndicatorsService";

const InventoriesIndicators = () => {
  /**
   * Use Translation
   */
  const { t, i18n } = useTranslation();

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  const [indicators, setIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Realiza la petición para traer las tarjetas de indicadores de inventarios
   * @param {*} data información de la empresa
   */
  const handleRequestInventoriesIndicators = async (data) => {
    try {
      const obj = {
        eanProvider: data.ean.eanCompany,
        indicatorType: "stock",
      };

      setIsLoading(true);

      const salesIndicator = await GetIndicators(obj);

      setIndicators(salesIndicator.data.responseMessage);

      setIsLoading(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <h1 className="display-large__primary-one text-access-sales">
        {t("App.inventoriesIndicators.title")}
      </h1>

      <br />

      <FilterBusinessGroup
        title={t("App.manageBusinessGroup.filter.title")}
        handleRequestIndicators={handleRequestInventoriesIndicators}
        setIsLoading={setIsLoading}
      />

      <br />
      <br />

      {isLoading ? (
        <CustomSkeleton />
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">
            {indicators.map((data, id) => {
              return (
                <Grid item lg={4} xs={12} sm={10}>
                  <CardAccessInventoriesIndicators
                    key={id}
                    icon={data.icon}
                    title={
                      i18n.language.includes("es")
                        ? data.name
                        : data.nameEnglish
                    }
                    text={
                      i18n.language.includes("es")
                        ? data.description
                        : data.descriptionEnglish
                    }
                    buttonText={t("App.salesIndicators.buttonText")}
                    link={data.url}
                    userInfo={userInfo}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export default InventoriesIndicators;

import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useEffect, useState } from "react";

/**
 *  Componente encargado de mostrar las opciones de menú con icono, que corresponden
 * a las opcione de nivel configuradas con icono.
 * Para agregar opcioned de menú con iconos/ nivel 1 al role Admin, se deben agregar a la vble combinedList de sideBar.jsx
 * en la parte de la funcion fetchData donde se valida el rol del usuario
 * ---------------        PROPS ----------------------
 * setSelectOption - Guardar información de la opcion seleccionada
 * selectedOption - Opción seleccionada
 * role - Rol del usuario
 * listMenuOptions - Lista de opciones de menú del usuario en sesión
 * navigate - Función para navegar entre rutas
 * routeHome - Ruta de inicio del home para el  usuario
 */
const MenusOptionsIconsMobile = ({
  setSelectedOption,
  role,
  listMenuOptions,
  navigate,
  routeHome,
  setOpenNivel1,
  isTabletWidth,
  isTabletHeight,
  setShowMenu,
}) => {
  const { i18n, t } = useTranslation();

  const [initIcon, setInitIcon] = useState(0);

  const [finalIcon, setFinalIcon] = useState(8);

  const [moreIcons, setMoreIcons] = useState(true);

  /**
   * Función para verificar si hay más opciones por mostrar
   * y realizar el desplazamiento de las opciones
   */
  const verifyMoreIcons = () => {
    // Actualiza los valores de los índices de las opciones a mostrar
    if (moreIcons) {
      setInitIcon(initIcon + 1);
      setFinalIcon(finalIcon + 1);
    } else {
      setInitIcon(initIcon - 1);
      setFinalIcon(finalIcon - 1);
    }
  };

  /**
   * Determinar que icono de navegación mostrar
   */
  useEffect(() => {
    // Verifica si hay más opciones por mostrar
    if (listMenuOptions.length - 1 - finalIcon > 0 && moreIcons) {
      setMoreIcons(true);
    } else {
      if (initIcon === 0) {
        setMoreIcons(true);
      } else {
        setMoreIcons(false);
      }
    }
  }, [initIcon, finalIcon]);

  /**
   * Función para realizar acciones al hacer click en una opción del menú
   * @param {object} item objeto con información de la opción de menú seleccionada
   */
  const handleOptionClick = (item) => {
    // Si el rol no es admin y la opción seleccionada no es Home
    // se oculta la visualización de las opciones de nivel 1
    if (role !== "admin" && item.nameEnglish !== "Home") {
      setOpenNivel1(false);
    }
    // Si la opción seleccionada es Home entonces se redirige a la ruta de inicio que le
    // corresponde al usuario, se reestablecen las opciones de menú a nivel 1 y se oculta el menú
    else if (item.nameEnglish === "Home") {
      role === "admin"
        ? navigate("/admin/home")
        : routeHome !== "" && navigate("/user" + routeHome);
      setOpenNivel1(true);
      setShowMenu(false);
    } else {
      setOpenNivel1(false);
    }
    setSelectedOption(item);
  };

  return (
    <Grid
      container
      width={isTabletWidth ? "256px" : "187px"}
      justifyContent={"center"}
      alignItems="center"
    >
      <Grid
        item
        height={"435px"}
        xs={12}
        md={12}
        lg={12}
        py={0.6}
        justifyContent={"center"}
        alignItems="center"
        textAlign={"center"}
      >
        {listMenuOptions.map(
          (item, idx) =>
            idx >= initIcon &&
            idx <= finalIcon && (
              <Grid
                container
                key={idx}
                justifyContent={"center"}
                alignItems="center"
                width="100%"
                py={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent={"center"}
                    alignItems="center"
                    textAlign={"center"}
                  >
                    <Grid
                      item
                      textAlign={"center"}
                      justifyContent={"center"}
                      width={isTabletWidth ? "200px" : "151px"}
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        alignItems="center"
                        textAlign={"center"}
                        width="100%"
                      >
                        <IconButton
                          disableRipple
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: "15px",
                            width: "100%",
                            height: "32px",
                            backgroundColor: "transparent",
                          }}
                          color="inherit"
                          id={`menu-options-${idx}`}
                          onClick={() => {
                            handleOptionClick(item);
                          }}
                        >
                          <Icon
                            sx={{
                              fontSize: 22,
                              color: "black",
                              fill: "black",
                              minWidth: 0,
                              justifyContent: "center",
                            }}
                          >
                            {item.nameEnglish === "Home" ? (
                              <HomeOutlinedIcon
                                sx={{
                                  fontSize: 22,
                                  color: "black",
                                  minWidth: 0,
                                  mr: "auto",
                                  justifyContent: "center",
                                  color: "black",
                                }}
                              />
                            ) : role === "admin" ? (
                              <AdminPanelSettingsOutlinedIcon
                                sx={{
                                  fontSize: 22,
                                  color: "black",
                                  minWidth: 0,
                                  mr: "auto",
                                  justifyContent: "center",
                                }}
                              />
                            ) : (
                              <img
                                className={"menu__icon__img"}
                                src={`/assets/menuIcons/${item.icon}`}
                                alt={item.menuOptionName}
                              />
                            )}
                          </Icon>
                          <Typography
                            variant="p"
                            className={"icons__text_mobile__responsive"}
                            style={{
                              width: isTabletWidth ? "12rem" : "8rem",
                            }}
                          >
                            {i18n.language.includes("es")
                              ? item.menuOptionName
                              : item.nameEnglish}{" "}
                          </Typography>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
      <Grid
        item
        width={isTabletWidth ? "200px" : "151px"}
        xs={12}
        md={12}
        lg={12}
        justifyContent={"center"}
        alignItems="center"
        textAlign={"center"}
      >
        {listMenuOptions.length > 9 && (
          <Grid container justifyContent="center" alignItems="flex-end">
            <Grid item>
              <IconButton
                sx={{
                  width: "48px",
                  height: "32px",
                  justifyContent: "center",
                }}
                size="large"
                disableRipple={true}
                edge="start"
                color="inherit"
              >
                <Icon
                  onClick={() => {
                    verifyMoreIcons();
                  }}
                  sx={{
                    fontSize: 22,
                    color: "black",
                    fill: "black",
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  <img
                    className={"menu__icon__img"}
                    src={`/assets/menuIcons/${
                      moreIcons ? "navigationDown.svg" : "navigationUp.svg"
                    }`}
                    alt={"navigation"}
                  />
                </Icon>
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default MenusOptionsIconsMobile;

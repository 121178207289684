import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateContractedProduct } from "../../../services/admin/adminServices";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import { CreateContact } from "../../../services/user/contactsService";
import { REGEXP } from "../../../consts/regexp";
import { de } from "date-fns/locale";

export const ModalCreateContact = ({
  setOpenModalCreate,
  open,
  setResponseContent,
  setResponseTitle,
  setOpenModalResponse,
  data,
  setData,
  setUnfilteredData,
  setDataStructureArray,
  setTypeOfAction,
  setOpenModalDecision,
  setNoContent,
  noContent,
  country,
  userName,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * UseForm
   */

  const schema = yup.object().shape({
    contactName: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    contactSurname: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    contactPhone: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      )
      .nullable(),
    contactEmail: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
    contactPosition: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString"))
      .nullable(),
    confirmEmail: yup
      .string()
      .test(
        "sameEmail",
        t("App.validationMessages.confirmEmail"),
        function (confirmEmail) {
          return confirmEmail === this.parent.contactEmail;
        }
      )
      .required(t("App.validationMessages.required")),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Sets loading state when creating contact request is loading
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  /**
   * Use Effect
   */

  //When closing the modal, it resets the form
  useEffect(() => {
    switch (open) {
      case false:
        reset();
        break;
    }
  }, [open]);

  /**
   * Handles
   */

  /**
   * Handles the creation of contact
   * @param {*} data  creation contact information
   */
  const handleCreateContact = async (data) => {
    try {
      setIsLoadingCreate(true);

      if (userInfo) {
        setTypeOfAction("create");

        const obj = {
          name: data.contactName,
          lastName: data.contactSurname,
          email: data.contactEmail,
          phone: data.contactPhone ? data.contactPhone : "",
          position: data.contactPosition ? data.contactPosition : "",
          eanCompany: userInfo.company.eanCompany,
          country,
          userName,
        };

        const createContactService = await CreateContact(obj);

        setResponseTitle("");

        if (createContactService.status === CODES.COD_RESPONSE_HTTP_OK) {
          //Si se crea correctamente se agrega a la grilla
          if (
            createContactService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
          ) {
            handleAddContactVisually(obj);
          }

          //ERROR: Correo ya diligenciado
          if (
            createContactService.data.responseCode ===
            CODES.COD_RESPONSE_ERROR_CREATE
          ) {
            //Abro modal decision
            setResponseContent(createContactService.data.responseMessage);
            setOpenModalDecision(true);
          } else {
            //Respuesta de back si es exitoso o no
            setResponseTitle(setTitle(createContactService.data.responseCode));
            setResponseContent(createContactService);
            setOpenModalResponse(true);
            handleCancelOrCloseCreate();
          }
        } else {
          setResponseContent(createContactService);
          setOpenModalResponse(true);
        }
      }

      setIsLoadingCreate(false);
    } catch (error) {
      console.log(
        "============== Error modalCreateContact.jsx function handleCreateContact ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Closes modal create
   */
  const handleCancelOrCloseCreate = () => {
    setOpenModalCreate(false);
  };

  /**
   * Disables create contact button based on required fields state
   * @returns true if button must be disabled, false if not
   */
  const disableCreateContact = () => {
    if (
      watch("contactName") &&
      watch("contactSurname") &&
      watch("contactEmail") &&
      watch("confirmEmail")
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Sets title for modal info based on responseCode value
   * @param {*} responseCode response code of edit request
   * @returns title for modal info
   */
  const setTitle = (responseCode) => {
    let title = "";
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        title = t("App.adminContacts.modals.case0");
        break;
      case CODES.COD_RESPONSE_ERROR_UPDATE:
        title = t("App.adminContacts.modals.case1001");
        break;
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        title = t("App.adminContacts.modals.case1005");
        break;
      default:
        break;
    }
    return title;
  };

  /**
   * Inserts the new contact into the datatable
   * @param {*} contactData current created contact
   */
  const handleAddContactVisually = (contactData) => {
    setData([]);
    const newRow = setDataStructureArray([contactData]);
    let newDataArray = !noContent ? data : [];
    newDataArray.unshift(newRow[0]);

    //Reseteamos los index de los otros contactos
    let finalArray = setDataStructureArray(newDataArray);

    setData(finalArray);
    setUnfilteredData(finalArray);
    setNoContent(false);
  };

  return (
    <Dialog
      maxWidth={"md"}
      onClose={handleCancelOrCloseCreate}
      open={open}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "2rem",
          },
        },
      }}
    >
      <DialogTitleContainer onClose={handleCancelOrCloseCreate}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.adminContacts.modals.create")}
          </Grid>

          <Grid item>
            <Button
              className="btn__outlined__blue"
              disabled
              sx={{ border: "none !important", padding: "0 !important" }}
              startIcon={<PersonOutlineOutlinedIcon />}
            >
              {t("App.adminContacts.modals.info")}
            </Button>
          </Grid>
        </Grid>
      </DialogTitleContainer>

      <DialogContent>
        <Grid container direction="column" sx={{ paddingTop: "1%" }}>
          <form id="hook-contact" onSubmit={handleSubmit(handleCreateContact)}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                rowSpacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.name")}
                      </label>
                    </Grid>

                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactName"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactName
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactName
                                ? errors.contactName.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.name")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.lastname")}
                      </label>
                    </Grid>

                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactSurname"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactSurname
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactSurname
                                ? errors.contactSurname.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.lastname")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.cellPhone")}
                      </label>
                    </Grid>

                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactPhone"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactPhone
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactPhone
                                ? errors.contactPhone.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.cellPhone")}
                            // onChange={onChange}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactPhone",
                                watch("contactPhone")
                                  ? watch("contactPhone")
                                  : null
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                rowSpacing={2}
                style={{ marginTop: "1%" }}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.email")}
                      </label>
                    </Grid>

                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactEmail"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactEmail
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactEmail
                                ? errors.contactEmail.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.email")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.confirmEmail")}
                      </label>
                    </Grid>

                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"confirmEmail"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.confirmEmail
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.confirmEmail
                                ? errors.confirmEmail.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.confirmEmail")}
                            onChange={onChange}
                            onPaste={(e) => e.preventDefault()}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminContacts.modals.position")}
                      </label>
                    </Grid>

                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactPosition"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactPosition
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactPosition
                                ? errors.contactPosition.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.adminContacts.modals.position")}
                            // onChange={onChange}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactPosition",
                                watch("contactPosition")
                                  ? watch("contactPosition")
                                  : null
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid
          container
          className="col-flex"
          columnGap={4}
          rowSpacing={2}
          sx={{ padding: "2.5%" }}
        >
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            lg={1}
            order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
          >
            <Button
              className="btn__filled__gradient-contact"
              onClick={handleCancelOrCloseCreate}
            >
              {t("App.createUser.cancelButton")}
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
          >
            {" "}
            <Button
              type="submit"
              form="hook-contact"
              disabled={disableCreateContact()}
              className={
                disableCreateContact()
                  ? "btn__filled__disabled-contact"
                  : "btn__filled__blue-contact"
              }
              startIcon={
                !isLoadingCreate ? <AddIcon sx={{ fontSize: 16 }} /> : <></>
              }
            >
              {!isLoadingCreate ? (
                t("App.adminContacts.modals.create")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

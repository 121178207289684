import * as yup from "yup";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  ApplyFiltersCards,
  ListIndicatorType,
} from "../../../services/admin/adminServices";

export const FilterAdminIndicatorCards = ({
  setDataStructureArray,
  setIsNoContentFilter,
  unfilteredData,
  setData,
  indicatorTypeFilter,
  setIsNoContent,
  setIsLoading,
  setOpenErrorModal,
}) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 476);
  const [selectedInsightType, setSelectedInsightType] = useState([]);
  const [insightTypeList, setInsightTypeList] = useState([]);
  const [statusList, setStatusList] = useState([
    {
      label: t("App.adminCompanies.filters.active"),
      value: 1,
    },
    {
      label: t("App.adminCompanies.filters.inactive"),
      value: 0,
    },
  ]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Schema to the create product form
   */
  const schema = yup.object().shape({
    status: yup.object().shape({
      label: "",
      value: "",
    }),
  });

  /**
   * useForm to resolver of the schema validation
   */
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      insightType: [],
      status: { label: "", value: "" },
    },
  });

  /**
   * useEffect to fetch the data for the autocomplete of indicator type
   */
  useEffect(() => {
    async function fetchData() {
      try {
        const indicatorsType = await ListIndicatorType();
        // console.log(indicatorsType);

        const { responseMessage, responseCode } = indicatorsType.data;

        if (indicatorsType.status === CODES.COD_RESPONSE_HTTP_OK) {
          if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
            const indicatorType = responseMessage.map((indicator) => ({
              label: indicatorTypeFilter(indicator),
              value: indicator.indicatorType,
            }));

            const indicatorTypelist = [
              { label: t("App.downloadSalesForce.selectAll") },
              ...indicatorType,
            ];

            setInsightTypeList(indicatorTypelist);
          }
        } else {
          setOpenErrorModal(true);
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
  }, []);

  /**
   * Method to change the value of the selectedInsightType state
   */
  const onInsightTypeChange = (e, values, reason, detail) => {
    let includeSelectAll = false;
    for (const commerce of values) {
      if (commerce.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedInsightType(insightTypeList);
      setValue("insightType", insightTypeList);
    } else {
      setSelectedInsightType(values);
      setValue("insightType", values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedInsightType([]);
        setValue("insightType", []);
      }
    }
  };

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect to update the size of the window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * @param {Array} indicatorType
   * @returns the list with the indicator type selected in the autocomplete
   */
  const getDataInsightType = (indicatorType) => {
    let indicatorList;

    if (indicatorType.length < 1) return;

    if (indicatorType === t("App.downloadSalesForce.selectAll")) {
      const indicatorFiltered = indicatorType.filter(
        (indicator) => indicator.label !== t("App.downloadSalesForce.selectAll")
      );

      indicatorList = indicatorFiltered.map((indicator) => indicator.value);
    } else {
      indicatorList = indicatorType.map((indicator) => indicator.value);
    }

    return indicatorList;
  };

  /**
   *
   * @param {Object} data
   *
   * Method to filter the data of the table by product and/or role
   */
  const handleFilters = async (data) => {
    try {
      const obj = {
        lstTypeIndicator: getDataInsightType(data.insightType),
        state: data.status.value,
      };

      setIsLoading(true);
      const filterService = await ApplyFiltersCards(obj);

      const { responseCode, responseMessage } = filterService.data;

      if (filterService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          const formated = setDataStructureArray(responseMessage);

          setData(formated);
          setIsNoContentFilter(false);
          setIsNoContent(false);
        } else {
          setIsNoContentFilter(true);
        }
      } else {
        setIsNoContentFilter(true);

        setOpenErrorModal(true);
      }

      setIsLoading(false);
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Method to clear the filters and reset the data of the table
   */
  const handleCleanFilters = () => {
    setValue("insightType", []);
    setValue("status", { label: "", value: "" });
    setSelectedInsightType([]);
    setData(unfilteredData);
    setIsNoContentFilter(false);
  };

  return (
    <Accordion defaultExpanded={true} expanded={true} disableGutters>
      <Grid item>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.catalogue.filterName")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails>
        <Grid
          container
          justifyContent={isDesktop ? "flex-start" : "center"}
          alignItems="center"
        >
          <Grid md={8} sm={8} item className="side-line">
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} lg={5.5}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.indicatorCards.table.insightType")}
                      </label>
                    </Grid>

                    <Grid item className="full-width">
                      <Controller
                        defaultValue=""
                        name="insightType"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.insightType
                                ? "select__filters__errors"
                                : "select__filters"
                            } full-width`}
                            style={{ height: "auto" }}
                            multiple
                            disableCloseOnSelect
                            limitTags={2}
                            options={insightTypeList}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value + ""}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  placeholder={t(
                                    "App.indicatorCards.table.insightType"
                                  )}
                                />
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              onInsightTypeChange(
                                event,
                                values,
                                reason,
                                detail
                              );
                            }}
                            value={selectedInsightType}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={5.5}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminCompanies.filters.status")}
                      </label>
                    </Grid>

                    <Grid item className="full-width">
                      <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disableClearable
                            options={statusList}
                            getOptionLabel={(option) => option.label}
                            noOptionsText={t("App.listUsers.noOptions")}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            className={
                              errors.country
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            renderInput={({ inputProps, ...rest }) => (
                              <TextField
                                {...rest}
                                placeholder={t(
                                  "App.indicatorCards.table.status"
                                )}
                                inputProps={{
                                  ...inputProps,
                                  readOnly: true,
                                  endAdornment: "",
                                }}
                              />
                            )}
                            onChange={(event, values, reason) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid xs={4} item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              className="confirmation-buttons"
            >
              <Grid item>
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  disabled={
                    watch("status")?.label !== "" ||
                    watch("insightType")?.length > 0
                      ? false
                      : true
                  }
                  className={
                    watch("status")?.label !== "" ||
                    watch("insightType")?.length > 0
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                  type="submit"
                  form="hook-form"
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  className="btn__deleteFilter"
                  onClick={handleCleanFilters}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

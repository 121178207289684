import { Box, Button, Grid, Modal } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useTranslation } from "react-i18next";
import { UnlockUserCognito } from "../../services/auth/authServices";
// { responseData, setOpen, setClose }
const UnlockActiveSession = ({ emailUnlockUser, open, onClose }) => {
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    borderRadius: "12px",
    bgcolor: "white",
    padding: "1rem !important",
    p: 4,
  };

  const handleClose = () => {
    onClose();
  };

  const { t } = useTranslation();

  
  /**
   * Función que permite cerrar sesiones activas del usuario en cognito
   */
  const unlockSession = async () => 
  {
    try 
    {
      const obj = { email: [emailUnlockUser.toLowerCase()] };

      await UnlockUserCognito(obj);
    } 
    catch (error) 
    {
      //TODO: Se debe manejar el error
      console.log(error);
    }
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style} className="modal__responsive">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <WarningAmberOutlinedIcon
                sx={{ color: "#4dcafa", fontSize: "4rem" }}
              />
            </Grid>

            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.validationMessages.error")}
              </h1>
            </Grid>

            <Grid item>
              <p className="label__text-default text-justify">
                {t("App.unlockActiveSession.text")}
              </p>
            </Grid>

            <Grid container item spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button className="btn__filled__gradient" onClick={handleClose}>
                  {t("App.buttonLabels.cancel")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  className="btn__applyFilter"
                  onClick={() => {
                    unlockSession();
                    handleClose();
                  }}
                >
                  {t("App.buttonLabels.continue")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default UnlockActiveSession;

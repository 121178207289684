import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";

/**
 * Componente encargado de mostrar las opciones de menú de nivel 2 y 3 que no tengan opciones hijas
 * Para agregarle opciones al rol Administrador se deben agregar en el archivo sideBar.jsx en la vble optionsLvl1Admin
 * Para agregarle opciones de menú nivel 2 o 3  al rol Usuario se deben agregar desde el modulo de opciones de menú en la plataforma
 *  ------------------------- PROPS ------------------------------------------
 * item: objeto con la información de la opción de menú
 * navigate: función que permite navegar a la ruta seleccionada
 * setOpen: función que permite abrir o cerrar el menú para ver las opciones
 * i18n: objeto con las traducciones
 * idx: índice de la opción
 * fullNameUser: nombre completo del usuario en sesión
 * emailUser: correo del usuario en sesión
 * openQlikSense: función que permite abrir Qlik Sense
 * role: rol del usuario en sesión
 * setShowMenu: función que permite mostrar u ocultar el menú lateral pero solo para móvil
 * setOpenNivel1: función que permite mostrar u ocultar el menú de nivel 1 para móvil
 */
const MenuOption = ({
  item,
  navigate,
  setOpen,
  i18n,
  idx,
  fullNameUser,
  emailUser,
  openQlikSense,
  role,
  setShowMenu = () => {},
  setOpenNivel1 = () => {},
}) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  /**
   * Actualizar si es desktop o no según el tamaño de la ventana
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect to update the state of isDesktop based on the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Función que valida las acciones a realizar al dar clic en una opción de menú
   * @param {object} item objeto con información de la opción de menú seleccionada
   */
  const handleOptionClick = (item) => {
    // Si el rol es admin se navega a la ruta de la opción
    // y ocultar las opciones hijas
    if (role === "admin") {
      navigate(item.navigateTo);
      setOpen(false);
    } else {
      if (
        item.url.includes("localhost") ||
        item.url.includes("innovation-dev-preescriptiva-front") ||
        item.url.includes("dev-prescriptiva-fronted.innovacioncarvajal") ||
        item.url.includes("test-prescriptiva-fronted.innovacioncarvajal") ||
        item.url.includes("prescriptivalatam.com")
      ) {
        let url = new URL(item.url).pathname;
        setOpen(false);

        navigate(url);
      } else {
        // nombre de las opciones de menú para 
        // para obtener la url de session storage
        const nameEnglishOptions = {
          "reporte en línea": "onlineReport",
          "preguntas de negocio": "bussinessQuestion",
        };
        let optionName = item.menuOptionName.toLowerCase();

        // Obtener la url de qlik de la empresa para la opción de menú
        let urlQlik =
          sessionStorage.getItem(nameEnglishOptions[optionName]) ?? "";
        checkRedirectQlik(urlQlik, optionName);
        setOpen(false);
      }
    }
    // Acciones adicional a realizar solo si e móvil
    if (!isDesktop) {
      setShowMenu(false); // ocultar el menú lateral
      setOpenNivel1(true); // restablecer las opciones de menú al nivel 1
    }
  };

  /**
   * Función para determinar redirección de Qlik Sense
   * @param {*} urlQlik url de qlik para la opción de menú
   */
  const checkRedirectQlik = (urlQlik, optionName) => {
    // opciones de menú con url de qlik dinámica
    const optionsDynamicUrlQlik = ["reporte en línea", "preguntas de negocio"];
    // Verificar si la opción de menú es una opción de menú con url qlik dinámica
    // y si la url no está vacia
    if (optionsDynamicUrlQlik.includes(optionName) && urlQlik !== "") {
      openQlikSense(fullNameUser, emailUser, urlQlik);
    } else {
      if (item.url.includes("https://prescriptivalatam.us.qlikcloud.com")) {
        openQlikSense(fullNameUser, emailUser, item.url);
      } else {
        window.open(item.url, "_blank", "noopener,noreferrer");
      }
    }
  };

  return (
    <Grid item width={"100%"}>
      <Button
        key={idx}
        className="sideBar__button__options"
        disableElevation={true}
        disableRipple={true}
        disableFocusRipple={true}
        onClick={() => {
          handleOptionClick(item);
        }}
      >
        {role === "admin"
          ? item.name
          : i18n.language.includes("es")
          ? item.menuOptionName
          : item.nameEnglish}
      </Button>
    </Grid>
  );
};

export default MenuOption;

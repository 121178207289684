import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useTranslation } from "react-i18next";

export const DayOfMonthPickerSpecialFiles = ({
  setSelectedGenerationValue,
  onChange,
  handleClose,
}) => {
  const daysOfMonth = Array.from({ length: 31 }, () => ({
    selected: false,
  }));

  const { t } = useTranslation();
  const value = "female";

  const [dayOfMonthList, setDayOfMonthList] = useState(daysOfMonth);

  const handleChange = (event) => {
    setDayOfMonthList((current) =>
      current.map((obj) => {
        if (obj.selected) {
          return { ...obj, selected: false };
        }

        return obj;
      })
    );

    setDayOfMonthList((current) =>
      current.map((obj, idx) => {
        if (idx + 1 === 31) {
          return { ...obj, selected: true };
        }

        return obj;
      })
    );

    onChange(31);
    setSelectedGenerationValue(31);
    handleClose();
  };
  const setSelected = (selected) => {
    setDayOfMonthList((current) =>
      current.map((obj) => {
        if (obj.selected) {
          return { ...obj, selected: false };
        }

        return obj;
      })
    );

    setDayOfMonthList((current) =>
      current.map((obj, idx) => {
        if (idx === selected) {
          return { ...obj, selected: true };
        }

        return obj;
      })
    );
    onChange(selected + 1);
    setSelectedGenerationValue(selected + 1);
    handleClose();
  };

  // useEffect(() => {
  //   if (props.generationdayDayList) {
  //     setDayOfMonthList((current) =>
  //       current.map((obj, idx) => {
  //         if (idx === props.generationdayDayList - 1) {
  //           return { ...obj, selected: true };
  //         }

  //         return obj;
  //       })
  //     );
  //   }
  // }, []);

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className="calendar__container"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columns={7}
          >
            {dayOfMonthList.map((item, idx) => {
              return (
                <Grid
                  item
                  onClick={() => {
                    setSelected(idx);
                  }}
                  key={idx}
                  xs={1}
                  style={{
                    marginTop: "2rem",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                  className={item.selected ? "grid_day__selected" : "grid_day"}
                >
                  <p
                    className={
                      item.selected
                        ? "dayOfMonth__selected"
                        : "label__primary-one"
                    }
                  >
                    {idx + 1}
                  </p>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <RadioGroup value={value} onChange={handleChange}>
            <FormControlLabel
              value="male"
              control={
                <Radio
                  sx={{
                    color: " #FFA836",
                    "&.Mui-checked": {
                      color: "#FFA836 !important",
                    },
                  }}
                />
              }
              label={
                <p className="label__primary-one">
                  {t("App.dynamicPeriodicReport.when.modalChoose.lastDay")}
                </p>
              }
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import DownloadTemplate from "../../../../components/common/UploadFile/downloadTemplate";
import DownloadGuide from "../../../../components/common/UploadFile/downloadGuide";
import { useTranslation } from "react-i18next";
import UploadFile from "../../../../components/common/UploadFile/uploadFile";
import { useEffect, useState } from "react";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import MUIDataTable from "mui-datatables";

import NoMatchImage from "../../../../components/common/NoMatchImage/noMatchImage";
import FileState from "../../../../components/common/fileState";
import LoopIcon from "@mui/icons-material/Loop";
import { FilterSalesForce } from "../../../../components/common/filterSalesForce";
import { useSelector } from "react-redux";
import { getUser } from "../../../userSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { NoInfoComponent } from "../../../../components/common/NoInfoImage/noInfoComponent";
import ModalInfo from "../../../../components/common/Modals/modalInfo";
import moment from "moment";
import ModalInfoTraders from "../../../../components/common/Modals/modalInfoTraders";
import {
  DownloadGuideMastersTraders,
  DownloadTemplateMastersTraders,
  GetMastersTraders,
  UploadMastersTradersRequest,
} from "../../../../services/user/traders/mastersTradersService";
import { CODES } from "../../../../consts/codes";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import {
  DownloadCatalogErrorLog,
  DownloadCatalogueFile,
} from "../../../../services/user/catalogueService";

export const UploadMastersTraders = ({ currentMaster }) => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.catalogue.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.catalogue.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.catalogue.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "file",
      label: t("App.catalogue.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value.fileName}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) =>
                  handleDownloadFile(value.fileName, value.pathFileName)
                }
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.traders.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "errors",
      label: t("App.catalogue.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              value.awsUploadLog ? (
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) => {
                    setModalTitle(t("App.validationMessages.error"));
                    setModalContent({
                      status: 500,
                      data: {
                        responseMessage: t(
                          "App.validationMessages.systemError2"
                        ),
                      },
                    });
                    setIsOpenModalResponse(true);
                  }}
                >
                  ERROR
                </a>
              ) : (
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadLog(value.logFileName, value.pathLogFileName)
                  }
                >
                  {value.logFileName}
                </a>
              )
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Data de grilla
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  //Filtros de la tabla
  const [filterApply, setFilterApply] = useState(null);

  //Archivo seleccionado
  const [fileSelected, setFileSelected] = useState(null);

  //Carga de tabla o refrescar
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //Modal respuesta carga maestro
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState({
    data: { responseMessage: "" },
  });
  //Si hay maestros cargados o no
  const [isMastersUploaded, setIsMastersUploaded] = useState(false);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Use Effect
   */

  /**
   * Carga los maestros cargados según el maestro elegido
   */
  useEffect(() => {
    if (userInfo && currentMaster !== "2") {
      setFilterApply(null);
      setFilterNotFound(false);
      fetchData();
    }
  }, [userInfo, currentMaster]);

  /**
   * Handles
   */

  /**
   * Trae la información de los maestros cargados según el maestro elegido
   */
  const fetchData = async () => {
    try {
      setData([]);
      setIsLoadingData(true);
      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: getMasterName() === "objectives" ? 23 : 24,
      };
      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await GetMastersTraders(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setIsMastersUploaded(data.length > 0);
        const finalData = setDataStructure(data);
        setUnfilteredData(finalData);
        setData(finalData);
      } else {
        setIsMastersUploaded(false);
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Descarga la plantilla del maestro elegido para los comercios
   * Si falla se muestra modal de error
   */
  const handleDownloadTemplate = async () => {
    try {
      const {
        status,
        data: { responseMessage, responseCode },
      } = await DownloadTemplateMastersTraders({ master: getMasterName() });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(responseMessage.fileContent),
          responseMessage.fileName
        );
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Descarga la guía del maestro elegido para comercios
   * Si falla se muestra modal de error
   */
  const handleDownloadGuide = async () => {
    try {
      const {
        status,
        data: { responseMessage, responseCode },
      } = await DownloadGuideMastersTraders({ master: getMasterName() });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(responseMessage.fileContent),
          responseMessage.fileName
        );
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo de catálogo que fue cargado (subido)
   *
   * @param {String} fileName nombre del archivo
   * @param {String} pathFileName path del archivo
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    try {
      const obj = {
        pathFileName: pathFileName,
        fileName: fileName,
      };

      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await DownloadCatalogueFile(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        fileDownload(decode(data.fileContent), data.fileName);
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo con los errores del archivo cargado (subido)
   *
   * @param {String} logFileName nombre del archivo log
   * @param {String} pathLogFileName path del archivo
   */
  const handleDownloadLog = async (logFileName, pathLogFileName) => {
    try {
      const obj = {
        pathLogFileName: pathLogFileName,
        logFileName: logFileName,
      };
      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await DownloadCatalogErrorLog(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        fileDownload(decode(data.fileContent), data.fileName);
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función que carga el maestro elegido a la plataforma
   */
  const handleUploadFile = async () => {
    try {
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        emailLoad: userInfo.email,
        userLoad: `${userInfo.firstName} ${userInfo.lastName}`,
        fileUpload: fileSelected[0],
        country: userInfo.company.country,
        provider: userInfo.company.companyName,
        file: fileSelected[0],
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await UploadMastersTradersRequest({
        master: getMasterName(),
        ...obj,
      });

      setFileSelected(null);
      setModalTitle(handleSetTitle(responseCode));
      setModalContent({
        status: status,
        data: {
          responseMessage,
          responseCode,
        },
      });
      setIsOpenModalResponse(true);
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        await fetchData();
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Refresca la tabla de cargas del maestro seleccionado
   */
  const handleRefresh = async () => {
    try {
      const previousData = unfilteredData;
      setData([]);
      setIsLoadingRefresh(true);
      setIsLoadingData(true);
      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: getMasterName() === "objectives" ? 23 : 24,
      };
      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await GetMastersTraders(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const finalData = setDataStructure(data);
        setUnfilteredData(finalData);
        setData(finalData);
        setIsMastersUploaded(data.length > 0);
      } else {
        setUnfilteredData(previousData);
        setData(previousData);
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
      setIsLoadingData(false);
      setIsLoadingRefresh(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Retorna el título correspondiente del modal según el responseCode
   * @param {*} responseCode código de respuesta
   * @returns título del modal
   */
  const handleSetTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS:
        return t("App.traders.masters.title");
      default:
        return t("App.validationMessages.error");
    }
  };

  /**
   * Aplica la estructura de tabla a los datos entrantes
   * @param {*} lstMasters lista de maestros cargados
   * @returns lista de de maestros formateada
   */
  const setDataStructure = (lstMasters) => {
    return lstMasters.map((master, i) => {
      return {
        startDate: !master.starDate ? "  " : master.starDate,
        endDate: !master.endDate ? "  " : master.endDate,
        user: !master.userLoad ? "" : master.userLoad,
        file: !master.fileName
          ? " "
          : {
              fileName: master.fileName,
              pathFileName: master.pathFileName,
            },
        state: !master.state ? " " : master.state,
        errors: master.awsUploadLog
          ? {
              awsUploadLog: master.awsUploadLog,
            }
          : {
              logFileName: master.logFileName,
              pathLogFileName: master.pathLogFileName,
            },
      };
    });
  };

  /**
   * Encuentra el nombre del maestro elegido por medio del id almacenado en currentMaster
   * @returns nombre del maestro seleccionado
   */
  const getMasterName = () => {
    return currentMaster === "1"
      ? "penalties"
      : currentMaster === "0"
      ? "objectives"
      : "none";
  };

  return (
    <Grid container direction="column" rowSpacing={2} className="full-width">
      <Grid item className="full-width">
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnSpacing={2}
          rowSpacing={2}
          textAlign={"center"}
        >
          <DownloadTemplate
            helpText={t("App.traders.masters.templateText")}
            buttonText={t("App.catalogue.templateDownload")}
            handleDownloadTemplate={handleDownloadTemplate}
          />
        </Grid>
      </Grid>
      <Grid item className="full-width">
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <DownloadGuide
            helpText={"App.salesForce.guide"}
            handleDownloadGuide={handleDownloadGuide}
          />
        </Grid>
      </Grid>
      <Grid item className="full-width">
        <UploadFile
          dragText={t("App.mastersUpload.dragging")}
          handleUploadFile={handleUploadFile}
          fileSelected={fileSelected}
          setFileSelected={setFileSelected}
          setErrorModal={setIsOpenErrorModal}
          setErrorTitle={setErrorTitle}
        />
      </Grid>
      {isMastersUploaded && (
        <Grid item className="full-width">
          <Grid
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            columnSpacing={2}
            rowSpacing={2}
            textAlign={"center"}
          >
            <Grid
              lg={"auto"}
              sm={"auto"}
              xs={12}
              item
              className="download-explanation"
            >
              {t("App.rateMaster.refresh")}
            </Grid>
            <Grid lg={"auto"} sm={"auto"} xs={12} item>
              <Button
                disableRipple
                disabled={isLoadingRefresh ? true : false}
                startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                className="btn__outlined__blue full-width"
                onClick={handleRefresh}
              >
                {!isLoadingRefresh ? (
                  t("App.catalogue.update")
                ) : (
                  <CircularProgress size={23} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            {" "}
            <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
          </Grid>
          <Grid item className="display-small__primary-one">
            {" "}
            {t("App.salesForce.subtitle")}
          </Grid>
        </Grid>
      </Grid>
      {isMastersUploaded && (
        <Grid item className="full-width">
          <Accordion className="accordion__filter" style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.catalogue.filterName")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterSalesForce
                userInfo={userInfo}
                unfilteredData={unfilteredData}
                setData={setData}
                setFilterApply={setFilterApply}
                idFile={getMasterName() === "objectives" ? 23 : 24}
                setIsLoadingData={setIsLoadingData}
                setFilterNotFound={setFilterNotFound}
                state={true}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      <Grid item className="chips-container full-width">
        {filterApply && isMastersUploaded && (
          <Chip className="chip__filter" label={filterApply.label} />
        )}
      </Grid>
      <Grid item className="full-width">
        {isMastersUploaded ? (
          isLoadingData ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce"
            />
          ) : !filterNotFound ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={options}
              className="dataTable__salesForce"
            />
          ) : (
            <NoMatchImage />
          )
        ) : (
          <NoInfoComponent type="load" />
        )}
      </Grid>
      <ModalInfoTraders
        title={modalTitle}
        responseData={modalContent}
        open={isOpenModalResponse}
        onClose={() => {
          setIsOpenModalResponse(false);
        }}
        currentMaster={
          currentMaster === "1"
            ? t("App.traders.masters.penalties")
            : t("App.traders.masters.objectives")
        }
      />
      <ModalInfoTraders
        title={errorTitle}
        responseData={{ status: 0 }}
        open={isOpenErrorModal}
        onClose={() => {
          setIsOpenErrorModal(false);
        }}
        currentMaster={
          currentMaster === "1"
            ? t("App.traders.masters.penalties")
            : t("App.traders.masters.objectives")
        }
      />
    </Grid>
  );
};

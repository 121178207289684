import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../../pages/userSlice";
import React, { useEffect, useState } from "react";
import { CODES } from "../../../consts/codes";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ModalDecision } from "../Modals/modalDecision";

export const TypeSelectorReportEdit = ({
  reportType,
  setReportType,
  control,
  handleTypeChange,
  typeOfDocument,
  errors,
  setValue,
  setStartForm,
  setDesiredType,
  componentName,
}) => {
  /**
   * Const
   */

  //Checkbox
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const typeOption = 1;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  /**
   * Use State
   */

  //Lista de compañías
  const [companyList, setCompanyList] = useState([]);

  //Grupo empresarial
  const [isBusinessGroup, setIsBusinessGroup] = useState(false);
  const [isCompanySelected, setIsCompanySelected] = useState(true);

  //Cargas
  const [isLoading, setIsLoading] = useState(false);

  //Modal de cambio de empresas
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [newSelectedCompanies, setNewSelectedCompanies] = useState([]);

  /**
   * Handles
   */

  /**
   * Realiza acciones sobre el formulario basado en la selección de empresas
   * @param {array} values empresas seleccionadas
   */
  const handleCompanyChange = (values) => {
    if (values.length > 0) {
      setIsCompanySelected(true);
      setNewSelectedCompanies(values);
      setIsOpenModalDecision(true);
    } else {
      setIsCompanySelected(false);
      setDesiredType(null);
      setValue(typeOfDocument, typeOfDocument === "type" ? 0 : "0");
      setReportType(typeOfDocument === "type" ? 0 : "0");
      setStartForm(false);
    }
  };

  /**
   * Maneja el caso en se acepte la condición del modal decision
   */
  const handleAgree = () => {
    setValue("lstCompanies", newSelectedCompanies);
    setIsOpenModalDecision(false);
  };

  /**
   * Maneja el caso en que no se esté de acuerdo con la condición del modal decision
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="grid__container__filled__rounded"
      columnSpacing={2}
    >
      <Grid item>
        <Controller
          name="lstCompanies"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={isLoading ? true : isBusinessGroup ? false : true}
              noOptionsText={t("App.listUsers.noOptions")}
              disableCloseOnSelect
              className={
                errors.lstCompanies
                  ? "select__filters__errors__medium"
                  : "select__filters__medium"
              }
              style={{ height: "auto" }}
              options={companyList}
              multiple
              limitTag={1}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <Checkbox
                    icon={icon}
                    className="autoComplete__checkbox"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder={t("App.listUsers.enterprise")}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? (
                            <CircularProgress
                              sx={{ color: "#4DCAFA" }}
                              size={15}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const limitTags = 1;

                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => (
                      <Chip
                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                        sx={{
                          width: "55%",
                        }}
                        key={index}
                        size="small"
                        label={`${option.label}`}
                      />
                    ))}
                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              onChange={(event, values, reason, detail) => {
                handleCompanyChange(values);
              }}
              value={value}
            />
          )}
          control={control}
        />
      </Grid>

      <Grid item>
        <p className="heading__text-default">
          {componentName === "specialFile"
            ? t("App.specialFilesClient.choose")
            : t("App.dynamicPeriodicReport.choose")}
        </p>
      </Grid>

      <Grid item className="u-marginL">
        <Controller
          name={typeOfDocument}
          defaultValue={typeOfDocument === "type" ? 0 : "0"}
          render={({ field: { onChange, value } }) => (
            <Select
              disabled={isCompanySelected ? false : true}
              value={reportType}
              onChange={(e) => {
                onChange(e);
                handleTypeChange(e);
              }}
              className={
                errors.type
                  ? "modalCreateUsers__form-input--error"
                  : "modalCreateUsers__form-input"
              }
              id={typeOfDocument}
              name={typeOfDocument}
              labelId={typeOfDocument}
            >
              <MenuItem disabled value="0">
                <em> {t("App.dynamicPeriodicReport.type.placeholder")}</em>
              </MenuItem>
              <MenuItem value="1">
                <em> {t("App.dynamicPeriodicReport.type.inventory")}</em>
              </MenuItem>
              <MenuItem value="2">
                <em> {t("App.dynamicPeriodicReport.type.sales")}</em>
              </MenuItem>
            </Select>
          )}
          control={control}
        />
      </Grid>
      <ModalDecision
        title={t("App.manageBusinessGroup.reports.modalTitle")}
        message={t("App.manageBusinessGroup.reports.modalContent")}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </Grid>
  );
};

import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../Modals/modalInfo";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { getUser } from "../../../pages/userSlice";
import { useSelector } from "react-redux";
import { EditBusinessRule as Edit } from "../../../services/user/buesinessRules";
import { FilterBusinessRules } from "../../../services/user/buesinessRules";
import { ModalDecision } from "../Modals/modalDecision";

const EditBusinessRule = ({
  rule,
  updateDatatableVisually,
  setData,
  dataArray,
  setDataStructure,
  setIsLoadingData,
  setFilterApply,
  setNoFilter,
}) => {
  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  /**
   * Use State
   */

  const [open, setOpen] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editData, setEditData] = useState(false);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Form
   */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({});

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * Use effect
   */

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    // function setValues() {
    setValue("ruleDescription", rule.businnessRuleName);
    setValue("ruleCode", rule.idBusinessRule);
    // }

    // setValues();
  }, [rule]);

  /**
   * Handles
   */

  const handleOpen = () => {
    // function setValues() {
    //   setValue("ruleDescription", rule.businnessRuleName);
    //   setValue("ruleCode", rule.idBusinessRule);
    // }

    // setValues();

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [agree, setAgree] = useState(false);
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [obj, setObj] = useState({});

  const handleAgree = () => {
    async function editService() {
      setLoadingEdit(true);

      const editService = await Edit(obj);

      setLoadingEdit(false);

      if (
        editService.status !== CODES.COD_RESPONSE_HTTP_OK ||
        editService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setError(true);
        setEditData(editService);
        return;
      }

      setSuccess(true);
      setEditData(editService);
      handleFilters();
    }

    editService();
    setOpenModalDecision(false);
  };

  const handleDisagree = () => {
    setAgree(false);
    setOpenModalDecision(false);
  };

  const handleEditBusinessRule = async (data) => {

    try {
      setOpenModalDecision(true);

      const obj = {
        country: rule.country,
        eanTrader: rule.eanTrader,
        idBusinessRule: data.ruleCode.toString(),
        businnessRuleName: data.ruleDescription,
        modificationUser: fullNameUser,
      };

      setObj(obj);

    } catch (error) {
      console.log(error);
    }
  };

  const getValuesDataArray = () => {
    const countries = dataArray.map((item) => item.country);
    const eanTraders = dataArray.map((item) => item.eanTrader);
    const nameTraders = dataArray.map((item) => item.traderName);
    const rules = dataArray.map((item) => item.idBusinessRule);

    return { country: countries[0], eanTraders, nameTraders, rules };
  };

  const handleFilters = async () => {
    try {
      setIsLoadingData(true);

      const { country, eanTraders, nameTraders, rules } = getValuesDataArray();

      const obj = {
        country,
        eanTraders,
        nameTraders,
        rules,
      };


      const filterService = await FilterBusinessRules(obj);

      if (
        filterService.status !== CODES.COD_RESPONSE_HTTP_OK ||
        filterService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      )
        return;

      setDataStructure(filterService.data.responseMessage);

      setNoFilter(false);
      setIsLoadingData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changeRuleByCode = (e) => {
    if (e.target !== undefined) {
      const element = e.target;

      const value = Number(element.getAttribute("data-value"));

      if (value === 1) {
        setValue("ruleDescription", "Sumar - Remplazar");
      } else if (value === 2) {
        setValue("ruleDescription", "Remplazar - Sumar");
      } else if (value === 3) {
        setValue("ruleDescription", "Remplazar - Remplazar");
      } else {
        setValue("ruleDescription", "Sumar - Sumar");
      }
    }
  };

  const changeCodeByRule = (e) => {
    if (e.target !== undefined) {
      const element = e.target;

      const value = element.getAttribute("data-value");

      if (value === "Sumar - Remplazar") {
        setValue("ruleCode", 1);
      } else if (value === "Remplazar - Sumar") {
        setValue("ruleCode", 2);
      } else if (value === "Remplazar - Remplazar") {
        setValue("ruleCode", 3);
      } else {
        setValue("ruleCode", 4);
      }
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal-box-createEditCompany">
          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton component="span" onClick={handleClose}>
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.businessRules.editForm.title")}
              </h1>
            </Grid>

            <Grid item className="full-width">
              <form onSubmit={handleSubmit(handleEditBusinessRule)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.businessRules.editForm.subTitle", {
                        name: rule.traderName,
                      })}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.businessRules.editForm.ruleDescription")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="ruleDescription"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              onClick={changeCodeByRule}
                              value={value}
                              className={`${
                                errors.ruleDescription
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t(
                                  "App.businessRules.editForm.ruleDescription"
                                )}
                              </MenuItem>
                              <MenuItem value="Sumar - Remplazar">
                                {t("App.businessRules.add")} -{" "}
                                {t("App.businessRules.replace")}
                              </MenuItem>
                              <MenuItem value="Remplazar - Sumar">
                                {t("App.businessRules.replace")} -{" "}
                                {t("App.businessRules.add")}
                              </MenuItem>
                              <MenuItem value="Remplazar - Remplazar">
                                {t("App.businessRules.replace")} -{" "}
                                {t("App.businessRules.replace")}
                              </MenuItem>
                              <MenuItem value="Sumar - Sumar">
                                {t("App.businessRules.add")} -{" "}
                                {t("App.businessRules.add")}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.businessRules.table.ruleCode")}*
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="ruleCode"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              onClick={changeRuleByCode}
                              value={value}
                              className={`${
                                errors.ruleCode
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.businessRules.table.ruleCode")}
                              </MenuItem>
                              <MenuItem value={1}>{1}</MenuItem>
                              <MenuItem value={2}>{2}</MenuItem>
                              <MenuItem value={3}>{3}</MenuItem>
                              <MenuItem value={4}>{4}</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  direction={isDesktop ? "row" : "column-reverse"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} lg={1} className="full-width">
                    <Button
                      className="btn__filled__gradient full-width"
                      onClick={handleClose}
                    >
                      {t("App.buttonLabels.cancel")}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4} lg={2.2} className="full-width">
                    <Button
                      disabled={
                        watch("ruleDescription") && watch("ruleCode")
                          ? false
                          : true
                      }
                      className={`${
                        watch("ruleDescription") && watch("ruleCode")
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width`}
                      type="submit"
                      startIcon={!loadingEdit ? <CheckIcon /> : <></>}
                    >
                      {!loadingEdit ? (
                        t("App.buttonLabels.confirmChange")
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ModalDecision
        title={t("App.businessRules.modalDecision.title", {
          nameCompany: rule.traderName,
        })}
        message={t("App.businessRules.modalDecision.message")}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setOpenModalDecision}
        isOpen={openModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />

      {error && (
        <ModalInfo
          title={""}
          responseData={editData}
          open={open}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpen(false);
          }}
        />
      )}

      {success && (
        <ModalInfo
          responseData={editData}
          open={open}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default EditBusinessRule;

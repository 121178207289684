import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { FilterAdminContacts } from "../../../components/common/Filters/filterAdminContacts";
import { useTranslation } from "react-i18next";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import MUIDataTable from "mui-datatables";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import { ModalCreateContact } from "../../../components/common/Modals/modalCreateContact";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import { ModalEditContact } from "../../../components/common/Modals/modalEditContact";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  DeleteContact,
  FilterContacts,
} from "../../../services/user/contactsService";
import { CODES } from "../../../consts/codes";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, getUser, getUserStatus } from "../../userSlice";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import ModalCreateContactBG from "../../../components/common/Modals/modalCreateContactBG";
import ModalEditContactBG from "../../../components/common/Modals/modalEditContactBG";
import ModalFailService from "../../../components/common/modalFailService";

const AdminContacts = () => {
  /**
   * UseTranslation
   */

  const { t } = useTranslation();

  /**
   * Table
   */

  const columns = [
    {
      name: "name",
      label: t("App.userDetail.name"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "lastName",
      label: t("App.userDetail.lastName"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "email",
      label: t("App.userDetail.email"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "phone",
      label: t("App.userDetail.phone"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "position",
      label: t("App.adminContacts.modals.position"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "edit",
      label: t("App.userDetail.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "delete",
      label: t("App.listRoles.delete"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  // Estructura de la tabla si el usuario pertenece a un grupo empresarial
  const columnsBusinessGroup = [
    {
      name: "name",
      label: t("App.userDetail.name"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "lastName",
      label: t("App.userDetail.lastName"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "email",
      label: t("App.userDetail.email"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "phone",
      label: t("App.userDetail.phone"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "position",
      label: t("App.adminContacts.modals.position"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "companyName",
      label: t("App.adminContacts.modals.company"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "edit",
      label: t("App.userDetail.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "delete",
      label: t("App.listRoles.delete"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.adminContacts.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Skeleton
   */

  const skeletonLine = [
    {
      name: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      lastName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      email: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      phone: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      position: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      delete: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  const skeletonLineBusinessGroup = [
    {
      name: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      lastName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      email: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      phone: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      position: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      company: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      delete: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  const userName = userInfo.firstName + " " + userInfo.lastName;
  const country = userInfo.company?.country;
  const companyName = userInfo.company?.companyName;

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use State
   */

  //No Content element
  const [noContent, setNoContent] = useState(false);
  const [hasResults, setHasResults] = useState(false);

  // Usuario pertenece a un grupo empresarial
  const [isBusinessGroup, setIsBusinessGroup] = useState(false);

  // Lista de empresas
  const [companyList, setCompanyList] = useState([]);

  // Estado para datos de tabla y chips de filtro
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterApply, setFilterApply] = useState({
    contactName: "",
    contactSurname: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  // modal error
  const [openErrorModal, setOpenErrorModal] = useState(false);

  //Estado para modales de creación y edición
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  // Estado para modales de decisión como correo electrónico existente y eliminar
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [openModalDecisionDelete, setOpenModalDecisionDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  // Conjunto de acciones para acciones de modales de decisión (create/edit)
  const [typeOfAction, setTypeOfAction] = useState("create");

  // Estado para el contacto actual a editar / eliminar
  const [currentContact, setCurrentContact] = useState({
    name: "",
    lastName: "",
    email: "",
    companyName: "",
    pk: "",
  });

  // Estado para la respuesta del modal de información, título y contenido
  const [openModalResponse, setOpenModalResponse] = useState(false);
  const [responseTitle, setResponseTitle] = useState("");
  const [responseContent, setResponseContent] = useState({});

  /**
   * Use Effect
   */

  //Obtiene información del usuario si no hay información en userInfo
  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  /**
   *  Obtener si la cuenta del usuario pertenece a
   * un grupo empresarial
   */
  useEffect(() => {
    try {
      if (userInfo) {
        isBusiness();
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log(
        "==============Error AdminContact.jsx  ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, [dispatch, userInfo]);

  /**
   * Obtener si la cuenta del usuario pertenece a un grupo empresarial
   */
  const isBusiness = async () => {
    try {
      const businessService = await ListBusinessGroup({
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        email: userInfo.email,
        typeOption: 1,
      });
      if (
        businessService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        getListBusinessGroup();
        setIsBusinessGroup(true);
      }
      setDataToTable();
    } catch (error) {
      setOpenErrorModal(true);

      console.log("-----------------error------------------");
      console.log(error);
      console.log("------------------------------------");
    }
  };

  /**
   * Obtener lista de empresas grupo empresarial
   */
  const getListBusinessGroup = async () => {
    try {
      const listService = await ListBusinessGroup({
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        email: userInfo.email,
        typeOption: 1,
      });
      if (
        listService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let list = listService.data.objectData;
        const companies =
          list.length > 1
            ? [
                { renameCompany: t("App.downloadSalesForce.selectAll") },
                ...list,
              ]
            : list;
        setCompanyList(companies);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("-----------------error------------------");
      console.log(error);
      console.log("------------------------------------");
    }
  };
  //Establece datos en la tabla de datos
  const setDataToTable = () => {
    setData([]);
    setData(isBusinessGroup ? skeletonLineBusinessGroup : skeletonLine);
    fetchData();
  };

  /**
   * Handles
   */

  /**
   * Obtiene información de datos de contactos y los formatea para la tabla de datos
   * Si no hay datos muestra el componente NoContent
   * @param {*} info datos de contactos a mostrar
   */
  const fetchData = async () => {
    const filterContactsService = await FilterContacts({
      eanCompany: userInfo.company.eanCompany,
      country,
      email: userInfo.email,
    });
    if (filterContactsService.status === CODES.COD_RESPONSE_HTTP_OK) {
      const hasContacts =
        filterContactsService.data.responseMessage.length > 0 ? true : false;

      switch (hasContacts) {
        case true:
          const proccessedRows = setDataStructureArray(
            filterContactsService.data.responseMessage
          );
          setData(proccessedRows);
          setUnfilteredData(proccessedRows);
          break;
        case false:
          setNoContent(true);
          break;
        default:
          setNoContent(true);
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  /**
   * Establecer estructura de datos para la tabla de datos
   * @param {*} data lista de contactos
   * @returns datos formateados para la tabla de datos
   */
  const setDataStructureArray = (data) => {
    const formattedRows = data.map((contact, index) => {
      return {
        ...contact,
        currentIndex: index,
        edit: (
          <IconButton
            onClick={() => {
              handleOpenEdit(contact, index);
            }}
            component="span"
          >
            <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
          </IconButton>
        ),
        delete: (
          <IconButton
            onClick={() => {
              handleOpenDelete(contact);
            }}
            component="span"
          >
            <DeleteOutlineOutlinedIcon
              sx={{ fontSize: 22, color: "#543ab4" }}
            />
          </IconButton>
        ),
      };
    });
    return formattedRows;
  };

  /**
   * Establece el contacto actual para editar y abre el modo de edición
   * @param {*} contact contacto a editar
   * @param {*} index posición del contacto en la tabla de datos
   */
  const handleOpenEdit = (contact, index) => {
    setCurrentContact({ ...contact, currentIndex: index });
    setOpenModalEdit(true);
  };

  /**
   * Sets current contact to delete and opens the delete modal
   * @param {*} contact contact to delete
   */
  const handleOpenDelete = (contact) => {
    setCurrentContact({ ...contact });
    setOpenModalDecisionDelete(true);
  };

  /**
   *
   * Manejar la acción segun el tipo de acción
   */
  const handleAgreeModalDecision = () => {
    switch (typeOfAction) {
      case "create":
        setOpenModalDecision(false);
        setOpenModalCreate(false);
        break;
      case "edit":
        setOpenModalDecision(false);
        setOpenModalEdit(false);
        break;
    }
  };

  /**
   * Manejo desacuerdo sobre correo electrónico del modal
   */
  const handleDisagreeModalDecision = () => {
    setOpenModalDecision(false);
  };

  /**
   * Manejo de cierre de modal de respuesta
   */
  const handleCloseModalResponse = () => {
    switch (typeOfAction) {
      case "create":
        setOpenModalCreate(false);
        break;
      case "edit":
        setOpenModalEdit(false);
        break;
    }

    setOpenModalResponse(false);
    setResponseTitle("");
    setResponseContent({});
  };

  /**
   * Manejo decisión modal eliminar y eliminar el contacto actual
   */
  const handleAgreeModalDecisionDelete = async () => {
    setIsLoadingDelete(true);
    const deleteContactService = await DeleteContact(currentContact);

    if (deleteContactService.status === CODES.COD_RESPONSE_HTTP_OK) {
      const codeResponse = deleteContactService.data.responseCode;
      switch (codeResponse) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          setResponseTitle(t("App.adminContacts.modals.deleteSuccess"));
          break;
        case CODES.COD_RESPONSE_ERROR_DELETE:
          setResponseTitle(t("App.adminContacts.modals.case1003"));
          break;
        default:
          setResponseTitle(t("App.adminContacts.modals.case1005"));
          break;
      }

      setResponseContent(deleteContactService);
      setOpenModalResponse(true);
    }
    setIsLoadingDelete(false);
    setOpenModalDecisionDelete(false);
    await fetchData();
  };

  /**
   *  Manejo desacuerdo modal eliminar
   */
  const handleDisagreeModalDecisionDelete = () => {
    setOpenModalDecisionDelete(false);
  };

  return (
    <Grid container direction="column" gap={3}>
      {!isBusinessGroup ? (
        <>
          <ModalCreateContact
            data={data}
            setData={setData}
            open={openModalCreate}
            setOpenModalCreate={setOpenModalCreate}
            setOpenModalResponse={setOpenModalResponse}
            setResponseTitle={setResponseTitle}
            setResponseContent={setResponseContent}
            setDataStructureArray={setDataStructureArray}
            setTypeOfAction={setTypeOfAction}
            setOpenModalDecision={setOpenModalDecision}
            setNoContent={setNoContent}
            noContent={noContent}
            setUnfilteredData={setUnfilteredData}
            userName={userName}
            country={country}
          />
          <ModalEditContact
            contact={currentContact}
            data={data}
            setData={setData}
            open={openModalEdit}
            setOpenModalEdit={setOpenModalEdit}
            setOpenModalResponse={setOpenModalResponse}
            setResponseTitle={setResponseTitle}
            setResponseContent={setResponseContent}
            setDataStructureArray={setDataStructureArray}
            setTypeOfAction={setTypeOfAction}
            setOpenModalDecision={setOpenModalDecision}
            setUnfilteredData={setUnfilteredData}
            userName={userName}
            country={country}
            companyName={companyName}
          />
        </>
      ) : (
        <>
          {!isLoading && (
            <>
              <ModalCreateContactBG
                data={data}
                setData={setData}
                open={openModalCreate}
                setOpenModalCreate={setOpenModalCreate}
                setOpenModalResponse={setOpenModalResponse}
                setResponseTitle={setResponseTitle}
                setResponseContent={setResponseContent}
                setDataStructureArray={setDataStructureArray}
                setTypeOfAction={setTypeOfAction}
                setOpenModalDecision={setOpenModalDecision}
                setNoContent={setNoContent}
                noContent={noContent}
                setUnfilteredData={setUnfilteredData}
                userName={userName}
                country={country}
                companyList={companyList.filter(
                  (company) =>
                    company.renameCompany !==
                    t("App.downloadSalesForce.selectAll")
                )}
              />
              <ModalEditContactBG
                contact={currentContact}
                data={data}
                setData={setData}
                open={openModalEdit}
                setOpenModalEdit={setOpenModalEdit}
                setOpenModalResponse={setOpenModalResponse}
                setResponseTitle={setResponseTitle}
                setResponseContent={setResponseContent}
                setDataStructureArray={setDataStructureArray}
                setTypeOfAction={setTypeOfAction}
                setOpenModalDecision={setOpenModalDecision}
                setUnfilteredData={setUnfilteredData}
                userName={userName}
                country={country}
                companyList={companyList.filter(
                  (company) =>
                    company.renameCompany !==
                    t("App.downloadSalesForce.selectAll")
                )}
              />
            </>
          )}
        </>
      )}

      <ModalInfo
        title={responseTitle}
        responseData={responseContent}
        open={openModalResponse}
        onClose={handleCloseModalResponse}
      />

      <ModalDecision
        isOpen={openModalDecision}
        setIsOpen={setOpenModalDecision}
        handleAgree={handleAgreeModalDecision}
        handleDisagree={handleDisagreeModalDecision}
        title={t("App.adminContacts.modals.case1001")}
        message={responseContent}
        agreeText={t("App.buttonLabels.accept")}
        disagreeText={t("App.buttonLabels.cancel")}
      />

      <ModalDecision
        isOpen={openModalDecisionDelete}
        setIsOpen={setOpenModalDecisionDelete}
        handleAgree={handleAgreeModalDecisionDelete}
        handleDisagree={handleDisagreeModalDecisionDelete}
        title={t("App.adminContacts.modals.deleteTitle")}
        message={t("App.adminContacts.modals.delete", { ...currentContact })}
        agreeText={
          !isLoadingDelete ? (
            t("App.buttonLabels.accept")
          ) : (
            <CircularProgress size={21} color="inherit" />
          )
        }
        disagreeText={t("App.buttonLabels.cancel")}
      />

      <Grid item>
        <Grid
          container
          rowSpacing={3}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item className="col-flex">
            <p className="display-large__primary-one">
              {t("App.adminContacts.title")}
            </p>
          </Grid>

          <Grid item className="col-flex">
            {!isLoading ? (
              <Button
                startIcon={<AddIcon sx={{ fontSize: 18 }} />}
                className="btn__filled__blue"
                onClick={() => {
                  setOpenModalCreate(true);
                }}
              >
                {t("App.adminContacts.modals.create")}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item className="companies_info_container full-width">
        {isLoading ? (
          <CustomSkeleton />
        ) : (
          <Grid container direction="column" gap={2}>
            <Grid item className="filter-container">
              <FilterAdminContacts
                setData={setData}
                setFilterApply={setFilterApply}
                unfilteredData={unfilteredData}
                userInfo={userInfo}
                setDataStructureArray={setDataStructureArray}
                skeletonLine={
                  isBusinessGroup ? skeletonLineBusinessGroup : skeletonLine
                }
                setHasResults={setHasResults}
                isBusinessGroup={isBusinessGroup}
                isLoading={isLoading}
                companyList={companyList}
                setOpenModalResponse={setOpenModalResponse}
                setResponseContent={setResponseContent}
                fetchData={fetchData}
              />
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {filterApply.contactName && !hasResults && (
                  <Grid item>
                    <Chip
                      label={`${t("App.userDetail.name")}: ${
                        filterApply.contactName
                      } `}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {filterApply.contactSurname && !hasResults && (
                  <Grid item>
                    <Chip
                      label={`${t("App.userDetail.lastName")}: ${
                        filterApply.contactSurname
                      } `}
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item sx={{ width: "100%" }}>
              {!noContent ? (
                !hasResults ? (
                  <MUIDataTable
                    className="dataTable__salesForce"
                    data={data}
                    columns={isBusinessGroup ? columnsBusinessGroup : columns}
                    options={options}
                  />
                ) : (
                  <NoMatchImage />
                )
              ) : (
                <NoInfoComponent type="contact" />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* Modal para fallo de servicios */}
      <ModalFailService open={openErrorModal} onClose={setOpenErrorModal} />
    </Grid>
  );
};

export default AdminContacts;

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import { CODES } from "../../../consts/codes";
import { getUser } from "../../../pages/userSlice";
import ModalInfo from "../Modals/modalInfo";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterDelete = (props) => {
  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Esquema de validación para el formulario de filtros
   */
  const schema = yup.object().shape({
    documentType: yup.string().required(),
    country: yup
      .object()
      .shape({
        country: yup.string().required(),
        countryName: yup.string().required(),
      })
      .required(),
    commerce: yup
      .array()
      .of(
        yup.object().shape({
          nameCompany: yup.string().required(),
          eanCompany: yup.string(),
        })
      )
      .min(1)
      .required(),

    supplier: yup
      .array()
      .of(
        yup.object().shape({
          nameCompany: yup.string().required(),
          eanCompany: yup.string(),
        })
      )
      .min(1)
      .required(),
    dateType: yup.string().required(),
    initialDate: yup.string().required(),
    finalDate: yup.string().required(),
  });

  /**
   * useForm to resolver schema validation
   */
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dateType: "0",
      finalDate: null,
      initialDate: null,
      documentType: "0",
      country: {
        country: "",
        countryName: "",
      },
    },
  });

  /**
   * Use State
   */
  const [dateType, setDateType] = useState("");
  const [country, setCountry] = useState("");
  const [commercesList, setCommercesList] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [selectedCommerces, setSelectedCommerces] = useState([]);

  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [error, setError] = useState(false);
  const [filterResponse, setFilterResponse] = useState({});

  /**
   * Use Selector
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  const userInfo = useSelector(getUser);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * useEffect para obtener los países
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "==============Error Get Geographies======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
  }, [dispatch, countriesStatus]);

  /**
   * useEffect para recargar los datos si se acepta la confirmación
   */
  useEffect(() => {
    const reloadData = async () => {
      if (filterData.documentType !== "") {
        props.setData([]);
        const obj = {
          typeDocument: filterData.documentType,
          country: filterData.country?.country,
          provider:
            filterData?.supplier?.[0]?.nameCompany ===
            t("App.downloadSalesForce.selectAll")
              ? []
              : filterData.supplier,
          trader:
            filterData.commerce?.[0]?.nameCompany ===
            t("App.downloadSalesForce.selectAll")
              ? []
              : filterData.commerce,
          startDate: moment(filterData.initialDate).format("YYYY-MM-DD"),
          endDate: moment(filterData.finalDate).format("YYYY-MM-DD"),
          typeDate: filterData.dateType,
        };

        obj.createUser = userInfo.firstName + " " + userInfo.lastName;
        props.setDeleteObject(obj);
      }
    };

    reloadData();
  }, [props.isLoadingAcceptConfirmation]);

  /**
   * Auto complete filters
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   *
   * Función para filtrar los datos de acuerdo a los filtros seleccionados
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    setFilterData(data);

    const obj = {
      typeDocument: data.documentType,
      country: data.country.country,
      provider:
        data.supplier[0].nameCompany === t("App.downloadSalesForce.selectAll")
          ? []
          : data.supplier,
      trader:
        data.commerce[0].nameCompany === t("App.downloadSalesForce.selectAll")
          ? []
          : data.commerce,
      startDate: moment(data.initialDate).format("YYYY-MM-DD"),
      endDate: moment(data.finalDate).format("YYYY-MM-DD"),
      typeDate: data.dateType,
    };
    props.setIsOpenDelete(true);

    obj.createUser = userInfo.firstName + " " + userInfo.lastName;
    props.setDeleteObject(obj);
  };

  /**
   *
   * Función que permite manejar el cambio del valor del listado de comercios
   *
   * @param {Event} event - Evento del cambio
   * @param {Array} values - Valores seleccionados
   * @param {String} reason - Razón del cambio
   * @param {Object} detail - Detalle del cambio
   */
  const onCommerceChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const commerce of values) {
      if (commerce.nameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    // Obtener el tamaño de las listas para verificar si se selecciona la opción de seleccionar todos

    // tamaño de la lista de comercios
    let sizeCommerces = commercesList.filter(
      (commerce) =>
        commerce.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // tamaño de los valores seleccionados
    let sizeValues = values.filter(
      (commerce) =>
        commerce.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se selecciona la opción de seleccionar todos o si se seleccionan todos los valores
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeCommerces === sizeValues && sizeCommerces !== 0)
    ) {
      setSelectedCommerces(commercesList);
      setValue("commerce", commercesList);
    } else if (includeSelectAll && reason === "removeOption") {
      //En caso que se remueva una opción y estén seleccionados todos
      const commerceWithoutSelect = values.filter(
        (supplier) =>
          supplier.nameCompany !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedCommerces(commerceWithoutSelect);
      setValue("commerce", commerceWithoutSelect);
    } else {
      // Si se selecciona una opción diferente a seleccionar todos
      setSelectedCommerces(values);
      setValue("commerce", values);
    }

    if (detail) {
      const option = detail.option;

      // Si se elimina la opción de seleccionar todos
      if (
        reason === "removeOption" &&
        option.nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedCommerces([]);
        setValue("commerce", null);
      }
    }
  };

  /**
   *
   * Función que permite manejar el cambio del valor del listado de proveedores
   * @param {Event} event - Evento del cambio
   * @param {Array} values - Valores del cambio
   * @param {String} reason - Razón del cambio
   * @param {Object} detail - Detalle del cambio
   */
  const onSupplierChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const commerce of values) {
      if (commerce.nameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    // Obtener el tamaño de las listas para verificar si se debe seleccionar la opción de seleccionar todos

    // tamaño de la lista de comercios
    let sizeSuppliers = suppliersList.filter(
      (commerce) =>
        commerce.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // tamaño de los valores seleccionados
    let sizeValues = values.filter(
      (commerce) =>
        commerce.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se selecciona la opción de seleccionar todos o si se seleccionan todos los valores

    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeSuppliers === sizeValues && sizeSuppliers !== 0)
    ) {
      setSelectedSuppliers(suppliersList);
      setValue("supplier", suppliersList);
    } else if (includeSelectAll && reason === "removeOption") {
      //En caso que se remueva una opción y estén seleccionados todos
      const suppliersWithoutSelect = values.filter(
        (supplier) =>
          supplier.nameCompany !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedSuppliers(suppliersWithoutSelect);
      setValue("supplier", suppliersWithoutSelect);
    } else {
      // Si se selecciona una opción diferente a seleccionar todos

      setSelectedSuppliers(values);
      setValue("supplier", values);
    }

    if (detail) {
      const option = detail.option;
      // Si se elimina la opción de seleccionar todos
      if (
        reason === "removeOption" &&
        option.nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedSuppliers([]);
        setValue("supplier", null);
      }
    }
  };

  /**
   *
   * Función para manejar el cambio de país, y obtener la lista de comercios y proveedores
   *
   * @param {Object} data
   */
  const onCountryChange = async (data) => {
    fetchTraders(data);
    fetchSuppliers(data);
  };

  /**
   *
   * Función para obtener la lista de comercios por país y actualizar el listado de comercios
   *
   * @param {String} country - País seleccionado
   */
  const fetchTraders = async (country) => {
    try {
      const obj = {
        country: country.country,
      };

      const tradersService = await GetTradersService(obj);

      if (tradersService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          tradersService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              nameCompany: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          const completeList =
            responseMessage.length > 0
              ? [
                  { nameCompany: t("App.downloadSalesForce.selectAll") },
                  ...tradersList,
                ]
              : [];

          setCommercesList(completeList);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para obtener la lista de proveedores por país y actualizar el listado de proveedores
   *
   * @param {String} country
   */
  const fetchSuppliers = async (country) => {
    try {
      const obj = {
        country: country.country,
      };

      const suppliersService = await GetSuppliersService(obj);

      if (suppliersService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          suppliersService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              nameCompany: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          const completeList =
            responseMessage.length > 0
              ? [
                  { nameCompany: t("App.downloadSalesForce.selectAll") },
                  ...suppliersList,
                ]
              : [];

          setSuppliersList(completeList);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para limpiar los filtros
   */
  const handleCleanFilters = () => {
    reset();
    setCountry("");
    setDateType("0");
    setValue("finalDate", null);
    setValue("initialDate", null);
    setSelectedCommerces([]);
    setSelectedSuppliers([]);
  };

  /**
   * Función para actualizar el texto de las fechas iniciales y finales de acuerdo al tipo de fecha seleccionado
   */
  let initialDateText = "";
  let finalDateText = "";
  if (dateType === "0") {
    initialDateText = t("App.deleteAndReload.filters.initialDate");
    finalDateText = t("App.deleteAndReload.filters.finalDate");
  } else if (dateType === "reported") {
    initialDateText = t("App.deleteAndReload.filters.initialDateReported");
    finalDateText = t("App.deleteAndReload.filters.finalDateReported");
  } else {
    initialDateText = t("App.deleteAndReload.filters.initialDateLoaded");
    finalDateText = t("App.deleteAndReload.filters.finalDateLoaded");
  }

  /**
   *
   * Función para validar si los campos están llenos
   *
   * @returns true o false dependiendo si todos los campos están llenos
   */
  const disabledButtonApply = () => {
    return (
      watch("documentType") === "0" ||
      watch("country")?.country === "" ||
      watch("dateType") === "0" ||
      watch("initialDate") === null ||
      watch("finalDate") === null ||
      selectedCommerces.length === 0 ||
      selectedSuppliers.length === 0
    );
  };

  /**
   * Habilitar o deshabilitar el select de compañias/empresas
   */
  const disabledCompanies = () => {
    return watch("country")?.country === "";
  };

  /**
   * Habilitar o deshabilitar los input de fechas iniciales y finales
   */
  const disabledFieldsDates = () => {
    return watch("dateType") === "0";
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      rowSpacing={2}
    >
      <Grid
        xl={9}
        lg={8.5}
        md={9}
        sm={8}
        xs={12}
        item
        className="side-line-contact__bottomAlign"
        width={"100%"}
        alignItems="center"
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid container direction="column" gap={3}>
            <Grid
              container
              direction="row"
              width={"100%"}
              spacing={1}
              rowSpacing={3}
            >
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  defaultValue="0"
                  control={control}
                  name="documentType"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    formState,
                  }) => (
                    <Select
                      id="documentType"
                      labelId="documentType"
                      value={value}
                      className={
                        errors.documentType
                          ? "filter-fields-reload__dateType__error"
                          : "filter-fields-reload__dateType"
                      }
                      onChange={onChange}
                    >
                      <MenuItem disabled value="0">
                        <em>{t("App.deleteAndReload.filters.documentType")}</em>
                      </MenuItem>
                      <MenuItem value="stock">
                        <em>
                          {t("App.deleteAndReload.filters.typeInventory")}
                        </em>
                      </MenuItem>
                      <MenuItem value="sales">
                        <em>{t("App.deleteAndReload.filters.typeSales")}</em>
                      </MenuItem>
                      <MenuItem value="sales_stock">
                        <em>
                          {t("App.deleteAndReload.filters.typeSalesStock")}
                        </em>
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Controller
                  name={"country"}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={
                        errors.country
                          ? "autoComplete__deleteAndReload__error"
                          : "autoComplete__deleteAndReload"
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.pk === value.pk
                      }
                      getOptionLabel={(option) => option.countryName}
                      options={countries}
                      renderInput={(params) => {
                        return (
                          <>
                            <TextField
                              label={t("App.deleteAndReload.filters.country")}
                              sx={{
                                label: {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D",
                                  },
                                },
                              }}
                              {...params}
                            />
                          </>
                        );
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.countryName}</li>
                      )}
                      onChange={(event, values, reason) => {
                        onChange(values);

                        setSelectedCommerces([]);
                        setSelectedSuppliers([]);

                        if (reason === "clear") {
                          setSuppliersList([]);
                          setCommercesList([]);
                        }

                        if (values !== null) {
                          onCountryChange(values);
                          setCountry(values);
                        } else {
                          setCountry({
                            country: "",
                            countryName: "",
                          });
                          onChange({
                            country: "",
                            countryName: "",
                          });
                        }
                      }}
                      value={value || null}
                    />
                  )}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              width={"100%"}
              spacing={1}
              rowSpacing={3}
            >
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Autocomplete
                  name="commerce"
                  disabled={disabledCompanies()}
                  noOptionsText={t("App.validationMessages.noMatchFilter")}
                  className={
                    errors.commerce
                      ? "autoComplete__deleteAndReload__error"
                      : "autoComplete__deleteAndReload"
                  }
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.nameCompany === value.nameCompany
                  }
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo"
                  options={commercesList}
                  value={selectedCommerces}
                  disableCloseOnSelect
                  limitTags={2}
                  onChange={onCommerceChange}
                  getOptionLabel={(option) => option.nameCompany}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.eanCompany}>
                      <Checkbox
                        icon={icon}
                        className="autoComplete__checkbox"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nameCompany}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label={t("App.deleteAndReload.filters.partner")}
                      sx={{
                        label: {
                          fontFamily: "Roboto !important",
                          fontSize: "14px !important",
                          translate: "0px -5px",
                          transformOrigin: "-45px -10px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                  renderTags={(value) => {
                    const numTags = value.length;
                    const limitTags = 2;

                    return (
                      <>
                        {value.slice(0, limitTags).map((option) => (
                          <Chip
                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                            sx={{
                              width: "20%",
                            }}
                            key={option.eanCompany}
                            size="small"
                            label={option.nameCompany}
                          />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Autocomplete
                  name="supplier"
                  disabled={disabledCompanies()}
                  noOptionsText={t("App.validationMessages.noMatchFilter")}
                  className={
                    errors.supplier
                      ? "autoComplete__deleteAndReload__error"
                      : "autoComplete__deleteAndReload"
                  }
                  multiple
                  isOptionEqualToValue={(option, value) => {
                    return option.eanCompany === value.eanCompany;
                  }}
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo"
                  options={suppliersList}
                  value={selectedSuppliers}
                  disableCloseOnSelect
                  limitTags={3}
                  onChange={onSupplierChange}
                  getOptionLabel={(option) => option.nameCompany}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.eanCompany}>
                      <Checkbox
                        icon={icon}
                        className="autoComplete__checkbox"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nameCompany}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label={t("App.deleteAndReload.filters.supplier")}
                      sx={{
                        label: {
                          fontFamily: "Roboto !important",
                          fontSize: "14px !important",
                          translate: "0px -5px",
                          transformOrigin: "-45px -10px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                  renderTags={(value) => {
                    const numTags = value.length;
                    const limitTags = 2;

                    return (
                      <>
                        {value.slice(0, limitTags).map((option) => (
                          <Chip
                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                            sx={{
                              width: "20%",
                            }}
                            key={option.eanCompany}
                            size="small"
                            label={option.nameCompany}
                          />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              width={"100%"}
              spacing={1}
              rowSpacing={3}
            >
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  defaultValue="0"
                  control={control}
                  name="dateType"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    formState,
                  }) => (
                    <Select
                      id="dateType"
                      labelId="dateType"
                      value={value}
                      className={
                        errors.dateType
                          ? "filter-fields-reload__dateType__error"
                          : "filter-fields-reload__dateType"
                      }
                      onChange={onChange}
                      onClick={(e) => {
                        const newValue = e.target.innerText;

                        if (
                          newValue ===
                          t("App.deleteAndReload.filters.dateTypeLoaded")
                        ) {
                          setDateType("loaded");
                        } else {
                          setDateType("reported");
                        }
                      }}
                    >
                      <MenuItem disabled value="0">
                        <em>{t("App.deleteAndReload.filters.dateType")}</em>
                      </MenuItem>
                      <MenuItem value="carga">
                        <em>
                          {t("App.deleteAndReload.filters.dateTypeLoaded")}
                        </em>
                      </MenuItem>
                      <MenuItem value="reportada">
                        <em>
                          {t("App.deleteAndReload.filters.dateTypeReported")}
                        </em>
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  defaultValue={null}
                  control={control}
                  name="initialDate"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    formState,
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="YYYY-MM-DD"
                        disabled={disabledFieldsDates()}
                        className={
                          errors.initialDate
                            ? "filter-fields-reload__datepicker__error"
                            : "filter-fields-reload__datepicker"
                        }
                        label={initialDateText}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label={t("App.deleteAndReload.filters.initialDate")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  defaultValue={null}
                  control={control}
                  name="finalDate"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    formState,
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="YYYY-MM-DD"
                        disabled={disabledFieldsDates()}
                        className={
                          errors.finalDate
                            ? "filter-fields-reload__datepicker__error"
                            : "filter-fields-reload__datepicker"
                        }
                        label={finalDateText}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label={t("App.deleteAndReload.filters.finalDate")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xl={3} lg={3.5} md={3} sm={4} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              disabled={disabledButtonApply()}
              className={
                disabledButtonApply()
                  ? "btn__applyFilter-reload-disabled"
                  : "btn__applyFilter-reload"
              }
              type="submit"
              form="hook-form"
            >
              <a>{t("App.deleteAndReload.filters.applyDeletion")}</a>
            </Button>
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width="100%">
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className={"btn__deleteFilter-reload"}
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <ModalInfo
          responseData={filterResponse}
          open={error}
          onClose={() => setError(false)}
        />
      )}
    </Grid>
  );
};

export default FilterDelete;

import React, { useEffect, useReducer } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { GetMenu } from "../../services/admin/menuOptionsServices";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { CODES } from "../../consts/codes";
import { useTranslation } from "react-i18next";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { GetMenuByProduct } from "../../services/admin/roleServices";

export default function EditRoleMenu(props) {
  const handleCheckDisplay = (level, des) => {
    let arrayChildren = [];

    if (level.levels) {
      for (let i = 0; i < level.levels.length; i++) {
        const childrenLevel = level.levels[i];
        const children = handleCheckDisplay(childrenLevel, des);
        arrayChildren.push(children);
      }
    }

    const finalParent = { ...level, decision: des, levels: arrayChildren };

    return finalParent;
  };

  const initialState = () => [];

  const reducer = (state = initialState(), action = {}) => {
    switch (action.type) {
      case "setLevels":
        return action.levels;
      case "firstLevel":
        //Lo dejo como futura referencia
        const checkedChildren = handleCheckDisplay(
          action.level,
          action.decision
        );

        const oneOpened = state.map((menuParent, idx) => {
          if (idx === action.index[0]) {
            return checkedChildren;
          } else {
            return { ...menuParent, decision: menuParent.decision };
          }
        });

        return oneOpened;

      case "secondLevel":
        const checkedChildren2 = handleCheckDisplay(
          action.level,
          action.decision
        );

        const oneOpened2 = state.map((menuParent, idx) => {
          if (idx === action.index[0]) {
            const parentLevels = state[idx].levels;
            parentLevels[action.index[1]] = checkedChildren2;

            let parentDecision = false;
            for (const level of parentLevels) {
              if (level.decision) {
                parentDecision = true;
              }
            }

            return {
              ...state[idx],
              decision: parentDecision,
              levels: parentLevels,
            };
          } else {
            return { ...menuParent, decision: menuParent.decision };
          }
        });

        return oneOpened2;

      case "thirdLevel":
        const oneOpened3 = state.map((menuParent, idx) => {
          if (idx === action.index[0]) {
            const secondChildrenLevels =
              state[idx].levels[action.index[1]].levels;

            secondChildrenLevels[action.index[2]] = {
              ...secondChildrenLevels[action.index[2]],
              decision: action.decision,
            };

            let parent2Decision = false;
            for (const level2 of secondChildrenLevels) {
              if (level2.decision) {
                parent2Decision = true;
              }
            }

            const secondChildren = {
              ...state[idx].levels[action.index[1]],
              levels: secondChildrenLevels,
              decision: parent2Decision,
            };

            const grandChildren = state[idx].levels;
            grandChildren[action.index[1]] = secondChildren;

            let parent1Decision = false;
            for (const level of grandChildren) {
              if (level.decision) {
                parent1Decision = true;
              }
            }

            return {
              ...state[idx],
              decision: parent1Decision,
              levels: grandChildren,
            };
          } else {
            return { ...menuParent, decision: menuParent.decision };
          }
        });

        return oneOpened3;

      case "Lvl1OneSelection":
        const justOneOpened = state.map((menuParent, idx) => {
          if (idx === action.index[0]) {
            return { ...menuParent, decision: true };
          } else {
            return { ...menuParent, decision: menuParent.decision };
          }
        });

        return justOneOpened;

      case "Lvl2OneSelection":
        const justOneOpened2 = state.map((menuParent, idx) => {
          if (idx === action.index[0]) {
            const parentLevels = state[idx].levels;

            parentLevels[action.index[1]] = {
              ...parentLevels[action.index[1]],
              decision: true,
            };

            return {
              ...state[idx],
              decision: menuParent.decision,
              levels: parentLevels,
            };
          } else {
            return { ...menuParent, decision: menuParent.decision };
          }
        });

        return justOneOpened2;

      case "Lvl3OneSelection":
        const justOneOpened3 = state.map((menuParent, idx) => {
          if (idx === action.index[0]) {
            const secondChildrenLevels =
              state[idx].levels[action.index[1]].levels;

            secondChildrenLevels[action.index[2]] = {
              ...secondChildrenLevels[action.index[2]],
              decision: true,
            };

            const secondChildren = {
              ...state[idx].levels[action.index[1]],
              levels: secondChildrenLevels,
              decision: state[idx].levels[action.index[1]].decision,
            };

            const grandChildren = state[idx].levels;
            grandChildren[action.index[1]] = secondChildren;

            return {
              ...state[idx],
              decision: state[idx].decision,
              levels: grandChildren,
            };
          } else {
            return { ...menuParent, decision: menuParent.decision };
          }
        });
        return justOneOpened3;

      default:
        return state;
    }
  };

  const [level1, dispatch] = useReducer(reducer, reducer());

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const previusStep = () => {
    setActiveStep(0);
    props.previusStep(activeStep);
  };

  const handleJustCheckedMenus = (level) => {
    let arrayOfMenuIds = [];

    if (level.levels) {
      for (let i = 0; i < level.levels.length; i++) {
        const childrenLevel = level.levels[i];

        const children = handleJustCheckedMenus(childrenLevel);
        arrayOfMenuIds = [...arrayOfMenuIds, ...children];
      }
    }

    if (level.decision) {
      arrayOfMenuIds.push(level.pk);
    }

    return arrayOfMenuIds;
  };

  const handleCheckOnDisplay = (item, arrayOfIndex, decision) => {
    switch (arrayOfIndex.length) {
      case 1:
        dispatch({
          type: "firstLevel",
          level: item,
          index: arrayOfIndex,
          decision: decision,
        });
        break;
      case 2:
        dispatch({
          type: "secondLevel",
          level: item,
          index: arrayOfIndex,
          decision: decision,
        });
        break;
      case 3:
        dispatch({
          type: "thirdLevel",
          level: item,
          index: arrayOfIndex,
          decision: decision,
        });
        break;
    }
  };

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted) {
          // const previewMenuService = await GetMenu();
          const previewMenuService = await GetMenuByProduct({
            idMenuOption: props.roleInfo.idProduct,
          });

          if (previewMenuService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              previewMenuService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const Level1 = previewMenuService.data.responseMessage;
              dispatch({ type: "setLevels", levels: Level1 });
              setUserMenuOptions(Level1);
            }
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  const handleSubmitStep2 = () => {
    const justCheckedMenuIds = handleJustCheckedMenus({ levels: level1 });
    props.handleEditRole(justCheckedMenuIds);
  };

  const setUserMenuOptions = (menu) => {
    const userMenu = props.currentRole.lstMenuOptions;

    //filtremos y dejemos una lista de lvl1 y lvl2 y otra de lvl3
    const userMenuMainLevels = userMenu.filter(
      (menuItem) => menuItem.level !== 3
    );

    const userMenuThirdLevel = userMenu.filter(
      (menuItem) => menuItem.level === 3
    );

    for (let i = 0; i < menu.length; i++) {
      const currentElement = menu[i];

      for (let j = 0; j < userMenuMainLevels.length; j++) {
        const currentUserElement = userMenuMainLevels[j];
        //CASO 1: Primer nivel del padre
        if (currentElement.idMenuOption === currentUserElement.idMenuOption) {
          let firstLevelIndex = [];
          firstLevelIndex.push(i);
          dispatch({
            type: "Lvl1OneSelection",
            index: firstLevelIndex,
          });
        } else if (currentUserElement.parent) {
          //CASO 2: Vemos segundo nivel
          if (currentElement.idMenuOption === currentUserElement.parent) {
            let secondLevelIndex = [];
            secondLevelIndex.push(i);
            secondLevelIndex.push(currentUserElement.orderMenu - 1);
            dispatch({
              type: "Lvl2OneSelection",
              index: secondLevelIndex,
            });

            //CASO 3: Verificamos tercer nivel
            for (const menuItem of userMenuThirdLevel) {
              if (menuItem.parent === currentUserElement.idMenuOption) {
                let thirdLevelIndex = [];
                thirdLevelIndex.push(i);
                thirdLevelIndex.push(currentUserElement.orderMenu - 1);
                thirdLevelIndex.push(menuItem.orderMenu - 1);
                dispatch({
                  type: "Lvl3OneSelection",
                  index: thirdLevelIndex,
                });
              }
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Grid>
        <Grid className="modalCreateRoles__descriptionStep2">
          {t("App.listRoles.createRolInfo")}
        </Grid>

        <Grid className="modalCreateRoles__descriptionStep2__select">
          {t("App.listRoles.createRolDecision")}
        </Grid>

        {level1.map((level, index1) => {
          const level2 = level.levels;

          return (
            <Accordion
              key={index1}
              square
              elevation={0}
              className="preview-modal__firstLevel"
              disableGutters
              sx={{
                "&:before": {
                  display: "none",
                },
                padding: "0 !important",
                paddingLeft: "2% !important",
              }}
            >
              <AccordionSummary
                expandIcon={
                  level2.length > 0 ? (
                    <ExpandMoreIcon
                      fontSize="large"
                      className="preview-modal__arrows"
                    />
                  ) : (
                    <></>
                  )
                }
                sx={{
                  "& .MuiAccordionSummary-content": {
                    flexGrow: "0",
                    marginRight: "0.1%",
                    pointerEvents: "auto !important",
                  },
                  justifyContent: "flex-start !important",
                  padding: "0 !important",
                  pointerEvents: "none !important",

                  "& .MuiAccordionSummary-expandIconWrapper": {
                    pointerEvents: "auto !important",
                  },
                }}
              >
                <FormGroup
                  style={{
                    padding: "0 !important",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#543ab4 !important",
                        fontFamily: "Roboto !important",
                        fontWeight: "100 !important",
                        cursor: "default",
                      },
                    }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#543ab4 !important",
                        }}
                        checked={level.decision ? level.decision : false}
                        className="modalPreviewMenu__form-checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          let aa = [];
                          aa.push(index1);
                          handleCheckOnDisplay(level, aa, e.target.checked);
                        }}
                        checkedIcon={<CheckBoxOutlinedIcon />}
                        uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                      />
                    }
                    label={level.menuOptionName}
                  />
                </FormGroup>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  backgroundColor: "white !important",
                  padding: "0 !important",
                  marginLeft: "0.6% !important",
                  border: "none !important",
                }}
              >
                {level2.map((level2, index2) => {
                  const level3 = level2.levels;

                  return (
                    <Grid
                      style={{
                        borderLeft: "1px dashed #5572b7",
                        paddingLeft: "3%",
                      }}
                      key={index2}
                    >
                      <Accordion
                        square
                        elevation={0}
                        sx={{
                          "&:before": {
                            display: "none",
                          },
                          padding: "0 !important",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            level3 && level3.length > 0 ? (
                              <ExpandMoreIcon
                                className="preview-modal__arrows"
                                fontSize="large"
                              />
                            ) : (
                              <></>
                            )
                          }
                          sx={{
                            "& .MuiAccordionSummary-content": {
                              flexGrow: "0",
                              marginRight: "0.5%",
                            },
                            padding: "0 !important",
                            margin: "0 !important",
                            justifyContent: "flex-start !important",
                          }}
                        >
                          <FormGroup style={{ padding: "0 !important" }}>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  color: "#2E3043 !important",
                                  fontFamily: "Roboto !important",
                                  fontWeight: "100 !important",
                                  cursor: "default",
                                },
                              }}
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#543ab4 !important",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  checked={
                                    level2.decision ? level2.decision : false
                                  }
                                  className="modalPreviewMenu__form-checkbox"
                                  onChange={(e) => {
                                    handleCheckOnDisplay(
                                      level2,
                                      [index1, index2],
                                      e.target.checked
                                    );
                                  }}
                                  checkedIcon={<CheckBoxOutlinedIcon />}
                                  uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                                />
                              }
                              label={level2.menuOptionName}
                            />
                          </FormGroup>
                        </AccordionSummary>

                        <AccordionDetails
                          sx={{
                            backgroundColor: "white !important",
                            padding: "0 !important",
                            marginLeft: "0% !important",
                            border: "none !important",
                          }}
                        >
                          {level3 &&
                            level3.map((level3, index3) => {
                              return (
                                <Grid
                                  style={{
                                    borderLeft: "1px dashed #5572b7",
                                    marginLeft: "0.6%",
                                    paddingLeft: "3%",
                                  }}
                                  key={index3}
                                >
                                  <FormGroup
                                    sx={{
                                      padding: "0 !important",
                                    }}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiFormControlLabel-label": {
                                          color: "#2E3043 !important",
                                          fontFamily: "Roboto !important",
                                          fontWeight: "100 !important",
                                        },
                                      }}
                                      control={
                                        <Checkbox
                                          sx={{
                                            color: "#543ab4 !important",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          checked={
                                            level3.decision
                                              ? level3.decision
                                              : false
                                          }
                                          className="modalPreviewMenu__form-checkbox"
                                          onChange={(e) => {
                                            handleCheckOnDisplay(
                                              level3,
                                              [index1, index2, index3],
                                              e.target.checked
                                            );
                                          }}
                                          checkedIcon={<CheckBoxOutlinedIcon />}
                                          uncheckedIcon={
                                            <CheckBoxOutlineBlankIcon />
                                          }
                                        />
                                      }
                                      // label={level3.name}
                                      label={level3.menuOptionName}
                                    />
                                  </FormGroup>
                                </Grid>
                              );
                            })}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}

        <Grid className="modalCreateRoles__form__actions">
          <Button
            className="modalCreateRoles__form__actions-buttonOutline"
            onClick={previusStep}
          >
            {t("App.createRole.returnButton")}
          </Button>

          <Button
            autoFocus
            className="modalCreateRoles__form__actions-buttonFilled"
            onClick={handleSubmitStep2}
          >
            {t("App.createRole.confirmButton")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

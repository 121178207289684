import {
  Autocomplete,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { ToolTipDayOfGeneration } from "../../../components/common/DynamicReport/toolTipDayOfGeneration";
import ModalChooseGenerationDay from "../../../components/common/DynamicReport/modalChooseGenerationDay";
import AddIcon from "@mui/icons-material/Add";

const WhenGenReport = ({
  t,
  control,
  errors,
  register,
  getValues,
  setValue,
  watch,
  frequency,
  setFrequency,
  dayWeek,
  setDayWeek,
  generationdayDayList,
  setGenerationDayList,
  status,
  setStatus,
}) => {
  const [generationdayDisabled, setGenerationDisabled] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  // Opciones para la frecuencia de generación del reporte
  const optionsFrecuency = [
    {
      frecuency: "0",
      frecuencyName: t("App.dynamicPeriodicReport.when.daily"),
    },
    {
      frecuency: "1",
      frecuencyName: t("App.dynamicPeriodicReport.when.weekly"),
    },
    {
      frecuency: "2",
      frecuencyName: t("App.dynamicPeriodicReport.when.monthly"),
    },
    {
      frecuency: "3",
      frecuencyName: t("App.dynamicPeriodicReport.when.yearly"),
    },
  ];

  // Opciones para los días de la semana
  const daysWeek = [
    { label: "Elegir", value: "-1" },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.monday"),
      value: "Lunes",
    },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.tuesday"),
      value: "Martes",
    },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.wednesday"),
      value: "Miércoles",
    },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.thursday"),
      value: "Jueves",
    },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.friday"),
      value: "Viernes",
    },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.saturday"),
      value: "Sabado",
    },
    {
      label: t("App.dynamicPeriodicReport.when.modalChoose.sunday"),
      value: "Domingo",
    },
  ];

  // Opciones para el estado del reporte
  const optionsStatus = [
    {
      status: "0",
      statusName: t("App.dynamicPeriodicReport.when.active"),
    },
    {
      status: "1",
      statusName: t("App.dynamicPeriodicReport.when.inactive"),
    },
  ];

  /**
   * Función para dar estilo al select
   * según si tiene error o si está aún no se
   * ha seleccionado una opción
   */
  const styleSelect = (error, field) => {
    let style = "";
    if (error) {
      style = "select-contact__filters__errors";
    } else if (field === "-1") {
      style = "select-contact__filters__disabled-text";
    } else {
      style = "select-contact__filters";
    }
    return style;
  };

  /**
   *
   * Función para colocar los valores correspondientes a los respectivos campos cuando hay un cambio en el campo de frequency
   *
   * @param {*} event evento del campo de frequency
   */
  const handleFrecuencyChange = (event) => {
    const value = event.target.value;
    setFrequency(value);
    setValue("frequency", value);
    setGenerationDayList({
      label: t("App.dynamicPeriodicReport.when.noDay"),
      value: "",
    });
    if (value !== "1") {
      setDayWeek("-1");
    }
    if (value === 0 || value === "0") {
      setGenerationDisabled(true);
      setGenerationDayList({
        label: t("App.dynamicPeriodicReport.when.noDay"),
        value: "-1",
      });
    } else {
      setGenerationDisabled(false);
      setGenerationDayList({
        label: "",
        value: "",
      });
    }
  };

  /**
   * Función para cambiar el día de generación
   */
  const handleGenerationDayChange = (event) => {
    const value = event.target.value;
    setDayWeek(value);
    setValue("generationDay", value);
    if (frequency === "1") {
      const day = daysWeek.find((day) => day.value === value);
      setGenerationDayList(day);
    }
  };

  /**
   *  Función para cambiar el estado de la generación
   * @param {*} event
   */
  const handleStatusChange = (event) => {
    const value = event.target.value;
    setStatus(value);
    setValue("status", value);
  };

  /**
   *
   * Función para validar si el día de generación fue seleccionado, esto solo aplica para frecuencia de generación diferente a diario
   *
   * @returns true dependiendo de la frecuencia y si se seleccionó el día de generación, false si es lo contrario
   */
  const invalidateGenerationDay = () => {
    let style = "select-contact__filters__disabled";
    if (frequency !== "0") {
      style =
        generationdayDayList.label !== "" || dayWeek !== "-1"
          ? "select-contact__filters"
          : "select-contact__filters__errors";
    }
    return style;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid xl={9} lg={8.7} md={8} sm={12} xs={12} item>
        <Grid
          container
          direction="row"
          width="100%"
          justifyContent="flex-start"
          gap={2}
          paddingBottom={2}
        >
          <Grid item lg={5.85} md={12} sm={12} xs={12} width={"100%"}>
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.dynamicPeriodicReport.when.frecuency")}{" "}
                </label>
              </Grid>
              <Grid item>
                <Select
                  {...register("frequency")}
                  onChange={handleFrecuencyChange}
                  className={styleSelect(errors.frecuency, frequency)}
                  value={frequency}
                  id="frequency"
                  name="frequency"
                  labelId="frequency"
                >
                  {optionsFrecuency.map((option) => (
                    <MenuItem key={option.frecuency} value={option.frecuency}>
                      {option.frecuencyName}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                    fontSize: 10,
                  }}
                >
                  {errors.frecuency ? errors.frecuency.message : ""}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5.85} md={12} sm={12} xs={12} width={"100%"}>
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.dynamicPeriodicReport.when.status")}
                </label>
              </Grid>
              <Grid item>
                <Select
                  {...register("status")}
                  onChange={handleStatusChange}
                  className={styleSelect(errors.status, status)}
                  value={status}
                  id="status"
                  name="status"
                  labelId="status"
                >
                  {optionsStatus.map((option) => (
                    <MenuItem key={option.status} value={option.status}>
                      {option.statusName}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText
                  sx={{
                    margin: "1px 0 0 0",
                    color: "#e52900",
                    fontSize: 10,
                  }}
                >
                  {errors.status ? errors.status.message : ""}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} width={"100%"}>
            <Grid
              container
              direction="row"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              gap={{ sm: 2, xs: 1, md: 2 }}
            >
              <Grid
                item
                lg={5.85}
                md={frequency !== "0" && frequency !== "1" ? 10.63 : 12}
                sm={frequency !== "0" && frequency !== "1" ? 10.94 : 12}
                xs={frequency !== "0" && frequency !== "1" ? 9.6 : 12}
              >
                <Grid container direction="column">
                  <Grid item>
                    <label className="form__label label__text-grey">
                      {t("App.dynamicPeriodicReport.when.generationDay")}{" "}
                    </label>
                  </Grid>
                  <Grid item>
                    {frequency === "1" ? (
                      <Select
                        {...register("generationDay")}
                        onChange={handleGenerationDayChange}
                        className={invalidateGenerationDay()}
                        value={dayWeek}
                        id="generationDay"
                        name="generationDay"
                        labelId="generationDay"
                      >
                        {daysWeek.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <TextField
                        onChange={handleGenerationDayChange}
                        className={invalidateGenerationDay()}
                        value={generationdayDayList.label}
                        InputProps={{
                          readOnly: true,
                        }}
                        FormHelperTextProps={{
                          style: {
                            margin: "-5px 0 1px 0px",
                            color: "#e52900",
                            fontSize: 10,
                          },
                        }}
                        id="generationDay"
                        name="generationDay"
                        disabled={generationdayDisabled || frequency === "0"}
                      />
                    )}
                  </Grid>
                  {frequency !== "0" &&
                    generationdayDayList.value === "" &&
                    dayWeek === "-1" && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {t("App.validationMessages.required")}
                      </Grid>
                    )}
                </Grid>
              </Grid>
              {frequency !== "0" && frequency !== "1" && (
                <Grid
                  item
                  xl={0.5}
                  lg={0.6}
                  paddingTop={generationdayDayList.value !== "" ? 2 : 0}
                >
                  <IconButton
                    className="btn__filled__blue"
                    sx={{
                      fontSize: 100,
                      color: "white",
                      width: "100%",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <AddIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                  <ModalChooseGenerationDay
                    type={"CEN"}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    generationdayDisabled={generationdayDisabled}
                    setGenerationDayList={setGenerationDayList}
                    generationdayDayList={generationdayDayList}
                    frequency={frequency}
                  />
                </Grid>
              )}
            </Grid>

            {/*  */}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xl={3} lg={3.3} md={4} sm={12} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item width="100%" height={"100%"}>
            <ToolTipDayOfGeneration
              generationdayDayList={generationdayDayList}
              frequency={frequency}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WhenGenReport;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StackedLineChart } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { v4 as uuidv4 } from "uuid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const QuestionsAccordion = ({ questionsInfo, isDesktop, t }) => {
  /**
   * Componentes auxiliares
   */
  const SelectionButton = ({ product }) => {
    const isSelected = selectedButton === product.key;
    return (
      <Button
        disableRipple
        className={isSelected ? "btn__help__selected" : "btn__help"}
        onClick={() => {
          handleClick(product);
        }}
      >
        <Grid
          container
          className="col-start"
          columnSpacing={1}
          minWidth={{ lg: 210, sm: 270, xs: 190 }}
        >
          <Grid item >
            <IconButton
              disableRipple
              className={isSelected ? "icon__help__selected" : "icon__help"}
            >
              <StackedLineChart />
            </IconButton>
          </Grid>
          <Grid item className="col-flex">
            {product.name}
          </Grid>
        </Grid>
      </Button>
    );
  };

  /**
   * UseState
   */

  //Navegación
  const [typeOfView, setTypeOfView] = useState("desktop-hd");
  const [upperLimitButtons, setUpperLimitButtons] = useState(4);
  const [lowerLimitButtons, setLowerLimitButtons] = useState(0);
  const [showRightNavigation, setShowRightNavigation] = useState(true);
  const [showLeftNavigation, setShowLeftNavigation] = useState(false);

  //Producto seleccionado
  const [selectedButton, setSelectedButton] = useState("salesstock");

  //Lista de preguntas
  const [questionsList, setQuestionsList] = useState([]);
  const [originalQuestionsList, setOriginalQuestionsList] = useState([]);

  const [isNotFound, setIsNotFound] = useState(false);

  /**
   * Use Effect
   */

  /**
   * useEffect que determina el tipo de vista
   */
  useEffect(() => {
    window.addEventListener("resize", setTypeOfViewByWindowSize);
    return () =>
      window.removeEventListener("resize", setTypeOfViewByWindowSize);
  });

  /**
   * Use Effect que settea el primer producto como seleccionado
   */
  useEffect(() => {
    //Manejo de botones
    setTypeOfViewByWindowSize();

    if (questionsInfo.length > 0) {
      //Setteo de productos
      setSelectedButton("salesstock");
      const initialProduct = questionsInfo.find(
        (product) => product.key === "salesstock"
      );
      setOriginalQuestionsList(initialProduct.questions);
      setQuestionsList(initialProduct.questions);
    }
  }, [questionsInfo]);

  /**
   * Use Effect que settea el límite de botones - 1 a mostrar y reinicia el orden cuando el tipo de view cambia
   */
  useEffect(() => {
    let initialUpperLimit = handleSetUpperLimitButtons();
    setLowerLimitButtons(0);
    setUpperLimitButtons(initialUpperLimit);
    handleShowNavigations(initialUpperLimit);
    handleShowNavigations(0);

    if (
      initialUpperLimit === questionsInfo.length - 1 ||
      questionsInfo.length - 1 < upperLimitButtons
    ) {
      setShowRightNavigation(false);
    }
  }, [typeOfView, questionsInfo]);

  /**
   * Handles
   */

  /**
   * Método que settea el tipo de pantalla
   */
  const setTypeOfViewByWindowSize = () => {
    const viewWidth = window.innerWidth;

    if (viewWidth <= 767) {
      setTypeOfView("mobile");
    } else if (viewWidth > 767 && viewWidth <= 991) {
      setTypeOfView("tablet");
    } else if (viewWidth > 991 && viewWidth <= 1399) {
      setTypeOfView("desktop");
    } else {
      setTypeOfView("desktop-hd");
    }
  };

  /**
   * Settea el número máximo de botones a mostrar (-1) según el tipo de view
   * @returns número máximo de botones a mostrar (-1)
   */
  const handleSetUpperLimitButtons = () => {
    switch (typeOfView) {
      case "mobile":
        return 0;
      case "tablet":
        return 1;
      case "desktop":
        return 3;
      case "desktop-hd":
        return 4;
    }
  };

  /**
   * Maneja la navegación de los botones de los productos hacia la derecha
   */
  const handleRightNavigation = () => {
    const nextIndex = upperLimitButtons + 1;

    if (nextIndex <= questionsInfo.length) {
      let newUpperLimit = upperLimitButtons + 1;
      let newLowerLimit = lowerLimitButtons + 1;
      setUpperLimitButtons(newUpperLimit);
      setLowerLimitButtons(newLowerLimit);
    }
    handleShowNavigations(nextIndex);

    if (typeOfView === "mobile") {
      handleClick(questionsInfo[nextIndex]);
    }
  };

  /**
   * Maneja la navegación de botones de los productos a la izquierda
   */
  const handleLeftNavigation = () => {
    const nextIndex = lowerLimitButtons - 1;

    if (nextIndex >= 0) {
      let newUpperLimit = upperLimitButtons - 1;
      let newLowerLimit = lowerLimitButtons - 1;
      setUpperLimitButtons(newUpperLimit);
      setLowerLimitButtons(newLowerLimit);
    }
    handleShowNavigations(nextIndex);

    if (typeOfView === "mobile") {
      handleClick(questionsInfo[nextIndex]);
    }
  };

  /**
   * Función que settea si los botones de navegación deben visualizarle o no
   * @param {*} nextIndex el siguiente index a mostrar
   */
  const handleShowNavigations = (nextIndex) => {
    if (nextIndex === questionsInfo.length - 1) {
      setShowRightNavigation(false);
    } else {
      setShowRightNavigation(true);
    }

    if (nextIndex === 0) {
      setShowLeftNavigation(false);
    } else {
      setShowLeftNavigation(true);
    }
  };

  /**
   * Función que settea las preguntas del producto en el área de preguntas cuando este es clickeado
   * @param {*} product producto seleccionado
   */
  const handleClick = (product) => {
    setSelectedButton(product.key);
    setOriginalQuestionsList(product.questions);
    setQuestionsList(product.questions);
  };

  /**
   * Función que ejecuta la búsqueda indexada por término exacto entre la lista de preguntas
   * @param {*} e término buscado
   */
  const handleSearch = (e) => {
    const currentSearch = e.target.value;

    const filteredQuestions = originalQuestionsList.filter((question) => {
      let searchLowered = currentSearch.toLowerCase();
      let titleLowered = question.title.toLowerCase();
      let contentLowered = question.contentText.toLowerCase();
      //Busca el término typeado en el título y el contenido
      return (
        titleLowered.includes(searchLowered) ||
        contentLowered.includes(searchLowered)
      );
    });

    //Si hay preguntas para filtrar las muestra, si no muestra filter not found
    if (filteredQuestions.length > 0) {
      setIsNotFound(false);
      setQuestionsList(filteredQuestions);
    } else {
      setIsNotFound(true);
    }
  };

  return (
    <Accordion className="accordion__help">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon fontSize="large" className="preview-modal__arrows" />
        }
      >
        <Grid container columnSpacing={1} className="col-start">
          <Grid item>
            <IconButton disableRipple className="accordion__help__icon-title">
              <img
                src={"/assets/helpResources/frequentQuestions.svg"}
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
          </Grid>
          <Grid item className="display-small__primary-one bold">
            {t("App.helpCenter.questionsTitle")}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="accordion__help__details full-width">
        <Grid container direction={"column"} rowSpacing={3}>
          <Grid
            item
            className="subheading__text-black-2"
            pr={{ xl: 10, lg: 10, sm: 10, xs: 0 }}
            sx={{ padding: !isDesktop ? "10px" : "" }}
          >
            {t("App.helpCenter.questionsDesc")}
          </Grid>

          <Grid item>
            <Grid
              container
              columnSpacing={2}
              rowSpacing={2}
              justifyContent={{
                xl: "flex-start",
                lg: "flex-start",
                sm: "flex-start",
                xs: "center",
              }}
            >
              <Grid item className="col-flex" lg={0} sm={0} xs={0}>
                {showLeftNavigation && (
                  <IconButton disableRipple onClick={handleLeftNavigation}>
                    <ArrowBackIosIcon
                      className="icon__help__selected"
                      sx={{ fontSize: 10 }}
                    />
                  </IconButton>
                )}
              </Grid>

              {questionsInfo.map(
                (product, index) =>
                  index >= lowerLimitButtons &&
                  index <= upperLimitButtons && (
                    <Grid item key={product.id}>
                      <SelectionButton product={product} />
                    </Grid>
                  )
              )}

              <Grid item className="col-flex" lg={0.3} sm={0.5} xs={0.7}>
                {showRightNavigation && (
                  <IconButton disableRipple onClick={handleRightNavigation}>
                    <ArrowForwardIosIcon
                      className="icon__help__selected"
                      sx={{ fontSize: 10 }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <TextField
              placeholder={t("App.helpCenter.tutorialsSearch")}
              className="select-contact__filters__grey"
              onChange={handleSearch}
              InputProps={{
                autoComplete: "none",
                sx: {
                  height: "39px",
                  width: "100% !important",
                },
                startAdornment: (
                  <React.Fragment>
                    <SearchIcon sx={{ color: "#9CA3AF" }} fontSize="large" />
                  </React.Fragment>
                ),
              }}
            />
          </Grid>
          <Grid item maxHeight={320} pb={2} className="only-scroll-y">
            {!isNotFound ? (
              <Grid container direction="column" rowSpacing={2}>
                {questionsList.map((question, idx) => (
                  <Grid item key={uuidv4()} className="full-width">
                    <Accordion className="accordion__help__questions-accordion">
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            fontSize="large"
                            className="preview-modal__arrows"
                          />
                        }
                      >
                        <Grid container className="col-start">
                          <Grid item>
                            <IconButton disableRipple>
                              <QuestionMarkIcon sx={{ color: "#553ab6" }} />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            className="display-small__primary-one"
                          >
                            {question.title}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails className="accordion__help__details">
                        <Grid
                          container
                          className="accordion__help__question-content full-width subheading__text-black-2"
                        >
                          {question.contentHTML}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item className="col-flex">
                <div style={{ height: "50%" }}>
                  <NoMatchImage />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

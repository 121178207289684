import {
  Autocomplete,
  Checkbox,
  Chip,
  Grid,
  TextField,
  createFilterOptions,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Controller } from "react-hook-form";

const FilterUserBG = ({
  setCompanySelected,
  companiesList,
  companySelected,
  control,
  errors,
  t,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Opciones para el autocomplete
   */
  /**
   * filterOptions para el autocomplete de compañías
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   *
   * Función que se acciona cuando se cambia el valor del autocomplete de compañías para cambiar el valor del state de companySelected que es lo que se muestra seleccionado en el autcomplete
   * También funciona para seleccionar todos los valores cuando se selecciona la opción de 'Seleccionar todos'
   *
   * @param {Event} event
   * @param {Values} values comercios
   * @param {String} reason tipo de selección; selectOption, removeOption
   * @param {Object} detail opción elegida
   */
  const OnCompanyChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const company of values) {
      if (company.renameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setCompanySelected(companiesList);
    } else {
      setCompanySelected(values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.renameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setCompanySelected([]);
      }
    }
  };
  return (
    <>
      <Grid item lg={4} md={4} sm={12} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <label className="form__label label__text-grey">
              {t("App.userDetail.company")}
            </label>
          </Grid>

          <Grid item width={"100%"}>
            <Controller
              name={"business"}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  sx={{ height: "39px", background: "white" }}
                  noOptionsText={t("App.validationMessages.noMatchFilter")}
                  className="select-contact__filters"
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.renameCompany === value.renameCompany
                  }
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo"
                  value={companySelected}
                  options={companiesList}
                  disableCloseOnSelect
                  limitTags={2}
                  onChange={OnCompanyChange}
                  getOptionLabel={(option) => option.renameCompany}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        className="autoComplete__checkbox"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.renameCompany}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("App.userDetail.company")}
                      {...params}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;
                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                            sx={{
                              width: "50%",
                            }}
                            key={index}
                            size="small"
                            label={option.renameCompany}
                          />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          {errors && errors.name && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.name.message}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={"100%"}
        >
          <Grid item>
            <label className="form__label label__text-grey">
              {t("App.userDetail.name")}
            </label>
          </Grid>

          <Grid item width={"100%"}>
            <Controller
              control={control}
              name={"name"}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  className={
                    errors.name
                      ? "select-contact__filters__errors__xs"
                      : "select-contact__filters"
                  }
                  placeholder={t("App.userDetail.name")}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          {errors && errors.name && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.name.message}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={"100%"}
        >
          <Grid item>
            <label className="form__label label__text-grey">
              {t("App.userDetail.lastName")}
            </label>
          </Grid>

          <Grid item width={"100%"}>
            <Controller
              control={control}
              name={"lastName"}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  defaultValue={null}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  className={
                    errors.lastName
                      ? "select-contact__filters__errors__xs"
                      : "select-contact__filters"
                  }
                  placeholder={t("App.userDetail.lastName")}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          {errors && errors.lastName && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.lastName.message}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FilterUserBG;

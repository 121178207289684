import React from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";

const ButtonRefresh = ({
  handleRefresh,
  helpText,
  isLoadingRefresh,
  buttonText,
}) => {
  return (
    <>
      <Grid item xs={12} sm={8} md={7} lg={6} className="help-text-container">
        {" "}
        <p className="download-explanation">{helpText}</p>
      </Grid>
      <Grid item xs={12} sm={4} md={2} lg={1}>
        <Button
          onClick={handleRefresh}
          disableRipple
          disabled={isLoadingRefresh ? true : false}
          startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
          className="btn__outlined__blue refresh-other "
        >
          {!isLoadingRefresh ? (
            buttonText
          ) : (
            <CircularProgress size={23} color="inherit" />
          )}
        </Button>
      </Grid>
    </>
  );
};

export default ButtonRefresh;

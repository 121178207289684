import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import { CODES } from "../../../consts/codes";
import {
  GetAlliesList,
  GetSuppliersService,
  GetTradersService,
  ListAlliesRequestService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import moment from "moment";
import ModalInfo from "../Modals/modalInfo";
import StateComponent from "../stateComponent";
import ModalCancelRequest from "../modalCancelRequest";

export const RequestFilterForm = ({
  control,
  errors,
  setValue,
  reset,
  watch,
  handleSubmit,
  setChipsFilters,
  setData,
  setDatatableLoading,
  setFilterNotFound,
  setNoContent,
  setFormFilters,
}) => {
  /**
   * Const
   */

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  /**
   * Use State
   */

  //Listas de los autocomplete
  const [listCountries, setListCountries] = useState([]);
  const [listCommerces, setListCommerces] = useState([]);
  const [listSuppliers, setListSuppliers] = useState([]);

  //Lista de aliados
  const [lstAllies, setLstAllies] = useState([]);

  //Boolean cuando el país está seleccionado
  const [isCountrySelected, setIsCountrySelected] = useState(false);

  //Data cargando
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAlliesLoading, setIsAlliesLoading] = useState(false);

  //Modal error general
  const [openModalGeneralError, setOpenModalGeneralError] = useState(false);

  let formFilters;

  /**
   * Use Effect
   */

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        //Traemos las geografías
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }

        //Traemos los aliados de la plataforma
        setIsAlliesLoading(true);
        fetchAllies().then((result) => {
          setLstAllies(result);
          setIsAlliesLoading(false);
        });
      } catch (error) {
        console.log(
          "============== Error requestFilterForm.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
    setListCountries(countries);
  }, [dispatch, countriesStatus]);

  /**
   * Handles
   */

  // Fetch de la data

  /**
   * Consulta los comercios de un país
   * @param {Object} country país a consultar
   * @returns lista de comercios por país consultado
   */
  const fetchCommerces = async (country) => {
    try {
      const obj = {
        country: country.country,
      };

      const tradersService = await GetTradersService(obj);

      const responseCode = tradersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          const completeList =
            responseMessage.length > 0
              ? [
                  { label: t("App.downloadSalesForce.selectAll") },
                  ...tradersList,
                ]
              : [];

          return completeList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================ Error requestFilterForm.jsx fetchCommerces ===================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Consulta los aliados registrados en la plataforma
   * @returns aliados registrados en la plataforma
   */
  const fetchAllies = async () => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetAlliesList();

    if (status === CODES.COD_RESPONSE_SUCCESS) {
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          return responseMessage;
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          return [];
        default:
          return [];
      }
    } else {
      return [];
    }
  };

  /**
   * Consulta los proveedores de un país específico
   * @param {*} country país a consultar
   * @returns lista de proveedores por país consultado
   */
  const fetchSuppliers = async (country) => {
    try {
      const obj = {
        country: country.country,
      };

      const suppliersService = await GetSuppliersService(obj);
      const responseCode = suppliersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          const completeList =
            responseMessage.length > 0
              ? [
                  { label: t("App.downloadSalesForce.selectAll") },
                  ...suppliersList,
                ]
              : [];

          return completeList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================== Error requestFilterForm.jsx fetchSuppliers =================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Maneja los cambios en el formulario basado en el valor del campo país
   * @param {*} values valor del campo país
   */
  const handleCountryChange = async (values) => {
    try {
      if (values !== null) {
        setIsCountrySelected(true);
        setIsDataLoading(true);

        const newCommerces = await fetchCommerces(values);
        const newSuppliers = await fetchSuppliers(values);

        setListCommerces(newCommerces);
        setListSuppliers(newSuppliers);
        setIsDataLoading(false);
      } else {
        setValue("country", "");
        setIsCountrySelected(false);
        setValue("ally", "0");
        setListCommerces([]);
        setValue("commerce", []);
        setListSuppliers([]);
        setValue("supplier", []);
      }
    } catch (error) {
      console.log(
        "================== Error requestFilterForm.jsx handleCountryChange =================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Maneja los casos de Seleccionar todos para los campos commerce/supplier
   * @param {array} list lista de elementos seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción actualmente seleccionada
   * @param {*} type tipo de campo: commerce/supplier
   * @returns true si el caso contiene selectAll, false si no posee el caso
   */
  const handleSelectAllSelection = (list, reason, detail, type) => {
    let includeSelectAll = false;

    //Reviso si la opción cliqueada es selectAll y fue deseleccionada
    if (detail) {
      const option = detail.option;
      const element = option.label;

      if (
        reason === "removeOption" &&
        element === t("App.downloadSalesForce.selectAll")
      ) {
        setValue(type, []);

        return true;
      }
    }

    //Reviso si el select all fue seleccionado
    for (const element of list) {
      if (element.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    const originalList = type === "commerce" ? listCommerces : listSuppliers;

    //Reviso si la opción select all fue seleccionada o
    //la lista de opciones seleccionadas fueron todas
    if (
      (includeSelectAll && reason === "selectOption") ||
      (!includeSelectAll && list.length === originalList.length - 1)
    ) {
      switch (type) {
        case "commerce":
          setValue(type, listCommerces);

          break;
        case "supplier":
          setValue(type, listSuppliers);

          break;

        default:
          break;
      }

      return true;
    }

    //Si la opción no fue seleccionada retorno falso
    if (!includeSelectAll) {
      return false;
    }

    //Si la opción select All se encuentra seleccionada y se deseleccionó otra opción
    if (includeSelectAll && reason === "removeOption") {
      const withoutSelectAll = list.filter(
        (element) => element.label !== t("App.downloadSalesForce.selectAll")
      );

      setValue(type, withoutSelectAll);

      return true;
    }
  };

  /**
   * Maneja los cambios sobre el campo Comercio
   * @param {*} values lista de comercios seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción seleccionada
   */
  const handleCommerceChange = (values, reason, detail) => {
    handleSelectAllSelection(values, reason, detail, "commerce");
  };

  /**
   * Maneja los cambios sobre el campo Proveedor
   * @param {*} values lista de proveedores seleccionados
   * @param {*} reason tipo de acción: selectOption, removeOption
   * @param {*} detail opción seleccionada
   */
  const handleSupplierChange = (values, reason, detail) => {
    handleSelectAllSelection(values, reason, detail, "supplier");
  };

  /**
   * Estructura la data entrante en el formato de la tabla
   * @param {Array} data data a formattear
   * @returns data con formato de tabla
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        applicationId: !item.idRequest ? " - " : item.idRequest,
        ally: !item.alliedName ? " - " : item.alliedName,
        applicationDate: !item.creationDate ? " - " : item.creationDate,
        user: !item.creationUser ? " - " : item.creationUser,
        documentType: !item.documentType
          ? "-"
          : item.documentType === "sales"
          ? "Ventas"
          : item.documentType === "stock"
            ? "Inventarios"
            : "Ventas e inventarios",
        processType: !item.processType
        ? "-"
        : item.processType === "historical"
        ? "Historico"
        : "Reproceso",
        commerce: !item.trader ? " - " : item.trader,
        supplier: !item.provider ? " - " : item.provider,
        errorDetail:
          item.state != "ERROR"
            ? {
                fileName: "",
                pathFileName: "",
                idRequest: "",
                state: item.state,
              }
            : {
                fileName: item.logFileName,
                pathFileName: item.pathLogFileName,
                idRequest: item.idRequest,
                state: item.state,
              },
        state: !item.state ? "" : valueState(item.state.toUpperCase()),
        cancel:
          item.state.toLowerCase() !== "pendiente" ? (
            <StateComponent value={item} />
          ) : (
            <ModalCancelRequest
              allies={true}
              data={item}
              applyFilters={handleApplyFilters}
            />
          ),
      };
    });

    return processRows;
  };

  /**
   * Valida el estado de la solicitud
   * @param {*} value
   * @returns
   */
  const valueState = (value) => {
    const statesP = [
      "PENDIENTE",
      "PENDIENTE CANCELADO",
      "FINALIZADO",
      "PROCESANDO",
      "EN PROCESO",
      "ENCOLADO POR RECARGAR",
      "CANCELADO",
      "PENDIENTE ENVIO",
    ];
    let text = value;
    if (value === "PENDIENTE CANCELADO") {
      text = "CANCELADO";
    } else if (!statesP.includes(value.toUpperCase())) {
      text = "ERROR";
    }
    return text;
  };

  /**
   * Aplica los filtros de solicitudes de recarga de aliados
   * Consulta las solicitudes de recarga de aliados basado en los filtros
   * @param {object} data filtros de formulario
   */
  const handleApplyFilters = async (data) => {
    try {
      setData([]);
      setNoContent(false);
      setDatatableLoading(true);
      if (data) {
        formFilters = data;
        //Setteamos los valores de las chips
        setChipsFilters({
          country: data.country.countryName,
          initialDate:
            data.initialDate === "" || data.initialDate === null
              ? ""
              : moment(data.initialDate).format("YYYY-MM-DD"),
          finalDate:
            data.finalDate === "" || data.finalDate === null
              ? ""
              : moment(data.finalDate).format("YYYY-MM-DD"),
        });
      }
      const obj = setFormToRequestStructure(data ? data : formFilters);

      setFormFilters(obj);
      const requestApplicationAllies = await ListAlliesRequestService(obj);

      const responseCode = requestApplicationAllies.data.responseCode;
      let responseMessage = requestApplicationAllies.data.responseMessage;
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          //Setteamos la data de la tabla
          const formattedData = setDataStructure(responseMessage);
          setData(formattedData);
          setFilterNotFound(false);
          break;
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          setData([]);
          setFilterNotFound(true);
          break;
        default:
          setData([]);
          setFilterNotFound(true);
          setOpenModalGeneralError(true);
          break;
      }

      setDatatableLoading(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Deshabilita el botón de aplicar filtros
   * @returns true si se deshabilita, false si no se deshabilita
   */
  const disableApplyFilters = () => {
    if (watch("country") !== "") {
      return false;
    }
    return true;
  };

  /**
   * Limpia los filtros y resetea la pantalla de solicitudes
   */
  const handleCleanFilters = () => {
    reset();
    setData([]);
    setIsCountrySelected(false);
    setListCommerces([]);
    setListSuppliers([]);
    setValue("ally", "0");
    setChipsFilters({
      country: "",
      initialDate: "",
      finalDate: "",
    });

    setFilterNotFound(false);
    setNoContent(true);
  };

  /**
   * Formatea la data que proviene de el formulario y construye el objeto para la petición
   * @param {} data información del formulario diligenciado
   * @returns objeto para la petición
   */
  const setFormToRequestStructure = (data) => {
    return {
      country: data.country.country,
      idAllied: data.ally,
      lstEansTraders:
        !data.commerce || data.commerce.length === 0
          ? []
          : data.commerce[0].label === t("App.downloadSalesForce.selectAll")
          ? []
          : data.commerce.map((item) => item.value),
      lstEansProviders:
        !data.supplier || data.supplier.length === 0
          ? []
          : data.supplier[0].label === t("App.downloadSalesForce.selectAll")
          ? []
          : data.supplier.map((item) => item.value),
      documentType:
        data.documentType === "0"
          ? ""
          : data.documentType === "1"
          ? "stock"
          : data.documentType === "2"
            ? "sales"
            : "sales_stock",

      startDate:
        data.initialDate === "" || data.initialDate === null
          ? ""
          : moment(data.initialDate).format("YYYY-MM-DD"),
      endDate:
        data.finalDate === "" || data.finalDate === null
          ? ""
          : moment(data.finalDate).format("YYYY-MM-DD"),
      user: data.user ? data.user : "",
    };
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        item
        xs={12}
        sm={7}
        lg={9}
        md={9}
        xl={9}
        paddingRight={{ xs: "0%", sm: "2%" }}
        className="side-line-contact__bottomAlign"
      >
        <form id="request-allies" onSubmit={handleSubmit(handleApplyFilters)}>
          <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            rowSpacing={3}
          >
            <Grid item width={"100%"} className="reloadsForm__block">
              <Grid container direction="row" spacing={2} rowSpacing={1}>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.countryN")}*{" "}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        name={"country"}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={listCountries}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) => {
                              return option.country === value.country;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.country
                                ? "select-contact__filters__errors__xs"
                                : "select-contact__filters__xs"
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.listUsers.countryN")}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleCountryChange(values, reason);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    {errors && errors.country && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.country.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.allies.request.filters.ally")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name="ally"
                        render={({ field: { onChange, value }, formState }) => (
                          <Select
                            disabled={isCountrySelected ? false : true}
                            value={value}
                            className={
                              errors.ally
                                ? "select-contact__filters__errors__xs"
                                : "select-contact__filters__xs"
                            }
                            id="type"
                            name="type"
                            labelId="type"
                            onChange={(event) => {
                              onChange(event);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.deleteAndReload.filters.commerce"
                                  ).replace("*", "")}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isAlliesLoading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          >
                            <MenuItem disabled value="0">
                              <em> {t("App.createUser.selectOption")}</em>
                            </MenuItem>
                            {lstAllies.map((ally) => (
                              <MenuItem
                                value={ally.idAllied}
                                key={ally.idAllied}
                              >
                                <em> {ally.alliedName}</em>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                    {errors && errors.ally && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.ally.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={"100%"} className="reloadsForm__block">
              <Grid container direction="row" spacing={2} rowSpacing={1}>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.allies.request.filters.commerce").replace(
                          "*",
                          ""
                        )}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        defaultValue={[]}
                        name={`commerce`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            disabled={
                              isCountrySelected
                                ? isDataLoading
                                  ? true
                                  : false
                                : true
                            }
                            className={
                              errors.commerce
                                ? "select-contact__filters__errors__xs"
                                : "select-contact__filters__xs"
                            }
                            disableCloseOnSelect
                            id="checkboxes-tags-commerce"
                            options={listCommerces}
                            multiple
                            limitTags={2}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleCommerceChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.allies.request.filters.commerce"
                                  ).replace("*", "")}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isDataLoading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const numTags = value.length;
                              const limitTags = 1;
                              return (
                                <>
                                  {value
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "55%",
                                        }}
                                        key={index}
                                        size="small"
                                        label={`${option.label}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.commerce && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.commerce.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.deleteAndReload.filters.supplier").replace(
                          "*",
                          ""
                        )}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        defaultValue={[]}
                        name={`supplier`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            disabled={
                              isCountrySelected
                                ? isDataLoading
                                  ? true
                                  : false
                                : true
                            }
                            className={
                              errors.supplier
                                ? "select-contact__filters__errors__xs"
                                : "select-contact__filters__xs"
                            }
                            disableCloseOnSelect
                            id="checkboxes-tags-commerce"
                            options={listSuppliers}
                            multiple
                            limitTags={2}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            value={value}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.value}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.label}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleSupplierChange(values, reason, detail);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.deleteAndReload.filters.supplier"
                                  ).replace("*", "")}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isDataLoading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            renderTags={(value, getTagProps) => {
                              const numTags = value.length;
                              const limitTags = 1;
                              return (
                                <>
                                  {value
                                    .slice(0, limitTags)
                                    .map((option, index) => (
                                      <Chip
                                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                        sx={{
                                          width: "55%",
                                        }}
                                        key={index}
                                        size="small"
                                        label={`${option.label}`}
                                      />
                                    ))}
                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.supplier && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.supplier.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={"100%"} className="reloadsForm__block">
              <Grid container direction="row" spacing={2} rowSpacing={1}>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.deleteAndReload.filters.documentType").replace(
                          "*",
                          ""
                        )}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name="documentType"
                        render={({ field: { onChange, value }, formState }) => (
                          <Select
                            value={value}
                            className={
                              errors.documentType
                                ? "select-contact__filters__errors__xs"
                                : "select-contact__filters__xs"
                            }
                            id="type"
                            name="type"
                            labelId="type"
                            onChange={(event) => {
                              onChange(event);
                            }}
                          >
                            <MenuItem disabled value="0">
                              <em> {t("App.createUser.selectOption")}</em>
                            </MenuItem>

                            <MenuItem value="1">
                              <em> {t("App.allies.stock")}</em>
                            </MenuItem>
                            <MenuItem value="2">
                              <em>
                                {" "}
                                {t("App.deleteAndReload.filters.typeSales")}
                              </em>
                            </MenuItem>
                            <MenuItem value="3">
                              <em>
                                {" "}
                                {t("App.externalFiles.select.salesStock")}
                              </em>
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      {" "}
                      <label className="form__label label__text-grey">
                        {t(
                          "App.applicationDeleteAndReload.filters.applicationInitialDate"
                        ).replace("*", "")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="initialDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat="YYYY-MM-DD"
                              className={
                                errors.initialDate
                                  ? "select-contact__filters__errors__xs"
                                  : "select-contact__filters__xs"
                              }
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              inputProps={{
                                placeholder: t(
                                  "App.applicationDeleteAndReload.filters.applicationInitialDate"
                                ),
                              }}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  size="medium"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    {errors && errors.initialDate && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.initialDate.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      {" "}
                      <label className="form__label label__text-grey">
                        {t(
                          "App.applicationDeleteAndReload.filters.applicationFinalDate"
                        ).replace("*", "")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="finalDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat="YYYY-MM-DD"
                              className={
                                errors.finalDate
                                  ? "select-contact__filters__errors__xs"
                                  : "select-contact__filters__xs"
                              }
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              inputProps={{
                                placeholder: t(
                                  "App.applicationDeleteAndReload.filters.applicationFinalDate"
                                ),
                              }}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  size="medium"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    {errors && errors.finalDate && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.finalDate.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  className="reloadsForm__field"
                >
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.allies.request.filters.user")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name={"user"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            autoComplete="off"
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.user
                                ? "select-contact__filters__errors__xs"
                                : "select-contact__filters__xs"
                            }
                            placeholder={t("App.allies.request.filters.user")}
                            onChange={(event) => {
                              onChange(event);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.user && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.user.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid
        item
        xl={3}
        lg={3}
        md={3}
        sm={5}
        xs={12}
        className="filter-button__container"
      >
        <Grid container direction="row" rowSpacing={0}>
          <Grid item xl={7} lg={7} md={12} sm={8} xs={12} width={"100%"}>
            <Button
              disabled={disableApplyFilters()}
              type="submit"
              form="request-allies"
              className={`${
                disableApplyFilters()
                  ? "btn__applyFilter-reload-disabled"
                  : "btn__applyFilter-reload"
              } filter-button__apply`}
              startIcon={<TuneOutlinedIcon />}
            >
              {t("App.catalogue.filter.applyFilters")}
            </Button>
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={12}
            sm={4}
            xs={12}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {" "}
            <Button
              onClick={handleCleanFilters}
              className="btn__deleteFilter-reload filter-button__clear"
              width="100%"
            >
              {" "}
              {t("App.catalogue.filter.cleanFilters")}
            </Button>{" "}
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          data: { responseMessage: t("App.validationMessages.systemError2") },
        }}
        open={openModalGeneralError}
        onClose={() => {
          setOpenModalGeneralError(false);
        }}
      />
    </Grid>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";
import { GetStores } from "../../services/user/stores/storesService";

export const storesSlice = createSlice({
  name: "stores",
  initialState: {
    stores: [],
    storesStatus: "fetch",
  },
  reducers: {
    resetStoresState(state, action) {
      state.storesStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStores.pending, (state, action) => {
        state.storesStatus = "loading";
      })
      .addCase(fetchStores.fulfilled, (state, action) => {
        state.storesStatus = "succeeded";
        state.stores = action.payload;
      })
      .addCase(fetchStores.rejected, (state, action) => {
        state.storesStatus = "failed";
        state.stores = [];
      });
  },
});

export const getStores = (state) => state.stores.stores;

export const getStoresStatus = (state) => state.stores.storesStatus;

export const { resetStoresState } = storesSlice.actions;

export const fetchStores = createAsyncThunk(
  "catalogue/fetchAllStores",
  async (data) => {

    //TODO: Ajustar el objeto GetStores por integracion de grupo empresarial¨
    data["idFileType"] = 3;
    const requestStoresList = await GetStores({
      ...data,
    });
    if (requestStoresList.status === CODES.COD_RESPONSE_SUCCESS) {
      if (requestStoresList.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        return requestStoresList.data.data;
      }
    }
  }
);

export default storesSlice.reducer;

import { Dialog, DialogContent, DialogActions, Grid, IconButton, Button } from "@mui/material";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ModalDecision } from "./modalDecision";
import ModalInfo from "./modalInfo";
import FileState from "../fileState";
import { GetProductsRequestOC } from "../../../services/user/adminPurchaseOrder/PurcharseOrderProviderService";
import { CODES } from "../../../consts/codes";

const ModalSeeRequestsPurchaseOrderDetail = ({
  order,
  userInfo,
  open,
  setOpen,
  setTitle,
  setOpenErrorModal,
}) => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseMessage, setResponseMesssage] = useState({});
  const [hasFoundData, setHasFoundData] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [causalTitle, setCausalTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);

  /**
   * Handle para cerrar el modal
   */
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();

  /**
   * Columna para la traduccion de causales
   */
  const causalNameColumn = t("App.adminPurchaseOrder.request") === "Solicitar"
    ? {
        name: "causalName",
        label: t("App.adminPurchaseOrder.seeDetailModal.causal"),
        options: { filter: false, searchable: false },
    }
    : {
        name: "causalNameEnglish",
        label: t("App.adminPurchaseOrder.seeDetailModal.causal"),
        options: { filter: false, searchable: false },
    };

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "eanProduct",
      label: t("App.adminPurchaseOrder.seeDetailModal.eanProduct"),
      options: { filter: false },
    },
    {
      name: "product",
      label: t("App.adminPurchaseOrder.seeDetailModal.product"),
      options: {
        filter: false,
        hint: (              
          <span style={{ textAlign: "left"}}>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextOne")}</p>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextTwo")}</p>
          </span>
          ),
      },
    },
    {
      name: "quantity",
      label: t("App.adminPurchaseOrder.seeDetailModal.quantity"),
      options: { filter: false },
    },
  ];

  // Añadimos causalNameColumn si el checkProduct es "SI"
  if (order.checkProduct === "SI") {
    columns.push(causalNameColumn);
  }

  // Añadimos la columna "actions" si el estado es "APROBADO PARCIAL"
  if (order.requestState.toUpperCase() === "APROBADO PARCIAL") {
    columns.push({
      name: "actions",
      label: t("App.adminClientUsers.table.state"),
      options: {
        filter: true,
        customBodyRender: (value) => (
          <FileState value={value.itemState ? value.itemState : "Pendiente"} />
        ),
      },
    });
  }
  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    searchPlaceholder: t("App.requestsPurchaseOrder.searchText"),
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * useEffect que verifica si el tamaño de la pantalla es mobile
   */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 400)
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    fetchProductsOC();
  }, [open]);

  useEffect(() => {
    if (data.length > 0) {
      getCausalOption();
    }
  }, [data]);

  /**
     * Función para obtener los productos de la orden de compra
     */
  const getCausalOption = () => {
    if(order.checkGeneral==="SI"){
      setCausalTitle(data[0].causalName)
    }
  };

  /**
   * Función para obtener los productos de la orden de compra
   */
  const fetchProductsOC = () => {
    if (userInfo) {
      getProductsOC();
    }
  };

  /**
   * Formatear la información de la tabla
   * @param {*} data información para la tabla
   * @returns
   */
  const setDataStructure = (data) => {
    const processedRows = data.map((item, index) => {
      return {
        eanProduct: !item.eanProduct ? " - " : item.eanProduct,
        product: !item.product ? " - " : item.product,
        quantity: !item.quantity ? " - " : item.quantity,
        causalName: !item.causalName ? " - " : item.causalName,
        causalNameEnglish: !item.causalNameEnglish
          ? " - "
          : item.causalNameEnglish,
        actions: item,
      };
    });
    return processedRows;
  };

  /**
   * Función para obtener los productos de la orden de compra
   */
  const getProductsOC = async () => {
    try {
      setIsLoading(true);

      const obj = {
        eanTrader: order.eanTrader,
        eanProvider: userInfo.company.eanCompany,
        ocNumber: order.ocNumber,
        requestId: order.requestId,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetProductsRequestOC(obj);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setHasFoundData(responseMessage.length > 0 ? true : false);
            setData(setDataStructure(responseMessage));
            break;

          default:
            setData([]);
            break;
        }
      } else {
        setData([]);
        setOpenErrorModal(true);
        setOpen(false);
      }
    } catch (error) {
      setOpenErrorModal(true);
      setOpen(false);
      console.log("---- ERROR getProductsOC ----");
      console.log(error);
    }
  };

  const handleOpenModal = () => {
    setHasFoundData(true);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo y isOpenModalInfo, cómo también enviar la info para el modalInfo
   */
  const handleAgree = () => {
    const obj = {
      status: 200,
      data: {
        responseCode: 0,
        responseMessage: t("App.adminPurchaseOrder.modalInfoConsult"),
      },
    };

    setResponseMesssage(obj);
    setIsOpenModalInfo(true);
    setIsOpenModalDecision(false);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo a false
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="dialog-title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              boxShadow: "none",
              width: "80%",
              maxWidth: "900px",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <Grid container gap={2}>
          <Grid item justifyContent="flex-end">
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid container item direction="column" gap={1}>
            <h1 className="display-large__primary-one">
              {t("App.adminPurchaseOrder.seeDetailModal.title")}{" "}
              {order?.ocNumber}
            </h1>

            <label>
              {t("App.businessRules.table.commerce")}:{" "}
              <span className="state-colors__primary-color-one">
                {order?.trader}
              </span>
            </label>

            {order?.requestState.toUpperCase() !== "PENDIENTE" &&
              order?.requestState.toUpperCase() !== "APROBADO PARCIAL" && (
                <label>
                  <span
                    className={`${
                      order.requestState.toUpperCase() === "APROBADO"
                        ? "text-colors__success"
                        : "text-colors__error-state"
                    }`}
                  >
                    {t("App.adminClientUsers.table.state")}
                    {": "} {order.requestState}
                  </span>
                </label>
              )}

            {order?.checkGeneral === "SI" &&(
                 <label className="state-colors__orange">
                 {t("App.adminPurchaseOrder.seeDetailModal.causal")}:{" "}
                 {causalTitle}
               </label>
              )}
          </Grid>
        </Grid>

        <DialogContent className="scroll-table full-width">
          <Grid container direction="column">
            <Grid item className="full-width">
              {hasFoundData ? (
                <MUIDataTable
                  className="dataTable__salesForce full-width"
                  data={data}
                  columns={columns}
                  options={options}
                />
              ) : (
                <Grid item sx={{ width: "100%" }}>
                  <NoMatchImage />
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>

          <Grid item
          sx={{
              marginTop: "8px",
              display: 'flex',
              justifyContent: 'center'
          }}>

              <Button
                variant="contained"
                className="btn__filled__blue"
                onClick={handleClose}
                fullWidth={isMobile}
              >
                {t("App.buttonLabels.accept")}
              </Button>
              
          </Grid>

      </Dialog>

      <ModalDecision
        title={t("App.adminPurchaseOrder.seeDetailModal.modalDecisionTitle", {
          ocNumber: order?.purchaseOrderNumber,
          commerce: order?.commerce,
        })}
        message={t("App.adminPurchaseOrder.seeDetailModal.modalDecisionText")}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />

      <ModalInfo
        title={t("App.adminPurchaseOrder.seeDetailModal.info")}
        responseData={responseMessage}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          setIsOpenModal(false);
        }}
      />
    </>
  );
};

export default ModalSeeRequestsPurchaseOrderDetail;

import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: "450px",
  },
});

export const TooltipMainCompany = () => {
  const { t } = useTranslation();

  return (
    <CustomTooltip
      className="tooltip__informative"
      title={
        <React.Fragment>
          <Grid container direction="row">
            <Grid item className="tooltip__informative">
              {t("App.manageBusinessGroup.create.tooltip")}
            </Grid>
          </Grid>
        </React.Fragment>
      }
    >
      <IconButton>
        <InfoOutlinedIcon sx={{ fontSize: "22px", color: "#543AB4" }} />
      </IconButton>
    </CustomTooltip>
  );
};

import { React, useEffect } from "react";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UploadMasters from "./uploadMasters";
import { SelectMasters } from "./selectMasters";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentMaster } from "../../../parts/masters/mastersSlice";
import DownloadMasters from "./downloadMasters";
import {
  fetchBusinessGroup,
  getIsBusinessGroupStatus,
} from "../../../parts/businessGroup/businessGroupSlice";
import { getUser } from "../../userSlice";
export const Masters = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const currentMaster = useSelector(getCurrentMaster);
  const businessGroupStatus = useSelector(getIsBusinessGroupStatus);
  const userInfo = useSelector(getUser);

  /**
   * Use Effect
   */

  useEffect(() => {
    try {
      switch (businessGroupStatus) {
        case "fetch":
          if (userInfo) {
            dispatch(
              fetchBusinessGroup({
                country: userInfo.company.country,
                eanProvider: userInfo.company.eanCompany,
                email: userInfo.email,
                typeOption: 1,
              })
            );
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(
        "==============Error masters.jsx fetchBusinessGroup ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, [businessGroupStatus, dispatch, userInfo]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <h1 className="display-large__primary-one">{t("App.masters.title")}</h1>
      </Grid>

      <Grid item className="grid__container__fullW">
        <SelectMasters />
      </Grid>
      {currentMaster !== "2" && (
        <>
          <Grid item className="grid__container__fullW">
            <Accordion className="accordion__upload">
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Grid container direction="row">
                  <Grid item>
                    <FileDownloadOutlinedIcon className="accordion__upload__downloadIcon" />
                  </Grid>
                  <Grid item className="col-flex">
                    <p
                      className="display-small__primary-one"
                      style={{ paddingLeft: "1rem" }}
                    >
                      {t("App.masters.download")}
                    </p>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className="accordion__upload__detail">
                <DownloadMasters />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item className="grid__container__fullW u-marginB">
            <Accordion className="accordion__upload">
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Grid container direction="row">
                  <Grid item>
                    <FileUploadOutlinedIcon className="accordion__upload__uploadIcon" />
                  </Grid>
                  <Grid item className="col-flex">
                    <p
                      className="display-small__primary-one"
                      style={{ paddingLeft: "1rem" }}
                    >
                      {t("App.masters.upload")}
                    </p>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className="accordion__upload__detail">
                <UploadMasters />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      )}
    </Grid>
  );
};

import jwt_decode from "jwt-decode";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../pages/userSlice";

const useAuth = ({ redirectPath = "/", requiredRole, children, path }) => {
  const userInfo = useSelector(getUser);
  const accessToken = localStorage.getItem("accessToken");
  const infoToken = localStorage.getItem("infoToken");

  const existAccessToken = !!accessToken;
  const existInfoToken = !!infoToken;
  let role = "";
  if (userInfo && existInfoToken) {
    const infoUser = jwt_decode(infoToken);
    role = infoUser["custom:Role"];
    if (path !== "home" && role === "user") {
      let menu = userInfo.lstMenuStandar;
      if (!menu.some((item) => item.url?.includes(path))) {
        return <Navigate to={"/user/home"} replace />;
      }
    }
  }
  if (existInfoToken) {
    const userInfo = jwt_decode(infoToken);
    role = userInfo["custom:Role"];
  }
  if (!existAccessToken || !existInfoToken) {
    return <Navigate to={redirectPath} replace />;
  }
  if (role === requiredRole) {
    return children ? children : <Outlet />;
  } else {
    if (role === "user") {
      return <Navigate to={"/user/home"} replace />;
    }

    if (role === "admin") {
      return <Navigate to={"/admin/home"} replace />;
    }
  }
};

export default useAuth;

import { useIdleTimer } from "react-idle-timer";
export default function useIdle({
  onIdle,
  idleTime = 1,
  onPrompt,
  promptTime = 1,
}) {
  /**
   * Función que activa el prompt o adventencia de inactividad al usuario
   * Esta función se ejecuta n minutos (siendo n promptTime) antes del timeout
   */
  const handleOnPrompt = () => {
    onPrompt();
  };

  /**
   * Función que ejecuta acciones cuando el usuario cumplió el tiempo total de inactividad en la plataforma
   */
  const handleOnIdle = () => {
    onIdle();
  };

  /**
   * useIdle
   */
  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    promptBeforeIdle: 1000 * 60 * promptTime,
    onIdle: handleOnIdle,
    onPrompt: handleOnPrompt,
    debounce: 500,
  });

  return {
    reset,
  };
}

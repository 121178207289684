import { Grid } from "@mui/material";
import React from "react";
import NoRegistry from "../../../assets/common/noRegistry.svg";

export const NoRegistryComponent = ({ title, description }) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="u-marginB u-marginT"
      spacing={2}
    >
      <Grid item className="text-center">
        <img className="img__noInfo" src={NoRegistry} />
      </Grid>
      <Grid item className="text-center">
        <p className="display-large__primary-one text-center">{title}</p>
      </Grid>
      <Grid item>
        <p className="subheading__text-grey text-center">{description}</p>
      </Grid>
    </Grid>
  );
};

import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../Modals/modalInfo";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InfoGeneration } from "../SpecialFiles/infoGenerationAccordion";
import CheckIcon from "@mui/icons-material/Check";
import { FieldsVisualization } from "../SpecialFiles/fieldsVisualizationAccordion";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { GetFiltersList } from "../../../services/admin/adminServices";
import { CreateSpecialFileCTyS } from "../../../services/admin/specialFiles";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import EditSpecialFile from "./editSpecialFile";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import { GetSuppliersService } from "../../../services/user/deleteAndReload/deleteAndReload";
import { REGEXP } from "../../../consts/regexp";

const CreateSpecialFile = ({
  setData,
  setIsLoadingData,
  data,
  updateDatatableVisually,
}) => {
  /**
   * Use State
   */
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);
  const [unfilteredList, setUnfilteredList] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [createData, setCreateData] = useState(false);
  const [titleModalInfo, setTitleModalInfo] = useState(false);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "============== Error createSpecialFile.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
    setUnfilteredList(countries);
    setCountriesList(countries);
  }, [dispatch, countriesStatus]);

  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  const schema = yup.object().shape({
    url: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA2,
        t("App.validationMessages.noSpecialCharacters", {
          characters: "<> ' ; ° ´ ` ¨ " + '"',
        })
      ),
  });

  /**
   * Use Form
   */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Handles
   */
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  /**
   *
   * Consulta los proveedores de un país específico
   *
   * @param {*} country país a consultar
   */
  const fetchSuppliers = async (country) => {
    try {
      const suppliersService = await GetSuppliersService({ country });
      const responseCode = suppliersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              companyName: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          const eanList = suppliersList.map((item) => item.eanCompany);

          setEanList(eanList);
          setCompanies(suppliersList);
          setCompanyList(suppliersList);

          break;
        default:
          setEanList([]);
          setCompanies([]);
          setCompanyList([]);

          break;
      }
    } catch (error) {
      console.log(
        "================= Error createSpecialFile.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para cambiar las empresas en base al país seleccionado
   *
   * @param {Oject} values
   */
  const handleChangeCountry = (values) => {
    if (values) {
      setValue("ean", null);
      setValue("company", null);
      setCompanyList([]);
      setEanList([]);
      const country = values.country;

      fetchSuppliers(country);
    } else {
      setValue("ean", null);
      setValue("company", null);
    }
  };

  /**
   *
   * Función para seleccionar el ean corrrespondiente a la empresa seleccionada
   *
   * @param {Object} values
   */
  const handleChangeCompany = (values) => {
    if (values) {
      if (!getValues("ean")) {
        setValue("ean", null);
      }

      const company = values;

      const filtered = companies.filter(
        (item) => item.companyName === company.companyName
      );

      const eanList = filtered.map((item) => item.eanCompany);

      setValue("ean", company.eanCompany);

      setEanList(eanList);
    }
  };

  /**
   *
   * Función para seleccionar la empresa corrrespondiente al ean seleccionada
   *
   * @param {Object} values
   */
  const handleChangeEan = (values) => {
    if (values) {
      const ean = values;

      if (values === null) {
      }

      const filtered = companies.filter((item) => item.eanCompany == ean);

      const companyList = filtered.map((item) => {
        return { companyName: item.companyName, eanCompany: item.eanCompany };
      });

      setValue("company", {
        companyName: companyList[0].companyName,
        eanCompany: companyList[0].eanCompany,
      });
      setCompanyList(companyList);
    }
  };

  /**
   * Función para reiniciar los valores de ean y empresa cuando se limpia el autocomplete de ean o empresa
   */
  const resetValuesWhenClear = () => {
    setValue("ean", null);
    setValue("company", null);

    const companyList = companies.map((item) => {
      return { companyName: item.companyName, eanCompany: item.eanCompany };
    });

    const eanList = companyList.map((item) => item.eanCompany);

    setCompanyList(companyList);
    setEanList(eanList);
  };

  /**
   *
   * Función para colocar el título correspondiente en base al código de respuesta
   *
   * @param {Number} responseCode
   * @returns título correspondiente
   */
  const setModalInfoTitle = (responseCode) => {
    if (responseCode === 1005) {
      return t("App.specialFilesCTyS.modalInfo.title1");
    } else {
      return "";
    }
  };

  /**
   *
   * Triggers create specialFile service
   *
   * @param {object} data route info
   * @returns if service does not response as expected
   */
  const handleCreateSpecialFileCTyS = async (data) => {
    try {
      const obj = {
        country: data.country.country,
        eanProvider: data.ean,
        provider: data.company.companyName,
        url: data.url,
        state: data.status,
        creationUser: fullNameUser,
      };

      setLoadingCreate(true);

      const createService = await CreateSpecialFileCTyS(obj);

      setLoadingCreate(false);

      //Si el servicio no responde como se espera
      if (
        createService.status !== CODES.COD_RESPONSE_HTTP_OK ||
        createService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setCreateData(createService);
        setTitleModalInfo(setModalInfoTitle(createService.data.responseCode));
        setError(true);

        return;
      }

      //Actualizo visualmente la tabla con el nuevo registro
      updateDatatableVisually(obj);
      setSuccess(true);
      setCreateData(createService);
    } catch (error) {
      console.log("================Error creating Route ====================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <Button
        className="btn__createConfig"
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        {t("App.specialFilesCTyS.buttonLabels.addConfiguration")}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box className="scroll-y modal-box-createEditCompany">
          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton component="span" onClick={handleClose}>
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.specialFilesCTyS.title1")}
              </h1>
            </Grid>

            <Grid item className="full-width">
              <form onSubmit={handleSubmit(handleCreateSpecialFileCTyS)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.createAndEditCompany.subtitle1")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.country
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            filterOptions={filterOptions}
                            options={countriesList}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) => {
                              // console.log(option, value);
                              return option.country === value.country;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("App.createAndEditCompany.country")}
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {isLoadingCreate ? (
                                        <CircularProgress
                                          sx={{ color: "#4DCAFA" }}
                                          size={15}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleChangeCountry(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        name="company"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            filterOptions={filterOptions}
                            disabled={watch("country") ? false : true}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.company
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            options={companyList}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) => {
                              return option.eanCompany === value.eanCompany;
                            }}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.eanCompany}>
                                  {option.companyName}
                                </li>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  label={t("App.listUsers.enterprise")}
                                  InputLabelProps={{
                                    style: {
                                      margin: "-5px 0 0 -10px",
                                      padding: "0 0 0 5px",
                                      color: "#8c8d9d",
                                      fontSize: 13,
                                    },
                                  }}
                                />
                              );
                            }}
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleChangeCompany(values, reason);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Grid item>
                        <Controller
                          name="ean"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={watch("country") ? false : true}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={`${
                                errors.ean
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                              getOptionLabel={(option) => option}
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              options={eanList}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  resetValuesWhenClear();
                                }
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    label={t(
                                      "App.createAndEditCompany.eanCompany"
                                    )}
                                    InputLabelProps={{
                                      style: {
                                        margin: "-5px 0 0 -10px",
                                        padding: "0 0 0 5px",
                                        color: "#8c8d9d",
                                        fontSize: 13,
                                      },
                                    }}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeEan(values);
                              }}
                              value={value || null}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.specialFilesCTyS.subtitle2")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={6} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="url"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.url
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            helperText={errors.url ? errors.url.message : ""}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.specialFilesCTyS.url")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} sm={6} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.listUsers.status")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.status
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.createAndEditCompany.status")}
                              </MenuItem>
                              <MenuItem value="ACTIVO">
                                {t("App.createRole.active")}
                              </MenuItem>
                              <MenuItem value="INACTIVO">
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  direction={isDesktop ? "row" : "column-reverse"}
                  justifyContent={isDesktop ? "flex-end" : "center"}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} lg={1} className="full-width">
                    <Button
                      className="btn__filled__gradient full-width"
                      onClick={handleClose}
                    >
                      {t("App.buttonLabels.cancel")}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4} lg={2.5} className="full-width">
                    <Button
                      disabled={
                        watch("country") &&
                        watch("company") &&
                        watch("ean") &&
                        watch("url") &&
                        watch("status") !== ""
                          ? false
                          : true
                      }
                      className={`${
                        watch("country") &&
                        watch("company") &&
                        watch("ean") &&
                        watch("url") &&
                        watch("status") !== ""
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width`}
                      type="submit"
                      startIcon={!loadingCreate ? <AddIcon /> : <></>}
                    >
                      {!loadingCreate ? (
                        t(
                          "App.specialFilesCTyS.buttonLabels.createConfiguration"
                        )
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {error && (
        <ModalInfo
          title={titleModalInfo}
          responseData={createData}
          open={open}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpen(false);
            reset();
          }}
        />
      )}

      {success && (
        <ModalInfo
          responseData={createData}
          open={open}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpen(false);
            reset();
          }}
        />
      )}
    </>
  );
};

export default CreateSpecialFile;

import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import es from "date-fns/locale/es";
import { DayOfMonthPicker } from "./dayOfMonthPicker";

registerLocale("es", es);
const ModalChooseGenerationDay = (props) => {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleClickOpen = () => {
    if (!props.generationdayDisabled) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    if (props.openModal) {
      props.setOpenModal(false);
    }
    setOpen(false);
    setSelectedOption(null);
    props.setGenerationDayList({
      label: "",
      value: "",
    });
  };

  const handleDayChange = (event) => {
    setSelectedOption(event.target.value);

    props.setGenerationDayList(JSON.parse(event.target.value));
  };

  const handleAccept = () => {
    if (props.openModal) {
      props.setOpenModal(false);
    }
    setOpen(false);
  };

  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  return (
    <div>
      {props.type !== "CEN" && (
        <AddOutlinedIcon
          sx={{
            fontSize: 38,
            color: "white",
            backgroundColor: props.generationdayDisabled
              ? "#C0C1D8"
              : "#543AB4",
            borderRadius: "5px",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={handleClickOpen}
        />
      )}

      <Dialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={props.openModal ? props.openModal : open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100%",
              maxWidth: "650px",
              maxHeight: "460px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="dialog-title"
          className="modalEditUsers__form-title"
          onClose={handleClose}
        >
          {t("App.dynamicPeriodicReport.when.modalChoose.title")}
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {(props.frequency === "1" || props.frequency === 1) && (
              <Grid
                item
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={handleDayChange}
                  value={JSON.stringify(props.generationdayDayList) || ""}
                >
                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.monday"
                      ),
                      value: 1,
                    })}
                    control={<Radio />}
                    label={
                      <p className="heading__text-black">
                        {t("App.dynamicPeriodicReport.when.modalChoose.monday")}
                      </p>
                    }
                  />
                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.tuesday"
                      ),
                      value: 2,
                    })}
                    control={<Radio />}
                    label={
                      <p className="heading__text-black">
                        {t(
                          "App.dynamicPeriodicReport.when.modalChoose.tuesday"
                        )}
                      </p>
                    }
                  />
                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.wednesday"
                      ),
                      value: 3,
                    })}
                    control={<Radio />}
                    label={
                      <p className="heading__text-black">
                        {t(
                          "App.dynamicPeriodicReport.when.modalChoose.wednesday"
                        )}
                      </p>
                    }
                  />

                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.thursday"
                      ),
                      value: 4,
                    })}
                    control={<Radio />}
                    label={
                      <p className="heading__text-black">
                        {t(
                          "App.dynamicPeriodicReport.when.modalChoose.thursday"
                        )}
                      </p>
                    }
                  />

                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.friday"
                      ),
                      value: 5,
                    })}
                    control={<Radio />}
                    label={
                      <p className="heading__text-black">
                        {t("App.dynamicPeriodicReport.when.modalChoose.friday")}
                      </p>
                    }
                  />

                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.saturday"
                      ),
                      value: 6,
                    })}
                    control={<Radio />}
                    label={
                      <p className="heading__text-black">
                        {t(
                          "App.dynamicPeriodicReport.when.modalChoose.saturday"
                        )}
                      </p>
                    }
                  />

                  <FormControlLabel
                    value={JSON.stringify({
                      label: t(
                        "App.dynamicPeriodicReport.when.modalChoose.sunday"
                      ),
                      value: 7,
                    })}
                    control={<Radio sx={{ font: "2rem" }} />}
                    label={
                      <p className="heading__text-black">
                        {t("App.dynamicPeriodicReport.when.modalChoose.sunday")}
                      </p>
                    }
                  />
                </RadioGroup>
              </Grid>
            )}

            {(props.frequency === "2" || props.frequency === 2) && (
              <DayOfMonthPicker
                setGenerationDayList={props.setGenerationDayList}
                setSelectedOption={setSelectedOption}
                generationdayDayList={props.generationdayDayList}
              />
            )}

            {(props.frequency === "3" || props.frequency === 3) && (
              <DatePicker
                locale={i18n.language.includes("es") ? "es" : ""}
                minDate={addDays(new Date(), 1)}
                onChange={(date) => {
                  setSelectedOption(moment(date).format("DD-MM-YYYY"));
                  props.setGenerationDayList({
                    label: moment(date).format("DD-MM-YYYY"),
                    value: moment(date).format("YYYYMMDD"),
                  });
                }}
                inline
              />
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            spacing={2}
            style={{ paddingTop: "3rem" }}
          >
            <Grid item>
              <Button
                type="button"
                disableRipple
                className="btn__filled__gradient"
                onClick={() => {
                  handleClose();
                }}
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disableRipple
                type="submit"
                disabled={selectedOption == null}
                className={
                  selectedOption !== null
                    ? "btn__applyFilter"
                    : "btn__filled__disabled"
                }
                onClick={() => {
                  handleAccept();
                }}
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalChooseGenerationDay;

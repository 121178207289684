import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalChangeAllie } from "../Modals/modalChangeAllie";
import { GetAlliesList } from "../../../services/user/deleteAndReload/deleteAndReload";
import { CODES } from "../../../consts/codes";

export const SelectorAlliesReload = ({
  allySelected,
  setAllySelected,
  control,
  setAllySelectedName,
  setValue,
  errors,
  reset,
}) => {
  const { t } = useTranslation();

  /**
   * Use State
   */
  //Lista de aliados
  const [lstAllies, setLstAllies] = useState([]);
  const [isAlliesLoading, setIsAlliesLoading] = useState(false);

  //Modal cambio de alidos
  const [openModalAllieChange, setOpenModalAllieChange] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    setIsAlliesLoading(true);
    fetchAllies().then((result) => {
      setLstAllies(result);
      setIsAlliesLoading(false);
    });
  }, []);

  /**
   * Handles
   */

  /**
   * Consulta los aliados registrados en la plataforma
   * @returns aliados registrados en la plataforma
   */
  const fetchAllies = async () => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetAlliesList();

    if (status === CODES.COD_RESPONSE_SUCCESS) {
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          return responseMessage;
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          return [];
        default:
          return [];
      }
    } else {
      return [];
    }
  };

  /**
   * Administra el cambio de selección de aliado
   * @param {*} event evento que contiene el aliado seleccionado
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;

    //Obtiene el id del aliado seleccionado
    setAllySelected(id);

    //Obtiene el nombre del aliado seleccionado
    setAllySelectedName(
      lstAllies.find((ally) => ally.idAllied === id).alliedName
    );
    if (allySelected !== 0) {
      setOpenModalAllieChange(true);
    } else {
      setValue("ally", id);
    }
  };

  return (
    <Grid item className="grid__container__fullW">
      <ModalChangeAllie
        open={openModalAllieChange}
        setOpen={setOpenModalAllieChange}
        desiredType={allySelected}
        setValue={setValue}
        allySelected={allySelected}
        reset={reset}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className="grid__container__filled__rounded"
      >
        <Grid item>
          <p className="heading__text-default">
            {t("App.allies.selectorTitle")}
          </p>
        </Grid>
        <Grid item className="u-marginL">
          <Controller
            control={control}
            name="ally"
            render={({ field: { onChange, value }, formState }) => (
              <Select
                disabled={isAlliesLoading}
                value={value}
                className={"modalCreateUsers__form-input"}
                id="type"
                name="type"
                labelId="type"
                onChange={(event) => {
                  handleTypeChange(event);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder={t(
                        "App.deleteAndReload.filters.commerce"
                      ).replace("*", "")}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isAlliesLoading ? (
                              <CircularProgress
                                sx={{ color: "#4DCAFA" }}
                                size={15}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
              >
                <MenuItem disabled value={0}>
                  <em> {t("App.allies.selectorPlaceholder")}</em>
                </MenuItem>

                {lstAllies.map((ally) => (
                  <MenuItem value={ally.idAllied} key={ally.idAllied}>
                    <em> {ally.alliedName}</em>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        {errors && errors.ally && (
          <Grid
            item
            sx={{
              margin: "1px 0 0 0",
              color: "#e52900",
            }}
          >
            {errors.ally.message}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

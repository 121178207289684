import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ModalInfo from "./modalInfo";
import PropTypes from "prop-types";
import { Check } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CODES } from "../../../consts/codes";
import CloseIcon from "@mui/icons-material/Close";
import { EditFieldMapping } from "../../../services/user/mapping/mappingServices";
import { BaseInfoAccordionEdit } from "../FieldsMapping/BaseInfoAccordionEdit";
import { FieldsAccordionEdit } from "../FieldsMapping/FieldsAccordionEdit";
import { ModalDecision } from "./modalDecision";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";

const ModalEditFieldMapping = ({
  isOpen,
  setIsOpen,
  editedItem,
  setEditedItem,
  filterData,
}) => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Const
   */
  const typeDocs = {
    sales: t("App.loadMapping.createModal.documentType.sales"),
    stock: t("App.loadMapping.createModal.documentType.stock"),
    sales_stock: t("App.loadMapping.createModal.documentType.saleStock"),
  };

  const countries = { CO: "COLOMBIA", PE: "PERÚ", MX: "MEXICO" };

  /**
   * Title component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Schema
   */

  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({
        country: yup.string().required(t("App.validationMessages.required")),
        countryName: yup
          .string()
          .required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),
    issuers: yup
      .array()
      .of(
        yup.object().shape({
          eanCompany: yup
            .string()
            .required(t("App.validationMessages.required")),
          companyName: yup
            .string()
            .required(t("App.validationMessages.required")),
        })
      )
      .min(1, t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required")),
    receiver: yup
      .object()
      .shape({
        eanCompany: yup.string().required(t("App.validationMessages.required")),
        companyName: yup
          .string()
          .required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),
    documentType: yup
      .object()
      .shape({
        label: yup.string().required(t("App.validationMessages.required")),
        value: yup.string().required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),

    fileType: yup.string().required(t("App.validationMessages.required")),
    delimiter: yup.string().required(t("App.validationMessages.required")),
    dataReading: yup.string().required(t("App.validationMessages.required")),
    mappingFields: yup.array().of(
      yup.object().shape({
        field: yup.string().required(t("App.validationMessages.required")),
        position: yup.string().required(t("App.validationMessages.required")),
        format: yup.string().required(t("App.validationMessages.required")),
      })
    ),
  });

  /**
   * Use Form
   */

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: { countryName: "", country: "" },
      issuers: [],
      receiver: null,
      documentType: null,
      fileType: "",
      delimiter: "",
      dataReading: "",
      storeDetail: "",
      mappingFields: [],
    },
  });

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [titleModalInfo, setTitleModalInfo] = useState("");
  const [contentModalInfo, setContentModalInfo] = useState({});

  const [storedFormData, setStoredFormData] = useState({});
  const [isLoadingMapping, setIsLoadingMapping] = useState(false);

  const [docType, setDocType] = useState("");
  const [isStoreDetail, setIsStoreDetail] = useState(false);
  const [storeDetail, setStoreDetail] = useState("NO");
  const [isCheckEdited, setIsCheckEdited] = useState(false);

  /**
   * Use Effect
   */

  //Settea la información del mapeo a editar
  useEffect(() => {
    if (isOpen) {
      setIsLoadingMapping(true);
      setDocType(editedItem.typeDocument);
      formatInfoDetail(editedItem);
      setTimeout(() => setIsLoadingMapping(false), [1000]);
    }
  }, [editedItem]);

  /**
   * Handles
   */

  /**
   * Función que ejecuta acciones cuando el modal se cierra
   */
  const onClose = () => {
    reset();
    setStoredFormData({});
    setIsCheckEdited(false);
    setIsOpen(false);
  };

  /**
   * Formatea la información del detalle del mappeo para mostrarla en el formulario
   */
  const formatInfoDetail = (currentItem) => {
    setValue("country", {
      country: currentItem.country,
      countryName: countries[currentItem.country],
    });
    setValue("issuers", [
      {
        companyName: editedItem.nameIssuer,
        eanCompany: currentItem.eanIssuer,
      },
    ]);
    setValue("receiver", {
      companyName: currentItem.nameReceiver,
      eanCompany: currentItem.eanReceiver,
    });
    setValue("documentType", {
      label: typeDocs[currentItem.typeDocument],
      value: currentItem.typeDocument,
    });
  };

  /**
   * Función cuando el usuario acepta en el modal decision
   */
  const handleAgree = async () => {
    setIsOpenModalDecision(false);
    await handleEditMapping(storedFormData);
  };

  /**
   * Función cuando el usuario cancela en el modal decision
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
    setIsOpen(false);
  };

  /**
   * Función que recibe los datos del formulario diligenciado y verifica si se puede hacer la creación
   * @param {*} data datos del formulario
   */
  const handleSubmitEditMapping = (data) => {
    setStoredFormData(data);
    setIsOpenModalDecision(true);
  };

  /**
   * Función que convierte el valor del campo storeDetail a string
   */

  const storeDetailStatus = (isStoreDetail) => {
    if (isStoreDetail === true) {
      return "SI";
    } else {
      return "NO";
    }
  };

  /**
   * Función que retorna el título del modal info según el código de respuesta
   * @param {*} responseCode código de respuesta
   * @returns título de modal
   */
  const handleSetTitle = (responseCode) => {
    if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      return t("App.validationMessages.success");
    } else {
      return t("App.validationMessages.error");
    }
  };

  /**
   * Función que crea el mappeo en el sistema
   * @param {} data datos del formulario
   */
  const handleEditMapping = async (data) => {
    try {
      setIsLoadingEdit(true);
      const finalFields = data.mappingFields.map((field) => ({
        field: field.field,
        position: field.position,
        format: field.format,
      }));

      const obj = {
        pk: `document_type#${data.documentType.value}`,
        sk: `country#${data.country.country}#ean_sender#${data.issuers[0].eanCompany}#ean_receiver#${data.receiver.eanCompany}`,
        country: data.country.country,
        eanSender: data.issuers[0].eanCompany,
        sender: data.issuers[0].companyName,
        eanReceiver: data.receiver.eanCompany,
        receiver: data.receiver.companyName,
        documentType: data.documentType.value,
        fileType: data.fileType,
        delimiter: data.delimiter,
        dataReading: data.dataReading,
        storeDetail: storeDetailStatus(isStoreDetail),
        fields: finalFields,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await EditFieldMapping(obj);

      setTitleModalInfo(handleSetTitle(responseCode));

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setContentModalInfo({
          status: status,
          data: {
            responseCode: responseCode,
          },
        });

        //Aplicamos filtro para refrescar la tabla
        filterData();
      } else {
        setContentModalInfo({
          data: {
            responseCode: responseCode,
            responseMessage: responseMessage,
          },
        });
      }
      setIsLoadingEdit(false);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.log(
        "===================== ERROR at modalCreateFieldMapping.jsx ====================="
      );
      console.log(error);
      console.log("==============================");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            alignSelf: "center",
            width: "100%",
            height: "100%",
            maxWidth: "1050px",
            maxHeight:  { 
              sm: "1050px",
              lg: "800px",
            },
            boxShadow: "none",
            borderRadius: "22px",
            padding: {
              xs: '2rem 1rem',
              sm: '2rem 3rem', 
              lg: '2rem 3rem'
            },

          },
        },
      }}
    >
      <DialogTitleContainer onClose={onClose}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one"
              sx={{
                paddingRight: "0 !important",
                xs: {fontSize: "19px !important"}
              }}
          >
            {t("App.loadMapping.createModal.titleEdit")}
          </Grid>
        </Grid>
      </DialogTitleContainer>
      <DialogContent className="no-scroll-table full-width">
        <form
          id="load-mapping-form"
          onSubmit={handleSubmit(handleSubmitEditMapping)}
        >
          {isLoadingMapping ? (
            <Grid container className="column" style={{ marginTop: "20%" }}>
              <CircularProgress
                value={90}
                size={40}
                style={{ color: "#553ab6" }}
              />
            </Grid>
          ) : (
            <Grid container direction="column" rowSpacing={3}>
              <Grid item>
                <BaseInfoAccordionEdit
                  t={t}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  setDocumentType={setDocType}
                  documentType={docType}
                  setIsStoreDetailSelected={setIsStoreDetail}
                  isStoreDetailSelected={isStoreDetail}
                  setIsCheckEdited={setIsCheckEdited}
                  isCheckEdited={isCheckEdited}
                  editedItem={editedItem}
                  isOpen={isOpen}
                />{" "}
              </Grid>
              <Grid item>
                <FieldsAccordionEdit
                  t={t}
                  control={control}
                  errors={errors}
                  reset={reset}
                  getValues={getValues}
                  setValue={setValue}
                  isStoreDetailSelected={isStoreDetail}
                  setIsCheckEdited={setIsCheckEdited}
                  isCheckEdited={isCheckEdited}
                  editedItem={editedItem}
                  setEditedItem={setEditedItem}
                />
              </Grid>
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"flex-end"} columnSpacing={3} 
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' }
          }}
        >
          <Grid item className="mapping-btn__container">
            <Button className="btn__filled__gradient" 
             onClick={onClose}
             sx={{ width: "100%"}}
             >
            {t("App.catalogue.cancel")}
            </Button>
          </Grid>
          <Grid item className="mapping-btn__container">
            <Button
              disabled={isLoadingEdit}
              type="submit"
              form="load-mapping-form"
              startIcon={
                !isLoadingEdit ? <Check sx={{ fontSize: 16 }} /> : <></>
              }
              className="btn__filled__blue"
              sx={{ width: "100%" }}
            >
              {!isLoadingEdit ? (
                t("App.editUser.confirmButton")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <ModalInfo
        title={titleModalInfo}
        responseData={contentModalInfo}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          onClose();
        }}
      />
      <ModalDecision
        title={t("App.loadMapping.createModal.titleDecisionEdit")}
        message={
          <span>
            <p>{t("App.loadMapping.createModal.contentDecisionEdit1")}</p>
            <p>{t("App.loadMapping.createModal.contentDecisionEdit2")}</p>
            <p>{t("App.loadMapping.createModal.contentDecisionEdit3")}</p>
          </span>
        }
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </Dialog>
  );
};

ModalEditFieldMapping.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  editedItem: PropTypes.object.isRequired,
};

export { ModalEditFieldMapping };

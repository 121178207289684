import { Trans } from "react-i18next";

const DownloadGuide = ({ handleDownloadGuide, helpText }) => {
  return (
    <Trans i18nKey={helpText}>
      <p className="subheading-one__text-black-guide" onClick={handleDownloadGuide}>
        <a
          style={{ cursor: "pointer" }}
          className="subheading-one__primary-one linkStyle"
        />
      </p>
    </Trans>
  );
};

export default DownloadGuide;

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetTradersService } from "../../../services/user/deleteAndReload/deleteAndReload";
import moment from "moment";
import ModalInfo from "../Modals/modalInfo";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import {
  GetOCNumbers,
  GetRequestsOC,
} from "../../../services/user/adminPurchaseOrder/PurcharseOrderProviderService";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterRequestsPurchaseOrders = ({
  setHasContent,
  setHasFound,
  setData,
  setIsLoading,
  userInfo,
  handleOpenEdit,
  setHasFilter,
  setOpenErrorModal,
}) => {
  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  /**
   * Schema para la validación del formulario de los filtros
   */
  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({ countryName: yup.string(), country: yup.string() })
      .required(),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use State
   */
  const [countries, setCountries] = useState([]);
  const [commercesList, setCommercesList] = useState([]);
  const [eanCommerces, setEanCommerces] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadingCommerce, setIsLoadingCommerce] = useState(false);
  const [isLoadingEan, setIsLoadingEan] = useState(false);
  const [filterResponse, setFilterResponse] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [ocNumbers, setOcNumbers] = useState([]);
  const [stateFilter, setStateFilter] = useState(true);

  /**
   * useEffect para obtener el listado de países disponibles en la plataforma
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        const geographiesService = await GetGeographiesList();

        const { status, data } = geographiesService;

        if (
          status === CODES.COD_RESPONSE_HTTP_OK &&
          data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          setCountries(data.responseMessage);
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log(
          "==============Error filterAdminPurchaseOrders.jsx useEffect GetGeographies======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
  }, []);

  /**
   * Función para obtener los números de OC por comercio
   */
  const fetchOCNumbers = async (commerce) => {
    try {
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        lstEansTraders: commerce,
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetOCNumbers(obj);
      if (
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST &&
        status === CODES.COD_RESPONSE_SUCCESS
      ) {
        let oclist = responseMessage.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        oclist =
          oclist.length > 1
            ? [{ label: t("App.downloadSalesForce.selectAll") }, ...oclist]
            : oclist;
        setOcNumbers(oclist);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("=========== fetchOCNumbers ===========");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *    * Función que se acciona cuando se cambia el valor del autocomplete de ocNumber para cambiar el valor del campo ocNumber

   * @param {*} event  
   * @param {*} values comercios
   * @param {*} reason tipo de selección; selectOption, removeOption
   * @param {*} detail  opción elegida
   */

  const onCommerceChange = async (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const commerce of values) {
      if (commerce.commerceName === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        setValue("ean", []);
        break;
      }
    }
    setValue("ocNumber", []);

    setValue("ocDate", null);
    setStateFilter(false);

    let sizeCommerce = commercesList.filter(
      (commerce) =>
        commerce.commerceName !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (commerce) =>
        commerce.commerceName !== t("App.downloadSalesForce.selectAll")
    ).length;
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCommerce && reason === "selectOption")
    ) {
      setValue("commerce", commercesList);
    } else if (values.length === commercesList.length - 1) {
      setValue(
        "commerce",
        values.filter(
          (commerce) =>
            commerce.commerceName !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("commerce", values);
    }
    if (getValues("commerce") && getValues("commerce").length === 1) {
      setValue("ean", getValues("commerce")[0].eanCommerce);
    } else {
      setValue("ean", []);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.commerceName === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("commerce", []);
        setStateFilter(true);
      }
    } else if (reason === "clear" && !detail) {
      setStateFilter(true);
    }
    await getOCNumbersByCommerce();
  };

  /**
   * Función para obtener los números de OC por comercio
   */
  const getOCNumbersByCommerce = async () => {
    if (getValues("commerce").length > 0) {
      let commerce = getValues("commerce");

      commerce = commerce.filter(
        (company) =>
          company.commerceName !== t("App.downloadSalesForce.selectAll")
      );
      commerce = commerce.map((commerce) => commerce.eanCommerce);

      await fetchOCNumbers(commerce);
    }
  };

  /**
   *
   * Función que se acciona cuando se cambia el valor del autocomplete de ocNumber para cambiar el valor del campo ocNumber
   *
   * @param {Event} event
   * @param {Values} values ocnumbers
   * @param {String} reason tipo de selección; selectOption, removeOption
   * @param {Object} detail opción elegida
   */
  const onOcNumberChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const ocNumber of values) {
      if (ocNumber.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeTrader = ocNumbers.filter(
      (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeTrader && reason === "selectOption")
    ) {
      setValue("ocNumber", ocNumbers);
    } else if (values.length === ocNumbers.length - 1) {
      setValue(
        "ocNumber",
        values.filter(
          (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("ocNumber", values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("ocNumber", []);
      }
    }
  };

  /**
   *
   * Función para manejar los cambios en los campos de ean y comercio:
   * Cuando se selecciona un ean automáticamente se selecciona el comercio y viceversa
   * Cuando se selecciona más de un comercio se vacía los campos de ean y número OC
   *
   * @param {Object} object
   */
  const handleChangeEanOrCommerce = (object) => {
    const { list, reason, type } = object;

    if (reason === "clear") {
      setValue(type === "commerce" ? "ean" : "commerce", []);

      setValue("ocNumber", []);
    }

    if (list?.length > 0) {
      const eanOrCommerceSelected = list[0]?.eanCommerce || list;

      const eanOrCommerce = commercesList.filter(
        (commerce) => commerce?.eanCommerce === eanOrCommerceSelected
      );

      let eanValue = list.length === 1 ? eanOrCommerce[0]?.eanCommerce : null;
      let commerceValue = list ? eanOrCommerce[0] : null;

      type === "ean"
        ? setValue("commerce", [commerceValue])
        : setValue("ean", eanValue);
    }
  };

  /**
   *
   * Función para filtrar la data por país, comercio, ean comercio, número OC y/o fecha OC
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        country: data.country.country,
        lstEansTraders: data.commerce
          .filter(
            (commerce) =>
              commerce.commerceName !== t("App.downloadSalesForce.selectAll")
          )
          .map((commerce) => commerce.eanCommerce),
        ocDate: data.ocDate ? formatDate(data.ocDate) : null,
        lstOcNumbers: data.ocNumber
          ? data.ocNumber
              .map((item) => item.label)
              .filter(
                (ocNumber) => ocNumber !== t("App.downloadSalesForce.selectAll")
              )
          : null,

        requestDate: data.requestDate ? formatDate(data.requestDate) : null,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetRequestsOC(obj);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setHasFound(true);
            setHasContent(responseMessage.length > 0 ? true : false);
            setData(setDataStructure(responseMessage));
            break;

          case CODES.COD_RESPONSE_ERROR_UPLOAD_FILE:
            setHasContent(true);
            setHasFound(false);
            break;

          default:
            setHasContent(false);
            break;
        }
      }
      setHasFilter(true);
      setIsLoading(false);
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Formatear la fecha
   */
  const formatDate = (date) => {
    let prueba = new Date(date);
    return moment(prueba).format("YYYY-MM-DD");
  };

  /**
   *
   * Función para estructurar la data para la tabla
   *
   * @param {Array} data
   * @returns data formateada
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        requestId: !item.requestId ? " - " : item.requestId,
        requestDate: !item.requestDate ? " - " : item.requestDate,
        eanTrader: !item.eanTrader ? " - " : item.eanTrader,
        trader: !item.trader ? "-" : item.trader,
        ocDate: !item.ocDate ? " - " : item.ocDate,
        ocNumber: !item.ocNumber ? " - " : item.ocNumber,
        dateResponse: !item.dateResponse ? " - " : item.dateResponse,
        requestState: !item.requestState ? " - " : item.requestState,
        states: item.states ? item.states : [],
        seeDetail: (
          <IconButton
            onClick={() => {
              handleOpenEdit(item);
            }}
            component="span"
          >
            <VisibilityOutlinedIcon sx={{ color: "#553AB6", fontSize: 22 }} />
          </IconButton>
        ),
      };
    });

    return processRows;
  };

  /**
   *
   * Función para actualizar el listado de comercios cuando se cambia el país
   *
   * @param {Object} data
   */
  const onCountryChange = async ({ country }) => {
    setValue("ean", []);
    setValue("commerce", []);
    setValue("ocNumber", []);
    setValue("ocDate", null);
    setValue("requestDate", null);

    setIsLoadingEan(true);
    setIsLoadingCommerce(true);

    fetchTraders(country);
  };

  /**
   *
   * Función para obtener los comercios por país
   *
   * @param {String} country
   */
  const fetchTraders = async (country) => {
    try {
      const tradersService = await GetTradersService({ country });

      if (
        tradersService.status === CODES.COD_RESPONSE_HTTP_OK &&
        tradersService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = tradersService.data.responseMessage;

        let traders = responseMessage.map((item) => {
          return {
            commerceName: item.companyName,
            eanCommerce: item.eanCompany,
          };
        });

        let eanTraders = responseMessage.map(
          (eanTrader) => eanTrader.eanCompany
        );

        const completeList =
          traders.length > 0
            ? [
                { commerceName: t("App.downloadSalesForce.selectAll") },
                ...traders,
              ]
            : [];

        setCommercesList(completeList);
        setEanCommerces(eanTraders);
      }

      setIsLoadingEan(false);
      setIsLoadingCommerce(false);
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para limpiar los campos del formulario
   */
  const handleCleanFilters = () => {
    setCommercesList([]);
    setEanCommerces([]);
    setStateFilter(true);
    setHasContent(false);
    setHasFilter(false);
    reset();
  };

  /**
   * Función para actualizar el estado de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect para actualizar el estado de isDesktop basado en el tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * Función para inhabilitar el botón de limpiar filtros
   *
   * @returns true o false
   */
  const isCleanDisabled = () => {
    const fields = watch();

    if (!fields.country) {
      return true;
    }

    return false;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid xs={12} sm={8} lg={9} item className="side-line">
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            spacing={2.5}
            justifyContent={isDesktop ? "flex-start" : "center"}
          >
            <Grid item lg={3.9} sm={6} xs={12}>
              <Controller
                name="country"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    noOptionsText={t("App.listUsers.noOptions")}
                    className={errors.country ? "filters__error" : "filters"}
                    options={countries}
                    getOptionLabel={(option) => option.countryName}
                    isOptionEqualToValue={(option, value) =>
                      option.country === value.country
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          label={t("App.deleteAndReload.filters.country")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                        />
                      );
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.country}>
                        {option.countryName}
                      </li>
                    )}
                    onChange={(event, values, reason) => {
                      onChange(values);

                      if (reason === "clear") {
                        setCommercesList([]);
                        setEanCommerces([]);
                        setValue("ean", []);
                        setValue("commerce", []);
                        setValue("ocNumber", []);
                        setValue("ocDate", null);
                        setValue("requestDate", null);
                      }

                      if (values !== null) {
                        onCountryChange(values);
                      }
                    }}
                    value={value || null}
                  />
                )}
                control={control}
              />
            </Grid>

            <Grid item lg={3.9} sm={6} xs={12}>
              <Controller
                defaultValue=""
                name="commerce"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={
                      watch("country") && !isLoadingCommerce ? false : true
                    }
                    noOptionsText={t("App.validationMessages.noMatchFilter")}
                    className={errors.commerce ? "filters__error" : "filters"}
                    multiple
                    isOptionEqualToValue={(option, value) =>
                      option.commerceName === value.commerceName
                    }
                    id="checkboxes-tags-demo"
                    options={commercesList}
                    value={value || []}
                    disableCloseOnSelect
                    limitTags={1}
                    onChange={onCommerceChange}
                    getOptionLabel={(option) => option.commerceName}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          className="autoComplete__checkbox"
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.commerceName}
                      </li>
                    )}
                    renderTags={(value) => {
                      const numTags = value.length;
                      const limitTags = 1;

                      return (
                        <>
                          {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                              className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                              sx={{
                                width: "50%",
                              }}
                              key={index}
                              size="small"
                              label={option.commerceName}
                            />
                          ))}

                          {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t("App.deleteAndReload.filters.commerce")}
                        sx={{
                          label: {
                            fontFamily: "Roboto !important",
                            fontSize: "14px !important",
                            translate: "0px -5px",
                            transformOrigin: "-45px -10px",
                            "&.Mui-focused": {
                              color: "#8C8D9D",
                            },
                          },
                        }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCommerce ? (
                                <CircularProgress
                                  sx={{ color: "#4DCAFA" }}
                                  size={15}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item lg={3.9} sm={6} xs={12}>
              <Controller
                name="ean"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={
                      watch("country") &&
                      getValues("commerce") &&
                      getValues("commerce").length <= 1 &&
                      !isLoadingEan
                        ? false
                        : true
                    }
                    noOptionsText={t("App.validationMessages.noMatchFilter")}
                    className={errors.ean ? "filters__error" : "filters"}
                    isOptionEqualToValue={(option, value) => option === value}
                    id="checkboxes-tags-demo"
                    options={eanCommerces}
                    value={value || null}
                    // disableCloseOnSelect
                    onChange={(e, values, reason) => {
                      const obj = { list: values, reason, type: "ean" };

                      handleChangeEanOrCommerce(obj);

                      onChange(values);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        label={t("App.businessRules.table.eanCommerce")}
                        sx={{
                          label: {
                            fontFamily: "Roboto !important",
                            fontSize: "14px !important",
                            translate: "0px -5px",
                            transformOrigin: "-45px -10px",
                            "&.Mui-focused": {
                              color: "#8C8D9D",
                            },
                          },
                        }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingEan ? (
                                <CircularProgress
                                  sx={{ color: "#4DCAFA" }}
                                  size={15}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item lg={3.9} sm={6} xs={12}>
              <Controller
                defaultValue=""
                name="ocNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={
                      getValues("country") && getValues("commerce").length > 0
                        ? false
                        : true
                    }
                    noOptionsText={t("App.validationMessages.noMatchFilter")}
                    className={errors.ocNumber ? "filters__error" : "filters"}
                    multiple
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    //
                    id="checkboxes-tags-demo"
                    options={ocNumbers}
                    value={value || []}
                    disableCloseOnSelect
                    limitTags={3}
                    onChange={onOcNumberChange}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          className="autoComplete__checkbox"
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderTags={(value) => {
                      const numTags = value.length;
                      const limitTags = 2;

                      return (
                        <>
                          {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                              className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                              sx={{
                                width: "25%",
                              }}
                              key={index}
                              size="small"
                              label={option.label}
                            />
                          ))}

                          {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t("App.adminPurchaseOrder.table.ocNumber")}
                        sx={{
                          label: {
                            fontFamily: "Roboto !important",
                            fontSize: "14px !important",
                            translate: "0px -5px",
                            transformOrigin: "-45px -10px",
                            "&.Mui-focused": {
                              color: "#8C8D9D",
                            },
                          },
                        }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCommerce ? (
                                <CircularProgress
                                  sx={{ color: "#4DCAFA" }}
                                  size={15}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item lg={3.9} sm={6} xs={12}>
              <Controller
                defaultValue=""
                control={control}
                name="ocDate"
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={
                        watch("commerce")?.length > 0 && watch("country")
                          ? false
                          : true
                      }
                      className={errors.ocDate ? "filters__error" : "filters"}
                      inputFormat="YYYY-MM-DD"
                      label={t("App.adminPurchaseOrder.table.ocDate")}
                      sx={{
                        translate: "0px -5px",
                      }}
                      value={watch("ocDate") ? watch("ocDate") : null}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -4px",
                              transformOrigin: "-45px -10px",
                              color: "#8C8D9D !important",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item lg={3.9} sm={6} xs={12}>
              <Controller
                defaultValue=""
                control={control}
                name="requestDate"
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={
                        watch("commerce")?.length > 0 && watch("country")
                          ? false
                          : true
                      }
                      className={
                        errors.requestDate ? "filters__error" : "filters"
                      }
                      inputFormat="YYYY-MM-DD"
                      label={t("App.requestsPurchaseOrder.table.requestDate")}
                      sx={{
                        translate: "0px -5px",
                      }}
                      value={watch("requestDate") ? watch("requestDate") : null}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -4px",
                              transformOrigin: "-45px -10px",
                              color: "#8C8D9D !important",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid xl={2} lg={3} sm={4} xs={12}item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              disabled={getValues("commerce") ? false : true}
              className={
                getValues("commerce") && getValues("commerce").length > 0
                  ? "btn__applyFilter"
                  : "btn__applyFilter-disabled"
              }
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className={"btn__deleteFilter"}
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <ModalInfo
          responseData={filterResponse}
          open={error}
          onClose={() => setError(false)}
        />
      )}
    </Grid>
  );
};

export default FilterRequestsPurchaseOrders;

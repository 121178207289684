import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { CompaniesSelection } from "../BusinessGroup/companiesSelection";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCountriesAndCompanies,
  getCountriesAndCompanies,
  getCountriesAndCompaniesStatus,
} from "../../../parts/businessGroup/businessGroupSlice";
import { useReducer } from "react";
import { TooltipMainCompany } from "../BusinessGroup/tooltipMainCompany";
import { getUser } from "../../../pages/userSlice";
import {
  EditBusinessGroup,
  ManageBusinessGroups,
} from "../../../services/user/businessGroup";
import { CODES } from "../../../consts/codes";
import { ModalDecision } from "./modalDecision";

import CheckIcon from "@mui/icons-material/Check";
import { REGEXP } from "../../../consts/regexp";

export const ModalEditBusinessGroup = ({
  open,
  setOpen,
  groupInfo,
  setModalResponseCreateInfo,
  setIsOpenModalResponseCreate,
  setModalResponseTitle,
  filters,
  setIsNoFilterFound,
  setData,
  setIsLoadingData,
  setDataStructureArray,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    groupName: yup
      .string()
      .required(t("App.validationMessages.required"))
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    country: yup
      .object()
      .shape({
        country: yup.string().required(t("App.validationMessages.required")),
        countryName: yup
          .string()
          .required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),
    companies: yup.array().of(
      yup.object().shape({
        country: yup
          .object()
          .shape({
            country: yup.string(),
            countryName: yup.string(),
          })
          .typeError(t("App.validationMessages.required")),
        company: yup
          .object()
          .shape({
            eanCompany: yup.string(),
            companyName: yup.string(),
          })
          .typeError(t("App.validationMessages.required")),
        state: yup
          .number()
          .moreThan(-1, t("App.validationMessages.required"))
          .required(t("App.validationMessages.required")),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * Use array field
   */
  const {
    fields: companiesFields,
    append: companiesAppend,
    remove: companiesRemove,
    update: companiesUpdate,
  } = useFieldArray({
    control,
    name: "companies",
  });

  /**
   * Use Selector
   */

  //User selectors
  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  //Countries and companies selectors
  const countriesAndCompanies = useSelector(getCountriesAndCompanies);
  const countriesAndCompaniesStatus = useSelector(
    getCountriesAndCompaniesStatus
  );

  /**
   * Use Reducer
   */

  const initialState = () => [];

  const reducer = (state = initialState(), action = {}) => {
    const nonRepeatedValues = new Set(state);

    let copyOfState = [...nonRepeatedValues];

    switch (action.type) {
      case "select":
        //Selecciona una compañia
        copyOfState.push(action.company);
        return copyOfState;
      case "remove":
        //Elimina una compañía de la lista porque esta se deselecciona
        let arrayWithRemovedCompany = copyOfState.filter(
          (companyOnList) =>
            companyOnList.eanCompany !== action.company.eanCompany
        );
        return arrayWithRemovedCompany;

      case "reset":
        return initialState();
      default:
        return state;
    }
  };

  //Main companies
  const [mainCompanyList, dispatchMainCompanies] = useReducer(
    reducer,
    reducer()
  );

  /**
   * Use State
   */

  //Loading

  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  //Saving data
  const [businessGroupData, setBusinessGroupData] = useState({});

  //Flow modals
  const [isOpenModalDecisionEdit, setIsOpenModalDecisionEdit] = useState(false);

  /**
   * Use Effect
   */

  //When opening the modal
  useEffect(() => {
    reset();
    if (groupInfo) {
      setFormValuesToEdit();
    }
  }, [groupInfo]);

  /**
   * Handles
   */

  /**
   * Closes the Edit modal and resets the form into initial state
   */
  const onClose = () => {
    reset();
    const emptyCompany = {
      country: null,
      company: null,
      state: -1,
      key: uuidv4(),
    };
    companiesAppend(emptyCompany);
    dispatchMainCompanies({ type: "reset" });
    setOpen(false);
  };

  /**
   * Sets the information of current business group into form
   */
  const setFormValuesToEdit = () => {
    setValue("groupName", groupInfo.businessGroupName);
    setValue("country", getCountry(groupInfo.country));

    const companiesFromBG = groupInfo.lstCompanyBusinessGroupDTO;

    for (const company of companiesFromBG) {
      const arrayCompany = {
        country: getCountry(company.country),
        company: {
          eanCompany: company.eanCompany,
          companyName: company.nameCompany,
        },
        state: company.state === "ACTIVO" ? 1 : 0,
        key: uuidv4(),
        disabled: true,
      };
      companiesAppend(arrayCompany);

      if (company.state === "ACTIVO") {
        dispatchMainCompanies({
          type: "select",
          company: arrayCompany.company,
        });
      }
    }

    // Se filtra para encontrar el objeto con la empresa principal
    const mainCompany = companiesFromBG?.filter(
      (company) => company.eanCompany === groupInfo.eanMainCompany
    );

    // Se crea un objeto nuevo para colocar su valor en el autocomplete de empresa principal
    const mainCompanyStructuredObj = mainCompany.map((company) => {
      return {
        companyName: company.nameCompany,
        eanCompany: company.eanCompany,
      };
    });

    setValue(
      "mainCompany",
      groupInfo.eanMainCompany ? mainCompanyStructuredObj[0] : null
    );
  };

  /**
   * Gets country by country code
   * @param {object} country object with country info
   * @returns
   */
  const getCountry = (country) => {
    const foundCountry = countriesAndCompanies.find(
      (countryOnList) => countryOnList.country === country
    );

    return foundCountry !== undefined
      ? foundCountry
      : { country: country, countryName: "" };
  };

  /**
   * Sets title for response modal when Edit request has been sent
   * @param {} responseCode response code of Edit request
   * @returns Title based on responseCode
   */
  const setTitle = (responseCode, groupName) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return t("App.manageBusinessGroup.edit.success");
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return t("App.manageBusinessGroup.edit.error", {
          groupName: groupName,
        });

      default:
        return t("App.validationMessages.error");
    }
  };

  /**
   * Builds companies object required for Edit request object
   * @param {*} fields form fields that contains companies
   * @returns structured object
   */
  const buildCompaniesObject = (fields) => {
    const buildObject = fields.map((field) => {
      return {
        eanCompany: field.company.eanCompany,
        nameCompany: field.company.companyName,
        country: field.country.country,
        state: field.state === 1 ? "ACTIVO" : "INACTIVO",
      };
    });

    return buildObject;
  };

  /**
   *Stores the form data and open decision modal of creation flow
   * when Edit button is pressed
   * @param {*} data form data
   */
  const handleConfirmEditBusinessGroup = (data) => {
    setBusinessGroupData(data);
    setIsOpenModalDecisionEdit(true);
  };

  /**
   * Function when decision is agree
   */
  const handleAgree = () => {
    setIsLoadingEdit(true);
    handleEditBusinessGroup(businessGroupData);
    setIsOpenModalDecisionEdit(false);
  };

  /**
   * Function when decision is disagree
   */
  const handleDisagree = () => {
    setIsOpenModalDecisionEdit(false);
  };

  /**
   * Edits a Business Group
   * @param {object} data business group data
   */
  const handleEditBusinessGroup = async (data) => {
    const lstCompanies = buildCompaniesObject(data.companies);

    let isMainCompanyActive = false;

    for (const company of lstCompanies) {
      if (
        company.eanCompany === data.mainCompany?.eanCompany &&
        company.state === "ACTIVO"
      ) {
        isMainCompanyActive = true;
      }
    }

    const obj = {
      pk: groupInfo.pk,
      sk: groupInfo.sk,
      idGroup: groupInfo.idGroup,
      country: data.country.country,
      businessGroupName: data.groupName,
      eanMainCompany: isMainCompanyActive ? data.mainCompany?.eanCompany : null,
      modificationUser: fullNameUser,
      lstCompanyBusinessGroupDTO: lstCompanies,
    };

    const requestEditBusinessGroup = await EditBusinessGroup(obj);

    const responseCode = requestEditBusinessGroup.data.responseCode;

    setModalResponseTitle(setTitle(responseCode, obj.businessGroupName));

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        await applyFilters(obj.country);
        break;
      default:
        setModalResponseCreateInfo(requestEditBusinessGroup);
        break;
    }

    setIsLoadingEdit(false);
    onClose();
    setIsOpenModalResponseCreate(true);
  };

  /**
   * Apply current filters when group is edited.
   * If filters are not applied, it takes the country of the current group and applies the filter
   * @param {string} country country from current edited group
   */
  const applyFilters = async (country) => {
    let filter =
      filters.lstContries.length > 0 ? filters : { lstContries: [country] };
    const requestAdminGroups = await ManageBusinessGroups(filter);

    let responseCode = requestAdminGroups.data.responseCode;

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        const finalArray = setDataStructureArray(
          requestAdminGroups.data.responseMessage
        );

        if (finalArray.length > 0) {
          setIsNoFilterFound(false);
          setData(finalArray);
        } else {
          setData([]);
          setIsNoFilterFound(true);
        }

        break;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        setData([]);
        setIsNoFilterFound(true);
        break;

      default:
        setData([]);
        setIsNoFilterFound(true);
        break;
    }

    setIsLoadingData(false);
  };

  /**
   *
   * Función para deshabilitar o habilitar el botón de editar en base a si los campos están llenados
   *
   * @returns true or false
   */
  const handleDisabledButtonEdit = () => {
    if (watch("groupName") && watch("country")) {
      return !companiesFields.every(
        (item) => item.company && item.state !== -1 && item.country
      );
    }

    return true;
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            height: "100%",
            maxWidth: "900px",
            maxHeight: "550px",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "2rem",
          },
        },
      }}
    >
      <DialogTitleContainer onClose={onClose}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.manageBusinessGroup.edit.title", {
              groupName: groupInfo ? groupInfo.businessGroupName : "",
            })}
          </Grid>
        </Grid>
      </DialogTitleContainer>

      <DialogContent className="scroll-table">
        <Grid container direction="column" sx={{ paddingTop: "1%" }}>
          <form
            id="hook-business-group"
            onSubmit={handleSubmit(handleConfirmEditBusinessGroup)}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={4}
                rowSpacing={2}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.name")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        control={control}
                        name={"groupName"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.groupName
                                ? "select__filters__errors__sm"
                                : "select__filters__sm"
                            }
                            placeholder={t(
                              "App.manageBusinessGroup.create.name"
                            )}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>

                    {errors && errors.groupName && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors.groupName.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.country")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name={"country"}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled
                            options={countriesAndCompanies}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) => {
                              return option.country == value.country;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.country
                                ? "select__filters__errors__sm"
                                : "select__filters__sm"
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.manageBusinessGroup.create.country"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>

                    {errors && errors.country && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {console.log(errors.country)}
                        {errors.country.message
                          ? errors.country.message
                          : errors.country.country.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <CompaniesSelection
                companiesFields={companiesFields}
                companiesAppend={companiesAppend}
                companiesRemove={companiesRemove}
                companiesUpdate={companiesUpdate}
                groupInfo={groupInfo}
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                countriesAndCompaniesList={countriesAndCompanies}
                countriesAndCompaniesStatus={countriesAndCompaniesStatus}
                dispatchMainCompanies={dispatchMainCompanies}
                getCountry={getCountry}
                isEdit={true}
              />
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={4}
                rowSpacing={2}
              >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.mainCompany")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="mainCompany"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Autocomplete
                            disabled={
                              !companiesFields.some((item) => item.company)
                            }
                            options={mainCompanyList}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) =>
                              option.eanCompany == value.eanCompany
                            }
                            value={value}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.mainCompany
                                ? "select__filters__errors__sm"
                                : "select__filters__sm"
                            }
                            onChange={(e, values) => onChange(values)}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  "App.manageBusinessGroup.create.mainCompany"
                                )}
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  className="col-end"
                  style={{
                    marginTop: "1.5%",
                    marginLeft: "-3%",
                  }}
                >
                  <Grid container direction="column">
                    <Grid item className="col-flex">
                      <TooltipMainCompany />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container columnGap={4} sx={{ padding: "2.5%" }}>
          <Grid item>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              className="btn__filled__gradient"
            >
              {t("App.createUser.cancelButton")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              startIcon={
                !isLoadingEdit ? <CheckIcon sx={{ fontSize: 16 }} /> : <></>
              }
              form="hook-business-group"
              disabled={handleDisabledButtonEdit()}
              className={
                handleDisabledButtonEdit()
                  ? "btn__filled__disabled"
                  : "btn__filled__blue"
              }
            >
              {!isLoadingEdit ? (
                t("App.manageBusinessGroup.edit.confirm")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

      <ModalDecision
        title={t("App.manageBusinessGroup.edit.decisionTitle")}
        message={
          <span>
            <p>{t("App.manageBusinessGroup.edit.decisionMessage1")}</p>
            <br />
            <p>{t("App.manageBusinessGroup.edit.decisionMessage2")}</p>
            <br />
            <p>{t("App.manageBusinessGroup.edit.decisionMessage3")}</p>
          </span>
        }
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecisionEdit}
        isOpen={isOpenModalDecisionEdit}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </Dialog>
  );
};

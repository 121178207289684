import React from "react";
import {
  Grid,
  FormControlLabel,
  Chip,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import MUIDataTable from "mui-datatables";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { fetchUser, getUser, getUserStatus } from "../../../pages/userSlice";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import moment from "moment";
import { useState, useEffect } from "react";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import ModalSeeCompanies from "../../../components/common/AdminUsersClient/modalSeeCompanies";
import UploadFile from "../../../components/common/UploadFile/uploadFile";
import { FilterSalesForce } from "../../../components/common/filterSalesForce";
import ButtonRefresh from "../../../components/common/buttonRefresh";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FilterBusinessGroupUniqueWB from "../../../components/common/Filters/businessGroup/filterUniqueWithoutButton";
import UploadFileBG from "../../../components/common/UploadFile/uploadFileBusinessGroup";
import FileState from "../../../components/common/fileState";
import DownloadTemplate from "../../../components/common/UploadFile/downloadTemplate";
import DownloadGuide from "../../../components/common/UploadFile/downloadGuide";
import {
  DownloadRateErrorLog,
  DownloadRateFile,
  GetRatesGuide,
  GetRatesTemplate,
  UploadRate,
} from "../../../services/user/rateService";
import { CODES } from "../../../consts/codes";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import {
  fetchRateLoad,
  getRateLoad,
  getRateLoadStatus,
  resetRatesLoadState,
} from "../../../parts/rate/rateSlice";
const UploadRateMaster = () => {
  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.rateMaster.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.rateMaster.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.rateMaster.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "companies",
      label: t("App.listUsers.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.rateMaster.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value.fileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadFile(value.fileName, value.pathFileName)
                  }
                >
                  {value.fileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "state",
      label: t("App.rateMaster.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FileState
              value={value}
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "errors",
      label: t("App.rateMaster.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value}
              control={
                value.awsUploadLog ? (
                  <a

                    className="default-text__linkBlue__sm"
                    onClick={(event) => {setTitle(t("App.validationMessages.error2")); setOpenErrorModal(true)}}
                  >
                    ERROR
                  </a>
                ) : (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) =>
                      handleDownloadLog(
                        value.logFileName,
                        value.pathLogFileName
                      )
                    }
                  >
                    {value.logFileName}
                  </a>
                )
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.rateMaster.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.rateMaster.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use State
   */

  //Filtro
  const [expanded, setExpanded] = useState(false);
  //Data de la tabla
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  //Carga de tabla o refrescar
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //Modal respuesta success de carga tarifa
  const [responseLoadRate, setResponseLoadRate] = useState({});
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  const [typeError, setTypeError] = useState(null);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  //Modal respuesta error carga de maestro de tarifas
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");

  //Manejo de comportamientos y datos grupo empresarial
  const [showUpload, setShowUpload] = useState(null);
  const [checkedReplicate, setCheckedReplicate] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);

  //Archivo seleccionado
  const [fileSelected, setFileSelected] = useState(null);
  const [fileSelectedName, setFileSelectedName] = useState("");

  //Sin contenido
  const [noRates, setNoRates] = useState(false);

  //Filtros de la tabla
  const [filterApply, setFilterApply] = useState(null);

  //Tarifas cargados
  const ratesLoadStatus = useSelector(getRateLoadStatus);
  const ratesLoad = useSelector(getRateLoad);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  // Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  //Usuario
  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  /**
   * useEffect que muestra el componente por default si la empresa del usuario
   * no pertece a un grupo empresarial
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [isBusinessGroup]);

  /**
   * useEffect que elimina el archivo previamente cargado si la carga se oculta
   */
  useEffect(() => {
    if (!showUpload) {
      setFileSelected(null);
      setCheckedReplicate(false);
    }
  }, [showUpload]);

  /**
   * useEffect para traer la información del usuario que inició sesión
   */
  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };

    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  /**
   * useEffect para traer la información del maestro cargado y mostrarla en la tabla
   */
  useEffect(() => {
    const getRatesLoad = async () => {
      try {
        if (ratesLoadStatus === "fetch") {
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 19,
          };
          dispatch(fetchRateLoad(obj));
        }
        switch (ratesLoadStatus) {
          case "succeeded":
            setDataRatesLoad();

            break;
          case "loading":
            break;
          case "failed":
            setNoRates(true);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;
          default:
            break;
        }
      } catch (error) {
        setTitle(t("App.validationMessages.error2"))
        setOpenErrorModal(true);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    };

    setLoading(true);

    if (userInfoStatus === "succeeded") {
      getRatesLoad();
    } else {
      setIsLoadingRefresh(false);
    }
  }, [dispatch, ratesLoadStatus, userInfoStatus]);

  /**
   *  Organizar la información cargada para mostrarla en la tabla
   */
  const setDataRatesLoad = () => {
    const finalRates = ratesLoad;
    let processedRows = finalRates.map((rate, i) => {
      return {
        startDate: !rate.starDate
          ? "  "
          : moment(rate.starDate).format("YYYY-MM-DD hh:mm:ss"),
        endDate: !rate.endDate
          ? ""
          : rate.state.toUpperCase() === "CARGA TOTAL" ||
            rate.state.toUpperCase() === "CARGA PARCIAL" ||
            rate.state.toUpperCase() === "RECHAZADO POR ESTRUCTURA" ||
            rate.state.toUpperCase() === "ERROR SISTEMA"
          ? moment(rate.endDate).format("YYYY-MM-DD hh:mm:ss")
          : "",
        user: !rate.userLoad ? "" : rate.userLoad,
        companies:
          rate.lstCompanies?.length > 1 ? (
            <ModalSeeCompanies
              data={rate.lstCompanies}
              userName={rate.userLoad}
            />
          ) : (
            rate.lstCompanies !== null &&
            rate.lstCompanies.length > 0 &&
            rate.lstCompanies[0].renameCompany
          ),
        file: !rate.fileName
          ? " "
          : {
              fileName: rate.fileName,
              pathFileName: rate.pathFileName,
            },
        state: !rate.state ? " " : rate.state,
        errors: rate.awsUploadLog
          ? {
              awsUploadLog: rate.awsUploadLog,
            }
          : {
              logFileName: rate.logFileName,
              pathLogFileName: rate.pathLogFileName,
            },
      };
    });
    setNoRates(processedRows.length > 0 ? false : true);
    setUnfilteredData(processedRows);
    setData(processedRows);
    setLoading(false);
    setIsLoadingRefresh(false);
  };

  /**
   *
   * Función para descargar el archivo de tarifa que fue cargado (subido)
   * Solo si el servicio responde correctamente se descarga el archivo
   * caso contrario no descargará nada
   *
   * @param {string} fileName: nombre del archivo
   * @param {string} pathFileName : ruta del archivo
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      pathFileName: pathFileName,
      fileName: fileName,
    };

    try {
      const fileService = await DownloadRateFile(obj);
      if (
        fileService.status === CODES.COD_RESPONSE_HTTP_OK &&
        fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        fileDownload(
          decode(fileService.data.data.fileContent),
          fileService.data.data.fileName
        );
        
      } else {
        setTitle(t("App.validationMessages.warning2"));
        setTypeError(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
        setMessage(fileService.data.responseMessage);
        setOpenSuccessLoadModal(true);
      }
    } catch (error) {
      setTitle(t("App.validationMessages.error2"))
      setOpenErrorModal(true);
    }    
  };

  /**
   *
   * Función para descargar el archivo con los errores del archivo cargado (subido)
   * si el servicio responde correctamente se descarga el
   * archivo, caso contrario no descargará nada
   * @param {string} logFileName: nombre del archivo
   * @param {string} pathLogFileName: ruta del archivo
   *
   */
  const handleDownloadLog = async (logFileName, pathLogFileName) => {
    const obj = {
      pathLogFileName: pathLogFileName,
      logFileName: logFileName,
    };

    try {
      const logService = await DownloadRateErrorLog(obj);

      if (logService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (logService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(logService.data.data.fileContent),
            logService.data.data.fileName
          );
        }
      } else {
        setTitle(t("App.validationMessages.warning"));
        setTypeError(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
        setMessage(logService.data.responseMessage);
        setOpenSuccessLoadModal(true);
      }
    } catch (error) {
      setTitle(t("App.validationMessages.error2"))
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar la plantilla (archivo con la estructura adecuada para la carga)
   *     Si el servicio responde correctamente se descarga la plantilla
   *    caso contrario no descargará nada
   * @param {*} event: evento click del botón descargar plantilla
   *
   */
  const handleDownloadTemplate = async (event) => {
    try {
      const templateService = await GetRatesTemplate();

      if (templateService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          templateService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(templateService.data.responseMessage.fileContent),
            templateService.data.responseMessage.fileName
          );
        }
      }
    } catch (error) {
      setTitle(t("App.validationMessages.error2"))
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar la guía de cómo llenar la plantilla
   *    Si el servicio responde correctamente se descarga la guía
   *   caso contrario no descargará nada
   * @param {*} event: evento click del botón descargar guía
   *
   */
  const handleDownloadGuide = async (event) => {
    event.preventDefault();
    try {
      const guideService = await GetRatesGuide();

      if (guideService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          guideService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(guideService.data.responseMessage.fileContent),
            guideService.data.responseMessage.fileName
          );
        }
      }
    } catch (error) {
      setTitle(t("App.validationMessages.error2"))
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para cargar el archivo de tarifas en la plataforma
   *
   */
  const handleUploadFile = async () => {
    try {
      const obj = {
        file: fileSelected[0],
        companies: companySelected,
        checkReplicateBusinessGroup: isBusinessGroup
          ? checkedReplicate
            ? 1
            : 0
          : 0,
      }; 
      const rateService = await UploadRate(obj);
      setCheckedReplicate(false);
      setFileSelectedName(obj.file.name);

  
      if (rateService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if(rateService.data.responseCode === CODES.COD_RESPONSE_ERROR_UPLOAD_FILE){
          setMessage(rateService.data.responseMessage);
          setTitle(t("App.rateMaster.titleUpload2"));
          setTypeError(CODES.COD_RESPONSE_ERROR_UPLOAD_FILE);
          setOpenErrorModal(true);
        }else{
          dispatch(resetRatesLoadState());
          setFileSelected(null);
          setTitle(t("App.rateMaster.titleUpload"));
          setMessage(rateService.data.responseMessage);
          setTypeError(CODES.COD_RESPONSE_HTTP_OK);
          setOpenSuccessLoadModal(true);
        }
      } else {
        setMessage(rateService.data.responseMessage);
        setTitle(t("App.validationMessages.warning"));
        setTypeError(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
        setOpenErrorModal(true);
      }
    } catch (error) {
      setTitle(t("App.validationMessages.error2"))
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para refrescar la información mostrada en la tabla
   */
  const handleRefresh = () => {
    try {
      setIsLoadingRefresh(true);
      setLoading(true);
      setData([]);
      dispatch(resetRatesLoadState());
    } catch (error) {
      setTitle(t("App.validationMessages.error2"))
      setOpenErrorModal(true);
    }

    // TODO: Si el servicio de refresh falla mostrar un modal con el error y mostrar componente no found en vez de la tabla
  };

  return (
    <Grid container direction="column" className="generalcat-container">
      <Grid
        container
        direction="row"
        className="u-marginB template-container__general"
        spacing={1}
        justifyContent={"flex-end"}
      >
        <DownloadTemplate
          helpText={t("App.rateMaster.templateText")}
          buttonText={t("App.rateMaster.templateDownload")}
          handleDownloadTemplate={handleDownloadTemplate}
        />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Grid
          container
          className="grid__container"
          style={{ marginTop: "0.2%", marginBottom: "1%", padding: "0" }}
          direction="column"
        >
          <Grid item className="guide-file">
            <DownloadGuide
              helpText={"App.rateMaster.guide"}
              handleDownloadGuide={handleDownloadGuide}
            />
          </Grid>

          {isBusinessGroup && (
            <Grid item>
              <FilterBusinessGroupUniqueWB
                title={t("App.manageBusinessGroup.filter.title4")}
                setCompanySelected={setCompanySelected}
                setShowUpload={setShowUpload}
              />
            </Grid>
          )}

          <Grid container className="upload-file">
            {isBusinessGroup ? (
              showUpload && (
                <UploadFileBG
                  dragText={t("App.rateMaster.dragging")}
                  handleUploadFile={handleUploadFile}
                  fileSelected={fileSelected}
                  setFileSelected={setFileSelected}
                  setErrorModal={setIsOpenErrorModal}
                  setErrorTitle={setErrorTitle}
                  userInfo={userInfo}
                  showUpload={showUpload}
                  setCheckedReplicate={setCheckedReplicate}
                />
              )
            ) : (
              <UploadFile
                dragText={t("App.rateMaster.dragging")}
                handleUploadFile={handleUploadFile}
                fileSelected={fileSelected}
                setFileSelected={setFileSelected}
                setErrorModal={setIsOpenErrorModal}
                setErrorTitle={setErrorTitle}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!noRates && (
        <Grid
          container
          direction="row"
          className="u-marginT template-container__general"
          justifyContent={"flex-end"}
          textAlign={"end"}
          style={{
            paddingRight: "1%",
          }}
        >
          <ButtonRefresh
            handleRefresh={handleRefresh}
            helpText={t("App.rateMaster.refresh")}
            isLoadingRefresh={isLoadingRefresh}
            buttonText={t("App.rateMaster.update")}
          />
        </Grid>
      )}

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="table-title"
      >
        <Grid item className="col-flex">
          <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
        </Grid>

        <Grid item className="col-start">
          <p className="display-small__primary-one">
            {t("App.salesForce.subtitle")}
          </p>
        </Grid>
      </Grid>

      {!noRates && (
        <Grid
          item
          className="u-marginB grid__container__fullW"
          container
          style={{ marginTop: "2%" }}
        >
          <Accordion
            onChange={() => {
              setExpanded(!expanded);
            }}
            className={
              expanded ? "accordion__filter__expanded" : "accordion__filter"
            }
            style={{ width: "100%" }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.rateMaster.filterName")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterSalesForce
                userInfo={userInfo}
                unfilteredData={unfilteredData}
                setData={setData}
                setFilterApply={setFilterApply}
                idFile={19}
                setIsLoadingData={setIsLoadingData}
                setFilterNotFound={setFilterNotFound}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      <Grid
        item
        className="u-marginB chips-container"
        style={{ width: "100%" }}
      >
        {filterApply && !noRates && (
          <Chip className="chip__filter" label={filterApply.label} />
        )}
      </Grid>
      {!noRates ? (
        <Grid item style={{ width: "100%" }}>
          {loading ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce"
            />
          ) : !filterNotFound ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={options}
              className="dataTable__salesForce"
            />
          ) : (
            <NoMatchImage />
          )}
        </Grid>
      ) : (
        <Grid container className="col-flex">
          <NoInfoComponent type="load" />
        </Grid>
      )}

      {/* Modal para éxito de carga de tarifas */}
      <ModalInfo
        title={title}
        responseData={{
          status: typeError,
          data: {
            responseCode: typeError,
            responseMessage: message,
          },
        }}
        open={openSuccessLoadModal}
        onClose={() => {
          setOpenSuccessLoadModal(false);
        }}
      />

      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={title}
        responseData={{
          status: 0,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError3"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
      {/* Modal para validaciones de archivo */}
      <ModalInfo
          title={errorTitle}
          responseData={{ status: 0 }}
          open={isOpenErrorModal}
          onClose={() => {
            setIsOpenErrorModal(false);
          }}
        />
    </Grid>
  );
};

export default UploadRateMaster;

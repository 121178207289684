import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadGuide from "../../../components/common/UploadFile/downloadGuide";
import DownloadTemplate from "../../../components/common/UploadFile/downloadTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import LoopIcon from "@mui/icons-material/Loop";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { FilterSalesForce } from "../../../components/common/filterSalesForce";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../userSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import MUIDataTable from "mui-datatables";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { CODES } from "../../../consts/codes";
import {
  GetStoresGuide,
  DownloadStoresErrorLog,
  GetStoresTemplate,
  UploadStores,
  DownloadStoresFile,
} from "../../../services/user/stores/storesService";
import {
  fetchStores,
  getStores,
  getStoresStatus,
  resetStoresState,
} from "../../../parts/stores/storesSlice";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../components/common/fileState";
import UploadFile from "../../../components/common/UploadFile/uploadFile";

const StoresUpload = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Table
   */

  /**Table */

  const columns = [
    {
      name: "startDate",
      label: t("App.catalogue.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.catalogue.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.catalogue.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.catalogue.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value.fileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadFile(value.fileName, value.pathFileName)
                  }
                >
                  {value.fileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FileState
              value={value}
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "errors",
      label: t("App.catalogue.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value}
              control={
                value.awsUploadLog ? (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) => setOpenErrorModal(true)}
                  >
                    ERROR
                  </a>
                ) : (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) =>
                      handleDownloadLog(
                        value.logFileName,
                        value.pathLogFileName
                      )
                    }
                  >
                    {value.logFileName}
                  </a>
                )
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
  ];
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  const stores = useSelector(getStores);
  const storesStatus = useSelector(getStoresStatus);

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use State
   */

  //Upload file
  const [fileSelected, setFileSelected] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [responseLoadStores, setResponseLoadStores] = useState({});
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  //Data management
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterApply, setFilterApply] = useState(null);

  //No content
  const [noStores, setNoStores] = useState(false);

  //Loadings
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);

  //Refresh function
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const getStoresList = async () => {
      try {
        const obj = {
          country: userInfo.company.country,
          eanProvider: userInfo.company.eanCompany,
          emailLoad: userInfo.email,
        };
        if (storesStatus === "fetch") {
          dispatch(fetchStores(obj));
        }

        switch (storesStatus) {
          case "succeeded":
            const finalStores = stores;

            let processedRows = finalStores.map((store, i) => {
              return {
                startDate: !store.starDate
                  ? "  "
                  : moment(store.starDate).format("YYYY-MM-DD hh:mm"),
                endDate: !store.endDate
                  ? "  "
                  : moment(store.endDate).format("YYYY-MM-DD hh:mm"),
                user: !store.userLoad ? "" : store.userLoad,
                file: !store.fileName
                  ? " "
                  : {
                      fileName: store.fileName,
                      pathFileName: store.pathFileName,
                    },
                state: !store.state ? " " : store.state,
                errors: store.awsUploadLog
                  ? {
                      awsUploadLog: store.awsUploadLog,
                    }
                  : {
                      logFileName: store.logFileName,
                      pathLogFileName: store.pathLogFileName,
                    },
              };
            });

            setNoStores(processedRows.length > 0 ? false : true);
            setUnfilteredData(processedRows);
            setData(processedRows);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;

          case "loading":
            break;

          case "failed":
            setNoStores(true);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;

          default:
            break;
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    };
    setLoading(true);
    if (userInfoStatus === "succeeded") {
      getStoresList();
    } else {
      setIsLoadingRefresh(false);
    }
  }, [dispatch, storesStatus, userInfoStatus]);

  /**
   * Handles
   */

  //Table downloads
  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      pathFileName: pathFileName,
      fileName: fileName,
    };
    try {
      const fileService = await DownloadStoresFile(obj);

      if (
        fileService.status === CODES.COD_RESPONSE_HTTP_OK &&
        fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(fileService.data.data.fileContent),
          fileService.data.data.fileName
        );
      }
    } catch (error) {
      console.log(
        "============= Error storesUpload.jsx handleDownloadFile =========================="
      );
      console.log(error);
      console.log(
        "=================================================================================="
      );
    }
  };

  const handleDownloadLog = async (logFileName, pathLogFileName) => {
    const obj = {
      pathLogFileName: pathLogFileName,

      logFileName: logFileName,
    };

    try {
      const logService = await DownloadStoresErrorLog(obj);

      if (
        logService.status === CODES.COD_RESPONSE_HTTP_OK &&
        logService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        fileDownload(
          decode(logService.data.data.fileContent),
          logService.data.data.fileName
        );
      }
    } catch (error) {
      console.log(
        "============= Error storesUpload.jsx handleDownloadLog ==========================="
      );
      console.log(error);
      console.log(
        "=================================================================================="
      );
    }
  };

  /**
   * Función permite la descarga del archivo plantilla de puntos de venta
   * @param {*} event
   */
  const handleDownloadTemplate = async (event) => {
    event.preventDefault();

    try {
      const templateService = await GetStoresTemplate();

      if (
        templateService.status === CODES.COD_RESPONSE_HTTP_OK &&
        templateService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(templateService.data.data.fileContent),
          templateService.data.data.fileName
        );
      }
    } catch (error) {
      console.log(
        "============= Error storesUpload.jsx handleDownloadTemplate ======================="
      );
      console.log(error);
      console.log(
        "=================================================================================="
      );
    }
  };

  /**
   * Función que permite descargar la guía de puntos de venta
   * @param {*} event
   */
  const handleDownloadGuide = async (event) => {
    event.preventDefault();

    try {
      const guideService = await GetStoresGuide();

      if (
        guideService.status === CODES.COD_RESPONSE_HTTP_OK &&
        guideService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(guideService.data.data.fileContent),
          guideService.data.data.fileName
        );
      }
    } catch (error) {
      console.log(
        "=============== Error storesUpload.jsx handleDownloadGuide ====================="
      );
      console.log(error);
      console.log(
        "==============================================================================="
      );
    }
  };

  //Upload file
  const handleUploadFile = async () => {
    try {
      const obj = {
        file: fileSelected[0],
        companies: null,
        checkReplicateBusinessGroup: null,
      };
      const storesService = await UploadStores(obj);

      if (storesService.status === CODES.COD_RESPONSE_HTTP_OK) {
        dispatch(resetStoresState());
        setFileSelected(null);
        setResponseLoadStores(storesService);
        setOpenSuccessLoadModal(true);
      }
    } catch (error) {
      console.log(
        "=============== Error storesUpload.jsx handleUploadFile ====================="
      );
      console.log(error);
      console.log(
        "==============================================================================="
      );
    }
  };

  //Refresh
  const handleRefresh = () => {
    setIsLoadingRefresh(true);
    setLoading(true);
    setData([]);
    dispatch(resetStoresState());
  };

  return (
    <Grid container direction="column" className="generalcat-container">
      {" "}
      <Grid
        container
        direction="row"
        className="u-marginB template-container__general"
        spacing={2}
      >
        <DownloadTemplate
          helpText={t("App.storesUpload.template")}
          buttonText={t("App.storesUpload.templateButton")}
          handleDownloadTemplate={handleDownloadTemplate}
        />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Grid
          container
          className="grid__container"
          style={{ marginTop: "0.2%", marginBottom: "1%", padding: "0" }}
          direction="column"
        >
          <Grid item className="guide-file">
            <DownloadGuide
              helpText={"App.salesForce.guide"}
              handleDownloadGuide={handleDownloadGuide}
            />
          </Grid>
          <Grid item className="upload-file">
            <UploadFile
              dragText={t("App.storesUpload.dragging")}
              handleUploadFile={handleUploadFile}
              fileSelected={fileSelected}
              setFileSelected={setFileSelected}
              setErrorModal={setIsOpenErrorModal}
              setErrorTitle={setErrorTitle}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" className="prev-refresh-container">
        {!noStores && (
          <Button
            disableRipple
            disabled={isLoadingRefresh ? true : false}
            startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
            className="refresh-other"
            onClick={handleRefresh}
          >
            {!isLoadingRefresh ? (
              t("App.catalogue.update")
            ) : (
              <CircularProgress size={23} color="inherit" />
            )}
          </Button>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className="table-title"
      >
        <Grid item className="col-flex">
          <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
        </Grid>
        <Grid item className="col-start">
          <p className="display-small__primary-one">
            {t("App.salesForce.subtitle")}
          </p>
        </Grid>
      </Grid>
      {!noStores && (
        <Grid
          item
          className="u-marginB grid__container__fullW"
          container
          style={{ marginTop: "2%" }}
        >
          <Accordion className="accordion__filter" style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />
              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.catalogue.filterName")}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <FilterSalesForce
                userInfo={userInfo}
                unfilteredData={unfilteredData}
                setData={setData}
                setFilterApply={setFilterApply}
                idFile={3}
                setIsLoadingData={setIsLoadingData}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      <Grid
        item
        className="u-marginB chips-container"
        style={{ width: "100%" }}
      >
        {filterApply && !noStores && (
          <Chip className="chip__filter" label={filterApply.label} />
        )}
      </Grid>
      {!noStores ? (
        <Grid item style={{ width: "100%" }}>
          {loading ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce"
            />
          ) : (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={options}
              className="dataTable__salesForce"
            />
          )}
        </Grid>
      ) : (
        <Grid container className="col-flex">
          <NoInfoComponent type="load" />
        </Grid>
      )}
      <ModalInfo
        title={t("App.storesUpload.title")}
        responseData={responseLoadStores}
        open={openSuccessLoadModal}
        onClose={() => {
          setOpenSuccessLoadModal(false);
        }}
      />
      <ModalInfo
        title={t("App.states.systemError")}
        responseData={{
          data: {
            responseCode: 0,
            responseMessage: t("App.validationMessages.systemError"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
      <ModalInfo
        title={errorTitle}
        responseData={{ status: 0 }}
        open={isOpenErrorModal}
        onClose={() => {
          setIsOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default StoresUpload;

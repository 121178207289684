import React from "react";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { useTranslation } from "react-i18next";
import { CODES } from "../../consts/codes";

const ModalFailService = ({ open, onClose }) => {
  /**
   * UseTranslation
   */

  const { t } = useTranslation();
  return (
    <ModalInfo
      title={t("App.validationMessages.error")}
      responseData={{
        status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
        data: {
          responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
          responseMessage: t("App.validationMessages.systemError2"),
        },
      }}
      open={open}
      onClose={() => {
        onClose(false);
      }}
    />
  );
};

export default ModalFailService;

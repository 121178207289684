import { React, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "12px",
  bgcolor: "white",
  padding: "1rem !important",
  p: 4,
};
export const ModalExceededLimit = (props) => {
  const handleClose = () => props.setOpen(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (props.actives && props.company) {
      const totalAvailables = props.company.userLimit - props.actives;
      if (totalAvailables < 0) {
        props.setOpen(true);
      }
    }
  }, [props.actives, props.company]);

  return (
    <div>
      <Modal open={props.open} onClose={handleClose}>
        <Box sx={style}>
          <>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item container justifyContent="flex-end">
                <IconButton onClick={handleClose} component="span">
                  <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <ErrorOutlineOutlinedIcon
                  sx={{
                    color: "#E59500",
                    fontSize: "4rem",
                  }}
                />
              </Grid>
              <Grid item>
                <p className="heading-secondary__md">
                  {" "}
                  {t("App.validationMessages.exceededLimitTitle")}
                </p>
              </Grid>
              <Grid item>
                <p className="default-text_black">
                  {t("App.validationMessages.exceededLimit")}
                </p>
              </Grid>

              <Grid
                item
                container
                style={{ paddingTop: "1rem" }}
                justifyContent="flex-end"
              >
                <Button
                  type="button"
                  disableRipple
                  className="label-Primary__uploadButton"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  {t("App.buttonLabels.accept")}
                </Button>
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
    </div>
  );
};

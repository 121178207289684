import * as yup from "yup";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApplyFiltersProduct } from "../../../services/admin/adminServices";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import {
  FilterRoles,
  GetRolesClientByProduct,
} from "../../../services/user/consultRolesService";

export const FilterConsultRoles = ({
  setDataStructureArray,
  setIsNoContent,
  unfilteredData,
  setData,
}) => {
  const [activeProductsList, setActiveProductsList] = useState([]);
  const [loading, isLoading] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 476);
  const [roleList, setRoleList] = useState([]);

  const userInfo = useSelector(getUser);

  let country = "";
  let eanCompany = "";
  if (userInfo) {
    country = userInfo.company.country;
    eanCompany = userInfo.company.eanCompany;
  }

  /**
   * Schema to the create product form
   */
  const schema = yup.object().shape({
    // product: yup.string(),
    // role: yup.string(),
  });

  /**
   * useForm to resolver of the schema validation
   */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * useEffect to set the data needed for the activeProductsList state for the filter of products
   */
  useEffect(() => {
    async function handleProducts() {
        try {
            const obj = {
                country,
                eanCompany,
            };

            isLoading(true);

            const productService = await ApplyFiltersProduct(obj);

            if (productService.status === CODES.COD_RESPONSE_HTTP_OK) {
                const response = productService.data.responseMessage;
                let activeProductsList = response.
                filter((product) => product.state === 1)
                .map((item) => ({
                    menuOptionName: item.menuOptionName,
                    idMenuOption: item.idMenuOption,
                }));

                // Ordenar alfabéticamente por 'menuOptionName'
                activeProductsList.sort((a, b) => a.menuOptionName.localeCompare(b.menuOptionName));

                console.log(activeProductsList);
                setActiveProductsList(activeProductsList);
            }

            isLoading(false);
        } catch (error) {
            console.log("====================================");
            console.log(error);
            console.log("====================================");
        }
    }

    async function handleRoles() {
        try {
            const obj = {
                eanCompany: userInfo.company.eanCompany,
                country: userInfo.company.country,
            };

            const roleService = await GetRolesClientByProduct(obj);

            if (
                roleService.status === CODES.COD_RESPONSE_HTTP_OK &&
                roleService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
                const responseMessage = roleService.data.responseMessage;
                let rolesList = responseMessage.map((role) => ({
                    nameRole: role.nameRole,
                    idRole: role.idRole,
                }));

                // Ordenar alfabéticamente por 'nameRole'
                rolesList.sort((a, b) => a.nameRole.localeCompare(b.nameRole));

                setRoleList(rolesList);
            }
        } catch (error) {
            console.log("====================================");
            console.log(error);
            console.log("====================================");
        }
    }

    handleProducts();
    handleRoles();
}, [userInfo.company]);

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect to update the size of the window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * @param {Object} data
   *
   * Method to filter the data of the table by product and/or role
   */
  const handleFilters = async (data) => {
    try {
      const obj = {
        pk: data.role ? `id_role#${data.role.idRole}` : "",
        sk: data.role ? "root" : "",
        idMenuOption: data.product ? data.product.idMenuOption : "",
      };

      setData([]);

      const roleService = await FilterRoles(obj);

      // console.log(roleService);

      if (roleService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          roleService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = roleService.data.responseMessage;
          // console.log(responseMessage);

          const formated = setDataStructureArray(responseMessage);

          setData(formated);

          setIsNoContent(false);
        } else {
          setIsNoContent(true);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Method to clear the filters and reset the data of the table
   */
  const handleCleanFilters = () => {
    reset();
    setData(unfilteredData);
    setIsNoContent(false);
  };

  return (
    <Accordion defaultExpanded={true} expanded={true} disableGutters>
      <Grid item className="">
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.catalogue.filterName")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails>
        <Grid
          container
          justifyContent={isDesktop ? "flex-start" : "center"}
          alignItems="center"
        >
          <Grid md={8} sm={8} item className="side-line">
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.product")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="product"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.product
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={activeProductsList}
                            getOptionLabel={(option) => option.menuOptionName}
                            // TODO: solve this issue
                            isOptionEqualToValue={(option, value) =>
                              option.idMenuOption === value.idMenuOption
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option.idMenuOption}>
                                {option.menuOptionName}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  placeholder={t("App.listUsers.product")}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              );
                            }}
                            onChange={(event, values) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.userDetail.role")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="role"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.role
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={roleList}
                            getOptionLabel={(option) => option.nameRole}
                            isOptionEqualToValue={(option, value) =>
                              option.pk === value.pk
                            }
                            renderOption={(props, option) => (
                              <li {...props} key={option.pk}>
                                {option.nameRole}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.userDetail.role")}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid xs={4} item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              className="confirmation-buttons"
            >
              <Grid item>
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  disabled={watch("product") || watch("role") ? false : true}
                  className={
                    watch("product") || watch("role")
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                  type="submit"
                  form="hook-form"
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  className="btn__deleteFilter"
                  onClick={handleCleanFilters}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

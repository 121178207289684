import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import React, { useEffect, useState } from "react";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import { CODES } from "../../../consts/codes";
import {
  GetBusinessGroupByCountries,
  GetCompaniesByCountries,
  ManageBusinessGroups,
} from "../../../services/user/businessGroup";
import { Controller, useForm } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const FilterAdminBusinessGroup = ({
  setData,
  setIsNoFilterFound,
  setNoContent,
  setIsLoadingData,
  setFilters,
  setDataStructureArray,
}) => {
  /**
   * Const
   */

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Form
   */

  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({});

  /**
   * Use State
   */

  //List
  const [countryList, setCountryList] = useState([]);
  const [businessGroupList, setBusinessGroupList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [unfilteredCompanies, setUnfilteredCompanies] = useState([]);
  const [eanList, setEanList] = useState([]);

  //Country selected
  const [isCountrySelected, setIsCountrySelected] = useState(false);

  //Fields selected limit
  const [isBusinessGroupLimit, setIsBusinessGroupLimit] = useState(false);
  const [isCompanyLimit, setIsCompanyLimit] = useState(false);
  const [isEANLimit, setIsEANLimit] = useState(false);

  //Loading fields
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);

  /**
   * Use Effect
   */

  /**
   * Fetchs countries data
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCountries(true);
      const getCountriesToSet = await getCountries();
      setCountryList(getCountriesToSet);

      setIsLoadingCountries(false);
    };

    fetchData();
  }, []);

  /**
   * Request
   */

  /**
   * Request to get countries
   * @returns countries from platform
   */
  const getCountries = async () => {
    const requestCountries = await GetGeographiesList();

    const responseCode = requestCountries.data.responseCode;

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS:
        return requestCountries.data.responseMessage;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];

      default:
        return [];
    }
  };

  /**
   * Request to get the businessgroup based on a list of countries
   * @param {array} countries list of countries
   * @returns business groups from list of countries
   */
  const getBusinessGroupByCountries = async (countries) => {
    const requestBusinessGroups = await GetBusinessGroupByCountries({
      lstContries: countries,
    });

    const responseCode = requestBusinessGroups.data.responseCode;

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return requestBusinessGroups.data.responseMessage;
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];
      default:
        return [];
    }
  };

  /**
   * Request to get companies from countries
   * @param {*} countries list of countries
   * @returns list of companies from list of countries
   */
  const getCompaniesByCountries = async (countries) => {
    const requestCompaniesByCountries = await GetCompaniesByCountries({
      lstContries: countries,
    });

    const responseCode = requestCompaniesByCountries.data.responseCode;
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return requestCompaniesByCountries.data.responseMessage;
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];
      default:
        return [];
    }
  };

  /**
   * Handles
   */

  /**
   * Adds the Select all option to the array based on the type of array
   * @param {array} arrayToSet array to set the option
   * @param {string} type type of array: business-group or companies
   * @returns array with select value
   */
  const setSelectAll = (arrayToSet, type) => {
    let finalArray = [];

    if (type == "business-group") {
      finalArray =
        arrayToSet.length > 0
          ? [
              {
                businessGroupName: t("App.downloadSalesForce.selectAll"),
                groupId: t("App.downloadSalesForce.selectAll"),
              },
              ...arrayToSet,
            ]
          : arrayToSet;
    } else {
      finalArray =
        arrayToSet.length > 0
          ? [
              {
                provider: t("App.downloadSalesForce.selectAll"),
                eanProvider: t("App.downloadSalesForce.selectAll"),
              },
              ...arrayToSet,
            ]
          : arrayToSet;
    }

    return finalArray;
  };

  /**
   * Handles the actions when select all option is clicked or array has similar behavior to select all option
   * @param {array} list list of selected options
   * @param {*} reason type of selection: selectOption, removeOption
   * @param {*} detail option that has been clicked
   * @param {*} type type of array: businessGroup, companies, eanCompanies
   * @returns true if select All has been clicked or options had similar behavior, false if select All option was not clicked
   */
  const handleSelectAllSelection = (list, reason, detail, type) => {
    //Casos de select

    let includeSelectAll = false;

    //Reviso si la opción cliqueada es selectAll y fue deseleccionada
    if (detail) {
      const option = detail.option;
      const typeOfElement =
        type === "businessGroups" ? option.groupId : option.provider;

      if (
        reason === "removeOption" &&
        typeOfElement === t("App.downloadSalesForce.selectAll")
      ) {
        setValue(type, []);

        switch (type) {
          case "businessGroups":
            setIsBusinessGroupLimit(false);
            setCompaniesList(unfilteredCompanies);
            setEanList(unfilteredCompanies);
            break;
          case "companies":
            setIsCompanyLimit(false);
            setCompaniesList(unfilteredCompanies);
            setEanList(unfilteredCompanies);
            break;
          case "eanCompanies":
            setIsEANLimit(false);
            setCompaniesList(unfilteredCompanies);
            setEanList(unfilteredCompanies);
            break;
          default:
            break;
        }
        return true;
      }
    }

    //Reviso si el select all fue seleccionado
    for (const element of list) {
      const typeOfElement =
        type === "businessGroups" ? element.groupId : element.provider;

      if (typeOfElement === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    const originalList =
      type === "businessGroups"
        ? businessGroupList
        : type === "companies"
        ? companiesList
        : eanList;

    //Reviso si la opción select all fue seleccionada o
    //la lista de opciones seleccionadas fueron todas
    if (
      (includeSelectAll && reason === "selectOption") ||
      (!includeSelectAll && list.length === originalList.length - 1)
    ) {
      switch (type) {
        case "businessGroups":
          setValue(type, businessGroupList);
          setValue("companies", []);
          setValue("eanCompanies", []);
          if (list.length !== 1 || includeSelectAll) {
            setIsBusinessGroupLimit(true);
          }
          break;
        case "companies":
          setValue(type, companiesList);
          setValue("businessGroups", []);
          setValue("eanCompanies", []);
          setIsCompanyLimit(true);
          break;
        case "eanCompanies":
          setValue(type, eanList);
          setValue("businessGroups", []);
          setValue("companies", []);
          setIsEANLimit(true);
          break;
        default:
          break;
      }

      return true;
    }

    //Si la opción no fue seleccionada retorno falso
    if (!includeSelectAll) {
      return false;
    }

    //Si la opción select All se encuentra seleccionada y se deseleccionó otra opción
    if (includeSelectAll && reason === "removeOption") {
      const withoutSelectAll = list.filter(
        (element) =>
          (type === "businessGroups" ? element.groupId : element.provider) !==
          t("App.downloadSalesForce.selectAll")
      );

      setValue(type, withoutSelectAll);

      switch (type) {
        case "businessGroups":
          checkBusinessGroups(withoutSelectAll);
          break;

        case "companies":
          checkCompanies(withoutSelectAll);
          break;
        case "eanCompanies":
          checkEANS(withoutSelectAll);
          break;

        default:
          break;
      }

      return true;
    }
  };

  /**
   * Handles the input change of country field
   * @param {array} values list of selected countries
   * @param {string} reason type of selection: selectOption, removeOption
   */
  const handleCountryChange = async (values, reason) => {
    //Reseteamos los demás campos
    setValue("businessGroups", []);
    setValue("companies", []);
    setValue("eanCompanies", []);
    setIsBusinessGroupLimit(false);
    setIsCompanyLimit(false);
    setIsEANLimit(false);

    //Si la lista tiene más de un elemento
    if (values.length > 0) {
      setIsLoadingFields(true);
      const countries = values.map((country) => {
        return country.country;
      });

      //Consultamos los BG de estos países
      const newBG = await getBusinessGroupByCountries(countries);
      let finalBusinessGroups = setSelectAll(newBG, "business-group");
      setBusinessGroupList(finalBusinessGroups);

      //Consultamos las companies de estos países
      const newCompanies = await getCompaniesByCountries(countries);
      let finalCompanies = setSelectAll(newCompanies, "companies");

      setUnfilteredCompanies(finalCompanies);
      setCompaniesList(finalCompanies);

      setEanList(finalCompanies);

      setIsCountrySelected(true);
      setIsLoadingFields(false);
    } else {
      setIsCountrySelected(false);
    }
  };

  /**
   * Handles change of business group field
   * @param {array} values array of selected business groups
   * @param {*} reason type of selection: selectOption, removeOption
   * @param {*} detail current clicked option
   * @returns to break flow
   */
  const handleBusinessGroupChange = (values, reason, detail) => {
    const selectAllHandle = handleSelectAllSelection(
      values,
      reason,
      detail,
      "businessGroups"
    );

    if (selectAllHandle) {
      return;
    } else {
      checkBusinessGroups(values);
    }
  };

  /**
   * Handles the actions that modify companies and eanCompanies fields based on business groups field selection.
   * @param {array} values selected business group
   */
  const checkBusinessGroups = (values) => {
    //Cuando solo seleccionamos un BG los otros campos deben mostrar
    //las empresas y eans de ese BG
    if (values.length === 1) {
      let currentBG = values[0];
      let companiesFromBG = unfilteredCompanies.filter(
        (company) => company.idGroup == currentBG.idGroup
      );
      let finalList = setSelectAll(companiesFromBG, "companies");

      setCompaniesList(finalList);
      setEanList(finalList);

      setIsBusinessGroupLimit(false);
      setIsCompanyLimit(false);
      setIsEANLimit(false);
    } else if (values.length > 1) {
      //Si se selecciona más de uno se levanta el límite y bloquea los campos empresas y eanCompanies
      setIsBusinessGroupLimit(true);
      setValue("companies", []);
      setValue("eanCompanies", []);
    } else {
      //Si el campo queda sin BG se resetean los campos de empresa y ean, se quitan los límites
      setCompaniesList(unfilteredCompanies);
      setEanList(unfilteredCompanies);
      setIsBusinessGroupLimit(false);
      setIsCompanyLimit(false);
      setIsEANLimit(false);
    }
  };

  /**
   * Handles change company field
   * @param {array} values selected companies
   * @param {string} reason type of selection: selectOption, removeOption
   * @param {object} detail option clicked
   * @returns to break the flow.
   */
  const handleCompanyChange = (values, reason, detail) => {
    const selectAllHandle = handleSelectAllSelection(
      values,
      reason,
      detail,
      "companies"
    );

    if (selectAllHandle) {
      return;
    } else {
      checkCompanies(values);
    }
  };

  /**
   * Handles the actions that modify BGs and eanCompanies fields based on companies field selection.
   * @param {array} values selected business group
   */
  const checkCompanies = (values) => {
    const valuesLength = values.length;
    //Si se selecciona más de una se resetean los campos de grupo y eanCompanies
    //Se levanta límite que deshabilita los otros campos
    if (valuesLength > 1) {
      setValue("businessGroups", []);
      setValue("eanCompanies", []);
      setIsCompanyLimit(true);
    } else if (valuesLength === 1) {
      //Si se selecciona una se settea el grupo de la empresa y el ean de la empresa
      const eanCompany = values[0];
      setValue("eanCompanies", [eanCompany]);

      const getBG = businessGroupList.filter(
        (bg) => bg.idGroup === eanCompany.idGroup
      );
      setValue("businessGroups", getBG);
      setIsCompanyLimit(false);
    } else {
      //Si el campo queda vacío los campos vuelven a sus valores iniciales
      setCompaniesList(unfilteredCompanies);
      setEanList(unfilteredCompanies);
      setValue("businessGroups", []);
      setValue("eanCompanies", []);
      setIsCompanyLimit(false);
    }
  };

  /**
   * Handles change of ean company field
   * @param {array} values selected eans
   * @param {string} reason type of selection: selectOption, removeOption
   * @param {object} detail option clicked
   * @returns
   */
  const handleEanChange = (values, reason, detail) => {
    const selectAllHandle = handleSelectAllSelection(
      values,
      reason,
      detail,
      "eanCompanies"
    );

    if (selectAllHandle) {
      return;
    } else {
      checkEANS(values);
    }
  };

  /**
   * Handles the actions that modify BGs and companies fields based on eanCompanies field selection.
   * @param {array} values selected business group
   */
  const checkEANS = (values) => {
    const valuesLength = values.length;
    if (valuesLength > 1) {
      //Cuando se selecciona más de uno se deshabilitan los campos
      setValue("businessGroups", []);
      setValue("companies", []);
      setIsEANLimit(true);
    } else if (valuesLength === 1) {
      //Si se selecciona uno se coloca el bg y empresa en los otros campos
      const eanCompany = values[0];
      const getBG = businessGroupList.filter(
        (bg) => bg.idGroup === eanCompany.idGroup
      );
      setValue("businessGroups", getBG);
      setValue("companies", [eanCompany]);

      setIsEANLimit(false);
    } else {
      //Si queda en blanco resetea los campos
      setCompaniesList(unfilteredCompanies);
      setEanList(unfilteredCompanies);
      setValue("businessGroups", []);
      setValue("companies", []);
      setIsEANLimit(false);
    }
  };

  /**
   * Disables
   */

  /**
   * Deshabilita el campo de businessGroups según límites establecidos
   * @returns true si se deshabilita, false si bo
   */
  const disableBusinessGroup = () => {
    const disable = isCountrySelected
      ? isLoadingFields
        ? true
        : isCompanyLimit
        ? true
        : isEANLimit
        ? true
        : false
      : true;

    return disable;
  };

  /**
   * Deshabilita el campo de companies según límites establecidos
   * @returns true si se deshabilita, false si bo
   */
  const disableCompanies = () => {
    const disable = isCountrySelected
      ? isLoadingFields
        ? true
        : isBusinessGroupLimit
        ? true
        : isEANLimit
        ? true
        : false
      : true;

    return disable;
  };

  /**
   * Deshabilita el campo de eanCompanies según límites establecidos
   * @returns true si se deshabilita, false si bo
   */
  const disableEans = () => {
    const disable = isCountrySelected
      ? isLoadingFields
        ? true
        : isBusinessGroupLimit
        ? true
        : isCompanyLimit
        ? true
        : false
      : true;

    return disable;
  };

  /**
   * Handles filter of admin business groups
   * @param {object} data filters to apply
   */
  const handleFilters = async (data) => {
    try {
      setIsLoadingData(true);
      setNoContent(false);

      //Limpiamos los objetos para que queden solo los strings que necesitamos para el filtro
      const finalCountries = data.countries.map((country) => {
        return country.country;
      });
      let finalEan = data.eanCompanies
        .map((ean) => {
          return ean.eanProvider;
        })
        .filter((ean) => ean !== t("App.downloadSalesForce.selectAll"));
      let finalCompanies = data.companies
        .map((company) => {
          return company.provider;
        })
        .filter((company) => company !== t("App.downloadSalesForce.selectAll"));
      const finalBusinessGroups = data.businessGroups
        .map((bg) => {
          return bg.idGroup;
        })
        .filter((bg) => bg !== t("App.downloadSalesForce.selectAll"));

      if (finalEan.length <= 0 && finalCompanies.length > 0) {
        finalEan = data.companies
          .map((company) => {
            return company.eanProvider;
          })
          .filter(
            (company) => company !== t("App.downloadSalesForce.selectAll")
          );
      }

      const obj = {
        lstContries: finalCountries,
        lstEansProviders: finalEan,
        lstProviders: finalCompanies,
        lstIdsGroups: finalBusinessGroups,
      };

      const requestAdminGroups = await ManageBusinessGroups(obj);

      let responseCode = requestAdminGroups.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const finalArray = setDataStructureArray(
            requestAdminGroups.data.responseMessage
          );

          if (finalArray.length > 0) {
            //Si el filtro trae información
            setIsNoFilterFound(false);
            setData(finalArray);
            setFilters(obj);
          } else {
            //SI no trae info
            setData([]);
            setIsNoFilterFound(true);
          }

          break;

        case CODES.COD_RESPONSE_ERROR_LOGIN:
          //Error de sistema
          setData([]);
          setIsNoFilterFound(true);
          break;

        default:
          setData([]);
          setIsNoFilterFound(true);
          break;
      }

      setIsLoadingData(false);
    } catch (e) {
      console.log("====== Error fetch business groups ======");
      console.log(e);
      console.log("===========================================");
    }
  };

  /**
   *Limpia los filtros, resetea el objeto de filtros y aplica el no content
   */
  const handleCleanFilters = () => {
    reset();

    setFilters({
      lstContries: [],
      lstEansProviders: [],
      lstProviders: [],
      lstIdsGroups: [],
    });

    setNoContent(true);
    setIsCountrySelected(false);
  };

  return (
    <Accordion defaultExpanded={true} expanded={true}>
      <Grid>
        <AccordionSummary>
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.catalogue.filterName")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={4}
          direction="row"
        >
          <Grid
            item
            className="side-line__bottomAlign"
            style={{ padding: "0% 3% 0% 3%" }}
            xs={12}
            sm={7}
            lg={9}
          >
            <form id="form-manage-bg" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                columnSpacing={4}
                rowSpacing={2}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnSpacing={4}
                    rowSpacing={2}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <label className="form__label label__text-grey">
                            {t("App.listUsers.countryN")}*{" "}
                          </label>
                        </Grid>

                        <Grid item>
                          <Controller
                            control={control}
                            defaultValue={[]}
                            name="countries"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Autocomplete
                                disabled={isLoadingCountries}
                                className={
                                  errors.countries
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                }
                                disableCloseOnSelect
                                id="checkboxes-tags-commerce"
                                options={countryList}
                                multiple
                                limitTags={2}
                                isOptionEqualToValue={(option, value) =>
                                  option.country === value.country
                                }
                                getOptionLabel={(option) => option.countryName}
                                value={value}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.country}>
                                    <Checkbox
                                      icon={icon}
                                      className="autoComplete__checkbox"
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.countryName}
                                  </li>
                                )}
                                onChange={(event, values, reason) => {
                                  onChange(values);
                                  handleCountryChange(values, reason);
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      placeholder={`${t(
                                        "App.listUsers.countryN"
                                      )}*`}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {isLoadingCountries ? (
                                              <CircularProgress
                                                sx={{ color: "#4DCAFA" }}
                                                size={15}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  );
                                }}
                                renderTags={(value, getTagProps) => {
                                  const numTags = value.length;
                                  const limitTags = 2;
                                  return (
                                    <>
                                      {value
                                        .slice(0, limitTags)
                                        .map((option, index) => (
                                          <Chip
                                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                            sx={{
                                              width: "30%",
                                            }}
                                            key={index}
                                            size="small"
                                            label={`${option.countryName}`}
                                          />
                                        ))}
                                      {numTags > limitTags &&
                                        ` +${numTags - limitTags}`}
                                    </>
                                  );
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <label className="form__label label__text-grey">
                            {t("App.manageBusinessGroup.filter.name")}
                          </label>
                        </Grid>

                        <Grid item>
                          <Controller
                            control={control}
                            defaultValue={[]}
                            name="businessGroups"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Autocomplete
                                disabled={disableBusinessGroup()}
                                className={
                                  errors.businessGroups
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                }
                                disableCloseOnSelect
                                id="checkboxes-tags-commerce"
                                options={businessGroupList}
                                multiple
                                limitTags={2}
                                isOptionEqualToValue={(option, value) =>
                                  option.idGroup === value.idGroup
                                }
                                getOptionLabel={(option) =>
                                  option.businessGroupName
                                }
                                value={value}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.idGroup + ""}>
                                    <Checkbox
                                      icon={icon}
                                      className="autoComplete__checkbox"
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.businessGroupName}
                                  </li>
                                )}
                                onChange={(event, values, reason, detail) => {
                                  onChange(values);
                                  handleBusinessGroupChange(
                                    values,
                                    reason,
                                    detail
                                  );
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      placeholder={t(
                                        "App.manageBusinessGroup.filter.name"
                                      )}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {isLoadingFields ? (
                                              <CircularProgress
                                                sx={{ color: "#4DCAFA" }}
                                                size={15}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  );
                                }}
                                renderTags={(value, getTagProps) => {
                                  const numTags = value.length;
                                  const limitTags = 2;
                                  return (
                                    <>
                                      {value
                                        .slice(0, limitTags)
                                        .map((option, index) => (
                                          <Chip
                                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                            sx={{
                                              width: "30%",
                                            }}
                                            key={index}
                                            size="small"
                                            label={`${option.businessGroupName}`}
                                          />
                                        ))}
                                      {numTags > limitTags &&
                                        ` +${numTags - limitTags}`}
                                    </>
                                  );
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnSpacing={4}
                    rowSpacing={2}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <label className="form__label label__text-grey">
                            {t(
                              "App.manageBusinessGroup.create.company"
                            ).replace("*", "")}
                          </label>
                        </Grid>

                        <Grid item>
                          <Controller
                            control={control}
                            defaultValue={[]}
                            name="companies"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Autocomplete
                                disabled={disableCompanies()}
                                className={
                                  errors.companies
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                }
                                disableCloseOnSelect
                                id="checkboxes-tags-commerce"
                                options={companiesList}
                                multiple
                                limitTags={2}
                                isOptionEqualToValue={(option, value) =>
                                  option.eanProvider === value.eanProvider
                                }
                                getOptionLabel={(option) => option.provider}
                                value={value}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.eanProvider}>
                                    <Checkbox
                                      icon={icon}
                                      className="autoComplete__checkbox"
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.provider}
                                  </li>
                                )}
                                onChange={(event, values, reason, detail) => {
                                  onChange(values);
                                  handleCompanyChange(values, reason, detail);
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      placeholder={t(
                                        "App.manageBusinessGroup.create.company"
                                      ).replace("*", "")}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {isLoadingFields ? (
                                              <CircularProgress
                                                sx={{ color: "#4DCAFA" }}
                                                size={15}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  );
                                }}
                                renderTags={(value, getTagProps) => {
                                  const numTags = value.length;
                                  const limitTags = 2;
                                  return (
                                    <>
                                      {value
                                        .slice(0, limitTags)
                                        .map((option, index) => (
                                          <Chip
                                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                            sx={{
                                              width: "30%",
                                            }}
                                            key={index}
                                            size="small"
                                            label={`${option.provider}`}
                                          />
                                        ))}
                                      {numTags > limitTags &&
                                        ` +${numTags - limitTags}`}
                                    </>
                                  );
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <label className="form__label label__text-grey">
                            {t("App.manageBusinessGroup.table.eanMainCompany")}
                          </label>
                        </Grid>

                        <Grid item>
                          <Controller
                            control={control}
                            defaultValue={[]}
                            name="eanCompanies"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Autocomplete
                                disabled={disableEans()}
                                className={
                                  errors.eanCompanies
                                    ? "select__filters__errors__medium"
                                    : "select__filters__medium"
                                }
                                disableCloseOnSelect
                                id="checkboxes-tags-commerce"
                                options={eanList}
                                multiple
                                limitTags={2}
                                isOptionEqualToValue={(option, value) =>
                                  option.eanProvider === value.eanProvider
                                }
                                getOptionLabel={(option) => option.eanProvider}
                                value={value}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.eanProvider}>
                                    <Checkbox
                                      icon={icon}
                                      className="autoComplete__checkbox"
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.eanProvider}
                                  </li>
                                )}
                                onChange={(event, values, reason, detail) => {
                                  onChange(values);
                                  handleEanChange(values, reason, detail);
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      placeholder={t(
                                        "App.manageBusinessGroup.table.eanMainCompany"
                                      )}
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {isLoadingFields ? (
                                              <CircularProgress
                                                sx={{ color: "#4DCAFA" }}
                                                size={15}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  );
                                }}
                                renderTags={(value, getTagProps) => {
                                  const numTags = value.length;
                                  const limitTags = 2;
                                  return (
                                    <>
                                      {value
                                        .slice(0, limitTags)
                                        .map((option, index) => (
                                          <Chip
                                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                            sx={{
                                              width: "30%",
                                            }}
                                            key={index}
                                            size="small"
                                            label={`${option.eanProvider}`}
                                          />
                                        ))}
                                      {numTags > limitTags &&
                                        ` +${numTags - limitTags}`}
                                    </>
                                  );
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12} sm={4} lg={3}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              columnSpacing={3}
              className="confirmation-buttons"
            >
              <Grid item>
                <Button
                  type="submit"
                  form="form-manage-bg"
                  disabled={
                    watch("countries") && watch("countries").length > 0
                      ? false
                      : true
                  }
                  className={
                    watch("countries") && watch("countries").length > 0
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  onClick={handleCleanFilters}
                  disabled={!watch("countries")?.length > 0}
                  className={
                    watch("countries")?.length > 0
                      ? "btn__deleteFilter"
                      : "btn__deleteFilter__disabled"
                  }
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

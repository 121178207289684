import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import { GetConfigurationsList } from "../../../services/admin/sendAlliesService";
import ModalInfo from "../Modals/modalInfo";

const FilterAdminSendAllies = ({
  setFilterApply,
  setData,
  setIsNoFilterFound,
  setNoContent,
  setIsLoadingData,
  setFilters,
  setDataStructureArray,
  countryList,
  allyList,
  setShowLoader,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [commercesList, setCommercesList] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [filterResponse, setFilterResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCommerce, setSelectedCommerce] = useState([]);

  /**
   * Íconos
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Función para resetear valores de los campos cuando se borra el país
   */
  const resetValuesWhenClear = () => {
    setValue("ally", "");
    setValue("company", []);
    setValue("commerce", []);
    setCompanyList([]);
    setCommercesList([]);
  };

  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect para actualizar el state de isDesktop en base al tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Schema para el fomulario de filtros
   */
  const schema = yup.object().shape({
    // country: yup.string(),
    // ally: yup.string(),
  });

  /**
   * useForm para resolver el schema
   */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (en comercios) cuando otra opicón es deseleccionada
   *
   * @param {*} values
   * @param {*} reason
   * @param {*} includeSelectAll
   */
  const handleDeleteSelectAllCommerce = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) =>
          option.nameCommerce !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedCommerce(optionsWithoutSelectAll);
      setValue("commerce", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (en empresas) cuando otra opicón es deseleccionada
   *
   * @param {*} values
   * @param {*} reason
   * @param {*} includeSelectAll
   */
  const handleDeleteSelectAllCompany = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option.nameCompany !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedCompany(optionsWithoutSelectAll);
      setValue("company", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para cambiar los valores del campo de empresa de acuerdo a lo seleccionado por el usuario, también selecciona todos los comercios si la opción 'Seleccionar todos' es seleccionada
   *
   * @param {*} values
   * @param {*} reason
   * @param {*} detail
   */
  const onCompanyChange = (values, reason, detail) => {
    let includeSelectAll = false;

    for (const company of values) {
      if (company.nameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedCompany(companyList);
      setValue("company", companyList);
    } else {
      setSelectedCompany(values);
      setValue("company", values);
    }

    handleDeleteSelectAllCompany(values, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedCompany([]);
        setValue("company", []);
      }
    }
  };

  /**
   *
   * Función para cambiar los valores del campo de comercio de acuerdo a lo seleccionado por el usuario, también selecciona todos los comercios si la opción 'Seleccionar todos' es seleccionada
   *
   * @param {*} values
   * @param {*} reason
   * @param {*} detail
   */
  const onCommerceChange = (values, reason, detail) => {
    let includeSelectAll = false;

    for (const commerce of values) {
      if (commerce.nameCommerce === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedCommerce(commercesList);
      setValue("commerce", commercesList);
    } else {
      setSelectedCommerce(values);
      setValue("commerce", values);
    }

    handleDeleteSelectAllCommerce(values, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.nameCommerce === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedCommerce([]);
        setValue("commerce", []);
      }
    }
  };

  /**
   *
   * Función para cambiar los valores de comercio y empresa de acuerdo al país seleccionado
   *
   * @param {*} country
   */
  const handleChangeCountry = (country) => {
    if (country) {
      // setValue("ally", []);
      setValue("company", []);
      setValue("commerce", []);
      setCompanyList([]);
      setCommercesList([]);

      fetchTraders(country.country);
      fetchSuppliers(country.country);
    } else {
      // setValue("ally", []);
      setValue("company", []);
      setValue("commerce", []);
    }
  };

  /**
   *
   * Función para traer la lista de los comercios de acuerdo al país seleccionado
   *
   * @param {String} country
   */
  const fetchTraders = async (country) => {
    try {
      const obj = {
        country,
      };

      const tradersService = await GetTradersService(obj);

      if (
        tradersService.status === CODES.COD_RESPONSE_HTTP_OK &&
        tradersService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = tradersService.data.responseMessage;

        let tradersList = responseMessage.map((item) => {
          return {
            nameCommerce: item.companyName,
            eanCommerce: item.eanCompany,
          };
        });

        const completeList =
          tradersList.length > 0
            ? [
                { nameCommerce: t("App.downloadSalesForce.selectAll") },
                ...tradersList,
              ]
            : [];

        setCommercesList(completeList);
      }
    } catch (error) {
      console.log(
        "================= ERROR filterAdminSendAllies.jsx function fetchTraders ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para traer la lista de los proveedores (empresa) de acuerdo al país seleccionado
   *
   * @param {String} country
   */
  const fetchSuppliers = async (country) => {
    try {
      const obj = {
        country,
      };

      const suppliersService = await GetSuppliersService(obj);

      if (
        suppliersService.status === CODES.COD_RESPONSE_HTTP_OK &&
        suppliersService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = suppliersService.data.responseMessage;

        let suppliersList = responseMessage.map((item) => {
          return {
            nameCompany: item.companyName,
            eanCompany: item.eanCompany,
          };
        });

        const completeList =
          suppliersList.length > 0
            ? [
                { nameCompany: t("App.downloadSalesForce.selectAll") },
                ...suppliersList,
              ]
            : [];

        setCompanyList(completeList);
      }
    } catch (error) {
      console.log(
        "================= ERROR filterAdminSendAllies.jsx function fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para limpiar los campos del filtro y el valor de las chips
   */
  const handleCleanFilters = () => {
    setValue("country", "");
    setValue("ally", "");
    setValue("company", []);
    setValue("commerce", []);

    setSelectedCompany([]);
    setSelectedCommerce([]);

    setFilterApply({ country: "", ally: "" });
    setData([]);
    setNoContent(true);
  };

  /**
   *
   * Función para eliminar el string de 'Seleccionar todos' para que no interrumpa el funcionamiento del filtro
   *
   * @param {array} data
   * @returns listas con los ean de los comercios y empresas
   */
  const cleanData = (data) => {
    let lstEansProviders, lstEansTraders;

    if (Array.isArray(data.company)) {
      const dataFiltered = data.company.filter(
        (company) =>
          company.nameCompany !== t("App.downloadSalesForce.selectAll")
      );

      lstEansProviders = dataFiltered.map((item) => item.eanCompany);
    }

    if (Array.isArray(data.commerce)) {
      const dataFiltered = data.commerce.filter(
        (commerce) =>
          commerce.nameCommerce !== t("App.downloadSalesForce.selectAll")
      );

      lstEansTraders = dataFiltered.map((item) => item.eanCommerce);
    }

    return {
      lstEansProviders: lstEansProviders ?? [],
      lstEansTraders: lstEansTraders ?? [],
    };
  };

  /**
   *
   * Función para filtrar la información a mostrar en la tabla en base a el país, aliado y/o comercio y empresa
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      setShowLoader(true);
      const { lstEansProviders, lstEansTraders } = cleanData(data);

      const obj = {
        country: data.country.country,
        idAllied: data.ally.idAlly,
        lstEansProviders,
        lstEansTraders,
      };

      setIsLoadingData(true);

      setNoContent(false);
      setIsNoFilterFound(false);

      const filterService = await GetConfigurationsList(obj);
      setFilterApply({
        country: data.country.country,
        ally: data.ally.allyName,
      });
      if (
        filterService.status === CODES.COD_RESPONSE_HTTP_OK &&
        filterService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const formattedData = await setDataStructureArray(
          filterService.data.responseMessage
        );

        setData(formattedData);
      } else if (
        filterService.data.responseCode === CODES.COD_RESPONSE_ERROR_LIST
      ) {
        setFilterResponse(filterService);
        setOpenModalInfo(true);
      } else {
        setNoContent(false);
        setIsNoFilterFound(true);
        setData([]);
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log(
        "================= ERROR filterAdminSendAllies.jsx function handleFilters ==================="
      );
      console.log(error);
      console.log("====================================");
    }
    setShowLoader(false);
  };

  return (
    <Grid style={{ paddingBottom: "20px" }}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />
          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.listUsers.filter")}
          </p>
        </AccordionSummary>

        <AccordionDetails className="filter_container">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid
              md={8}
              sm={8}
              item
              className="side-line form__container"
              style={{
                justifyContent: "center",
                padding: "2%",
              }}
            >
              <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
                <Grid container direction="row" alignItems="center" gap={2}>
                  <Grid
                    item
                    container
                    gap={3}
                    justifyContent={isDesktop ? "flex-start" : "center"}
                  >
                    <Grid item lg={5.5} xs={12} sm={6}>
                      <Controller
                        defaultValue=""
                        name="country"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.country
                                ? "select__filters__errors"
                                : "select__filters"
                            } full-width`}
                            options={countryList}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) =>
                              option.country === value.country
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.adminSendAllies.filter.country"
                                  )}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {!loading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleChangeCountry(values);

                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>

                    <Grid item lg={5.5} xs={12} sm={6}>
                      <Controller
                        defaultValue=""
                        name="ally"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.ally
                                ? "select__filters__errors"
                                : "select__filters"
                            } full-width`}
                            options={allyList}
                            getOptionLabel={(option) => option.allyName}
                            isOptionEqualToValue={(option, value) =>
                              option.idAlly === value.idAlly
                            }
                            disabled={watch("country") ? false : true}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.adminSendAllies.filter.ally")}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(e, values) => onChange(values)}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>

                    <Grid item lg={5.5} xs={12} sm={6}>
                      <Controller
                        defaultValue=""
                        name="company"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.company
                                ? "select__filters__errors"
                                : "select__filters"
                            } full-width`}
                            options={companyList}
                            multiple
                            disableCloseOnSelect
                            limitTags={3}
                            isOptionEqualToValue={(option, values) =>
                              option.eanCompany === values.eanCompany
                            }
                            getOptionLabel={(option) => option.nameCompany}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.eanCompany}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.nameCompany}
                              </li>
                            )}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 1;

                              return (
                                <>
                                  {value.slice(0, limitTags).map((option) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "50%",
                                      }}
                                      key={option.eanCompany}
                                      size="small"
                                      label={option.nameCompany}
                                    />
                                  ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                            disabled={watch("country") ? false : true}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.adminSendAllies.table.company")}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              onCompanyChange(values, reason, detail);
                            }}
                            value={value || []}
                          />
                        )}
                        control={control}
                      />
                    </Grid>

                    <Grid item lg={5.5} xs={12} sm={6}>
                      <Controller
                        defaultValue=""
                        name="commerce"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.commerce
                                ? "select__filters__errors"
                                : "select__filters"
                            } full-width`}
                            options={commercesList}
                            multiple
                            disableCloseOnSelect
                            limitTags={1}
                            isOptionEqualToValue={(option, values) =>
                              option.eanCommerce === values.eanCommerce
                            }
                            getOptionLabel={(option) => option.nameCommerce}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} ket={option.eanCommerce}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.nameCommerce}
                              </li>
                            )}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 1;

                              return (
                                <>
                                  {value.slice(0, limitTags).map((option) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "50%",
                                      }}
                                      key={option.eanCommerce}
                                      size="small"
                                      label={option.nameCommerce}
                                    />
                                  ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                            disabled={watch("country") ? false : true}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.adminSendAllies.table.partner")}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              onCommerceChange(values, reason, detail);
                            }}
                            value={value || []}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid xs={4}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={2}
                className="confirmation-buttons"
              >
                <Grid item>
                  <Button
                    startIcon={
                      <TuneOutlinedIcon
                        sx={{
                          fontSize: 20,
                          color: "white",
                        }}
                      />
                    }
                    disableRipple
                    disabled={watch("country") && watch("ally") ? false : true}
                    className={
                      watch("country") && watch("ally")
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    }
                    type="submit"
                    form="hook-form"
                  >
                    {t("App.listUsers.applyFilters")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    disableRipple
                    className={
                      watch("country")
                        ? "btn__deleteFilter"
                        : "btn__deleteFilter__disabled"
                    }
                    disabled={watch("country") ? false : true}
                    onClick={handleCleanFilters}
                  >
                    {t("App.listUsers.cleanFilters")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {openModalInfo && (
        <ModalInfo
          open={openModalInfo}
          title={t("App.validationMessages.error")}
          responseData={{
            status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
            data: {
              responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
              responseMessage: t("App.validationMessages.systemError2"),
            },
          }}
          onClose={() => {
            setOpenModalInfo(false);
          }}
        />
      )}
    </Grid>
  );
};

export default FilterAdminSendAllies;

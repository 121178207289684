import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIconOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIconOutlined from "@mui/icons-material/VisibilityOffOutlined";
import CheckIcon from "@mui/icons-material/Check";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import logo from "../../assets/common/logo.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { REGEXP } from "../../consts/regexp";
import {
  changePasswordService,
  LoginService,
  LogoutService,
} from "../../services/auth/authServices";
import jwt_decode from "jwt-decode";
import { CODES } from "../../consts/codes";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { useDispatch } from "react-redux";
import { resetUserStatus } from "../userSlice";
import { Link } from "react-router-dom";

export const ChangePasswordFT = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("App.validationMessages.required"))
      .min(8, t("App.validationMessages.MinCharacters"))
      .max(15, t("App.validationMessages.MaxCharactersTwo"))
      .test("upperCase", t("App.validationMessages.minCapital"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/^(?=.*?[A-Z])/);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("lowerCase",t("App.validationMessages.minLowercase"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/(?=.*?[a-z])/);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("number", t("App.validationMessages.OneNumber"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/(?=.*?[0-9])/);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("specialChar", t("App.validationMessages.SpecialCharacters"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/(?=.*?[#?!@$%^&*-])/);
          return schema.isValidSync(value);
        }
        return true;
      }),
    confirmPassword: yup
      .string()
      .required(t("App.validationMessages.required"))
      .oneOf(
        [yup.ref("password"), null],
        t("App.validatePassword.noMatch")
      ),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let checklist;

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  const [acceptTerms, setacceptTerms] = useState(true);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState("");

  const [respCode, setRespCode] = useState(null);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState({});

  /**
   * Función para validar que la nueva contraseña
   * no sea igual a la anterior
   * @param {*} data // Credeciales del usuario y contraseña nueva
   * @returns // true si la contraseña es diferente, false si es igual
   */
  const verifyPassword = (data) => {
    if (data.oldPassword === data.newPassword) {
      return false;
    }
    return true;
  };

  /**
   * Función para cerrar el modal de información
   */
  const handleAction = () => {
    setIsOpenModalInfo(false);
  };

  /**
   * Función para cambiar la contraseña del usuario
   * solo si la contraseña nueva es diferente a la anterior
   * sino se muestra un modal informandole al usuario
   * @param {*} data // Información del formulario
   */
  const handleChangePasswordFT = async (data) => {
    try {
      setIsLoading(true);
      const tmpPassword = localStorage.getItem("tmpPassword");
      const tmpEmail = localStorage.getItem("tmpEmail");

      const obj = {
        email: tmpEmail,
        oldPassword: tmpPassword,
        newPassword: data.password,
      };

      // Validar que la contraseña nueva sea diferente a la anterior
      if (verifyPassword(obj)) {
        const service = await changePasswordService(obj);

        if ([CODES.COD_RESPONSE_HTTP_OK].includes(service.status)) {
          const loginObj = {
            email: tmpEmail,
            password: data.password,
          };
          await handleLogOut();
          const service = await LoginService(loginObj);
          // Verificar que se haya iniciado sesión correctamente
          if (
            service.status === CODES.COD_RESPONSE_HTTP_OK &&
            service.data.responseCode === CODES.COD_RESPONSE_SUCCESS
          ) {
            const accessToken = service.data.responseMessage.accessToken;
            localStorage.setItem("accessToken", accessToken);

            const refreshToken = service.data.responseMessage.refreshToken;
            localStorage.setItem("refreshToken", refreshToken);

            const jwtTokenInfo = service.data.responseMessage.idToken;
            localStorage.setItem("infoToken", jwtTokenInfo);

            const payloadToken = service.data.responseMessage.idToken;
            localStorage.setItem("payloadToken", JSON.stringify(payloadToken));

            const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);
            //Mostrar indicador que la contraseña se cambió correctamente
            setRole(jwtDecodeTokenInfo["custom:Role"]);

            setTitle(t("App.validatePassword.save"));
            setRespCode(CODES.COD_RESPONSE_HTTP_OK);
            setMessage(t("App.validatePassword.updatePass"));
            setOpen(true);
          }
        }
      } else {
        // Mostrar modal para indicar que la contraseña nueva es
        // igual a la anterior
        setTitle(t("App.validatePassword.warning"));
        setRespCode(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
        setMessage(t("App.validatePassword.old"));
        setIsOpenModalInfo(true);
      }
    } catch (error) {
      console.log(
        "=============== ERROR CHANGEPASSWORDFT.JSX ====================="
      );
      console.log(error);
      console.log(
        "================================================================"
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Función para mostrar u ocultar la contraseña
   */
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  /**
   * Función para cerrar la sesión actual del usuario
   */
  const handleLogOut = async () => {
    try {
      const service = await LogoutService();

      if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          service.data.responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST
        ) {
          localStorage.clear();
        }
      }
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Evita que el campo de entrada pierda el foco cuando se hace clic en el botón
   * del ojo para mostrar u ocultar la contraseña
   * @param {*} event // Evento del mouse
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Actualizar el valor del campo de confirmación de la contraseña
   * @param {*} prop // Nombre de la propiedad del campo
   */
  const handleConfirmPasswordChange = (prop) => (event) => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      [prop]: event.target.value,
    });
  };

  /**
   * Evita que el campo de entrada pierda el foco cuando se hace clic en el botón
   * del ojo para mostrar u ocultar la contraseña
   * @param {*} event // Evento del mouse
   */
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showConfirmPassword: !valuesConfirmPassword.showConfirmPassword,
    });
  };

  /**
   * Función para redireccionar al usuario a la sección
   * correspondiente según su role
   */
  const redirectSection = () => {
    setOpen(false);
    dispatch(resetUserStatus());
    if (role === "admin") {
      navigate("/admin/home");
    } else if (role === "user") {
      navigate("/user/home");
    }
  };

  return (
    <div className="changePasswordFT__wrap">
      <div className="changePasswordFT__leftSide">
      <Grid item className="login__head-container-pasword">
           <Grid item xs={7} sm={6} md={4} lg={10} xl={4}>
              <div className="login__logo">
                <img
                  src={logo}
                  alt="Logo Prescriptiva"
                  className="logo-CENAnalíticos"
                />
              </div>
            </Grid>
            </Grid>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          
        >
          
          
          <Grid item>
            <p className="display-large-password__primary-one">
              {t("App.changePasswordFT.leftPanel.mainTitle")}
            </p>
          </Grid>

          <Grid item>
            {watch("password")?.length >= 8 &&
              watch("password")?.length <= 15 &&
              REGEXP.ONE_LOWER_LETTER.test(watch("password")) &&
              REGEXP.ONE_UPPER_LETTER.test(watch("password")) &&
              REGEXP.ONE_NUMBER.test(watch("password")) &&
              REGEXP.ONE_SPECIAL_CHAR.test(watch("password")) &&
              !acceptTerms && (
                // default-text_successful
                <p className="subheading_one__success-color">
                  {t("App.changePasswordFT.leftPanel.successfulText")}
                </p>
              )}
            <br />
            <p className="subheadingOne__text-black">
              {t("App.changePasswordFT.leftPanel.instructionText")}
            </p>
          </Grid>

          <Grid item>
            <div className="changePasswordFT__form">
              <form onSubmit={handleSubmit(handleChangePasswordFT)}>
                <Input
                  className={
                    errors.password
                      ? "changePasswordFT__form-input--error"
                      : "changePasswordFT__form-input"
                  }
                  disableUnderline
                  placeholder={t("App.changePasswordFT.leftPanel.password")}
                  {...register(`password`, {
                    onChange: (e) => {
                      setValue(`password`, e.target.value);
                    },
                  })}
                  id="password"
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOffIconOutlined />
                        ) : (
                          <VisibilityIconOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {errors.password && (
                  <p className="changePasswordFT_one__form-errorText">
                    {errors.password.message}
                  </p>
                )}
                <br />

                <Input
  className={
    errors.confirmPassword
      ? "changePasswordFT__form-input--error"
      : "changePasswordFT__form-input"
  }
  disableUnderline
  id="confirmPassword"
  name="confirmPassword"
  placeholder={t("App.changePasswordFT.leftPanel.confirmPassword")}
  {...register("confirmPassword")}
  type={valuesConfirmPassword.showConfirmPassword ? "text" : "password"}
  onChange={handleConfirmPasswordChange("confirmPassword")}
  value={valuesConfirmPassword.confirmPassword}
  endAdornment={
    <InputAdornment position="end">
      <IconButton
        onClick={handleClickShowConfirmPassword}
        onMouseDown={handleMouseDownConfirmPassword}
      >
        {valuesConfirmPassword.showConfirmPassword ? (
          <VisibilityOffIconOutlined />
        ) : (
          <VisibilityIconOutlined />
        )}
      </IconButton>
    </InputAdornment>
  }
/>

                {errors.confirmPassword && (
                  <p className="changePasswordFT_one__form-errorText">
                    {errors.confirmPassword.message}
                  </p>
                )}

                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: "22px" },
                        right: {
                          sm: "30%",  
                          lg: "24%",
                        },
                        color: "#543AB4",
                        "&.Mui-checked": {
                          color: "#543AB4",
                        },
                      }}
                    />
                  }
                  onChange={() => setacceptTerms(!acceptTerms)}
                  label={
                    <a
                      href="https://www.carvajaltys.com/politica-de-tratamiento-de-datos/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="default-text__link heading_one__primary-one"
                    >
                      <u>{t("App.changePasswordFT.leftPanel.acceptPolicies")}</u>
                    </a>
                  }
                />
                <br />
                <br />
                <button
                  className="changePasswordFT__form-button"
                  type="submit"
                  disabled={acceptTerms || isLoading}
                >
                  {!isLoading ? (
                    t("App.changePasswordFT.leftPanel.button")
                  ) : (
                    <CircularProgress size={16} color="inherit" />
                  )}
                </button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>

      {checklist && (
        <div>
          <CheckIcon></CheckIcon>
        </div>
      )}

      <div className="changePasswordFT__rightSide">
        <Grid item className="language_selector__changePasword">
        <LanguageSwitcher />
        </Grid>
        <Grid container direction="column" spacing={1}>
          <Grid item className="changePasswordFT__rightSide-instructions">
            <p className="changePasswordFT__rightSide-instructions--mainTitleOne">
              {t("App.changePasswordFT.rightPanel.mainTitle")}
            </p>

            <Grid className="changePasswordFT__rightSide-instructions--instructionSet">
              <p className="display-large-changePassword__text-white">
                {t("App.changePasswordFT.rightPanel.instructionText")}
              </p>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {watch("password")?.length >= 8 &&
                  watch("password")?.length <= 15 ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-changePassword__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionOne")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_LOWER_LETTER.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-changePassword__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionTwo")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_UPPER_LETTER.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-changePassword__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionThree")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_NUMBER.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-changePassword__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionFour")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_SPECIAL_CHAR.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-changePassword__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionFive")}
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
        close={true}
      />

      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={open}
        onClose={() => {
          redirectSection();
        }}
        close={false}
      />
    </div>
  );
};

import { Card, CardContent, Grid } from "@mui/material";

import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

export const DonutsSection = ({ t, requestInfo }) => {
  /**
   * Const
   */

  const chartColors = ["rgba(141, 81, 218, 1)", "rgba(252,176,69,1)"];
  const donutLabels = [
    t("App.homeReception.donuts.success"),
    t("App.homeReception.donuts.error"),
  ];

  /**
   * Dona vacía
   */
  const emptyDonut = {
    labels: donutLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Opciones  básicas de dona
   */
  const donutOptions = {
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 15,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  /**
   * Use State
   */

  //Cantidad de documentos procesados
  const [qtyDocuments, setQtyDocuments] = useState(emptyDonut);

  // Porcentaje de documentos procesados
  const [percentageDocuments, setPercentageDocuments] = useState(emptyDonut);

  /**
   * UseEffect
   */

  useEffect(() => {
    handleSetQtyDocuments(requestInfo.quantityDonuts);
    handleSetPercentageDocuments(requestInfo.quantityDonuts);
  }, [requestInfo]);

  /**
   * Handles
   */

  /**
   * Settea la información sobre la dona de cantidad de documentos procesados
   * @param {*} currentQuantity cantidad de documentos procesas a mostrar
   */
  const handleSetQtyDocuments = (currentQuantity) => {
    //ADECUADO Observar el objeto antes por si viene sin datos o nulo

    //Construyo el objeto de información completo nuevamente
    const newLabels = [
      t("App.homeReception.donuts.success", { value: currentQuantity[0] }),
      t("App.homeReception.donuts.error", { value: currentQuantity[1] }),
    ];
    const newQuantity = {
      labels: newLabels,
      datasets: [
        {
          data: currentQuantity,
          backgroundColor: chartColors,
        },
      ],
    };

    setQtyDocuments(newQuantity);
  };

  /**
   * Settea la información sobre la dona de porcentaje de documentos procesados
   * @param {*} currentQuantity cantidad de documentos procesados actualmente
   */
  const handleSetPercentageDocuments = (currentQuantity) => {
    //SEGURAMENTE Tocará hacer el cálculo a partir de la cantidad

    const totalDocuments = currentQuantity.reduce(
      (accumulator, currentElement) => accumulator + currentElement
    );

    const successPercentage = parseFloat(
      (currentQuantity[0] / totalDocuments) * 100
    ).toFixed(2);

    const errorPercentage = parseFloat(
      (currentQuantity[1] / totalDocuments) * 100
    ).toFixed(2);

    const finalPercentageArray = [successPercentage, errorPercentage];
    //Construyo el objeto de información completo nuevamente
    const newLabels = [
      t("App.homeReception.donuts.success", { value: `${successPercentage}%` }),
      t("App.homeReception.donuts.error", { value: `${errorPercentage}%` }),
    ];
    const newPercentage = {
      labels: newLabels,
      datasets: [
        {
          data: finalPercentageArray,
          backgroundColor: chartColors,
        },
      ],
    };

    setPercentageDocuments(newPercentage);
  };

  return (
    <Grid
      container
      direction="row"
      columnSpacing={{ md: 2, sm: 4 }}
      rowSpacing={2}
      justifyContent="center"
    >
      <Grid item md={6}>
        <Card className="border-radius">
          <CardContent>
            <Grid
              container
              direction="column"
              rowSpacing={2}
              className="col-flex donut-height"
            >
              <Grid item className="display-small__text-default text-center">
                {t("App.homeReception.donuts.titleOne")}
              </Grid>
              <Grid item style={{ width: "175px" }}>
                <Doughnut data={qtyDocuments} options={donutOptions} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6}>
        <Card className="border-radius">
          <CardContent>
            <Grid
              container
              direction="column"
              rowSpacing={2}
              className="col-flex donut-height"
            >
              <Grid item className="display-small__text-default text-center">
                {t("App.homeReception.donuts.titleTwo")}
              </Grid>
              <Grid item style={{ width: "175px" }}>
                <Doughnut data={percentageDocuments} options={donutOptions} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

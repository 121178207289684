import { React, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { InputAdornment, IconButton, Button, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReCAPTCHA from "react-google-recaptcha";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getUser,
  fetchUser,
  getUserStatus,
  resetUserStatus,
} from "../../pages/userSlice";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router";
import {
  CheckSession,
  LoginService,
  LogoutService,
} from "../../services/auth/authServices";
import { CODES } from "../../consts/codes";
import Divider from "@mui/material/Divider";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import logo from "../../assets/common/logo.svg";
import bubble from "../../assets/common/bubble.svg";
import bubble_mobile from "../../assets/common/bubble_mobile.svg";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import UnlockActiveSession from "../../components/common/unlockActiveSession";
import ModalInfo from "../../components/common/Modals/modalInfo";
import LoginImage from "./loginImage";
import { Watch } from "@mui/icons-material";

export const Login = () => {
  /**
   * Const
   */

  /**
   * Lista de rutas de home que no son de ventas e inventarios
   */
  const othersHome = [
    "/user/homeBillCE",
    "/user/homePayrollCEN",
    "/user/homeEmissionCEN",
    "/user/homeReceptionCEN",
  ];

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Form
   */
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Este campo debe ser un email válido")
      .required(t("App.validationMessages.required")),
    password: yup.string().required(t("App.validationMessages.required")),
  });

  {
    /** defaultValues dentro del useForm, eliminar defaultValue del render */
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "" },
  });

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Ref
   */
  const captcha = useRef(null);

  /**
   * Use State
   */
  const [isLoading, setIsLoading] = useState(false);
  const [isBorderFocus, setIsBorderFocus] = useState(false);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [validCaptcha, setValidCaptcha] = useState(true);
  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(false);
  const [noUserMessage, setNoUserMessage] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [respCode, setRespCode] = useState(null);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState({});
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  /**
   * Obtener información del usuario
   */
  useEffect(() => {
    // Validar que haya token de acceso
    const loadUserInfo = async () => {
      if (localStorage.getItem("infoToken")) {
        //Valida si la pestaña o navegador fue cerrado
        const activeSession = sessionStorage.getItem("activeSession");
        if (activeSession) {
          fetchData();
        } else {
          await handleLogOut();
        }
      }
    };
    loadUserInfo();
  }, [userStatus, dispatch]);

  /**
   * Use Effect para manejar el tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Handles
   */

  /**
   * Función para manejar el tamaño de la pantalla
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * Funcion para saber si el value de un Textfield esta vacio para cambiar el estilo del borde
   */
  const handleChangeBorder = (values) => {
    if(values){
      setIsBorderFocus(true)
    }else{
      setIsBorderFocus(false)
    }
  }

  /**
   * Función que cierra la sesión del usuario
   */
  const handleLogOut = async () => {
    try {
      const {
        status,
        data: { responseCode },
      } = await LogoutService();

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST)
      ) {
        localStorage.clear();
      }
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para mostrar u ocultar la contraseña
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Evita que el campo de entrada pierda el foco cuando se hace clic en el botón
   * del ojo para mostrar u ocultar la contraseña
   * @param {*} event // Evento del mouse
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Actualizar el valor del captcha
   */
  const onChangeCaptcha = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    }
  };

  /**
   *  Función para realizar el login del usuario
   * @param {*} data // Datos del formulario
   */
  const handleLogin = async (data) => {
    try {
      setInvalidPasswordMessage(false);
      setNoUserMessage(false);
      setIsLoading(true);
      if (captcha.current.getValue()) {
        setValidCaptcha(true);
        setIsLoading(true);
        const email = data.email;
        const password = data.password;
        const obj = {
          email: email,
          password: password,
        };
        const {
          status: checkStatus,
          data: { responseCode: responseCodeCheck },
        } = await CheckSession(obj);

        if (checkStatus === CODES.COD_RESPONSE_HTTP_OK) {
          //Login exitoso
          if (responseCodeCheck === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
            const {
              status: loginStatus,
              data: {
                responseCode: responseCodeLogin,
                responseMessage: responseMessageLogin,
              },
            } = await LoginService(obj);
            // Veerificar si se realizó el login correctamente
            if (loginStatus === CODES.COD_RESPONSE_HTTP_OK) {
              switch (responseCodeLogin) {
                case CODES.COD_RESPONSE_SUCCESS: {
                  //Login exitoso
                  //Guardo valor que me indica que el usuario inició sesión
                  sessionStorage.setItem("activeSession", "true");

                  dispatch(resetUserStatus());

                  const accessToken = responseMessageLogin.accessToken;
                  localStorage.setItem("accessToken", accessToken);

                  const refreshToken = responseMessageLogin.refreshToken;
                  localStorage.setItem("refreshToken", refreshToken);

                  const jwtTokenInfo = responseMessageLogin.idToken;
                  localStorage.setItem("infoToken", jwtTokenInfo);

                  const payloadToken = responseMessageLogin.idToken;
                  localStorage.setItem(
                    "payloadToken",
                    JSON.stringify(payloadToken)
                  );

                  localStorage.setItem("tmpEmail", email);
                  localStorage.setItem("tmpPassword", password);
                  await fetchData();
                  break;
                }

                case CODES.COD_RESPONSE_LOGIN_FIRST_TIME:
                  //Login primera vez
                  localStorage.setItem("tmpPassword", data.password);
                  localStorage.setItem("tmpEmail", data.email);
                  //Guardo valor que me indica que el usuario inició sesión
                  sessionStorage.setItem("activeSession", "true");
                  navigate("/changePasswordFT");
                  break;

                case CODES.COD_RESPONSE_ERROR_LOGIN:
                  //Login error
                  setInvalidPasswordMessage(true);
                  break;
                default:
                  setError(true);
                  setOpen(true);
                  break;
              }
            }
          } else if (responseCodeCheck === CODES.COD_RESPONSE_ERROR_LOGIN) {
            setNoUserMessage(true);
          } else {
            localStorage.setItem("tmpEmail", data.email);
            setError(true);
            setOpen(true);
          }
        }
      } else {
        setValidCaptcha(false);
      }
    } catch (error) {
      console.log(
        "===============ERROR LOGIN.JSX handleLogin ====================="
      );
      console.log(error);
      console.log("====================================================");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Obtener la información del usuario
   */
  const fetchData = async () => {
    try {
      if (userStatus === "idle") {
        dispatch(fetchUser());
      }
      if (userInfo) {
        if (localStorage.getItem("infoToken")) {
          const lastUpdate = userInfo?.lastPasswordChange || "2021-10-10";
          // Verificar que sea vigente la contraseña
          verifyValidatePassword(lastUpdate);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Verificar si la contraseña está vigente o no
   * de acuerdo a la fecha de actualización
   * @param {*} date // fecha de actualización de la contraseña
   */
  const verifyValidatePassword = (date) => {
    const fecha = new Date();
    const currentYear = fecha.getFullYear();
    const currentMonth = fecha.getMonth() + 1;

    // Obtener la fecha pasada
    const pastDate = new Date(date);
    const pastYear = pastDate.getFullYear();
    const pastMonth = pastDate.getMonth() + 1;
    const differenceInMonths =
      (currentYear - pastYear) * 12 + (currentMonth - pastMonth);

    // Verificar si la contraseña tiene más de 3 meses de antigüedad
    if (differenceInMonths >= 3) {
      setTitle(t("App.validatePassword.mainTitle"));
      setRespCode(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
      setMessage(t("App.validatePassword.message"));
      setIsOpenModalInfo(true);
    } else {
      setIsOpenModalInfo(false);
      localStorage.removeItem("tmpPassword");
      localStorage.removeItem("tmpEmail");
      redirectOk();
    }
  };
  /**
   * Función para redireccionar al usuario a su home correspondiente dependiendo de si su rol es administrador o cliente
   */
  const redirectOk = () => {
    const jwtTokenInfo = localStorage.getItem("infoToken");
    if (jwtTokenInfo) {
      const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);
      const user = jwtDecodeTokenInfo["custom:Role"];
      if (user) {
        if (user === "admin") {
          navigate("/admin/home");
        }
        if (user === "user") {
          if (userInfo.lstMenu) {
            navigate("/user" + getHomeRoute(userInfo.lstMenu));
          } else {
            navigate("/admin/home");
          }
        }
      }
    }
  };

  /**
   * Función para activar o desactivar el botón de login
   */
  const handleLoginDisabled = () => {
    // if (watch("email") && watch("password") && validCaptcha) {
    //   return false;
    // } else {
    //   return true;
    // }
    if (watch("email") && watch("password")) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función para obtener la ruta del home inicio
   * @param {*} menu lista de opciones de menú contratadas
   * @returns ruta del home
   */
  const getHomeRoute = (menu) => {
    let route = "";
    if (findHomeSalesStock(menu)) {
      route = "/home";
    } else {
      route = findFirstOtherHome(menu);
    }
    return route;
  };

  /**
   * Función para buscar si se tiene contratado
   * el módulo de Ventas e Inventario
   * @param {*} menu lista de opciones de menú contratadas
   * @returns true si se tiene contratado el módulo
   */
  const findHomeSalesStock = (menu) => {
    for (const optionMenu of menu) {
      if (
        optionMenu.menuOptionName === "Ventas e Inventarios" ||
        optionMenu.nameEnglish === "Sales and Stocks"
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Función para buscar la primera opción de home que no sea la de ventas e inventarios
   * @param {*} menu lista de opciones de menú contratadas
   * @returns url del home
   */
  const findFirstOtherHome = (menu) => {
    let urlHome = "";
    for (const optionMenu of menu) {
      if (urlHome !== "") {
        break;
      } else {
        for (const option of optionMenu.levels) {
          if (
            option?.url?.includes("/user/home") &&
            excludeUrl(option.url, othersHome)
          ) {
            urlHome = `${option.url.split("user")[1]}`;
            break;
          } else {
            urlHome = "/home";
          }
        }
      }
    }
    return urlHome;
  };

  /**
   * Función identificar si la url es de un home
   * @param {*} url url a revisar
   * @returns true si es un home
   */
  const excludeUrl = (url, routes) => {
    for (const urlHome of routes) {
      if (url.includes(urlHome)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Función para cerrar el modal de información sobre la caducidad
   * de la contraseña y redireccionar al cambio de contraseña
   */
  const handleChangeExpiredPassword = () => {
    setIsOpenModalInfo(false);
    navigate("/changePasswordFT");
  };

  return (
    <div className="login__wrap">
      <Grid className="login__leftSide">
        <LoginImage />
      </Grid>
      <Grid className="login__rigthSide" xl={10}>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item className="login__head-container">
            <Grid item xs={7} sm={6} md={4} lg={10} xl={12}>
              <div className="login__logo">
                <img
                  src={logo}
                  alt="Logo Prescriptiva"
                  className="logo-CENAnalíticos"
                />
              </div>
            </Grid>
            <Grid item xs={4.2} sm={2} md={3} lg={2.8} xl={2}>
              <LanguageSwitcher />
            </Grid>
          </Grid>
          <Grid item sm={3} md={3}>
            <p className="login__title">
              {" "}
              {t("App.login.rightPanel.mainTitle1")}{" "}
              <span className="color">
                {" "}
                {t("App.login.rightPanel.mainTitle2")}
              </span>{" "}
              {t("App.login.rightPanel.mainTitle3")}
            </p>
            <p className="login__subtitle">
              {" "}
              {t("App.login.rightPanel.subtitle")}
            </p>
          </Grid>
          <Grid item className="login__container">
            <Grid item className="">
              <Grid container direction="column">
                <Grid item>
                  <p className="subheading__text-black login__container__message">
                    {t("App.login.rightPanel.welcomeMessage")}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="login__form">
              <Grid>
                <form onSubmit={handleSubmit(handleLogin)}>
                  <Controller
                    defaultValue=""
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className={
                          errors.email
                            ? "login__form-input--error"
                            : isBorderFocus
                            ? "login__form-input--focus"
                            : "login__form-input"
                        }
                        disableUnderline
                        type="email"
                        name="email"
                        id="email"
                        label={t("App.login.rightPanel.email")}
                        sx={{
                          label: {
                            fontFamily: "Roboto !important",
                            fontSize: "14px !important",
                            translate: "0px -3px",
                            alignItems: "center", 
                            padding: "4px",
                            transformOrigin: "-45px -40px",
                            "&.Mui-focused": {
                              color: "#8C8D9D", // Mismo valor para mantener alineación
                            },
                          },
                        }}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChangeBorder(e.target.value);
                        }}
                        InputProps={{
                          autoComplete: "none !important",
                          sx: {
                            height: "100% !important",
                            width: "100% !important",
                            "&:-webkit-autofill": {
                              backgroundColor: "white !important",
                              boxShadow: "0 0 0 100px white inset !important",
                              borderRadius: "8px",
                        }}}}
                      />
                    )}
                  />

                  <Grid>
                    {errors?.email ? (
                      <p className="changePasswordFT__form-errorText">
                        {errors?.email?.message}
                      </p>
                    ) : null}
                    {noUserMessage ? (
                      <p className="login-error-message">
                        {" "}
                        {t("App.login.errors.noUser")}
                      </p>
                    ) : null}
                  </Grid>
                  <Controller
                    defaultValue=""
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className={
                          errors?.password
                            ? "login__form-input--error"
                            : isBorderFocus
                            ? "login__form-input--focus"
                            : "login__form-input"
                        }
                        disableUnderline
                        id="password"
                        label={t("App.login.rightPanel.password")}
                        sx={{
                          label: {
                            fontFamily: "Roboto !important",
                            fontSize: "14px !important",
                            translate: "0px -5px",
                            alignItems: "center", 
                            padding: "4px",
                            transformOrigin: "-45px -36px",
                            "&.Mui-focused": {
                              color: "#8C8D9D",
                            },
                          },
                        }}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          onChange(e);
                          handleChangeBorder(e.target.value);
                        }}
                        InputProps={{
                          autoComplete: "none !important",
                          sx: {
                            height: "100% !important",
                            width: "100% !important",
                          },
                        }}
                        value={value}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />

                  <Grid>
                    {invalidPasswordMessage && (
                      <p className="login-error-message">
                        {t("App.login.errors.wrongPassword")}
                      </p>
                    )}
                    {errors?.password && (
                      <p className="changePasswordFT__form-errorText">
                        {errors?.password.message}
                      </p>
                    )}
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    justifyItems="center"
                    alignItems="center"
                    className="u-marginB second"
                  >
                    <Grid item className="recover-password__container">
                      <Link
                        className="link__moderate-blue recover-password"
                        to="/recoverPassword"
                      >
                        {t("App.login.rightPanel.recoverPassword")}
                      </Link>
                    </Grid>
                    <Grid item sx={{ justifyContent: "center" }}>
                      <Grid className="recaptcha_container">
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey="6LfkwBwgAAAAAIzpuBBjEZL8Pid8GvnYNq5EYE40"
                          onChange={onChangeCaptcha}
                          hl={t("App.login.rightPanel.recaptcha")}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      {!validCaptcha && (
                        <p className="login-error-message">
                          {t("App.login.rightPanel.recaptchaMessage")}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <button
                    disabled={handleLoginDisabled()}
                    className={
                      handleLoginDisabled()
                        ? "login__form-button--disabled"
                        : "login__form-button"
                    }
                    type="submit"
                  >
                    {!isLoading ? (
                      t("App.login.rightPanel.button")
                    ) : (
                      <CircularProgress size={16} color="inherit" />
                    )}
                  </button>
                </form>

                <Grid item className="register__container">
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} md={8} lg={8} className="login__text">
                      <b className="login__text__message">
                        {t("App.login.rightPanel.registerMessage")}
                      </b>
                    </Grid>
                    <Grid item xs={4} md={3} lg={3}>
                      <Button
                        className="register__button"
                        onClick={() =>
                          window.open(
                            "https://carvajaldigital.co/cen-renovacio/",
                            "_blank"
                          )
                        }
                      >
                        {t("App.login.rightPanel.registerButton")}
                      </Button>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={isOpenModalInfo}
        onClose={handleChangeExpiredPassword}
        close={false}
      />

      {error && (
        <>
          {" "}
          <UnlockActiveSession
            emailUnlockUser={localStorage.getItem("tmpEmail")}
            open={open}
            onClose={() => {
              setError(false);
              setOpen(false);
            }}
          />
        </>
      )}
    </div>
  );
};

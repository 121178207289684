import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { CODES } from "../../consts/codes";
import { GetMenu } from "../../services/admin/menuOptionsServices";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ModalPreviewMenu = (props) => {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted) {
          const previewMenuService = await GetMenu();
          if (previewMenuService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              previewMenuService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const Level1 = previewMenuService.data.responseMessage;
              setLevel1(Level1);
            }
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  };
  const handleClose = () => {
    setOpen(false);
    setLevel1([]);
  };

  const [level1, setLevel1] = useState([]);
  const { t, i18n } = useTranslation();
  return (
    <Grid>
      <Button
        className="modalPreviewMenu-principalButton"
        onClick={handleClickOpen}
      >
        {t("App.listMenuOptions.preview.button")}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxHeight: "60rem",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
            "& .MuiDialog-paper": {
              height: "592px",
              width: "749px",
              maxWidth: "749px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="title"
          className="display-large__primary-one"
          onClose={handleClose}
        >
          {t("App.listMenuOptions.preview.title")}
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid>
            {level1.map((level) => {
              const level2 = level.levels;

              return (
                <Grid
                  key={level.pk}
                  className="modalPreviewMenu__form-box-level1"
                >
                  <Accordion
                    disableGutters
                    className="modalPreviewMenu__accordion"
                    defaultExpanded={level2.length > 0 ? true : false}
                  >
                    <AccordionSummary
                      className="modalPreviewMenu__accordion__summary"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <a className="modalPreviewMenu__levelLabel">
                        {t("App.listMenuOptions.preview.level1")}
                      </a>
                      <span>&nbsp;&nbsp;</span>

                      {i18n.language.includes("es")
                        ? level.menuOptionName
                        : level.nameEnglish}
                    </AccordionSummary>
                    <AccordionDetails className="modalPreviewMenu__accordion__detail">
                      <List disablePadding className="modalPreviewMenu__list">
                        {level2.map((level2, idxParent) => {
                          const level3 = level2.levels;
                          if (level3.length > 0) {
                            return (
                              <Grid
                                key={level2.pk}
                                className="modalPreviewMenu__form-box-level2"
                              >
                                <Accordion
                                  disableGutters
                                  className="modalPreviewMenu__accordion"
                                  defaultExpanded={
                                    level3.length > 0 ? true : false
                                  }
                                >
                                  <AccordionSummary
                                    className="modalPreviewMenu__accordion__summary"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <ListItem disablePadding>
                                      {idxParent === 0 && (
                                        <a className="modalPreviewMenu__levelLabel">
                                          {t(
                                            "App.listMenuOptions.preview.level2"
                                          )}
                                        </a>
                                      )}
                                      {idxParent !== 0 &&
                                        level3.length === 0 && (
                                          <a className="modalPreviewMenu__levelLabel__white">
                                            {t(
                                              "App.listMenuOptions.preview.level2"
                                            )}
                                          </a>
                                        )}
                                      {idxParent !== 0 && level3.length > 0 && (
                                        <>
                                          <span>&nbsp;&nbsp;</span>
                                        </>
                                      )}
                                      <span>&nbsp;&nbsp;</span>

                                      {idxParent + 1 + ". "}
                                      {i18n.language.includes("es")
                                        ? level2.menuOptionName
                                        : level2.nameEnglish}
                                    </ListItem>
                                  </AccordionSummary>
                                  <AccordionDetails className="modalPreviewMenu__accordion__detail">
                                    <List
                                      disablePadding
                                      className="modalPreviewMenu__list"
                                    >
                                      {level3.map((level3, idx) => {
                                        return (
                                          <Grid
                                            key={level3.pk}
                                            className="modalPreviewMenu__form-box-level3"
                                          >
                                            <ListItem disablePadding>
                                              {idx === 0 && (
                                                <a className="modalPreviewMenu__levelLabel">
                                                  {t(
                                                    "App.listMenuOptions.preview.level3"
                                                  )}
                                                </a>
                                              )}
                                              {idx !== 0 && (
                                                <a className="modalPreviewMenu__levelLabel__white">
                                                  {t(
                                                    "App.listMenuOptions.preview.level3"
                                                  )}
                                                </a>
                                              )}
                                              <span>&nbsp;&nbsp;</span>
                                              {idxParent + 1 + "."}
                                              {idx + 1}{" "}
                                              {i18n.language.includes("es")
                                                ? level3.menuOptionName
                                                : level3.nameEnglish}
                                            </ListItem>
                                          </Grid>
                                        );
                                      })}
                                    </List>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid
                                key={level2.pk}
                                className="modalPreviewMenu__form-box-level2"
                              >
                                <ListItem disablePadding>
                                  {idxParent === 0 && (
                                    <a className="modalPreviewMenu__levelLabel">
                                      {t("App.listMenuOptions.preview.level2")}
                                    </a>
                                  )}
                                  {idxParent !== 0 && (
                                    <a className="modalPreviewMenu__levelLabel__white">
                                      {t("App.listMenuOptions.preview.level2")}
                                    </a>
                                  )}
                                  <span>&nbsp;&nbsp;</span>
                                  {idxParent + 1 + ". "}
                                  {i18n.language.includes("es")
                                    ? level2.menuOptionName
                                    : level2.nameEnglish}
                                </ListItem>
                                <List
                                  disablePadding
                                  className="modalPreviewMenu__list"
                                >
                                  {level3.map((level3, idx) => {
                                    return (
                                      <Grid
                                        key={level3.pk}
                                        className="modalPreviewMenu__form-box-level3"
                                      >
                                        <ListItem disablePadding>
                                          {idx === 0 && (
                                            <a className="modalPreviewMenu__levelLabel">
                                              {t(
                                                "App.listMenuOptions.preview.level3"
                                              )}
                                            </a>
                                          )}
                                          {idx !== 0 && (
                                            <a className="modalPreviewMenu__levelLabel__white">
                                              {t(
                                                "App.listMenuOptions.preview.level3"
                                              )}
                                            </a>
                                          )}
                                          <span>&nbsp;&nbsp;</span>
                                          {idxParent + 1 + "."}
                                          {idx + 1}{" "}
                                          {i18n.language.includes("es")
                                            ? level3.menuOptionName
                                            : level3.nameEnglish}
                                        </ListItem>
                                      </Grid>
                                    );
                                  })}
                                </List>
                              </Grid>
                            );
                          }
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
          <Grid className="modalPreviewMenu__form__actions">
            <Button
              autoFocus
              type="submit"
              className="modalPreviewMenu__form__actions-buttonFilled"
              onClick={handleClose}
            >
              {t("App.buttonLabels.accept")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ModalPreviewMenu;

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

/**
 *
 * Servicio para traer el listado de aliados disponibles para el usuario
 *
 * @returns respuesta de axios con la lista de aliados disponibles
 */
export async function GetAlliesList() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: "/allies",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio para traer el listado de configuraciones de aliados para mostrar en la tabla
 *
 * @param {Object} data
 * @returns respuesta de axios con la lista de configuraciones de aliados
 */
export async function GetConfigurationsList(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "/allies/routes/administrations",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio para traer la configuración de un aliado en concreto, esto será usado en el editar
 *
 * @param {Object} data
 * @returns respuesta de axios con la configuración del aliado correspondiente
 */
export async function GetConfigurationsListToEdit(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "/allies/routes/providers",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio para traer el listado de empresas por aliado
 *
 * @param {Object} data
 * @returns respuesta axios con el listado de empresas por aliado
 */
export async function GetProviderByAlly(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "/allies/providers/availables",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio para traer el listado de comercios por empresa
 *
 * @param {Object} data
 * @returns respuesta axios con el listado de comercios por empresa
 */
export async function GetCommerceByCompany(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "/allies/traders/availables",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio para crear una configuración de aliado
 *
 * @param {Object} data
 * @returns respuesta axios con el mensaje de éxito o error
 */
export async function CreateAllyRoute(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: "/allies/routes",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Servicio para editar una configuración de aliado
 *
 * @param {Object} data
 * @returns respuesta axios con el mensaje de éxito o error
 */
export async function EditAllyRoute(data) {
  const token = await getToken();

  return axios({
    method: "PUT",
    url: "/allies/routes",
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { getUser } from "../../../pages/userSlice";
import {
  ApplyFiltersProduct,
  EditUsers,
  GetRolesByProduct,
  ResendCredentials,
} from "../../../services/admin/adminServices";
import { AvailableCompaniesPermissions } from "../../../services/user/consultRolesService";
import ModalInfo from "../Modals/modalInfo";
import PermissionsRegisters from "./permissionsRegisters";

import { UserInfoEdit } from "./userInfoEdit";
import { REGEXP } from "../../../consts/regexp";

const EditUser = ({
  clientUser,
  openEditUser,
  setOpenEditUser,
  currentCompany,
  role,
  companyId,
  country,
  fetchUsers,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Schema for the create user form
   */
  const schema = yup.object().shape({
    name: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(
        /^[a-zA-Z\u00C0-\u00FF\s]+$/,
        t("App.validationMessages.onlyString")
      ),
    lastNames: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(
        /^[a-zA-Z\u00C0-\u00FF\s]+$/,
        t("App.validationMessages.onlyString")
      ),
    email: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
    phone: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .nullable()
      .matches(
        REGEXP.SPECIAL_QA2,
        t("App.validationMessages.onlyAlphanumeric")
      ),
    permissions: yup.array().of(
      yup.object().shape({
        productList: yup
          .string()
          .required(t("App.validationMessages.required")),
        companyList: yup
          .array()
          .of(
            yup.object().shape({
              nameCompany: yup.string(),
              renameCompany: yup.string(),
              country: yup.string(),
              eanCompany: yup.string(),
            })
          )
          .min(1, t("App.validationMessages.required"))
          .typeError(t("App.validationMessages.required")),
        roleList: yup
          .string()
          .required(t("App.validationMessages.required"))
          .typeError(t("App.validationMessages.required")),
        status: yup.string().required(t("App.validationMessages.required")),
      })
    ),
  });

  /**
   * useForm
   */
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      permissions: [],
      name: "",
      lastNames: "",
      email: "",
      confirmEmail: "",
      phone: null,
    },
  });

  /**
   * useFieldArray
   */
  const {
    fields: permissionsFields,
    append: permissionsAppend,
    remove: permissionsRemove,
    update: permissionsUpdate,
  } = useFieldArray({
    control,
    name: "permissions",
  });

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingProductsAndRoles, setLoadingProductsAndRoles] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState({});

  const [isBusinessGroup, setIsBusinessGroup] = useState(true);

  const [productsList, setProductsList] = useState([]);
  const [rolesMap, setRolesMap] = useState(new Map());
  const [companiesMap, setCompaniesMap] = useState(new Map());


  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);

  /**
   * Use Effect
   */
    /**
   *  verifica si el tamaño de la pantalla es mobile o tablet 
   */
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 400);
        setIsScreenSmall(window.innerWidth < 1000);
      };
      handleResize();
    
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);  

  /**
   * Carga los productos, roles y compañías para arreglo de permisos
   */
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        setLoadingProductsAndRoles(true);
        const finalProductsList = [];
        const rolesMap = new Map();
        const companiesMap = new Map();

        //Traigo los productos activos con al menos un usuario disponible de la empresa
        let initialProductsList = await fetchProductsList(companyId, country);
        for (const product of initialProductsList) {
          //Verifico si el producto lo tiene contratado el cliente en sus permisos
          const checkedContracted = handleCheckContractedProducts(
            product.idMenuOption
          );

          //Formateo el producto
          const productFormatted = setProductFormat(
            product,
            checkedContracted ? true : false
          );

          //Traigo los roles por el idMenuOption del producto
          const initialRolesList = await fetchRolesByIdMenuOption(
            product.idMenuOption
          );
          //Formateo los roles al formato requerido por el render
          const finalRolesList = setRolesFormat(initialRolesList);

          //Traigo las empresas asociadas por el idMenuOption del producto
          let finalCompaniesList = await fetchCompaniesByIdMenuOption(
            product.idMenuOption
          );

          //Si lo tiene contratado, creo una lista única de valores de empresas
          // entre la lista de empresas de la opción contratada por el usuario y la lista de compañías del producto
          // para así lograr que la lista de empresas esté completa, con las opciones
          // contratadas por el usuario así no tenga usuarios disponibles , que se pueda seleccionar/deseleccionar
          if (checkedContracted) {
            //Formateamos lista del usuario
            const userCompanies = checkedContracted.companyList.map(
              (company) => ({
                nameCompany: `${company.nameCompany}`,
                renameCompany: `${company.country}-${company.nameCompany}`,
                country: company.country,
                eanCompany: company.eanCompany,
              })
            );

            //Unificamos lista de companias del permiso del usuario con la lista de compañias disponible
            let tmpCompanies = finalCompaniesList.concat(userCompanies);

            //Eliminamos de la lista las empresas repetidas
            finalCompaniesList = tmpCompanies.filter(
              (item, index, self) =>
                index ===
                self.findIndex(
                  (obj) => obj["eanCompany"] === item["eanCompany"]
                )
            );
          }

          //Guardo el producto en la lista de productos
          finalProductsList.push(productFormatted);

          //Guardo la lista de roles en el mapa de roles
          rolesMap.set(product.idMenuOption, finalRolesList);

          //Guardo la lista de compañías en el mapa de compañias
          companiesMap.set(product.idMenuOption, finalCompaniesList);
        }

        //Setteo la lista de productos
        const permissionsList = clientUser.permissions.map(permission => {
          return {
            value: `{ "idMenuOption": "${permission.idMenuOption}", "menuOptionName": "${permission.menuOptionName}" }`,
            roleList: permission.roleList
          };
        });
        for (const products of permissionsList) {
          const parsedValue = JSON.parse(products.value);
          const menuOptionName = parsedValue.menuOptionName;
          const idMenuOption = parsedValue.idMenuOption;
          const roleList = JSON.parse(products.roleList);
        
          // Encuentra el índice del producto en finalProductsList si ya existe
          const existingProductIndex = finalProductsList.findIndex(product => JSON.parse(product.value).idMenuOption === idMenuOption);
        
          const newProduct = {
            "label": menuOptionName,
            "value": products.value,
            "selected": true,
            "isActive": true
          };
        
          if (existingProductIndex !== -1) {
            // Si el producto ya existe, reemplaza el producto existente
            finalProductsList[existingProductIndex] = newProduct;
          } else {
            // Si el producto no existe, agrégalo como nuevo
            finalProductsList.push(newProduct);
          }
        
          if (!rolesMap.has(idMenuOption)) {
            rolesMap.set(idMenuOption, [{ label: roleList.nameRole, value: products.roleList }]);
          }
        }
        setProductsList(finalProductsList);
        //Setteo mapa de roles
        setRolesMap(rolesMap);
        //Setteo mapa de compañías
        setCompaniesMap(companiesMap);
        setLoadingProductsAndRoles(false);
      } catch (error) {
        setLoadingProductsAndRoles(false);
        console.log("============ Error editUser.jsx ============");
        console.log(error);
        console.log("========================================");
      }
    };

    if (openEditUser && clientUser) {
      fetchPermissions();
    }
  }, [openEditUser, clientUser]);

  /**
   * Actualiza el estado del tamaño de pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Carga la información del usuario y sus permisos en el modal
   */
  useEffect(() => {
    setIsLoadingPermissions(true);
    if (clientUser !== null && productsList) {
      handleSetInformation(productsList);
    } else {
      setIsLoadingPermissions(false);
    }
  }, [clientUser, productsList.length > 0]);

  /**
   * Fetch
   */

  /**
   * Función trae los productos activos que tengan al menos un usuario disponible
   * @param {*} eanCompany ean de la empresa
   * @param {*} country país de la empresa
   * @returns lista de productos
   */
  const fetchProductsList = async (eanCompany, country) => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await ApplyFiltersProduct({
        eanCompany: eanCompany,
        country: country,
      });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        return responseMessage;
      } else {
        return [];
      }
    } catch (error) {
      console.log("============ Error editUser.jsx ============");
      console.log(error);
      console.log("========================================");
      return [];
    }
  };

  /**
   * Función que trae la lista de roles según el idMenuOption
   * @param {*} idMenuOption opción de menú
   * @returns lista de roles
   */
  const fetchRolesByIdMenuOption = async (idMenuOption) => {
    try {
      const isClient = currentCompany.eanCompany !== "CASSANDRA" ? true : false;

      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetRolesByProduct({
        idMenuOption: idMenuOption,
        isClient: isClient,
      });

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        return responseMessage;
      } else {
        return [];
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      return [];
    }
  };

  /**
   * Función que trae la lista de empresas según el idMenuOption
   * @param {} idMenuOption opción de menú
   * @returns lista de empresas
   */
  const fetchCompaniesByIdMenuOption = async (idMenuOption) => {
    try {
      const obj = {
        country: currentCompany.country,
        eanCompany: currentCompany.eanCompany,
        idMenuOption,
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await AvailableCompaniesPermissions(obj);

      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            if (responseMessage.length > 0) {
              setIsBusinessGroup(true);
              return responseMessage.map((item) => {
                return {
                  nameCompany: `${item.nameCompany}`,
                  renameCompany: `${item.country}-${item.nameCompany}`,
                  country: item.country,
                  eanCompany: item.eanCompany,
                };
              });
            } else {
              return [];
            }

          case CODES.COD_RESPONSE_ERROR_RESET_PASSWORD:
            setIsBusinessGroup(false);
            const currentCompanyInfo = {
              nameCompany: `${currentCompany.companyName}`,
              renameCompany: `${currentCompany.country}-${currentCompany.companyName}`,
              country: currentCompany.country,
              eanCompany: currentCompany.eanCompany,
            };

            return [currentCompanyInfo];

          default:
            //Setteo a true el businessGroup para permitir desplegar lista vacía y evidenciar que no
            //existen campos por seleccionar (error en la carga y evitar crash)
            setIsBusinessGroup(true);
            return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      return [];
    }
  };

  /**
   * Handles
   */

  /**
   * Settea la información del usuario y sus permisos en el modal
   * @param {*} finalProducts Productos contratados activos de la empresa
   */
  const handleSetInformation = (finalProducts) => {
    setValue("name", clientUser.firstName);
    setValue("lastNames", clientUser.lastName);
    setValue("email", clientUser.email);
    setValue("phone", clientUser.phone ? clientUser.phone : null);

    //Appendeamos estos permisos al array de permisos
    if (clientUser.permissions.length > 0 && finalProducts.length > 0) {
      //Inhabilitamos los productos elegidos en la lista de productos
      const permissionsList = clientUser.permissions.map(permission => {
        return {
          value: `{ "idMenuOption": "${permission.idMenuOption}", "menuOptionName": "${permission.menuOptionName}" }`
        };
      });
      const permissionTwo = clientUser.permissions.map((item) => {
        for (const products of permissionsList) {
          if (JSON.parse(products.value).idMenuOption === item.idMenuOption) {
            return {
              productList: products.value,
              status: item.state,
              roleList: item.roleList,
              companyList: item.companyList,
              productSelection: true,
              editDisabled: item.editRecord !== "ACTIVO",
              key: generateId(),
            };
          }
        }
      });
      permissionTwo.map((item) => {
        permissionsAppend(item);
      });
      setTimeout(() => setIsLoadingPermissions(false), [3000]);
    }else{
      //TODO: Condicional de cuando no se retorno ningun permiso se comenta es porque al cargar por primera vez el modal, este carga y luego deja de cargar, parece que los permisos no cargaron
      //setTimeout(() => setIsLoadingPermissions(false), [3000]);
    }
  };

  /**
   * Función que revisa los productos del usuario y los compara con la lista de productos activos de la empresa
   * para marcar como seleccionados los productos de la lista
   * @param {*} userPermissions permisos del usuario
   * @param {*} productsList productos activos
   * @returns lista filtrada por los productos seleccionados
   */
  const reviewUserProducts = (userPermissions, productsList) => {
    const reviewedPermissions = productsList.map((item) => {
      let finalItem = undefined;

      for (const userPermission of userPermissions) {
        const product = JSON.parse(item.value);

        if (product.idMenuOption === userPermission.idMenuOption) {
          finalItem = { ...item, selected: true };

          return finalItem;
        }

        finalItem = { ...item, selected: false };
      }

      return finalItem;
    });

    return reviewedPermissions.filter((item) => item.selected);
  };

  /**
   * Función que formatea la lista de roles a el formato requerido para el render
   * @param {} rolesList lista de roles
   * @returns lista de roles formateada
   */
  const setRolesFormat = (rolesList) => {
    return rolesList.map((role) => ({
      label: role.nameRole,
      value: `{"idRole": "${role.idRole}", "nameRole": "${role.nameRole}"}`,
    }));
  };

  /**
   * Función que formatea el producto a el formato requerido para el render
   * @param {*} product producto
   * @returns producto formateado
   */
  const setProductFormat = (product, isContracted) => {
    return {
      label: product.menuOptionName,
      value: `{ "idMenuOption": "${product.idMenuOption}", "menuOptionName": "${product.menuOptionName}" }`,
      selected: isContracted,
      isActive: product.state === 1 ? true : false,
    };
  };

  /**
   * Función que verifica si el producto fue contratado por el usuario
   * @param {} idMenuOption opción de usuario a consultar
   * @returns objeto si lo tiene contratado, undefined si no lo tiene contratado
   */
  const handleCheckContractedProducts = (idMenuOption) => {
    return clientUser.permissions.find(
      (permission) => permission.idMenuOption === idMenuOption
    );
  };

  /**
   * Función que cierra el modal y resetea la lista de productos
   */
  const handleClose = async () => {
    await resetProductList();
    reset();
    setOpenEditUser(false);
  };

  /**
   * Función que actualiza el tamaño de la view
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * Función que retorna un id aleatorio
   * @returns id aleatorio
   */
  const generateId = () => {
    const id = Date.now().toString(32) + Math.random().toString(32);

    return id;
  };

  /**
   * Función que añade un nuevo permiso a la lista
   */
  const handleAddNewPermission = () => {
    const userInfoCompany = {
      nameCompany: `${currentCompany.companyName}`,
      renameCompany: `${currentCompany.country}-${currentCompany.companyName}`,
      country: currentCompany.country,
      eanCompany: currentCompany.eanCompany,
    };
    const newPermission = {
      companyList: isBusinessGroup ? [] : [userInfoCompany],
      roleList: [],
      productSelection: false,
      key: generateId(),
    };

    permissionsAppend(newPermission);
  };

  /**
   * Función que remueve un permiso de la lista
   * @param {} index posición del permiso
   */
  const handleRemovePermission = (index) => {
    const previousValue = getValues(`permissions.${index}.productList`);
    const convertedPrevious = previousValue ? JSON.parse(previousValue) : [];

    const updatedProductList = productsList.map((item) => {
      const product = JSON.parse(item.value);
      const convertedCurrent = product.idMenuOption;

      if (convertedCurrent === convertedPrevious.idMenuOption) {
        return { ...item, selected: false };
      }

      return item;
    });

    setProductsList(updatedProductList);

    permissionsRemove(index);
  };

  /**
   * Función que actualiza la lista de productos cuando el cliente hace click en el field de productos
   * @param {} event producto seleccionado
   * @param {*} previous producto previamente seleccionado
   */
  const handleProductChange = (event, previous) => {
    const selectedProduct = event.target.value;
    const selectedConverted = JSON.parse(selectedProduct);
    const previousValue = previous;
    const previousConverted = previousValue ? JSON.parse(previousValue) : "";

    // Esto permite que se actualice el array y se habiliten los campos
    // de estado y rol
    const permission = getValues("permissions.0");
    permissionsUpdate(0, permission);

    const updatedProductList = productsList.map((item) => {
      const product = JSON.parse(item.value);
      const convertedValue = product.idMenuOption;

      if (convertedValue === selectedConverted.idMenuOption) {
        return { ...item, selected: true };
      } else if (convertedValue === previousConverted.idMenuOption) {
        return { ...item, selected: false };
      }

      return item;
    });

    setProductsList(updatedProductList);
  };

  /**
   * Función para reenviar las credenciales
   */
  const handleResend = async () => {
    try {
      const obj = {
        email: clientUser.email,
      };

      const {
        status,
        data: { responseCode },
      } = await ResendCredentials(obj);

      let title =
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
          ? t("App.editUser.forwardModalInfo")
          : t("App.validationMessages.systemError2");

      setModalTitle(title);

      let responseBody = {
        status: status,
        data: { responseCode: responseCode, responseMessage: "" },
      };

      setModalContent(responseBody);
      setOpenModalInfo(true);
    } catch (error) {
      let errorBody = {
        status: CODES.COD_RESPONSE_HTTP_ERROR,
        data: {
          responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
          responseMessage: t("App.validationMessages.systemError2"),
        },
      };

      setModalTitle(t("App.validationMessages.error"));
      setModalContent(errorBody);
      setOpenModalInfo(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función que construye el arreglo de permisos según el formato requerido por back
   * @param {*} data información del usuario
   * @returns arreglo de permisos formateado
   */
  const handlEditUserObject = (data) => {
    //Construyo el objeto de usuario
    const userObject = {
      eanCompany: currentCompany.eanCompany,
      companyName: currentCompany.companyName,
      firstName: data.name,
      lastName: data.lastNames,
      email: data.email.toLowerCase(),
      phone: data.phone,
    };

    //Tomo el arreglo de permisos y lo construyo conforme cuerpos para el back
    const permissionsModification = data.permissions.map((permission) => {
      const parsedProduct = JSON.parse(permission.productList);
      const parsedRole = JSON.parse(permission.roleList);

      const companies = permission.companyList.map((company) => company);

      return {
        ...parsedProduct,
        ...parsedRole,
        lstCompanies: companies,
        state: Number(permission.status),
      };
    });

    //Construimos el arreglo final
    const userPermissionsArray = permissionsModification.map((permission) => {
      return { ...userObject, ...permission };
    });

    return userPermissionsArray;
  };

  /**
   * Función que settea el título del modalInfo según el código de respuesta
   * @param {*} responseCode código de respuesta
   * @returns título según código
   */
  const handleModalTitle = (responseCode) => {
    if (responseCode === CODES.COD_RESPONSE_ERROR_CREATE) {
      return t("App.userDetail.modalTitle.title1");
    } else {
      return "";
    }
  };

  /**
   * Función que envía la petición para editar el usuario
   * @param {*} data información del usuario
   */
  const handleEditUser = async (data) => {
    try {
      const userEditArray = handlEditUserObject(data);

      setLoadingEdit(true);

      const obj = {
        userName: userInfo.firstName + " " + userInfo.lastName,
        userCreateArray: userEditArray,
        previousEmail: clientUser.email.toLowerCase(),
        country: currentCompany.country,
      };

      const editUserService = await EditUsers(obj);

      if (
        editUserService.status === CODES.COD_RESPONSE_HTTP_OK &&
        editUserService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        fetchUsers();
      }

      const responseCode = editUserService.data.responseCode;

      setModalTitle(handleModalTitle(responseCode));
      setOpenModalInfo(true);
      setModalContent(editUserService);

      setLoadingEdit(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función que resetea los productos de la lista y los deselecciona
   */
  const resetProductList = () => {
    const resetList = productsList.map((item) => {
      return { ...item, selected: false };
    });

    setProductsList(resetList);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={openEditUser}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100%",
              maxWidth: "900px",
              maxHeight: "575px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
              overflowX: "hidden", 
            },
          },
        }}
      >
        <DialogTitleContainer onClose={handleClose}>
          <Grid container direction="column" rowSpacing={1}>
            <Grid item className="display-large__primary-one">
              {t("App.editUser.title")}
            </Grid>
          </Grid>
        </DialogTitleContainer>

        <DialogContent
          sx={{ overflowX: "hidden" }} // Evita el scroll horizontal
        >
          <Grid container direction="column" className="full-width">
            <Grid item>
              <form id="edit-form" onSubmit={handleSubmit(handleEditUser)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item container>
                    <Grid item container alignItems="center" spacing={2}>
                      <Grid item>
                        <Button
                          className={
                            clientUser?.isFirstLogin === "true"
                              ? "modalEditUsers__form-buttonResend"
                              : "modalEditUsers__form-buttonResend__disabled"
                          }
                          onClick={handleResend}
                          startIcon={<RecordVoiceOverOutlinedIcon />}
                          disabled={
                            clientUser?.isFirstLogin == "true" ? false : true
                          }
                          variant="outlined"
                        >
                          {t("App.editUser.forwardButton")}
                        </Button>
                      </Grid>

                      {clientUser?.isFirstLogin === "false" && (
                        <>
                          <Grid item lg={8} className="logout_container__text">
                            <p> {t("App.editUser.forwardText")}</p>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item>
                      <h3 className="heading__text-grey">
                        {t("App.createUser.information")}
                      </h3>
                    </Grid>
                    <br />
                    <br />
                    <Grid item>
                      <UserInfoEdit control={control} errors={errors} t={t} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item lg={1} md={2} sm={2} xs={12} paddingY={1} style={{ display: "flex", alignItems: "center" }}>
                        <h3 className="heading__text-grey">
                          {t("App.createUser.permit")}
                        </h3>
                        <Tooltip
                          title={<span style={{ textAlign: "left", fontSize: "12px" }}><p>{t("App.createUser.paragraphPermit")}</p></span>}
                          placement={isMobile ? "top" : "right-end"}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [isMobile
                                    ? 0  // No ajustar el desplazamiento horizontal en móviles
                                    : isScreenSmall
                                    ? 0  // Ajustar el desplazamiento horizontal en tabletas
                                    : 0,  // No ajustar el desplazamiento horizontal en pantallas grandes
                                  isMobile
                                    ? -10  // Ajuste vertical en móviles
                                    : isScreenSmall
                                    ? -90  // Ajuste vertical en tabletas
                                    : 0,  // Ajuste vertical en pantallas grandes
                                    ], // Ajusta la distancia del tooltip respecto al icono
                                },
                              },
                              {
                                name: 'preventOverflow',
                                options: {
                                  padding: isMobile ? 4 : 8, // Asegúrate de que no se salga de la pantalla
                                },
                              },
                            ],
                          }}
                        
                        >
                        <InfoOutlinedIcon sx={{ textAlign:"right", fontSize: "22px", color: "#543AB4", marginLeft: "12px" }} />{" "}
                      </Tooltip>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    container
                    className="permissionBox"
                    width={"100%"}
                    sx={{ overflowX: "hidden" }} // Evita el scroll horizontal
                  >
                    {isLoadingPermissions ? (
                      <Grid
                        container
                        className="col-flex"
                        style={{ marginTop: "1%" }}
                      >
                        <CircularProgress
                          value={90}
                          size={21}
                          style={{ color: "#553ab6" }}
                        />
                      </Grid>
                    ) : (
                      <PermissionsRegisters
                        permissionsFields={permissionsFields}
                        handleAddNewPermission={handleAddNewPermission}
                        handleRemovePermission={handleRemovePermission}
                        handleProductChange={handleProductChange}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        productsList={productsList}
                        errors={errors}
                        loadingProductsAndRoles={loadingProductsAndRoles}
                        isBusinessGroup={isBusinessGroup}
                        rolesMap={rolesMap}
                        companiesMap={companiesMap}
                      />
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction={isDesktop ? "row" : "column-reverse"}
            className="col-flex"
            columnGap={4}
            sx={{ padding: "1.5%" }}
          >
            <Grid item xs={12} sm={2} lg={1} className="full-width">
              <Button
                className="btn__filled__gradient full-width"
                onClick={handleClose}
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} lg={2.2} className="full-width">
              <Button
                disabled={
                  watch("name") && watch("lastNames") && watch("email")
                    ? false
                    : true
                }
                className={`${
                  watch("name") && watch("lastNames") && watch("email")
                    ? "btn__applyFilter"
                    : "btn__applyFilter-disabled"
                } full-width`}
                type="submit"
                form="edit-form"
                startIcon={!loadingEdit ? <CheckIcon /> : <></>}
              >
                {!loadingEdit ? (
                  t("App.editUser.confirmButton")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <ModalInfo
        title={modalTitle}
        responseData={modalContent}
        onClose={() => {
          handleClose();
          setOpenModalInfo(false);
        }}
        open={openModalInfo}
      />
    </>
  );
};

export default EditUser;

import React, { useEffect, useState } from "react";
import { CODES } from "../../../consts/codes";
import {
  DownloadLogs,
  DownloadReports,
  ListReports,
} from "../../../services/user/listDynamicReportService";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  createTheme,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import PeriodicReportState from "../../../components/common/DynamicReport/periodicReportState";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { useNavigate, useParams } from "react-router-dom";
import FilterPeriodicReportsCEN from "../../../components/common/DynamicReport/filterPeriodicReportsCEN";
import MUIDataTable from "mui-datatables";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import ModalSeeReportCompanies from "../../../components/common/CENCO/modalSeeReportCompanies";
import {
  ListReportsCEN,
  ListReportsCENByFilter,
} from "../../../services/user/dynamicReportCENFiscal/dynamicReportCENFiscal";
import ModalInfo from "../../../components/common/Modals/modalInfo";
const ListPeriodicReportCEN = () => {
  const { type } = useParams();

  const [data, setData] = useState([]);
  const [noReports, setNoReports] = useState(false);
  const [noFound, setNoFound] = useState(false);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState([]);

  const [filters, setFilters] = useState({});

  const { t, i18n } = useTranslation();

  const userInfo = useSelector(getUser);

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const optionsDataTable = {
    download: false,
    print: "false",
    search: false,
    searchOpen: true,
    selectableRows: "none",
    viewColumns: false,
    filter: false,
    jumpToPage: true,
    rowHover: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: t("App.listDynamicPeriodicReport.search"),
    elevation: 0,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  const columns = [
    {
      name: "creationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column1"),
      options: {
        sort: true,
        searchable: false,
      },
    },
    {
      name: "creationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column2"),
      options: {
        sort: true,
      },
    },
    {
      name: "modificationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column3"),
      options: {
        sort: true,
        searchable: false,
        hint: t(
          "App.listDynamicPeriodicReport.cen.table.tooltip.modificationDate"
        ),
      },
    },
    {
      name: "modificationUser",
      label: t("App.listDynamicPeriodicReport.table.columns.column4"),
      options: {
        sort: true,
        searchable: false,
        hint: t(
          "App.listDynamicPeriodicReport.cen.table.tooltip.modificationUser"
        ),
      },
    },
    {
      name: "expirationDate",
      label: t("App.listDynamicPeriodicReport.table.columns.column5"),
      options: {
        sort: true,
        searchable: false,
      },
    },
    {
      name: "company",
      label: t("App.listUsers.enterpriseN"),
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <p style={{ minWidth: "120px" }}>{value?.companies ?? value}</p>
        ),
      },
    },
    {
      name: "generationFrequency",
      label: t("App.listDynamicPeriodicReport.table.columns.column6"),
      options: {
        sort: true,
        searchable: false,
      },
    },
    {
      name: "fileName",
      label: t("App.listDynamicPeriodicReport.table.columns.column8"),
      options: {
        filter: true,
        searchable: true,
        sort: true,
        hint: t("App.listDynamicPeriodicReport.tooltips.tooltip3"),
        customHeadLabelRender: (columnMeta) => (
          <p style={{ width: "130px" }}>{columnMeta.label}</p>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            style={{ padding: "2%" }}
            label=""
            value={value.pathFileName && value.fileName}
            control={
              <a
                style={{
                  cursor: "pointer",
                }}
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  if (value.pathFileName) {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.pathFileName && value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "status",
      label: t("App.listDynamicPeriodicReport.table.columns.column9"),
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (value) => <PeriodicReportState value={value} />,
      },
    },
    {
      name: "detailErrors",
      label: t("App.listDynamicPeriodicReport.table.columns.column10"),
      options: {
        sort: true,
        searchable: false,
        hint: t("App.listDynamicPeriodicReport.tooltips.tooltip4"),
        customHeadLabelRender: (columnMeta) => (
          <p style={{ width: "130px" }}>{columnMeta.label}</p>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            style={{ padding: "1%" }}
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: "pointer",
                }}
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  if (value.logFileName) {
                    handleDownloadLogs(
                      value.logFileName,
                      value.pathLogFileName
                    );
                  }
                }}
              >
                {value.logFileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "edit",
      label: t("App.listDynamicPeriodicReport.table.columns.column11"),
      options: {
        sort: false,
      },
    },
  ];

  // Nombres de los meses para las etiquetas de filtro
  const namesMonth = {
    spanish: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    english: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  /**
   * Función para descargar el archivo
   * @param {*} fileName
   * @param {*} path
   */
  const handleDownloadFile = async (fileName, path) => {
    const obj = {
      pathFileName: path,
      generatedFileName: fileName,
    };

    try {
      const fileService = await DownloadReports(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Obtener la lista de empresas grupo empresarial
   * y los datos iniciales para la tabla
   */
  useEffect(() => {
    if (userInfo) {
      getListBusinessGroup();
      fetchInitialData();
    }
  }, [userInfo, type]);

  /**
   * Obtener lista de empresas grupo empresarial
   */
  const getListBusinessGroup = async () => {
    try {
      const listService = await ListBusinessGroup({
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        email: userInfo.email,
        typeOption: 1,
      });
      if (
        listService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let list = listService.data.objectData;
        const companies =
          list.length > 1
            ? [
                { renameCompany: t("App.downloadSalesForce.selectAll") },
                ...list,
              ]
            : list;
        setCompaniesList(companies);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("-----------------error------------------");
      console.log(error);
      console.log("------------------------------------");
    }
  };

  /**
   * Función para descargar el archivo de log
   * @param {*} fileName
   * @param {*} path
   */
  const handleDownloadLogs = async (fileName, path) => {
    const obj = {
      pathLogFileName: path,
      logFileName: fileName,
    };

    try {
      const fileService = await DownloadLogs(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS ||
          fileService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Filtrar los datos de la tabla
   * @param {*} data
   */
  const filterData = async (data) => {
    setHasFilter(true);
    try {
      // Estructurar informacióan para las etiquetas de filtro
      setOptionsFilter([
        data.numberMonth !== -1
          ? {
              label: t("App.listDynamicPeriodicReport.expirationDate"),
              value: i18n.language.includes("es")
                ? namesMonth.spanish[data.numberMonth] + " de " + data.year
                : namesMonth.english[data.numberMonth] + " " + data.year,
            }
          : {
              label: "",
              value: "",
            },
        {
          label: t("App.listDynamicPeriodicReport.state"),
          value: data.statusLabel,
        },
      ]);

      // Estructurar información para el objeto de filtro
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        emails: userInfo.email,
        type: type,
        expirationDate: data.expirationDate,
        state: data.status,
        lstCompanies: data.company,
      };

      const reportsService = await ListReportsCENByFilter(obj);
      if (
        reportsService.status === CODES.COD_RESPONSE_SUCCESS &&
        reportsService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        const responseMessage = reportsService.data.responseMessage;

        const processRows = dataStructure(responseMessage);
        if (processRows.length > 0) {
          setData(processRows);
          setNoFound(false);
        } else {
          setNoFound(true);
        }
        setNoReports(false);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Obtener datos iniciales para la tabla
   */
  async function fetchInitialData() {
    setData(skeletonLine);
    setHasFilter(true);
    try {
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        emails: userInfo.email,
        type: type,
      };

      const reportsService = await ListReportsCEN(obj);

      if (
        reportsService.status === CODES.COD_RESPONSE_HTTP_OK &&
        reportsService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const responseMessage = reportsService.data.responseMessage;

        const processRows = dataStructure(responseMessage);

        if (processRows.length > 0) {
          setNoFound(false);
          setNoReports(false);
        } else {
          setNoFound(true);
          setNoReports(true);
        }
        setData(processRows);

        setUnfilteredData(processRows);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }

  /**
   * Estructura de los datos para la tabla
   */
  const dataStructure = (data) => {
    return data.map((item, idx) => {
      return {
        creationDate: !item.creationDate ? " - " : item.creationDate,
        creationUser: !item.creationUser ? " - " : item.creationUser,
        modificationDate: !item.modificationDate
          ? " - "
          : item.modificationDate,
        modificationUser: !item.modificationUser
          ? " - "
          : item.modificationUser,
        expirationDate: !item.expirationDate ? " - " : item.expirationDate,
        company: !item.lstCompanies
          ? ""
          : item.lstCompanies.length > 1
          ? {
              renameCompany: item.lstCompanies[0].nameCompany,
              companies: <ModalSeeReportCompanies data={item.lstCompanies} />,
            }
          : item.lstCompanies[0].nameCompany,

        generationFrequency: !item.generationFrecuency
          ? " - "
          : item.generationFrequency,
        fileName: !item.generatedFileName
          ? " - "
          : {
              fileName: item.generatedFileName ? item.generatedFileName : "",
              fileNameOrignal: item.generatedFileName
                ? item.generatedFileName
                : "",
              pathFileName: item.pathFileName ? item.pathFileName : "",
              // state: !master.state ? "" : master.state,
            },
        detailErrors: !item.logFileName
          ? " - "
          : {
              logFileName: item.logFileName,
              pathLogFileName: item.pathLogFileName,
              // state: !master.state ? "" : master.state,
            },
        status: !item.state
          ? ""
          : calculateRestDays(item.expirationDate, item.state),
        edit: (
          <IconButton
            onClick={() =>
              navigate(
                `/user/editPeriodicReportCEN/${type}/${item.pk.split("#")[1]}/${
                  item.sk.split("#")[1]
                }`
              )
            }
          >
            <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
          </IconButton>
        ),
      };
    });
  };

  /**
   * Obtener el estado del reporte según la fecha de expiración
   * @param {*} date fecha de expiración
   * @param {*} state estado del reporte
   * @returns el estado del reporte
   */
  const calculateRestDays = (date, state) => {
    const currentDate = new Date();
    const expirationDate = new Date(date);
    const diferenciaMilisegundos = expirationDate - currentDate;
    const diferenciaDias = Math.floor(
      diferenciaMilisegundos / (1000 * 60 * 60 * 24)
    );
    if (diferenciaDias < 0 && state === "INACTIVO") {
      return "INACTIVO VENCIDO";
    } else if (diferenciaDias > 0 && diferenciaDias <= 30) {
      return "ACTIVO PROXIMO A VENCER";
    } else {
      return state;
    }
  };

  const skeletonLine = [
    {
      creationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      creationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modificationUser: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      expirationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      generationFrequency: (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      ),
      company: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      typeOfDataGrouping: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      fileName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      status: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      detailErrors: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  const navigate = useNavigate();

  return (
    <Grid container direction="column" rowSpacing={5}>
      <Grid item>
        <p className="display-large__primary-one">
          {t("App.listDynamicPeriodicReport.title")}
        </p>
      </Grid>

      <Grid item>
        <Grid container justifyContent={"flex-end"}>
          <Grid item>
            <Button
              className="btn__applyFilter"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/user/createPeriodicReportCEN/${type}`)}
            >
              {t("App.listDynamicPeriodicReport.createButton")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ maxWidth: "100% !important" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="companies_info_container full-width"
          gap={2}
        >
          <Grid item width="100%">
            <Accordion className="accordion__filter" expanded>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "#543ab4",
                  }}
                />

                <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                  {t("App.listUsers.filter")}
                </p>
              </AccordionSummary>

              <AccordionDetails>
                <FilterPeriodicReportsCEN
                  unfilteredData={unfilteredData}
                  setData={setData}
                  data={data}
                  setNoReports={setNoReports}
                  setFilters={setFilters}
                  setNoFound={setNoFound}
                  filterData={filterData}
                  companiesList={companiesList}
                  calculateRestDays={calculateRestDays}
                  setHasFilter={setHasFilter}
                  type={type}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          {hasFilter && (
            <Grid item width="100%">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {optionsFilter.map((option) => {
                  return option.value !== "" ? (
                    <Grid item>
                      <Chip
                        label={option.label + ": " + option.value}
                        variant="outlined"
                      />
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </Grid>
          )}

          {!noFound ? (
            <MUIDataTable
              className="dataTable__salesForce datatable-style full-width"
              data={data}
              columns={columns}
              options={optionsDataTable}
            />
          ) : noReports ? (
            <NoInfoComponent type="dynamicReport" />
          ) : (
            <NoMatchImage />
          )}
        </Grid>
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default ListPeriodicReportCEN;

import { useTranslation } from "react-i18next";

const TypeDocState = ({ value }) => {
  const { t } = useTranslation();

  const transformValue = value && !value.$$typeof ? value.toLowerCase() : "";
  let text = "";
  let textColor = "";
  switch (transformValue) {
    case "stock":
      text = t("App.loadMapping.table.typeDoc.type.inventory");
      textColor = "typedoc-colors__inventories";
      break;
    case "sales":
      text = t("App.loadMapping.table.typeDoc.type.sales");
      textColor = "typedoc-colors__sales";
      break;
    default:
      text = t("App.loadMapping.table.typeDoc.type.saleStock");
      textColor = "typedoc-colors__sales-invetories";
      break;
  }

  return (
    <p className={`${textColor} text-transform word-break__keep`}>{text}</p>
  );
};

export default TypeDocState;

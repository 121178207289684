import { React, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CODES } from "../../consts/codes";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DeleteRole } from "../../services/admin/roleServices";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "12px",
  bgcolor: "white",
  padding: "1rem !important",
  p: 4,
};

export const ModalDeleteRole = (props) => {
  const [open, setOpen] = useState(false);

  const [error, setError] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      const obj = {
        pk: props.role.pk,
        sk: props.role.sk,
      };

      const roleService = await DeleteRole(obj);

      if (roleService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (roleService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          props.setModified(!props.modified);
          props.setRefreshFilters(!props.refreshFilters);
          setOpen(false);
        } else {
          setError(true);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <div>
      <IconButton onClick={handleOpen} component="span">
        <DeleteOutlineOutlinedIcon sx={{ fontSize: 22, color: "#E59500" }} />
      </IconButton>

      <Modal open={open} onClose={handleClose} className="btn__uploadNew">
        <Box sx={style}>
          {!error && (
            <>
              <>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item container justifyContent="flex-end">
                    <IconButton onClick={handleClose} component="span">
                      <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <ErrorOutlineOutlinedIcon
                      sx={{
                        color: "#E59500",
                        fontSize: "4rem",
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <p className="heading-secondary__md">
                      {t("App.validationMessages.confirmDeleteRole", {
                        name: props.role.nameRole,
                      })}
                    </p>
                  </Grid>

                  <Grid item container justifyContent="flex-end">
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      style={{ paddingTop: "3rem" }}
                    >
                      <Grid item>
                        <Button
                          type="button"
                          disableRipple
                          className="btn__filled__gradient"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          {t("App.buttonLabels.cancel")}
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          disableRipple
                          type="submit"
                          className="btn__applyFilter"
                          onClick={handleDelete}
                        >
                          {t("App.buttonLabels.accept")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </>
          )}

          {error && (
            <>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item container justifyContent="flex-end">
                  <IconButton onClick={handleClose} component="span">
                    <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
                  </IconButton>
                </Grid>

                <Grid item>
                  <ErrorOutlineOutlinedIcon
                    sx={{
                      color: "#E59500",
                      fontSize: "4rem",
                    }}
                  />
                </Grid>

                <Grid item>
                  <p className="heading-secondary__md">
                    {t("App.validationMessages.deleteRoleError", {
                      name: props.role.nameRole,
                    })}
                  </p>
                </Grid>

                <Grid item>
                  <p className="default-text_black">
                    {t("App.validationMessages.deleteRoleErrorMessage")}
                  </p>
                </Grid>

                <Grid item container justifyContent="flex-end">
                  <Button
                    type="button"
                    disableRipple
                    className="label-Primary__uploadButton"
                    onClick={() => {
                      setError(false);
                      setOpen(false);
                    }}
                  >
                    {t("App.buttonLabels.accept")}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getUser } from "../../userSlice";
import { useSelector } from "react-redux";
import { CardAccessFillRateIndicators } from "../../../components/common/AccessIndicators/cardAccessFillRateIndicators";
import { useEffect, useState } from "react";
import { GetIndicators } from "../../../services/user/salesIndicatorsService";
import { CODES } from "../../../consts/codes";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import ModalInfo from "../../../components/common/Modals/modalInfo";

const FillRateIndicator = () => {
  /**
   * Use Translation
   */
  const { t, i18n } = useTranslation();

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  const [indicators, setIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  /**
   *  Obtener los indicadores de nivel de servicio al cargar la página
   */
  useEffect(() => {
    if (userInfo) {
      getIndicators();
    }
  }, [userInfo]);

  /**
   * Obtener los indicadores de nivel de servicio
   */
  const getIndicators = async () => {
    try {
      setIsLoading(true);
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        indicatorType:
          userInfo.company.typeCompany === "C"
            ? "Commerce Service Level"
            : "Provider Service Level",
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetIndicators(obj);
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        setIndicators(responseMessage);
      } else {
        setIndicators([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(" --------- ERROR FILL RATE INDICATOR ---------");
      console.log(error);
      console.log(" ---------------------------------------------");
      setOpenErrorModal(true);
      setIsLoading(false);
    }
  };

  return (
    <Grid container direction="column" rowGap={5}>
      <Grid item>
        <p className="display-large__primary-one">{t("App.fillRate.title")}</p>
      </Grid>

      <Grid container spacing={2} className="container-indicator">
        {isLoading ? (
          <CustomSkeleton />
        ) : (
          indicators.map((data, id) => {
            return (
              <Grid item lg={4} xs={12} sm={9} md={6} key={id + ""}>
                <CardAccessFillRateIndicators
                  key={id}
                  icon={data.icon}
                  title={
                    i18n.language.includes("es") ? data.name : data.nameEnglish
                  }
                  text={
                    i18n.language.includes("es")
                      ? data.description
                      : data.descriptionEnglish
                  }
                  buttonText={t("App.salesIndicators.buttonText")}
                  link={data.url}
                  userInfo={userInfo}
                />
              </Grid>
            );
          })
        )}
      </Grid>
      {/* Modal para fallo de servicios */}

      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default FillRateIndicator;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";
import DownloadGuide from "../../../components/common/UploadFile/downloadGuide";
import UploadFile from "../../../components/common/UploadFile/uploadFile";
import { FilterSalesForce } from "../../../components/common/filterSalesForce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import { DataTable } from "../../../components/DataTable/DataTable";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import FileState from "../../../components/common/fileState";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import { CODES } from "../../../consts/codes";
import {
  GetExternalFileGuide,
  GetListUploadesExternalFiles,
  UploadExternal,
} from "../../../services/user/externalFiles/externalFilesService";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { useTranslation } from "react-i18next";
import { DownloadFile } from "../../../services/user/tracebility/traceabilityService";
const UploadExternalFile = ({ userInfo, currentType, sender, receiver }) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.rateMaster.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };
  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.rateMaster.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "starDate",
      label: t("App.externalFiles.table.startDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.traceabilityExternal.table.uploadDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "endDate",
      label: t("App.externalFiles.table.endDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.externalFiles.table.endDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "user",
      label: t("App.externalFiles.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "file",
      label: t("App.externalFiles.table.uploadedFile"),
      options: {
        filter: false,
        sort: true,
        searchable: true,

        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value.fileName}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) =>
                  handleDownloadFile(value.fileName, value.pathFileName)
                }
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.externalFiles.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "errors",
      label: t("App.externalFiles.table.errorDetail"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              label=""
              value={value.logFileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) => {
                    if (
                      value.logFileName !== "Error" &&
                      value.logFileName !== ""
                    ) {
                      handleDownloadFile(
                        value.logFileName,
                        value.pathLogFileName
                      );
                    } else {
                      setOpenModalLogError(true);
                    }
                  }}
                >
                  {value.logFileName}
                </a>
              }
            />
          );
        },
      },
    },
  ];

  /**
   * Configuración para las columnas de la tabla cuando se está cargando la data
   */
  const SkeletonColumns = [
    {
      name: "starDate",
      label: t("App.externalFiles.table.startDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.traceabilityExternal.table.uploadDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "endDate",
      label: t("App.externalFiles.table.endDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.externalFiles.table.endDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "userLoad",
      label: t("App.externalFiles.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "fileName",
      label: t("App.externalFiles.table.uploadedFile"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "state",
      label: t("App.externalFiles.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "errors",
      label: t("App.externalFiles.table.errorDetail"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
  ];

  /**
   * Skeleton para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonLine = [
    {
      starDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      endDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      userLoad: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      fileName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      state: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      logError: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];
  /**
   * Use State
   */

  //Archivo seleccionado
  const [fileSelected, setFileSelected] = useState(null);
  //Modal respuesta carga maestro
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  // Manejo modal para permitir carga de archivos
  const [openModalPermit, setOpenModalPermit] = useState(false);
  //indica si se encontró información
  const [isMastersUploaded, setIsMastersUploaded] = useState(true);
  //indica si se aplicó un filtro
  const [filterApply, setFilterApply] = useState(false);
  //indica si se encontró un filtro
  const [filterNotFound, setFilterNotFound] = useState(false);
  //indica si se está cargando la data
  const [isLoadingData, setIsLoadingData] = useState(false);
  //indica si se está actualizando la tabla
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  // Información para la tabla
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [data, setData] = useState([]);
  // modal log error
  const [openModalLogError, setOpenModalLogError] = useState(false);
  // modal carga exitosa
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  // modal error
  const [openErrorModal, setOpenErrorModal] = useState(false);
  // modal warning
  const [openModalWarning, setOpenModalWarning] = useState(false);

  useEffect(() => {
    if (userInfo) {
      getUploadFiles();
    }
  }, [userInfo, currentType]);

  /**
   *  Obtener el tipo de documento seleccionado
   * @returns tipo de documento seleccionado
   */
  const getType = () => {
    return currentType === "1"
      ? "sales"
      : currentType === "2"
      ? "stock"
      : "sales_stock";
  };
  /**
   * Handles
   */
  /**
   *
   * Función para descargar la guía de cómo llenar la plantilla
   *
   * @param {Event} event
   */
  const handleDownloadGuide = async (event) => {
    event.preventDefault();
    try {
      const {
        status,
        data: {
          responseCode,
          responseMessage: { fileContent, fileName },
        },
      } = await GetExternalFileGuide(getType());

      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          fileDownload(decode(fileContent), fileName);
        }
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Obtener la lista de archivos cargados al iniciar la página
   */
  const getUploadFiles = async () => {
    try {
      setData(skeletonLine);
      setIsLoadingData(true);

      const obj = {
        idFileType: 27,
        eanCompany: userInfo.company.eanCompany,
        country: userInfo.company.country,
        documentType: getType(),
        typeCompany: userInfo.company.typeCompany,
      };
      const {
        data: { responseCode, responseMessage },
        status,
      } = await GetListUploadesExternalFiles(obj);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          setData(setDataStructure(responseMessage));
          setUnfilteredData(setDataStructure(responseMessage));
          setFilterNotFound(false);
          setIsMastersUploaded(true);
        } else if (responseCode === CODES.COD_NO_INFO_FOUND) {
          setFilterNotFound(false);
          setIsMastersUploaded(false);
          setData(setDataStructure([]));
        }
      } else {
        setFilterNotFound(true);
        setIsMastersUploaded(false);
        setData(setDataStructure([]));
      }
    } catch (error) {
      setOpenErrorModal(true);
      setFilterNotFound(true);
      setIsMastersUploaded(false);
      setData(setDataStructure([]));
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
    setIsLoadingData(false);
  };

  /**
   * Encuentra el nombre del tipo de documento elegido de acuerdo al id que tiene currentType
   * @returns nombre del tipo de documento seleccionado
   */
  const getMasterName = () => {
    return currentType === "1"
      ? t("App.externalFiles.select.sales")
      : currentType === "2"
      ? t("App.externalFiles.select.inventory")
      : t("App.externalFiles.select.salesStock");
  };

  /**
   * Encuentra el nombre y tipo de la empresa emisora/receptora
   */

  const getCompanyName = () => {
    if (userInfo.company.typeCompany === "F") {
      return sender ? sender.companyName : "";
    }
    if (userInfo.company.typeCompany === "C") {
      return receiver ? receiver.companyName : "";
    }
  };
  /**
   * Encuentra el tipo de empresa para mostrar en el modal
   */
  const getTypesModal = () => {
    if (userInfo.company.typeCompany === "F") {
      return [t("App.externalFiles.select.issuer")];
    }
    if (userInfo.company.typeCompany === "C") {
      return [t("App.externalFiles.select.receiver")];
    }
  };

  /**
   * Funciones para aceptar/rechazar la carga del archivo
   */

  const openPermitWindow = () => {
    setOpenModalPermit(true);
  };

  /*
   * Función para permitir la carga del archivo
   */
  const handleAccept = () => {
    handleUploadFile();
    setOpenModalPermit(false);
  };
  /*
   * Función para rechazar la carga del archivo
   */
  const handleReject = () => {
    setOpenModalPermit(false);
  };

  /**
   * Función que carga el archivo elegido a la plataforma
   */

  const handleUploadFile = async () => {
    try {
      const obj = {
        idFileType: 27,
        eanCompany: userInfo.company.eanCompany,
        fileName: fileSelected[0].name,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        emailLoad: userInfo.email,
        userLoad: `${userInfo.firstName} ${userInfo.lastName}`,
        fileUpload: fileSelected[0],
        country: userInfo.company.country,
        eanReceiver: receiver.eanCompany,
        eanIssuer: sender.eanCompany,
        documentType: getType(),
      };
      const {
        status,
        data: { responseMessage, responseCode },
      } = await UploadExternal(obj);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          setTextModal(responseMessage);
          setOpenSuccessLoadModal(true);
          setFileSelected(null);
          await getUploadFiles();
        } else {
          setTextModal(responseMessage);
          setOpenModalWarning(false);
        }
      } else {
        setTextModal(responseMessage);
        setOpenModalWarning(false);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("------------------------------------");
      console.log(error);
      console.log("------------------------------------");
    }
  };

  /**
   *
   * Función para descargar el archivo que fue cargado (subido)
   * Solo si el servicio responde correctamente se descarga el archivo
   * caso contrario no descargará nada
   *
   * @param {string} fileName: nombre del archivo
   * @param {string} pathFileName : ruta del archivo
   *
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    try {
      const obj = {
        fileName: fileName,
        pathFileName: pathFileName,
      };
      const {
        data: { data, responseCode },
        status,
      } = await DownloadFile(obj);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(decode(data.fileContent), data.fileName);
        } else {
          setOpenErrorModal(true);
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log(" ---------- ERROR ----------");
      console.log(error);
      console.log("-----------------------------");
    }
  };

  /**
   *
   * Función para estructurar la data para la tabla
   *
   * @param {Array} data
   * @returns data formateada
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        starDate: !item.starDate ? "" : item.starDate,
        endDate: !item.endDate
          ? ""
          : item.state.toUpperCase() === "CARGA TOTAL" ||
            item.state.toUpperCase() === "CARGA PARCIAL" ||
            item.state.toUpperCase() === "RECHAZADO POR ESTRUCTURA" ||
            item.state.toUpperCase() === "ERROR SISTEMA"
          ? item.endDate
          : "",
        user: !item.userLoad ? "" : item.userLoad,
        file: !item.fileName
          ? ""
          : { fileName: item.fileName, pathFileName: item.pathFileName },
        state: !item.state ? " - " : item.state,
        errors: !item.pathLogFileName
          ? {
              logFileName: "",
              pathLogFileName: "",
            }
          : item.state.toUpperCase() !== "PENDIENTE"
          ? item.state.toUpperCase() !== "ERROR SISTEMA"
            ? {
                logFileName: item.logFileName,
                pathLogFileName: item.pathLogFileName,
              }
            : {
                logFileName: "Error",
                pathLogFileName: "Error",
              }
          : {
              logFileName: "",
              pathLogFileName: "",
            },
      };
    });
    return processRows;
  };

  /**
   * Refresca la tabla de cargas del maestro seleccionado
   */
  const handleRefresh = async () => {
    try {
      setIsLoadingRefresh(true);

      await getUploadFiles();
    } catch (error) {
      setOpenErrorModal(true);
      console.log("------------------------------------");
      console.log("error");
      console.log("------------------------------------");
    }
    setIsLoadingRefresh(false);
  };

  return (
    <Grid container direction="column" rowSpacing={1} className="full-width">
      <Grid item className="full-width">
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <DownloadGuide
            helpText={"App.salesForce.guide"}
            handleDownloadGuide={handleDownloadGuide}
          />
        </Grid>
      </Grid>
      <Grid item className="full-width">
        <UploadFile
          dragText={t("App.externalFiles.dragging")}
          handleUploadFile={openPermitWindow}
          fileSelected={fileSelected}
          setFileSelected={setFileSelected}
          setErrorModal={setIsOpenModalResponse}
          setErrorTitle={setModalTitle}
          type={true}
        />
      </Grid>
      {isMastersUploaded && (
        <Grid item className="full-width">
          <Grid
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            columnSpacing={2}
            rowSpacing={2}
            textAlign={"center"}
          >
            <Grid
              lg={"auto"}
              sm={"auto"}
              xs={12}
              item
              className="download-explanation__externalFiles"
            >
              {t("App.rateMaster.refresh")}
            </Grid>
            <Grid lg={"auto"} sm={"auto"} xs={12} item>
              <Button
                disableRipple
                disabled={isLoadingRefresh}
                startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                className="btn__outlinedExternal__blue full-width"
                onClick={handleRefresh}
              >
                {!isLoadingRefresh ? (
                  t("App.catalogue.update")
                ) : (
                  <CircularProgress size={23} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid
          container
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            {" "}
            <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
          </Grid>
          <Grid item className="display-small__primary-one">
            {" "}
            {t("App.salesForce.subtitle")}
          </Grid>
        </Grid>
      </Grid>
      {isMastersUploaded && (
        <Grid item className="full-width">
          <Accordion className="accordion__filter_externalFiles" style={{ width: "100%"}}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.catalogue.filterName")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterSalesForce
                userInfo={userInfo}
                unfilteredData={unfilteredData}
                setData={setData}
                skeletonLine={skeletonLine}
                setFilterApply={setFilterApply}
                idFile={27}
                setIsLoadingData={setIsLoadingData}
                setFilterNotFound={setFilterNotFound}
                documentType={getType()}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      <Grid item className="chips-container full-width">
        {isMastersUploaded && filterApply && (
          <Chip className="chip__filter" label={filterApply.label} />
        )}
      </Grid>
      <Grid item className="full-width">
        {!filterNotFound ? (
          isMastersUploaded ? (
            <DataTable
              loading={isLoadingData}
              data={data}
              columns={isLoadingData ? SkeletonColumns : columns}
              skeletonOptions={skeletonOptions}
              options={options}
            />
          ) : (
            <NoInfoComponent type="load" />
          )
        ) : (
          <NoMatchImage />
        )}
      </Grid>
      {/* Modal log Error estado archivo Error Sistema */}
      <ModalInfo
        title={t("App.traceabilityExternal.modals.error.title")}
        responseData={{
          data: {
            responseMessage: t("App.traceabilityExternal.modals.error.message"),
          },
        }}
        open={openModalLogError}
        onClose={() => {
          setOpenModalLogError(false);
        }}
      />
      {/* Modal para confirmara la carga del archivo */}
      <ModalDecision
        title={t("App.externalFiles.modalDecision.title", {
          typeDoc: getMasterName(),
        })}
        message={t("App.externalFiles.modalDecision.message", {
          type: getTypesModal(),
          company: getCompanyName(),
        })}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setOpenModalPermit}
        isOpen={openModalPermit}
        handleAgree={handleAccept}
        handleDisagree={handleReject}
      />
      {/* Modal para indicar estado proceso de carga del archivo */}
      <ModalInfo
      className="modal-title"
        title={t("App.externalFiles.modal.titleUpload", {
          typeDoc: getMasterName(),
        })}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: !isOpenModalResponse ? textModal : modalTitle,
          },
        }}
        open={openSuccessLoadModal || isOpenModalResponse}
        onClose={() => {
          openSuccessLoadModal
            ? setOpenSuccessLoadModal(false)
            : setIsOpenModalResponse(false);
        }}
      />
      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />

      <ModalInfo
        title={t("App.validationMessages.warning")}
        responseData={{
          status: CODES.COD_RESPONSE_HTTP_BAD_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_BAD_REQUEST,
            responseMessage: textModal,
          },
        }}
        open={openModalWarning}
        onClose={() => {
          setOpenModalWarning(false);
        }}
      />
    </Grid>
  );
};

export default UploadExternalFile;

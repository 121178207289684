import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DownloadTemplate from "../../../../components/common/UploadFile/downloadTemplate";
import DownloadGuide from "../../../../components/common/UploadFile/downloadGuide";
import { useEffect, useState } from "react";
import UploadFile from "../../../../components/common/UploadFile/uploadFile";
import LoopIcon from "@mui/icons-material/Loop";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import MUIDataTable from "mui-datatables";

import NoMatchImage from "../../../../components/common/NoMatchImage/noMatchImage";
import FileState from "../../../../components/common/fileState";
import { FilterSalesForce } from "../../../../components/common/filterSalesForce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useSelector } from "react-redux";
import { getUser } from "../../../userSlice";
import { NoInfoComponent } from "../../../../components/common/NoInfoImage/noInfoComponent";
import {
  DownloadGuideTraders,
  DownloadTemplateTraders,
  GetCatalogsTraders,
  GetPreviewCatalogTraders,
  UploadCatalogTradersRequest,
} from "../../../../services/user/traders/catalogTradersService";
import { CODES } from "../../../../consts/codes";
import ModalSeeCompanies from "../../../../components/common/AdminUsersClient/modalSeeCompanies";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import ModalInfo from "../../../../components/common/Modals/modalInfo";
import ModalPreviewCatalogue from "../../../../components/common/Modals/modalPreviewCatalogue";
import {
  DownloadCatalogErrorLog,
  DownloadCatalogueFile,
} from "../../../../services/user/catalogueService";

export const UploadCatalogTraders = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Tabla
   */

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.catalogue.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.catalogue.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.catalogue.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "file",
      label: t("App.catalogue.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value.fileName}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) =>
                  handleDownloadFile(value.fileName, value.pathFileName)
                }
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.traders.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "errors",
      label: t("App.catalogue.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              value.awsUploadLog ? (
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) => {
                    setModalTitle(t("App.validationMessages.error"));
                    setModalContent({
                      status: 500,
                      data: {
                        responseMessage: t(
                          "App.validationMessages.systemError2"
                        ),
                      },
                    });
                    setIsOpenModalResponse(true);
                  }}
                >
                  ERROR
                </a>
              ) : (
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadLog(value.logFileName, value.pathLogFileName)
                  }
                >
                  {value.logFileName}
                </a>
              )
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  //Comentario para committS

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Data de grilla
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  //Filtros de la tabla
  const [filterApply, setFilterApply] = useState(null);

  //Archivo seleccionado
  const [fileSelected, setFileSelected] = useState(null);

  //Carga de tabla o refrescar
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //Catálogo para arbol de preview
  const [catalogueForPreview, setCatalogueForPreview] = useState([]);
  const [loadingPreviewCatalog, setLoadingPreviewCatalog] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);

  //Modal respuesta carga de catálogo
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  // Modal para cuando el archivo no cumple con las validaciones
  const [isErrorFile, setIsErrorFile] = useState(false);
  //Modal Error
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  //Si hay catálogos cargados o no
  const [isCatalogUploaded, setIsCatalogUploaded] = useState(false);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Use Effect
   */

  /**
   * Triggea la función para cargar los catálogos del comercio
   */
  useEffect(() => {
    if (userInfo) fetchData();
  }, [userInfo]);

  /**
   * Handles
   */

  /**
   * Trae la información de los catálogos cargados para el comercio actual
   */
  const fetchData = async () => {
    try {
      setData([]);
      setIsLoadingData(true);
      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: 21,
      };
      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await GetCatalogsTraders(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const finalData = setDataStructure(data);
        setUnfilteredData(finalData);
        setData(finalData);
        setIsCatalogUploaded(data.length > 0);
      } else {
        setIsCatalogUploaded(false);
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Descarga la plantilla de catálogo para comercios
   * Si falla se muestra modal de error
   */
  const handleDownloadTemplate = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await DownloadTemplateTraders();

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(responseMessage.fileContent),
          responseMessage.fileName
        );
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Descarga la guia para catálogo de comercios
   * Si falla se muestra modal de error
   */
  const handleDownloadGuide = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await DownloadGuideTraders();

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        fileDownload(
          decode(responseMessage.fileContent),
          responseMessage.fileName
        );
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo de catálogo que fue cargado (subido)
   *
   * @param {String} fileName nombre del archivo
   * @param {String} pathFileName path del archivo
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    try {
      const obj = {
        pathFileName: pathFileName,
        fileName: fileName,
      };

      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await DownloadCatalogueFile(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        fileDownload(decode(data.fileContent), data.fileName);
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo con los errores del archivo cargado (subido)
   *
   * @param {String} logFileName nombre del archivo log
   * @param {String} pathLogFileName path del archivo
   */
  const handleDownloadLog = async (logFileName, pathLogFileName) => {
    try {
      const obj = {
        pathLogFileName: pathLogFileName,
        logFileName: logFileName,
      };
      const {
        status,
        data: { responseCode, responseMessage, data },
      } = await DownloadCatalogErrorLog(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        fileDownload(decode(data.fileContent), data.fileName);
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Realiza la acción de subir un nuevo catálogo de comercio a la plataforma
   */
  const handleUploadFile = async () => {
    try {
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        emailLoad: userInfo.email,
        userLoad: `${userInfo.firstName} ${userInfo.lastName}`,
        fileUpload: fileSelected[0],
        country: userInfo.company.country,
        provider: userInfo.company.companyName,
        file: fileSelected[0],
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await UploadCatalogTradersRequest(obj);

      setFileSelected(null);
      setModalTitle(handleSetTitle(responseCode));
      setModalContent({
        status: status,
        data: { responseMessage, responseCode },
      });
      setIsOpenModalResponse(true);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        await fetchData();
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Carga el preview del catálogo y muestra el modal con el preview
   */
  const handlePreviewCatalog = async () => {
    try {
      setLoadingPreviewCatalog(true);
      const obj = { eanTrader: userInfo.company.eanCompany };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetPreviewCatalogTraders(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setCatalogueForPreview(responseMessage);
        setTimeout(() => {
          setOpenModalPreview(true);
        }, 0.1);
      } else {
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
      setLoadingPreviewCatalog(false);
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Refresca la tabla de catálogos de comercios
   */
  const handleRefresh = async () => {
    try {
      const previousData = data;

      setIsLoadingRefresh(true);
      setIsLoadingData(true);
      setData([]);

      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: 21,
      };
      const {
        status,
        data: { responseCode, responseMessage, data: responseData },
      } = await GetCatalogsTraders(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const finalData = setDataStructure(responseData);
        setIsCatalogUploaded(data.length > 0);
        setUnfilteredData(finalData);
        setData(finalData);
      } else {
        setIsCatalogUploaded(false);
        setData(previousData);
        setModalTitle(handleSetTitle(responseCode));
        setModalContent({
          status: status,
          data: {
            responseMessage,
            responseCode,
          },
        });
        setIsOpenModalResponse(true);
      }
      setIsLoadingData(false);
      setIsLoadingRefresh(false);
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Aplica estructura de tabla a los datos entrantes
   * @param {*} lstCatalogs lista de catálogos cargados
   * @returns lista de catálogos formateada
   */
  const setDataStructure = (lstCatalogs) => {
    return lstCatalogs.map((catalog, i) => ({
      startDate: !catalog.starDate ? "  " : catalog.starDate,
      endDate: !catalog.endDate ? "  " : catalog.endDate,
      user: !catalog.userLoad ? "" : catalog.userLoad,

      file: !catalog.fileName
        ? " "
        : {
            fileName: catalog.fileName,
            pathFileName: catalog.pathFileName,
          },
      state: !catalog.state ? " " : catalog.state,
      errors: catalog.awsUploadLog
        ? {
            awsUploadLog: catalog.awsUploadLog,
          }
        : {
            logFileName: catalog.logFileName,
            pathLogFileName: catalog.pathLogFileName,
          },
    }));
  };

  /**
   * Settea el título del modalResponse según el responseCode
   * @param {*} responseCode código de respuesta
   * @returns título del modal
   */
  const handleSetTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS:
        return t("App.catalogue.title");
      default:
        return t("App.validationMessages.error");
    }
  };

  return (
    <Grid container direction="column" rowSpacing={2} className="full-width">
      <Grid item className="full-width">
        {" "}
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnSpacing={2}
          rowSpacing={2}
          textAlign={"center"}
        >
          <DownloadTemplate
            helpText={t("App.traders.catalog.templateText")}
            buttonText={t("App.catalogue.templateDownload")}
            handleDownloadTemplate={handleDownloadTemplate}
          />
        </Grid>
      </Grid>
      <Grid item className="full-width">
        {" "}
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <DownloadGuide
            helpText={"App.salesForce.guide"}
            handleDownloadGuide={handleDownloadGuide}
          />
        </Grid>
      </Grid>
      <Grid item className="full-width">
        <UploadFile
          dragText={t("App.catalogue.dragging")}
          handleUploadFile={handleUploadFile}
          fileSelected={fileSelected}
          setFileSelected={setFileSelected}
          setErrorModal={setIsErrorFile}
          setErrorTitle={setModalTitle}
        />
      </Grid>
      <Grid item className="full-width">
        {isCatalogUploaded && (
          <Grid
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            columnSpacing={2}
            rowSpacing={2}
            textAlign={"center"}
          >
            <Grid
              lg={"auto"}
              sm={"auto"}
              xs={12}
              item
              className="heading__tertiary-one"
            >
              {t("App.rateMaster.refresh")}
            </Grid>
            <Grid lg={"auto"} sm={"auto"} xs={12} item>
              <Button
                disableRipple
                disabled={isLoadingRefresh ? true : false}
                startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                className="btn__outlined__blue full-width"
                onClick={handleRefresh}
              >
                {!isLoadingRefresh ? (
                  t("App.catalogue.update")
                ) : (
                  <CircularProgress size={23} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item className="full-width">
        {isCatalogUploaded && (
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <Button
                disableRipple
                disabled={loadingPreviewCatalog ? true : false}
                className="btn__filled__blue"
                onClick={handlePreviewCatalog}
              >
                {" "}
                {!loadingPreviewCatalog ? (
                  t("App.catalogue.cataloguePreviewText")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item>
        <Grid
          container
          columnSpacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            {" "}
            <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
          </Grid>
          <Grid item className="display-small__primary-one">
            {" "}
            {t("App.salesForce.subtitle")}
          </Grid>
        </Grid>
      </Grid>
      {isCatalogUploaded && (
        <Grid item className="full-width">
          <Accordion className="accordion__filter" style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {" "}
                {t("App.catalogue.filterName")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterSalesForce
                userInfo={userInfo}
                unfilteredData={unfilteredData}
                setData={setData}
                setFilterApply={setFilterApply}
                idFile={21}
                setIsLoadingData={setIsLoadingData}
                setFilterNotFound={setFilterNotFound}
                state={true}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      <Grid item className="chips-container full-width">
        {filterApply && isCatalogUploaded && (
          <Chip className="chip__filter" label={filterApply.label} />
        )}
      </Grid>

      <Grid item className="full-width">
        {isCatalogUploaded ? (
          isLoadingData ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce"
            />
          ) : !filterNotFound ? (
            <MUIDataTable
              style={{ width: "100% !important" }}
              data={data}
              columns={columns}
              options={options}
              className="dataTable__salesForce"
            />
          ) : (
            <NoMatchImage />
          )
        ) : (
          <NoInfoComponent type="load" />
        )}
      </Grid>

      <ModalInfo
        title={modalTitle}
        responseData={modalContent}
        open={isOpenModalResponse}
        onClose={() => {
          setIsOpenModalResponse(false);
        }}
      />
      {/* Modal para indicar que el archivo no cumple con las validaciones */}
      <ModalInfo
        title={modalTitle}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseMessage: "",
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
          },
        }}
        open={isErrorFile}
        onClose={() => {
          setIsErrorFile(false);
        }}
      />
      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: 0,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />

      <ModalPreviewCatalogue
        open={openModalPreview}
        catalogue={catalogueForPreview}
        onClose={() => {
          setOpenModalPreview(false);
        }}
      />
    </Grid>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import React, { useEffect, useState } from "react";
import {
  GetFiltersList,
  ListIndicatorType,
} from "../../../services/admin/adminServices";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import { CODES } from "../../../consts/codes";
import { getTypeInsight } from "../../../services/user/insight";
import { Controller, useForm } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdministrationAccessIndicators } from "../../../services/admin/adminServices";

export const FilterIndicators = ({
  setData,
  setIsNoFilterFound,
  setNoContent,
  setIsLoadingData,
  setFilters,
  setDataStructure,
  indicatorTypeFilter,
  setOpenErrorModal,
}) => {
  /**
   * Const
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  //Country selected
  const [isCountrySelected, setIsCountrySelected] = useState(false);

  //Fields selected limit
  const [isTypeIndicatorLimit, setIsTypeIndicatorLimit] = useState(false);

  //Loading fields
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);

  //List
  const [countryList, setCountryList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesFilterList, setCompaniesFilterList] = useState([]);
  const [typeInsight, setTypeInsight] = useState([]);
  const [eanList, setEanList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  //useForm
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      country: "",
      company: "",
      eanCompany: "",
      typeIndicator: [],
    },
  });

  /**
   *Limpia los filtros, resetea el objeto de filtros y aplica el no content
   */
  const handleCleanFilters = () => {
    reset();
    setFilters({
      country: "",
      eanCompany: "",
      companyName: "",
      lstTypeInsight: [],
    });
    setNoContent(true);
  };

  /**
   * Use Effect
   */

  /**
   * Fetchs countries, companies and type insights data
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCountries(true);
      //Consulta a los paises
      const getCountriesToSet = await getCountries();
      setCountryList(getCountriesToSet);

      //Consultamos las companies de estos países
      const newCompanies = await getCompanies();
      setCompaniesFilterList(newCompanies);

      //Agregamos los tipos de indicadores
      await getInsightTypes();
      setIsLoadingCountries(false);
    };

    fetchData();
  }, []);

  /**
   * Request to get countries
   * @returns countries from platform
   */
  const getCountries = async () => {
    const requestCountries = await GetGeographiesList();

    const responseCode = requestCountries.data.responseCode;

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS:
        return requestCountries.data.responseMessage;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];

      default:
        return [];
    }
  };

  /**
   * Este servicio permite obtener el listado de acceso a las tarjetas de indicadores para su configuracion de url
   * @returns listado de acceso a las tarjetas de indicadores
   */
  const getAccessIndicators = async (data) => {
    const requestAccessIndicators = await AdministrationAccessIndicators(data);

    const responseCode = requestAccessIndicators.data.responseCode;
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return requestAccessIndicators.data.responseMessage;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];

      case CODES.COD_RESPONSE_ERROR_LIST:
        setOpenErrorModal(true);
        return [];

      default:
        return [];
    }
  };

  /**
   * Request to get companies
   * @returns list of companies from list of countries
   */
  const getCompanies = async () => {
    const filtersService = await GetFiltersList();

    if (
      filtersService.status === CODES.COD_RESPONSE_HTTP_OK &&
      filtersService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      return filtersService.data.responseMessage;
    } else {
      setOpenErrorModal(true);
      return [];
    }
  };

  /**
   * Request to get type insights
   * @returns list of type insights
   */
  const getInsightTypes = async () => {
    const indicatorsType = await ListIndicatorType();

    const { responseMessage, responseCode } = indicatorsType.data;

    if (indicatorsType.status === CODES.COD_RESPONSE_HTTP_OK) {
      if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
        const indicatorType = responseMessage.map((indicator) => ({
          label: indicatorTypeFilter(indicator),
          value: indicator.indicatorType,
        }));

        const indicatorTypelist = [
          {
            label: t("App.downloadSalesForce.selectAll"),
            value: t("App.downloadSalesForce.selectAll"),
          },
          ...indicatorType,
        ];

        setTypeInsight(indicatorTypelist);
      } else {
        setOpenErrorModal(true);
      }
    } else {
      setOpenErrorModal(true);
    }
  };

  /**
   * Handles the input change of country field
   * @param {array} values list of selected countries
   */
  const handleCountryChange = async (values, reason) => {
    //Reseteamos los demás campos
    setValue("typeIndicator", []);
    setValue("company", null);
    setValue("eanCompany", null);
    setCompaniesList([]);
    setEanList([]);
    setCompanyList([]);
    setIsTypeIndicatorLimit(false);
    setIsLoadingFields(true);

    //Si se limpia el field se settea "company" como null
    if (reason === "clear") {
      setIsCountrySelected(true);
      setIsLoadingFields(false);
    } else {
      //Filtramos las compañias por el pais
      const filtered = companiesFilterList.filter(
        (item) => item.country === values.country
      )[0];
      if (filtered) {
        //Llenamos las listas de los eans y los nombres de compañias
        const eanList = filtered.companies.map((item) => item.eanCompany);
        const companyList = filtered.companies.map((item) => item.companyName);
        setCompaniesList(filtered.companies);
        setEanList(eanList);
        setCompanyList(companyList);
      }
      setIsCountrySelected(true);
      setIsLoadingFields(false);
    }
  };

  /**
   * Handles the input change of company field
   * @param {array} values list of selected countries
   * @param {string} reason type of selection: selectOption, removeOption
   */
  const handleCompanyChange = (values, reason) => {
    const company = companiesList.filter((item) => item.companyName === values);

    //Si se limpia el field se settea "eanCompany" como null
    if (reason === "clear") {
      setValue("eanCompany", null);
    } else {
      setValue("eanCompany", company[0].eanCompany);
    }
  };

  /**
   * Handles the input change of ean field
   * @param {array} values list of selected countries
   * @param {string} reason type of selection: selectOption, removeOption
   */
  const handleEanChange = (values, reason) => {
    const company = companiesList.filter((item) => item.eanCompany === values);

    //Si se limpia el field se settea "company" como null
    if (reason === "clear") {
      setValue("company", null);
    } else {
      setValue("company", company[0].companyName);
    }
  };

  /**
   * Handles the input change of type indicador field
   * @param {array} values list of selected countries
   */
  const handleTypeInsightChange = (values, reason, detail) => {
    if (values) {
      const withSelectAll = values.map((item) => item.value);
      const withoutSelectAll = values.filter(
        (item) => item.value !== t("App.downloadSalesForce.selectAll")
      );
      //Revisando si selectAll fue deseleccionado
      if (
        reason === "removeOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("typeIndicator", []);
        setIsTypeIndicatorLimit(false);
      }
      //Revisando si selectAll fue seleccionado
      if (
        reason === "selectOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("typeIndicator", typeInsight);
        setIsTypeIndicatorLimit(false);
      }
      //Revisando si todos los campos a excepcion de selectAll estan seleccionados
      if (
        values.length >= typeInsight.length - 1 &&
        reason === "selectOption" &&
        !withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("typeIndicator", typeInsight);
      }
      //Revisando si se deselecciono alguna opcion
      if (
        reason === "removeOption" &&
        values.length < typeInsight.length &&
        withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("typeIndicator", withoutSelectAll);
      }
    }
  };

  /**
   * Handles filter of admin indicators
   * @param {object} data filters to apply
   */
  const handleFilters = async (data) => {
    try {
      setIsLoadingData(true);
      setNoContent(false);

      const withoutSelectAll = data.typeIndicator.filter(
        (item) => item.value !== t("App.downloadSalesForce.selectAll")
      );
      const lstTypeIndicators = withoutSelectAll.map((item) => item.value);
      const obj = {
        eanProvider: data.eanCompany,
        provider: data.company,
        country: data.country.country,
        lstTypeIndicators: lstTypeIndicators,
      };
      setFilters(obj);
      const accessIndicatorsService = await getAccessIndicators(obj);
      //Llenamos los datos
      const finalArray = setDataStructure(accessIndicatorsService);
      if (finalArray.length > 0) {
        //Si el filtro trae información
        setIsNoFilterFound(false);
        setData(finalArray);
        setFilters(obj);
      } else {
        //SI no trae info
        setData([]);
        setIsNoFilterFound(true);
      }

      setData(finalArray);
      setIsLoadingData(false);
    } catch (e) {
      setOpenErrorModal(true);
      console.log("====== Error filterIndicators.jsx handleFilters ======");
      console.log(e);
      console.log("===========================================");
    }
  };

  /**
   * Función para habilitar o inhabilitar botón
   */
  const handleDisableElement = () => {
    if (watch("country")) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función para inhabilitar si país y compañía no son seleccionados
   */
  const handleDisableElementCompany = () => {
    if (watch("country") && watch("company")) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Accordion defaultExpanded={true} expanded={true}>
        <Grid>
          <AccordionSummary>
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />
            <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
              {t("App.catalogue.filterName")}
            </p>
          </AccordionSummary>
        </Grid>
        <AccordionDetails className="filter_container">
          <Grid
            container
            justifyContent="flex-center"
            alignItems="center"
            columnSpacing={4}
            direction="row"
            margin={0}
          >
            <Grid
              item
              className="side-line__bottomAlign form__container"
              style={{ padding: "0% 1% 0% 1%" }}
            >
              <form id="form-manage-bg" onSubmit={handleSubmit(handleFilters)}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-center"
                  alignItems="center"
                  columnSpacing={4}
                  rowSpacing={4}
                  sx={{ marginRight: "2% !important" }}
                  className="filter_container__form"
                >
                  <Grid item className="field_container__block">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      columnSpacing={4}
                      rowSpacing={2}
                    >
                      <Grid item className="field_container">
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item className="full-width">
                            <Controller
                              control={control}
                              name={`country`}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Autocomplete
                                  disabled={isLoadingCountries}
                                  options={countryList}
                                  className={`${
                                    errors.countries
                                      ? "select__filters__errors__medium"
                                      : "select__filters__medium"
                                  } full-width autoComplete__filter`}
                                  isOptionEqualToValue={(option, value) =>
                                    option.country === value.country
                                  }
                                  getOptionLabel={(option) =>
                                    option.countryName
                                  }
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        label={t("App.indicadors.country")}
                                        sx={{
                                          label: {
                                            fontFamily: "Roboto !important",
                                            fontSize: "14px !important",
                                            translate: "0px -5px",
                                            transformOrigin: "-45px -10px",
                                            "&.Mui-focused": {
                                              color: "#8C8D9D",
                                            },
                                          },
                                        }}
                                        {...params}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <React.Fragment>
                                              {isLoadingCountries ? (
                                                <CircularProgress
                                                  sx={{ color: "#4DCAFA" }}
                                                  size={15}
                                                />
                                              ) : null}
                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                        }}
                                      />
                                    );
                                  }}
                                  onChange={(event, values, reason) => {
                                    onChange(values);
                                    handleCountryChange(values, reason);
                                  }}
                                  value={value || null}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="field_container">
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item className="field_container">
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              <Grid item className="full-width">
                                <Controller
                                  control={control}
                                  name={`company`}
                                  render={({
                                    field: { onChange, onBlur, value },
                                  }) => (
                                    <Autocomplete
                                      disabled={handleDisableElement()}
                                      className={`${
                                        errors.countries
                                          ? "select__filters__errors__medium"
                                          : "select__filters__medium"
                                      } full-width autoComplete__filter`}
                                      options={companyList}
                                      value={value}
                                      onChange={(
                                        event,
                                        values,
                                        reason,
                                        detail
                                      ) => {
                                        onChange(values);
                                        handleCompanyChange(
                                          values,
                                          reason,
                                          detail
                                        );
                                      }}
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            label={t("App.indicadors.company")}
                                            sx={{
                                              label: {
                                                fontFamily: "Roboto !important",
                                                fontSize: "14px !important",
                                                translate: "0px -5px",
                                                transformOrigin: "-45px -10px",
                                                "&.Mui-focused": {
                                                  color: "#8C8D9D",
                                                },
                                              },
                                            }}
                                            {...params}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <React.Fragment>
                                                  {isLoadingFields ? (
                                                    <CircularProgress
                                                      sx={{ color: "#4DCAFA" }}
                                                      size={15}
                                                    />
                                                  ) : null}
                                                  {
                                                    params.InputProps
                                                      .endAdornment
                                                  }
                                                </React.Fragment>
                                              ),
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="field_container__block">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      columnSpacing={4}
                      rowSpacing={2}
                    >
                      <Grid item className="field_container">
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item className="full-width">
                            <Controller
                              control={control}
                              name={`eanCompany`}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Autocomplete
                                  disabled={handleDisableElement()}
                                  className={`${
                                    errors.countries
                                      ? "select__filters__errors__medium"
                                      : "select__filters__medium"
                                  } full-width autoComplete__filter`}
                                  options={eanList}
                                  value={value}
                                  onChange={(event, values, reason, detail) => {
                                    onChange(values);
                                    handleEanChange(values, reason, detail);
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        label={t("App.indicadors.ean")}
                                        sx={{
                                          label: {
                                            fontFamily: "Roboto !important",
                                            fontSize: "14px !important",
                                            translate: "0px -5px",
                                            transformOrigin: "-45px -10px",
                                            "&.Mui-focused": {
                                              color: "#8C8D9D",
                                            },
                                          },
                                        }}
                                        {...params}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <React.Fragment>
                                              {isLoadingFields ? (
                                                <CircularProgress
                                                  sx={{ color: "#4DCAFA" }}
                                                  size={15}
                                                />
                                              ) : null}
                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                        }}
                                      />
                                    );
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="field_container">
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item></Grid>
                          <Grid item className="full-width">
                            <Controller
                              control={control}
                              name={`typeIndicator`}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Autocomplete
                                  disabled={handleDisableElement()}
                                  className={`${
                                    errors.countries
                                      ? "select__filters__errors__medium"
                                      : "select__filters__medium"
                                  } full-width autoComplete__filter`}
                                  disableCloseOnSelect
                                  options={typeInsight}
                                  multiple
                                  limitTags={2}
                                  value={value}
                                  isOptionEqualToValue={(option, value) =>
                                    option.label === value.label
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props} key={option.value + ""}>
                                      <Checkbox
                                        icon={icon}
                                        className="autoComplete__checkbox"
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />

                                      {`${option.label}`}
                                    </li>
                                  )}
                                  onChange={(event, values, reason, detail) => {
                                    onChange(values);
                                    handleTypeInsightChange(
                                      values,
                                      reason,
                                      detail
                                    );
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        label={t(
                                          "App.indicadors.table.typeInsight"
                                        )}
                                        sx={{
                                          label: {
                                            fontFamily: "Roboto !important",
                                            fontSize: "14px !important",
                                            translate: "0px -5px",
                                            transformOrigin: "-45px -10px",
                                            "&.Mui-focused": {
                                              color: "#8C8D9D",
                                            },
                                          },
                                        }}
                                        {...params}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <React.Fragment>
                                              {isLoadingFields ? (
                                                <CircularProgress
                                                  sx={{ color: "#4DCAFA" }}
                                                  size={15}
                                                />
                                              ) : null}
                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                        }}
                                      />
                                    );
                                  }}
                                  renderTags={(value, getTagProps) => {
                                    const limitTags = 2;
                                    const withoutSelectAll = value.filter(
                                      (item) =>
                                        item.value !==
                                        t("App.downloadSalesForce.selectAll")
                                    );
                                    const numTags = withoutSelectAll.length;
                                    return (
                                      <>
                                        {withoutSelectAll
                                          .slice(0, limitTags)
                                          .map((option, index) => (
                                            <Chip
                                              className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                              sx={{
                                                width: "30%",
                                              }}
                                              key={index}
                                              size="small"
                                              label={`${option.label}`}
                                            />
                                          ))}
                                        {numTags > limitTags &&
                                          ` +${numTags - limitTags}`}
                                      </>
                                    );
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item style={{ marginLeft: "4%" }} className="btn__group">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                columnSpacing={3}
                className="confirmation-buttons btn__group"
              >
                <Grid item className="btn__container">
                  {" "}
                  <Button
                    type="submit"
                    form="form-manage-bg"
                    disabled={handleDisableElementCompany()}
                    className={
                      handleDisableElementCompany()
                        ? "btn__applyFilter-disabled"
                        : "btn__applyFilter"
                    }
                  >
                    {t("App.listUsers.applyFilters")}
                  </Button>
                </Grid>
                <Grid item className="btn__container">
                  {" "}
                  <Button
                    disabled={handleDisableElement()}
                    onClick={handleCleanFilters}
                    className="btn__deleteFilter"
                  >
                    {t("App.listUsers.cleanFilters")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

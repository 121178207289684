import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterDeleteAndReload from "../../../components/common/Filters/filterDeleteAndReload";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import { getUser } from "../../userSlice";
import { useSelector } from "react-redux";
import {
  FilterLoadAndReloadService,
  ReloadSaveService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import MultiBuzonInfo from "../../../components/common/multiBuzónInfo";

const AdminReload = ({ setShowLoader }) => {
  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Use selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [hasFiltersTriggered, setHasFiltersTriggered] = useState(false);
  const [filters, setFilters] = useState({});
  const [filtersInfo, setFiltersInfo] = useState({});
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [isLoadingAcceptConfirmation, setIsLoadingAcceptConfirmation] =
    useState(false);
  const [isOpenNoSelection, setIsOpenNoSelection] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [responseMessageModalInfo, setResponseMessageModalInfo] = useState("");
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [hasFoundData, setHasFoundData] = useState(false);

  const mockData = [
    {
      state: "ACTIVO",
      loadDate: "2021-10-10",
      font: "WEB",
      bgm: "11111111",
      trader: "Almacenes Exito S.A",
      provider: "Colgate Palmolive",
      snrf: "123456",
      reportedStartDate: "2024-02-10",
      reportedEndDate: "2024-02-10",
    },
    {
      state: "ACTIVO",
      loadDate: "2021-10-11",
      font: "SFTP",
      bgm: "22222222",
      trader: "Almacenes Flamingo S.A.",
      provider: "Altipal S.A",
      snrf: "987987",
      reportedStartDate: "2024-02-11",
      reportedEndDate: "2024-02-11",
    },
  ];

  /**
   * Configuración de las columnas de la tabla
   */
  const columns = [
    {
      name: "eanProviderMain",
      label: "EAN Provider Main",
      options: {
        display: false,  // Esto hace que la columna sea invisible en la tabla
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "nameProviderMain",
      label: "Name Provider Main",
      options: {
        display: false,  // También ocultamos esta columna
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "eanProvider",
      label: "eanProvider",
      options: {
        display: false,  // También ocultamos esta columna
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "state",
      label: " ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          if (value === "ACTIVO") {
            return <div></div>;
          } else {
            return (
              <Tooltip
                title={t("App.deleteAndReload.reloadChildren.proccessFile")}
                placement="right"
                arrow={true}
              >
                <InfoOutlinedIcon
                  // onClick={handleLogOut}
                  sx={{ fontSize: 22, color: "#553ab6" }}
                ></InfoOutlinedIcon>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      name: "loadDate",
      label: t("App.deleteAndReload.table.uploadDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            style={{ margin: "0" }}
            value={value}
            control={<div>{moment(value).format("YYYY-MM-DD")}</div>}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "channel",
      label: t("App.deleteAndReload.table.font"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "bgm",
      label: t("App.deleteAndReload.table.bgm"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "trader",
      label: t("App.deleteAndReload.table.partner"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },

    {
      name: "provider",
      label: t("App.deleteAndReload.table.supplier"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const eanProviderMain = rowData[0]; // Ajusta este índice según tu estructura de datos
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{value}</span>
              {eanProviderMain !== null && (
                <MultiBuzonInfo
                  data={[
                    { company: rowData[1], eanMain: rowData[0], eanSecondary: rowData[2] },
                  ]}
                />
              )}
            </div>
          );
        }
      },
    },
    {
      name: "snrf",
      label: t("App.deleteAndReload.table.snfr"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "reportedStartDate",
      label: t("App.deleteAndReload.table.initialDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            style={{ margin: "0" }}
            value={value}
            control={
              <div>{value ? moment(value).format("YYYY-MM-DD") : ""}</div>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "reportedEndDate",
      label: t("App.deleteAndReload.table.finalDate"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            style={{ margin: "0" }}
            value={value}
            control={
              <div>{value ? moment(value).format("YYYY-MM-DD") : ""}</div>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  const columnsLoading = [
    {
      name: "state",
      label: " ",
    },
    {
      name: "loadDate",
      label: t("App.deleteAndReload.table.uploadDate"),
    },
    {
      name: "channel",
      label: t("App.deleteAndReload.table.font"),
    },
    {
      name: "bgm",
      label: t("App.deleteAndReload.table.bgm"),
    },
    {
      name: "trader",
      label: t("App.deleteAndReload.table.commerce"),
    },

    {
      name: "provider",
      label: t("App.deleteAndReload.table.supplier"),
    },
    {
      name: "snrf",
      label: t("App.deleteAndReload.table.snfr"),
    },
    {
      name: "reportedStartDate",
      label: t("App.deleteAndReload.table.initialDate"),
    },
    {
      name: "reportedEndDate",
      label: t("App.deleteAndReload.table.finalDate"),
    },
  ];

  /**
   * Skeleton configuration for the table
   */
  const skeletonLine = [
    {
      state: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      loadDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      channel: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      bgm: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      trader: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      provider: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      snrf: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      reportedStartDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      reportedEndDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Configurations for the table
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    selectableRowsHeader: "true",
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSelectedRow(allRowsSelected);
    },
    responsive: "standard",
    searchPlaceholder: t(
      "App.deleteAndReload.reloadChildren.searchPlaceHolder"
    ),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",
    isRowSelectable: (dataIndex) => {
      return data[dataIndex].state !== "ACTIVO" ? false : true;
    },
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Función para manejar las filas seleccionadas y cambiar el canal
   * @param {*} rowsSelected Filas seleccionadas
   */
  const handleSelectedRow = (rowsSelected) => {
    if (rowsSelected.length === 0) {
      setSelectedRows((selectedRow) => []);
    } else {
      setSelectedRows((selectedRow) => {
        return rowsSelected.map((item) => ({
          ...item,
          channel:
            item.channel === "CENt"
              ? "CENT"
              : item.channel === t("App.deleteAndReload.filters.uploadWeb")
              ? "WEB"
              : "SFTP",
        }));
      });
    }
  };

  /**
   * Función abrir modal de confirmación para aplicar la recarga
   */
  const handleApplyReload = () => {
    if (selectedRows.length === 0) {
      setIsOpenNoSelection(true);
    } else {
      setIsOpenConfirmation(true);
    }
  };

  /**
   * Función para cambiar el estado de 'isOpenNoSelection' cuando se acepta la confirmación
   */
  const handleAgree = () => {
    setIsOpenNoSelection(false);
  };

  /**
   * Función para aplicar el servicio de recarga cuando se acepta la confirmación
   */
  const handleAgreeConfirmation = async () => {
    let selectedReloads = selectedRows.map((item) => {
      return data[item.dataIndex];
    });

    // Formatear la estructura de los datos seleccionados
    selectedReloads = selectedReloads.map((item) => {
      return {
        ...item,
        channel:
          item.channel === "CENt"
            ? "CENT"
            : item.channel === t("App.deleteAndReload.filters.uploadWeb")
            ? "WEB"
            : "SFTP",
      };
    });

    const obj = {
      createUser: userInfo.firstName + " " + userInfo.lastName,
      loadAuditReloadArray: selectedReloads,
      startDate: filters.startDate,
      endDate: filters.endDate,
      dateType: filters.dateType,
    };

    setIsOpenConfirmation(false);

    try {
      setShowLoader(true);
      setData(skeletonLine);
      setIsLoading(true);
      handleSelectedRow([]);

      const reloadService = await ReloadSaveService(obj);

      setIsLoadingAcceptConfirmation(!isLoadingAcceptConfirmation);

      if (reloadService.status === CODES.COD_RESPONSE_HTTP_OK) {
        handleSetTitle(reloadService.data.responseCode);
        setResponseMessageModalInfo(reloadService);
        setOpenModalInfo(true);

        await ApplyFilters();
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
    setIsLoading(false);

    setShowLoader(false);
  };

  /**
   * Función para cerrar el modal de confirmación cuando se cancela
   */
  const handleDisagreeConfirmation = () => {
    setIsOpenConfirmation(false);
  };

  /**
   *
   * Función para establecer el título del modalInfo por responseCode   *
   * @param {Number} responseCode código de respuesta del servicio
   */
  const handleSetTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        setConfirmationTitle(
          t("App.deleteAndReload.reloadChildren.reloadError")
        );
        break;
      case CODES.COD_RESPONSE_SUCCESS:
        setConfirmationTitle(
          t("App.deleteAndReload.reloadChildren.reloadResponse")
        );
        break;
      default:
        break;
    }
  };

  /**
   * Función para filtrar la información
   * @param {*} obj Información de los filtros
   */
  const ApplyFilters = async (obj = null) => {
    setHasFiltersTriggered(true);
    handleSelectedRow([]);

    try {
      setHasFoundData(true);
      setData(skeletonLine);
      setIsLoading(true);

      //Relizar el filtro con los nuevo datos de filtro recibidos o con los datos anteriores
      const filterService = await FilterLoadAndReloadService(
        obj === null && Object.keys(filters).length > 0 ? filters : obj
      );
      const { status, data } = filterService;
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
          const responseMessage = data.responseMessage;
          setData(setDataStructure(responseMessage));
          setHasFoundData(true);
        } else if (data.responseCode === CODES.COD_RESPONSE_ERROR_LIST) {
          setHasFoundData(false);
          setOpenErrorModal(true);
        } else if (data.responseCode === CODES.COD_RESPONSE_ERROR_LOGIN) {
          setData([]);
          setHasFoundData(false);
        } else {
          setData([]);
          setOpenErrorModal(true);
          setHasFoundData(false);
        }
      } else {
        setData([]);
        setHasFoundData(false);
        setOpenErrorModal(true);
      }
    } catch (error) {
      setData([]);
      setHasFoundData(false);

      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
    setIsLoading(false);
  };
  /**
   * Funcion para formatear los datos de la respuesta del servicio de filtro
   * @param {*} data Datos de la respuesta del servicio
   * @returns
   */
  const setDataStructure = (data) => {
    return data.map((item) => {
      return {
        ...item,
        channel: !item.channel
          ? ""
          : item.channel === "WEB"
          ? t("App.deleteAndReload.filters.uploadWeb")
          : item.channel === "CENT"
          ? "CENt"
          : item.channel,
      };
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item style={{ width: "100%" }}>
        <ModalInfo
          title={confirmationTitle}
          responseData={responseMessageModalInfo}
          open={openModalInfo}
          onClose={() => {
            setOpenModalInfo(false);
          }}
        />

        <Accordion className="accordion__filter" expanded={true}>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ cursor: "auto !important" }}
          >
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />

            <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
              {" "}
              {t("App.listUsers.filter")}
            </p>
          </AccordionSummary>

          <AccordionDetails>
            <FilterDeleteAndReload
              applyFilters={ApplyFilters}
              setIsLoading={setIsLoading}
              mockData={mockData}
              data={data}
              setData={setData}
              unfilteredData={unfilteredData}
              setHasFoundData={setHasFoundData}
              setHasFiltersTriggered={setHasFiltersTriggered}
              setFilters={setFilters}
              setFiltersInfo={setFiltersInfo}
              requestType={"RECARGA"}
              skeletonData={skeletonLine}
              setOpenErrorModal={setOpenErrorModal}
              isLoadingAcceptConfirmation={isLoadingAcceptConfirmation}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>

      {hasFiltersTriggered && (
        <>
          {hasFoundData && (
            <Grid container className="col-end u-marginT ">
              <Button className="btn__filled__blue" onClick={handleApplyReload}>
                {t("App.deleteAndReload.reloadChildren.applyReload")}
              </Button>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className="u-marginT"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Chip
                    label={`${t("App.userDetail.country")}: ${
                      filtersInfo.country ? filtersInfo.country : "-"
                    } `}
                    variant="outlined"
                  />
                </Grid>

                <Grid item>
                  <Chip
                    label={`${t(
                      "App.deleteAndReload.filters.documentType"
                    ).replace("*", "")}: ${
                      filtersInfo.documentType ? filtersInfo.documentType : "-"
                    } `}
                    variant="outlined"
                  />
                </Grid>

                <Grid item>
                  <Chip
                    label={`${t("App.deleteAndReload.filters.dateType").replace(
                      "*",
                      ""
                    )}: ${filtersInfo.dateType ? filtersInfo.dateType : "-"} `}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Tooltip title={t("App.deleteAndReload.filters.docsView")}>
                <ErrorOutlineOutlinedIcon
                  style={{ color: "#553AB6", fontSize: 27 }}
                />
              </Tooltip>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {hasFiltersTriggered && !hasFoundData && (
        <Grid item sx={{ width: "100%" }}>
          <NoMatchImage />
        </Grid>
      )}
      {!hasFiltersTriggered && <NoSelectionComponent type="deleteAndReload" />}

      {hasFiltersTriggered && hasFoundData && (
        <>
          <Grid item style={{ width: "100%" }}>
            <MUIDataTable
              className="dataTable__base"
              data={data}
              columns={isLoading ? columnsLoading : columns}
              options={options}
            />
          </Grid>

          <Grid item>
            <p className="heading__text-black">
              {t("App.deleteAndReload.reloadChildren.infoText")}
            </p>
          </Grid>
        </>
      )}

      <ModalDecision
        isOpen={isOpenNoSelection}
        setIsOpen={setIsOpenNoSelection}
        handleAgree={handleAgree}
        handleDisagree={handleAgree}
        title={t("App.deleteAndReload.reloadChildren.nonSelectedTitle")}
        message={t("App.deleteAndReload.reloadChildren.nonSelectedText")}
        agreeText={t("App.buttonLabels.accept")}
        disagreeText={t("App.buttonLabels.cancel")}
      />

      <ModalDecision
        isOpen={isOpenConfirmation}
        setIsOpen={setIsOpenConfirmation}
        handleAgree={handleAgreeConfirmation}
        handleDisagree={handleDisagreeConfirmation}
        title={t("App.deleteAndReload.reloadChildren.reloadQuestionTitle")}
        message={t("App.deleteAndReload.reloadChildren.reloadQuestionText")}
        agreeText={t("App.buttonLabels.accept")}
        disagreeText={t("App.buttonLabels.cancel")}
      />

      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

export default AdminReload;

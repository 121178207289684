import { Button, Card, CardContent, Grid, IconButton } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const GenericCardAccess = ({
  sourceIcon,
  title,
  text,
  buttonText,
  handleClick,
}) => {
  return (
    <>
      <Card className="card-access-sales">
        <CardContent className="card-access-sales__content">
          <div className="div-icon-title">
            <IconButton className="icon-button">
              <img className="icon" src={sourceIcon} />
            </IconButton>
            <h2 className="display-small__text-black">{title}</h2>
          </div>

          <Grid container direction="column">
            <Grid item>
              <p className="label__text-black text-justify">{text}</p>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                className="button__text-white button-access-sales"
                startIcon={<KeyboardArrowRightIcon />}
                onClick={handleClick}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

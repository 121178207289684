import { Container, Grid } from "@mui/material";
import { SideBar } from "./sideBar";
import TopBar from "./topBar";
import { Outlet, useNavigate } from "react-router-dom";
import useIdle from "../../hooks/useIdleTimer";
import ModalLogOut from "../common/Modals/modalLogOut";
import { useEffect, useState } from "react";
import { LogoutService } from "../../services/auth/authServices";
import { CODES } from "../../consts/codes";
import { resetCurrentCompany } from "../../parts/listUsers/listUsersSlice";
import { useDispatch } from "react-redux";
const Layout = () => {
  /**
   * Use Navigate
   */
  const navigate = useNavigate();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use State
   */

  const [openInactivity, setOpenInactivity] = useState(false);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 768);

  /**
   * Use Effect
   */

  /**
   * Revisa si la pestaña o el navegador fue cerrado para hacer logout
   */
  useEffect(() => {
    const checkSession = async () => {
      const activeSession = sessionStorage.getItem("activeSession");
      if (!activeSession) {
        await handleLogOut();
      }
    };
    checkSession();
  }, []);

  /**
   * Handles
   */

  /**
   * Función que abre el modal de inactividad
   */
  const openInactivityWarning = () => {
    setOpenInactivity(true);
  };

  /**
   * Función que cierra la sesión del usuario
   */
  const handleLogOut = async () => {
    try {
      const {
        status,
        data: { responseCode },
      } = await LogoutService();

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST)
      ) {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(resetCurrentCompany());
        navigate("/");
      }
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Use Idle para inactividad del usuario
   */
  const { reset: resetTimerInactivity } = useIdle({
    onIdle: handleLogOut,
    idleTime: 10,
    //Mostrar el modal a los 9 minutos de inactividad (10-1)
    promptTime: 1,
    onPrompt: openInactivityWarning,
  });
  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setShowMenu(window.innerWidth > 768);
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Grid className="container__page">
      <Grid container>
        {showMenu && (
          <Grid item xl={0.6} lg={0.87} md={1.28} sm={1.4} xs={2}>
            <SideBar setShowMenu={setShowMenu} />
          </Grid>
        )}
        <Grid
          item
          xl={!showMenu ? 12 : 11.4}
          lg={!showMenu ? 12 : 11.13}
          md={!showMenu ? 12 : 11.72}
          sm={!showMenu ? 12 : 10.6}
          xs={!showMenu ? 12 : 10}
        >
          <TopBar setShowMenu={setShowMenu} showMenu={showMenu} />
        </Grid>
      </Grid>

      <Grid container>
        {showMenu && (
          <Grid item xl={0.6} lg={0.87} md={1.28} sm={1.4} xs={2}></Grid>
        )}
        <Grid
          item
          xl={!showMenu ? 12 : 11.4}
          lg={!showMenu ? 12 : 11.13}
          md={!showMenu ? 12 : 11.72}
          sm={!showMenu ? 12 : 10.6}
          xs={!showMenu ? 12 : 10}
          style={{ paddingRight: "2rem", paddingTop: "2rem" }}
        >
          <Container
            style={{ paddingLeft: "4rem" }}
            className="main_content_container"
          >
            <ModalLogOut
              isOpen={openInactivity}
              setIsOpen={setOpenInactivity}
              resetTimerInactivity={resetTimerInactivity}
            />

            <Outlet />
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Grid, Icon, IconButton, Typography } from "@mui/material";
import MenuOption from "../menuOption";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
/**
 * Componente que renderiza las opciones de menú de nivel 2 con sus opciones hijas de nivel 3 si las tiene
 * Éstas opciones se deben agregar desde la sección de opciones de menú en la plataforma por parte del administrador
 *    -------------------------- PROPS ---------------------
 * selectedOption: opción de menú seleccionada
 * i18n: objeto con las traducciones
 * navigate: función que permite navegar a la ruta seleccionada
 * fullNameUser: nombre completo del usuario en sesión
 * emailUser: correo del usuario en sesión
 * excludeUrl: función que permite excluir una url de las opciones de menú
 * homeRoutes: rutas de inicio de la aplicación
 * setOpen: función que permite abrir o cerrar el menú para ver las opciones
 * openQlikSense: función que permite abrir Qlik Sense
 * idToken: token de autenticación
 * fetchTeamcore: función que permite iniciar sesión en Teamcore
 * role: rol del usuario en sesión
 */
const MenuOptionsLvl2Mobile = ({
  selectedOption,
  i18n,
  navigate,
  fullNameUser,
  emailUser,
  excludeUrl,
  homeRoutes,
  setOpen,
  openQlikSense,
  idToken,
  fetchTeamcore,
  role,
  setOpenNivel1,
  isTabletWidth,
  isTabletHeight,
  setShowMenu,
  optionsLvl1Admin,
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      width={isTabletWidth ? "256px" : "187px"}
      textAlign="center"
      rowSpacing={2}
    >
      <Grid item width={isTabletWidth ? "200px" : "151px"}>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          textAlign={"center"}
          width="100%"
          borderBottom={1}
          sx={{ padding: "4px 8px 4px 0px" }}
        >
          <IconButton
            disableRipple
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "15px",
              width: "100%",
              height: "32px",
              backgroundColor: "transparent",
            }}
            color="inherit"
            id={`menu-options-${1}`}
            onClick={() => {
              setOpenNivel1(true);
            }}
          >
            <Icon
              sx={{
                fontSize: 22,
                color: "black",
                fill: "black",
                minWidth: 0,
                justifyContent: "center",
              }}
            >
              <img
                className={"menu__icon__img"}
                src={`/assets/menuIcons/navigationBack.svg`}
                alt={"back"}
              />
            </Icon>
            <Typography
              variant="p"
              className={
                isTabletWidth
                  ? "icons__text_mobile__back-tablet"
                  : "icons__text_mobile__back-phone"
              }
              style={{
                width: isTabletWidth ? "12rem" : "8rem",
              }}
            >
              {i18n.language.includes("es") ? "Menú Principal" : "Home"}
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item width={isTabletWidth ? "200px" : "151px"}>
        {/**
         * Título de sección cuando se oprime opción NIVEL 1
         * */}
        <Grid
          container
          direction="column"
          alignItems={"start"}
          rowSpacing={2}
          justifyContent="left"
          textAlign="left"
        >
          <Grid item>
            <IconButton
              disableRipple
              sx={{
                justifyContent: "center",
                borderRadius: "15px",
                textAlign: "center",
                width: "48px",
                height: "32px",
                backgroundColor: "#553AB6",
              }}
              color="inherit"
              id={`menu-options-${1}`}
            >
              <Icon
                sx={{
                  fontSize: 22,
                  color: "white",
                  fill: "white",
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                {selectedOption.nameEnglish === "Home" ? (
                  <HomeOutlinedIcon
                    sx={{
                      fontSize: 22,
                      color: "white",
                      minWidth: 0,
                      mr: "auto",
                      justifyContent: "center",
                      color: "white",
                    }}
                  />
                ) : role === "admin" ? (
                  <AdminPanelSettingsOutlinedIcon
                    sx={{
                      fontSize: 22,
                      color: "white",
                      minWidth: 0,
                      mr: "auto",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <img
                    className={"menu__icon__img__normal"}
                    src={`/assets/menuIcons/${selectedOption.icon}`}
                    alt={selectedOption.menuOptionName}
                  />
                )}
              </Icon>
            </IconButton>
          </Grid>
          <Grid
            item
            justifyContent="left"
            textAlign="left"
            style={{
              width: isTabletWidth ? "12rem" : "8rem",
            }}
          >
            <Typography
              variant="p"
              style={{
                width: isTabletWidth ? "12rem" : "8rem",
              }}
              className={"icons__text_mobile__responsive"}
            >
              {i18n.language.includes("es")
                ? selectedOption.menuOptionName
                : selectedOption.nameEnglish}
            </Typography>
          </Grid>
          {role !== "admin" &&
            selectedOption.description &&
            selectedOption.description !== "" && (
              <Grid
                item
                justifyContent="left"
                textAlign="left"
                style={{
                  width: isTabletWidth ? "12rem" : "8rem",
                }}
              >
                <Typography
                  variant="p"
                  style={{
                    width: isTabletWidth ? "12rem" : "8rem",
                  }}
                  className={"icons__text_mobile__description"}
                >
                  {i18n.language.includes("es")
                    ? selectedOption.description
                    : selectedOption.descriptionEnglish}
                </Typography>
              </Grid>
            )}
        </Grid>
      </Grid>

      <Grid item width={isTabletWidth ? "200px" : "151px"}>
        {role === "admin" ? (
          <Grid
            item
            justifyContent="left"
            textAlign={"left"}
            width={isTabletWidth ? "200px" : "151px"}
            height={isTabletHeight ? "49rem" : "37rem"}
            className="scroll-sidebar"
          >
            {optionsLvl1Admin.map((item, idx) => (
              <MenuOption
                key={idx}
                idx={idx}
                item={item}
                setOpen={setOpen}
                navigate={navigate}
                i18n={i18n}
                fullNameUser={fullNameUser}
                emailUser={emailUser}
                openQlikSense={openQlikSense}
                role={role}
                setShowMenu={setShowMenu}
                setOpenNivel1={setOpenNivel1}
              />
            ))}
          </Grid>
        ) : (
          <Grid
            item
            height={isTabletHeight ? "43rem" : "33rem"}
            className="scroll-sidebar"
            justifyContent={"left"}
            textAlign={"left"}
          >
            {selectedOption.levels &&
              i18n &&
              selectedOption.levels.map((item, idx) => {
                if (item.levels && item.levels.length > 0) {
                  //Renderiza lvl2 con hijos
                  return (
                    <Grid item key={idx} width="100%">
                      {/**
                       * Accordion para opciones NIVEL 2 con opciones hijas (nivel 3)
                       * */}
                      <Accordion className="accordion__menuParent__rounded">
                        <AccordionSummary
                          className="accordion__menuParent"
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#2E3043" }} />
                          }
                        >
                          <Button
                            className="sideBar__button__options"
                            disableElevation={true}
                            disableRipple={true}
                            disableFocusRipple={true}
                          >
                            {i18n.language.includes("es")
                              ? item.menuOptionName
                              : item.nameEnglish}
                          </Button>
                        </AccordionSummary>
                        <AccordionDetails className="accordion__menuParent__content">
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item justifyContent="left" textAlign={"left"}>
                              {/**
                               * Botones de NIVEL 3
                               */}
                              {item?.levels
                                ?.filter(
                                  (item) =>
                                    excludeUrl(item.url, homeRoutes) === false
                                )

                                .map((item, idx) => {
                                  return (
                                    <MenuOption
                                      key={idx}
                                      idx={idx}
                                      item={item}
                                      setOpen={setOpen}
                                      navigate={navigate}
                                      i18n={i18n}
                                      fullNameUser={fullNameUser}
                                      emailUser={emailUser}
                                      openQlikSense={openQlikSense}
                                      role={role}
                                      setShowMenu={setShowMenu}
                                      setOpenNivel1={setOpenNivel1}
                                    />
                                  );
                                })}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid
                      container
                      key={idx}
                      rowSpacing={2}
                      textAlign={"left"}
                      justifyContent="left"
                    >
                      {/**
                       * Botones NIVEL 2 SIN hijos
                       */}
                      <Grid item textAlign={"left"} justifyContent="left">
                        {!item?.url?.includes("teamcore") ? (
                          excludeUrl(item.url, homeRoutes) == false ? (
                            <MenuOption
                              key={idx}
                              idx={idx}
                              item={item}
                              setOpen={setOpen}
                              navigate={navigate}
                              i18n={i18n}
                              fullNameUser={fullNameUser}
                              emailUser={emailUser}
                              openQlikSense={openQlikSense}
                              role={role}
                              setShowMenu={setShowMenu}
                              setOpenNivel1={setOpenNivel1}
                            />
                          ) : null
                        ) : (
                          <form
                            action="https://auth.teamcore.net/1/public/session"
                            method="POST"
                            id="teamcore-form"
                            target="_blank"
                          >
                            <Button
                              className="sideBar__button__options"
                              disableElevation={true}
                              disableRipple={true}
                              disableFocusRipple={true}
                              onClick={fetchTeamcore}
                            >
                              <input
                                type="hidden"
                                name="id_token"
                                id="id_token"
                                value={idToken}
                              />

                              {i18n.language.includes("es")
                                ? item.menuOptionName
                                : item.nameEnglish}
                            </Button>
                          </form>
                        )}
                      </Grid>
                    </Grid>
                  );
                }
              })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default MenuOptionsLvl2Mobile;

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { BaseInfoAccordion } from "../FieldsMapping/BaseInfoAccordion";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FieldsAccordion } from "../FieldsMapping/FieldsAccordion";
import PropTypes from "prop-types";
import {
  CreateFieldMapping,
  ValidateCreateFieldMapping,
} from "../../../services/user/mapping/mappingServices";
import { CODES } from "../../../consts/codes";
import ModalInfo from "./modalInfo";
import { ModalDecision } from "./modalDecision";
import { getUser } from "../../../pages/userSlice";
import { useSelector } from "react-redux";

const ModalCreateFieldMapping = ({
  isOpen,
  setIsOpen,
  filterData,
  objFilter,
}) => {
  /**
   * Title component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Schema
   */

  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({
        country: yup.string().required(t("App.validationMessages.required")),
        countryName: yup
          .string()
          .required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),
    issuers: yup
      .array()
      .of(
        yup.object().shape({
          eanCompany: yup
            .string()
            .required(t("App.validationMessages.required")),
          companyName: yup
            .string()
            .required(t("App.validationMessages.required")),
        })
      )
      .min(1, t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required")),
    receiver: yup
      .object()
      .shape({
        eanCompany: yup.string().required(t("App.validationMessages.required")),
        companyName: yup
          .string()
          .required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),
    documentType: yup
      .object()
      .shape({
        label: yup.string().required(t("App.validationMessages.required")),
        value: yup.string().required(t("App.validationMessages.required")),
      })
      .typeError(t("App.validationMessages.required")),

    fileType: yup.string().required(t("App.validationMessages.required")),
    delimiter: yup.string().required(t("App.validationMessages.required")),
    dataReading: yup.string().required(t("App.validationMessages.required")),
    mappingFields: yup.array().of(
      yup.object().shape({
        field: yup.string().required(t("App.validationMessages.required")),
        position: yup.string().required(t("App.validationMessages.required")),
        format: yup.string().required(t("App.validationMessages.required")),
      })
    ),
  });

  /**
   * Use Form
   */

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: { countryName: "", country: "" },
      issuers: [],
      receiver: null,
      documentType: null,
      fileType: "",
      delimiter: "",
      dataReading: "",
      mappingFields: [],
    },
  });

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [titleModalInfo, setTitleModalInfo] = useState("");
  const [contentModalInfo, setContentModalInfo] = useState({});

  const [storedFormData, setStoredFormData] = useState({});
  const [docType, setDocType] = useState("");
  const [isStoreDetail, setIsStoreDetail] = useState(false);

  /**
   * Handles
   */

  /**
   * Función que ejecuta acciones cuando el modal se cierra
   */
  const onClose = () => {
    reset();
    setStoredFormData({});
    setDocType("");
    setIsStoreDetail(false);
    setIsOpen(false);
  };

  /**
   * Función que recibe los datos del formulario diligenciado y verifica si se puede hacer la creación
   * @param {*} data datos del formulario
   */
  const handleSubmitCreateMapping = async (data) => {
    try {
      setIsLoadingCreate(true);
      const finalIssuers = data.issuers
        .filter(
          (issuer) =>
            issuer.eanCompany !== t("App.downloadSalesForce.selectAll")
        )
        .map((issuer) => ({
          eanCompany: issuer.eanCompany,
          companyName: issuer.companyName,
        }));
      const obj = {
        country: data.country.country,
        countryName: data.country.countryName,
        eanReceiver: data.receiver.eanCompany,
        receiver: data.receiver.companyName,
        documentType: data.documentType.value,
        storeDetail: isStoreDetail,
        lstSenders: finalIssuers,
      };
      data.issuers = finalIssuers;
      setStoredFormData(data);
      const {
        status,
        data: { responseMessage, responseCode },
      } = await ValidateCreateFieldMapping(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setIsOpenModalDecision(true);
      } else {
        setTitleModalInfo(handleSetTitle(CODES.COD_RESPONSE_ERROR_SHOW));
        setContentModalInfo({ data: { responseMessage: responseMessage } });
        setIsOpenModalInfo(true);
      }
      setIsLoadingCreate(false);
    } catch (error) {
      console.log(
        "=============================== ERROR at modalCreateFieldMapping.jsx =============================="
      );
      console.log(error);
      console.log("=====================================================");
    }
  };

  /**
   * Función cuando el usuario acepta en el modal decision
   */
  const handleAgree = async () => {
    setIsOpenModalDecision(false);
    await handleCreateMapping(storedFormData);
  };

  /**
   * Función cuando el usuario cancela en el modal decision
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  /**
   * Función que retorna el título del modal info según el código de respuesta
   * @param {*} responseCode código de respuesta
   * @returns título de modal
   */
  const handleSetTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_ERROR_SHOW:
        return t("App.loadMapping.createModal.title1007");

      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return t("App.loadMapping.createModal.title0");

      default:
        return t("App.validationMessages.error");
    }
  };

  /**
   * Función que convierte el valor del campo storeDetail a string
   */

  const storeDetailStatus = (isStoreDetail) => {
    if (isStoreDetail) {
      return "SI";
    } else {
      return "NO";
    }
  };

  /**
   * Función que crea el mappeo en el sistema
   * @param {} data datos del formulario
   */
  const handleCreateMapping = async (data) => {
    try {
      setIsLoadingCreate(true);

      const finalFields = data.mappingFields.map((field) => ({
        field: field.field,
        position: field.position,
        format: field.format,
      }));

      const obj = {
        country: data.country.country,
        eanReceiver: data.receiver.eanCompany,
        receiver: data.receiver.companyName,
        lstSenders: data.issuers,
        documentType: data.documentType.value,
        fileType: data.fileType,
        delimiter: data.delimiter,
        dataReading: data.dataReading,
        storeDetail: storeDetailStatus(isStoreDetail),
        fields: finalFields,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await CreateFieldMapping(obj);

      setTitleModalInfo(handleSetTitle(responseCode));

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setContentModalInfo({
          status: status,
          data: {
            responseCode: responseCode,
            responseMessage: "",
          },
        });

        //Aplicamos filtro para refrescar la tabla si ya se filtró previamente
        if (Object.keys(objFilter).length > 0) {
          filterData();
        }
      } else {
        setContentModalInfo({
          data: {
            responseCode: responseCode,
            responseMessage: responseMessage,
          },
        });
      }
      setIsLoadingCreate(false);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.log(
        "===================== ERROR at modalCreateFieldMapping.jsx ====================="
      );
      console.log(error);
      console.log("==============================");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            alignSelf: "center",
            width: "100%",
            height: "100%",
            maxWidth: "1050px",
            maxHeight:  { 
              sm: "1040px",
              lg: "780px",
            },
            boxShadow: "none",
            borderRadius: "22px",
            padding: {
              xs: '2rem 1rem',
              sm: '2rem 3rem', 
              lg: '2rem 3rem'
            },
          },
        },
      }}
    >
      <DialogTitleContainer onClose={onClose}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one"
              sx={{
                paddingRight: "0 !important",
                xs: {fontSize: "19px !important"}
              }}
          >
            {t("App.loadMapping.createModal.title")}
          </Grid>
        </Grid>
      </DialogTitleContainer>
      <DialogContent className="no-scroll-table full-width">
        <form
          id="load-mapping-form"
          onSubmit={handleSubmit(handleSubmitCreateMapping)}
        >
          <Grid container direction="column" rowSpacing={3}>
            <Grid item>
              <BaseInfoAccordion
                t={t}
                control={control}
                errors={errors}
                setValue={setValue}
                setDocumentType={setDocType}
                documentType={docType}
                setIsStoreDetailSelected={setIsStoreDetail}
                isStoreDetailSelected={isStoreDetail}
              />{" "}
            </Grid>
            <Grid item>
              <FieldsAccordion
                t={t}
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                documentType={docType}
                isStoreDetail={isStoreDetail}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"flex-end"} columnSpacing={3}
          sx={{
            marginRight: { lg: '2rem', xl: '2rem' },
            flexDirection: { xs: 'column-reverse', sm: 'row' }
            }}
        >
          <Grid item className="mapping-btn__container">
            <Button
              className="btn__filled__gradient"
              onClick={onClose}
              sx={{ width: "100%" }}
            >
              {t("App.catalogue.cancel")}
            </Button>
          </Grid>
          <Grid item className="mapping-btn__container">
            <Button
              disabled={isLoadingCreate}
              type="submit"
              form="load-mapping-form"
              startIcon={
                !isLoadingCreate ? <AddIcon sx={{ fontSize: 16 }} /> : <></>
              }
              className="btn__filled__blue"
              sx={{ width: "100%" }}
            >
              {!isLoadingCreate ? (
                t("App.loadMapping.create")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <ModalInfo
        title={titleModalInfo}
        responseData={contentModalInfo}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          onClose();
        }}
      />
      <ModalDecision
        title={t("App.loadMapping.createModal.titleDecision")}
        message={t("App.loadMapping.createModal.contentDecision")}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </Dialog>
  );
};

ModalCreateFieldMapping.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  filterData: PropTypes.func.isRequired,
};

export { ModalCreateFieldMapping };

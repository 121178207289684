import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { RequestFilterForm } from "../Allies/requestFilterForm";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const FilterAlliesRequest = ({
  setData,
  setChipFilters,
  setIsDataLoading,
  setFilterNotFound,
  setNoContent,
  setFormFilters,
}) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(true);

  /**
   * Esquema para validar el filtro de de solicitudes de recarga
   */
  const schema = yup.object().shape({
    ally: yup
      .string()
      .matches(/^[1-100]+$/, {
        message: t("App.validationMessages.required"),
        excludeEmptyString: true,
      })
      .transform((value, originalValue) => {
        // Convert empty values to null
        if (value === "0") {
          return null;
        }
        return originalValue;
      })
      .nullable()
      .optional(),
    country: yup
      .object()
      .required(t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required")),
    commerce: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    ),
    supplier: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    ),
    documentType: yup.string(),

    initialDate: yup.date().typeError("Fecha no válida").nullable().optional(),
    finalDate: yup.date().typeError("Fecha no válida").nullable().optional(),
    user: yup
      .string()
      .matches(/^[a-zA-Z0-9\u00C0-\u00FF\s]+$/, {
        message: "No se admiten caracteres especiales",
        excludeEmptyString: true,
      })
      .nullable()
      .optional(),
  });

  /**
   * UseForm
   */
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      finalDate: null,
      initialDate: null,
      documentType: "0",
      country: "",
      commerce: [],
      supplier: [],
      ally: "0",
      user: "",
    },
  });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary>
        <TuneOutlinedIcon
          sx={{
            fontSize: 20,
            color: "#543ab4",
          }}
        />

        <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
          {" "}
          {t("App.catalogue.filterName")}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <RequestFilterForm
          control={control}
          errors={errors}
          setValue={setValue}
          reset={reset}
          watch={watch}
          handleSubmit={handleSubmit}
          setData={setData}
          setChipsFilters={setChipFilters}
          setDatatableLoading={setIsDataLoading}
          setFilterNotFound={setFilterNotFound}
          setNoContent={setNoContent}
          setFormFilters={setFormFilters}
        />
      </AccordionDetails>
    </Accordion>
  );
};

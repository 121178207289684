import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalChangeType } from "../../../components/common/DynamicReport/modalChangeType";
import { InfoGeneration } from "../../../components/common/SpecialFiles/infoGenerationAccordion";
import CheckIcon from "@mui/icons-material/Check";
import { FieldsVisualization } from "../../../components/common/SpecialFiles/fieldsVisualizationAccordion";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useParams } from "react-router-dom";
import { ModalExceedPeriod } from "../../../components/common/DynamicReport/modalExceedPeriod";
import { EditSpecialFilesClient } from "../../../services/user/specialFilesClient";
import { GetSpecialFileClient } from "../../../services/user/specialFilesClient";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, getUser, getUserStatus } from "../../userSlice";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import moment from "moment";
import { TypeSelectorReportEdit } from "../../../components/common/DynamicReport/typeSelectorEdit";

const EditSpecialFileClient = () => {
  /**
   * Mock vacío
   */
  const emptyMock = {
    typeOfReport: "1",
    generationFrequency: "Diario",
    generationDay: "",
    typeOfDataGrouping: "Diario",
    amountOfPeriodsToGenerate: 1,
    state: "1",
    checkcompany: 0,
    checkmerchant: 0,
    checkpointOfSale: 0,
    checkchannel: 0,
    checkformat: 0,
    checkdepartment: 0,
    checkcity: 0,
    checkhierarchy: 0,
    checkseller: 0,
    checktree: 0,
    checkproduct: 0,
    checkbrand: 0,
    checkcolour: 0,
    checksize: 0,
    checkdispatch: 0,
    checkstandard: 0,
    checkweight: 0,
    checkvolume: 0,
  };
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { pk, sk } = useParams();

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    typeOfReport: yup.string(),
    generationFrequency: yup.string().required(),
    generationDay: yup.string(),
    typeOfDataGrouping: yup.string().required(),
    amountOfPeriodsToGenerate: yup
      .number()
      .positive(t("App.validationMessages.greaterZero"))
      .required(t("App.validationMessages.required")),
    state: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({ resolver: yupResolver(schema), defaultValues: emptyMock });

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  /**
   * Use State
   */
  // Tipo de reporte
  const [reportType, setReportType] = useState(0);
  const [desiredType, setDesiredType] = useState(null);
  const [startForm, setStartForm] = useState(false);

  // Frecuencia de generación para componente InfoGeneration
  // SOLO se emplea para los campos del formulario que cambian según este valor. El resumen funciona con el watch hacia generationFrequency
  const [frequency, setFrequency] = useState("");
  const [selectedGenerationValue, setSelectedGenerationValue] = useState("");

  //Manejo de límite de agrupación para modal de periodo excedido
  const [groupingLimit, setGroupingLimit] = useState(null);
  const [grouping, setGrouping] = useState("0");
  const [openModalExceedPeriodLimit, setOpenModalExceedPeriodLimit] =
    useState(false);

  //Modal cambio de tipo de reporte
  const [openModalChangeType, setOpenModalChangeType] = useState(false);

  // Validaciones de checks sección de visualización
  const [showValidationChecks, setShowValidationChecks] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  //Modal de error cuando hay un error al solicitar el reporte
  const [isOpenModalFailure, setIsOpenModalFailure] = useState(false);
  const [responseModalFailure, setResponseModalFailure] = useState(false);

  //Modal respuesta al editar el archivo
  const [isOpenModalEditResponse, setIsOpenEditResponse] = useState(false);
  const [responseModalEdit, setResponseModalEdit] = useState(false);
  const [titleEditResponse, setTitleEditResponse] = useState("");

  //Limpieza de formulario
  const [isOpenModalClean, setIsOpenModalClean] = useState(false);

  //Cargas
  const [isLoading, setIsLoading] = useState(false);

  //Editar cuando la frecuencia a editar es Anual
  //Si la frecuencia es Anual se settea la fecha (para calendario)
  //De lo contrario se settea un booleano.
  const [isEdit, setIsEdit] = useState(true);

  /**
   * Use Effect
   */

  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  useEffect(() => {
    try {
      setIsLoading(true);
      setStartForm(true);
      getSpecialFileInfo();
    } catch (error) {
      console.log(
        "==============Error Get Special File Info======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, []);

  /**
   * Handles
   */

  /**
   * Trae la información de archivo especial a editar
   */
  const getSpecialFileInfo = async () => {
    const obj = { pk: `ean_provider#${pk}`, sk: `special_file#${sk}` };
    const getSpecialFileService = await GetSpecialFileClient(obj);
    if (
      getSpecialFileService.status === CODES.COD_RESPONSE_HTTP_OK &&
      getSpecialFileService.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const specialFileInfo = getSpecialFileService.data.responseMessage;
      await setInformationToForm(specialFileInfo);
    } else if (
      getSpecialFileService.data.responseCode === CODES.COD_RESPONSE_ERROR_LIST
    ) {
      setResponseModalFailure(getSpecialFileService.data);
      setIsOpenModalFailure(true);
    }
    setIsLoading(false);
  };

  /**
   * Settea la información del archivo especial en el formulario
   * @param {*} data información del archivo especial
   */
  const setInformationToForm = async (data) => {
    setStartForm(true);
    //Type
    setValue("typeOfReport", data.typeOfReport === "sales" ? "2" : "1");
    setReportType(data.typeOfReport === "sales" ? "2" : "1");

    //Generation info
    setFrequency(data.generationFrequency);
    setValue("generationFrequency", data.generationFrequency);

    switch (data.generationFrequency) {
      case "Mensual":
        setValue("generationDay", +data.generationDay);
        setSelectedGenerationValue(+data.generationDay);
        setIsEdit(true);
        break;
      case "Anual":
        //transformo date a formato
        let spplited = data.generationDay.split("-");
        let finalDate = new Date(
          spplited[1] + "-" + spplited[0] + "-" + spplited[2]
        );

        setValue("generationDay", moment(finalDate).format("DD-MM-YYYY"));
        setSelectedGenerationValue(data.generationDay);
        setIsEdit(finalDate);
        break;
      default:
        //SEMANAL Y DIARIO
        setValue(
          "generationDay",
          data.generationDay === null ? "" : data.generationDay
        );
        setSelectedGenerationValue(data.generationDay);
        setIsEdit(true);
        break;
    }

    setValue("typeOfDataGrouping", data.typeOfDataGrouping);
    setValue("amountOfPeriodsToGenerate", data.amountOfPeriodsToGenerate);
    setValue("state", data.state === "ACTIVO" ? "1" : "0");

    //Companies

    const currentCompanyToEdit = {
      label: `${data.country}-${data.provider}`,
      value: data.eanProvider,
      country: data.country,
    };

    setValue("lstCompanies", [currentCompanyToEdit]);

    //Visualization
    setValue("checkcompany", data.checkCompany === 1 ? true : false);
    setValue("checkmerchant", data.checkTrader === 1 ? true : false);
    setValue("checkpointOfSale", data.checkPointSale === 1 ? true : false);
    setValue("checkchannel", data.checkChanel === 1 ? true : false);
    setValue("checkformat", data.checkFormat === 1 ? true : false);
    setValue(
      "checkdepartment",
      data.checkDepartamentState === 1 ? true : false
    );
    setValue("checkcity", data.checkCity === 1 ? true : false);
    setValue(
      "checkhierarchy",
      data.checkSalesForceHierarchy === 1 ? true : false
    );
    setValue("checkseller", data.checkSalesMan === 1 ? true : false);
    setValue("checktree", data.checkCategoriesProduct === 1 ? true : false);
    setValue("checkproduct", data.checkProduct === 1 ? true : false);
    setValue("checkbrand", data.checkBrand === 1 ? true : false);
    setValue("checkcolour", data.checkColor === 1 ? true : false);
    setValue("checksize", data.checkSize === 1 ? true : false);
    setValue("checkdispatch", data.checkDispatchUnit === 1 ? true : false);
    setValue("checkstandard", data.checkStandardUnit === 1 ? true : false);
    setValue("checkweight", data.checkWeight === 1 ? true : false);
    setValue("checkvolume", data.checkVolume === 1 ? true : false);
  };

  /**
   * Settea el tipo de reporte
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;
    if (desiredType === null) {
      setReportType(id);
    }
    if (id !== 0) {
      setStartForm(true);

      setDesiredType(id);
    }
    if (desiredType !== null) {
      setOpenModalChangeType(true);
    }
  };

  /**
   * Maneja el envío del formulario y edita el archivo especial actual
   * @param {object} data información del formulario
   */
  const handleEditSpecialFileClient = async (data) => {
    setIsLoadingRequest(true);

    const validationChecks = validateAtLeastOneCheck(data);
    const validationLimit = isPeriodLimitValid(data);

    const okValidations = validationChecks && validationLimit;

    switch (okValidations) {
      case true:
        setShowValidationChecks(false);
        //LOS DATOS DE LA EMPRESA DEBEN SER LOS DE LA EMPRESA ASOCIADO AL REPORTE (eanProvider, provider, country)

        const obj = {
          pk: `ean_provider#${pk}`,
          sk: `special_file#${sk}`,
          eanProvider: data.lstCompanies[0].value,
          provider: data.lstCompanies[0].label.split("-")[1],
          country: data.lstCompanies[0].country,
          typeOfReport: data.typeOfReport === "1" ? "inventory" : "sales",
          generationFrequency: data.generationFrequency,
          generationDay: data.generationDay,
          typeOfDataGrouping: data.typeOfDataGrouping,
          amountOfPeriodsToGenerate: data.amountOfPeriodsToGenerate,
          checkCompany: data.checkcompany ? 1 : 0,
          checkTrader: data.checkmerchant ? 1 : 0,
          checkPointSale: data.checkpointOfSale ? 1 : 0,
          checkChanel: data.checkchannel ? 1 : 0,
          checkFormat: data.checkformat ? 1 : 0,
          checkDepartamentState: data.checkdepartment ? 1 : 0,
          checkCity: data.checkcity ? 1 : 0,
          checkSalesForceHierarchy: data.checkhierarchy ? 1 : 0,
          checkSalesMan: data.checkseller ? 1 : 0,
          checkCategoriesProduct: data.checktree ? 1 : 0,
          checkProduct: data.checkproduct ? 1 : 0,
          checkBrand: data.checkbrand ? 1 : 0,
          checkColor: data.checkcolour ? 1 : 0,
          checkSize: data.checksize ? 1 : 0,
          checkDispatchUnit: data.checkdispatch ? 1 : 0,
          checkStandardUnit: data.checkstandard ? 1 : 0,
          checkWeight: data.checkweight ? 1 : 0,
          checkVolume: data.checkvolume ? 1 : 0,
          state: data.state === "1" ? "ACTIVO" : "INACTIVO",
          modificationUser: userInfo.firstName + " " + userInfo.lastName,
        };

        const serviceEditSpecialFile = await EditSpecialFilesClient(obj);

        setResponseModalEdit(serviceEditSpecialFile);

        setTitleEditResponse(
          setModalTitle(serviceEditSpecialFile.data.responseCode)
        );
        setIsOpenEditResponse(true);

        break;

      case false:
        if (!validationChecks) {
          setShowValidationChecks(true);
        } else {
          setShowValidationChecks(false);
        }
        if (!validationLimit) {
          setOpenModalExceedPeriodLimit(true);
        }

        break;
    }

    setIsLoadingRequest(false);
  };

  /**
   * Valida que exista al menos un check seleccionado en la sección de visualización
   * @param {} data campos del formulario
   * @returns true si al menos un check está marcado, false si no hay check seleccionado
   */
  const validateAtLeastOneCheck = (data) => {
    const objToArray = Object.entries(data);

    const checkAtLeastOne = objToArray.filter(
      (element) => element[0].includes("check") && element[1]
    );

    if (checkAtLeastOne.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Checkea que el usuario haya elegido el número correcto para el periodo seleccionado
   * @param {} data datos del formulario
   * @returns true si el user eligió el número correcto para el periodo seleccionado, false si no
   */
  const isPeriodLimitValid = (data) => {
    const groupingType = data.typeOfDataGrouping;
    const periodNumbers = data.amountOfPeriodsToGenerate;

    if (groupingType === "Diario" && periodNumbers > 31) {
      setGroupingLimit(31);
      setGrouping("0");

      return false;
    } else if (groupingType === "Semanal" && periodNumbers > 52) {
      setGrouping("1");
      setGroupingLimit(52);

      return false;
    } else if (groupingType === "Mensual" && periodNumbers > 12) {
      setGrouping("2");
      setGroupingLimit(12);

      return false;
    } else if (groupingType === "Anual" && periodNumbers > 2) {
      setGrouping("3");
      setGroupingLimit(2);

      return false;
    } else {
      return true;
    }
  };

  /**
   * Navega a la vista de administrar archivos especiales
   */
  const handleBackButton = () => {
    navigate("/user/adminSpecialFiles");
  };

  /**
   * Limpia el formulario
   */
  const handleCleanForm = () => {
    reset();
    setReportType("1");
    setFrequency("Diario");
    setValue("generationFrequency", "Diario");
    setSelectedGenerationValue("");
    setIsOpenModalClean(false);
  };

  /**
   * Settea el título del modal según el código de repuesta
   * @param {number} codeResponde código de respuesta
   * @returns Título del modal
   */
  const setModalTitle = (codeResponse) => {
    switch (codeResponse) {
      case 0:
        return t("App.validationMessages.success");

      case 1005:
        return t("App.specialFilesClient.error1005");

      case 1009:
        return t("App.specialFilesClient.error1009");

      default:
        return t("App.specialFilesClient.error1005");
    }
  };

  return (
    <>
      {isLoading ? (
        <CustomSkeleton />
      ) : (
        <form onSubmit={handleSubmit(handleEditSpecialFileClient)}>
          <Grid container direction="column" spacing={2}>
            <ModalChangeType
              componentName={"specialFile"}
              open={openModalChangeType}
              setOpen={setOpenModalChangeType}
              setReportType={setReportType}
              desiredType={desiredType}
              setValue={setValue}
            />

            <ModalExceedPeriod
              open={openModalExceedPeriodLimit}
              setOpen={setOpenModalExceedPeriodLimit}
              grouping={grouping}
              limit={groupingLimit}
            />

            <ModalInfo
              title={t("App.validationMessages.error")}
              responseData={responseModalFailure}
              open={isOpenModalFailure}
              onClose={() => {
                setIsOpenModalFailure(false);
                handleBackButton();
              }}
            />

            <ModalInfo
              title={titleEditResponse}
              responseData={responseModalEdit}
              open={isOpenModalEditResponse}
              onClose={() => {
                setIsOpenEditResponse(false);
                handleBackButton();
              }}
            />

            <ModalDecision
              isOpen={isOpenModalClean}
              setIsOpen={setIsOpenModalClean}
              handleAgree={handleCleanForm}
              handleDisagree={() => {
                setIsOpenModalClean(false);
              }}
              title={t("App.specialFilesClient.cleanFormat")}
              message={""}
              agreeText={t("App.buttonLabels.accept")}
              disagreeText={t("App.buttonLabels.cancel")}
            />

            <Grid item style={{ marginTop: "2%" }}>
              <p className="display-large__primary-one">
                {t("App.editSpecialFile.title")}
              </p>
            </Grid>

            <Grid item className="back-container">
              <Grid container direction="row">
                <Grid xs={1.5} sm={0.7} md={0.4} item>
                  <IconButton disableRipple onClick={handleBackButton}>
                    <ArrowBackIosNewIcon className="display-large__moderate-blue" />
                  </IconButton>
                </Grid>

                <Grid xs={10.5} sm={11} md={11} item className="col-start">
                  <p className="display-small__moderate-blue">
                    {t("App.createSpecialFile.backButtom")}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <TypeSelectorReportEdit
                componentName={"specialFile"}
                reportType={reportType}
                setReportType={setReportType}
                control={control}
                handleTypeChange={handleTypeChange}
                typeOfDocument={"typeOfReport"}
                errors={errors}
                setValue={setValue}
                setStartForm={setStartForm}
                setDesiredType={setDesiredType}
              />
            </Grid>

            {startForm && (
              <>
                <Grid item className="companies_info_container u-marginT">
                  {/** control, errors, setValue, watch son elementos para que los hijos controlen el form */}
                  {/** frequency y setFrequency es para comportamientos en torno a frequencia en el formulario */}
                  {/** selectedGenerationValue es para controlar valor del campo día generación  */}
                  <InfoGeneration
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                    frequency={frequency}
                    setFrequency={setFrequency}
                    selectedGenerationValue={selectedGenerationValue}
                    setSelectedGenerationValue={setSelectedGenerationValue}
                    isEdit={isEdit}
                  />
                </Grid>

                <Grid
                  item
                  className="companies_info_container full-width u-marginT"
                >
                  <FieldsVisualization
                    control={control}
                    register={register}
                    showValidationChecks={showValidationChecks}
                  />
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    className="col-flex"
                    style={{ padding: "4%" }}
                  >
                    <Grid item>
                      <Button
                        className="btn__outlined__blue"
                        onClick={() => {
                          setIsOpenModalClean(true);
                        }}
                      >
                        {t("App.buttonLabels.clean")}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        type="submit"
                        startIcon={!isLoadingRequest ? <CheckIcon /> : <></>}
                        className="btn__filled__blue"
                      >
                        {!isLoadingRequest ? (
                          t("App.buttonLabels.save")
                        ) : (
                          <CircularProgress size={21} color="inherit" />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      )}
    </>
  );
};

export default EditSpecialFileClient;

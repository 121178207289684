import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../Modals/modalInfo";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { getUser } from "../../../pages/userSlice";
import { useSelector } from "react-redux";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import { GetTradersService } from "../../../services/user/deleteAndReload/deleteAndReload";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateTransmissionRegistry } from "../../../services/user/transmittedValueService";

const CreateTransmittedValue = ({
  setData,
  dataArray,
  setDataStructure,
  setIsLoadingData,
  setNoContent,
}) => {
  const [open, setOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [commerceList, setCommerceList] = useState([]);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [createData, setCreateData] = useState(false);
  const [titleModalInfo, setTitleModalInfo] = useState("");

  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Schema for the create a new type of transmitted value form
   */
  const schema = yup.object().shape({
    // country: yup.string().required("*Este campo es requerido"),
    // commerce: yup.string().required(t("App.validationMessages.required")),
    // informationType: yup.string().required("*Este campo es requerido"),
    // transmittedValueType: yup.string().required("*Este campo es requerido"),
    // status: yup.string().required("*Este campo es requerido"),
  });

  /**
   * useForm to resolver of the schema validation
   */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect to update the state of isDesktop by the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Method to open the modal and fetch the data for the autocompletes of country
   */
  const handleOpen = () => {
    async function fetchData() {
      try {
        // Fetch the data for the countriesList state
        const countriesService = await GetGeographiesList();

        if (
          countriesService.status === CODES.COD_RESPONSE_HTTP_OK &&
          countriesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const countries = countriesService.data.responseMessage.map(
            (country) => {
              const countryCapitalized =
                country.countryName[0] +
                country.countryName.slice(1).toLowerCase();
              return {
                value: country.country,
                label: countryCapitalized,
              };
            }
          );

          setCountriesList(countries);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
    setOpen(true);
  };

  /**
   * Method to clear the commerce field  when the reason of autocomplete country is equal to clear
   */
  const resetValuesWhenClear = () => {
    setValue("commerce", null);
  };

  /**
   * Method to fetch the data for the autocomplete of commerce
   *
   * @param {Object} value -> apply the destructuring of object to get the value
   */
  const handleCommercesList = async ({ value }) => {
    const obj = {
      country: value,
    };

    const commercePerCountry = await GetTradersService(obj);

    if (
      commercePerCountry.status === CODES.COD_RESPONSE_HTTP_OK &&
      commercePerCountry.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const commercesList = commercePerCountry.data.responseMessage.map(
        (commerce) => {
          return {
            label: commerce.companyName,
            value: commerce.eanCompany,
          };
        }
      );

      setCommerceList(commercesList);
    }
  };

  /**
   * Method to close the modal
   */
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  /**
   * Method to create a new type of transmitted value
   *
   * @param {Object} data
   */
  const handleCreateTransmittedValue = async (data) => {
    try {
      setLoadingCreate(true);

      const obj = {
        eanTrader: data.commerce.value,
        trader: data.commerce.label,
        country: data.country.value,
        typeOfInformation: data.informationType,
        typeOfValueTransmitted: data.transmittedValueType,
        state: data.status === 1 ? "ACTIVO" : "INACTIVO",
        creationUser: fullNameUser,
      };

      const createTransmittedValueService = await CreateTransmissionRegistry(
        obj
      );

      setCreateData(createTransmittedValueService);

      setLoadingCreate(false);

      if (createTransmittedValueService.status === CODES.COD_RESPONSE_HTTP_OK) {
        const responseCode = createTransmittedValueService.data.responseCode;

        setTitleModalInfo("");

        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setSuccess(true);
            updateDatatableVisually(obj);
            break;
          case CODES.COD_RESPONSE_ERROR_LOGIN:
            setTitleModalInfo(
              t("App.adminTransmittedValue.modals.errorCreateModalInfo")
            );
            setError(true);
            break;
          case CODES.COD_RESPONSE_ERROR_CREATE:
            setError(true);
            break;
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(
        "============== Error createTransmittedValue.jsx function handleCreateTransmittedValue ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Method to update the table when create a new type of transmitted value
   *
   * @param {Object} newTransmittedValue
   */
  const updateDatatableVisually = (newTransmittedValue) => {
    setNoContent(false);
    setIsLoadingData(true);
    setData([]);
    const newRow = setDataStructure([newTransmittedValue], true);

    let newDataArray = dataArray;

    newDataArray.unshift(newRow[0]);
    const finalArray = newDataArray.map((item) => item);

    setData(finalArray);

    setIsLoadingData(false);
  };

  return (
    <>
      <Button
        className="btn__applyFilter"
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        {t("App.adminTransmittedValue.modals.titleCreate")}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box className="scroll-y modal-box-createEditCompany">
          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton component="span" onClick={handleClose}>
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.adminTransmittedValue.modals.titleCreate")}
              </h1>
            </Grid>

            <Grid item className="full-width">
              <form onSubmit={handleSubmit(handleCreateTransmittedValue)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={4}>
                      <Controller
                        defaultValue=""
                        name="country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.country
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            options={countriesList}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("App.createAndEditCompany.country")}
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            onChange={(event, values) => {
                              onChange(values);
                              // Clear the commerce field when change the value of the autocomplete of country
                              setValue("commerce", null);
                              handleCommercesList(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <Controller
                        defaultValue=""
                        name="commerce"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled={watch("country") ? false : true}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={`${
                              errors.commerce
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            options={commerceList}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(
                                  "App.deleteAndReload.filters.commerce"
                                )}
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                            onChange={(event, values, reason, e) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t(
                            "App.adminTransmittedValue.modals.informationType"
                          )}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="informationType"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              disabled={watch("commerce") ? false : true}
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.informationType
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t(
                                  "App.adminTransmittedValue.modals.informationType"
                                )}
                              </MenuItem>
                              <MenuItem value="Inventarios">
                                {t(
                                  "App.adminTransmittedValue.modals.selectOptions.informationType.inventory"
                                )}
                              </MenuItem>
                              <MenuItem value="Ventas">
                                {t(
                                  "App.adminTransmittedValue.modals.selectOptions.informationType.sales"
                                )}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t(
                            "App.adminTransmittedValue.modals.transmittedValueType"
                          )}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="transmittedValueType"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              disabled={watch("informationType") ? false : true}
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.transmittedValueType
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t(
                                  "App.adminTransmittedValue.modals.transmittedValueType"
                                )}
                              </MenuItem>
                              <MenuItem value="Precio de venta público con impuestos">
                                {t(
                                  "App.adminTransmittedValue.modals.selectOptions.option1"
                                )}
                              </MenuItem>
                              <MenuItem value="Precio de venta público sin impuestos">
                                {t(
                                  "App.adminTransmittedValue.modals.selectOptions.option2"
                                )}
                              </MenuItem>
                              <MenuItem value="Precio de compra con impuestos">
                                {t(
                                  "App.adminTransmittedValue.modals.selectOptions.option3"
                                )}
                              </MenuItem>
                              <MenuItem value="Precio de compra sin impuestos">
                                {t(
                                  "App.adminTransmittedValue.modals.selectOptions.option4"
                                )}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.listUsers.status")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.status
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.createAndEditCompany.status")}
                              </MenuItem>
                              <MenuItem value={1}>
                                {t("App.createRole.active")}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <br />
                <br />

                <Grid
                  item
                  container
                  spacing={2}
                  direction={isDesktop ? "row" : "column-reverse"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} lg={1} className="full-width">
                    <Button
                      className="btn__filled__gradient full-width"
                      onClick={handleClose}
                    >
                      {t("App.buttonLabels.cancel")}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4} lg={2.2} className="full-width">
                    <Button
                      disabled={
                        watch("country") &&
                        watch("commerce") &&
                        watch("informationType") &&
                        watch("transmittedValueType") &&
                        watch("status")
                          ? false
                          : true
                      }
                      className={`${
                        watch("country") &&
                        watch("commerce") &&
                        watch("informationType") &&
                        watch("transmittedValueType") &&
                        watch("status")
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width`}
                      type="submit"
                      startIcon={!loadingCreate ? <AddIcon /> : <></>}
                    >
                      {!loadingCreate ? (
                        t("App.adminTransmittedValue.modals.buttonCreate")
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>

                <br />
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {error && (
        <ModalInfo
          title={titleModalInfo}
          responseData={createData}
          open={open}
          onClose={() => {
            reset();
            setError(false);
            setSuccess(false);
            setOpen(false);
          }}
        />
      )}

      {success && (
        <ModalInfo
          responseData={createData}
          open={open}
          onClose={() => {
            reset();
            setError(false);
            setSuccess(false);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CreateTransmittedValue;

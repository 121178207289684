import { Autocomplete, Grid, TextField } from "@mui/material";
import { React, useState, useEffect, useRef } from "react";
import { getUser, resetUserStatus } from "../../pages/userSlice";
import { useTranslation } from "react-i18next";

import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router";
import { CheckSession, LoginService } from "../../services/auth/authServices";
import { CODES } from "../../consts/codes";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import logo from "../../assets/common/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { Controller, useForm } from "react-hook-form";
import { UpdateSupportCompany } from "../../services/admin/adminServices";
import { GetListReceiver } from "../../services/user/tracebility/traceabilityService";

export const LoginSupportUser = () => {
  const userInfo = useSelector(getUser);
  /**
   * Use State
   */
  const [receivers, setReceivers] = useState([]);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [respCode, setRespCode] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    if (localStorage.getItem("infoToken")) {
      redirectOk();
    } else {
      getReceivers();
    }
  }, [isLogin]);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   *
   */
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  /**
   * Cerrar el modal de error
   */
  const handleAction = () => {
    setIsOpenErrorModal(false);
  };

  /**
   * Cambiar empresa del usuario de soporte
   */
  const handleUpdate = async () => {
    const data = getValues();
    try {
      const obj = {
        eanCompany: data.receiver.eanCompany,
        companyName: data.receiver.companyName,
        country: data.receiver.country,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await UpdateSupportCompany(obj);
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        //setTitle(t("App.updateCompany.title"));
        setRespCode(responseCode);
        setMessage(responseMessage);
        handleLogin();
      } else {
        setIsOpenErrorModal(true);
      }
    } catch (error) {
      setTitle(t("App.login.errors.supportTitle"));
      setMessage(t("App.login.errors.supportCompany"));
      setIsOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Obtener lista de empresas
   */
  const getReceivers = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListReceiver();

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let listCompanies = [];
        responseMessage.map((item) => {
          listCompanies.push({
            eanCompany: item.eanCompany,
            companyName: item.companyName,
            country: item.country,
          });
        });

        listCompanies = listCompanies.map((item) => {
          return {
            companyName: item.companyName,
            eanCompany: item.eanCompany,
            country: item.country,
          };
        });
        setReceivers(listCompanies);
      } else {
        setTitle("Error");
        setMessage(t("App.login.errors.supportCompany"));
        setIsOpenErrorModal(true);
      }
    } catch (error) {
      setTitle("Error");
      setMessage(t("App.login.errors.supportCompany"));
      setIsOpenErrorModal(true);
      console.log("-------------");
      console.log(error);
      console.log("-------------");
    }
  };

  /**
   * Iniciar sesión con el usuario de soporte
   */
  const handleLogin = async (data) => {
    try {
      const email = "";
      const password = "";
      const obj = {
        email: "soporteprescriptiva@gmail.com",
        password: "Soporte1**",
      };
      const check = await CheckSession(obj);
      if (check.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (check.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          const service = await LoginService(obj);
          // Veerificar si se realizó el login correctamente
          if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (service.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
              dispatch(resetUserStatus());

              //Guardo valor que me indica que el usuario inició sesión
              sessionStorage.setItem("activeSession", "true");

              const accessToken = service.data.responseMessage.accessToken;
              localStorage.setItem("accessToken", accessToken);

              const refreshToken = service.data.responseMessage.refreshToken;
              localStorage.setItem("refreshToken", refreshToken);

              const jwtTokenInfo = service.data.responseMessage.idToken;
              localStorage.setItem("infoToken", jwtTokenInfo);

              const payloadToken = service.data.responseMessage.idToken;
              localStorage.setItem(
                "payloadToken",
                JSON.stringify(payloadToken)
              );
              setIsLogin(true);
            }
          }
        } else {
          setIsOpenErrorModal(true);
        }
      }
    } catch (error) {
      console.log(
        "===============ERROR LOGIN.JSX handleLogin ====================="
      );
      console.log(error);
      console.log("====================================================");
    }
  };

  /**
   * Redireccionar a la página de home
   */
  const redirectOk = () => {
    const jwtTokenInfo = localStorage.getItem("infoToken");
    if (jwtTokenInfo) {
      const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);
      const user = jwtDecodeTokenInfo["custom:Role"];
      if (user) {
        if (user === "user") {
          navigate("/user/home");
        }
      }
    }
  };

  return (
    <div className="changePasswordFT__wrap">
      <div className="changePasswordFT__leftSide">
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <div className="navbar__logo">
              <img
                src={logo}
                alt="Logo Prescriptiva"
                className="logo-CENAnalíticos"
              />
            </div>
          </Grid>

          <Grid item>
            <Grid
              container
              spacing={2.5}
              direction="column"
              alignItems="flex-start"
            >
              <Grid item>
                <p className="display-large__primary-one">
                  {t("App.login.rightPanel.support")}
                </p>
              </Grid>

              <Grid item></Grid>
            </Grid>
          </Grid>

          <Grid
            item
            className="changePasswordFT__form"
            sx={{ width: "50%", justifyItems: "center" }}
          >
            <Grid item className="loginSup__elements">
              <Controller
                control={control}
                name="receiver"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disableClearable
                    options={receivers}
                    getOptionLabel={(option) => option.companyName}
                    noOptionsText={t("App.listUsers.noOptions")}
                    isOptionEqualToValue={(option, value) =>
                      option.eanCompany === value.eanCompany
                    }
                    className={"select-contact__filters"}
                    renderInput={({ inputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        label={t("App.login.rightPanel.supportLabel")}
                        inputProps={{
                          ...inputProps,
                          readOnly: false,
                          endAdornment: "",
                        }}
                      />
                    )}
                    onChange={(event, values, reason) => {
                      onChange(values);
                    }}
                  />
                )}
              />
            </Grid>
            <br />
            <Grid
              item
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <button
                className="login__form-button loginSup__button"
                type="submit"
                onClick={handleUpdate}
              >
                {t("App.login.rightPanel.button")}
              </button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="changePasswordFT__rightSide">
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justify="center"
        >
          <LanguageSwitcher />
          <Grid item>
            <p className="display-x-large__text-white text-center">
              {t("App.login.leftPanel.welcomeTitle")}
            </p>
          </Grid>

          <Grid item>
            <p className="heading__text-white text-center">
              {t("App.login.leftPanel.welcomeMessage")}
            </p>
          </Grid>
        </Grid>
      </div>
      <ModalInfo
        title={title}
        responseData={{
          status: respCode,
          data: {
            responseCode: respCode,
            responseMessage: message,
          },
        }}
        open={isOpenErrorModal}
        onClose={handleAction}
        close={false}
      />
    </div>
  );
};

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalChangeType } from "../../../components/common/DynamicReport/modalChangeType";
import { InfoGeneration } from "../../../components/common/SpecialFiles/infoGenerationAccordion";
import CheckIcon from "@mui/icons-material/Check";
import { FieldsVisualization } from "../../../components/common/SpecialFiles/fieldsVisualizationAccordion";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateSpecialFilesClient } from "../../../services/user/specialFilesClient";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { ModalExceedPeriod } from "../../../components/common/DynamicReport/modalExceedPeriod";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, getUser, getUserStatus } from "../../userSlice";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import { TypeSelectorReport } from "../../../components/common/DynamicReport/typeSelector";

const CreateSpecialFileClient = () => {
  /**
   * Mock vacío
   */

  const emptyMock = {
    typeOfReport: "1",
    generationFrequency: "Diario",
    generationDay: "",
    typeOfDataGrouping: "Diario",
    amountOfPeriodsToGenerate: 1,
    state: "1",
    checkmerchant: 0,
    checkpointOfSale: 0,
    checkchannel: 0,
    checkformat: 0,
    checkdepartment: 0,
    checkcity: 0,
    checkhierarchy: 0,
    checkseller: 0,
    checktree: 0,
    checkproduct: 0,
    checkbrand: 0,
    checkcolour: 0,
    checksize: 0,
    checkdispatch: 0,
    checkstandard: 0,
    checkweight: 0,
    checkvolume: 0,
    lstCompanies: [],
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    typeOfReport: yup.string(),
    generationFrequency: yup
      .string()
      .required(t("App.validationMessages.required")),
    generationDay: yup.string(),
    typeOfDataGrouping: yup
      .string()
      .required(t("App.validationMessages.required")),
    amountOfPeriodsToGenerate: yup
      .number()
      .positive(t("App.validationMessages.greaterZero"))
      .required(t("App.validationMessages.required")),
    state: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({ resolver: yupResolver(schema), defaultValues: emptyMock });

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  /**
   * Use State
   */
  // Tipo de reporte
  const [reportType, setReportType] = useState(0);
  const [desiredType, setDesiredType] = useState(null);
  const [startForm, setStartForm] = useState(false);

  //Manejo de límite de agrupación para modal de periodo excedido
  const [groupingLimit, setGroupingLimit] = useState(null);
  const [grouping, setGrouping] = useState("0");
  const [openModalExceedPeriodLimit, setOpenModalExceedPeriodLimit] =
    useState(false);

  // Frecuencia de generación para componente InfoGeneration
  // SOLO se emplea para los campos del formulario que cambian según este valor. El resumen funciona con el watch hacia generationFrequency
  const [frequency, setFrequency] = useState("Diario");
  const [selectedGenerationValue, setSelectedGenerationValue] = useState("");

  //Modal cambio de tipo de reporte
  const [openModalChangeType, setOpenModalChangeType] = useState(false);

  // Validaciones de checks sección de visualización
  const [showValidationChecks, setShowValidationChecks] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  //Modal respuesta al crear el archivo
  const [isOpenResponseModal, setIsOpenResponseModal] = useState(false);
  const [modalInfoTitle, setModalInfoTitle] = useState("");
  const [modalResponse, setModalResponse] = useState({});

  //Limpieza de formulario
  const [isOpenModalClean, setIsOpenModalClean] = useState(false);

  //Grupo empresarial
  const [isBusinessGroupReport, setIsBusinessGroupReport] = useState(false);

  /**
   * Use Effect
   */

  /**
   * Trae la información del usuario
   */
  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  /**
   * Handles
   */

  /**
   * Settea el tipo de formulario elegido
   * @param {*} event valor de formulario elegido
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;
    if (desiredType === null) {
      setReportType(id);
    }
    if (id !== 0) {
      setStartForm(true);

      setDesiredType(id);
    }
    if (desiredType !== null) {
      setOpenModalChangeType(true);
    }
  };

  /**
   * Crea un nuevo archivo especial realizando las verificaciones necesarias
   * @param {} data información del formulario
   */
  const handleCreateSpecialFileClient = async (data) => {
    setIsLoadingRequest(true);

    const validationChecks = validateAtLeastOneCheck(data);
    const validationLimit = isPeriodLimitValid(data);

    const okValidations = validationChecks && validationLimit;

    switch (okValidations) {
      case true:
        setShowValidationChecks(false);

        //GRUPO EMPRESARIAL: Dejamos listo el obj de las empresas seleccionadas
        //En caso de que este no tenga grupo, se envía arreglo vacío
        const finalCompanies = isBusinessGroupReport
          ? data.lstCompanies.map((company) => ({
              eanCompany: company.value,
              nameCompany: company.label.split("-")[1],
              country: company.country,
            }))
          : [];

        //LOS DATOS DE LA EMPRESA SI DEBEN SER LOS DEL USUARIO LOGUEADO (eanProvider, provider, country)

        const obj = {
          eanProvider: userInfo.company.eanCompany,
          provider: userInfo.company.companyName,
          country: userInfo.company.country,
          typeOfReport: data.typeOfReport === "1" ? "inventory" : "sales",
          generationFrequency: data.generationFrequency,
          generationDay: data.generationDay,
          typeOfDataGrouping: data.typeOfDataGrouping,
          amountOfPeriodsToGenerate: data.amountOfPeriodsToGenerate,
          checkCompany: data.checkcompany ? 1 : 0,
          checkTrader: data.checkmerchant ? 1 : 0,
          checkPointSale: data.checkpointOfSale ? 1 : 0,
          checkChanel: data.checkchannel ? 1 : 0,
          checkFormat: data.checkformat ? 1 : 0,
          checkDepartamentState: data.checkdepartment ? 1 : 0,
          checkCity: data.checkcity ? 1 : 0,
          checkSalesForceHierarchy: data.checkhierarchy ? 1 : 0,
          checkSalesMan: data.checkseller ? 1 : 0,
          checkCategoriesProduct: data.checktree ? 1 : 0,
          checkProduct: data.checkproduct ? 1 : 0,
          checkBrand: data.checkbrand ? 1 : 0,
          checkColor: data.checkcolour ? 1 : 0,
          checkSize: data.checksize ? 1 : 0,
          checkDispatchUnit: data.checkdispatch ? 1 : 0,
          checkStandardUnit: data.checkstandard ? 1 : 0,
          checkWeight: data.checkweight ? 1 : 0,
          checkVolume: data.checkvolume ? 1 : 0,
          state: data.state === "1" ? "ACTIVO" : "INACTIVO",
          creationUser: userInfo.firstName + " " + userInfo.lastName,
          lstCompanies: finalCompanies,
        };

        const serviceCreateSpecialFile = await CreateSpecialFilesClient(obj);
        setModalResponse(serviceCreateSpecialFile);
        setModalInfoTitle(
          setModalTitle(serviceCreateSpecialFile.data.responseCode)
        );
        setIsOpenResponseModal(true);
        break;

      case false:
        if (!validationChecks) {
          setShowValidationChecks(true);
        } else {
          setShowValidationChecks(false);
        }
        if (!validationLimit) {
          setOpenModalExceedPeriodLimit(true);
        }

        break;
    }

    setIsLoadingRequest(false);
  };

  /**
   * Valida que exista al menos un check seleccionado en la sección de visualización
   * @param {} data campos del formulario
   * @returns true si al menos un check está marcado, false si no hay check seleccionado
   */
  const validateAtLeastOneCheck = (data) => {
    const objToArray = Object.entries(data);

    const checkAtLeastOne = objToArray.filter(
      (element) => element[0].includes("check") && element[1]
    );

    if (checkAtLeastOne.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Checkea que el usuario haya elegido el número correcto para el periodo seleccionado
   * @param {} data datos del formulario
   * @returns true si el user eligió el número correcto para el periodo seleccionado, false si no
   */
  const isPeriodLimitValid = (data) => {
    const groupingType = data.typeOfDataGrouping;
    const periodNumbers = data.amountOfPeriodsToGenerate;

    if (groupingType === "Diario" && periodNumbers > 31) {
      setGroupingLimit(31);
      setGrouping("0");

      return false;
    } else if (groupingType === "Semanal" && periodNumbers > 52) {
      setGrouping("1");
      setGroupingLimit(52);

      return false;
    } else if (groupingType === "Mensual" && periodNumbers > 12) {
      setGrouping("2");
      setGroupingLimit(12);

      return false;
    } else if (groupingType === "Anual" && periodNumbers > 2) {
      setGrouping("3");
      setGroupingLimit(2);

      return false;
    } else {
      return true;
    }
  };

  /**
   * Navega a la vista de administrar archivos especiales
   */
  const handleBackButton = () => {
    navigate("/user/adminSpecialFiles");
  };

  /**
   * Limpia el formulario
   */
  const handleCleanForm = () => {
    reset();
    setReportType("0");
    setDesiredType(null);
    setFrequency("Diario");
    setValue("generationFrequency", "Diario");
    setSelectedGenerationValue("");
    setIsOpenModalClean(false);
  };

  /**
   * Settea el título del modal según el código de repuesta
   * @param {number} codeResponde código de respuesta
   * @returns Título del modal
   */
  const setModalTitle = (codeResponse) => {
    switch (codeResponse) {
      case 0:
        return t("App.specialFilesClient.success0");

      case 1005:
        return t("App.specialFilesClient.error1005");

      case 1009:
        return t("App.specialFilesClient.error1009");

      default:
        return t("App.specialFilesClient.error1005");
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateSpecialFileClient)}>
      <ModalDecision
        isOpen={isOpenModalClean}
        setIsOpen={setIsOpenModalClean}
        handleAgree={handleCleanForm}
        handleDisagree={() => {
          setIsOpenModalClean(false);
        }}
        title={t("App.specialFilesClient.cleanFormat")}
        message={""}
        agreeText={t("App.buttonLabels.accept")}
        disagreeText={t("App.buttonLabels.cancel")}
      />

      <ModalInfo
        title={modalInfoTitle}
        responseData={modalResponse}
        open={isOpenResponseModal}
        onClose={() => {
          setIsOpenResponseModal(false);
          handleBackButton();
        }}
      />

      <Grid container direction="column" spacing={2}>
        <ModalChangeType
          componentName={"specialFile"}
          open={openModalChangeType}
          setOpen={setOpenModalChangeType}
          setReportType={setReportType}
          desiredType={desiredType}
          setValue={setValue}
        />

        <ModalExceedPeriod
          open={openModalExceedPeriodLimit}
          setOpen={setOpenModalExceedPeriodLimit}
          grouping={grouping}
          limit={groupingLimit}
        />

        <Grid item style={{ marginTop: "2%" }}>
          <p className="display-large__primary-one">
            {t("App.createSpecialFile.title")}
          </p>
        </Grid>

        <Grid item className="back-container">
          <Grid container direction="row">
            <Grid xs={1.5} sm={0.7} md={0.4} item>
              <IconButton disableRipple onClick={handleBackButton}>
                <ArrowBackIosNewIcon className="display-large__moderate-blue" />
              </IconButton>
            </Grid>

            <Grid xs={10.5} sm={11} md={11} item className="col-start">
              <p className="display-small__moderate-blue">
                {t("App.createSpecialFile.backButtom")}
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="grid__container__fullW">
          <TypeSelectorReport
            componentName={"specialFile"}
            reportType={reportType}
            setReportType={setReportType}
            control={control}
            handleTypeChange={handleTypeChange}
            typeOfDocument={"typeOfReport"}
            errors={errors}
            setValue={setValue}
            setStartForm={setStartForm}
            setDesiredType={setDesiredType}
            setIsBusinessGroupReport={setIsBusinessGroupReport}
            handleCleanForm={handleCleanForm}
            getValues={getValues}
            typeOption={1}
          />
        </Grid>

        {startForm && (
          <>
            <Grid item className="companies_info_container u-marginT">
              {/** control, errors, setValue, watch son elementos para que los hijos controlen el form */}
              {/** frequency y setFrequency es para comportamientos en torno a frequencia en el formulario */}
              {/** selectedGenerationValue es para controlar valor del campo día generación  */}
              <InfoGeneration
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                frequency={frequency}
                setFrequency={setFrequency}
                selectedGenerationValue={selectedGenerationValue}
                setSelectedGenerationValue={setSelectedGenerationValue}
                isEdit={false}
              />
            </Grid>

            <Grid
              item
              className="companies_info_container full-width u-marginT"
            >
              <FieldsVisualization
                control={control}
                register={register}
                showValidationChecks={showValidationChecks}
              />
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={2}
                className="col-flex"
                style={{ padding: "4%" }}
              >
                <Grid item>
                  <Button
                    className="btn__outlined__blue"
                    onClick={() => {
                      setIsOpenModalClean(true);
                    }}
                  >
                    {t("App.buttonLabels.clean")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    type="submit"
                    startIcon={!isLoadingRequest ? <CheckIcon /> : <></>}
                    className="btn__filled__blue"
                  >
                    {!isLoadingRequest ? (
                      t("App.buttonLabels.save")
                    ) : (
                      <CircularProgress size={21} color="inherit" />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default CreateSpecialFileClient;

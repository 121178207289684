import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import FileState from "../../../../components/common/fileState";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../../userSlice";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { NoInfoComponent } from "../../../../components/common/NoInfoImage/noInfoComponent";
import { DataTable } from "../../../../components/DataTable/DataTable";
import moment from "moment";
import ModalInfoTraders from "../../../../components/common/Modals/modalInfoTraders";
import { CODES } from "../../../../consts/codes";
import {
  DownloadTradersOnDownload,
  GenerateDownloadMastersTraders,
  GetSuppliersService,
  ListDownloadMastersTraders,
} from "../../../../services/user/traders/mastersTradersService";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import ModalInfoMasters from "../../../../components/common/Modals/modalInfoMasters";
import ModalFailService from "../../../../components/common/modalFailService";
export const DownloadMastersTraders = ({ currentMaster }) => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.mastersDownload.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.mastersDownload.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.mastersDownload.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "file",
      label: t("App.mastersDownload.table.file"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value.fileName}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) =>
                  handleDownloadFile(value.fileName, value.pathFileName)
                }
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.mastersDownload.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container justifyContent="space-around" alignItems="center">
              {validateStates("ERROR SISTEMA") ? (
                <a style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                  <FileState
                    value={value}
                    onChange={(event) => updateValue(event.target.value)}
                  />
                </a>
              ) : (
                <FileState
                  value={value}
                  onChange={(event) => updateValue(event.target.value)}
                />
              )}

              {validateStates(value) && (
                <ModalInfoMasters
                  state={value}
                  text={textState}
                  onClose={() => setOpen(false)}
                  open={open}
                />
              )}
            </Grid>
          );
        },
      },
    },
  ];

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <Skeleton />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Manejo de tabla
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  //Estados para tabla
  const [open, setOpen] = useState(false);
  const [textState, setTextState] = useState("");

  // modal error
  const [openErrorModal, setOpenErrorModal] = useState(false);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Cargas visuales
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  //Comerciantes para filtro
  const [tradersList, setTradersList] = useState([]);
  const [traderSelected, setTraderSelected] = useState([]);

  //Carga de resultados de tabla
  const [foundResults, setFoundResults] = useState(false);

  //Modal respuesta carga maestro
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState({
    data: { responseMessage: "" },
  });

  /**
   * Const
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    setData([]);  // Vacía la data cada vez que cambia currentMaster
    getDownloadMasters()
  }, [currentMaster]);  

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * useEffect para traer la información a mostrar en la tabla
   */
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setIsLoadingRefresh(true);
        setData([]);

        if (userInfo) {
          await fetchSuppliers();
          await getDownloadMasters();

          setIsLoadingRefresh(false);
        }
      } catch (error) {
        setOpenErrorModal(true);

        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
  }, [refresh, userInfo]);

  const getDownloadMasters = async () => {
    try {
      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        emailLoad: userInfo.email,
        idFileType: getMasterName() === "objectives" ? 25 : 26,
      };

      const {
        data: { responseCode, data },
      } = await ListDownloadMastersTraders(obj);
      if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
        const processRows = processData(data);
        setFoundResults(processRows.length > 0 ? true : false);
        setData(processRows);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };
  /**
   *  Organizar la información cargada para mostrarla en la tabla
   */
  const processData = (data = []) => {
    return data.map((item, idx) => {
      return {
        id: !item.idDownload ? "  " : item.idDownload,
        startDate: !item.starDate ? "  " : item.starDate,
        endDate: !item.endDate ? "  " : item.endDate,
        user: !item.userLoad ? "  " : item.userLoad,
        company: !item.company ? "" : item.company,
        file: !item.fileName
          ? "  "
          : {
              fileName: item.fileName,
              pathFileName: item.pathFileName,
            },
        state: !item.state ? " " : item.state,
      };
    });
  };

  /**
   *
   * Consulta los proveedores de un país específico
   *
   * @returns lista de proveedores por país consultado
   */
  const fetchSuppliers = async () => {
    try {
      const suppliersService = await GetSuppliersService({
        country: userInfo.company.country,
      });
      const responseCode = suppliersService.data.responseCode;
      const responseMessage = suppliersService.data.responseMessage;
      if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        let listCompanies = [];
        // Obtiene la lista de proveedores
        let aux = responseMessage
          .map((item) => item.companies)
          .map((item) => item.map((item) => listCompanies.push(item)));

        // Estrucutra la lista de proveedores y ordena alfabéticamente
        let suppliersList = listCompanies
          .map((item) => {
            return {
              companyName: item.companyName,
              eanCompany: item.eanCompany,
            };
          })
          .sort((a, b) => a.companyName.localeCompare(b.companyName));
        suppliersList =
          suppliersList.length > 1
            ? [
                { companyName: t("App.downloadSalesForce.selectAll") },
                ...suppliersList,
              ]
            : suppliersList;
        setTradersList(suppliersList);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log(
        "================= Error downloadMaster.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Encuentra el nombre del maestro elegido por medio del id almacenado en currentMaster
   * @returns nombre del maestro seleccionado
   */
  const getMasterName = () => {
    return currentMaster === "1"
      ? "penalties"
      : currentMaster === "0"
      ? "objectives"
      : "none";
  };

  /**
   * Valida el status del master
   * @param {} state estado del master
   * @returns true si el estado es ERROR SISTEMA, false si no lo es
   */
  const validateStates = (state) => {
    const value = state.toLowerCase();

    if (value === "error sistema") {
      setTextState(t("App.traders.modals.error"));
      return true;
    } else {
      return false;
    }
  };
  /**
   *
   * Función para descargar el archivo que fue cargado (subido)
   * Solo si el servicio responde correctamente se descarga el archivo
   * caso contrario no descargará nada
   *
   * @param {string} fileName: nombre del archivo
   * @param {string} pathFileName : ruta del archivo
   *
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    try {
      const obj = {
        pathFileName: pathFileName,
        fileName: fileName,
      };
      const fileService = await DownloadTradersOnDownload(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      } else {
        setOpenErrorModal(true);

        console.log("====================================");
      }
    } catch (error) {
      setOpenErrorModal(true);
    }
  };

  /**
   *
   * Función para generar la descargar de (los) archivo(s) encontrados según
   *  lo seleccionado en el autocomplete de compañías
   * @param {String} fileName nombre del archivo
   * @param {String} pathFileName ubicación del archivo
   */
  const handleDownload = async () => {
    try {
      const obj = {
        eanTrader: userInfo.company.eanCompany,
        country: userInfo.company.country,
        lstProviders: traderSelected.filter(
          (company) =>
            company.companyName !== t("App.downloadSalesForce.selectAll")
        ),
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        email: userInfo.email,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GenerateDownloadMastersTraders(
        obj,
        getMasterName() === "objectives" ? "objectives" : "penalties"
      );
      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        let type =
          getMasterName() === "objectives"
            ? t("App.traders.masters.objectives")
            : t("App.traders.masters.penalties");
        setTextModal(
          t("App.traders.modals.download", {
            type: type,
          }),
          responseCode,
          responseMessage
        );
        getDownloadMasters();
      } else if (status === CODES.COD_RESPONSE_HTTP_ERROR) {
        setOpenErrorModal(true);
      } else {
        setTextModal(
          t("App.traders.modals.moment"),
          responseCode,
          currentMaster === "1"
            ? responseMessage.replace(
                t("App.traders.masters.objectives"),
                t("App.traders.masters.penalties")
              )
            : responseMessage
        );
      }
    } catch (error) {
      setIsLoadingDownload(true);
      setLoading(true);
      setOpenErrorModal(true);
    }
  };

  /**
   *
   * Función que se acciona cuando se cambia el valor del autocomplete de compañías para cambiar el valor del state de traderSelected que es lo que se muestra seleccionado en el autcomplete
   * También funciona para seleccionar todos los valores cuando se selecciona la opción de 'Seleccionar todos'
   *
   * @param {Event} event
   * @param {Values} values comercios
   * @param {String} reason tipo de selección; selectOption, removeOption
   * @param {Object} detail opción elegida
   */
  const OnTraderChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const trader of values) {
      if (trader.companyName === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }
    let sizeTrader = tradersList.filter(
      (company) => company.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (company) => company.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeTrader && reason === "selectOption")
    ) {
      setTraderSelected(tradersList);
    } else if (values.length === tradersList.length - 1) {
      setTraderSelected(
        values.filter(
          (company) =>
            company.companyName !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setTraderSelected(values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.companyName === t("App.downloadSalesForce.selectAll")
      ) {
        setTraderSelected([]);
      }
    }
  };

  /**
   * Función para refrescar la tabla
   */
  const handleRefresh = async () => {
    setIsLoadingRefresh(true);
    setData([]);
    setLoading(true);
    getDownloadMasters();
    setIsLoadingRefresh(false);
    setLoading(false);
  };

  /**
   * Función param establecer el texto del modal de respuesta
   * @param {*} title // Título del modal
   * @param {*} status // Estado de la respuesta
   * @param {*} responseMessage // Mensaje de la respuesta
   * @param {*} responseCode  // Código de la respuesta
   */
  const setTextModal = (title, status, responseMessage, responseCode) => {
    setModalTitle(title);
    setModalContent({
      status,
      data: {
        responseMessage,
        responseCode,
      },
    });
    setIsOpenModalResponse(true);
  };

  return (
    <>
      <Grid item sx={{ width: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <p className="subheading__text-black">
              {t("App.mastersDownload.textInfo")}
            </p>
          </Grid>

          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={9} md={6} lg={5}>
                <Autocomplete
                  noOptionsText={t("App.validationMessages.noMatchFilter")}
                  className="autoComplete__downloadTrader"
                  isOptionEqualToValue={(option, value) =>
                    option?.eanCompany === value?.eanCompany
                  }
                  getOptionLabel={(option) => option.companyName}
                  id="checkboxes-tags-demo"
                  value={traderSelected}
                  options={tradersList || []}
                  multiple
                  disableCloseOnSelect
                  onChange={OnTraderChange}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.eanCompany}>
                      <Checkbox
                        icon={icon}
                        className="autoComplete__checkbox"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.companyName}
                    </li>
                  )}
                  renderTags={(value) => {
                    const numTags = value.length;
                    const limitTags = 2;

                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                            sx={{
                              width: "30%",
                            }}
                            key={index}
                            size="small"
                            label={option.companyName}
                          />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      label={t("App.mastersDownload.autoCompletePlaceHolder")}
                      sx={{
                        label: {
                          fontFamily: "Roboto !important",
                          fontSize: "14px !important",
                          translate: "0px -5px",
                          transformOrigin: "-45px -10px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={2} lg={2}>
                <Button
                  disabled={traderSelected.length > 0 ? false : true}
                  disableRipple
                  className={
                    traderSelected.length > 0
                      ? "btn__filled__blue download-btn"
                      : "btn__filled__disabled download-btn"
                  }
                  onClick={handleDownload}
                >
                  {!isLoadingDownload ? (
                    t("App.buttonLabels.download")
                  ) : (
                    <CircularProgress size={21} color="inherit" />
                  )}
                </Button>
              </Grid>

              <Grid item>
                {isLoadingDownload && (
                  <label className={"label-Primary__small"}>
                    {t("App.salesForce.paragraphUpload")}
                  </label>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={isDesktop ? "row" : "column-reverse"}
        className="col-start download-middle"
      >
        {isDesktop ? (
          <>
            <Grid
              xs={12}
              sm={4}
              md={4}
              item
              className="download-middle__container"
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item className="col-flex">
                  <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
                </Grid>

                <Grid
                  item
                  style={{
                    marginLeft: "2%",
                  }}
                >
                  <p className="display-small__primary-one">
                    {t("App.downloadSalesForce.subtitle")}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            {foundResults && (
              <Grid item xs={12} sm={8} md={8}>
                <Grid container justifyContent="flex-end">
                  <Grid
                    xs={12}
                    md={9}
                    sm={8}
                    item
                    className="download-middle__descrip"
                    textAlign={"right"}
                  >
                    {" "}
                    <p className="download-explanation-trader">
                      {t("App.mastersDownload.updateBox.description")}
                    </p>
                  </Grid>
                  <Grid item md={2} xs={12} sm={3} className="col-end">
                    <Grid container className="col-end">
                      {" "}
                      <Button
                        disableRipple
                        disabled={isLoadingRefresh ? true : false}
                        startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                        onClick={handleRefresh}
                        className="btn__outlined__blue download-middle__button"
                      >
                        {!isLoadingRefresh ? (
                          t("App.mastersDownload.updateBox.button")
                        ) : (
                          <CircularProgress size={21} color="inherit" />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid container justifyContent="flex-start">
            {foundResults && (
              <Grid item className="full-width">
                <Grid
                  container
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  columnSpacing={2}
                  rowSpacing={2}
                  textAlign={"center"}
                >
                  <Grid
                    lg={"auto"}
                    sm={"auto"}
                    xs={12}
                    item
                    className="download-explanation"
                  >
                    {t("App.mastersDownload.updateBox.description")}
                  </Grid>
                  <Grid lg={"auto"} sm={"auto"} xs={12} item>
                    <Button
                      disableRipple
                      disabled={isLoadingRefresh ? true : false}
                      startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                      className="btn__outlined__blue full-width"
                      onClick={handleRefresh}
                    >
                      {!isLoadingRefresh ? (
                        t("App.catalogue.update")
                      ) : (
                        <CircularProgress size={23} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={4} md={3} justifyContent="flex-start">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                className="table-title"
                textAlign={"left"}
              >
                <Grid item className="col-flex">
                  <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
                </Grid>

                <Grid item>
                  <p className="display-small__primary-one">
                    {t("App.downloadSalesForce.subtitle")}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {foundResults && (
        <>
          <Grid item className="u-marginB u-marginT" style={{ width: "100%" }}>
            <DataTable
              loading={loading}
              data={data}
              columns={columns}
              skeletonOptions={skeletonOptions}
              options={options}
            />
          </Grid>
        </>
      )}

      {!foundResults && <NoInfoComponent type="download" />}
      <ModalInfoTraders
        title={modalTitle}
        responseData={modalContent}
        open={isOpenModalResponse || false}
        onClose={() => {
          setIsOpenModalResponse(false);
        }}
        currentMaster={
          currentMaster === "1"
            ? t("App.traders.masters.penalties")
            : t("App.traders.masters.objectives")
        }
      />
      {/* Modal para fallo de servicios */}
      <ModalFailService open={openErrorModal} onClose={setOpenErrorModal} />
    </>
  );
};

import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import noMatchImage from "../../../assets/common/noMatchImage.svg";

const NoMatchImage = () => {
  const { t } = useTranslation();

  return (
    <Grid className="noMatchImage">
      <img
        src={noMatchImage}
        alt="Not Match Image"
        className="noMatchImage_img"
      />

      <h1 className="noMatchImage_title">
        {t("App.validationMessages.noMatchFilterImage.title")}
      </h1>

      <p className="noMatchImage_description">
        {t("App.validationMessages.noMatchFilterImage.description")}
      </p>

      <p className="noMatchImage_instruction">
        {t("App.validationMessages.noMatchFilterImage.instruction")}
      </p>
    </Grid>
  );
};

export default NoMatchImage;

import { Button, CircularProgress, Dialog, Grid, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { FilterReloadAllies } from "../../../components/common/Filters/filterReloadAllies";
import { SelectorAlliesReload } from "../../../components/common/Allies/reloadAlliesSelector";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { ReloadAlliesService } from "../../../services/user/deleteAndReload/deleteAndReload";
import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";

const ReloadAllies = () => {
  /**
   * use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Esquema para validar los campos del formulario recarga de aliados
   */
  const schema = yup.object().shape({
    ally: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[1-100]+$/, t("App.validationMessages.required")),

    country: yup
      .object()
      .required(t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required")),
    commerce: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string(),
        })
      )
      .min(1, t("App.validationMessages.required"))
      .required(t("App.validationMessages.required")),

    supplier: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string(),
        })
      )
      .min(1, t("App.validationMessages.required"))
      .required(t("App.validationMessages.required")),

    documentType: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string(),
        })
      )
      .min(1, t("App.validationMessages.required"))
      .required(t("App.validationMessages.required")),

    initialDate: yup
      .date()
      .required(t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required")),
    finalDate: yup
      .date()
      .required(t("App.validationMessages.required"))
      .typeError(t("App.validationMessages.required")),
  });

  /**
   * Use Form
   */

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      finalDate: null,
      initialDate: null,
      documentType: [],
      country: "",
      commerce: [],
      supplier: [],
      ally: 0,
    },
  });

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Allies form info
  const [allySelected, setAllySelected] = useState(0);
  const [allySelectedName, setAllySelectedName] = useState("");
  const [alliesReloadForm, setAlliesReloadForm] = useState(false);

  //Modals
  const [openModalDecision, setOpenModalDecision] = useState(false);

  const [openModalResponse, setOpenModalResponse] = useState(false);
  const [titleResponse, setTitleResponse] = useState("");
  const [contentResponse, setContentResponse] = useState("");

  //Loading
  const [openLoading, setOpenLoading] = useState(false);

  /**
   * Handles
   */

  /**
   * Redirige la la página de solicitudes de recarga aliados
   */
  const handleNavigateRequest = () => {
    navigate("request");
  };

  /**
   * Función de aceptar para modal de decisión de recarga
   * Cierra el modal de decisión de recarga y llama la función que crea la recarga de aliados
   */
  const handleAgree = async () => {
    setOpenModalDecision(false);
    setOpenLoading(true);
    await handleCreateReloadAlly(alliesReloadForm);
  };

  /**
   * Funcionar de rechazar para el modal de decisión de recarga
   * Cierra el modal de decisión de recarga
   */
  const handleDisagree = () => {
    setOpenModalDecision(false);
  };

  /**
   * Crea la recarga de un aliado basado en la información del formulario
   * @param {*} reloadForm información del formulario
   */
  const handleCreateReloadAlly = async (reloadForm) => {
    try {
      const requestData = setFormRequestStructure(reloadForm);
      const createReloadRequest = await ReloadAlliesService(requestData);

      setTitleResponse(setTitle(createReloadRequest.data.responseCode));
      setContentResponse(createReloadRequest);

      setOpenLoading(false);
      setOpenModalResponse(true);
    } catch (error) {
      console.log(
        "================== error reloadAllies.jsx =================="
      );
      console.log(error);
      console.log(
        "================== error reloadAllies.jsx =================="
      );
    }
  };

  /**
   * Retorna el título del modal de respuesta basado en el respondeCode
   * @param {*} responseCode código de respuesta
   * @returns título del modal de respuesta
   */
  const setTitle = (responseCode) => {
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return t("App.allies.case0");
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return t("App.adminContacts.modals.case1005");
      default:
        return t("App.adminContacts.modals.case1005");
    }
  };

  /**
   * Formatea la data que proviene de el formulario y construye el objeto para la petición
   * @param {} data información del formulario diligenciado
   * @returns objeto para la petición
   */
  const setFormRequestStructure = (data) => {
    const finalTraders = data.commerce
      .filter(
        (commerce) => commerce.label !== t("App.downloadSalesForce.selectAll")
      )
      .map((commerce) => ({
        eanCompany: commerce.value,
        nameCompany: commerce.label,
      }));

    const finalProviders = data.supplier
      .filter(
        (supplier) => supplier.label !== t("App.downloadSalesForce.selectAll")
      )
      .map((supplier) => ({
        eanCompany: supplier.value,
        nameCompany: supplier.label,
      }));

    const finalDocumentType = data.documentType
      ?.filter(
        (document) => document.label !== t("App.downloadSalesForce.selectAll")
      )
      .map((document) => document.value);
    return {
      country: data.country.country,
      idAllied: data.ally,
      alliedName: allySelectedName,
      lstProviders: finalProviders,
      lstTraders: finalTraders,
      lstDocumentsTypes: finalDocumentType,
      startDate: data.initialDate,
      rechargeType: data.rechargeType.value,
      endDate: data.finalDate,
      user: `${userInfo.firstName} ${userInfo.lastName}`,
    };
  };

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item sx={{ marginTop: "2%" }}>
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item>
            <p className="display-large__primary-one">
              {t("App.allies.title")}
            </p>
          </Grid>
          <Grid item>
            <Button
              className="btn__applyFilter"
              onClick={handleNavigateRequest}
            >
              {t("App.deleteAndReload.request")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SelectorAlliesReload
          setAllySelected={setAllySelected}
          setAllySelectedName={setAllySelectedName}
          allySelected={allySelected}
          control={control}
          setValue={setValue}
          errors={errors}
          reset={reset}
        />
      </Grid>
      {allySelected !== 0 && allySelectedName !== "" && (
        <Grid item>
          {" "}
          <Grid container className="companies_info_container" rowSpacing={2}>
            <Grid item>
              <Grid container direction="row" columnSpacing={2}>
                <Grid item>
                  {" "}
                  <RefreshOutlinedIcon className="accordion__upload__downloadIcon" />
                </Grid>
                <Grid item className="display-small__primary-one col-flex">
                  {t("App.deleteAndReload.reload")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="full-width">
              <FilterReloadAllies
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                setValue={setValue}
                reset={reset}
                watch={watch}
                setOpenModalDecision={setOpenModalDecision}
                setAlliesReloadForm={setAlliesReloadForm}
                allySelected={allySelected}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <ModalDecision
        title={t("App.allies.confirmationTitle")}
        message={t("App.allies.confirmationText", {
          allyName: allySelectedName,
        })}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setOpenModalDecision}
        isOpen={openModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
      <ModalInfo
        title={titleResponse}
        responseData={contentResponse}
        open={openModalResponse}
        onClose={() => {
          setOpenModalResponse(false);
        }}
      />
      <Modal open={openLoading} className="col-flex">
        <CircularProgress size={80} sx={{ color: "#4DCAFA" }} />
      </Modal>
    </Grid>
  );
};

export default ReloadAllies;

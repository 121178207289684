import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const FieldsVisualization = (props) => {
  const { t } = useTranslation();

  const checkBox = document.querySelectorAll(".MuiFormControlLabel-label");

  checkBox.forEach((element) => element.classList.add("subheading__text-grey"));

  const checkList = [
    {
      label: t("App.dynamicPeriodicReport.fields.check.company"),
      register: "checkcompany",
      default: true,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.merchant"),
      register: "checkmerchant",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.pointOfSale"),
      register: "checkpointOfSale",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.channel"),
      register: "checkchannel",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.format"),
      register: "checkformat",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.department"),
      register: "checkdepartment",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.city"),
      register: "checkcity",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.hierarchy"),
      register: "checkhierarchy",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.seller"),
      register: "checkseller",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.tree"),
      register: "checktree",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.product"),
      register: "checkproduct",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.brand"),
      register: "checkbrand",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.colour"),
      register: "checkcolour",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.size"),
      register: "checksize",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.dispatch"),
      register: "checkdispatch",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.standard"),
      register: "checkstandard",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.weight"),
      register: "checkweight",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.volume"),
      register: "checkvolume",
      default: false,
    },
  ];

  const list = checkList;

  return (
    <Accordion defaultExpanded={true}>
      <Grid item>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <VisibilityOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />
          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.specialFilesClient.create.visualizeTitle")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails>
        <Grid container direction="row">
          <Grid item style={{ marginBottom: "2%" }}>
            <Grid container direction="column">
              <Grid item className="heading__text-default bold">
                {t("App.dynamicPeriodicReport.fields.select").replace(":", "")}
              </Grid>

              {props.showValidationChecks && (
                <Grid
                  item
                  style={{ marginTop: "3%" }}
                  className="caption__error-color"
                >
                  {t("App.specialFilesClient.oneSelected")}
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {list.map((item, idx) => {
                return (
                  <Grid item xs={12} sm={3} key={idx}>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={item.default}
                          name={item.register}
                          control={props.control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label={item.label}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid
            item
            style={{
              backgroundColor: "#553AB60D",
              borderRadius: "8px",
              padding: "1%",
              marginTop: "1.5%",
            }}
          >
            <Grid container direction="row">
              <Grid item sm={1} xs={2} md={0.5}>
                <IconButton disabled>
                  <InfoOutlinedIcon
                    sx={{ fontSize: "22px", color: "#543AB4" }}
                  />
                </IconButton>
              </Grid>

              <Grid sm={11} xs={10} md={11} item className="col-flex">
                <Grid container direction="column">
                  <p>
                    <span className="label__primary-one">
                      {t("App.specialFilesClient.create.tooltipGenerateThree")}
                    </span>

                    <span className="label__primary-one">
                      {". "}
                      {t("App.specialFilesClient.create.tooltipGenerateFour")}
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

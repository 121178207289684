import { Grid, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const FilterUser = ({ control, errors, t }) => {
  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={"100%"}
        >
          <Grid item>
            <label className="form__label label__text-grey">
              {t("App.userDetail.name")}
            </label>
          </Grid>

          <Grid item width={"100%"}>
            <Controller
              control={control}
              name={"name"}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  className={
                    errors.name
                      ? "select-contact__filters__errors__xs"
                      : "select-contact__filters"
                  }
                  placeholder={t("App.userDetail.name")}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          {errors && errors.name && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.name.message}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={"100%"}
        >
          <Grid item>
            <label className="form__label label__text-grey">
              {t("App.userDetail.lastName")}
            </label>
          </Grid>

          <Grid item width={"100%"}>
            <Controller
              control={control}
              name={"lastName"}
              render={({ field: { onChange, value } }) => (
                <TextField
                  defaultValue={null}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  className={
                    errors.lastName
                      ? "select-contact__filters__errors__xs"
                      : "select-contact__filters"
                  }
                  placeholder={t("App.userDetail.lastName")}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          {errors && errors.lastName && (
            <Grid
              item
              sx={{
                margin: "1px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.lastName.message}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FilterUser;

import { TokenQlikSense } from "../services/auth/authServices";

/**
 *
 * @param {String} name
 * @param {String} email
 * @returns ticket para el doble logueo de qlik sense
 *
 * @author Isaac Navarro
 */
async function handleTicket(name, email) {
  const obj = {
    name,
    email,
  };

  try {
    const res = await TokenQlikSense(obj);
    const ticket = res.data.responseMessage;

    return ticket;
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {String} jwt
 * @param {String} url
 * @param {String} integrationID
 * @param {String} urlQlik
 *
 * @author Isaac Navarro
 *
 * Función para ejecutar el fetch para el doble logueo en qlik sense
 */
function fetchQlikSense(jwt, url, integrationID, urlQlik) {
  const myHeaders = new Headers();

  myHeaders.append("qlik-web-integration-id", integrationID);
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Bearer " + jwt);

  let raw = "";

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    credentials: "include",
    mode: "cors",
    rejectunAuthorized: false,
  };

  fetch(
    `https://${url}/login/jwt-session?qlik-web-integration-id=${integrationID}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      // console.log(result);

      window.open(urlQlik, "_blank");
    })
    .catch((error) => console.log("error", error));
}

/**
 *
 * @param {String} name
 * @param {String} email
 * @param {String} urlQlik
 *
 * @author Isaac Navarro
 *
 * Función para abrir el qlik sense e implementar el doble logueo
 */
export async function openQlikSense(name, email, urlQlik) {
  try {
    const jwt = await handleTicket(name, email);
    const url = "prescriptivalatam.us.qlikcloud.com";
    const integrationID = "Ro_TqKTDIdq0qA2i0b4VRV4iqHRd62hZ";

    // console.log("JWT: " + jwt);

    fetchQlikSense(jwt, url, integrationID, urlQlik);
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {HTMLElement} element
 * @returns Nombre del producto seleccionado en el crear producto del admin producto
 *
 * @author Isaac Navarro
 */
export const getElementName = (element) => {
  if (element !== undefined) {
    const options = element;
    // console.log(options);

    const productName = options.getAttribute("name");
    return productName;
  }
};

/**
 *
 * @param {Array} data
 * @returns objecto que contenga el idOptionType igual a 1
 *
 * @author Isaac Navarro
 */
export const getCommerce = (data) => {
  if (data.length > 0) {
    return data.find((com) => com.idOptionType === 1);
  }
};

/**
 *
 * Función para devolver la lista de comercios o vendedores
 *
 *  @author Isaac Navarro
 *
 * @param {Array} data
 * @param {Array} value
 * @returns obtener la lista de los comercios o vendedores asociados a un producto
 */
export const getCommerces = (data, value) => {
  if (Array.isArray(data) && Array.isArray(value)) {
    let element1 = null;
    let element2 = null;

    let commerces = [];
    for (let i = 0; i < data.length; i++) {
      element1 = data[i].value;

      for (let j = 0; j < value.length; j++) {
        element2 = value[j];

        if (element1 === element2) {
          commerces.push(data[i]);
        }
      }
    }

    return commerces;
  }
};

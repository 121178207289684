import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { useState } from "react";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import DownloadBox from "../../../components/common/Boxes/downloadBox";
import ButtonRefresh from "../../../components/common/buttonRefresh";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import { CODES } from "../../../consts/codes";
import FileState from "../../../components/common/fileState";
import FilterBusinessGroupWB from "../../../components/common/Filters/businessGroup/filterWithoutButtons";
import moment from "moment";
import LoopIcon from "@mui/icons-material/Loop";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../userSlice";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import MUIDataTable from "mui-datatables";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import {
  DownloadRateOnDownload,
  GenerateDownloadRateRequest,
} from "../../../services/user/rateService";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import {
  fetchRateDownload,
  getRateDownload,
  getRateDownloadStatus,
  resetRateDownloadState,
} from "../../../parts/rate/rateSlice";

const DownloadRateMaster = () => {
  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "initialUploadDate",
      label: t("App.rateMaster.downloadRate.downloadTable.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "finalUploadDate",
      label: t("App.rateMaster.downloadRate.downloadTable.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.rateMaster.downloadRate.downloadTable.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "company",
      label: t("App.rateMaster.table.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.rateMaster.downloadRate.downloadTable.downloadFile"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: value.state === "FINALIZADO" ? "pointer" : "default",
                }}
                className={
                  value.state === "FINALIZADO"
                    ? "default-text__linkBlue__sm"
                    : "default-text__linkBlue__sm__grey"
                }
                onClick={(event) => {
                  if (value.state === "FINALIZADO") {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.rateMaster.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla en tamaño de celular
   */
  const columnsPhone = [
    {
      name: "file",
      label: t("App.rateMaster.downloadRate.downloadTable.downloadFile"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: value.state === "Finalizado" ? "pointer" : "default",
                }}
                className={
                  value.state === "Finalizado"
                    ? "default-text__linkBlue__sm"
                    : "default-text__linkBlue__sm__grey"
                }
                onClick={(event) => {
                  if (value.state === "Finalizado") {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.rateMaster.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            className={
              value === "Finalizado"
                ? "state-colors__finished"
                : value === "Pendiente"
                ? "state-colors__pending"
                : value === "En Proceso"
                ? "state-colors__process"
                : "state-colors__error"
            }
            value={value}
            control={<p>{value}</p>}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <NoMatchImage />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <Skeleton />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * Use state
   */

  //Campo de tabla
  const [data, setData] = useState([]);
  const [noDownloadRate, setNoDownloadRate] = useState(false);

  //Grupo empresarial
  const [showDownload, setShowDownload] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  //Modal respuesta de descarga
  const [downloadTitle, setDownloadTitle] = useState("");
  const [downloadResponseData, setDownloadResponseData] = useState({});
  const [isOpenDownloadedFile, setIsOpenDownloadedFile] = useState(false);

  //Modal respuesta success de descarga tarifa
  const [message, setMessage] = useState({});
  const [titleNoFile, setTitleNoFile] = useState("");
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  const [RespCode, setRespCode] = useState(null);
  const [title, setTitle] = useState(null);
  //Error
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openNoFile, setOpenNoFile] = useState(false);

  //Carga visual
  const [isLoadingDownloadRequest, setIsLoadingDownloadRequest] =
    useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  //Usuario
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  //Archivos de descarga
  const rateDownload = useSelector(getRateDownload);
  const rateDownloadStatus = useSelector(getRateDownloadStatus);

  //Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * useEffect para manejar la actualización de la información de la tabla
   */
  useEffect(() => {
    try {
      if (userInfo) {
        handleUpdateTable();
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }, [userStatus, dispatch, rateDownloadStatus]);

  /**
   * Use Effect para mostrar por default el componente de carga si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [isBusinessGroup]);

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * Función para estructurar la información a mostrar en la tabla
   *
   * @param {Object} rate celda de la tarifa
   * @returns la información estructurada para mostrar en la tabla
   */
  const cellStructure = (rate) => {
    return {
      initialUploadDate: !rate.starDate
        ? " "
        : moment(rate.starDate).format("YYYY-MM-DD hh:mm:ss"),
      finalUploadDate: !rate.endDate
        ? "  "
        : moment(rate.endDate).format("YYYY-MM-DD hh:mm:ss"),
      user: !rate.userLoad ? "" : rate.userLoad,
      company: !rate.company ? "" : rate.company,
      file: !rate.fileName
        ? ""
        : {
            fileName: rate.fileName,
            pathFileName: rate.pathFileName,
            state: !rate.state ? "" : rate.state,
          },
      state: !rate.state ? "" : rate.state,
    };
  };

  /**
   *
   * Función para descargar el archivo de tarifa que fue cargado (subido)
   * Solo si el servicio responde correctamente se descarga el archivo
   * caso contrario no descargará nada
   *
   * @param {string} fileName: nombre del archivo
   * @param {string} pathFileName : ruta del archivo
   *
   */
  const handleDownloadFile = async (fileName, path) => {
    try {
      const obj = {
        pathFileName: path,
        fileName: fileName,
      };
      const fileService = await DownloadRateOnDownload(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      } else {
        setTitle(t("App.validationMessages.warning"));
        setRespCode(CODES.COD_RESPONSE_HTTP_BAD_REQUEST);
        setMessage(fileService.responseMessage);
        openSuccessLoadModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para actualizar la información de la tabla
   */
  const handleUpdateTable = async () => {
    try {
      switch (rateDownloadStatus) {
        case "fetch":
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 20,
          };
          dispatch(fetchRateDownload(obj));

          break;
        case "succeeded":
          let newData = [];
          const records = rateDownload;

          if (records.length > 0) {
            const finalData = records.map((catalog) => {
              return cellStructure(catalog);
            });
            newData = finalData;
          }

          setNoDownloadRate(newData.length > 0 ? false : true);
          setData(newData);
          setLoading(false);
          setIsLoadingRefresh(false);
          break;
        default:
          break;
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para refrescar la información de la tabla
   */
  const handleRefresh = () => {
    try {
      setIsLoadingRefresh(true);
      setLoading(true);
      setData([]);
      dispatch(resetRateDownloadState());
      handleUpdateTable()
    } catch (error) {
      setOpenErrorModal(true);
    }
  };

  /**
   *
   * Función que muestra modal de información basado en el
   * responsecode
   *
   * @param {Object} response código de respuesta de la petición
   */
  const handleDisplayModal = (response) => {
    const { responseCode } = response.data;
    let title = "";
    // Settear el título basado en el responseCode

    switch (responseCode) {
      case CODES.COD_RESPONSE_HTTP_ERROR:
        setTitle(t("App.rateMaster.downloadRate.downloadModal.errorDownload"));
        setOpenSuccessLoadModal(true);
        break;
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        setTitle(t("App.rateMaster.downloadRate.downloadModal.initDownload"));
        setOpenSuccessLoadModal(true);
        break;
      case CODES.COD_RESPONSE_ERROR_CREATE:
        setOpenErrorModal(true);
      default:
        break;
    }

    // settear la información y mostrar el modal
    setDownloadTitle(title);
    setDownloadResponseData(response);
    setIsOpenDownloadedFile(true);
  };

  /**
   * Función para generar una petición de la descarga de la tarifa
   */
  const handleDownloadRate = async () => {
    try {
      setIsLoadingDownloadRequest(true);
      const cleanSelectAll = selectedCompanies.filter(
        (company) => company.label !== t("App.downloadSalesForce.selectAll")
      );

      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        provider: userInfo.company.companyName,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        emailUser: userInfo.email,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        lstCompanies: cleanSelectAll,
      };

      const requestDownload = await GenerateDownloadRateRequest(obj);
      if (
        requestDownload.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestDownload.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setMessage(requestDownload.data.responseMessage);
        setTitleNoFile(t("App.rateMaster.downloadRate.downloadModal.title"))
        dispatch(resetRateDownloadState());
      }
      if (requestDownload.data.responseCode === CODES.COD_RESPONSE_ERROR_LIST) {
        setMessage(requestDownload.data.responseMessage);
        setTitleNoFile(t("App.rateMaster.downloadRate.downloadModal.title"))
        setOpenNoFile(true);
      }
      if (requestDownload.data.responseCode === CODES.COD_RESPONSE_ERROR_UNAUTHORIZED) {
        setMessage(requestDownload.data.responseMessage);
        setTitleNoFile(t("App.rateMaster.downloadRate.downloadModal.errorDownload"))
        setOpenNoFile(true);
      }
      handleDisplayModal(requestDownload);
      setRespCode(CODES.COD_RESPONSE_HTTP_OK);
      setIsLoadingDownloadRequest(false);
    } catch (error) {
      setIsLoadingRefresh(true);
      setLoading(true);
      setData([]);
      dispatch(resetRateDownloadState);
      setOpenErrorModal(true);
      setIsLoadingDownloadRequest(false);
    }
  };

  return (
    <>
      {isBusinessGroup && (
        <FilterBusinessGroupWB
          title={t("App.manageBusinessGroup.filter.title3")}
          setShowDownload={setShowDownload}
          setSelectedCompanies={setSelectedCompanies}
        />
      )}

      <br />
      <br />

      <Grid container direction="column" rowSpacing={2}>
        {showDownload && (
          <Grid item>
            <DownloadBox
              isLoading={isLoadingDownloadRequest}
              handleDownload={handleDownloadRate}
              description={t(
                "App.rateMaster.downloadRate.downloadBox.boxDescription"
              )}
              buttonText={t(
                "App.rateMaster.downloadRate.downloadBox.boxButton"
              )}
            />
          </Grid>
        )}

        <Grid
          container
          direction={isDesktop ? "row" : "column-reverse"}
          className="col-start download-middle"
        >
          {isDesktop ? (
            <>
              <Grid xs={12} md={12} item className="download-middle__container">
                <Grid item xs={12} md={12}>
                  <Grid container justifyContent="flex-end">
                    {!noDownloadRate && (
                      <Grid
                        xs={12}
                        md={9}
                        item
                        className="download-middle__descrip"
                        textAlign={"right"}
                      >
                        {" "}
                      </Grid>
                    )}
                    {!noDownloadRate && (
                      <Grid
                        container
                        direction="row"
                        className="u-marginT template-container__general"
                        justifyContent={"flex-end"}
                        textAlign={"end"}
                        style={{
                          paddingRight: "1%",
                        }}
                      >
                        <ButtonRefresh
                          handleRefresh={handleRefresh}
                          helpText={t(
                            "App.rateMaster.downloadRate.updateBox.description"
                          )}
                          isLoadingRefresh={isLoadingRefresh}
                          buttonText={t("App.rateMaster.update")}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                justifyContent="flex-start"
              ></Grid>{" "}
              {!noDownloadRate && (
                <Grid
                  container
                  direction="row"
                  className="u-marginT template-container__general"
                  justifyContent={"flex-end"}
                  textAlign={"end"}
                  style={{
                    paddingRight: "1%",
                  }}
                >
                  <ButtonRefresh
                    handleRefresh={handleRefresh}
                    helpText={t("App.rateMaster.refresh")}
                    isLoadingRefresh={isLoadingRefresh}
                    buttonText={t("App.rateMaster.update")}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          className="table-title"
          textAlign={"left"}
        >
          <Grid item className="col-flex">
            <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
          </Grid>

          <Grid item>
            <p className="display-small__primary-one">
              {t("App.downloadSalesForce.subtitle")}
            </p>
          </Grid>
        </Grid>

        {!noDownloadRate ? (
          <Grid item className="download-table">
            <>
              {loading ? (
                <MUIDataTable
                  style={{ width: "100% !important" }}
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                  className="dataTable__salesForce"
                />
              ) : (
                <MUIDataTable
                  style={{ width: "100% !important" }}
                  data={data}
                  columns={columns}
                  options={options}
                  className="dataTable__salesForce"
                />
              )}
            </>
          </Grid>
        ) : (
          <Grid container className="col-flex">
            <NoInfoComponent type="download" />
          </Grid>
        )}

        <ModalInfo
          title={title}
          responseData={{
            status: RespCode,
            data: {
              responseCode: RespCode,
              responseMessage: message,
            },
          }}
          open={openSuccessLoadModal}
          onClose={() => {
            setOpenSuccessLoadModal(false);
          }}
        />

        {/* Modal para fallo de servicios */}
        <ModalInfo
          title={t("App.validationMessages.error")}
          responseData={{
            status: 0,
            data: {
              responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
              responseMessage: t("App.validationMessages.systemError2"),
            },
          }}
          open={openErrorModal}
          onClose={() => {
            setOpenErrorModal(false);
          }}
        />

        {/* Modal para el fallo por no tener información */}
        <ModalInfo
          title={titleNoFile}
          responseData={{
            status: CODES.COD_RESPONSE_ERROR_LIST,
            data: {
              responseCode: CODES.COD_RESPONSE_ERROR_LIST,
              responseMessage: message,
            },
          }}
          open={openNoFile}
          onClose={() => {
            setOpenNoFile(false);
          }}
        />
      </Grid>
    </>
  );
};

export default DownloadRateMaster;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";
import {
  GetMasters,
  ListDownloadMaster,
} from "../../services/user/masters/mastersService";

export const mastersSlice = createSlice({
  name: "masters",
  initialState: {
    currentMaster: "2",
    masters: [],
    mastersStatus: "fetch",
    mastersDownload: [],
    mastersDownloadStatus: "fetch",
  },
  reducers: {
    setCurrentMaster(state, action) {
      const { current } = action.payload;
      state.currentMaster = current;
    },

    resetMastersStatus(state, action) {
      state.mastersStatus = "fetch";
    },

    resetMastersDownloadStatus(state, action) {
      state.mastersDownloadStatus = "fetch";
    },

    getMaster(state, action) {
      return state.currentMaster;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMasters.pending, (state, action) => {
        state.mastersStatus = "loading";
      })
      .addCase(fetchMasters.fulfilled, (state, action) => {
        state.mastersStatus = "succeeded";
        state.masters = action.payload;
      })
      .addCase(fetchMasters.rejected, (state, action) => {
        state.mastersStatus = "failed";
        state.masters = [];
      })
      .addCase(fetchMastersDownload.pending, (state, action) => {
        state.mastersDownloadStatus = "loading";
      })
      .addCase(fetchMastersDownload.fulfilled, (state, action) => {
        state.mastersDownloadStatus = "succeeded";
        state.mastersDownload = action.payload;
      })
      .addCase(fetchMastersDownload.rejected, (state, action) => {
        state.mastersDownloadStatus = "failed";
      });
  },
});

export const getCurrentMaster = (state) => state.masters.currentMaster;

export const getMasters = (state) => state.masters.masters;

export const getMastersStatus = (state) => state.masters.mastersStatus;

export const getMastersDownload = (state) => state.masters.mastersDownload;

export const getMastersDownloadStatus = (state) => state.masters.mastersDownloadStatus;

export const {
  setCurrentMaster,
  resetMastersStatus,
  resetMastersDownloadStatus,
  getMaster,
} = mastersSlice.actions;

export const fetchMasters = createAsyncThunk(
  "masters/fetchAllMasters",
  async ({ data, master }) => {
    try {
      let requestMasters = undefined;

      //TODO: Ajustar el objeto GetMasters por integracion de grupo empresarial
      switch (master) {
        case "0":
          data["idFileType"] = 14;
          requestMasters = await GetMasters({
            ...data,
          });
          break;
        case "1":
          data["idFileType"] = 13;
          requestMasters = await GetMasters({
            ...data,
          });
          break;
      }

      if (requestMasters.status === CODES.COD_RESPONSE_SUCCESS) {
        if (requestMasters.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
          return requestMasters.data.data;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchMastersDownload = createAsyncThunk(
  "masters/fetchAllMastersDownload",
  async ({ data, master }) => {
    let requestMastersDownload = undefined;

    switch (master) {
      case "0":
        data["idFileType"] = 16;
        requestMastersDownload = await ListDownloadMaster({
          ...data,
        });
        break;
      case "1":
        data["idFileType"] = 15;
        requestMastersDownload = await ListDownloadMaster({
          ...data,
        });
        break;
    }
    if (requestMastersDownload.status === CODES.COD_RESPONSE_SUCCESS) {
      if (
        requestMastersDownload.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        return requestMastersDownload.data.data;
      }
    }
  }
);

export default mastersSlice.reducer;

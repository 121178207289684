import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CODES } from "../../../consts/codes";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { DownloadRateFile } from "../../../services/user/rateService";
import { env } from "../../../env";

export const UserManualAccordion = ({
  isDesktop,
  t,
  setIsOpenModalInfo,
  setModalInfoContent,
}) => {
  /**
   * Handles
   */

  /**
   * Descarga el manual del usuario
   */
  const handleDownloadGuide = async () => {
    try {
      let environment = "";
      if (env.REACT_APP_API_URL.toString().includes("dev")) {
        environment = "dev";
      } else if (env.REACT_APP_API_URL.toString().includes("test")) {
        environment = "test";
      } else if (env.REACT_APP_API_URL.toString().includes("stage")) {
        environment = "stage";
      } else {
        environment = "prod";
      }

      const obj = {
        pathFileName: `analitica-${environment}-prescriptiva-instructives-bucket/guides/user`,
        fileName: "Manual de Usuario Prescriptiva.pdf",
      };

      const {
        status,
        data: { data, responseCode, responseMessage },
      } = await DownloadRateFile(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        fileDownload(decode(data.fileContent), data.fileName);
      } else {
        setModalInfoContent({ data: { responseMessage, responseCode } });
        setIsOpenModalInfo(true);
      }
    } catch (error) {
      console.log(
        "==============Error Download Guide User Manual ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <Accordion className="accordion__help">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon fontSize="large" className="preview-modal__arrows" />
        }
      >
        <Grid container columnSpacing={1} className="col-start">
          <Grid item>
            <IconButton disableRipple className="accordion__help__icon-title">
              <img
                src={"/assets/helpResources/frequentQuestions.svg"}
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
          </Grid>
          <Grid item className="display-small__primary-one bold">
            {t("App.helpCenter.userManualTitle")}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="accordion__help__details full-width">
        <Grid container direction="row" rowSpacing={2}>
          <Grid
            item
            xl={10}
            lg={10}
            className="heading__text-default"
            sx={{ fontWeight: "400 !important" }}
            padding={1}
          >
            {t("App.helpCenter.userManualDesc")}
          </Grid>
          <Grid item className="col-flex" xl={2} lg={2} sm={12} xs={12}>
            <Button
              disableRipple
              endIcon={<FileDownloadOutlinedIcon />}
              className={
                isDesktop ? "btn__filled__blue" : "btn__filled__blue full-width"
              }
              onClick={handleDownloadGuide}
            >
              {t("App.helpCenter.buttonManual")}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

/* eslint-disable default-case */
import { React, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ModalChooseGenerationDay from "../../../components/common/DynamicReport/modalChooseGenerationDay";
import TextField from "@mui/material/TextField";
import { ToolTipDayOfGeneration } from "../../../components/common/DynamicReport/toolTipDayOfGeneration";
import { TooltipAmountOfPeriods } from "../../../components/common/DynamicReport/tooltipAmountOfPeriods";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DynamicCheckBoxList } from "../../../components/common/DynamicReport/dynamicCheckBoxList";
import { ScopeList } from "../../../components/common/DynamicReport/scopeList";
import { ModalChangeType } from "../../../components/common/DynamicReport/modalChangeType";
import { ModalClearInfo } from "../../../components/common/DynamicReport/modalClearInfo";
import { ModalExceedEmails } from "../../../components/common/DynamicReport/modalExceedEmails";
import { ModalInvalidMail } from "../../../components/common/DynamicReport/modalInvalidMail";
import { ModalExceedPeriod } from "../../../components/common/DynamicReport/modalExceedPeriod";
import { ToolTipPeriodSummary } from "../../../components/common/DynamicReport/toolTipPeriodSummary";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import moment from "moment";
import {
  setSelectedTrader,
  getTradersSelected,
  getEanPointSaleSelected,
  setSelectedEanPointSale,
  getPointSalesSelected,
  setSelectedPointSale,
  getFormatsSelected,
  setSelectedFormats,
  getDepartmentsSelected,
  setSelectedDepartments,
  getChannelsSelected,
  setSelectedChannel,
  getCitiesSelected,
  setSelectedCities,
  getCategoriesSelected,
  setSelectedCategories,
  getProductsSelected,
  setSelectedProducts,
  getSalesForceSelected,
  setSelectedSalesForceLevelOne,
  getBrandsSelected,
  setSelectedBrands,
  getColorsSelected,
  setSelectedColors,
  getSizesSelected,
  setSelectedSizes,
  getSalesManSelected,
  setSelectedSalesMan,
} from "../../../parts/dynamicReport/dynamicReportSlice";
import {
  EditDynamicReport,
  GetDynamicReportByEan,
} from "../../../services/user/dynamicReport/dynamicReportServices";
import { CODES } from "../../../consts/codes";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingPage } from "../../common/loading";
import { FormHelperText, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ModalContactsReport } from "../../../components/common/Modals/modalContactsReport";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { TypeSelectorReportEdit } from "../../../components/common/DynamicReport/typeSelectorEdit";

export const EditDynamicPeriodicReport = () => {
  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  //Selectores Redux

  const userInfo = useSelector(getUser);

  const tradersSelected = useSelector(getTradersSelected);

  const eanPointSaleSelected = useSelector(getEanPointSaleSelected);

  const pointSalesSelected = useSelector(getPointSalesSelected);

  const formatsSelected = useSelector(getFormatsSelected);

  const departmentsSelected = useSelector(getDepartmentsSelected);

  const citiesSelected = useSelector(getCitiesSelected);

  const channelsSelected = useSelector(getChannelsSelected);

  const categoriesSelected = useSelector(getCategoriesSelected);

  const productsSelected = useSelector(getProductsSelected);

  const salesForceSelected = useSelector(getSalesForceSelected);

  const brandsSelected = useSelector(getBrandsSelected);

  const colorsSelected = useSelector(getColorsSelected);

  const sizesSelected = useSelector(getSizesSelected);

  const salesManSelected = useSelector(getSalesManSelected);

  //Estados

  const { pk, sk } = useParams();

  const [startForm, setStartForm] = useState(false);

  const [reportType, setReportType] = useState(0);

  const [fileName, setFileName] = useState("");

  const [generationdayDisabled, setGenerationDisabled] = useState(true);

  const [frequency, setFrequency] = useState(0);

  const [status, setStatus] = useState("1");

  const [period, setPeriod] = useState(null);

  const [groupingLimit, setGroupingLimit] = useState(null);

  const [grouping, setGrouping] = useState("0");

  const [limitReport, setLimitReport] = useState(false);

  const [periodNumber, setPeriodNumber] = useState(1);

  const [openModalChangeType, setOpenModalChangeType] = useState(false);

  const [openModalExceedEmails, setOpenModalExceedLimits] = useState(false);

  const [openModalInvalidMail, setOpenModalInvalidMail] = useState(false);

  const [endDate, setEndDate] = useState("");

  const [startDate, setStartDate] = useState("");

  const [responseModalInfo, setResponseModalInfo] = useState({});

  const [openModalInfo, setModalInfo] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [originalReport, setOriginalReport] = useState(null);

  const [fetchingInfo, setFetchingInfo] = useState(true);

  const [openModalExceedPeriodLimit, setOpenModalExceedPeriodLimit] =
    useState(false);

  const [desiredType, setDesiredType] = useState(null);

  const [openModalContacts, setOpenModalContacts] = useState(false);
  const [openErrorContacts, setOpenErrorContacts] = useState(false);

  const [showValidationChecks, setShowValidationChecks] = useState(false);

  /**
   * Use Navigate
   */
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [generationdayDayList, setGenerationDayList] = useState({
    label: t("App.dynamicPeriodicReport.when.noDay"),
    value: "",
  });

  //Grupo empresarial
  const [isBusinessGroupReport, setIsBusinessGroupReport] = useState(false);

  /**
   * Schema para la validación de los campos
   */
  const schema = yup.object().shape({
    type: yup.string().required(t("App.validationMessages.required")),
    frecuency: yup.string().required(t("App.validationMessages.required")),
    status: yup.string().required(t("App.validationMessages.required")),
    grouping: yup.string().required(t("App.validationMessages.required")),
    numberOfPeriods: yup
      .number()
      .positive(t("App.validationMessages.greaterZero"))
      .required(t("App.validationMessages.required")),
    fileName: yup
      .string()
      .max(50, t("App.validationMessages.maxCharacters", { number: 50 }))
      .required(t("App.validationMessages.required")),

    accounts: yup.string().required(t("App.validationMessages.required")),
  });

  /**
   *
   * Función que acciona el modal de cambio al usuario en caso de hacer cambio del tipo de reporte dinámico
   *
   * @param {*} event el evento del select de tipo de reporte
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;
    if (desiredType === null) {
      setReportType(id);
    }
    if (id !== 0) {
      setStartForm(true);

      setDesiredType(id);
    }
    if (desiredType !== null) {
      setOpenModalChangeType(true);
    }
  };

  /**
   *
   * Función para colocar los valores correspondientes a los respectivos campos cuando hay un cambio en el campo de frequency
   *
   * @param {*} event evento del campo de frequency
   */
  const handleFrecuencyChange = (event) => {
    const value = event.target.value;

    setFrequency(value);
    setPeriod(value);
    setGrouping(value);
    setValue("frecuency", value);
    setValue("grouping", value);

    if (value === 0 || value === "0") {
      setGenerationDisabled(true);
      setGenerationDayList({
        label: t("App.dynamicPeriodicReport.when.noDay"),
        value: "",
      });
    } else {
      setGenerationDisabled(false);
      setGenerationDayList({
        label: "",
        value: "",
      });
    }
  };

  /**
   *
   * @param {*} event
   */
  const handleStatusChange = (event) => {
    const value = event.target.value;

    setStatus(value);
  };

  /**
   *
   * Función que coloca los valores de período y grupo a sus respectivos state cada vez que hay un cambio en el campo de grouping
   *
   * @param {*} event evento del campo de grouping
   */
  const handlePeriodChange = (event) => {
    const value = event.target.value;
    setPeriod(value);
    setGrouping(value);
    setValue("grouping", value);
  };

  /**
   *
   * Función que aplica una expresión regular en el nombre del archivo para verificar que no tenga carácteres especiales
   * si no hay carácteres especiales se procede a colocar el valor al state de fileName
   *
   * @param {*} e evento del campo de fileName
   */
  const onChangeFileName = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[%<>\\$'";=]/)) {
      setFileName(newValue);
    }
  };

  /**
   * Función para retroceder a la página anterior
   */
  const handleBackReports = () => {
    navigate(-1);
  };

  /**
   * Función que cambia el valor del state de periodNumber cada vez que hay un cambio en el campo de numberOfPeriods
   *
   * @param {*} e evento del campo de numberOfPeriods
   */
  const onChangePeriodNumber = (e) => {
    const newValue = e.target.value;
    setPeriodNumber(newValue);
  };

  /**
   *
   * Función que elige la función correspondiente dependiendo del tipo de reporte
   *
   * @param {*} data data que se envía desde el formulario
   * @returns la función correspondiente al tipo de reporte
   */
  const exceedsPeriodLimit = (data) => {
    const groupingType = data.grouping;
    const periodNumbers = data.numberOfPeriods;

    if (reportType === "1") {
      return exceedsPeriodLimitInventories(periodNumbers, groupingType);
    } else {
      return exceedsPeriodLimitSales(periodNumbers, groupingType);
    }
  };

  /**
   *
   * Función que verifica si se excedieron los límites del número de periodos en inventarios
   *
   * @param {*} periodNumbers
   * @param {*} groupingType
   * @returns true en caso de sobrepasarese del límite, false si es lo contrario
   */
  const exceedsPeriodLimitInventories = (periodNumbers, groupingType) => {
    if (groupingType === "0" && periodNumbers > 31) {
      setGroupingLimit(31);
      return true;
    } else if (
      (groupingType === "1" || groupingType === "4") &&
      periodNumbers > 26
    ) {
      setGroupingLimit(26);
      return true;
    } else if (groupingType === "2" && periodNumbers > 6) {
      setGroupingLimit(6);
      return true;
    } else if (groupingType === "3" && periodNumbers > 1) {
      setGroupingLimit(1);
      return true;
    } else {
      return false;
    }
  };

  /**
   *
   * Función que verifica si se excedieron los límites del número de periodos en ventas
   *
   * @param {*} periodNumbers
   * @param {*} groupingType
   * @returns true en caso de sobrepasarese del límite, false si es lo contrario
   */
  const exceedsPeriodLimitSales = (periodNumbers, groupingType) => {
    if (groupingType === "0" && periodNumbers > 31) {
      setGroupingLimit(31);
      return true;
    } else if (
      (groupingType === "1" || groupingType === "4") &&
      periodNumbers > 52
    ) {
      setGroupingLimit(52);
      return true;
    } else if (groupingType === "2" && periodNumbers > 12) {
      setGroupingLimit(12);
      return true;
    } else if (groupingType === "3" && periodNumbers > 2) {
      setGroupingLimit(2);
      return true;
    } else {
      return false;
    }
  };

  /**
   *
   * Función para validar si el día de generación fue seleccionado, esto solo aplica para frecuencia de generación diferente a diario
   *
   * @returns true o false dependiendo de la frecuencia y si se seleccionó el día de generación
   */
  const handleValidateGenerationDay = () => {
    if (frequency !== "0") {
      if (generationdayDayList.label === "") {
        return true;
      } else {
        return false;
      }
    }
  };

  /**
   *
   * Valida si el formulario tiene al menos un checkbox seleccionado
   *
   * @param {} data campos del formulario
   * @returns true si hay un checkbox seleccionado, false si es lo contrario
   */
  const validateAtLeastOneCheck = (data) => {
    const objToArray = Object.entries(data);

    // console.log(objToArray);

    // element[0].includes("check") &&
    const checkAtLeastOne = objToArray.filter((element) => element[1] === true);
    // console.log(checkAtLeastOne);

    if (checkAtLeastOne.length > 0) {
      setShowValidationChecks(false);
      return false;
    } else {
      setShowValidationChecks(true);
      return true;
    }
  };

  /**
   * Función para abrir un modal para seleccionar los contactos
   */
  const handleOpenContacts = () => {
    setOpenModalContacts(true);
  };

  /**
   *
   * Función para editar un reporte dinámico
   *
   * @param {Object} data
   */ // TODO: Revisar para selección contactos usuario pertenece BG
  const handleEditReport = async (data) => {
    const exceeds = exceedsPeriodLimit(data);
    const oneSelected = validateAtLeastOneCheck(data);

    if (data.accounts?.split(",")?.length > 10) {
      setOpenModalExceedLimits(true);
    }

    if (exceeds) {
      setOpenModalExceedPeriodLimit(true);
    }

    let isValidateGenerationDay;
    if (handleValidateGenerationDay()) {
      isValidateGenerationDay = true;
      return;
    } else {
      isValidateGenerationDay = false;
    }

    if (!exceeds && !oneSelected && !isValidateGenerationDay) {
      setShowValidationChecks(false);
      setIsLoading(true);

      const emails = data.accounts ? data.accounts.split(",") : [];
      const cleanEmails = emails.map((email) => {
        return email.trim();
      });

      //LOS DATOS DE LA EMPRESA DEBEN SER LOS DE LA EMPRESA ASOCIADO AL REPORTE (eanProvider, provider, country)
      const obj = {
        pk: "ean_provider#" + pk,
        sk: "pivot#" + sk,
        dynamicReportDTO: {
          eanProvider: data.lstCompanies[0].value,
          provider: data.lstCompanies[0].label.split("-")[1],
          country: data.lstCompanies[0].country,
          typeOfReport: data.type === "1" ? "inventarios" : "ventas",
          generationFrequency: getGenerationFrecuency(Number(data.frecuency)),
          generationDay: generationdayDayList.value,
          reportStatus: data.status === "1" ? "ACTIVO" : "INACTIVO",
          typeOfDataGrouping: getGenerationGrouping(data.grouping),
          amountOfPeriodsToGenerate: parseInt(data.numberOfPeriods),
          fileName: data.fileName,
          emails: cleanEmails.join(","),
          comparePreviousPeriod: data.compareCheck ? 1 : 0,
          crossSalesOrInventories: data.matchCheck ? 1 : 0,

          lstFilterTraders: transformArray(tradersSelected, "trader"),
          lstFilterEansPointsales: eanPointSaleSelected,
          lstFilterPointSales: transformArray(pointSalesSelected, "pointSale"),
          lstFilterFormats: formatsSelected,
          lstFilterLevel1Geographies: transformArray(
            departmentsSelected,
            "department"
          ),
          lstFilterLevel4Geographies: transformArray(citiesSelected, "city"),
          lstFilterChannels: channelsSelected,
          lstFilterLevel1Categories: categoriesSelected,
          lstFilterProducts: transformArray(productsSelected, "product"),
          lstLevel1Jeraquies: salesForceSelected,
          lstFilterSalesMan: transformArray(salesManSelected, "salesman"),
          lstFilterBrands: brandsSelected,
          lstFilterColors: colorsSelected,
          lstFilterSizes: sizesSelected,

          checkCompany: data.company ? 1 : 0,
          checkTrader: data.merchant ? 1 : 0,
          checkPointSale: data.pointOfSale ? 1 : 0,
          checkChanel: data.channel ? 1 : 0,
          checkFormat: data.format ? 1 : 0,
          checkDepartamentState: data.department ? 1 : 0,
          checkCity: data.city ? 1 : 0,
          checkSalesForceHierarchy: data.hierarchy ? 1 : 0,
          checkSalesMan: data.seller ? 1 : 0,
          checkCategoriesProduct: data.tree ? 1 : 0,
          checkProduct: data.product ? 1 : 0,
          checkBrand: data.brand ? 1 : 0,
          checkColor: data.colour ? 1 : 0,
          checkSize: data.size ? 1 : 0,
          checkDispatchUnit: data.dispatch ? 1 : 0,
          checkStandardUnit: data.standard ? 1 : 0,
          checkWeight: data.weight ? 1 : 0,
          checkVolume: data.volume ? 1 : 0,
          idUser: `${userInfo.firstName.substring(
            0,
            3
          )}${userInfo.lastName.substring(0, 3)}`,
          creationUser: originalReport.creationUser,
          creationDate: originalReport.creationDate,
          modificationUser: userInfo.firstName + " " + userInfo.lastName,
        },
      };

      const dynamicReportService = await EditDynamicReport(obj);

      if (dynamicReportService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          dynamicReportService.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_CREATE
        ) {
          setLimitReport(true);
        }
        setResponseModalInfo(dynamicReportService);

        setModalInfo(true);
      }

      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Función para limpiar los campos el formulario
   */
  const handleClear = async () => {
    reset();
    setFileName("");
    setPeriodNumber(1);

    handleFrecuencyChange({
      target: {
        value: "0",
      },
    });
    await fetchData();
  };

  /**
   *
   * Función para obtener el string correspondiente del tipo de frecuencia de generación
   *
   * @param {*} data
   * @returns string corrspondiente al tipo de frecuencia de generación
   */
  const getGenerationFrecuency = (data, convert) => {
    if (!convert) {
      switch (data) {
        case 0:
          return "Diario";
        case 1:
          return "Semanal";
        case 2:
          return "Mensual";
        case 3:
          return "Anual";
      }
    } else {
      switch (data) {
        case "Diario":
          return 0;
        case "Semanal":
          return 1;
        case "Mensual":
          return 2;
        case "Anual":
          return 3;
      }
    }
  };

  /**
   *
   * FUnción para obtener el label del tipo de frecuencia de generación
   *
   * @param {*} data
   * @param {*} value
   */
  const getGenerationFrecuencyLabel = (data, value) => {
    try {
      switch (data) {
        case "Diario":
          setGenerationDayList({
            label: t("App.dynamicPeriodicReport.when.noDay"),
            value: "",
          });
          break;
        case "Semanal":
          if (value === "1") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.monday"),
              value: 1,
            });
          }
          if (value === "2") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.tuesday"),
              value: 2,
            });
          }
          if (value === "3") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.wednesday"),
              value: 3,
            });
          }
          if (value === "4") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.thursday"),
              value: 4,
            });
          }
          if (value === "5") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.friday"),
              value: "5",
            });
          }
          if (value === "6") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.saturday"),
              value: 6,
            });
          }
          if (value === "7") {
            setGenerationDayList({
              label: t("App.dynamicPeriodicReport.when.modalChoose.sunday"),
              value: 7,
            });
          }
          break;
        case "Mensual":
          setGenerationDayList({
            label: value,
            value: value,
          });
          break;
        case "Anual":
          setGenerationDayList({
            label: moment(value).format("DD-MM-YYYY"),
            value: moment(value).format("YYYYMMDD"),
          });
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *
   * Función para obtener el string del tipo de agrupación
   *
   * @param {*} data
   * @param {*} convert
   * @returns el string respectivo al número de agrupación
   */
  const getGenerationGrouping = (data, convert) => {
    if (!convert) {
      switch (data) {
        case "0":
          return "Diario";
        case "1":
          return "Semanal";
        case "2":
          return "Mensual";
        case "3":
          return "Anual";
      }
    } else {
      switch (data) {
        case "Diario":
          return "0";
        case "Semanal ISO (L-D)":
          return "1";
        case "Mensual":
          return "2";
        case "Anual":
          return "3";
      }
    }
  };

  /**
   *
   * Función para transformar los valores seleccionados de los checkboxes en un arreglo de objetos
   *
   * @param {*} data
   * @param {*} type
   * @param {*} convert
   * @returns el arreglo con los objeto creados apartir de los valores de los checkboxes
   */
  const transformArray = (data, type, convert) => {
    if (!convert) {
      switch (type) {
        case "trader":
          return data.map((item) => {
            return { trader: item.label, eanTrader: item.ean };
          });
        case "pointSale":
          return data.map((item) => {
            return { pointSaleName: item.label, eanPointSale: item.ean };
          });
        case "department":
          return data.map((item) => {
            return { levelOne: item.label, idLevelOne: item.ean };
          });
        case "city":
          return data.map((item) => {
            return { levelFour: item.label, idLevelFour: item.ean };
          });
        case "product":
          return data.map((item) => {
            return { productName: item.label, eanProduct: item.ean };
          });
        case "salesman":
          return data.map((item) => {
            return { salesMan: item.label, salesManCode: item.ean };
          });
      }
    } else {
      switch (type) {
        case "trader":
          return data.map((item) => {
            return { label: item.trader, ean: item.eanTrader };
          });
        case "pointSale":
          return data.map((item) => {
            return { label: item.pointSaleName, ean: item.eanPointSale };
          });
        case "department":
          return data.map((item) => {
            return { label: item.levelOne, ean: item.idLevelOne };
          });
        case "city":
          return data.map((item) => {
            return { label: item.levelFour, ean: item.idLevelFour };
          });
        case "product":
          return data.map((item) => {
            return { label: item.productName, ean: item.eanProduct };
          });
        case "salesman":
          return data.map((item) => {
            return { label: item.salesMan, ean: item.salesManCode };
          });
      }
    }
  };

  /**
   *
   * Función para crear un arreglo de objeto con las listas que devuelve el servicio de GetDynamicReportByEan
   *
   * @param {*} data
   * @returns arreglo de objeto con el atributo de label que contiene el item
   */
  const transformLabelArray = (data) => {
    return data.map((item) => {
      return { label: item };
    });
  };

  /**
   * Obtiene los datos iniciales del formulario
   */
  const initialData = async () => {
    setFetchingInfo(true);
    await fetchData();
    setFetchingInfo(false);
  };

  /**
   * Obtiene los datos del reporte periodico
   */
  const fetchData = async () => {
    try {
      const obj = {
        pk: "ean_provider#" + pk,
        sk: "pivot#" + sk,
      };

      const reportService = await GetDynamicReportByEan(obj);

      if (reportService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (reportService.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
          const responseMessage =
            reportService.data.responseMessage[0].dynamicReportDTO;

          setOriginalReport(responseMessage);

          setStartForm(true);

          let typeOfReport =
            responseMessage.typeOfReport === "ventas" ? "2" : "1";

          setReportType(typeOfReport);
          setDesiredType(typeOfReport);
          setValue("type", typeOfReport);

          let generationFrec = getGenerationFrecuency(
            responseMessage.generationFrequency,
            true
          );

          handleFrecuencyChange({
            target: {
              value: generationFrec,
            },
          });

          setValue("accounts", responseMessage.emails);

          setValue(
            "status",
            responseMessage.reportStatus === "ACTIVO" ||
              responseMessage.reportStatus === "ACTIVO PROXIMO A VENCER"
              ? "1"
              : "0"
          );

          setStatus(
            responseMessage.reportStatus === "ACTIVO" ||
              responseMessage.reportStatus === "ACTIVO PROXIMO A VENCER"
              ? "1"
              : "0"
          );

          setValue("fileName", responseMessage.fileName);

          setFileName(responseMessage.fileName);

          let periodGene = getGenerationGrouping(
            responseMessage.typeOfDataGrouping,
            true
          );

          handlePeriodChange({
            target: {
              value: periodGene,
            },
          });

          let periodNumbers = responseMessage.amountOfPeriodsToGenerate;
          onChangePeriodNumber({
            target: {
              value: periodNumbers,
            },
          });

          getGenerationFrecuencyLabel(
            responseMessage.generationFrequency,
            responseMessage.generationDay
          );

          const currentCompanyToEdit = {
            label: `${responseMessage.country}-${responseMessage.provider}`,
            value: responseMessage.eanProvider,
            country: responseMessage.country,
          };

          setValue("lstCompanies", [currentCompanyToEdit]);

          //Checkboxs
          setValue("company", responseMessage.checkCompany ? true : false);

          setValue("merchant", responseMessage.checkTrader ? true : false);

          setValue(
            "pointOfSale",
            responseMessage.checkPointSale ? true : false
          );

          setValue("channel", responseMessage.checkChanel ? true : false);

          setValue("format", responseMessage.checkFormat ? true : false);

          setValue(
            "department",
            responseMessage.checkDepartamentState ? true : false
          );

          setValue("city", responseMessage.checkCity ? true : false);

          setValue(
            "hierarchy",
            responseMessage.checkSalesForceHierarchy ? true : false
          );

          setValue("seller", responseMessage.checkSalesMan ? true : false);

          setValue(
            "tree",
            responseMessage.checkCategoriesProduct ? true : false
          );

          setValue("product", responseMessage.checkProduct ? true : false);

          setValue("brand", responseMessage.checkBrand ? true : false);

          setValue("colour", responseMessage.checkColor ? true : false);

          setValue("size", responseMessage.checkSize ? true : false);

          setValue(
            "dispatch",
            responseMessage.checkDispatchUnit ? true : false
          );

          setValue(
            "standard",
            responseMessage.checkStandardUnit ? true : false
          );

          setValue("weight", responseMessage.checkWeight ? true : false);

          setValue("volume", responseMessage.checkVolume ? true : false);

          setValue(
            "compareCheck",
            responseMessage.comparePreviousPeriod ? true : false
          );

          setValue(
            "matchCheck",
            responseMessage.crossSalesOrInventories ? true : false
          );

          //Filtros

          dispatch(
            setSelectedTrader(
              transformArray(responseMessage.lstFilterTraders, "trader", true)
            )
          );

          dispatch(
            setSelectedEanPointSale(
              transformLabelArray(responseMessage.lstFilterEansPointsales)
            )
          );

          dispatch(
            setSelectedPointSale(
              transformArray(
                responseMessage.lstFilterPointSales,
                "pointSale",
                true
              )
            )
          );

          dispatch(
            setSelectedFormats(
              transformLabelArray(responseMessage.lstFilterFormats)
            )
          );

          dispatch(
            setSelectedDepartments(
              transformArray(
                responseMessage.lstFilterLevel1Geographies,
                "department",
                true
              )
            )
          );

          dispatch(
            setSelectedCities(
              transformArray(
                responseMessage.lstFilterLevel4Geographies,
                "city",
                true
              )
            )
          );

          dispatch(
            setSelectedChannel(
              transformLabelArray(responseMessage.lstFilterChannels)
            )
          );

          dispatch(
            setSelectedCategories(
              transformLabelArray(
                responseMessage.lstFilterLevel1Categories
                  ? responseMessage.lstFilterLevel1Categories
                  : []
              )
            )
          );

          dispatch(
            setSelectedProducts(
              transformArray(responseMessage.lstFilterProducts, "product", true)
            )
          );

          dispatch(
            setSelectedSalesForceLevelOne(
              transformLabelArray(
                responseMessage.lstLevel1Jeraquies
                  ? responseMessage.lstLevel1Jeraquies
                  : []
              )
            )
          );

          dispatch(
            setSelectedBrands(
              transformLabelArray(responseMessage.lstFilterBrands)
            )
          );

          dispatch(
            setSelectedColors(
              transformLabelArray(responseMessage.lstFilterColors)
            )
          );

          dispatch(
            setSelectedSizes(
              transformLabelArray(responseMessage.lstFilterSizes)
            )
          );

          dispatch(
            setSelectedSalesMan(
              transformArray(
                responseMessage.lstFilterSalesMan,
                "salesman",
                true
              )
            )
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * useEffect para colocar los valores correspondientes a cada campo
   */
  useEffect(() => {
    initialData();
  }, []);

  /**
   * Función para eliminar los contactos seleccionaados
   */
  const handleDeleteAccounts = () => {
    setValue("accounts", "");
  };

  return (
    <form onSubmit={handleSubmit(handleEditReport)}>
      {fetchingInfo && <LoadingPage />}

      {!fetchingInfo && (
        <>
          <ModalChangeType
            open={openModalChangeType}
            setOpen={setOpenModalChangeType}
            setReportType={setReportType}
            desiredType={desiredType}
            setValue={setValue}
          />

          <ModalExceedEmails
            open={openModalExceedEmails}
            setOpen={setOpenModalExceedLimits}
          />

          <ModalInvalidMail
            open={openModalInvalidMail}
            setOpen={setOpenModalInvalidMail}
          />

          <ModalExceedPeriod
            open={openModalExceedPeriodLimit}
            setOpen={setOpenModalExceedPeriodLimit}
            grouping={grouping}
            limit={groupingLimit}
          />

          <ModalInfo
            title={
              limitReport
                ? t("App.dynamicPeriodicReport.limit")
                : t("App.dynamicPeriodicReport.title")
            }
            responseData={responseModalInfo}
            open={openModalInfo}
            onClose={() => {
              setModalInfo(false);
              navigate(-1);
            }}
          />

          <ModalContactsReport
            open={openModalContacts}
            setOpen={setOpenModalContacts}
            setValue={setValue}
            watch={watch}
            userInfo={userInfo}
            setOpenModalError={setOpenErrorContacts}
          />

          <ModalInfo
            open={openErrorContacts}
            title={t("App.adminContacts.modals.case1005")}
            responseData={{
              data: {
                responseMessage: t("App.validationMessages.systemError2"),
              },
            }}
            onClose={() => {
              setOpenErrorContacts(false);
            }}
          />

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <p className="display-large__primary-one">
                {t("App.dynamicPeriodicReport.title")}
              </p>
            </Grid>

            <Grid item className="back-container">
              <Grid container direction="row">
                <Grid item>
                  <IconButton disableRipple onClick={handleBackReports}>
                    <ArrowBackIosNewIcon className="display-large__moderate-blue" />
                  </IconButton>
                </Grid>

                <Grid item className="col-start">
                  <p className="display-small__moderate-blue">
                    {t("App.dynamicPeriodicReport.goBack")}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <TypeSelectorReportEdit
                componentName={"dynamicReport"}
                reportType={reportType}
                setReportType={setReportType}
                control={control}
                handleTypeChange={handleTypeChange}
                typeOfDocument={"type"}
                errors={errors}
                setValue={setValue}
                setStartForm={setStartForm}
                setDesiredType={setDesiredType}
              />
            </Grid>

            {startForm && (
              <>
                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="grid__container__filled__rounded"
                  >
                    <Grid item>
                      <p className="display-small__primary-one">
                        {t("App.dynamicPeriodicReport.when.subtitle")}
                      </p>
                    </Grid>

                    <Grid item className="grid__container__fullW">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={8}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "center",
                                }}
                                alignItems="center"
                              >
                                <Grid item xs={12} sm={4}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.when.frecuency"
                                    )}
                                  </p>
                                </Grid>

                                <Grid item xs={4} sm={8}>
                                  <Select
                                    {...register("frecuency")}
                                    onChange={handleFrecuencyChange}
                                    className={
                                      errors.frecuency
                                        ? "modalCreateUsers__form-input--error"
                                        : "modalCreateUsers__form-input"
                                    }
                                    value={frequency}
                                    id="frecuency"
                                    name="frecuency"
                                    labelId="frecuency"
                                  >
                                    <MenuItem value="0">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.daily"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="1">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.weekly"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="2">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.monthly"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="3">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.yearly"
                                        )}
                                      </em>
                                    </MenuItem>
                                  </Select>

                                  <FormHelperText
                                    sx={{
                                      margin: "-8px 0 1px 0",
                                      color: "#e52900",
                                      fontSize: 10,
                                    }}
                                  >
                                    {errors.frecuency
                                      ? errors.frecuency.message
                                      : ""}
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "center",
                                }}
                                alignItems="center"
                              >
                                <Grid item xs={12} sm={4}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.when.generationDay"
                                    )}
                                  </p>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <TextField
                                        onChange={handleFrecuencyChange}
                                        className={
                                          handleValidateGenerationDay()
                                            ? "modalCreateUsers__form-input--error"
                                            : "modalCreateUsers__form-input"
                                        }
                                        FormHelperTextProps={{
                                          style: {
                                            margin: "-5px 0 1px 0px",
                                            color: "#e52900",
                                            fontSize: 10,
                                          },
                                        }}
                                        helperText={
                                          handleValidateGenerationDay()
                                            ? t(
                                                "App.validationMessages.required"
                                              )
                                            : ""
                                        }
                                        value={generationdayDayList.label}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        id="generationDay"
                                        name="generationDay"
                                        disabled={generationdayDisabled}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <ModalChooseGenerationDay
                                        generationdayDisabled={
                                          generationdayDisabled
                                        }
                                        setGenerationDayList={
                                          setGenerationDayList
                                        }
                                        generationdayDayList={
                                          generationdayDayList
                                        }
                                        frequency={frequency}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "center",
                                }}
                                alignItems="center"
                              >
                                <Grid item xs={12} sm={4}>
                                  <p className="label__text-default">
                                    {t("App.dynamicPeriodicReport.when.status")}
                                  </p>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                  <Select
                                    {...register("status")}
                                    className={
                                      errors.status
                                        ? "modalCreateUsers__form-input--error"
                                        : "modalCreateUsers__form-input"
                                    }
                                    id="status"
                                    name="status"
                                    onChange={handleStatusChange}
                                    value={status}
                                  >
                                    <MenuItem value="1">
                                      <em>
                                        {t(
                                          "App.dynamicPeriodicReport.when.active"
                                        )}
                                      </em>
                                    </MenuItem>

                                    <MenuItem value="0">
                                      <em>
                                        {" "}
                                        {t(
                                          "App.dynamicPeriodicReport.when.inactive"
                                        )}
                                      </em>
                                    </MenuItem>
                                  </Select>

                                  <FormHelperText
                                    sx={{
                                      margin: "-8px 0 1px 0",
                                      color: "#e52900",
                                      fontSize: 10,
                                    }}
                                  >
                                    {errors.status ? errors.status.message : ""}
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <ToolTipDayOfGeneration
                            generationdayDayList={generationdayDayList}
                            frequency={frequency}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="grid__container__filled__rounded"
                  >
                    <Grid item>
                      <p className="display-small__primary-one">
                        {t("App.dynamicPeriodicReport.period.subtitle")}
                      </p>
                    </Grid>

                    <Grid item className="grid__container__fullW">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={8}>
                          <Grid
                            container
                            direction="column"
                            justifyContent={{ xs: "flex-start", sm: "center" }}
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item xs={12} sm={4}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.period.typeOfGroup"
                                    )}
                                  </p>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                  <Controller
                                    name="grouping"
                                    onChange={handlePeriodChange}
                                    control={control}
                                    defaultValue="0"
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Select
                                        className={
                                          errors.grouping
                                            ? "modalCreateUsers__form-input--error"
                                            : "modalCreateUsers__form-input"
                                        }
                                        id="grouping"
                                        name="grouping"
                                        labelId="grouping"
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          handlePeriodChange(e);
                                        }}
                                      >
                                        <MenuItem value="0">
                                          <em>
                                            {t(
                                              "App.dynamicPeriodicReport.when.daily"
                                            )}
                                          </em>
                                        </MenuItem>

                                        <MenuItem value="1">
                                          <em>
                                            {t(
                                              "App.dynamicPeriodicReport.period.isoWeek"
                                            )}
                                          </em>
                                        </MenuItem>

                                        <MenuItem value="2">
                                          <em>
                                            {t(
                                              "App.dynamicPeriodicReport.when.monthly"
                                            )}
                                          </em>
                                        </MenuItem>
                                        <MenuItem value="3">
                                          <em>
                                            {t(
                                              "App.dynamicPeriodicReport.when.yearly"
                                            )}
                                          </em>
                                        </MenuItem>
                                      </Select>
                                    )}
                                  />

                                  <FormHelperText
                                    sx={{
                                      margin: "-8px 0 1px 0",
                                      color: "#e52900",
                                      fontSize: 10,
                                    }}
                                  >
                                    {errors.grouping
                                      ? errors.grouping.message
                                      : ""}
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "center",
                                }}
                                alignItems="center"
                              >
                                <Grid item xs={12} sm={4}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.period.numberPeriods"
                                    )}
                                  </p>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <Controller
                                        name="numberOfPeriods"
                                        control={control}
                                        defaultValue="1"
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <TextField
                                            inputProps={{
                                              style: {
                                                margin: "-5px 0 0 0",
                                                fontSize: 12,
                                              },
                                            }}
                                            type="number"
                                            className={
                                              errors.numberOfPeriods
                                                ? "modalCreateUsers__form-input--error__small"
                                                : "modalCreateUsers__form-input__small"
                                            }
                                            FormHelperTextProps={{
                                              style: {
                                                margin: "-5px 0 1px 0px",
                                                color: "#e52900",
                                                fontSize: 10,
                                              },
                                            }}
                                            helperText={
                                              value
                                                ? errors.amountOfPeriodsToGenerate
                                                  ? errors
                                                      .amountOfPeriodsToGenerate
                                                      .message
                                                  : value < 1
                                                  ? t(
                                                      "App.validationMessages.greaterZero"
                                                    )
                                                  : ""
                                                : t(
                                                    "App.validationMessages.greaterZero"
                                                  )
                                            }
                                            value={value}
                                            onChange={(e) => {
                                              console.log(e);
                                              onChange(e);
                                              onChangePeriodNumber(e);
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item>
                                      <TooltipAmountOfPeriods />
                                    </Grid>

                                    <Grid item>
                                      {(period === null || period === "0") && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.backDays"
                                          )}
                                        </p>
                                      )}

                                      {period === "1" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.backIsoWeeks"
                                          )}
                                        </p>
                                      )}

                                      {period === "2" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.backMonths"
                                          )}
                                        </p>
                                      )}

                                      {period === "3" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.backYears"
                                          )}
                                        </p>
                                      )}

                                      {period === "4" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.backWeeks"
                                          )}
                                        </p>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      {(period === null || period === "0") && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.lastPeriodDay"
                                          )}
                                        </p>
                                      )}

                                      {period === "1" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.lastPeriodIsoWeek"
                                          )}
                                        </p>
                                      )}

                                      {period === "2" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.lastPeriodMonth"
                                          )}
                                        </p>
                                      )}

                                      {period === "3" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.lastPeriodYear"
                                          )}
                                        </p>
                                      )}

                                      {period === "4" && (
                                        <p className="subheading__primary-one">
                                          {t(
                                            "App.dynamicPeriodicReport.period.lastPeriodWeek"
                                          )}
                                        </p>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <ToolTipPeriodSummary
                            numberPeriods={watch}
                            generationdayDayList={generationdayDayList}
                            grouping={grouping}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            amountOfPeriods={periodNumber}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="grid__container__filled__rounded"
                  >
                    <Grid item>
                      <p className="display-small__primary-one">
                        {t("App.dynamicPeriodicReport.config.subtitle")}
                      </p>
                    </Grid>

                    <Grid item className="grid__container__fullW ">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={9}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW  u-marginB__responsive"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "center",
                                }}
                                alignItems="center"
                              >
                                <Grid item xs={12} sm={3}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.config.fileName"
                                    )}
                                  </p>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                  <TextField
                                    {...register("fileName")}
                                    value={fileName}
                                    className={
                                      errors.fileName
                                        ? "modalCreateUsers__form-input--error label__text-grey"
                                        : "modalCreateUsers__form-input label__text-grey"
                                    }
                                    inputProps={{ maxLength: 50 }}
                                    id="fileName"
                                    name="fileName"
                                    helperText={t(
                                      "App.dynamicPeriodicReport.config.fileHelper"
                                    )}
                                    FormHelperTextProps={{
                                      className: "caption__blue3",
                                      style: {
                                        margin: "6px 0 1px 0px",
                                      },
                                    }}
                                    onChange={onChangeFileName}
                                  />

                                  <FormHelperText
                                    sx={{
                                      margin: "-8px 0 1px 0px",
                                      color: "#e52900",
                                      fontSize: 10,
                                    }}
                                  >
                                    {errors.fileName
                                      ? errors.fileName.message
                                      : ""}
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              className="u-marginT grid__container__fullW   u-marginB__responsive"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={{
                                  xs: "flex-start",
                                  sm: "center",
                                }}
                                alignItems="center"
                              >
                                <Grid item xs={12} md={3}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.config.accounts"
                                    )}
                                  </p>
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <TextField
                                        {...register("accounts")}
                                        disabled
                                        autoComplete="off"
                                        className={
                                          errors.accounts
                                            ? "modalCreateUsers__form-input--error__large label__text-grey"
                                            : "modalCreateUsers__form-input__large label__text-grey"
                                        }
                                        FormHelperTextProps={{
                                          className: "caption__blue3",
                                          style: {
                                            margin: "6px 0 1px 0px",
                                          },
                                        }}
                                        id="accounts"
                                        name="accounts"
                                        helperText={t(
                                          "App.dynamicPeriodicReport.config.accountsHelper"
                                        )}
                                        InputProps={{
                                          endAdornment: watch("accounts") && (
                                            <IconButton
                                              className="btn__outlined__blue"
                                              sx={{
                                                color: "white",
                                                cursor: "pointer",
                                                border: "none !important",
                                                marginBottom: "3%",
                                              }}
                                              onClick={handleDeleteAccounts}
                                            >
                                              <ClearIcon
                                                sx={{ fontSize: 16 }}
                                              />
                                            </IconButton>
                                          ),
                                        }}
                                      />

                                      <FormHelperText
                                        sx={{
                                          margin: "-8px 0 1px 0",
                                          color: "#e52900",
                                          fontSize: 10,
                                        }}
                                      >
                                        {errors.accounts
                                          ? errors.accounts.message
                                          : ""}
                                      </FormHelperText>
                                    </Grid>

                                    <Grid
                                      item
                                      className="col-flex"
                                      style={{ marginTop: "1%" }}
                                    >
                                      <IconButton
                                        className="btn__filled__blue"
                                        sx={{
                                          fontSize: 26,
                                          color: "white",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleOpenContacts}
                                      >
                                        <AddIcon sx={{ fontSize: 20 }} />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Controller
                                  defaultValue={false}
                                  name={"compareCheck"}
                                  control={control}
                                  render={({ field: props }) => (
                                    <Checkbox
                                      {...props}
                                      checked={props.value}
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                    />
                                  )}
                                />
                              }
                              label={t(
                                "App.dynamicPeriodicReport.config.compare"
                              )}
                            />

                            <FormControlLabel
                              control={
                                <Controller
                                  defaultValue={false}
                                  name={"matchCheck"}
                                  control={control}
                                  render={({ field: props }) => (
                                    <Checkbox
                                      {...props}
                                      checked={props.value}
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                    />
                                  )}
                                />
                              }
                              label={
                                reportType == "2"
                                  ? t(
                                      "App.dynamicPeriodicReport.config.inventoryMatch"
                                    )
                                  : t(
                                      "App.dynamicPeriodicReport.config.salesMatch"
                                    )
                              }
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      className=" grid__container__fullW grid__container__boxShadow"
                      style={{ marginTop: "3rem" }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <p className="label__text-default">
                                {t(
                                  "App.dynamicPeriodicReport.config.informpt1"
                                )}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="grid__container__filled__rounded"
                  >
                    <Grid item>
                      <p className="display-small__primary-one">
                        {t("App.dynamicPeriodicReport.scope.subtitle")}
                      </p>
                    </Grid>

                    <Grid item className="grid__container__fullW ">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12}>
                                  <ScopeList
                                    control={control}
                                    register={register}
                                    setValue={setValue}
                                    getValues={getValues}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className="grid__container__fullW">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="grid__container__filled__rounded"
                  >
                    <Grid item>
                      <p className="display-small__primary-one">
                        {t("App.dynamicPeriodicReport.fields.subtitle")}
                      </p>
                    </Grid>

                    <Grid item className="grid__container__fullW ">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.fields.select"
                                    )}
                                  </p>
                                </Grid>

                                {showValidationChecks && (
                                  <Grid
                                    item
                                    xs={12}
                                    className="caption__error-color"
                                  >
                                    {t("App.specialFilesClient.oneSelected")}
                                  </Grid>
                                )}

                                <Grid item xs={12}>
                                  <DynamicCheckBoxList
                                    control={control}
                                    register={register}
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <p className="label__text-default">
                                    {t(
                                      "App.dynamicPeriodicReport.fields.description"
                                    )}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className="grid__container__fullW u-marginB">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    className="grid__container__filled__rounded"
                  >
                    <Grid item className="grid__container__fullW ">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              className="u-marginT grid__container__fullW"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                  <ModalClearInfo handleClear={handleClear} />
                                </Grid>
                                <Grid item>
                                  <Button
                                    className="btn__filled__blue"
                                    type="submit"
                                  >
                                    {!isLoading ? (
                                      t("App.buttonLabels.save")
                                    ) : (
                                      <CircularProgress
                                        size={16}
                                        color="inherit"
                                      />
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </form>
  );
};

import React from "react";
import Grid from "@mui/material/Grid";
import WelcomeHome from "../../components/common/welcomeHome";

export const AdminHome = () => {
  return (
    <div>
      <div className="container__page">
        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WelcomeHome />
        </Grid>
      </div>
    </div>
  );
};

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function FilterBusinessRules(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data,
    url: "businness/rules/filter",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetBusinessRules() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: "businness/rules",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditBusinessRule(data) {
  const token = await getToken();

  return axios({
    method: "PUT",
    data,
    url: "businness/rules",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

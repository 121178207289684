import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

const authAxios = axios.create();

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    console.log("=============== ERROR AUTHSERVICES.JS =====================");
    console.log(error);
    console.log("===========================================================");

    return Promise.reject(error);
  }
);

export function LoginService(data) {
  return authAxios({
    method: "POST",
    url: "auths/login",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email: data.email,
      password: data.password,
    },
  });
}

export function CheckSession(data) {
  return authAxios({
    method: "POST",
    url: "auths/check/sessions",
    baseURL: env.REACT_APP_API_URL,
    data: data,
  });
}

export function refreshToken(data) {
  return authAxios({
    method: "POST",
    url: "auths/refresh/tokens",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email: data.email,
      refreshToken: data.refreshToken,
    },
  });
}
export async function LogoutService() {
  const token = await getToken();
  return authAxios({
    method: "POST",
    url: "auths/logout",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

export function RecoverPasswordService(data) {
  return authAxios({
    method: "POST",
    url: "auths/forgot/passwords",
    baseURL: env.REACT_APP_API_URL,
    data: {
      email: data.email,
    },
  });
}

export async function validateCredentialsService(data) {
  const token = await getToken();

  return authAxios({
    method: "POST",
    url: "auths/changes/passwords",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      email: data.email,
      oldPassword: "C4@jk3zmhn9j",
      newPassword: data.password,
    },
    headers: {
      Authorization: token,
    },
  });
}

export async function changePasswordService(data) {
  const token = await getToken();

  return authAxios({
    method: "POST",
    url: "auths/changes/passwords",
    baseURL: env.REACT_APP_API_URL,
    data: {
      email: data.email,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    },
    headers: {
      Authorization: token,
    },
  });
}

export async function recoveryPassword(data) {
  const token = await getToken();

  return authAxios({
    method: "POST",
    url: "auths/passwords/recoveries",
    baseURL: env.REACT_APP_API_URL,
    data: { ...data },
    headers: {
      Authorization: token,
    },
  });
}

export async function TokenQlikSense(data) {
  const token = await getToken();

  return authAxios({
    method: "POST",
    url: "qlik",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

export async function UnlockUserCognito(data) {
  const token = await getToken();

  return authAxios({
    method: "POST",
    url: "auths/unlock/users/cognito",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

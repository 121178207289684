import { Grid, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModalChangeAction } from "../../../components/common/Modals/modalChangeAction";
import {
  getCurrentAction,
  setCurrentAction,
} from "../../../parts/deleteAndReload/deleteAndReloadSlice";

const SelectDeleteOrReload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentAction = useSelector(getCurrentAction);
  const [openModalChangeType, setOpenModalChangeType] = useState(null);
  const [desiredType, setDesiredType] = useState(null);

  /**
   *
   * Function to change the visually when select an option (reload o deleted)
   *
   * @param {Event} event
   */
  const handleTypeChange = (event) => {
    const id = event.target.value;
    // console.log(id);

    if (currentAction !== "2") {
      setDesiredType(id);
      setOpenModalChangeType(true);
    } else {
      dispatch(setCurrentAction({ newAction: id }));
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="grid__container__filled__rounded"
    >
      <ModalChangeAction
        open={openModalChangeType}
        setOpen={setOpenModalChangeType}
        desiredType={desiredType}
      />
      <Grid item>
        <p className="subheading__text-black">
          {t("App.deleteAndReload.selection")}
        </p>
      </Grid>
      <Grid item className="u-marginL">
        <Select
          className="select__border"
          onChange={handleTypeChange}
          value={currentAction}
          id="type"
          name="type"
          labelId="type"
          style={{ width: "200%" }}
        >
          <MenuItem disabled value="2">
            <em> {t("App.deleteAndReload.choose")}</em>
          </MenuItem>

          <MenuItem value="0">
            <em> {t("App.deleteAndReload.deleted")}</em>
          </MenuItem>

          <MenuItem value="1">
            <em> {t("App.deleteAndReload.reload")}</em>
          </MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default SelectDeleteOrReload;

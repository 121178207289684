import React from "react";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "12px",
  bgcolor: "white",
  padding: "1rem !important",

  p: 4,
};

export const ModalChangeAllie = (props) => {
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleAccept = () => {
    props.setOpen(false);
    props.reset();
    props.setValue("ally", props.allySelected);
  };
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        className="modal__notification"
        onClose={handleClose}
        open={props.open}
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50rem",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <DialogTitleContainer onClose={handleClose} className="col-start">
          <InfoOutlinedIcon sx={{ color: "#4dcafa", fontSize: "4rem" }} />
        </DialogTitleContainer>
        <DialogContent className="default-text_black">
          <Grid container direction="column">
            <Grid item className="heading-secondary__md">
              {t("App.allies.selectorChange")}
            </Grid>
            <Grid item className="default-text_black">
              {" "}
              {t("App.allies.selectorMsg")}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            columnGap={2}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                onClick={handleClose}
                disableRipple
                className="btn__filled__gradient"
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleAccept}
                disableRipple
                className="btn__filled__blue"
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

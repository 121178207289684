import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { UpdateContact } from "../../../services/user/contactsService";
import { REGEXP } from "../../../consts/regexp";

export const ModalEditContact = ({
  contact,
  setOpenModalEdit,
  open,
  currentIndex,
  setResponseContent,
  setResponseTitle,
  setOpenModalResponse,
  data,
  setData,
  setDataStructureArray,
  setTypeOfAction,
  setOpenModalDecision,
  setUnfilteredData,
  country,
  userName,
  companyName,
}) => {
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * UseForm
   */

  const schema = yup.object().shape({
    contactName: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    contactSurname: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString")),
    contactPhone: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(
        REGEXP.LETTERS_AND_NUMBERS,
        t("App.validationMessages.onlyAlphanumeric")
      )
      .nullable(),
    contactEmail: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
    contactPosition: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .matches(REGEXP.ONLY_LETTERS, t("App.validationMessages.onlyString"))
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * Use State
   */

  //Sets loading state when editing
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  /**
   * Use Effect
   */

  //Sets information of current contact into the form
  useEffect(() => {
    setValue("contactName", contact.name);
    setValue("contactSurname", contact.lastName);
    setValue("contactEmail", contact.email);
    setValue("contactPhone", contact.phone ? contact.phone : null);
    setValue("contactPosition", contact.position ? contact.position : null);
  }, [contact]);

  //When closing the modal, it resets the form
  useEffect(() => {
    switch (open) {
      case false:
        reset();
        break;
    }
  }, [open]);

  /**
   * Handles
   */

  /**
   * Handles the edit of the current contact
   * @param {*} data updated current contact information
   */
  const handleEditContact = async (data) => {
    try {
      setIsLoadingEdit(true);
      setTypeOfAction("edit");

      const obj = {
        pk: contact.pk,
        name: data.contactName,
        lastName: data.contactSurname,
        newEmail: data.contactEmail,
        lastEmail: contact.email,
        phone: data.contactPhone ? data.contactPhone : "",
        position: data.contactPosition ? data.contactPosition : "",
        eanCompany: contact.eanCompany,
        country,
        userName,
        companyName,
      };

      const editContactService = await UpdateContact(obj);

      setResponseTitle("");

      if (editContactService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          editContactService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          handleUpdateContactVisually(obj, contact.currentIndex);
        }

        //Correo ya diligenciado
        if (
          editContactService.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_UPDATE
        ) {
          //Abro modal decision
          setResponseContent(editContactService.data.responseMessage);
          setOpenModalDecision(true);
        } else {
          //Abro success o error
          setResponseTitle(setTitle(editContactService.data.responseCode));
          setResponseContent(editContactService);
          setOpenModalResponse(true);
          handleClose();
        }
      } else {
        setResponseContent(editContactService);
        setOpenModalResponse(true);
      }

      setIsLoadingEdit(false);
    } catch (error) {
      console.log(
        "============== Error modalEditContact.jsx function handleEditContact ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Closes modal edit
   */
  const handleClose = () => {
    setOpenModalEdit(false);
  };

  /**
   * Disables confirm changes button based on required fields state
   * @returns true if button must be disabled, false if not
   */
  const disableEditContact = () => {
    if (
      watch("contactName") &&
      watch("contactSurname") &&
      watch("contactEmail")
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Sets title for modal info based on responseCode value
   * @param {*} responseCode response code of edit request
   * @returns title for modal info
   */
  const setTitle = (responseCode) => {
    let title = "";
    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        title = t("App.validationMessages.success");
        break;
      case CODES.COD_RESPONSE_ERROR_UPDATE:
        title = t("App.adminContacts.modals.case1001");
        break;
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        title = t("App.adminContacts.modals.case1005");
        break;
      default:
        break;
    }
    return title;
  };

  /**
   * Updates the current edited contact into the datatable
   * @param {*} contactData current edited contact
   * @param {*} index contact position into datatable array
   */
  const handleUpdateContactVisually = (contactData, index) => {
    setData([]);
    const newRow = setDataStructureArray([
      { ...contactData, email: contactData.newEmail },
    ]);

    let newDataArray = data;
    newDataArray[index] = newRow[0];
    const finalArray = newDataArray.map((item) => item);
    setData(finalArray);
    setUnfilteredData(finalArray);
  };

  return (
    <Dialog
      maxWidth={"md"}
      onClose={handleClose}
      open={open}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "2rem",
          },
        },
      }}
    >
      <DialogTitleContainer onClose={handleClose}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.adminContacts.modals.edit")}
          </Grid>
          <Grid item>
            <Button
              className="btn__outlined__blue"
              disabled
              sx={{ border: "none !important", padding: "0 !important" }}
              startIcon={<PersonOutlineOutlinedIcon />}
            >
              {t("App.adminContacts.modals.info")}
            </Button>
          </Grid>
        </Grid>
      </DialogTitleContainer>
      <DialogContent>
        <Grid container direction="column" sx={{ paddingTop: "1%" }}>
          <form id="hook-contact" onSubmit={handleSubmit(handleEditContact)}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
                rowSpacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.name")}
                      </label>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactName"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactName
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactName
                                ? errors.contactName.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.name")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.lastname")}
                      </label>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactSurname"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactSurname
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactSurname
                                ? errors.contactSurname.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.lastname")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.cellPhone")}
                      </label>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactPhone"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactPhone
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactPhone
                                ? errors.contactPhone.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.cellPhone")}
                            // onChange={onChange}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactPhone",
                                watch("contactPhone")
                                  ? watch("contactPhone")
                                  : null
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
                rowSpacing={2}
                style={{ marginTop: "2%" }}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.createUser.email")}
                      </label>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactEmail"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactEmail
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactEmail
                                ? errors.contactEmail.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.createUser.email")}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width={"100%"}
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminContacts.modals.position")}
                      </label>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Controller
                        control={control}
                        name={"contactPosition"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={
                              errors.contactPosition
                                ? "select-contact__filters__errors__sm"
                                : "select-contact__filters__sm"
                            }
                            helperText={
                              errors.contactPosition
                                ? errors.contactPosition.message
                                : ""
                            }
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            placeholder={t("App.adminContacts.modals.position")}
                            // onChange={onChange}
                            onChange={(e) => {
                              onChange(e);
                              setValue(
                                "contactPosition",
                                watch("contactPosition")
                                  ? watch("contactPosition")
                                  : null
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          className="col-flex"
          columnGap={4}
          rowSpacing={2}
          sx={{ padding: "2.5%" }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            lg={1}
            order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
          >
            <Button
              className="btn__filled__gradient-contact"
              onClick={handleClose}
            >
              {t("App.createUser.cancelButton")}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
          >
            <Button
              type="submit"
              form="hook-contact"
              disabled={disableEditContact()}
              className={
                disableEditContact()
                  ? "btn__filled__disabled-contact"
                  : "btn__filled__blue-contact"
              }
            >
              {!isLoadingEdit ? (
                t("App.editUser.confirmButton")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Función de request para crear el mapeo de campos
 * @param {*} data información del mapeo
 * @returns respuesta sistema
 */
export async function CreateFieldMapping(data) {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `companies/traders/countries/CO`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función de request para validar si se puede crear el mapeo de campos
 * @param {*} data información de mapeo
 * @returns respuesta sistema
 */
export async function ValidateCreateFieldMapping(data) {
  const token = await GetToken();
  return axios({
    method: "GET",
    url: `companies/traders/countries/CO`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función de request para obtener el mapeo de campos que coincida
 * con el filtro
 * @param {*} data información de filtro
 * @returns respuesta sistema
 */
export async function FilterMapping(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    data,
    url: `mappings/externals/files/administrations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Función para consumir el servicio que devuelve el listado de comercios por país
 *
 * @param {*} data
 * @returns resultado de la petición de axios (lista de comercios)
 */
export async function GetTradersService(country) {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `companies/traders/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Función para consumir el servicio que devuelve la lista de proveedores por país
 *
 * @param {*} data
 * @returns resultado de la petición de axios (lista de proveedores)
 */
export async function GetSuppliersService(country) {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `companies/providers/countries/${country}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Obtener el detalle del mapeo
 * @param {*} data información deL registro del mapeo
 * @returns
 */
export async function GetDetailMapping(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    data,
    url: `mappings/externals/files/configurations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

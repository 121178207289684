import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, Button } from "@mui/material";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getUser, fetchUser, getUserStatus } from "../../pages/userSlice";
import ModalInfo from "../common/Modals/modalInfo";
import MenuOptionsComponent from "./menu/menuOptionsComponent";
const drawerWidth = 280;
const openedMixin = (theme) => ({
  width: drawerWidth,
  [theme.breakpoints.up("xs")]: {
    background: "white",
    width: "256px",
  },
  [theme.breakpoints.up("xl")]: {
    width: drawerWidth,
    background: " linear-gradient(90deg, #543AB4 34%, white 22%)",
  },
  [theme.breakpoints.up("lg")]: {
    width: drawerWidth,
    background: " linear-gradient(90deg, #543AB4 34%, white 22%)",
  },
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
    background: " linear-gradient(90deg, #543AB4 34%, white 22%)",
  },
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
    background: " linear-gradient(90deg, #543AB4 34%, white 22%)",
  },

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: "white",

  overflowX: "hidden",
});

/**
 * Custom breakpoints en px
 */
const customBreakpoints = {
  xs: 375,
  sm: 769,
  md: 960,
  lg: 1280,
  xl: 2300,
};

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.lefavingScreen,
  }),

  overflowX: "hidden",

  [`@media (max-width: ${customBreakpoints.xl}px)`]: {
    width: "95px",
    backgroundColor: "#543AB4",
  },
  [`@media (max-width: ${customBreakpoints.lg}px)`]: {
    width: "95px",
    backgroundColor: "#543AB4",
  },
  [`@media (max-width: ${customBreakpoints.md}px)`]: {
    width: "95px",
    backgroundColor: "#543AB4",
  },
  [`@media (max-width: ${customBreakpoints.sm}px)`]: {
    width: "256px",
    backgroundColor: "white",
  },
  [`@media (max-width: ${customBreakpoints.xs}px)`]: {
    width: "187px",
    backgroundColor: "white",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const SideBar = ({ setShowMenu }) => {
  const dispatch = useDispatch();
  const userStatus = useSelector(getUserStatus);

  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;
  const emailUser = userInfo.email;

  const [isDesktop, setIsDesktop] = React.useState(window.innerWidth > 768);

  const [isHD, setHD] = React.useState(window.innerHeight > 1079);

  const [routeHome, setRouteHome] = React.useState("");
  //Modal
  const [isOpenModalInfo, setIsOpenModalInfo] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalContent, setModalContent] = React.useState("");

  const [listMenuOptionsIcons, setListMenuOptionsIcons] = React.useState([]);

  //Token temcore

  const [open, setOpen] = React.useState(false);

  /**
   * Lista de rutas de home disponibles
   */
  const homeRoutes = [
    "/user/home",
    "/user/homeBillCE",
    "/user/homePayrollCEN",
    "/user/homeEmissionCEN",
    "/user/homeReceptionCEN",
  ];

  /**
   * Lista de rutas de home que no son la de ventas e inventarios
   */
  const othersHome = [
    "/user/homeBillCE",
    "/user/homePayrollCEN",
    "/user/homeEmissionCEN",
    "/user/homeReceptionCEN",
  ];

  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    role = jwtDecodeTokenInfo["custom:Role"];
  }

  /**
   * Función identificar si la url es de un home
   * @param {*} url url a revisar
   * @returns
   */
  const excludeUrl = (url = "", routes) => {
    for (const urlHome of routes) {
      if (url?.includes(urlHome)) {
        return true;
      }
    }
    return false;
  };
  /**
   * Función para actualizar el estado de isDesktop y isHD
   * basado en el tamaño de la ventana del navegador
   */
  const updateMedia = () => {
    setHD(window.innerHeight > 1079);

    setIsDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect to update the state of isDesktop based on the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Función para ocultar el menú lateral
   */
  const handleDrawerShow = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userStatus === "idle") {
          dispatch(fetchUser());
        }
        if (userInfo) {
          let combinedList = [];
          if (role === "user") {
            combinedList = [
              {
                menuOptionName: "Página principal",
                nameEnglish: "Home",
              },
              ...userInfo.lstMenu,
            ];
            setListMenuOptionsIcons(combinedList);
            getHomeRoute(userInfo.lstMenu);
          } else {
            combinedList = [
              {
                menuOptionName: "Página principal",
                nameEnglish: "Home",
              },
              ,
              {
                nameEnglish: "Managment",
                menuOptionName: "Administrar",
              },
            ];
            setListMenuOptionsIcons(combinedList);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [userStatus, dispatch]);

  /**
   * Función para obtener la ruta de inicio
   * @param {*} menu lista de opciones de menú contratadas
   */
  const getHomeRoute = (menu) => {
    let routeFind = "";
    if (findHomeSalesStock(menu)) {
      routeFind = "/home";
    } else {
      routeFind = findFirstOtherHome(menu).replace("/user", "");
    }
    setRouteHome((route) => routeFind);
  };

  /**
   * Función para buscar si se tiene contratado
   * el módulo de Ventas e Inventario
   */
  const findHomeSalesStock = (menu) => {
    for (const optionMenu of menu) {
      if (
        optionMenu.menuOptionName === "Ventas e Inventarios" ||
        optionMenu.nameEnglish === "Sales and Stocks"
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Función para buscar la primera opción de home que no sea la de ventas e inventarios
   * @param {*} menu lista de opciones de menú contratadas
   */
  const findFirstOtherHome = (menu) => {
    let urlHome = "";
    for (const optionMenu of menu) {
      if (urlHome !== "") {
        break;
      } else {
        for (const option of optionMenu.levels) {
          if (
            option?.url?.includes("/user/home") &&
            excludeUrl(option?.url, othersHome)
          ) {
            urlHome = `${option?.url?.split("user")[1]}`;
            break;
          } else {
            urlHome = "/home";
          }
        }
      }
    }
    return urlHome !== "" ? urlHome : "/home";
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={"drawer__" + (open ? "open" : "close")}
        open={open}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Button
            disableRipple
            style={{ height: isHD ? "10rem" : "6rem" }}
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
                width: "100%",
              },
            }}
            disabled={isDesktop}
            onClick={handleDrawerShow}
          ></Button>
          {/* 
          
          Componente para el manejor de las opciones de menú para
          el usuario
          */}
          <MenuOptionsComponent
            open={open}
            setOpen={setOpen}
            isDesktop={isDesktop}
            userInfo={userInfo}
            fullNameUser={fullNameUser}
            emailUser={emailUser}
            role={role}
            excludeUrl={excludeUrl}
            homeRoutes={homeRoutes}
            routeHome={routeHome}
            listMenuOptionsIcons={listMenuOptionsIcons}
            setShowMenu={setShowMenu}
            setIsOpenModalInfo={setIsOpenModalInfo}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
          />
        </Grid>
      </Drawer>
      <ModalInfo
        title={modalTitle}
        responseData={modalContent}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
      />
    </Box>
  );
};

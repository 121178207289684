import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { CODES } from "../../consts/codes";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ModalChooseIcon } from "./modalChooseIcon";
import Chip from "@mui/material/Chip";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  GetLevelOptions,
  GetOptionTypes,
  GetOrderFromParent,
  GetParentOption,
  UpdateMenu,
} from "../../services/admin/menuOptionsServices";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import { useEffect } from "react";

export default function ModalEditOptions(props) {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const [open, setOpen] = React.useState(false);

  const [disabledParent, setDisabledParent] = useState(true);

  const [disabledOrder, setDisabledOrder] = useState(true);

  const [levelOptions, setLevelOptions] = useState([]);

  const [parentOptions, setParentOptions] = useState([]);

  const [level, setLevel] = useState(0);

  const [orderOptions, setOrderOptions] = useState([]);

  const [selectedIcon, setSelectedIcon] = React.useState(
    props.menuOption ? props.menuOption.icon : ""
  );

  const [isLoadingParent, setIsLoadingParent] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);

  const [optionTypes, setOptionTypes] = useState([]);

  const { t } = useTranslation();

  const schema = yup.object().shape({
    nameESP: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Este campo solo admite letras"),
    descriptionESP: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Este campo solo admite letras"),
    nameENG: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Este campo solo admite letras"),
    descriptionENG: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Este campo solo admite letras"),
    // url: yup
    //   .string()
    //   .required(t("App.validationMessages.required"))
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     "Ingrese un url valido este debe contener https y www"
    //   ),
    disabledOrder: yup.boolean(),
    disabledParent: yup.boolean(),
    level: yup.number().min(1).required(t("App.validationMessages.required")),
    order: yup.number().when("disabledOrder", {
      is: false,
      then: yup
        .number()
        .required("please input refer number")
        .positive("entry should be greater than 0")
        .integer("input integer value"),
    }),
    // parent: yup.string().when("disabledParent", {
    //   is: false,
    //   then: yup.string().required(t("App.validationMessages.required")),
    // }),
  });

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClickOpen = async () => {
    await fetchData();
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  async function fetchData() {
    let mounted = true;
    try {
      if (mounted) {
        await setOptionType();
        const levelService = await GetLevelOptions();
        if (levelService.status === CODES.COD_RESPONSE_HTTP_OK) {
          if (
            levelService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST
          ) {
            const responseMessage = levelService.data.responseMessage;
            setLevelOptions(responseMessage);

            //Setteamos los valores inciales
            setValue("nameESP", props.menuOption.menuOptionName);
            setValue("descriptionESP", props.menuOption.description);
            setValue("nameENG", props.menuOption.nameEnglish);
            setValue("descriptionENG", props.menuOption.descriptionEnglish);
            setValue("optionType", props.menuOption.idOptionType);
            setValue("url", props.menuOption.url);
            setValue("active", props.menuOption.state);

            if (props.menuOption.level === 1) {
              setDisabledOrder(false);
              setValue("disabledOrder", false);
              setValue("product", props.menuOption.product);
              setValue("level", props.menuOption.level);
              setLevel(props.menuOption.level);
              setValue("parent", "0");
              fetchOrderLevel1();
              setValue("order", props.menuOption.orderMenu);
              setDisabledParent(true);
              setValue("disabledParent", true);
            } else if (props.menuOption.level > 1) {
              setDisabledOrder(false);
              setDisabledParent(false);
              setValue("disabledParent", false);
              setValue("disabledOrder", false);
              setValue("level", props.menuOption.level);
              setLevel(props.menuOption.level);
              setValue("parent", props.menuOption.parent);
              fetchParent(props.menuOption.level);
              fetchOrder();

              setValue("order", props.menuOption.orderMenu);
            }
          }
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }

  const handleEditOption = async (data) => {
    try {
      const obj = {
        pk: props.menuOption.pk,
        sk: props.menuOption.sk,
        idMenuOption: props.menuOption.idMenuOption,
        parent: data.parent === "0" ? "" : data.parent,
        parentMenuOptionName:
          data.parent === "0" ? "" : getParentsName(data.parent),
        menuOptionName: data.nameESP,
        idOptionType: data.optionType === "Default" ? null : data.optionType,
        description: data.descriptionESP,
        icon: selectedIcon,
        url: data.url,
        level: data.level,
        state: data.active,
        orderMenu: data.order,
        productType: data.level === 1 ? (data.product ? 1 : 0) : 0,
        nameEnglish: data.nameENG,

        descriptionEnglish: data.descriptionENG,
        userModification: props.userName,
      };

      const updateMenuService = await UpdateMenu(obj);

      const responseCode = updateMenuService.data.responseCode;

      if (
        updateMenuService.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_HTTP_OK
      ) {
        props.setCreatedOption(!props.createdOption);
        handleClose();
      } else if (responseCode === CODES.COD_RESPONSE_ERROR_UPDATE) {
        props.setModalInfoTitle(t("App.listMenuOptions.errorTitleModalInfo"));
      }

      props.setResponseData(updateMenuService);
      props.openModal(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDelete = () => {
    setSelectedIcon(null);
  };

  const handleLevelChange = (event) => {
    setDisabledOrder(true);
    setDisabledParent(true);
    setValue("disabledParent", true);
    setValue("disabledOrder", true);
    setValue("order", "0");
    setValue("parent", "0");
    const level = event.target.value;
    setLevel(level);
    if (level === 1) {
      setDisabledOrder(false);
      setValue("disabledOrder", false);
      fetchOrderLevel1();
    } else if (level > 1) {
      setDisabledOrder(false);
      setDisabledParent(false);
      setValue("disabledParent", false);
      setValue("disabledOrder", false);
      fetchParent(level);
    }
  };

  const fetchParent = async (data) => {
    setIsLoadingParent(true);
    const obj = {
      level: data,
    };

    try {
      const parentService = await GetParentOption(obj);
      if (parentService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (parentService.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
          const responseMessage = parentService.data.responseMessage;
          setParentOptions(responseMessage);
        }
      }
      setIsLoadingParent(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const fetchOrder = async () => {
    setIsLoadingOrder(true);
    setValue("order", "0");
    const level = watch("level");
    const parent = watch("parent");

    const obj = {
      level: level,
      parent: parent,
    };

    try {
      const orderService = await GetOrderFromParent(obj);
      if (orderService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          orderService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage =
            orderService.data.responseMessage.lstOrdenUpdate;
          setOrderOptions(responseMessage);
        }
      }
      setIsLoadingOrder(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const fetchOrderLevel1 = async () => {
    setIsLoadingOrder(true);
    const obj = {
      level: "1",
      idMenuParent: "",
    };
    setValue("order", "0");
    try {
      const orderService = await GetOrderFromParent(obj);
      if (orderService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          orderService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage =
            orderService.data.responseMessage.lstOrdenUpdate;
          setOrderOptions(responseMessage);
        }
      }
      setIsLoadingOrder(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const getParentsName = (parent) => {
    const parentFound = parentOptions.filter((item) => {
      return item.idMenuOption === parent;
    });
    return parentFound[0].menuOptionName;
  };

  const handleOptionTypeChange = (e) => {
    setValue("optionType", e.target.value);
    console.log(watch("optionType"));
  };

  const setOptionType = async () => {
    const optionTypesService = await GetOptionTypes();

    if (
      optionTypesService.status === CODES.COD_RESPONSE_HTTP_OK &&
      optionTypesService.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      setOptionTypes(optionTypesService.data.responseMessage);
    }
  };

  const disableSubmitButton = () => {
    const nameESP = watch("nameESP");
    const descriptionESP = watch("descriptionESP");
    const nameENG = watch("nameENG");
    const descriptionENG = watch("descriptionENG");
    const level = watch("level");
    const order = watch("order");

    if (
      nameESP &&
      descriptionESP &&
      nameENG &&
      descriptionENG &&
      level !== "0" &&
      order !== "0"
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div>
      <EditOutlinedIcon
        sx={{ fontSize: 22, color: "#543ab4", cursor: "pointer" }}
        onClick={handleClickOpen}
      />
      <Dialog
        style={{ overflowY: "none !important" }}
        onClose={handleClose}
        aria-labelledby="title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100%",
              maxWidth: "820px",
              maxHeight: "600px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="title"
          className="display-large__primary-one"
          onClose={handleClose}
        >
          {t("App.editOption.title")}
        </BootstrapDialogTitle>
        <DialogContent className="overflow__x__hidden">
          <form onSubmit={handleSubmit(handleEditOption)}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              direction="row"
              spacing={2}
              className="full-width"
            >
              <Grid item xs={12} lg={4}>
                <TextField
                  className={
                    errors.nameESP
                      ? "modalEditOptions__form-input--error"
                      : "modalEditOptions__form-input"
                  }
                  helperText={errors.nameESP ? errors.nameESP.message : ""}
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  id="nameESP"
                  name="nameESP"
                  label={t("App.editOption.nameESP")}
                  {...register("nameESP")}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControl
                  className="full-width"
                  style={{ marginTop: "4.5%" }}
                >
                  <InputLabel
                    className="modalCreateOptions__form__inputLabel"
                    id="demo-simple-select-label"
                    sx={{
                      translate: "0px -5px",
                      transformOrigin: "0px 5px",
                      "&.Mui-focused": {
                        color: "#8C8D9D",
                      },
                    }}
                  >
                    {t("App.createOption.optionType")}
                  </InputLabel>
                  <Select
                    {...register("optionType")}
                    onChange={handleOptionTypeChange}
                    className={
                      errors.optionType
                        ? "modalCreateOptions__form-input--error"
                        : "modalCreateOptions__form-input"
                    }
                    label={t("App.createOption.optionType")}
                    defaultValue={
                      props.menuOption
                        ? props.menuOption.idOptionType
                          ? props.menuOption.idOptionType
                          : "Default"
                        : "Default"
                    }
                  >
                    <MenuItem value={"Default"}>
                      <em>Default</em>
                    </MenuItem>

                    {optionTypes.map((item, idx) => {
                      return (
                        <MenuItem value={item.idOptionType}>
                          {item.optionName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextField
                  className={
                    errors.descriptionESP
                      ? "modalEditOptions__form-description--error"
                      : "modalEditOptions__form-description"
                  }
                  helperText={
                    errors.descriptionESP ? errors.descriptionESP.message : ""
                  }
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  id="descriptionESP"
                  name="descriptionESP"
                  label={t("App.editOption.descriptionESP")}
                  {...register("descriptionESP")}
                  type="text"
                />
              </Grid>

              <Grid item xs={12} lg={5}>
                <TextField
                  className={
                    errors.nameENG
                      ? "modalEditOptions__form-input--error"
                      : "modalEditOptions__form-input"
                  }
                  helperText={errors.nameENG ? errors.nameENG.message : ""}
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  id="nameENG"
                  name="nameENG"
                  label={t("App.editOption.nameENG")}
                  {...register("nameENG")}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <TextField
                  className={
                    errors.descriptionENG
                      ? "modalEditOptions__form-description--error"
                      : "modalEditOptions__form-description"
                  }
                  helperText={
                    errors.descriptionENG ? errors.descriptionENG.message : ""
                  }
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  id="descriptionENG"
                  name="descriptionENG"
                  label={t("App.editOption.descriptionENG")}
                  {...register("descriptionENG")}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={
                    errors.url
                      ? "modalEditOptions__form-description--error"
                      : "modalEditOptions__form-description"
                  }
                  helperText={errors.url ? errors.url.message : ""}
                  InputLabelProps={{
                    style: {
                      margin: "-5px 0 0 -10px",
                      padding: "0 0 0 5px",
                      color: "#8c8d9d",
                      fontSize: 13,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      margin: "-5px 0 1px 0px",
                      color: "#e52900",
                      fontSize: 10,
                    },
                  }}
                  inputProps={{
                    style: {
                      margin: "-5px 0 0 0",
                      fontSize: 12,
                    },
                  }}
                  id="url"
                  name="url"
                  label={t("App.editOption.url")}
                  {...register("url")}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <FormControl className="u-marginT full-width">
                  <InputLabel
                    className="modalCreateOptions__form__inputLabel"
                    id="demo-simple-select-label"
                    sx={{
                      translate: "0px -5px",
                      transformOrigin: "0px 5px",
                      "&.Mui-focused": {
                        color: "#8C8D9D",
                      },
                    }}
                  >
                    {t("App.createOption.level")}
                  </InputLabel>
                  <Select
                    {...register("level")}
                    onChange={handleLevelChange}
                    className={
                      errors.level
                        ? "modalCreateOptions__form-input--error"
                        : "modalCreateOptions__form-input"
                    }
                    defaultValue={
                      props.menuOption ? props.menuOption.level : "0"
                    }
                  >
                    <MenuItem disabled value="0">
                      <em> {t("App.createOption.level")}</em>
                    </MenuItem>

                    {levelOptions.map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controller
                  name="parent"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl className="u-marginT full-width">
                      <InputLabel
                        className="modalCreateOptions__form__inputLabel"
                        id="demo-simple-select-label"
                        sx={{
                          translate: "0px -5px",
                          transformOrigin: "0px 5px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        }}
                      >
                        {t("App.createOption.parentOption")}
                      </InputLabel>
                      <Select
                        className={
                          errors.parent
                            ? "modalCreateOptions__form-input--error"
                            : "modalCreateOptions__form-input"
                        }
                        disabled={disabledParent}
                        value={value}
                        onChange={(e) => {
                          setValue("parent", e.target.value);
                          fetchOrder();
                        }}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              "&.MuiInputAdornment-positionEnd": {
                                marginRight: "5%",
                              },
                            }}
                          >
                            <React.Fragment>
                              {isLoadingParent ? (
                                <CircularProgress
                                  sx={{ color: "#4DCAFA" }}
                                  size={15}
                                />
                              ) : null}
                            </React.Fragment>
                          </InputAdornment>
                        }
                        defaultValue={
                          props.menuOption
                            ? props.menuOption.parent
                              ? props.menuOption.parent
                              : "0"
                            : "0"
                        }
                      >
                        <MenuItem disabled value={"0"}>
                          <em>{t("App.createOption.parentOption")}</em>
                        </MenuItem>

                        {parentOptions.map((item, idx) => {
                          return (
                            <MenuItem key={idx} value={item.idMenuOption}>
                              {item.menuOptionName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controller
                  name="order"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl className="u-marginT full-width">
                      <InputLabel
                        className="modalCreateOptions__form__inputLabel"
                        id="demo-simple-select-label"
                        sx={{
                          translate: "0px -5px",
                          transformOrigin: "0px 5px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        }}
                      >
                        {t("App.createOption.orderByLevel")}
                      </InputLabel>
                      <Select
                        className={
                          errors.order
                            ? "modalCreateOptions__form-input--error"
                            : "modalCreateOptions__form-input"
                        }
                        disabled={disabledOrder}
                        value={value}
                        onChange={onChange}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              "&.MuiInputAdornment-positionEnd": {
                                marginRight: "5%",
                              },
                            }}
                          >
                            <React.Fragment>
                              {isLoadingOrder ? (
                                <CircularProgress
                                  sx={{ color: "#4DCAFA" }}
                                  size={15}
                                />
                              ) : null}
                            </React.Fragment>
                          </InputAdornment>
                        }
                        defaultValue={
                          props.menuOption
                            ? props.menuOption.orderMenu
                              ? props.menuOption.orderMenu
                              : "0"
                            : "0"
                        }
                      >
                        <MenuItem disabled value={"0"}>
                          <em>{t("App.createOption.orderByLevel")}</em>
                        </MenuItem>

                        {orderOptions.map((item, idx) => {
                          return (
                            <MenuItem key={idx} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4} lg={4}>
                <FormControl>
                  <InputLabel
                    className="modalCreateOptions__form__inputLabel"
                    id="demo-simple-select-label"
                  >
                    {t("App.editOption.status")}
                  </InputLabel>
                  <Select
                    id="state"
                    {...register("active")}
                    className={
                      errors.active
                        ? "modalEditOptions__form-input--error"
                        : "modalEditOptions__form-input"
                    }
                    defaultValue={
                      props.menuOption ? props.menuOption.state : ""
                    }
                  >
                    <MenuItem disabled value="empty">
                      <em>{t("App.editOption.status")}</em>
                    </MenuItem>
                    <MenuItem value={"1"}>
                      {t("App.createUser.active")}
                    </MenuItem>
                    <MenuItem value={"0"}>
                      {t("App.createUser.inactive")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={8} className="u-marginT">
                {level === 1 ? (
                  <FormControlLabel
                    control={
                      <Controller
                        defaultValue={
                          props.menuOption
                            ? props.menuOption.productType === 1
                              ? true
                              : false
                            : false
                        }
                        name={"product"}
                        control={control}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={
                      <span className="heading__text-grey">
                        {t("App.createOption.product")}
                      </span>
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <Grid item>
                  <ModalChooseIcon
                    optionName={watch("nameESP")}
                    selectedIcon={selectedIcon}
                    setSelectedIcon={setSelectedIcon}
                  />
                </Grid>
                <Grid item>
                  {selectedIcon && (
                    <Chip label={selectedIcon} onDelete={handleDelete} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="modalCreateOptions__form__actions"
              spacing={2}
            >
              <Grid item>
                <Button onClick={handleClose} className="btn__filled__gradient">
                  {t("App.createOption.cancelButton")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  autoFocus
                  type="submit"
                  disabled={disableSubmitButton()}
                  className={
                    disableSubmitButton()
                      ? "btn__filled__disabled"
                      : "btn__filled__blue"
                  }
                >
                  <CheckOutlinedIcon />
                  {t("App.editOption.createButton")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { Box, Button, Grid, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "12px",
  bgcolor: "white",
  padding: "1rem !important",
  p: 4,
};

const ModalLogOut = ({ isOpen, setIsOpen, resetTimerInactivity }) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Función que cierra el modal y reinicia el timer de Inactividad
   */
  const handleConfirm = () => {
    resetTimerInactivity();
    setIsOpen(false);
  };

  return (
    <div>
      <Modal open={isOpen}>
        <Box sx={style} className="modal__responsive">
          <Grid container direction="column" spacing={1.5}>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item>
                <h2 className="display-large__primary-one">
                  {t("App.inactivityLogOut.title")}
                </h2>
              </Grid>

              <Grid item>
                <p className="label__text-black">
                  {t("App.inactivityLogOut.text")}
                </p>
              </Grid>
            </Grid>

            <Grid
              className="u-marginT"
              container
              item
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="contained"
                  className="button__text-white btn__applyFilter"
                  onClick={handleConfirm}
                >
                  {t("App.inactivityLogOut.buttonText")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalLogOut;

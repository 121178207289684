import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
export const FrequencySummary = ({ frequency, selectedGenerationValue }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      className="grid__container__boxShadow"
      style={{ backgroundColor: "white" }}
    >
      <Grid item>
        <p className="display-small__primary-one">
          {t("App.dynamicPeriodicReport.when.frequencySummary")}
        </p>
      </Grid>

      <Grid item>
        {(frequency === null || frequency === "Diario") && (
          <p className="label__text-default">
            {t("App.specialFilesClient.create.frequencyDaily")}
          </p>
        )}
        {frequency === "Semanal" && (
          <p className="label__text-default">
            {t("App.specialFilesClient.create.frequencyWeekly", {
              week: selectedGenerationValue,
            })}
          </p>
        )}

        {frequency === "Mensual" && (
          <p className="label__text-default">
            {t("App.specialFilesClient.create.frequencyMonthly", {
              month: selectedGenerationValue,
            })}
          </p>
        )}
        {frequency === "Anual" && (
          <p className="label__text-default">
            {t("App.specialFilesClient.create.frequencyAnually", {
              year: selectedGenerationValue,
            })}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

import { Button, Chip, Grid, IconButton, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { FilterLoadMapping } from "../../../components/common/Filters/filterLoadMapping";
import { ModalCreateFieldMapping } from "../../../components/common/Modals/modalCreateFieldMapping";

import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { DataTable } from "../../../components/DataTable/DataTable";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TypeDocState from "../../../components/common/typeDocState";
import {
  FilterMapping,
  GetDetailMapping,
} from "../../../services/user/mapping/mappingService";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalEditFieldMapping } from "../../../components/common/Modals/modalEditFieldMapping";

const FieldLoadMapping = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use State
   */

  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);

  /**
   * Handles
   */

    /**
   * useEffect que carga la lista de cuasales y verifica si el tamaño de la pantalla es mobile
   */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 400)
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  },);


  const handleOpenCreateModal = () => {
    setIsOpenModalCreate(true);
  };
  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.loadMapping.table.placeHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };
  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.loadMapping.table.placeHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "creationDate",
      label: t("App.loadMapping.table.creationDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.loadMapping.table.creationDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "userCreate",
      label: t("App.loadMapping.table.createUser"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "modifiedDate",
      label: t("App.loadMapping.table.modificationDate"),
      options: {
        hint: t("App.loadMapping.table.tooltipLmDate"),
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.loadMapping.table.modificationDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "userModified",
      label: t("App.loadMapping.table.modificationUser"),
      options: {
        hint: t("App.loadMapping.table.tooltipLmUser"),
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "nameIssuer",
      label: t("App.loadMapping.table.issuer"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "eanIssuer",
      label: t("App.loadMapping.table.eanIssuer"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },

    {
      name: "nameReceiver",
      label: t("App.loadMapping.table.receiver"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "eanReceiver",
      label: t("App.loadMapping.table.eanReceiver"),
      options: {
        filter: false,
        searchable: true,
        sort: true,
      },
    },
    {
      name: "typeDocument",
      label: t("App.loadMapping.table.typeDoc.title"),
      options: {
        filter: false,
        searchable: false,
        sort: true,

        customBodyRender: (value) => <TypeDocState value={value} />,
      },
    },
    {
      name: "edit",
      label: t("App.loadMapping.table.edit"),
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
    },
  ];

  /**
   * Configuración para las columnas de la tabla cuando se está cargando la data
   */
  const SkeletonColumns = [
    {
      name: "creationDate",
      label: t("App.loadMapping.table.creationDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.loadMapping.table.creationDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "userCreate",
      label: t("App.loadMapping.table.createUser"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "modifiedDate",
      label: t("App.loadMapping.table.modificationDate"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.loadMapping.table.modificationDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "userModified",
      label: t("App.loadMapping.table.modificationUser"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "nameIssuer",
      label: t("App.loadMapping.table.issuer"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },

    {
      name: "eanIssuer",
      label: t("App.loadMapping.table.eanIssuer"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "nameReceiver",
      label: t("App.loadMapping.table.receiver"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "eanReceiver",
      label: t("App.loadMapping.table.eanReceiver"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "typeDocument",
      label: t("App.loadMapping.table.typeDoc.title"),
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "edit",
      label: t("App.loadMapping.table.edit"),
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
    },
  ];

  /**
   * Skeleton para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonLine = [
    {
      creationDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      userCreate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      modifiedDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      userModified: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      eanIssuer: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      nameIssuer: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      eanReceiver: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      nameReceiver: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      typeDocument: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      edit: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];
  //indica si se está cargando la data
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [unFilteredData, setUnFilteredData] = useState([]);
  // Modal para editar el registro
  const [openModalEdit, setOpenModalEdit] = useState(false);
  // Modal para mostrar el error de los servicios
  const [openErrorModal, setOpenErrorModal] = useState(false);

  // información del registro actual a editar
  const [currentMap, setCurrentMap] = useState({});

  // Almacenar el objeto de los filtros
  const [objFilter, setObjFilter] = useState({});

  // información del filtro
  const [optionsFilter, setOptionsFilter] = useState([]);

  const [hasFilter, setHasFilter] = useState(false);

  const [hasFoundData, setHasFoundData] = useState(false);

  /**UseEffect */

  /**
   *
   * Función para estructurar la data para la tabla
   *
   * @param {Array} data
   * @returns data formateada
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        creationDate: item.creationDate ? item.creationDate : "",
        country: item.country ? item.country : "",
        userCreate: item.createUser ? item.createUser : "",
        modifiedDate: item.modificationDate ? item.modificationDate : "",
        userModified: item.userModification ? item.userModification : "",
        eanIssuer: item.eanSender ? item.eanSender : "",
        nameIssuer: item.sender ? item.sender : "",
        eanReceiver: item.eanReceiver ? item.eanReceiver : "",
        nameReceiver: item.receiver ? item.receiver : "",
        typeDocument: item.documentType ? item.documentType : "",
        fileType: item.fileType ? item.fileType : "",
        delimiter: item.delimiter ? item.delimiter : "",
        dataReading: item.dataReading ? item.dataReading : "",
        storeDetail: item.storeDetail ? item.storeDetail : "",
        fields: item.fields ? item.fields : {},
        edit: (
          <IconButton
            onClick={() => {
              handleOpenModalEdit(item);
            }}
            component="span"
          >
            <EditOutlinedIcon
              sx={{ fontSize: 22, color: "#543ab4", cursor: "pointer" }}
            />{" "}
          </IconButton>
        ),
      };
    });
    return processRows;
  };
  /**
   * Función para abrir el modal para editar el registro
   * @param {*} item registro a editar
   */
  const handleOpenModalEdit = (item) => {
    getDetails({ pk: item.pk, sk: item.sk });
  };

  /**
   * Formatear la información del detalle del mappeo
   */
  const formatInfoDetail = (data) => {
    return {
      pk: data.pk,
      sk: data.sk,
      country: data.country,
      nameIssuer: data.sender,
      eanIssuer: data.eanSender,
      nameReceiver: data.receiver,
      eanReceiver: data.eanReceiver,
      typeDocument: data.documentType,
      fileType: data.fileType,
      delimiter: data.delimiter,
      dataReading: data.dataReading,
      storeDetail: data.storeDetail,
      fields: data.fields,
    };
  };

  /**
   * Obtener la información del detalle del mappeo
   * @param {*} infoMapping información del mappeo a consultar
   */
  const getDetails = async (infoMapping) => {
    try {
      const {
        status,
        data: { responseMessage, responseCode },
      } = await GetDetailMapping(infoMapping);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setCurrentMap(formatInfoDetail(responseMessage));
            setOpenModalEdit(true);
            break;
          default:
            setOpenErrorModal(true);
            break;
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-------------------");
      console.log(error);
      console.log("-------------------");
    }
  };

  /**
   * Función que recibe la i foirmació del filtro y realiza el filtrado de los datos
   * @param {*} data información de los filtros
   */
  const filterData = async (data = null) => {
    try {
      let obj =
        data === null && Object.keys(objFilter).length > 0 ? objFilter : data;
      setOption(obj.countryName);
      // Eliminar la propiedad countryName del objeto para filtrar
      delete obj.countryName;

      setIsLoadingData(true);
      setHasFoundData(true);

      const {
        status,
        data: { responseMessage, responseCode },
      } = await FilterMapping(obj);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setData(setDataStructure(responseMessage));
            setUnFilteredData(setDataStructure(responseMessage));
            break;
          case CODES.COD_NO_INFO_FOUND:
            setHasFoundData(false);
            setData([]);
            break;
          default:
            setOpenErrorModal(true);
            setHasFoundData(false);
            setData([]);
            break;
        }
      } else {
        setOpenErrorModal(true);
        setHasFoundData(false);
        setData([]);
      }
      setIsLoadingData(false);
    } catch (error) {
      setOpenErrorModal(true);
      console.log(" ------------- ERROR FIELD LOAD MAPPING -----------------");
      console.log(error);
      console.log(" ---------------------------------------------------------");
    }
  };

  /**
   * Formatear información para mostrar etiqueta opción de filtro
   */
  const setOption = (country) => {
    const op = [
      {
        value: country,
        label: t("App.loadMapping.filter.country"),
      },
    ];
    setOptionsFilter(op);
  };

  return (
    <Grid container direction="column" rowSpacing={5}>
      <ModalCreateFieldMapping
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        filterData={filterData}
        objFilter={objFilter}
      />
      <ModalEditFieldMapping
        isOpen={openModalEdit}
        setIsOpen={setOpenModalEdit}
        editedItem={currentMap}
        setEditedItem={setCurrentMap}
        filterData={filterData}
      />
      <Grid item>
        <p className="display-large__primary-one">
          {t("App.loadMapping.title")}
        </p>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item 
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            }} >
            <Button
              fullWidth= {isMobile }
              className="btn__filled__blue"
              startIcon={<AddIcon sx={{ fontSize: 22 }} />}
              onClick={handleOpenCreateModal}
            >
              {t("App.loadMapping.create")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ maxWidth: "100% !important" }}>
        <Grid
          container
          direction={"column"}
          rowSpacing={2}
          className="companies_info_container"
        >
          <Grid item>
            <FilterLoadMapping
              filterData={filterData}
              setData={setData}
              setHasFilter={setHasFilter}
              unFilteredData={unFilteredData}
              setObjFilter={setObjFilter}
            />
          </Grid>
          {hasFilter && (
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {optionsFilter.map((option) => {
                  return option.value ? (
                    <Grid item>
                      <Chip
                        label={option.label + ": " + option.value}
                        variant="outlined"
                        sx={{
                          boxShadow: "none !important",
                          border: "1px solid #d4d4d4 !important",
                          marginTop: "1rem !important"
                        }}
                      />
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </Grid>
          )}
          <Grid item className="full-width"
              sx={{
                marginTop: "1rem !important",
              }}
          >
            {hasFilter ? (
              hasFoundData ? (
                <DataTable
                  loading={isLoadingData}
                  data={isLoadingData ? skeletonLine : data}
                  columns={isLoadingData ? SkeletonColumns : columns}
                  skeletonOptions={skeletonOptions}
                  options={options}
                />
              ) : (
                <NoMatchImage />
              )
            ) : (
              <NoSelectionComponent
                type="dynamic"
                text={(
                <Grid
                  sx={{
                    padding:{
                      lg: "0 26% !important",
                      sm: "0 26% !important",
                    },
                  }}
                >
                  {t("App.loadMapping.filter.noSelection")}
                </Grid>
                )}
              />
            )}
          </Grid>
          {/* Modal para fallo de servicios */}
          <ModalInfo
            title={t("App.validationMessages.error")}
            responseData={{
              status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
              data: {
                responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
                responseMessage: (
                  <span>
                    <p> {t("App.validationMessages.systemErrorV2")} </p>
                    <a style={{textDecoration: "none", color: "#6133e4"}} href="https://soporte.cen.biz/">{t("App.validationMessages.link")}</a> 
                  </span>
                ),
              },
            }}
            open={openErrorModal}
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />{" "}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FieldLoadMapping;

import { Card, CardContent, Grid } from "@mui/material";
import ChangeHistoryRoundedIcon from "@mui/icons-material/ChangeHistoryRounded";
import PanoramaFishEyeRoundedIcon from "@mui/icons-material/PanoramaFishEyeRounded";
import { useEffect, useState } from "react";

export const FunnelCard = ({ requestInfo, t }) => {
  /**
   * Mock
   */

  //Este mock es la expectativa de cuerpo final
  const mockTypeOfInvoice = [
    {
      title: "Acuse de recibo de factura electrónica de venta",
      issued: "11,77%",
      pending: "88,23%",
      color: "#0FBBF1",
    },
    {
      title: "Recibo del bien o prestación del servicio",
      issued: "4,37%",
      pending: "95,63%",
      color: "#8D51DA",
    },
    {
      title: "Aceptación expresa",
      issued: "2,59%",
      pending: "95,41%",
      color: "#89C758",
    },
    {
      title: "Reclamo de factura electrónica de venta",
      issued: "0,05%",
      pending: "99,95%",
      color: "#FCB045",
    },
    {
      title: "Aceptación táctica",
      issued: "0,00%",
      pending: "100%",
      color: "#F3D63B",
    },
  ];

  const mockValueBlocks = [
    { typeOfInvoice: "TOTAL", value: "850" },
    { typeOfInvoice: "FEV", value: "19,420" },
    { typeOfInvoice: "PDS", value: "7,214" },
    { typeOfInvoice: "AE", value: "4,273" },
    { typeOfInvoice: "RFE", value: "84" },
    { typeOfInvoice: "AT", value: "0" },
  ];

  /**
   * Componentes Auxiliares
   */

  //Componente de información detalle facturas emitidas pago a crédito
  const InfoComponent = ({ color, title, issued, pending }) => {
    return (
      <Grid container direction="column" rowSpacing={1}>
        <Grid item>
          <Grid container className="col-start" columnSpacing={0.5}>
            <Grid
              item
              style={{
                backgroundColor: color,
                width: "10px",
                height: "10px",
                borderRadius: "2px",
              }}
            ></Grid>
            <Grid item className="col-flex heading__text-default">
              {title}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1} marginLeft={"0.1%"}>
            <Grid item>
              <Grid container columnSpacing={0.3}>
                <Grid item className="col-flex">
                  <ChangeHistoryRoundedIcon sx={{ color: color }} />
                </Grid>
                <Grid item>{issued}</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={0.3}>
                <Grid item className="col-flex">
                  <PanoramaFishEyeRoundedIcon sx={{ color: color }} />
                </Grid>
                <Grid item>{pending}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  //Componente que representa un bloque de la pila visual
  const BlockComponent = ({ typeOfInvoice, value }) => {
    let backgroundColor = "";
    let valueColor = "";

    switch (typeOfInvoice) {
      case "TOTAL":
        backgroundColor = "white";
        valueColor = "#553AB6";
        break;
      case "FEV":
        backgroundColor = "#0FBBF1";
        valueColor = "#262626";
        break;
      case "PDS":
        backgroundColor = "#8D51DA";
        valueColor = "#CFC7ED";
        break;
      case "AE":
        backgroundColor = "#89C758";
        valueColor = "#166534";
        break;
      case "RFE":
        backgroundColor = "#FCB045";
        valueColor = "#9A3412";
        break;
      case "AT":
        backgroundColor = "#F3D63B";
        valueColor = "#854D0E";
        break;

      default:
        break;
    }

    return (
      <Grid
        item
        className="col-flex heading__text-default"
        style={{
          backgroundColor: backgroundColor,
          width: "70px",
          height: "40px",
          color: valueColor,
          border: typeOfInvoice === "TOTAL" ? "1.5px solid #553AB6" : "none",
          borderRadius: typeOfInvoice === "TOTAL" ? "8px 8px 0px 0px" : "none",
        }}
      >
        {value}
      </Grid>
    );
  };

  /**
   * Use State
   */

  const [typeOfInvoiceList, setTypeOfInvoiceList] = useState(mockTypeOfInvoice);

  const [valueBlocksList, setValueBlocksList] = useState(mockValueBlocks);

  /**
   * Use Effect
   */

  useEffect(() => {}, [requestInfo]);
  /**
   * Handles
   */

  const handleBuildTypeOfInvoice = (lstInvoice) => {};

  const handleBuildValueBlockList = (lstValueBlock) => {};

  return (
    <Card className="border-radius">
      <CardContent>
        <Grid container direction="column" rowSpacing={3}>
          <Grid item className="display-small__text-default text-center">
            Cantidad Total Facturas Emitidas Pago a Crédito
          </Grid>
          <Grid item style={{ maxHeight: "28rem" }}>
            <Grid container columnSpacing={2}>
              <Grid item md={3.7}>
                <Grid
                  container
                  direction={"column"}
                  className="grid-funnel col-flex"
                >
                  {valueBlocksList.map((valueBlock) => (
                    <BlockComponent
                      typeOfInvoice={valueBlock.typeOfInvoice}
                      value={valueBlock.value}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" rowSpacing={1}>
                  {typeOfInvoiceList.map((invoiceType, idx) => (
                    <>
                      <Grid item>
                        <InfoComponent
                          title={invoiceType.title}
                          issued={invoiceType.issued}
                          pending={invoiceType.pending}
                          color={invoiceType.color}
                        />
                      </Grid>
                      {idx !== typeOfInvoiceList.length - 1 && (
                        <Grid item>
                          <hr style={{ opacity: "0.4" }} />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={2} className="col-flex">
              <Grid item>
                <Grid container columnSpacing={0.3}>
                  <Grid item className="col-flex">
                    <ChangeHistoryRoundedIcon
                      sx={{ color: "#D4D4D4" }}
                      fontSize="large"
                    />
                  </Grid>
                  <Grid item className="col-flex heading__text-default">
                    Facturas Emitidas
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container columnSpacing={0.3}>
                  <Grid item className="col-flex">
                    <PanoramaFishEyeRoundedIcon
                      sx={{ color: "#D4D4D4" }}
                      fontSize="large"
                    />
                  </Grid>
                  <Grid item className="col-flex heading__text-default">
                    Pendiente eventos
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

import { Button, Container, Grid } from "@mui/material";
import Layout from "../../components/layout/layout";
import NotFoundIconMd from "../../assets/common/notfoundmd.svg";
import NotFoundIconSm from "../../assets/common/notfoundsm.svg";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

const NotFound = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Handles
   */

  const getRole = () => {
    const jwtTokenInfo = localStorage.getItem("infoToken");

    let role = "";
    if (jwtTokenInfo) {
      const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

      role = jwtDecodeTokenInfo["custom:Role"];
    }
    return role;
  };
  const handleClickBack = () => {
    const role = getRole();
    if (role) {
      navigate(`/${role}/home`);
    } else {
      navigate("/");
    }
  };
  return (
    <div>
      {getRole() ? <Layout /> : <></>}
      <Container maxWidth="false" className="container__page">
        <Grid container>
          <Grid item container className="notfound-container">
            {isDesktop ? (
              <img src={NotFoundIconMd} alt="Not Found Icon" />
            ) : (
              <img
                src={NotFoundIconSm}
                style={{ marginTop: "25%" }}
                alt="Not Found Icon"
              />
            )}
          </Grid>
          <Grid
            item
            container
            className="notfound-container"
            style={{ paddingTop: "1.7%" }}
          >
            <p className="notfound-text" style={{ fontWeight: "500" }}>
              <span style={{ fontWeight: "700" }}>
                {t("App.notFound.infoExpression")}
              </span>{" "}
              {t("App.notFound.infoText")}
            </p>
          </Grid>
          <Grid
            item
            container
            className="notfound-container"
            style={{ paddingTop: "2%", marginBottom: "10%" }}
          >
            <Button
              className="back-button"
              startIcon={<ArrowBackIcon />}
              onClick={handleClickBack}
            >
              {t("App.notFound.buttonBack")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NotFound;

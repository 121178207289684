import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
export const ToolTipPeriodSummary = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.grouping === null || props.grouping === "0") {
      const endDateNoFormat = moment().subtract(1, "days");
      const endDate = endDateNoFormat.format("DD-MM-YYYY");
      props.setEndDate(endDate);

      const daySubstractPeriods = endDateNoFormat.subtract(
        props.amountOfPeriods,
        "days"
      );
      const startDate = moment(daySubstractPeriods)
        .add(1, "days")
        .format("DD-MM-YYYY");

      props.setStartDate(startDate);
    } else if (props.grouping === "1") {
      const endDateNoFormat = moment().endOf("isoWeek").subtract(1, "week");
      const endDate = endDateNoFormat.format("DD-MM-YYYY");
      props.setEndDate(endDate);

      const weeksBefore = endDateNoFormat.subtract(
        props.amountOfPeriods,
        "week"
      );
      const startDate = moment(weeksBefore)
        .startOf("isoWeek")
        .format("DD-MM-YYYY");

      props.setStartDate(startDate);
    } else if (props.grouping === "2") {
      const endDateNoFormat = moment().subtract(1, "month").endOf("month");
      const endDate = endDateNoFormat.format("DD-MM-YYYY");
      props.setEndDate(endDate);
      const monthsBefore = endDateNoFormat.subtract(
        props.amountOfPeriods - 1,
        "month"
      );
      const startDate = moment(monthsBefore)
        .startOf("month")
        .format("DD-MM-YYYY");
      props.setStartDate(startDate);
    } else if (props.grouping === "3") {
      const endDateNoFormat = moment().subtract(1, "year").endOf("year");
      const endDate = endDateNoFormat.format("DD-MM-YYYY");
      props.setEndDate(endDate);
      const yearsBefore = endDateNoFormat.subtract(
        props.amountOfPeriods - 1,
        "year"
      );
      const startDate = moment(yearsBefore)
        .startOf("year")
        .format("DD-MM-YYYY");
      props.setStartDate(startDate);
    }
  }, [props.grouping, props.amountOfPeriods]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className="grid__container__boxShadow"
      style={{ backgroundColor: "white" }}
    >
      <Grid item>
        <p className="display-small__primary-one">
          {t("App.dynamicPeriodicReport.period.periodSummary")}
        </p>
      </Grid>

      <Grid item>
        {(props.grouping === null || props.grouping === "0") && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.period.summaryDay", {
              startDate: props.startDate,
              endDate: props.endDate,
            })}
          </p>
        )}
        {props.grouping === "1" && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.period.summaryWeek", {
              startDate: props.startDate,
              endDate: props.endDate,
            })}
          </p>
        )}

        {props.grouping === "2" && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.period.summaryMonth", {
              startDate: props.startDate,
              endDate: props.endDate,
            })}
          </p>
        )}
        {props.grouping === "3" && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.period.summaryYear", {
              startDate: props.startDate,
              endDate: props.endDate,
            })}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";

import { FilterContacts } from "../../../services/user/contactsService";
import { CODES } from "../../../consts/codes";
import { NoSelectionComponent } from "../noImages/noSelectionComponent";
import { useNavigate } from "react-router";
import { ModalDecision } from "./modalDecision";
import ModalInfo from "./modalInfo";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import { NoInfoComponent } from "../NoInfoImage/noInfoComponent";

export const ModalContactsReport = ({
  open,
  setOpen,
  setValue,
  watch,
  userInfo,
  setOpenModalError,
}) => {
  /**
   * Skeleton
   */

  // Skeleton tabla para usuarios normales
  const skeletonLine = [
    {
      name: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      lastName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      email: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      phone: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      position: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  // Skeleton de tabla para usuarios de grupo empresarial
  const skeletonLineBusinessGroup = [
    {
      name: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      lastName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      email: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      phone: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      position: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      companyName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * UseTranslation
   */

  const { t } = useTranslation();

  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Table
   */

  // Estructura de la tabla si el usuario no pertenece a un grupo empresarial
  const columns = [
    {
      name: "name",
      label: t("App.userDetail.name"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: t("App.userDetail.lastName"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: t("App.userDetail.email"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "position",
      label: t("App.adminContacts.modals.position"),
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  // Estructura de la tabla si el usuario pertenece a un grupo empresarial
  const columnsBusinessGroup = [
    {
      name: "name",
      label: t("App.userDetail.name"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: t("App.userDetail.lastName"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: t("App.userDetail.email"),
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "position",
      label: t("App.adminContacts.modals.position"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "companyName",
      label: t("App.adminContacts.modals.company"),
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use State
   */

  //Table data
  const [data, setData] = useState([]);

  //Contacts selected
  const [selectedContacts, setSelectedContacts] = useState([]);

  //No Content element
  const [noContent, setNoContent] = useState(false);

  //Modal decision create contact

  const [openModalDecision, setOpenModalDecision] = useState(false);

  //Modal Error
  const [openErrorModal, setOpenErrorModal] = useState(false);

  // Usuario pertenece a un grupo empresarial
  const [isBusinessGroup, setIsBusinessGroup] = useState(false);

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    selectableRowsHeader: "true",
    rowsSelected: selectedContacts,
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSelectedRows(allRowsSelected);
    },
    responsive: "standard",
    searchPlaceholder: !isBusinessGroup
      ? t("App.adminContacts.searchModal")
      : t("App.adminContacts.searchModalBG"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",

    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Effect
   */

  /**
   * Observes accounts form field if field was previosuly filled to check them onto the contacts modal
   * Loads de contacts information
   */
  useEffect(() => {
    setData([]);
    setData(isBusinessGroup ? skeletonLineBusinessGroup : skeletonLine);

    if (userInfo) {
      isBusiness();
    }
  }, [userInfo]);

  /**
   * Observes accounts form field if field was previosuly filled to check them onto the contacts modal
   * Loads de contacts information
   */
  useEffect(() => {
    const fetchContacts = () => {
      setData([]);
      setData(skeletonLine);

      if (userInfo) {
        fetchData();
      }
    };

    const handleCheckAccountsField = () => {
      const emails = watch("accounts") ? watch("accounts") : "";
      const arrayOfEmails = emails.split(",");

      let selectedRows = [];
      arrayOfEmails.map((email) => {
        data.forEach((element, index) => {
          if (email.trim() === element.email) {
            selectedRows.push(index);
          }
        });
      });

      setSelectedContacts(selectedRows);
    };

    handleCheckAccountsField();
    fetchContacts();
  }, [open]);

  /**
   * Obtener si la cuenta del usuario pertenece a un grupo empresarial
   */
  const isBusiness = async () => {
    try {
      const businessService = await ListBusinessGroup({
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        email: userInfo.email,
        typeOption: 1,
      });
      if (
        businessService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setIsBusinessGroup(true);
      }
      fetchData();
    } catch (error) {
      setOpenErrorModal(true);

      console.log("-----------------error------------------");
      console.log(error);
      console.log("------------------------------------");
    }
  };

  /**
   * Handles
   */

  /**
   * Fetch the contacts data
   */
  const fetchData = async () => {
    try {
      fetchDataContacts();
    } catch (error) {
      setOpenModalError(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Obtener lista de contactos de un usuario que no pertenece a un grupo empresarial
   */
  const fetchDataContacts = async () => {
    const getContactsService = await FilterContacts({
      eanCompany: userInfo.company.eanCompany,
      country: userInfo.company.country,
      email: userInfo.email,
    });

    const codeResponse = getContactsService.data.responseCode;
    switch (codeResponse) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        const contacts = getContactsService.data.responseMessage;
        if (contacts.length > 0) {
          const formattedContacts = setDataStructureArray(contacts);
          setData(formattedContacts);
        } else {
          setNoContent(true);
        }
        break;
      case CODES.COD_RESPONSE_ERROR_LOGIN:
        setOpenModalError(true);
        onClose();
        break;
      default:
        setOpenModalError(true);
        onClose();
        break;
    }
  };

  /**
   * Sets data structure for the incoming data based on table data structure
   * @param {*} data array of contacts
   * @returns formatted data
   */
  const setDataStructureArray = (data) => {
    const formattedRows = data.map((contact, index) => {
      return {
        ...contact,
      };
    });
    return formattedRows;
  };

  /**
   * Closes contacts modal and resets selectedContacts value
   */
  const onClose = () => {
    setSelectedContacts([]);
    setOpen(false);
  };

  /**
   * Handles the selected contacts to set them into form
   * @param {*} selectedRows
   */
  const handleSelectedRows = (selectedRows) => {
    const contactsSelected = selectedRows.map((contactIndex) => {
      return contactIndex.dataIndex;
    });

    setSelectedContacts(contactsSelected);
  };

  /**
   * Gets data information from the current index
   * @param {*} index index of the data
   * @returns contact information from current indez
   */
  const handleGetDataByIndex = (index) => {
    return data[index];
  };

  /**
   * Sets the selected contacts into the accounts fields as an string separated by commas
   */
  const handleAddContacts = () => {
    const contactsSelected = selectedContacts.map((contactIndex) => {
      return handleGetDataByIndex(contactIndex);
    });

    let stringContacts = "";
    for (let i = 0; i < contactsSelected.length; i++) {
      let contact = contactsSelected[i];
      if (i + 1 === contactsSelected.length) {
        stringContacts += ` ${contact.email}`;
      } else {
        stringContacts += ` ${contact.email},`;
      }
    }
    setValue("accounts", stringContacts);

    onClose();
  };

  /**
   * Disables Add button based on selectedContacts length
   * @returns true if selectedContacts is empty, false if not
   */
  const isDisabledAdd = () => {
    if (selectedContacts.length > 0 && selectedContacts.length <= 10) {
      return false;
    }
    return true;
  };

  const handleAgree = () => {
    setOpenModalDecision(false);
    onClose();
    navigate("/user/adminContacts");
  };

  const handleDisagree = () => {
    setOpenModalDecision(false);
  };

  return (
    <Dialog
      maxWidth={"md"}
      minWidth={"md"}
      onClose={onClose}
      open={open}
      aria-labelledby="customized-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "2rem",
            overflowY: "visible",
          },
        },
      }}
    >
      <DialogTitleContainer onClose={() => {}}>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.adminContacts.titleModal")}
          </Grid>
        </Grid>
      </DialogTitleContainer>

      <DialogContent className="scroll-table-contacts">
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            {!noContent ? (
              <MUIDataTable
                className="dataTable__salesForce full-width "
                data={data}
                columns={isBusinessGroup ? columnsBusinessGroup : columns}
                options={options}
              />
            ) : (
              // <NoSelectionComponent type="contacts" />
              <NoInfoComponent type={"contact"} />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container marginTop={{ xs: 0, sm: 3, md: 3 }}>
          <Grid item className="col-start" md={6} xs={12} sm={6}>
            <Grid item md={5} xs={12} sm={6} lg={4}>
              <Button
                className="btn__filled__blue-contact"
                startIcon={<AddIcon sx={{ fontSize: 16 }} />}
                onClick={() => {
                  setOpenModalDecision(true);
                }}
              >
                {t("App.adminContacts.modals.create")}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={6}
            lg={6}
            marginTop={{ xs: 1, sm: 0, md: 0 }}
          >
            <Grid
              container
              className={!noContent ? "col-end" : "col-flex"}
              columnGap={2}
              rowSpacing={1}
              justifyContent="flex-end"
            >
              <Grid item md={3} xs={12} sm={3} lg={3}>
                <Button
                  className="btn__filled__gradient-contact"
                  onClick={onClose}
                >
                  {t("App.createUser.cancelButton")}
                </Button>
              </Grid>

              {!noContent && (
                <Grid item md={3} xs={12} sm={6} justifyContent="flex-end">
                  <Button
                    width="100%"
                    type="submit"
                    form="hook-contact"
                    disabled={isDisabledAdd()}
                    className={
                      isDisabledAdd()
                        ? "btn__filled__disabled-contact"
                        : "btn__filled__blue-contact"
                    }
                    startIcon={<AddIcon sx={{ fontSize: 16 }} />}
                    onClick={handleAddContacts}
                  >
                    {t("App.adminContacts.decisionAdd")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>

      <ModalDecision
        isOpen={openModalDecision}
        setIsOpen={setOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
        title={t("App.adminContacts.modalDecisionTitle")}
        message={
          <span>
            <p>{t("App.adminContacts.modalDecisionContent1")}</p>
            <br />
            <p>{t("App.adminContacts.modalDecisionContent2")}</p>
          </span>
        }
        agreeText={t("App.buttonLabels.continue")}
        disagreeText={t("App.buttonLabels.cancel")}
      />

      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: 0,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Dialog>
  );
};

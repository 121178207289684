import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { Controller } from "react-hook-form";
import { TooltipAmountOfPeriods } from "../DynamicReport/tooltipAmountOfPeriods";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

import { GenerationDay } from "./generationDayComponent";
import { useEffect, useState } from "react";
import { FrequencySummary } from "./frequencySummary";

export const InfoGeneration = ({
  control,
  errors,
  setValue,
  frequency,
  setFrequency,
  selectedGenerationValue,
  setSelectedGenerationValue,
  watch,
  isEdit,
}) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Constantes
   * Valores estáticos asociados a los valores básicos del formulario
   */

  const staticFormValues = {
    generationFrequency: [
      {
        label: t("App.dynamicPeriodicReport.when.daily"),
        value: "Diario",
      },
      {
        label: t("App.dynamicPeriodicReport.when.weekly"),
        value: "Semanal",
      },
      {
        label: t("App.dynamicPeriodicReport.when.monthly"),
        value: "Mensual",
      },
      {
        label: t("App.dynamicPeriodicReport.when.yearly"),
        value: "Anual",
      },
    ],
    generationDay: {
      daily: {
        label: t("App.dynamicPeriodicReport.when.noDay"),
        value: "",
      },
      weekly: [
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.monday"),
          value: "Lunes",
        },
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.tuesday"),
          value: "Martes",
        },
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.wednesday"),
          value: "Miércoles",
        },
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.thursday"),
          value: "Jueves",
        },
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.friday"),
          value: "Viernes",
        },
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.saturday"),
          value: "Sabado",
        },
        {
          label: t("App.dynamicPeriodicReport.when.modalChoose.sunday"),
          value: "Domingo",
        },
      ],
    },
    dataGrouping: [
      {
        label: t("App.dynamicPeriodicReport.when.daily"),
        value: "Diario",
      },
      {
        label: t("App.dynamicPeriodicReport.period.isoWeek"),
        value: "Semanal",
      },
      {
        label: t("App.dynamicPeriodicReport.when.monthly"),
        value: "Mensual",
      },
      {
        label: t("App.dynamicPeriodicReport.when.yearly"),
        value: "Anual",
      },
    ],
  };

  /**
   * Use Effect
   */

  /**
   * Settea el valor de typeOfDataGrouping basado en el valor de generationFrequency
   */
  useEffect(() => {
    let generationFrequency = staticFormValues.generationFrequency;
    let dataGrouping = staticFormValues.dataGrouping;

    switch (frequency) {
      case generationFrequency[0].value:
        //Daily
        setValue("typeOfDataGrouping", dataGrouping[0].value);
        break;
      case generationFrequency[1].value:
        //Weekly
        setValue("typeOfDataGrouping", dataGrouping[1].value);
        break;
      case generationFrequency[2].value:
        //Monthly
        setValue("typeOfDataGrouping", dataGrouping[2].value);
        break;
      case generationFrequency[3].value:
        //Yearly
        setValue("typeOfDataGrouping", dataGrouping[3].value);
        break;
    }
  }, [frequency]);

  /**
   * Handles
   */

  /**
   * Settea el valor de generationFrequency cuando este es elegido
   * @param {} value frecuencia elegida
   */
  const handleChangeFrequencyGeneration = (value) => {
    setFrequency(value);
  };
  return (
    <Accordion defaultExpanded={true}>
      <Grid item>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <ReceiptLongOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {t("App.specialFilesClient.create.generateTitle")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails>
        <Grid container direction="row" spacing={2}>
          <Grid item md={8} sm={8} className="generation-col-one">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid md={12} sm={8} item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={2}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.dynamicPeriodicReport.when.frecuency")}
                        </label>
                      </Grid>

                      <Grid item className="label-margin">
                        <Controller
                          name={"generationFrequency"}
                          defaultValue={
                            staticFormValues.generationFrequency[0].value
                          }
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={(e) => {
                                onChange(e);
                                handleChangeFrequencyGeneration(e.target.value);
                              }}
                              className={
                                errors.generationFrequency
                                  ? "modalCreateUsers__form-input--error__medium"
                                  : "modalCreateUsers__form-input__medium"
                              }
                              value={value}
                              id="generationFrequency"
                              name="generationFrequency"
                              labelId="generationFrequency"
                              defaultValue="Diario"
                            >
                              {staticFormValues.generationFrequency.map(
                                (generation) => (
                                  <MenuItem value={generation.value}>
                                    {generation.label}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      // className="generation-day"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.dynamicPeriodicReport.when.generationDay")}
                        </label>
                      </Grid>

                      <Grid item className="label-margin">
                        <Controller
                          name={"generationDay"}
                          defaultValue={
                            staticFormValues.generationDay.daily.value
                          }
                          render={({ field: { onChange, value } }) => (
                            <GenerationDay
                              frequency={watch("generationFrequency")}
                              onChange={onChange}
                              value={value}
                              errors={errors}
                              setValue={setValue}
                              setSelectedGenerationValue={
                                setSelectedGenerationValue
                              }
                              staticFormValues={staticFormValues}
                              isEdit={isEdit}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={1}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className="type-of-group"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.dynamicPeriodicReport.period.typeOfGroup")}
                        </label>
                      </Grid>

                      <Grid item className="label-margin">
                        <Controller
                          name="typeOfDataGrouping"
                          control={control}
                          defaultValue={staticFormValues.dataGrouping[0].value}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              className={
                                errors.typeOfDataGrouping
                                  ? "modalCreateUsers__form-input--error__small"
                                  : "modalCreateUsers__form-input__small"
                              }
                              id="typeOfDataGrouping"
                              name="typeOfDataGrouping"
                              labelId="typeOfDataGrouping"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            >
                              {staticFormValues.dataGrouping.map(
                                (dataGroup) => (
                                  <MenuItem value={dataGroup.value}>
                                    {dataGroup.label}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.dynamicPeriodicReport.period.numberPeriods")}
                        </label>
                      </Grid>

                      <Grid container direction="row" className="label-margin">
                        <Grid item>
                          <Controller
                            name="amountOfPeriodsToGenerate"
                            control={control}
                            defaultValue="1"
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                inputProps={{
                                  style: {
                                    margin: "-5px 0 0 0",
                                    fontSize: 12,
                                  },
                                }}
                                type="number"
                                className={
                                  errors.amountOfPeriodsToGenerate
                                    ? "modalCreateUsers__form-input--error__small"
                                    : "modalCreateUsers__form-input__small"
                                }
                                FormHelperTextProps={{
                                  style: {
                                    margin: "-5px 0 1px 0px",
                                    color: "#e52900",
                                    fontSize: 10,
                                  },
                                }}
                                helperText={
                                  value
                                    ? errors.amountOfPeriodsToGenerate
                                      ? errors.amountOfPeriodsToGenerate.message
                                      : ""
                                    : t("App.validationMessages.greaterZero")
                                }
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item className="col-flex info-periods-tooltip">
                          <TooltipAmountOfPeriods type="specialFiles" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    // className="state-gen"
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      {" "}
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.dynamicPeriodicReport.when.status")}
                        </label>
                      </Grid>
                      <Grid item className="label-margin">
                        <Controller
                          name="state"
                          control={control}
                          defaultValue="1"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              className={
                                errors.state
                                  ? "modalCreateUsers__form-input--error__small"
                                  : "modalCreateUsers__form-input__small"
                              }
                              defaultValue="1"
                              id="state"
                              name="state"
                              onChange={onChange}
                              value={value}
                            >
                              <MenuItem value="1">
                                <em>
                                  {t("App.dynamicPeriodicReport.when.active")}
                                </em>
                              </MenuItem>

                              <MenuItem value="0">
                                <em>
                                  {t("App.dynamicPeriodicReport.when.inactive")}
                                </em>
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid md={4} sm={4} item className="col-flex freq-summ">
            <FrequencySummary
              frequency={watch("generationFrequency")}
              selectedGenerationValue={selectedGenerationValue}
            />
          </Grid>

          <Grid md={7.5} item className="tooltip-gen-info">
            <Grid container direction="row">
              <Grid item md={0.8} sm={1} xs={2}>
                <IconButton disabled>
                  <InfoOutlinedIcon
                    sx={{ fontSize: "22px", color: "#543AB4" }}
                  />
                </IconButton>
              </Grid>

              <Grid md={11} sm={11} xs={10} item className="col-flex">
                <Grid container direction="column">
                  <p>
                    <span className="label__primary-one">
                      {t("App.specialFilesClient.create.tooltipGenerateOne")}
                    </span>

                    <span className="label__primary-one">
                      {t("App.specialFilesClient.create.tooltipGenerateTwo")}
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

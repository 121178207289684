import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";
import {
  GetCatalogues,
  ListDownloadCatalogue,
} from "../../services/user/catalogueService";

export const catalogueSlice = createSlice({
  name: "catalogue",
  initialState: {
    catalogueLoad: [],
    catalogueLoadStatus: "fetch",
    catalogueDownload: [],
    catalogueDownloadStatus: "fetch",
    error: null,
  },
  reducers: {
    resetCatalogueLoadState(state, action) {
      state.catalogueLoadStatus = "fetch";
    },
    resetCatalogueDownloadState(state, action) {
      state.catalogueDownloadStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCatalogueLoad.pending, (state, action) => {
        state.catalogueLoadStatus = "loading";
      })
      .addCase(fetchCatalogueLoad.fulfilled, (state, action) => {
        state.catalogueLoadStatus = "succeeded";
        state.catalogueLoad = action.payload;
      })
      .addCase(fetchCatalogueLoad.rejected, (state, action) => {
        state.catalogueLoadStatus = "failed";
        state.catalogueLoad = [];
      })
      .addCase(fetchCatalogueDownload.pending, (state, action) => {
        state.catalogueDownloadStatus = "loading";
      })
      .addCase(fetchCatalogueDownload.fulfilled, (state, action) => {
        state.catalogueDownloadStatus = "succeeded";
        state.catalogueDownload = action.payload;
      })
      .addCase(fetchCatalogueDownload.rejected, (state, action) => {
        state.catalogueDownloadStatus = "failed";
      });
  },
});

export const getCatalogueLoad = (state) => state.catalogue.catalogueLoad;
export const getCatalogueLoadStatus = (state) =>
  state.catalogue.catalogueLoadStatus;

export const getCatalogueDownload = (state) =>
  state.catalogue.catalogueDownload;
export const getCatalogueDownloadStatus = (state) =>
  state.catalogue.catalogueDownloadStatus;

export const { resetCatalogueLoadState, resetCatalogueDownloadState } =
  catalogueSlice.actions;

export const fetchCatalogueLoad = createAsyncThunk(
  "catalogue/fetchAllCatalogueLoad",
  async (data) => {
    //TODO: Ajustar el objeto GetCatalogues por integracion de grupo empresarial
    const requestCatalogueLoad = await GetCatalogues({
      ...data,
    });

    if (
      requestCatalogueLoad.status === CODES.COD_RESPONSE_SUCCESS &&
      requestCatalogueLoad.data.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      return requestCatalogueLoad.data.data;
    }
  }
);

//TODO: en esta funcion ajuste el objecto para recibir el objecto para que funione como grupo empresarial
export const fetchCatalogueDownload = createAsyncThunk(
  "catalogue/fetchAllCatalogueDownload",
  async (data) => {
    const requestCatalogueDownload = await ListDownloadCatalogue({
      ...data,
    });

    if (requestCatalogueDownload.status === CODES.COD_RESPONSE_SUCCESS) {
      if (
        requestCatalogueDownload.data.responseCode ===
        CODES.COD_RESPONSE_SUCCESS
      ) {
        return requestCatalogueDownload.data.data;
      }
    }
  }
);

export default catalogueSlice.reducer;

import axios from "axios";
import GetToken from "../../config/getToken";
import { env } from "../../env";

//TODO: Francisco Diaz ajustar el objeto data con los nuevos atributos donde se haga el llamado a ListSpecialFilesClient, el axios ya esta ajustado
export async function ListSpecialFilesClient(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "configurations/special/files/administrations/providers",    
    validateStatus: null,
    data,
    headers: {
      Authorization: token,
    },
  });
}

export async function FilterSpecialFilesClient(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: `configurations/special/files/providers`,
    validateStatus: null,
    data,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateSpecialFilesClient(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "configurations/special/files",
    validateStatus: null,
    data,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditSpecialFilesClient(data) {
  const token = await GetToken();
  return axios({
    method: "PUT",
    baseURL: env.REACT_APP_API_URL,
    url: "configurations/special/files",
    validateStatus: null,
    data,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetSpecialFileClient(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "configurations/special/files/providers/loads",
    validateStatus: null,
    data,
    headers: {
      Authorization: token,
    },
  });
}

import * as React from "react";
import Button from "@mui/material/Button";
import { CODES } from "../../consts/codes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router";
import { LogoutService } from "../../services/auth/authServices";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../pages/userSlice";
import LoginIcon from "@mui/icons-material/Login";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useRef, useState } from "react";
import { resetCurrentCompany } from "../../parts/listUsers/listUsersSlice";

export default function ButtonLogOut() {
  const { t } = useTranslation();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef(null);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      setButtonWidth(width < 210 ? 210 : width); // Establece un ancho mínimo de 210px
    }
  }, [isOpen]);

  const userInfo = useSelector(getUser);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);
    role = jwtDecodeTokenInfo["custom:Role"];
  }

  const handleLogOut = async () => {
    try {
      const service = await LogoutService();

      if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          service.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          service.data.responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST
        ) {
          dispatch(resetCurrentCompany());
          sessionStorage.clear();
          localStorage.clear();

          navigate("/");
        }
      }
    } catch (error) {
      console.log("==============Error handleLogout======================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        ref={buttonRef}
        disableRipple
        variant="contained"
        onClick={handleClick}
        sx={{
          border: "none !important",
          boxShadow: "2px 2px 2px  #E0E0E066 !important",
        }}
      >
        <Grid container direction="row" spacing={1}>
          <Grid item className="col-flex">
            <Avatar sx={{ bgcolor: "#F0ECFF", color: "#553AB6", fontSize: 18 }}>
              {role === "admin"
                ? "A"
                : userInfo &&
                  userInfo.firstName &&
                  userInfo.lastName &&
                  `${userInfo.firstName[0]} ${userInfo.lastName[0]}`}
            </Avatar>
          </Grid>
          {isDesktop ? (
            <Grid item className="col-flex">
              {role === "admin"
                ? t("App.roles.admin")
                : userInfo &&
                  userInfo.firstName &&
                  userInfo.lastName &&
                  `${userInfo.firstName} ${userInfo.lastName}`}
            </Grid>
          ) : (
            <></>
          )}
          <Grid item className="col-flex">
            <ExpandMoreIcon sx={{ fontSize: 20 }}></ExpandMoreIcon>
          </Grid>
        </Grid>
      </Button>

      {isOpen && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: isDesktop ? 0 : `calc(100% - ${buttonWidth}px)`, // Ajusta la posición basada en isDesktop
            bgcolor: "background.paper",
            boxShadow: 1,
            borderTopLeftRadius: 0, // Esquina superior izquierda no redondeada
            borderTopRightRadius: 0, // Esquina superior derecha no redondeada
            borderBottomLeftRadius: '12px', // Esquina inferior izquierda redondeada
            borderBottomRightRadius: '12px', // Esquina inferior derecha redondeada
            zIndex: 1,
            width: buttonWidth, // Usa el ancho del botón
            padding: 1,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            className="logout_container"
            width={"100%"}
          >
            <Grid item>
              <p className="logout_container__textTitle">
                {role === "admin"
                  ? t("App.roles.admin")
                  : userInfo && userInfo.firstName + " " + userInfo.lastName}
              </p>
            </Grid>
            <Grid item pr={1}>
              <p className="logout_container__text">
                {userInfo && userInfo.email}
              </p>
            </Grid>
            <Grid item>
              <p className="logout_container__text__two">
                {userInfo && userInfo.company && userInfo.company.companyName}
              </p>
            </Grid>
            <Grid item sx={{ width: "100%", padding: "1%", marginTop: "2%", marginBottom: "2%" }}>
              <hr style={{ color: "#E0E0E0 !important" }} />
            </Grid>
            <Grid item sx={{ marginLeft: "2.5px" }}>
              <Button
                className="btn__logout"
                startIcon={<SettingsIcon sx={{ color: "#553AB6" }} />}
              >
                {t("App.navBar.settings")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="btn__logout"
                startIcon={<LoginIcon sx={{ color: "#553AB6" }} />}
                onClick={handleLogOut}
              >
                {t("App.navBar.logOut")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

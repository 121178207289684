import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NavigationCard } from "../../components/common/CENCO/NavigationCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../userSlice";

export const IndicatorsCards = ({ excludeHome, setIsLoadingButtons }) => {
  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use State
   */

  // Home contratados
  const [contractedHomes, setContractedHomes] = useState({});

  /**
   * Use Effect
   */

  useEffect(() => {
    setIsLoadingButtons(true);
    if (userInfo) verifiedHome();
  }, [userInfo]);

  // Verificar los homes contratados
  const verifiedHome = () => {
    const lstMenuServices = userInfo.lstMenu;
    // Verificar si lstMenuServices contiene los servicios Ventas e Inventarios
    const hasVentasInventariosService = lstMenuServices.some(
      (menuOption) => menuOption.menuOptionName === "Ventas e Inventarios"
    );
    // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de factura"
    const hasCenFiscalInvoice = lstMenuServices.some(
      (menuOption) => menuOption.menuOptionName === "CEN Fiscal Factura"
    );
    // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de nómina"
    const hasCenFiscalPayroll = lstMenuServices.some(
      (menuOption) => menuOption.menuOptionName === "CEN Fiscal Nómina"
    );

    // Verificar si lstMenuServices contiene el servicio CEN Fiscal Emisión
    const hasCenFiscalEmission = lstMenuServices.some(
      (menuOption) => menuOption.menuOptionName === "CEN Fiscal Emisión"
    );

    // Verificar si lstMenuServices contiene el servicio CEN Fiscal Recepción
    const hasCenFiscalReception = lstMenuServices.some(
      (menuOption) => menuOption.menuOptionName === "CEN Fiscal Recepción"
    );
    const products = {
      invoice: hasCenFiscalInvoice,
      payroll: hasCenFiscalPayroll,
      emission: hasCenFiscalEmission,
      prescriptiva: hasVentasInventariosService,
      reception: hasCenFiscalReception,
    };

    setContractedHomes((contractedHomes) => products);
    setIsLoadingButtons(false);
  };

  /**
   * Lista de botones de Home de navegación
   */
  const indicatorCards = [
    {
      title: t("App.homeDashboard.client.subTitle"),
      excludeHome: "prescriptiva",
      icon: (
        <img
          alt="docStats icon"
          style={{ width: "19px", height: "18px", color: "white" }}
          src={"/assets/insightAccess/docStats.svg"}
        />
      ),
      iconColor: "#FFD37D",
      clickNavigate: "/user/home",
    },
    {
      title: t("App.CENDashboard.billCENClient.subTitle"),
      excludeHome: "invoice",
      icon: (
        <RequestQuoteOutlinedIcon
          style={{ width: "19px", height: "18px", color: "white" }}
        />
      ),
      iconColor: "#3BC7F4",
      clickNavigate: "/user/homeBillCEN",
    },
    {
      title: t("App.CENDashboard.payrollCENClient.subTitle"),
      excludeHome: "payroll",
      icon: (
        <LocalAtmOutlinedIcon
          style={{ width: "19px", height: "18px", color: "white" }}
        />
      ),
      iconColor: "#0fbbf1",
      clickNavigate: "/user/homePayrollCEN",
    },

    {
      title: t("App.homeEmission.subtitleTwo"),
      excludeHome: "emission",
      icon: (
        <LocalAtmOutlinedIcon
          sx={{
            width: "19px",
            height: "18px",
            color: "white",
          }}
        />
      ),
      iconColor: "#3BC7F4",
      clickNavigate: "/user/homeEmissionCEN",
    },
    {
      title: t("App.homeReception.subtitleTwo"),
      excludeHome: "reception",
      icon: (
        <LocalAtmOutlinedIcon
          sx={{
            width: "19px",
            height: "18px",
            color: "white",
          }}
        />
      ),
      iconColor: "#3BC7F4",
      clickNavigate: "/user/homeReceptionCEN",
    },
  ];

  /**
   * Función para manejar la navegación entre pantallas de Home
   */
  const handleClickNavigation = (navigateTo) => {
    navigate(navigateTo);
  };

  return (
    <>
      {Object.keys(contractedHomes).length > 0 ? (
        indicatorCards
          .filter(
            (card) =>
              card.excludeHome !== excludeHome &&
              contractedHomes[card.excludeHome]
          )
          .map((card, index) => (
            <Grid
              item
              key={index}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              width="100%"
            >
              <NavigationCard
                title={card.title}
                icon={card.icon}
                iconColor={card.iconColor}
                handleClick={() => handleClickNavigation(card.clickNavigate)}
              />
            </Grid>
          ))
      ) : (
        <></>
      )}
    </>
  );
};

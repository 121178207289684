import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MenuOptionsLvl2Desktop from "./menuOptionLvl2Desktop";
import MenusOptionsIconsDesktop from "./menuOptionsIconsDesktop";
import MenuOption from "../menuOption";
/**
 * Componente que contiene el menú de opciones para desktop
 *   -------------------------- PROPS ---------------------
 * open: estado para mostrar u ocultar  las opciones de menú nivel 2 o 3
 * setOpen: función que permite abrir o cerrar el menú para ver las opciones de nivel 2 o 3
 * userInfo: información del usuario en sesión
 * listMenuOptionsIcons: lista de opciones de menú con icono
 * fullNameUser: nombre completo del usuario en sesión
 * emailUser: correo del usuario en sesión
 * i18n: objeto con las traducciones
 * role: rol del usuario en sesión
 * setSelectedOption: función que permite seleccionar una opción de menú
 * listMenuOptions: lista de opciones de menú
 * optionsLvl1Admin: opciones de menú para administrador
 * selectedOption: opción de menú seleccionada
 * navigate: función que permite navegar a la ruta seleccionada
 * excludeUrl: función que permite excluir una url de las opciones de menú
 * homeRoutes: rutas de inicio de la aplicación
 * openQlikSense: función que permite abrir Qlik Sense
 * idToken: token de autenticación
 * fetchTeamcore: función que permite iniciar sesión en Teamcore
 * routeHome: ruta de inicio de la aplicación
 */

const MenuDesktopComponent = ({
  open,
  setOpen,
  userInfo,
  listMenuOptionsIcons,
  fullNameUser,
  emailUser,
  i18n,
  role,
  setSelectedOption,
  optionsLvl1Admin,
  selectedOption,
  navigate,
  excludeUrl,
  homeRoutes,
  openQlikSense,
  idToken,
  fetchTeamcore,
  routeHome,
  handleLogOut,
}) => {
  // Para establecer el tamaño de componentes y para el límite de
  // iconos a mostrar
  const [isHD, setIsHD] = useState(window.innerHeight > 800);
  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setIsHD(window.innerHeight > 800);
  };

  /**
   * Función para saber si la longitud de la descripción es mayor a 99 caracteres
   * @param {*} selectedOption opción seleccionada
   * @returns si la longitud de la descripción es mayor a 99 caracteres
   */
  const overFlowLengthDescription = (selectedOption) => {
    let lengthDescription = i18n.language.includes("es")
      ? selectedOption.description.length
      : selectedOption.descriptionEnglish.length;
    return lengthDescription > 99;
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      <Grid item xs={5} md={4.5} lg={4.2} xl={4.2}>
        {/* OPCIONES DE MENÚ CON ICONO */}
        <MenusOptionsIconsDesktop
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setOpen={setOpen}
          role={role}
          listMenuOptions={listMenuOptionsIcons}
          navigate={navigate}
          routeHome={routeHome}
          isHD={isHD}
          handleLogOut={handleLogOut}
        />
      </Grid>

      {open && userInfo && i18n && (
        <Grid xs={7} md={7.5} lg={7.5} xl={7.5} item>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ paddingLeft: "8px" }}
            paddingTop={"1.2rem"}
          >
            {role === "user" && (
              <>
                <Grid
                  item
                  height={
                    overFlowLengthDescription(selectedOption)
                      ? "13.5rem"
                      : "9rem"
                  }
                  rowSpacing={
                    overFlowLengthDescription(selectedOption) ? 1 : 1.2
                  }
                  marginBottom={"0.2rem"}
                >
                  {/**
                   * Título de sección cuando se oprime opción NIVEL 1
                   * */}
                  <Grid
                    container
                    direction="column"
                    alignItems={"start"}
                    rowSpacing={1.5}
                  >
                    <Grid item alignItems={"start"}>
                      <p className="navbar__text">
                        {i18n.language.includes("es")
                          ? selectedOption.menuOptionName
                          : selectedOption.nameEnglish}
                      </p>
                    </Grid>
                    <Grid item height={"5.7rem"}>
                      <p className="navbar__text__description">
                        {i18n.language.includes("es")
                          ? selectedOption.description
                          : selectedOption.descriptionEnglish}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {/* OPCIONES DE MENU NIVEL 2 CON O SIN OPCIONES HIJAS */}
                  <MenuOptionsLvl2Desktop
                    selectedOption={selectedOption}
                    setOpen={setOpen}
                    idToken={idToken}
                    fetchTeamcore={fetchTeamcore}
                    homeRoutes={homeRoutes}
                    fullNameUser={fullNameUser}
                    emailUser={emailUser}
                    excludeUrl={excludeUrl}
                    i18n={i18n}
                    navigate={navigate}
                    openQlikSense={openQlikSense}
                    role={role}
                    isHD={isHD}
                    overFlowLengthDescription={overFlowLengthDescription}
                  />
                </Grid>
              </>
            )}
            {/* MOSTRAR OPCIONES DE MENÚ PARA ADMIN */}
            {role === "admin" && (
              <Grid item>
                <Grid container direction="column" rowSpacing={2}>
                  <Grid item>
                    <p className="navbar__text">
                      {" "}
                      {i18n.language.includes("es")
                        ? "Administración"
                        : "Management"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    height={isHD ? "81rem" : "44rem"}
                    className="scroll-sidebar"
                  >
                    {optionsLvl1Admin.map((item, idx) => (
                      <MenuOption
                        key={idx}
                        idx={idx}
                        item={item}
                        setOpen={setOpen}
                        navigate={navigate}
                        i18n={i18n}
                        fullNameUser={fullNameUser}
                        emailUser={emailUser}
                        openQlikSense={openQlikSense}
                        role={role}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MenuDesktopComponent;

import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";
import { convertFileToBase64 } from "../../../utils/functions";
import { CODES } from "../../../consts/codes";

//CARGA

/**
 * Petición que carga un nuevo master según selección
 * @param {*} data información del maestro
 * @returns respuesta de carga
 */
export async function UploadMastersTradersRequest(data) {
  const token = await getToken();
  const { master, file } = data;

  const formData = new FormData();

  formData.append("file", file);

  const finalData = await convertFileToBase64(
    formData,
    master === "objectives" ? CODES.OBJECTIVES : CODES.PENALTIES,
    data
  );

  return axios({
    method: "POST",
    data: finalData,
    url: `masters/uploads/${master}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Trae la lista de cargas de maestro de comercio
 * @param {*} data información del comercio
 * @returns lista de cargas de maestro seleccionado
 */
export async function GetMastersTraders(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Petición que trae la plantilla del maestro seleccionado
 * @returns plantilla codificada
 */
export async function DownloadTemplateMastersTraders(data) {
  const token = await getToken();
  const { master } = data;

  return axios({
    method: "GET",
    url: `${master}/downloads/templates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Petición que trae la guía del maestro seleccionado
 * @returns guía codificada
 */
export async function DownloadGuideMastersTraders(data) {
  const token = await getToken();
  const { master } = data;
  return axios({
    method: "GET",
    url: `${master}/downloads/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite utilizar el servicio de generación de
 * petición de descarga del maestro seleccionado
 */

export async function GenerateDownloadMastersTraders(data, master) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `${master}/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener los archivos de penalidades o metas descargados
 * @param {*} data // Datos de la compañía y modulo a consultar
 * @returns
 */
export async function ListDownloadMastersTraders(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}
/**
 *
 * Función para consumir el servicio que devuelve la lista de proveedores por país
 *
 * @param {*} data
 * @returns resultado de la petición de axios (lista de proveedores)
 */
export async function GetSuppliersService(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    url: "companies",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite utilizar el servicio de descarga del archivo
 */

export async function DownloadTradersOnDownload(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

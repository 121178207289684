import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function FilterSpecialFilesCTyS(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data,
    url: "configurations/routes/special/files/administrations",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateSpecialFileCTyS(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data,
    url: "configurations/routes/special/files",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditSpecialFileCTyS(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    data,
    url: "configurations/routes/special/files",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

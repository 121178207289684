import "./App.css";

import "./styles/main.scss";
import AppRoutes from "./routes/index";

function App() {
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;

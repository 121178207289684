import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "@mui/material";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import logo from "../../assets/common/logo.svg";
import { useTranslation } from "react-i18next";
import { RecoverPasswordService } from "../../services/auth/authServices";
import { CODES } from "../../consts/codes";
import ModalInfo from "../../components/common/Modals/modalInfo";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

export const RecoverPassword = () => {
  
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailRoute, setEmailRoute] = useState("");

  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t("App.validationMessages.email"))
      .required(t("App.validationMessages.required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRecoverPassword = async (data) => {
    try {
      setIsLoading(true);
      const email = data.email;
      setEmailRoute(email);
      const obj = {
        email: email,
      };
      const service = await RecoverPasswordService(obj);

      setResponseData(service);
      setIsOpenModal(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    if (responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      navigate(`/validateCredentials/email/${emailRoute}`);
      window.location.reload();
    }
  };

  return (
    <div className="recoverPassword__wrap">
      <div className="recoverPassword__leftSide">
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justify="center"
        > <Grid item className="login__head-container-pasword">
           <Grid item xs={7} sm={6} md={4} lg={10} xl={4}>
              <div className="login__logo">
                <img
                  src={logo}
                  alt="Logo Prescriptiva"
                  className="logo-CENAnalíticos"
                />
              </div>
            </Grid>
            </Grid>
          <Grid item>
            <p className="display-large__primary-one">
              {t("App.recoverPassword.leftPanel.mainTitle")}
            </p>
          </Grid>
          <Grid item >
            <p className="subheading__text-black">
              {t("App.recoverPassword.leftPanel.instructionText")}
            </p>
          </Grid>
          <Grid item>
            <div className="recoverPassword__form ">
              <form onSubmit={handleSubmit(handleRecoverPassword)}>
                <Input
                  style={{ display: "block" }}
                  className={
                    errors.email
                      ? "recoverPassword__form-input--error"
                      : "recoverPassword__form-input"
                  }
                  disableUnderline
                  type="email"
                  placeholder={t("App.recoverPassword.leftPanel.email")}
                  {...register("email")}
                />
                {errors.email && (
                  <p className="recoverPassword__form-errorText">
                    {errors.email.message}
                  </p>
                )}
                <br />
                <Grid>
                  <p>
                    <button
                      disabled={isLoading}
                      className="recoverPassword__form-button"
                    >
                      {!isLoading ? (
                        t("App.recoverPassword.leftPanel.button")
                      ) : (
                        <CircularProgress size={16} color="inherit" />
                      )}
                    </button>
                  </p>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="recoverPassword__rightSide">
       <div className="language_selector__pasword" >
        <LanguageSwitcher/>
         </div>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems="center"
          justify="center"
        >
          <Grid item sx={{width:"69%"}}>
            <p className="display-x-large__text-white text-center text-pasword ">
              {t("App.login.leftPanel.welcomeTitle")}
            </p>
          </Grid>


        </Grid>
      </div>
      <ModalInfo
        title={t("App.recoverPassword.title")}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </div>
  );
};

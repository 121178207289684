import { useTranslation } from "react-i18next";

const CommerceState = ({ value }) => {
  const { t } = useTranslation();

  const transformValue = value && !value.$$typeof ? value.toLowerCase() : "";
  let text = "";
  let textColor = "";

  switch (transformValue) {
    case "activo":
      console.log(transformValue);
      text = t("App.homeDashboard.client.donuts.modalCommerce.state.active");
      textColor = "state-colors__primary-color-one";

      break;
    default:
      text = t("App.homeDashboard.client.donuts.modalCommerce.state.inactive");
      textColor = "state-colors__tertiary-color-one";

      break;
  }

  return (
    <p className={`${textColor} text-transform__capitalize word-break__keep`}>
      {text}
    </p>
  );
};

export default CommerceState;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import FilterAdminPurchaseOrders from "../../../components/common/Filters/filterAdminPurchaseOrders";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import ModalInfoRequest from "../../../components/common/Modals/modalInfoRequest";
import ModalSeePurchaseOrderDetail from "../../../components/common/Modals/modalSeePurchaseOrderDetail";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import {
  GetListOrders,
  RequestExceptionOC,
} from "../../../services/user/adminPurchaseOrder/PurcharseOrderProviderService";
import { CODES } from "../../../consts/codes";
import ModalFailService from "../../../components/common/modalFailService";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ChooseCausalComponent from "./ChooseCausalComponent";
import { GetListCausales } from "../../../services/user/adminPurchaseOrder/Causales";

const AdminPurchaseOrders = () => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfoRequest, setIsOpenModalInfoRequest] = useState(false);
  const [responseMessage, setResponseMesssage] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFound, setHasFound] = useState(false);
  const [data, setData] = useState([]);
  const [orderSelected, setOrderSelected] = useState({});
  const [title, setTitle] = useState("");
  const [obj, setObj] = useState({});
  const [selectedCausal, setSelectedCausal] = useState({value:null});
  
  const [openModalEdit, setOpenModalEdit] = useState(false);
  // modal error
  const [openErrorModal, setOpenErrorModal] = useState(false);
  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  

  /**
   * navigate para navegar entre páginas
   */
  const navigate = useNavigate();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);


  // Estado para el registro actual a editar / eliminar
  const [currentOC, setCurrentOC] = useState({
    eanTrader: "",
    trader: "",
    ocDate: "",
    ocNumber: "",
  });

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "eanTrader",
      label: t("App.businessRules.table.eanCommerce"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "trader",
      label: t("App.businessRules.table.commerce"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "ocDate",
      label: t("App.adminPurchaseOrder.table.ocDate"),
      options: {
        hint: t("App.adminPurchaseOrder.table.tooltipOcDate"),
        filter: false,
        searchable: false,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.adminPurchaseOrder.table.ocDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "ocNumber",
      label: t("App.adminPurchaseOrder.table.ocNumber"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "seeDetail",
      label: t("App.consultRoles.table.seeDetails"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];


  /**
   * Configuraciones necesarias para la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    selectableRowsHeader: "true",
    rowsSelected: selectedRows.map((row) => row.index),

    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSelectedRow(allRowsSelected);
    },

    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: false,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",
    // isRowSelectable: (dataIndex) => {
    //   return data[dataIndex].state !== "ACTIVO" ? false : true;
    // },
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    selectableRowsHeader: "true",
    rowsSelected: selectedRows.map((row) => row.index),

    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSelectedRow(allRowsSelected);
    },

    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: false,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",
    // isRowSelectable: (dataIndex) => {
    //   return data[dataIndex].state !== "ACTIVO" ? false : true;
    // },
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Establece el registro actual para editar y abre el modo de edición
   * @param {*} contact contacto a editar
   * @param {*} index posición del contacto en la tabla de datos
   */
  const handleOpenEdit = (order) => {
    setCurrentOC(order);
    setOpenModalEdit(true);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo y isOpenModalInfo, cómo también enviar la info para el modalInfo
   */
  const handleAgree = async () => {
    setIsLoading(true);
    formatDataRequestOC();
    try {
      await requestException();
      await filterData();
      setSelectedRows([])
      setSelectedCausal({})
    } catch (error) {
      console.error("Error en handleAgree:", error);
    } finally {
      setIsLoading(false);
    }
  };


  /**
   * Función para dar formato a la data para la excepción de la orden de compra
   */
  const formatDataRequestOC = () => {
    setOrderSelected(
      orderSelected.map((item) => {
        delete item.seeDetail;
        delete item.trader;
        delete item.ocDate;
        item.username = userInfo.firstName + " " + userInfo.lastName;
        item.lstItemOcExcludeds = [];
        item.idCausal = selectedCausal.value
        return item;
      })
    );
  };

  /**
   * Función para solicitar la excepción de la orden de compra
   */
  const requestException = async () => {
    try {
      const requestService = await RequestExceptionOC(orderSelected);
      if (requestService.status === CODES.COD_RESPONSE_SUCCESS) {
        if (
          requestService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          setResponseMesssage(requestService);
          setTitle(t("App.adminPurchaseOrder.modal.success"));
          setIsOpenModalInfoRequest(true);
          setIsOpenModalDecision(false);
        } else {
          setOpenErrorModal(true);
        }
      } else {
        setOpenErrorModal(true);
        setIsOpenModalDecision(false);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("--------------requestException");
      console.log(error);
      console.log("------------------------------");
    }
    setIsOpenModalDecision(false);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo a false
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  /**
   * Función para colocar el check en el estado de selectedRows cuando la fila es seleccionada
   */
  const handleSelectedRow = (rowsSelected) => {
    // Lógica actualizada para manejar las selecciones de filas
    const validSelectedRows = rowsSelected.filter((row) =>
      data.some((_, i) => i === row.dataIndex)
    );

    setSelectedRows(validSelectedRows);
    getSelectedRows(validSelectedRows);
  };

  /**
   * Función para llamar al servicio de listar ordenes de compras con los filtros
   * que coincida con el filtro
   * @param {*} data
   */
  const filterData = async (data = null) => {
    const {
      status,
      data: { responseCode, responseMessage },
    } = await GetListOrders(
      data === null && Object.keys(obj).length > 0 ? obj : data
    );
    if (status === CODES.COD_RESPONSE_SUCCESS) {
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          setHasFound(true);
          setHasContent(true);
          setData(setDataStructure(responseMessage));
          handleSelectedRow(selectedRows.map(row => ({ dataIndex: row.index })));
          break;

        case CODES.COD_RESPONSE_ERROR_UPLOAD_FILE:
          setHasContent(true);
          setHasFound(false);
          break;

        default:
          setHasContent(false);
          setOpenErrorModal(true);

          break;
      }
    } else {
      setOpenErrorModal(true);
      setHasContent(true);
      setHasFound(false);
    }
  };

  /**
   *
   * Función para estructurar la data para la tabla
   *
   * @param {Array} data
   * @returns data formateada
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        eanTrader: !item.eanTrader ? " - " : item.eanTrader,
        eanProvider: !item.eanProvider ? " - " : item.eanProvider,
        country: !item.country ? " - " : item.country,
        trader: !item.trader ? "-" : item.trader,
        ocDate: !item.ocDate ? "-" : item.ocDate,
        ocNumber: !item.ocNumber ? "-" : item.ocNumber,
        seeDetail: (
          <IconButton
            onClick={() => {
              handleOpenEdit(item);
            }}
            component="span"
          >
            <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
          </IconButton>
        ),
      };
    });

    return processRows;
  };

  /**
   *  Obtener la información de las filas seleccionadas
   * @param {*} rowsSelected
   */
  const getSelectedRows = (rowsSelected) => {
    const indexes = rowsSelected.map((row) => row.dataIndex);
    const list = indexes.map((index) => data[index]);
    setOrderSelected(list);
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <h1 className="display-large-orders__primary-one">
          {t("App.adminPurchaseOrder.title")}
        </h1>
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button
          className="btn__applyFilterComerce"
          startIcon={<RemoveRedEyeOutlinedIcon />}
          onClick={() => navigate("request")}
        >
          {t("App.adminPurchaseOrder.seeRequests")}
        </Button>
      </Grid>
      <Grid
        container
        item
        className="companies_info_container"
        direction="column"
        gap={2}
      >
        <Grid item container xs={12}>
          <Accordion
            className="accordion__filter full-width"
            defaultExpanded={true}
          >
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterAdminPurchaseOrders
                setHasContent={setHasContent}
                setHasFound={setHasFound}
                setData={setData}
                setIsLoading={setIsLoading}
                userInfo={userInfo}
                setOpenErrorModal={setOpenErrorModal}
                setSelectedRows={setSelectedRows}
                setObj={setObj}
                filterData={filterData}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid container item gap={1} className="full-width">
          {hasContent ? (
            hasFound ? (
              <>
                <Grid container item 
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={0.5}
                  rowSpacing={0.5}>
                  <Grid item xl={3} lg={4} sm={7} xs={12}>
                    <ChooseCausalComponent
                      state={selectedCausal}
                      setState={setSelectedCausal}
                      contentModal={t("App.adminPurchaseOrder.modalInfo.content")}
                      isModalView={false}
                      hasSelectedRows={selectedRows.length > 0}
                    />
                  </Grid>
                  <Grid item lg={2} sm={3} xs={12}>
                    <Button
                      className={`${
                        selectedRows.length > 0
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width`}
                      startIcon={<AssignmentOutlinedIcon />}
                      onClick={() => setIsOpenModalDecision(true)}
                      disabled={!selectedRows.length > 0}
                    >
                      {t("App.adminPurchaseOrder.request")}
                    </Button>
                  </Grid>
                  
                </Grid>
                {isLoading ? (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    style={{ marginTop: "5%", display: "none" }}
                    data={[]}
                    columns={columns}
                    options={skeletonOptions}
                  />
                ) : (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    style={{ marginTop: "5%", display: "none" }}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )}
              </>
            ) : (
              <Grid item sx={{ width: "100%" }}>
                <NoMatchImage />
              </Grid>
            )
          ) : (
            <NoSelectionComponent type="purchaseOrder" />
          )}
        </Grid>
      </Grid>
      <br />
      <ModalSeePurchaseOrderDetail
        order={currentOC}
        open={openModalEdit}
        setOpen={setOpenModalEdit}
        userInfo={userInfo}
        setTitle={setTitle}
        setOpenErrorModal={setOpenErrorModal}
        filterData={filterData}
      />
      <ModalDecision
        title={t("App.adminPurchaseOrder.decisionText")}
        message={""}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
        customClass="heading-secondary-modal"
      />
      <ModalInfoRequest
        title={title}
        responseData={responseMessage}
        open={isOpenModalInfoRequest}
        onClose={() => setIsOpenModalInfoRequest(false)}
      />
      {/* Modal para fallo de servicios */}
      <ModalFailService open={openErrorModal} onClose={setOpenErrorModal} />
    </Grid>
  );
};

export default AdminPurchaseOrders;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Button,
  Skeleton,
  Tooltip,
} from "@mui/material";
import FilterAdminPurchaseOrderCommerce from "../../../components/common/Filters/filterAdminPurchaseOrderCommerce";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { useEffect, useState } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ModalSeePurchaseOrderDetailCommerce from "../../../components/common/Modals/modalSeePurchaseOrderDetailCommerce";
import FileState from "../../../components/common/fileState";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  ApproveOCCommerce,
  DeclineOCCommerce,
  GetListRequestOCCommerce,
} from "../../../services/user/adminPurchaseOrder/PurcharseOrderCommerceService";
import { CODES } from "../../../consts/codes";
import ModalFailService from "../../../components/common/modalFailService";

const AdminPurchaseOrderCommerce = () => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [modalDecisionTitle, setModalDecisionTitle] = useState("");
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [modalInfoTitle, setModalInfoTitle] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [hasContent, setHasContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFound, setHasFound] = useState(false);
  const [data, setData] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [orderSelected, setOrderSelected] = useState({});
  const [hasFilter, setHasFilter] = useState(false);
  const [objFilter, setObjFilter] = useState({});
  const [approve, setApprove] = useState(true);
  const [showMultipleSelectHeader, setShowMultipleSelectHeader] = useState(false);

  // Estado para el registro actual a editar / eliminar
  const [currentOC, setCurrentOC] = useState({
    eanTrader: "",
    trader: "",
    ocNumber: "",
    eanProvider: "",
    provider: "",
    requestId: "",
    dateResponse: "",
    lstItemOCExcludes: [],
    country: "",
    userName: "",
    requestState: "",
    idCausal: "",
    checkGeneral: "",
    checkProduct: "",
  });

  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  const userInfo = useSelector(getUser);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);


  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "state",
      label: " ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        setCellProps: (value) => ({
          style: {// Si es "Pendiente", elimina el ancho
            minWidth: value === "Pendiente" ? "0px" : "0px",
          },
        }),
        customBodyRender: (value) => {
          if (value === "Pendiente") {
            return <div></div>;
          } else {
            return (
              <Tooltip
                title={
                  <span style={{ textAlign: "left" }}>
                    <p>{t("App.adminPurchaseOrderCommerce.toolTip")}</p>
                    <p>{t("App.adminPurchaseOrderCommerce.toolTip2")}</p>
                  </span>
                }
                placement={isMobile ? "top-end" : "bottom-end"} // Cambia a "top" para pantallas grandes
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [isMobile
                          ? 0  // No ajustar el desplazamiento horizontal en móviles
                          : isScreenSmall
                          ? -100  // Ajustar el desplazamiento horizontal en tabletas
                          : 0,  // No ajustar el desplazamiento horizontal en pantallas grandes
                        isMobile
                          ? 12  // Ajuste vertical en móviles
                          : isScreenSmall
                          ? -55  // Ajuste vertical en tabletas
                          : -50,  // Ajuste vertical en pantallas grandes
                          ], // Ajusta la distancia del tooltip respecto al icono
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        padding: isMobile ? 4 : 8, // Asegúrate de que no se salga de la pantalla
                      },
                    },
                  ],
                }}
              >
                <InfoOutlinedIcon
                  // onClick={handleLogOut}
                  sx={{ fontSize: 22, color: "#553ab6" }}
                ></InfoOutlinedIcon>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      name: "requestId",
      label: t("App.requestsPurchaseOrder.table.idRequest"),
      options: {
        filter: true,
      },
    },
    {
      name: "requestDate",
      label: t("App.requestsPurchaseOrder.table.requestDate"),
      options: {
        filter: false,
        searchable: false,
        hint: t("App.adminPurchaseOrderCommerce.table.tooltipRequestDate"),
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.requestsPurchaseOrder.table.requestDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "eanProvider",
      label: t("App.adminPurchaseOrderCommerce.filter.eanSupplier"),
      options: { filter: false },
    },
    {
      name: "provider",
      label: t("App.adminPurchaseOrderCommerce.table.supplier"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "ocNumber",
      label: t("App.adminPurchaseOrder.table.ocNumber"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "ocDate",
      label: t("App.adminPurchaseOrder.table.ocDate"),
      options: {
        hint: t("App.adminPurchaseOrder.table.tooltipOcDate"),
        filter: false,
        searchable: false,
        customHeadLabelRender: () => (
          <div>
            <h4>{t("App.adminPurchaseOrder.table.ocDate")}</h4>

            <h5 className="subheading-datatable">
              {t("App.homeDashboard.client.table.columns.formatDate")}
            </h5>
          </div>
        ),
      },
    },
    {
      name: "requestState",
      label: t("App.adminClientUsers.table.state"),
      options: {
        filter: true,
        customBodyRender: (value) => <FileState value={value} />,
      },
    },
    {
      name: "seeDetail",
      label: t("App.consultRoles.table.seeDetails"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const skeletonLine = [
    {
      requestId: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      requestDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      eanProvider: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      provider: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      ocDate: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      ocNumber: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      dateResponse: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      requestState: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      seeDetail: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    selectableRowsHeader: showMultipleSelectHeader,
    search: false,
    searchOpen: true,
    searchPlaceholder: t("App.adminPurchaseOrderCommerce.table.searchable"),
    // dataIndex: conserva el indice de la ubicación original del registro en la respuesta del servicio
    // sin importar el ordenamiento por columna que se realice en la parte visual de la tabla
    rowsSelected: selectedRows.map((row) => row.dataIndex),
    isRowSelectable: (dataIndex) => {
      return data[dataIndex].requestState === "Pendiente";
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSelectedRow(allRowsSelected);
    },

    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    filter: false,
    jumpToPage: true,
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

   /**
   *  verifica si el tamaño de la pantalla es mobile o tablet 
   */
   useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 400);
      setIsScreenSmall(window.innerWidth < 1000);
    };
    handleResize();
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);  

  /**
   * Obtener la lista de solicitudes de excepción de orden de compra
   * cuando se carga la página
   */
  useEffect(() => {
    if (userInfo) {
      getRequestInitials();
    }
  }, [userInfo]);

  /**
   * Para saber si se muestra la opcion de seleccionar todos cuando la data sea solo con state pendiente
   */
  useEffect(() => {
    // Efecto para verificar el estado de los datos
    if (data.length > 0) {
      const allPending = data.every(item => item.requestState === "Pendiente");
      setShowMultipleSelectHeader(allPending);
    } else {
      setShowMultipleSelectHeader(false);
    }
  }, [data]);

  /**
   * Función para obtener la lista de solicitudes de excepción de orden de compra
   * cuando se carga la página
   */
  const getRequestInitials = async () => {
    setIsLoading(true);
    setOrderSelected({});
    setSelectedRows([]);
    await getListRequest({ eanTrader: userInfo.company.eanCompany });
  };

  /**
   * Función para actualizar la lista de solicitudes de excepción cuando se aprueba
   * o rechaza una solicitud
   */
  const updateListRequest = async () => {
    setOrderSelected({});
    setSelectedRows([]);
    hasFilter ? await getListRequest(objFilter) : await getRequestInitials();
  };

  /**
   * Función para obtener la lista de solicitudes de excepción de orden de compra para
   * el comercio
   * @param {*} data // Objeto con los filtros para la búsqueda
   */
  const getListRequest = async (data) => {
    try {
      setData(skeletonLine);
      setOrderSelected({});
      setSelectedRows([]);
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListRequestOCCommerce(data);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setHasFound(true);
            setHasContent(true);
            setData(setDataStructure(responseMessage));
            break;
          case CODES.COD_RESPONSE_ERROR_LIST:
            setOpenErrorModal(true);
            setHasFound(false);
            break;
          default:
            setHasFound(false);
        }
      } else {
        setOpenErrorModal(true);
        setHasFound(false);
      }
      setIsLoading(false);
    } catch (error) {
      setHasFound(false);
      setOpenErrorModal(true);
      console.log("=========== getListRequest ===========");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para estructurar la data para la tabla
   *
   * @param {Array} data
   * @returns data formateada
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      // Si encuentra una solicitud diferente a Pendiente, ocultar el header checkbox
      if (item.requestState !== "Pendiente") {
        setShowMultipleSelectHeader(false);
      }
      return {
        state: item.requestState ?? "Pendiente",
        requestId: !item.requestId ? " - " : item.requestId,
        requestDate: !item.requestDate ? " - " : item.requestDate,
        eanProvider: !item.eanProvider ? " - " : item.eanProvider,
        provider: !item.provider ? "-" : item.provider,
        ocDate: !item.ocDate ? " - " : item.ocDate,
        ocNumber: !item.ocNumber ? " - " : item.ocNumber,
        dateResponse: !item.dateResponse ? " - " : item.dateResponse,
        requestState: !item.requestState ? " - " : item.requestState,
        lstItemOCExcludes: !item.lstItemOCExcludes
          ? []
          : item.lstItemOCExcludes,
        country: !item.country ? " - " : item.country,
        userName: userInfo.firstName + " " + userInfo.lastName,
        eanTrader: !item.eanTrader ? " - " : item.eanTrader,
        trader: !item.trader ? " - " : item.trader,
        idCausal: !item.idCausal ? " - " : item.idCausal,
        checkGeneral: !item.checkGeneral ? " - " : item.checkGeneral,
        checkProduct: !item.checkProduct ? " - " : item.checkProduct,
        seeDetail: (
          <IconButton
            onClick={() => {
              handleOpenEdit(item);
            }}
            component="span"
          >
            {item.requestState === "Pendiente" ? (
              <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
            ) : (
              <RemoveRedEyeOutlinedIcon
                sx={{ fontSize: 22, color: "#543ab4" }}
              />
            )}
          </IconButton>
        ),
      };
    });

    return processRows;
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo y isOpenModalInfo, cómo también enviar la info para el modalInfo
   */
  const handleAgree = async () => {
    formatDataRequestOC();
    setIsLoading(true);
    setIsOpenModalDecision(false);
    approve ? await approveOC([]) : await declineOC([]);
  };

  /**
   * Establece el registro actual para editar y abre el modo de edición
   * @param {*} contact contacto a editar
   */
  const handleOpenEdit = (order) => {
    setCurrentOC(order);
    setOpenModalEdit(true);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo a false
   */
  const handleDisagree = async () => {
    setOpenModalEdit(false);
    setIsOpenModalDecision(false);
  };

  /**
   * Función para colocar el index en el estado de selectedRows cuando la fila es seleccionada
   */
  const handleSelectedRow = (rowsSelected) => {
    setSelectedRows(rowsSelected);
    getSelectedRows(rowsSelected);
  };

  /**
   *  Obtener la información de las filas seleccionadas
   * @param {*} rowsSelected
   */
  const getSelectedRows = (rowsSelected) => {
    const index = rowsSelected.map((row) => row.dataIndex);
    let list = [];
    index.forEach((element) => {
      if (data[element].requestState === "Pendiente") {
        list.push(data[element]);
      }
    });
    setOrderSelected(list);
  };

  /**
   * Función para dar formato a la data para la excepción de la orden de compra
   */
  const formatDataRequestOC = () => {
    setOrderSelected(
      orderSelected.map((item) => {
        delete item.dateResponse;
        delete item.ocDate;
        delete item.requestDate;
        delete item.requestState;
        delete item.seeDetail;
        delete item.index;
        return item;
      })
    );
  };

  /**
   * Función para aprobar la excepción de la orden de compra
   * @param {*} products // Lista de productos a aprobar
   */
  const approveOC = async (products) => {
    try {
      setData(skeletonLine);

      const {
        status,
        data: { responseCode, responseMessage },
      } = await ApproveOCCommerce(orderSelected);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          setModalInfoTitle(responseMessage);

          setIsOpenModalInfo(true);
        } else {
          setOpenErrorModal(true);
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-- error Approve ----");
      console.log(error);
      console.log("---------------------");
    }
    await updateListRequest();
  };

  /**
   * Función para rechazar la excepción de la orden de compra
   * @param {*} products  lista de productos a rechazar
   */
  const declineOC = async () => {
    try {
      setData(skeletonLine);

      const {
        status,
        data: { responseCode },
      } = await DeclineOCCommerce(orderSelected);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          setIsOpenModalInfo(true);
          setModalInfoTitle(t("App.adminPurchaseOrder.modal.decline"));
        } else {
          setOpenErrorModal(true);
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("-- error Delcine ----");
      console.log(error);
      console.log("---------------------");
    }
    await updateListRequest();
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <h1 className="display-large__primary-one">
          {t("App.adminPurchaseOrderCommerce.title")}
        </h1>
      </Grid>

      <Grid
        container
        item
        className="companies_info_container full-width"
        direction="column"
        gap={2}
      >
        <Grid item container xs={12}>
          <Accordion
          sx={{
            boxShadow: "none !important" 
           }}
            className="full-width"
            defaultExpanded={true}
          >
            <AccordionSummary
            className="accordion__filter"
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterAdminPurchaseOrderCommerce
                getListRequest={getListRequest}
                setIsLoading={setIsLoading}
                setOpenErrorModal={setOpenErrorModal}
                userInfo={userInfo}
                setHasFilter={setHasFilter}
                setObjFilter={setObjFilter}
                getRequestInitials={getRequestInitials}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid container item gap={1} className="full-width">
          {!hasContent && (
            <Grid item sx={{ width: "100%" }}>
              <NoInfoComponent type="purchaseOrderCommerce" />
            </Grid>
          )}

          {hasContent && !hasFound && (
            <Grid item sx={{ width: "100%" }}>
              <NoMatchImage />
            </Grid>
          )}

          {hasFound && hasContent && (
            <>
              <Grid container item justifyContent="flex-end" gap={2}>
                <Grid item lg={2} sm={3} xs={12}>
                  <Button
                    className={`${
                      selectedRows.length > 0
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    } full-width`}
                    onClick={() => {
                      setApprove(false);
                      setIsOpenModalDecision(true);
                      setModalDecisionTitle(
                        t("App.adminPurchaseOrderCommerce.declineText")
                      );
                    }}
                    disabled={!selectedRows.length > 0}
                  >
                    {t("App.adminPurchaseOrderCommerce.decline")}
                  </Button>
                </Grid>

                <Grid item lg={2} sm={3} xs={12}>
                  <Button
                    className={`${
                      selectedRows.length > 0
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    } full-width`}
                    onClick={async () => {
                      setApprove(true);
                      setIsOpenModalDecision(true);
                      setModalDecisionTitle(
                        t("App.adminPurchaseOrderCommerce.confirmText")
                      );
                    }}
                    disabled={!selectedRows.length > 0}
                  >
                    {t("App.adminPurchaseOrderCommerce.approve")}
                  </Button>
                </Grid>
              </Grid>
              <MUIDataTable
                className="dataTable__OC datatable-style full-width"
                columns={columns}
                options={options}
                data={data}
              />
            </>
          )}
        </Grid>
      </Grid>

      <br />

      <ModalDecision
        title={modalDecisionTitle}
        message={""}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
      <ModalSeePurchaseOrderDetailCommerce
        order={currentOC}
        open={openModalEdit}
        setOpen={setOpenModalEdit}
        userInfo={userInfo}
        setTitle={"prueba"}
        setOpenErrorModal={setOpenErrorModal}
        approveOC={approveOC}
        declineOC={declineOC}
        updateListRequest={updateListRequest}
      />

      <ModalInfo
        title={modalInfoTitle}
        responseData={""}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          setModalInfoTitle("");
          setSelectedRows([]);
        }}
      />

      {/* Modal para fallo de servicios */}
      <ModalFailService open={openErrorModal} onClose={setOpenErrorModal} />
    </Grid>
  );
};

export default AdminPurchaseOrderCommerce;

import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CODES } from "../../../consts/codes";
import { ListBusinessGroup } from "../../../services/user/businessGroup";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// todo cambiarle el npmbre al componente
const SelectCompaniesAndType = ({
  t,
  setIsBusinessGroupReport,
  setValue,
  handleCleanForm,
  getValues,
  errors,
  control,
  setStartForm,
  isIndividualReport,
  isConsolidatedReport,
  setIsIndividualReport,
  setIsConsolidatedReport,
  setIsBusinessGroup,
  isBusinessGroup,
  setSelectOption,
}) => {
  /**
   * Const
   */

  //Checkbox
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="medium" sx={{ fill: "blue" }} />;
  const uncheckedIcon = (
    <CheckBoxOutlineBlankIcon fontSize="medium" sx={{ fill: "blue" }} />
  );
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Lista de compañías
  const [companyList, setCompanyList] = useState([]);

  //Grupo empresarial
  const [isCompanySelected, setIsCompanySelected] = useState(false);

  //Cargas
  const [isLoading, setIsLoading] = useState(false);

  /**
   * useEffect que consulta las empresas
   */
  useEffect(() => {
    /**
     * Función que trae la data para settear los estados de companyList y eanList
     */
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const obj = {
          country: userInfo.company.country,
          eanProvider: userInfo.company.eanCompany,
          email: userInfo.email,
          typeOption: 1,
        };

        const listService = await ListBusinessGroup(obj);

        const { status, data } = listService;

        if (
          status === CODES.COD_RESPONSE_HTTP_OK &&
          Number(data.responseCode) === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const companyName = data.objectData.map((company) => {
            return {
              nameCompany: company.renameCompany,
              eanCompany: company.eanCompany,
              country: company.country,
            };
          });

          setCompanyList(companyName);
          setIsCompanySelected(false);
          setIsBusinessGroup(true);
          setIsBusinessGroupReport(true);
        } else {
          setValue("lstCompanies", [
            {
              nameCompany: `${userInfo.company.country}-${userInfo.company.companyName}`,
              eanCompany: userInfo.company.eanCompany,
              country: userInfo.company.country,
            },
          ]);
          setIsBusinessGroupReport(false);
          setIsBusinessGroup(false);
          setIsCompanySelected(true);
          setSelectOption(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (userInfo) {
      fetchData();
    }
  }, [userInfo]);

  /**
   * Handles
   */

  /**
   * Resetea el formulario y lo oculta
   */
  const handleResetForm = () => {
    setIsCompanySelected(false);
    setStartForm(false);
    handleCleanForm();
  };

  /**
   * Oculta y resetea el formulario cuando el usuario limpia el campo de empresa
   * @param {*} reason razón del cambio en el campo
   */
  const handleClearCompanyInput = (reason) => {
    if (reason === "clear") handleResetForm();
  };

  /**
   * Aplica cambios sobre el formulario cuando se cierra el campo empresa
   */
  const handleOnCloseCompanyInput = () => {
    const companies = getValues("lstCompanies");

    if (companies.length > 0) {
      setIsCompanySelected(true);
    } else {
      handleResetForm();
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="grid__container__filled__rounded"
    >
      <Grid
        item
        className="full-width"
        lg={4}
        md={10.6}
        sm={11.3}
        xs={10.6}
        mr={1}
      >
        <Controller
          name="lstCompanies"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={isLoading ? true : isBusinessGroup ? false : true}
              noOptionsText={t("App.listUsers.noOptions")}
              disableCloseOnSelect
              className={`${
                errors.lstCompanies
                  ? "select__filters__errors"
                  : "select__filters"
              } full-width autoComplete__filter`}
              options={companyList}
              multiple
              limitTag={1}
              getOptionLabel={(option) => option.nameCompany}
              isOptionEqualToValue={(option, value) => {
                return option.eanCompany === value.eanCompany;
              }}
              onChange={(event, values, reason, detail) => {
                onChange(values);
                setSelectOption(true);
              }}
              onInputChange={(event, value, reason) => {
                handleClearCompanyInput(reason);
                setSelectOption(false);
              }}
              onClose={(event, reason) => {
                handleOnCloseCompanyInput();
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.eanCompany}>
                  <Checkbox
                    icon={icon}
                    className="autoComplete__checkbox"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.nameCompany}
                </li>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder={t(
                      "App.listDynamicPeriodicReport.cen.create.company"
                    )}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? (
                            <CircularProgress
                              sx={{ color: "#4DCAFA" }}
                              size={15}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const limitTags = 1;

                return (
                  <>
                    {value.slice(0, limitTags).map((option, index) => (
                      <Chip
                        className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                        sx={{
                          width: "55%",
                        }}
                        key={index}
                        size="small"
                        label={`${option.nameCompany}`}
                      />
                    ))}
                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              value={value}
            />
          )}
          control={control}
        />
      </Grid>
      {isBusinessGroup && (
        <Grid item lg={0.3} xl={0.3} md={1} sm={0.5} xs={1} mt={0.5}>
          <Tooltip
            title={
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item textAlign={"left"} paddingLeft={1}>
                  <Typography
                    align="left"
                    sx={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    {t(
                      "App.listDynamicPeriodicReport.cen.create.type.tooltip1"
                    )}
                  </Typography>
                </Grid>
                <Grid item textAlign={"left"} paddingLeft={1}>
                  <Typography
                    align="left"
                    sx={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    {t(
                      "App.listDynamicPeriodicReport.cen.create.type.tooltip2"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            }
          >
            <InfoOutlinedIcon sx={{ fontSize: "20px", color: "#543AB4" }} />
          </Tooltip>
        </Grid>
      )}
      {isBusinessGroup && (
        <Grid item lg={2} md={5} sm={3} xs={12} mt={0.5} ml={{ md: 1, sm: 0 }}>
          <Grid container direction="row">
            <Grid item justifyContent={"start"}>
              <FormControlLabel
                control={
                  <Controller
                    defaultValue={false}
                    name={"consolidated"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        onClick={(e) => {
                          setIsIndividualReport(false);
                          setIsConsolidatedReport(true);
                          setSelectOption(true);
                        }}
                        checkedIcon={checkedIcon}
                        checked={isConsolidatedReport}
                        uncheckedIcon={uncheckedIcon}
                      />
                    )}
                  />
                }
                label={
                  <p className={"heading__primary-one"}>
                    {t(
                      "App.listDynamicPeriodicReport.cen.create.type.consolidated"
                    )}
                  </p>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {isBusinessGroup && (
        <Grid item lg={2} md={5} sm={3} xs={12} mt={0.5}>
          <Grid container direction="row" item justifyContent={"start"}>
            <Grid item>
              <FormControlLabel
                control={
                  <Controller
                    defaultValue={false}
                    name={"individual"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        onClick={(e) => {
                          setIsIndividualReport(true);
                          setIsConsolidatedReport(false);
                          setSelectOption(true);
                        }}
                        checkedIcon={checkedIcon}
                        checked={isIndividualReport}
                        uncheckedIcon={uncheckedIcon}
                        sx={{ fill: "blue" }}
                      />
                    )}
                  />
                }
                label={
                  <p className={"heading__primary-one"}>
                    {t(
                      "App.listDynamicPeriodicReport.cen.create.type.individual"
                    )}
                  </p>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectCompaniesAndType;

import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";
import { convertFileToBase64 } from "../../../utils/functions";
import { CODES } from "../../../consts/codes";

//TODO: ajustar el objeto data con los nuevos atributos donde se haga el llamado a GetStores, el axios ya esta ajustado
export async function GetStores(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función permite la descarga del archivo plantilla de puntos de venta
 * @returns Archivo codificado en base64
 */
export async function GetStoresTemplate() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `salepoints/downloads/templates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función permite decargar la guía de puntos de venta
 * @returns Archivo codificado en base64
 */
export async function GetStoresGuide() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `salepoints/downloads/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function UploadStores(data) {
  const token = await getToken();
  const formData = new FormData();
  formData.append("file", data.file);

  const finalData = await convertFileToBase64(formData, CODES.SALESPOINT, data);

  return axios({
    method: "POST",
    url: "masters/uploads/salepoints",
    data: finalData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadStoresFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * TODO
 */
export async function DownloadStoresErrorLog(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads/logs`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que obtener el listado de los comerciantes que tienen registrado venta o inventarios con el fabricante logueado
 * @returns Lista de las comerciantes registrados
 */
export async function GetStoresTradersList() {
  const token = await getToken();

  return axios({
    method: "GET",
    url: `salepoints/traders`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite descargar el archivo de puntos de venta que fue cargado por la pagina web
 * @param {Object} data Contiene los datos del compañia que solicito el archivo
 * @returns
 */
export async function DownloadStoresService(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `salepoints`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite descargar el archivo según la compañía y el tipo de archivo
 * @param {data} Objeto que contiene el id de la compañia y el tipo de archivo
 * @returns Archivo codificado en base 64
 */
export async function GetStoresDownloadedFiles(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadStoresDownloadedFiles(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

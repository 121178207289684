import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ModalInfoMasters = ({ state, text, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  const { t } = useTranslation();

  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <div>
      <Dialog
        className="modal__notification"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <div style={{ padding: "2rem" }}>
          <DialogTitleContainer onClose={handleClose}>
            <ErrorOutlineIcon sx={{ color: "#4dcafa", fontSize: "4rem" }} />
          </DialogTitleContainer>

          <DialogContent className="heading-secondary__md">
            <label className="text-transform__capitalize">
              {state.toLowerCase()}
            </label>

            <p className="default-text_black">{text}</p>
          </DialogContent>

          <DialogActions>
            <div>
              <Button
                variant="contained"
                className="modal-button-action"
                onClick={handleClose}
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalInfoMasters;

import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UploadCatalog } from "../../../services/user/masters/mastersService";
import { CODES } from "../../../consts/codes";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, getUser, getUserStatus } from "../../../pages/userSlice";
import LoopIcon from "@mui/icons-material/Loop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import MUIDataTable from "mui-datatables";
import {
  DownloadCatalogErrorLog,
  DownloadCatalogueFile,
  // GetCatalogues,
  GetCataloguesGuide,
  GetCataloguesTemplate,
  GetPreviewCatalog,
} from "../../../services/user/catalogueService";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";

import { FilterSalesForce } from "../../../components/common/filterSalesForce";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import ModalPreviewCatalogue from "../../../components/common/Modals/modalPreviewCatalogue";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import DownloadTemplate from "../../../components/common/UploadFile/downloadTemplate";

import DownloadGuide from "../../../components/common/UploadFile/downloadGuide";
import UploadFileBG from "../../../components/common/UploadFile/uploadFileBusinessGroup";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  fetchCatalogueLoad,
  getCatalogueLoad,
  getCatalogueLoadStatus,
  resetCatalogueLoadState,
} from "../../../parts/catalogue/catalogueSlice";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../components/common/fileState";
import FilterBusinessGroupUniqueWB from "../../../components/common/Filters/businessGroup/filterUniqueWithoutButton";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import ModalSeeCompanies from "../../../components/common/AdminUsersClient/modalSeeCompanies";
import UploadFile from "../../../components/common/UploadFile/uploadFile";

export const UploadCatalogue = () => {
  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "startDate",
      label: t("App.catalogue.table.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.catalogue.table.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.catalogue.table.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "companies",
      label: t("App.catalogue.table.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.catalogue.table.file"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value.fileName}
              control={
                <a
                  className="default-text__linkBlue__sm"
                  onClick={(event) =>
                    handleDownloadFile(value.fileName, value.pathFileName)
                  }
                >
                  {value.fileName}
                </a>
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FileState
              value={value}
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "errors",
      label: t("App.catalogue.table.errors"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <FormControlLabel
              label=""
              value={value}
              control={
                value.awsUploadLog ? (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) => setOpenErrorModal(true)}
                  >
                    ERROR
                  </a>
                ) : (
                  <a
                    className="default-text__linkBlue__sm"
                    onClick={(event) =>
                      handleDownloadLog(
                        value.logFileName,
                        value.pathLogFileName
                      )
                    }
                  >
                    {value.logFileName}
                  </a>
                )
              }
              onChange={(event) => updateValue(event.target.value)}
            />
          ) : (
            <>{value}</>
          ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Selector
   */

  //Usuario
  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);

  //Catálogos cargados
  const cataloguesLoadStatus = useSelector(getCatalogueLoadStatus);
  const cataloguesLoad = useSelector(getCatalogueLoad);

  // Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use State
   */
  //Data de la tabla
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  //Sin contenido
  const [noCatalogues, setNoCatalogues] = useState(false);

  //Filtros de la tabla
  const [expanded, setExpanded] = useState(false);
  const [filterApply, setFilterApply] = useState(null);

  //Archivo seleccionado
  const [fileSelected, setFileSelected] = useState(null);

  //Catálogo para arbol de preview
  const [catalogueForPreview, setCatalogueForPreview] = useState([]);
  const [loadingPreviewCatalogue, setLoadingPreviewCatalogue] = useState(false);

  //Modal respuesta success de carga catálogo
  const [responseLoadCatalogue, setResponseLoadCatalogue] = useState({});
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);

  //Carga de tabla o refrescar
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //Modal respuesta error carga de catálogo
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);

  //Manejo de comportamientos y datos grupo empresarial
  const [showUpload, setShowUpload] = useState(null);
  const [checkedReplicate, setCheckedReplicate] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * useEffect para traer la información del usuario que inició sesión
   */
  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };

    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  /**
   * useEffect para traer la información del catálogo cargado y mostrarla en la tabla
   */
  useEffect(() => {
    const getCataloguesLoad = async () => {
      try {
        if (cataloguesLoadStatus === "fetch") {
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 1,
          };
          dispatch(fetchCatalogueLoad(obj));
        }

        switch (cataloguesLoadStatus) {
          case "succeeded":
            const finalCatalogues = cataloguesLoad;

            let processedRows = finalCatalogues.map((catalogue, i) => {
              return {
                startDate: !catalogue.starDate
                  ? "  "
                  : moment(catalogue.starDate).format("YYYY-MM-DD hh:mm"),
                endDate: !catalogue.endDate
                  ? "  "
                  : moment(catalogue.endDate).format("YYYY-MM-DD hh:mm"),
                user: !catalogue.userLoad ? "" : catalogue.userLoad,
                companies:
                  catalogue.lstCompanies?.length > 1 ? (
                    <ModalSeeCompanies
                      data={catalogue.lstCompanies}
                      userName={catalogue.userLoad}
                    />
                  ) : (
                    catalogue.lstCompanies !== null &&
                    catalogue.lstCompanies.length > 0 &&
                    catalogue.lstCompanies[0].renameCompany
                  ),
                file: !catalogue.fileName
                  ? " "
                  : {
                      fileName: catalogue.fileName,
                      pathFileName: catalogue.pathFileName,
                    },
                state: !catalogue.state ? " " : catalogue.state,
                errors: catalogue.awsUploadLog
                  ? {
                      awsUploadLog: catalogue.awsUploadLog,
                    }
                  : {
                      logFileName: catalogue.logFileName,
                      pathLogFileName: catalogue.pathLogFileName,
                    },
              };
            });

            setNoCatalogues(processedRows.length > 0 ? false : true);
            setUnfilteredData(processedRows);
            setData(processedRows);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;

          case "loading":
            break;
          case "failed":
            setNoCatalogues(true);
            setLoading(false);
            setIsLoadingRefresh(false);
            break;
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    };

    setLoading(true);

    if (userInfoStatus === "succeeded") {
      getCataloguesLoad();
    } else {
      setIsLoadingRefresh(false);
    }
  }, [dispatch, cataloguesLoadStatus, userInfoStatus]);

  /**
   * useEffect que muestra el componente por default si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [isBusinessGroup]);

  /**
   * useEffect que elimina el archivo previamente cargado si la carga se oculta
   */
  useEffect(() => {
    if (!showUpload) {
      setFileSelected(null);
      setCheckedReplicate(false);
    }
  }, [showUpload]);

  /**
   *
   * Función para cargar el archivo de catálogo en la plataforma
   */
  const handleUploadFile = async () => {
    try {
      const obj = {
        file: fileSelected[0],
        companies: companySelected,
        checkReplicateBusinessGroup: isBusinessGroup
          ? checkedReplicate
            ? 1
            : 0
          : 0,
      };

      const catalogService = await UploadCatalog(obj);
      setCheckedReplicate(false);

      if (catalogService.status === CODES.COD_RESPONSE_HTTP_OK) {
        dispatch(resetCatalogueLoadState());
        setFileSelected(null);
        setResponseLoadCatalogue(catalogService);
        setOpenSuccessLoadModal(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo de catálogo que fue cargado (subido)
   *
   * @param {String} fileName
   * @param {String} pathFileName
   */
  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      pathFileName: pathFileName,
      fileName: fileName,
    };

    try {
      const fileService = await DownloadCatalogueFile(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar el archivo con los errores del archivo cargado (subido)
   *
   * @param {String} logFileName
   * @param {String} pathLogFileName
   */
  const handleDownloadLog = async (logFileName, pathLogFileName) => {
    const obj = {
      pathLogFileName: pathLogFileName,
      logFileName: logFileName,
    };

    try {
      const logService = await DownloadCatalogErrorLog(obj);

      if (logService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (logService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(logService.data.data.fileContent),
            logService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar la plantilla (archivo con la estructura adecuada para la carga)
   *
   * @param {Event} event
   */
  const handleDownloadTemplate = async (event) => {
    event.preventDefault();

    try {
      const templateService = await GetCataloguesTemplate();

      if (templateService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          templateService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(templateService.data.data.fileContent),
            templateService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para descargar la guía de cómo llenar la plantilla
   *
   * @param {Event} event
   */
  const handleDownloadGuide = async (event) => {
    event.preventDefault();

    try {
      const guideService = await GetCataloguesGuide();

      if (guideService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          guideService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(guideService.data.data.fileContent),
            guideService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para refrescar la información mostrada en la tabla
   */
  const handleRefresh = () => {
    setIsLoadingRefresh(true);
    setLoading(true);
    setData([]);
    dispatch(resetCatalogueLoadState());
  };

  /**
   * Función para previsualizar el catálogo de un fabricante en específico
   */
  const handlePreviewCatalogue = async () => {
    setLoadingPreviewCatalogue(true);

    const ean = isBusinessGroup
      ? companySelected.eanCompany
      : userInfo.company.eanCompany;

    const getCatalogue = await GetPreviewCatalog({ eanCompany: ean });

    let catalogue = [];
    if (getCatalogue.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      catalogue = getCatalogue.data.responseMessage;
    }
    setCatalogueForPreview(catalogue);
    setTimeout(handleOpenPreview, 0.1);
  };

  /**
   * Función para abrir la previsualización
   */
  const handleOpenPreview = () => {
    setLoadingPreviewCatalogue(false);
    setOpenModalPreview(true);
  };

  return (
    <>
      <Grid container direction="column" className="generalcat-container">
        <Grid
          container
          direction="row"
          className="u-marginB template-container__general"
          spacing={2}
        >
          <DownloadTemplate
            helpText={t("App.catalogue.templateText")}
            buttonText={t("App.catalogue.templateDownload")}
            handleDownloadTemplate={handleDownloadTemplate}
          />
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Grid
            container
            className="grid__container "
            style={{ marginTop: "0.2%", marginBottom: "1%", padding: "0" }}
            direction="column"
          >
            <Grid item className="guide-file">
              <DownloadGuide
                helpText={"App.salesForce.guide"}
                handleDownloadGuide={handleDownloadGuide}
              />
            </Grid>

            {isBusinessGroup && (
              <Grid item className="filter__company__container">
                <FilterBusinessGroupUniqueWB
                  title={t("App.manageBusinessGroup.filter.title4")}
                  setCompanySelected={setCompanySelected}
                  setShowUpload={setShowUpload}
                />
              </Grid>
            )}
            <Grid item className="upload-file">
              {isBusinessGroup ? (
                showUpload && (
                  <UploadFileBG
                    dragText={t("App.catalogue.dragging")}
                    handleUploadFile={handleUploadFile}
                    fileSelected={fileSelected}
                    setFileSelected={setFileSelected}
                    setErrorModal={setIsOpenErrorModal}
                    setErrorTitle={setErrorTitle}
                    userInfo={userInfo}
                    showUpload={showUpload}
                    setCheckedReplicate={setCheckedReplicate}
                  />
                )
              ) : (
                <UploadFile
                  dragText={t("App.catalogue.dragging")}
                  handleUploadFile={handleUploadFile}
                  fileSelected={fileSelected}
                  setFileSelected={setFileSelected}
                  setErrorModal={setIsOpenErrorModal}
                  setErrorTitle={setErrorTitle}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="row" className="prev-refresh-container">
          <>
            {showUpload && (
              <Button
                disableRipple
                className="btn__filled__blue prev-refresh-buttons__tree"
                onClick={handlePreviewCatalogue}
              >
                {" "}
                {!loadingPreviewCatalogue ? (
                  t("App.catalogue.cataloguePreviewText")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            )}

            <Button
              disableRipple
              disabled={isLoadingRefresh ? true : false}
              startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
              className="btn__outlined__blue prev-refresh-buttons"
              onClick={handleRefresh}
            >
              {!isLoadingRefresh ? (
                t("App.catalogue.update")
              ) : (
                <CircularProgress size={23} color="inherit" />
              )}
            </Button>
          </>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          className="table-title"
        >
          <Grid item className="col-flex">
            <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
          </Grid>

          <Grid item className="col-start">
            <p className="display-small__primary-one">
              {t("App.salesForce.subtitle")}
            </p>
          </Grid>
        </Grid>

        {!noCatalogues && (
          <Grid
            item
            className="u-marginB grid__container__fullW"
            container
            style={{ marginTop: "2%" }}
          >
            <Accordion
              onChange={() => {
                setExpanded(!expanded);
              }}
              className={
                expanded ? "accordion__filter__expanded" : "accordion__filter"
              }
              style={{ width: "100%" }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "#543ab4",
                  }}
                />

                <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                  {" "}
                  {t("App.catalogue.filterName")}
                </p>
              </AccordionSummary>

              <AccordionDetails>
                <FilterSalesForce
                  userInfo={userInfo}
                  unfilteredData={unfilteredData}
                  setData={setData}
                  setFilterApply={setFilterApply}
                  idFile={1}
                  setIsLoadingData={setIsLoadingData}
                  setFilterNotFound={setFilterNotFound}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        <Grid
          item
          className="u-marginB chips-container"
          style={{ width: "100%" }}
        >
          {filterApply && !noCatalogues && (
            <Chip className="chip__filter" label={filterApply.label} />
          )}
        </Grid>

        {!noCatalogues ? (
          <Grid item style={{ width: "100%" }}>
            {loading ? (
              <MUIDataTable
                style={{ width: "100% !important" }}
                data={data}
                columns={columns}
                options={skeletonOptions}
                className="dataTable__salesForce"
              />
            ) : !filterNotFound ? (
              <MUIDataTable
                style={{ width: "100% !important" }}
                data={data}
                columns={columns}
                options={options}
                className="dataTable__salesForce"
              />
            ) : (
              <NoMatchImage />
            )}
          </Grid>
        ) : (
          <Grid container className="col-flex">
            <NoInfoComponent type="load" />
          </Grid>
        )}

        <ModalInfo
          title={t("App.catalogue.title")}
          responseData={responseLoadCatalogue}
          open={openSuccessLoadModal}
          onClose={() => {
            setOpenSuccessLoadModal(false);
          }}
        />

        <ModalPreviewCatalogue
          open={openModalPreview}
          catalogue={catalogueForPreview}
          onClose={() => {
            setOpenModalPreview(false);
          }}
        />

        <ModalInfo
          title={t("App.states.systemError")}
          responseData={{
            data: {
              responseCode: 0,
              responseMessage: t("App.validationMessages.systemError"),
            },
          }}
          open={openErrorModal}
          onClose={() => {
            setOpenErrorModal(false);
          }}
        />

        <ModalInfo
          title={errorTitle}
          responseData={{ status: 0 }}
          open={isOpenErrorModal}
          onClose={() => {
            setIsOpenErrorModal(false);
          }}
        />
      </Grid>
    </>
  );
};

import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";

export async function CancelApplication(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "requests/cancellations",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadLogs(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "requests/downloads/logs",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListApplication(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: "requests",
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import { Grid, IconButton, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { FilterConsultRoles } from "../../../components/common/Filters/filterConsultRoles";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { useEffect, useState } from "react";
import { GetRolesList } from "../../../services/user/consultRolesService";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { CODES } from "../../../consts/codes";
import ModalPreviewMenuRoles from "../../../components/common/ConsultRoles/modalPreviewMenu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const ConsultRoles = () => {
  /**
   * Use state
   */
  const [data, setData] = useState([]);
  const [isNoContent, setIsNoContent] = useState(false);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  //buggs
  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Get the info of the user who login
   */
  const userInfo = useSelector(getUser);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Configurations of the MuiDataTable
   */
  const options = {
    filterType: "dropdown",
    print: false,
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "default",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,

    searchOpen: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        // noMatch: <NoMatchImage />,
        noMatch: <Skeleton />,

        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Configuration of the table columns
   */
  const columns = [
    {
      name: "menuOptionName",
      label: t("App.consultRoles.table.menuOptionName"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "nameRole",
      label: t("App.consultRoles.table.nameRole"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "descriptionRole",
      label: t("App.consultRoles.table.descriptionRole"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "seeDetail",
      label: t("App.consultRoles.table.seeDetails"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  /**
   * useEffect to set the data needed for the table
   */
  useEffect(() => {
    async function fetchData() {
      try {
        if (userInfo) {
          const obj = {
            country: userInfo.company.country,
            eanCompany: userInfo.company.eanCompany,
          };

          const rolesList = await GetRolesList(obj);
          // console.log(rolesList);

          if (
            rolesList.status !== CODES.COD_RESPONSE_SUCCESS ||
            rolesList.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
          )
            return;

          const response = rolesList.data.responseMessage;

          const formated = setDataStructureArray(response);

          setData(formated);

          setUnfilteredData(formated);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
  }, [userInfo.company]);

  /**
   * Sets table data structure for the current data
   *
   * @param {Array} data
   *
   * @returns formated data
   */
  const setDataStructureArray = (data) => {
    const processedRows = data.map((role) => {
      return {
        menuOptionName: role.menuOptionName,
        nameRole: role.nameRole,
        descriptionRole: role.descriptionRole,
        seeDetail: <ModalPreviewMenuRoles role={role} />,
      };
    });

    // console.log(processedRows);

    return processedRows;
  };

  const handleBackManageReload = () => {
    navigate("/user/adminUsers");
  };

  return (
    <>
      <Grid container direction="column" rowGap={4}>
        <Grid item width={"100%"} sx={{ marginTop: "2%" }}>
          <p className="display-large__primary-one">
            {t("App.consultRoles.title")}
          </p>
        </Grid>

        <Grid item>
          <Grid container direction="row" columnSpacing={2}>
            <Grid item xs={1.9} xl={0.3} lg={0.5} sm={0.9}>
              <IconButton
                className="btn__filled__blue"
                disableRipple
                onClick={handleBackManageReload}
                sx={{ borderRadius: "4px" }}
              >
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item className="col-start" xs={10}>
              <p className="display-small__moderate-blue">
                {t("App.consultRoles.goBack")}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />

      <Grid
        container
        style={{ padding: "15px", marginBottom: "15px" }}
        className="companies_info_container full-width"
        direction="column"
      >
        <Grid item style={{ marginBottom: "40px" }}>
          <FilterConsultRoles
            setDataStructureArray={setDataStructureArray}
            setIsNoContent={setIsNoContent}
            unfilteredData={unfilteredData}
            setData={setData}
          />
        </Grid>

        <Grid item className="full-width">
          {!isNoContent ? (
            <MUIDataTable
              className="dataTable__base"
              data={data}
              columns={columns}
              options={options}
            />
          ) : (
            <NoMatchImage />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ConsultRoles;

import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import loginImageLeftSide from "../../assets/common/loginImageLeftSide.svg";
import dotPattern from "../../assets/common/dotPattern.svg";

const LoginImage = () => {
  const { t } = useTranslation();

  return (
    <Grid className="loginImage" xl={12}>
      <img
        src={loginImageLeftSide}
        alt="Login Image"
        className="loginImage_img"
      />
      <Grid className="loginImage_title-container" xl={12}>
        <p className="loginImage_title">{t("App.login.leftPanel.imageText")}</p>
      </Grid>
      <img
        src={dotPattern}
        alt="Login Dot Patter"
        className="loginImage_pattern"
      />
    </Grid>
  );
};

export default LoginImage;

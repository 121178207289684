import axios from "axios";
import GetToken from "../../config/getToken";
import { env } from "../../env";

export async function CreateCompany(data) {
  const token = await GetToken();
  if (env.REACT_APP_API_URL.toString().includes("dev")){
    data["environment"] = "dev";
  }else if (env.REACT_APP_API_URL.toString().includes("test")){
    data["environment"] = "test";
  }else if (env.REACT_APP_API_URL.toString().includes("stage")){
    data["environment"] = "stage";
  }else {
    data["environment"] = "prod";
  }
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "companies",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditCompany(data) {
  const token = await GetToken();

  return axios({
    method: "PUT",
    baseURL: env.REACT_APP_API_URL,
    url: "companies",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useTranslation } from "react-i18next";
import { DownloadCatalogTraders } from "./downloadCatalog";
import { UploadCatalogTraders } from "./uploadCatalog";

const UnifiedCatalogTraders = () => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  return (
    <Grid container direction="column" rowSpacing={3} marginBottom={"5%"}>
      <Grid item className="display-large__primary-one">
        {t("App.traders.catalog.title")}
      </Grid>
      <Grid item className="full-width">
        <Accordion className="accordion__upload">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              columnSpacing={2}
            >
              <Grid item>
                <FileDownloadOutlinedIcon className="accordion__upload__downloadIcon" />
              </Grid>
              <Grid item md={9} sm={9} xs={9}>
                <p className="display-small__primary-one">
                  {t("App.unifiedCatalogue.download")}
                </p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordion__upload__detail">
            <DownloadCatalogTraders />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item className="full-width">
        {" "}
        <Accordion className="accordion__upload">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              columnSpacing={2}
            >
              <Grid item>
                <FileUploadOutlinedIcon className="accordion__upload__uploadIcon" />
              </Grid>
              <Grid item md={9} sm={9} xs={9}>
                <p className="display-small__primary-one">
                  {t("App.unifiedCatalogue.upload")}
                </p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordion__upload__detail">
            <UploadCatalogTraders />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default UnifiedCatalogTraders;

import store from "../app/store";

/**
 * Funcion que permite codificar un archivo a base 64
 * @param {obj} file
 * @returns
 */
function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Funcion que permite enviar el cuerpo para los servicios de carga
 * @param {formData} formData
 * @param {int} fileType
 * @returns
 */
export async function convertFileToBase64(formData, fileType, data) {
  const userInfo = store.getState().user.user;
  const base64 = await fileToBase64(formData.get("file"));
  const obj = {
    idFileType: fileType,
    eanProvider: userInfo.company.eanCompany,
    fileName: formData.get("file").name,
    idUser:
      userInfo.firstName.substring(0, 3) + userInfo.lastName.substring(0, 3),
    emailLoad: userInfo.email,
    userLoad: `${userInfo.firstName} ${userInfo.lastName}`,
    fileUpload: base64,
    country: userInfo.company.country,
    provider: userInfo.company.companyName,
    companies: data.companies,
    checkReplicateBusinessGroup: data.checkReplicateBusinessGroup,
  };
  return obj;
}

/**
 * Funcion que permite enviar el cuerpo previamente establecido solo
 * cambiando el archivo cargado a base 64 para los servicios de carga
 * @param {formData} formData
 * @returns
 */
export async function convertFileToBase64Dynamic(formData, data) {
  const base64 = await fileToBase64(formData.get("file"));
  data.fileUpload = base64;
  return data;
}

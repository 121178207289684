import { React, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  borderRadius: "10px",
  bgcolor: "white",
  padding: "1rem !important",
  p: 4,
};
export const ModalChooseIcon = (props) => {
  const initialIconsState = [
    { id: 1, name: "Account Tree", value: "accountTree.svg", selected: false },
    { id: 2, name: "Add Chart", value: "addChart.svg", selected: false },
    {
      id: 3,
      name: "Add Photo Alternate",
      value: "addPhotoAlternate.svg",
      selected: false,
    },
    { id: 4, name: "Analytics", value: "analytics.svg", selected: false },
    { id: 5, name: "Assignment", value: "assignment.svg", selected: false },
    { id: 6, name: "Available", value: "available.svg", selected: false },
    { id: 7, name: "Bar Chart", value: "barChart.svg", selected: false },
    { id: 8, name: "Dashboard", value: "dashboard.svg", selected: false },
    { id: 9, name: "Database", value: "database.svg", selected: false },
    { id: 10, name: "Data Usage", value: "dataUsage.svg", selected: false },
    { id: 11, name: "Event Busy", value: "eventBusy.svg", selected: false },
    { id: 12, name: "Event Note", value: "eventNote.svg", selected: false },
    { id: 13, name: "Exploration", value: "exploration.svg", selected: false },
    { id: 14, name: "Filter Drama", value: "filterDrama.svg", selected: false },
    {
      id: 15,
      name: "Local Shipping",
      value: "localShipping.svg",
      selected: false,
    },
    { id: 16, name: "Monitoring", value: "monitoring.svg", selected: false },
    {
      id: 17,
      name: "Notification Add",
      value: "notificationAdd.svg",
      selected: false,
    },
    { id: 18, name: "Person", value: "person2.svg", selected: false },
    {
      id: 19,
      name: "Person Filled",
      value: "personFilled.svg",
      selected: false,
    },

    {
      id: 20,
      name: "Person Search",
      value: "personSearch.svg",
      selected: false,
    },
    {
      id: 21,
      name: "Picture as Pdf",
      value: "pictureAsPdf.svg",
      selected: false,
    },
    {
      id: 22,
      name: "Query Stats",
      value: "queryStats.svg",
      selected: false,
    },
    {
      id: 23,
      name: "Request Quote",
      value: "requestQuote.svg",
      selected: false,
    },
    {
      id: 24,
      name: "Search",
      value: "search.svg",
      selected: false,
    },
    {
      id: 25,
      name: "Sell Fill",
      value: "sellFill.svg",
      selected: false,
    },
    {
      id: 26,
      name: "Shopping Cart",
      value: "shoppingCart.svg",
      selected: false,
    },
    {
      id: 27,
      name: "Icon Nav",
      value: "iconNav.svg",
      selected: false,
    },

    {
      id: 28,
      name: "Detailed Analysis",
      value: "detailedAnalysis.svg",
      selected: false,
    },
  ];

  const [iconList, setIconList] = useState(initialIconsState);
  const [iconListSelected, setIconListSelected] = useState(initialIconsState);
  const originalIconList = initialIconsState;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    setIconList(originalIconList);

    props.setSelectedIcon(null);
  };
  const { t } = useTranslation();

  const handleChooseIcon = (icon) => {
    setIconList((current) =>
      current.map((obj) => {
        if (obj.selected) {
          return { ...obj, selected: false };
        }

        return obj;
      })
    );
    setIconList((current) =>
      current.map((obj) => {
        if (obj.id === icon.id) {
          return { ...obj, selected: true };
        }

        return obj;
      })
    );
    setIconListSelected((current) =>
      current.map((obj) => {
        if (obj.selected) {
          return { ...obj, selected: false };
        }

        return obj;
      })
    );
    setIconListSelected((current) =>
      current.map((obj) => {
        if (obj.id === icon.id) {
          return { ...obj, selected: true };
        }

        return obj;
      })
    );
    props.setSelectedIcon(icon.value);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    const list = iconList.map((obj) => {
      if (obj.value === props.selectedIcon) {
        return { ...obj, selected: true };
      }
      return obj;
    });
    if (value === "") {
      if (props.selectedIcon !== null) {
        setIconList(list);
      } else {
        setIconList(originalIconList);
      }
    } else {
      if (props.selectedIcon !== null) {
        setIconList(
          iconListSelected.filter((info) => {
            if (info.name.toLowerCase().includes(value.toLowerCase())) {
              return info;
            }
          })
        );
      } else {
        setIconList(
          originalIconList.filter((info) => {
            if (info.name.toLowerCase().includes(value.toLowerCase())) {
              return info;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    const value = props.selectedIcon;

    if (value !== null) {
      setIconList((current) =>
        current.map((obj) => {
          if (obj.value === props.selectedIcon) {
            return { ...obj, selected: true };
          }

          return obj;
        })
      );
      setIconListSelected((current) =>
        current.map((obj) => {
          if (obj.value === props.selectedIcon) {
            return { ...obj, selected: true };
          }

          return obj;
        })
      );
    } else {
      setIconList(originalIconList);
    }
  }, [props.selectedIcon]);

  return (
    <div>
      <Button
        className="btn__users"
        onClick={handleOpen}
        startIcon={<PaletteOutlinedIcon />}
      >
        {t("App.editOption.selectIconButton")}
      </Button>
      <Modal open={open} onClose={handleClose} className="btn__uploadNew">
        <Box sx={style} className="modal__selectIcon">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={11}>
                  <p className="heading-secondary__md">
                    {t("App.selectIcon.title") + props.optionName}
                  </p>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleClose} component="span">
                    <CloseIcon sx={{ color: "#543AB4", fontSize: "1.8rem" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className="grid__container__fullW"
              item
              style={{ marginBottom: "1rem" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  {" "}
                  <OutlinedInput
                    placeholder={t("App.selectIcon.searchFor")}
                    id="outlined-adornment-weight"
                    className={"icon__searchBar"}
                    onChange={(event) => handleSearch(event)}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="grid__container__outlined">
              <Grid container style={{ justifyContent: "space-evenly" }}>
                {iconList.map((icon) => {
                  return (
                    <Grid
                      key={icon.id}
                      item
                      style={{ textAlign: "center" }}
                      className={
                        icon.selected
                          ? "icon__container__selected"
                          : "icon__container"
                      }
                    >
                      {icon.selected && <div className="icon__selected"></div>}
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        style={{ height: "100%" }}
                        onClick={() => {
                          handleChooseIcon(icon);
                        }}
                      >
                        <Grid item>
                          <img
                            className="icon__color"
                            style={{ color: "#543AB4" }}
                            src={`/assets/menuIcons/${icon.value}`}
                          />
                        </Grid>

                        <Grid item>
                          <p className="default-text__grey">{icon.name}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <Grid
                item
                container
                justifyContent="center"
                spacing={2}
                style={{ paddingTop: "3rem" }}
              >
                <Grid item>
                  <Button
                    type="button"
                    disableRipple
                    className="btn__cancel "
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t("App.buttonLabels.cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableRipple
                    type="submit"
                    className="btn__applyFilter"
                    onClick={() => {
                      setIconList(iconList);
                      setOpen(false);
                    }}
                  >
                    {t("App.selectIcon.confirm")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

import axios from "axios";
import GetToken from "../../config/getToken";
import { env } from "../../env";

export async function GetCompaniesByCountry(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups/companies/countries/available",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetCompaniesByCountries(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups/companies/countries",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateBusinessGroup(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetBusinessGroup(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups/idgroup",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetBusinessGroupByCountries(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups/countries",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditBusinessGroup(data) {
  const token = await GetToken();
  return axios({
    method: "PUT",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ManageBusinessGroups(data) {
  const token = await GetToken();
  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "business/groups/administrations",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListBusinessGroup(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    baseURL: env.REACT_APP_API_URL,
    url: "users/companies/permissions",
    data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

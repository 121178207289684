import React, { useEffect, useState } from "react";
import MenusOptionsIconsMobile from "./menuOptionsIconsMobile";
import { Grid, Icon, IconButton } from "@mui/material";
import MenuOptionsLvl2Mobile from "./menuOptionLvl2Mobile";

const MenuMobileComponent = ({
  setOpen,
  listMenuOptionsIcons,
  fullNameUser,
  emailUser,
  i18n,
  role,
  setSelectedOption,
  optionsLvl1Admin,
  selectedOption,
  navigate,
  excludeUrl,
  homeRoutes,
  openQlikSense,
  idToken,
  setShowMenu,
  fetchTeamcore,
  routeHome,
  handleLogOut,
}) => {
  const [openNivel1, setOpenNivel1] = useState(true);

  // Manejo de tamaño de pantalla si es tablet o no
  const [isTabletWidth, setIsTablet] = useState(window.innerWidth > 375);

  const [isTabletHeight, setIsTabletHeight] = useState(
    window.innerHeight > 750
  );
  /**
   * Función para actualizar el estado  si es tablet o no
   */
  const updateMedia = () => {
    isTabletWidth(window.innerWidth > 375);
    setIsTabletHeight(window.innerHeight > 400);
  };
  /**
   * Actualizar de acuerdo al tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <Grid container justifyContent="center" textAlign="center">
      <Grid item xs={12} md={12} lg={12} xl={12}>
        {/* OPCIONES DE MENÚ CON ICONO */}
        {openNivel1 ? (
          <MenusOptionsIconsMobile
            setSelectedOption={setSelectedOption}
            role={role}
            listMenuOptions={listMenuOptionsIcons}
            navigate={navigate}
            routeHome={routeHome}
            setOpenNivel1={setOpenNivel1}
            isTabletWidth={isTabletWidth}
            setShowMenu={setShowMenu}
          />
        ) : (
          <MenuOptionsLvl2Mobile
            selectedOption={selectedOption}
            setOpen={setOpen}
            idToken={idToken}
            fetchTeamcore={fetchTeamcore}
            homeRoutes={homeRoutes}
            fullNameUser={fullNameUser}
            emailUser={emailUser}
            excludeUrl={excludeUrl}
            i18n={i18n}
            navigate={navigate}
            openQlikSense={openQlikSense}
            role={role}
            setOpenNivel1={setOpenNivel1}
            isTabletWidth={isTabletWidth}
            isTabletHeight={isTabletHeight}
            setShowMenu={setShowMenu}
            optionsLvl1Admin={optionsLvl1Admin}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        height={"40px"}
        style={{ position: "absolute", top: "92%" }}
      >
        {" "}
        <Grid container justifyContent="center" alignItems="flex-end">
          <Grid item>
            <IconButton
              sx={{
                width: "48px",
                height: "32px",
                justifyContent: "center",
              }}
              size="large"
              disableRipple={true}
              edge="start"
              color="inherit"
              onClick={handleLogOut}
            >
              <Icon
                sx={{
                  fontSize: 22,
                  color: "black",
                  fill: "black",
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <img
                  className={"menu__icon__img"}
                  src={"/assets/menuIcons/exitMobile.svg"}
                  alt={"navigation"}
                />
              </Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenuMobileComponent;

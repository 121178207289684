import {
  Checkbox,
  FormControlLabel,
  Grid,
  FormHelperText,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const ViewFields = ({
  t,
  control,
  mapView,
  setMapView,
  type,
  zeroOptionSelect,
}) => {
  const checkedIcon = (
    <CheckBoxIcon fontSize="medium" sx={{ color: "#553AB6" }} />
  );
  const uncheckedIcon = (
    <CheckBoxOutlineBlankIcon
      fontSize="medium"
      sx={{ color: "#553AB6", borderColor: "#553AB6" }}
    />
  );

  useEffect(() => {
    console.log("zeroOptionSelect", zeroOptionSelect);
  }, [zeroOptionSelect]);

  /**
   * Función para actualizar el valor del campo
   * @param {*} field nombre del campo a cambiar
   * @param {*} event
   */
  const changeValueField = (field, event) => {
    setMapView((prevState) => ({
      ...prevState,
      [field]: mapView[field] === 0 ? 1 : 0,
    }));
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Grid item xs={12} paddingBottom={1}>
          <p className="heading__bold">
            {t("App.dynamicPeriodicReport.fields.select")}
          </p>
        </Grid>
        {zeroOptionSelect && (
          <FormHelperText
            sx={{
              margin: "1px 0 0 0",
              color: "#e52900",
              fontSize: 10,
            }}
          >
            {t(`App.listDynamicPeriodicReport.cen.create.view.error`)}
          </FormHelperText>
        )}

        <Grid
          container
          direction="row"
          width="100%"
          justifyContent="flex-start"
          gap={1.2}
          paddingBottom={1}
          border={zeroOptionSelect ? 1 : 0}
          sx={{
            border: zeroOptionSelect ? "0.8px solid #e52900" : "none",
            borderRadius: zeroOptionSelect ? "8px" : "none",
          }}
          px={zeroOptionSelect ? 0.9 : 0}
          height={{ lg: "210px", md: "200px", sm: "230px", xs: "842px" }}
          className="only-scroll-y"
        >
          {Object.keys(mapView).map((item) => (
            <Grid item lg={2.9} md={3.8} sm={2.85} xs={12} width={"100%"}>
              <FormControlLabel
                control={
                  <Controller
                    defaultValue={false}
                    name={item}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checkedIcon={checkedIcon}
                        uncheckedIcon={uncheckedIcon}
                        checked={mapView[item] === 1}
                        onChange={(e) => changeValueField(item, e)}
                      />
                    )}
                  />
                }
                label={
                  <p className={"heading"}>
                    {t(
                      `App.listDynamicPeriodicReport.cen.create.view.${type}.${item}`
                    )}
                  </p>
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewFields;

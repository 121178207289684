import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";
import { GetSalesForceList } from "../../services/user/salesForceServices";

export const salesForceSlice = createSlice({
  name: "salesForce",
  initialState: {
    salesForce: [],
    salesForceStatus: "fetch",
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllSaleForces.pending, (state, action) => {
        state.salesForceStatus = "loading";
      })
      .addCase(fetchAllSaleForces.fulfilled, (state, action) => {
        state.salesForceStatus = "succeeded";
        state.salesForce = action.payload;
      })
      .addCase(fetchAllSaleForces.rejected, (state, action) => {
        state.salesForceStatus = "failed";
      });
  },
});

export const getSalesForce = (state) => state.salesForce.salesForce;

export const getSalesForceStatus = (state) => state.salesForce.salesForceStatus;

export const fetchAllSaleForces = createAsyncThunk(
  "salesForce/fetchAllSaleForce",
  async (idCompany) => {

    //TODO: Ajustar el objeto GetSalesForceList por integracion de grupo empresarial
    const requestSalesForce = await GetSalesForceList({
      idCompany: idCompany,
      idFile: 2,
    });

    if (requestSalesForce.status === CODES.COD_RESPONSE_HTTP_OK) {
      if (requestSalesForce.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST || requestSalesForce.data.responseCode === CODES.COD_RESPONSE_SUCCESS) 
      {
        return requestSalesForce.data;
      }
    }
  }
);

export default salesForceSlice.reducer;

import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../Modals/modalInfo";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import {
  EditSpecialFileCTyS,
  FilterSpecialFilesCTyS,
} from "../../../services/admin/specialFiles";
import { getUser } from "../../../pages/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import {
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import { REGEXP } from "../../../consts/regexp";

const EditSpecialFile = ({
  specialFile,
  updateDatatableVisually,
  setData,
  dataArray,
  setDataStructureArray,
  setIsLoadingData,
  setFilterApply,
  setNoFilter,
}) => {
  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;

  /**
   * Use State
   */

  const [open, setOpen] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editData, setEditData] = useState(false);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  /**
   * t de useTranslation para la traducción de los textos
   */
  const { t } = useTranslation();

  const schema = yup.object().shape({
    url: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA2,
        t("App.validationMessages.noSpecialCharacters", {
          characters: "<> ' ; ° ´ ` ¨ " + '"',
        })
      ),
  });

  /**
   * Use Form
   */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * Use effect
   */

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Handles
   */
  const handleOpen = () => {
    handleSetInfo();
    setOpen(true);
  };

  /**
   * Sets initial form info for edit special file
   */
  const handleSetInfo = () => {
    setValue("country", specialFile.country);
    setValue("company", specialFile.provider);
    setValue("eanCompany", specialFile.eanProvider);
    setValue("url", specialFile.url);
    setValue("status", specialFile.state);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Calls edit service to modify current special file
   * @param {object} data
   * @returns if proccess does not succeed
   */
  const handleEditSpecialFileCTyS = async (data) => {
    try {
      const obj = {
        pk: specialFile.pk ? specialFile.pk : `country#${data.country}`,
        sk: specialFile.sk ? specialFile.sk : `ean_provider#${data.eanCompany}`,
        country: specialFile.country,
        eanProvider: data.eanCompany,
        provider: data.company,
        url: data.url,
        state: data.status,
        modificationUser: fullNameUser,
        modificationDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };

      setLoadingEdit(true);

      const editService = await EditSpecialFileCTyS(obj);

      setLoadingEdit(false);

      // Si no responde satisfactoriamente
      if (
        editService.status !== CODES.COD_RESPONSE_HTTP_OK ||
        editService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setEditData(editService);
        setError(true);
        return;
      }

      //Si es exitoso se muestra al usuario y se realiza el filtro correspondiente

      setSuccess(true);
      setEditData(editService);
      handleFilters(obj);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Triggers the filters using the data of the current edited special File
   */
  const handleFilters = async (data) => {
    setIsLoadingData(true);

    const obj = {
      country: data.country,
      lstProviders: [data.provider],
      lstEanProviders: [data.eanProvider],
    };

    //Hacemos la petición del filtro con este special file
    const filterFilesService = await FilterSpecialFilesCTyS(obj);

    //Si falla setteamos lo correspondiente
    if (
      filterFilesService.status !== CODES.COD_RESPONSE_HTTP_OK &&
      filterFilesService.data.responseCode !==
        CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      setData([]);
      setIsLoadingData(false);
      return;
    }

    //Damos forma al cuerpo de la respuesta y setteamos la data
    const proccesedRows = setDataStructureArray(
      filterFilesService.data.responseMessage
    );
    setData(proccesedRows);

    //Permitimos visualizar la data
    setNoFilter(false);
    setIsLoadingData(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        <Box className="scroll-y modal-box-createEditCompany">
          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item container justifyContent="flex-end">
              <IconButton component="span" onClick={handleClose}>
                <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.specialFilesCTyS.title2")} {specialFile.provider}
              </h1>
            </Grid>

            <Grid item className="full-width">
              <form onSubmit={handleSubmit(handleEditSpecialFileCTyS)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.createAndEditCompany.subtitle1")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled={true}
                            className={`${
                              errors.country
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            // {...params}
                            label={t("App.createAndEditCompany.country")}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            onChange={(event, values) => {
                              onChange(values);
                            }}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="company"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled={true}
                            className={`${
                              errors.company
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            label={t("App.listUsers.enterprise")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="eanCompany"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled={true}
                            className={`${
                              errors.eanCompany
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            label={t("App.createAndEditCompany.eanCompany")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <h3 className="heading__text-grey">
                      {t("App.specialFilesCTyS.subtitle2")}
                    </h3>
                  </Grid>

                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={6} sm={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="url"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.url
                                ? "modalCreateRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            helperText={errors.url ? errors.url.message : ""}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            label={t("App.specialFilesCTyS.url")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} sm={6} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.listUsers.status")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.status
                                  ? "modalCreateRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.createAndEditCompany.status")}
                              </MenuItem>
                              <MenuItem value="ACTIVO">
                                {t("App.createRole.active")}
                              </MenuItem>
                              <MenuItem value="INACTIVO">
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  direction={isDesktop ? "row" : "column-reverse"}
                  justifyContent={isDesktop ? "flex-end" : "center"}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} lg={1} className="full-width">
                    <Button
                      className="btn__filled__gradient full-width"
                      onClick={handleClose}
                    >
                      {t("App.buttonLabels.cancel")}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4} lg={2.5} className="full-width">
                    <Button
                      disabled={
                        watch("country") &&
                        watch("company") &&
                        watch("eanCompany") &&
                        watch("url") &&
                        watch("status") !== ""
                          ? false
                          : true
                      }
                      className={`${
                        watch("country") &&
                        watch("company") &&
                        watch("eanCompany") &&
                        watch("url") &&
                        watch("status") !== ""
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width`}
                      type="submit"
                      startIcon={!loadingEdit ? <CheckIcon /> : <></>}
                    >
                      {!loadingEdit ? (
                        t("App.buttonLabels.confirmChange")
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {error && (
        <ModalInfo
          title={""}
          responseData={editData}
          open={open}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpen(false);
            reset();
          }}
        />
      )}

      {success && (
        <ModalInfo
          responseData={editData}
          open={open}
          onClose={() => {
            setError(false);
            setSuccess(false);
            setOpen(false);
            reset();
          }}
        />
      )}
    </>
  );
};

export default EditSpecialFile;

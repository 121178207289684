import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CODES } from "../../consts/codes";
import {
  GetGeographiesList,
  GetGeographiesLoadList,
} from "../../services/admin/geographies/geographiesService";

export const geographiesSlice = createSlice({
  name: "geographies",
  initialState: {
    geographies: [],
    geographiesStatus: "fetch",
    error: null,
    geographiesLoad: [],
    geographiesLoadStatus: "fetch",
  },
  reducers: {
    addNewGeography(state, action) {
      const { folder } = action.payload;
      const geographies = state.geographies;
      geographies.unshift(folder);
      state.geographies = geographies;
    },

    deleteGeographyByIsoCode(state, action) {
      const { isoCode } = action.payload;
      const geographies = state.geographies;
      const filteredGeographies = geographies.filter(
        (geography) => geography.country !== isoCode
      );
      state.geographies = filteredGeographies;
    },

    resetGeographiesLoadState(state, action) {
      state.geographiesLoadStatus = "fetch";
    },
    resetGeographiesState(state, action) {
      state.geographiesStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllGeographies.pending, (state, action) => {
        state.geographiesStatus = "loading";
      })
      .addCase(fetchAllGeographies.fulfilled, (state, action) => {
        state.geographiesStatus = "succeeded";
        // Add any fetched posts to the array
        state.geographies = action.payload;
      })
      .addCase(fetchAllGeographies.rejected, (state, action) => {
        state.geographiesStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllLoadGeographies.pending, (state, action) => {
        state.geographiesLoadStatus = "loading";
      })
      .addCase(fetchAllLoadGeographies.fulfilled, (state, action) => {
        state.geographiesLoadStatus = "succeeded";
        // Add any fetched posts to the array
        state.geographiesLoad = action.payload;
      })
      .addCase(fetchAllLoadGeographies.rejected, (state, action) => {
        state.geographiesLoadStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const getGeographies = (state) => state.geographies.geographies;

export const getGeographiesLoad = (state) => state.geographies.geographiesLoad;

export const getStatusGeographies = (state) =>
  state.geographies.geographiesStatus;

export const getStatusGeographiesLoad = (state) =>
  state.geographies.geographiesLoadStatus;

export const { resetGeographiesLoadState, resetGeographiesState } =
  geographiesSlice.actions;

export const fetchAllGeographies = createAsyncThunk(
  "geographies/fetchAll",
  async () => {
    const requestGeographies = await GetGeographiesList();

    if (requestGeographies.status === CODES.COD_RESPONSE_HTTP_OK) {
      if (
        requestGeographies.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST ||
        requestGeographies.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        return requestGeographies.data.responseMessage;
      }
    }
  }
);

export const fetchAllLoadGeographies = createAsyncThunk(
  "geographies/fetchAllLoad",
  async (data) => {    

    data["idFileType"] = 4;
    //TODO: Ajustar el objeto GetGeographiesLoadList por integracion de grupo empresarial
    const requestGeographiesLoad = await GetGeographiesLoadList({
      ...data,
    });

    if (requestGeographiesLoad.status === CODES.COD_RESPONSE_HTTP_OK) 
    {
      if (requestGeographiesLoad.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST || requestGeographiesLoad.data.responseCode === CODES.COD_RESPONSE_SUCCESS) 
      {
        return requestGeographiesLoad.data.data;
      }
    }
  }
);

export default geographiesSlice.reducer;

import {
  Autocomplete,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useEffect, useReducer, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CompaniesSelection = ({
  companiesFields,
  companiesAppend,
  companiesRemove,
  companiesUpdate,
  control,
  setValue,
  getValues,
  errors,
  countriesAndCompaniesList,
  countriesAndCompaniesStatus,
  dispatchMainCompanies,
  isEdit,
}) => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Reducer
   */

  const initialState = () => [{ country: "", countryName: "" }];

  const reducer = (state = initialState(), action = {}) => {
    switch (action.type) {
      case "buildCountriesAndCompanies":
        //Setteamos la información de los países con sus companies
        return action.countriesAndCompanies;
      case "onSelectCompany":
        const lookForCountry = state.map((country) => {
          if (country.country === action.country) {
            const letsCheckCompany = country.lstCompanies.map((company) => {
              if (company.eanCompany === action.eanCompany) {
                if (action.typeOfAction === "select") {
                  return { ...company, selected: true };
                } else {
                  return { ...company, selected: false };
                }
              } else {
                return company;
              }
            });
            return { ...country, lstCompanies: letsCheckCompany };
          }
          return country;
        });
        return lookForCountry;
    }
  };

  //Countries and companies reducer to handle dynamic companies addition/deletion
  const [countriesAndCompanies, dispatch] = useReducer(reducer, reducer());

  /**
   * Use Effect
   */

  useEffect(() => {
    switch (countriesAndCompaniesStatus) {
      case "succeeded":
        dispatch({
          type: "buildCountriesAndCompanies",
          countriesAndCompanies: countriesAndCompaniesList,
        });
        break;

      case "failed":
        dispatch({
          type: "buildCountriesAndCompanies",
          countriesAndCompanies: [],
        });
        break;
    }
  }, [countriesAndCompaniesStatus]);

  /**
   * Handles
   */

  /**
   *Adds a company to the form
   */
  const handleAddCompany = () => {
    const emptyCompany = {
      country: null,
      company: null,
      state: -1,
      key: uuidv4(),
    };
    companiesAppend(emptyCompany);
  };

  /**
   * Triggers events into the countriesAndCompanies reducer to update the state based on user's decision
   * Modifies mainCompanies reducer to update mainCompanies list
   * @param {*} action action to perform into countriesAndCompanies reducer, could be 'remove' or 'select'
   * @param {*} country country selected by the user
   * @param {*} company company selected by the user
   */
  const setSelectedValueToCompany = (action, country, company) => {
    dispatch({
      type: "onSelectCompany",
      typeOfAction: action,
      country: country.country,
      eanCompany: company.eanCompany,
    });
    if (action === "remove") {
      handleBuildMainCompanies(action, company);
    }
  };

  /**
   * Agrega o elimina la empresa actual de la lista de empresas principales basado en su estado
   * @param {*} index index de la empresa actual
   * @param {*} currentState estado de la empresa
   */
  const handleCompanyState = (index, currentState) => {
    const company = getValues(`companies.${index}.company`);

    if (company && currentState === 1) {
      handleBuildMainCompanies("select", company);
    } else if (company) {
      handleBuildMainCompanies("remove", company);
    } else {
    }
  };

  /**
   *Removes company onto companies form
   * @param {*} index index of the current company field
   * @param {*} isRemoving true if user remove field from form, false if user just cleared company field
   */
  const handleRemoveCompany = (index, isRemoving) => {
    //Habilitamos nuevamente la empresa en la lista

    const country = getValues(`companies.${index}.country`);
    const company = getValues(`companies.${index}.company`);

    if (country !== null && company !== null) {
      setSelectedValueToCompany("remove", country, company);
    }

    if (isRemoving) {
      //Eliminanos del form
      companiesRemove(index);
    }

    //Esto permite que se actualice el array y se vea la lista de compañias
    const companyUpdate = getValues("companies.0");
    companiesUpdate(0, companyUpdate);
  };

  /**
   * Removes a country from the current field
   * @param {*} index index of current country
   */
  const handleRemoveCountry = (index) => {
    //Verificamos si existe alguna empresa dentro del campo cuando se resetea el país
    const currentCompany = getValues(`companies.${index}.company`);
    if (currentCompany !== null && currentCompany !== "") {
      setSelectedValueToCompany("remove", currentCompany, currentCompany);
    }

    //Reseteamos todos los valores de la linea actual
    setValue(`companies.${index}.country`, null);
    setValue(`companies.${index}.company`, "");
    setValue(`companies.${index}.state`, -1);

    //Forzamos actualizar el campo
    const company = getValues("companies.0");
    companiesUpdate(0, company);
  };

  /**
   * Handles user's selection of country into the current field
   * @param {*} index current index field
   * @param {*} values country selected
   */
  const handleChangeCountry = (index, values) => {
    //Caso en que se cambie el país y haya una empresa de otro país en el campo
    const previousCompany = getValues(`companies.${index}.company`);
    if (previousCompany && values.country !== previousCompany.country) {
      setSelectedValueToCompany("remove", previousCompany, previousCompany);
    }

    //Si hay un cambio en el country reseteamos los valores
    setValue(`companies.${index}.company`, "");
    setValue(`companies.${index}.state`, -1);

    //Esto permite que se actualice el array y se vea la lista de compañias
    const company = getValues("companies.0");
    companiesUpdate(0, company);
  };

  /**
   * Handles user's selection of company into the current field
   * @param {*} index current field index
   * @param {*} company company selected
   */
  const handleSelectCompany = (index, company) => {
    const country = getValues(`companies.${index}.country`);

    const previousValue = getValues(`companies.${index}.company`);

    //Si hay un valor previo seleccionado entonces le quita el selected:true
    if (previousValue !== null && previousValue !== undefined) {
      setSelectedValueToCompany("remove", country, previousValue);
    }

    setSelectedValueToCompany("select", country, company);
  };

  /**
   * Gets list of companies from a country
   * @param {*} currentIndex current field index
   * @returns list of companies
   */
  const getCompanyListByCountry = (currentIndex) => {
    const country = getValues(`companies.${currentIndex}.country`);

    if (
      country !== "" &&
      country !== null &&
      country !== undefined &&
      countriesAndCompanies !== undefined
    ) {
      const getCountry = countriesAndCompanies.filter(
        (countryFilter) => countryFilter.country === country.country
      );

      return getCountry[0] ? getCountry[0].lstCompanies : [];
    } else {
      return [];
    }
  };

  /**
   * Updates mainCompanies reducer based on companies fields selections
   * @param {*} action action to perform into reducer
   */
  const handleBuildMainCompanies = (action, company) => {
    dispatchMainCompanies({ type: action, company: company });
    const companyToUpdate = getValues("companies.0");
    companiesUpdate(0, companyToUpdate);
  };

  return (
    <Grid container direction="column" className="companiesBox">
      <Grid container direction="column">
        {isEdit && (
          <Grid
            item
            style={{
              marginTop: "1%",
              height: "30px",
            }}
          >
            <Grid
              style={{ width: "45%", padding: "0" }}
              container
              alignItems="center"
              className="state-colors__primary-color-one label__primary-one"
            >
              <Grid item style={{ padding: "0.7%" }} className="col-flex">
                <InfoOutlinedIcon fontSize="large" />
              </Grid>

              <Grid item>
                <p>{t("App.manageBusinessGroup.edit.noDelete")}</p>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          {companiesFields.map((company, index) => (
            <Grid item style={{ marginTop: "2%" }} key={company.key}>
              <Grid container direction="row" columnGap={3}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.companyCountry")}
                      </label>
                    </Grid>
                    <Grid item>
                      {" "}
                      <Controller
                        name={`companies.${index}.country`}
                        defaultValue={""}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={
                              countriesAndCompanies ? countriesAndCompanies : []
                            }
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) => {
                              return option.country == value.country;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors &&
                              errors.companies &&
                              errors.companies[index] &&
                              errors.companies[index].country
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.country}>
                                  {option.countryName}
                                </li>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.manageBusinessGroup.create.companyCountry"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              if (reason === "selectOption") {
                                handleChangeCountry(index, values, reason);
                              } else if (reason === "clear") {
                                handleRemoveCountry(index);
                              }
                              onChange(values);
                            }}
                            value={value || null}
                            disabled={company.disabled === true ? true : false}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    {errors &&
                      errors.companies &&
                      errors.companies[index] &&
                      errors.companies[index].country && (
                        <Grid
                          item
                          sx={{
                            margin: "1px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.companies[index].country.message}
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.company")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        name={`companies.${index}.company`}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled={
                              company.country !== null &&
                              company.disabled !== true
                                ? false
                                : true
                            }
                            options={getCompanyListByCountry(index)}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) => {
                              return option.eanCompany === value.eanCompany;
                            }}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors &&
                              errors.companies &&
                              errors.companies[index] &&
                              errors.companies[index].company
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                            renderOption={(props, option) => {
                              return (
                                <li
                                  style={{
                                    display: option.selected
                                      ? option.selected === true
                                        ? "none"
                                        : ""
                                      : "",
                                  }}
                                  {...props}
                                  key={option.eanCompany}
                                >
                                  {option.companyName}
                                </li>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.manageBusinessGroup.create.company"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              if (reason === "selectOption") {
                                handleSelectCompany(index, values);
                              } else if (reason === "clear") {
                                handleRemoveCompany(index, false);
                              }
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    {errors &&
                      errors.companies &&
                      errors.companies[index] &&
                      errors.companies[index].company && (
                        <Grid
                          item
                          sx={{
                            margin: "1px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.companies[index].company.message}
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      {" "}
                      <label className="form__label label__text-grey">
                        {t("App.manageBusinessGroup.create.state")}
                      </label>
                    </Grid>
                    <Grid item>
                      <Controller
                        name={`companies.${index}.state`}
                        defaultValue={-1}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            disabled={company.country !== null ? false : true}
                            defaultValue={-1}
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleCompanyState(index, e.target.value);
                            }}
                            value={value}
                            className={
                              errors &&
                              errors.companies &&
                              errors.companies[index] &&
                              errors.companies[index].state
                                ? "select__filters__errors__xs"
                                : "select__filters__xs"
                            }
                          >
                            <MenuItem disabled value={-1}>
                              <em>{t("App.createUser.status")}</em>
                            </MenuItem>
                            <MenuItem value={1}>
                              {t("App.createUser.active")}
                            </MenuItem>
                            <MenuItem value={0}>
                              {t("App.createUser.inactive")}{" "}
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                    {errors &&
                      errors.companies &&
                      errors.companies[index] &&
                      errors.companies[index].state && (
                        <Grid
                          item
                          sx={{
                            margin: "1px 0 0 0",
                            color: "#e52900",
                          }}
                        >
                          {errors.companies[index].state.message}
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item className="col-flex" style={{ marginTop: "1.8%" }}>
                  <IconButton
                    disabled={index !== 0 && company.disabled}
                    onClick={() => {
                      if (index === 0) {
                        handleAddCompany();
                      } else {
                        handleRemoveCompany(index, true);
                      }
                    }}
                    className={
                      index === 0
                        ? "addPermission__button"
                        : company.disabled
                        ? "addPermission__disabled"
                        : "addPermission__delete"
                    }
                  >
                    {" "}
                    {index === 0 ? (
                      <AddIcon sx={{ fontSize: 22 }} />
                    ) : (
                      <ClearIcon sx={{ fontSize: 22 }} />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

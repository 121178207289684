import { Card, CardContent, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";

export const BoardsSection = ({ t, requestInfo }) => {
  /**
   * Const
   */
  const emptyQtyDocumentsPie = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "#FCB045",
      },
    ],
  };

  const emptyQtyInvoice = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "#FCB045",
      },
    ],
  };

  /**
   * Opciones para Stacked Bar Chart
   */
  const optionsPie = {
    maintainAspectRatio: false,

    plugins: {
      datalabels: {
        display: true,
      },
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: "right",
        align: "center",
        labels: {
          boxWidth: 15,
          boxHeight: 10,
          textAlign: "left",
        },
      },
    },
    responsive: true,
  };

  const optionsStackedBar = {
    maintainAspectRatio: false,
    indexAxis: "x",
    plugins: {
      datalabels: {
        display: true,
      },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  /**
   * Use State
   */

  const [qtyDocumentsPie, setQtyDocumentsPie] = useState(emptyQtyDocumentsPie);

  const [qtyInvoiceMonths, setQtyInvoiceMonths] = useState(emptyQtyInvoice);

  /**
   * Use Effect
   */

  useEffect(() => {
    setQtyDocumentsPie(requestInfo.qtyDocumentsPie);
    setQtyInvoiceMonths(requestInfo.qtyInvoiceBar);
  }, [requestInfo]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item md={6}>
        <Card className="border-radius">
          <CardContent>
            <Grid container direction={"column"} rowSpacing={4}>
              <Grid item className="col-flex">
                <span className="display-small__text-default text-center">
                  <p>
                    {t("App.homeEmission.boards.title1")}{" "}
                    {t("App.homeEmission.boards.title2")}
                  </p>
                </span>
              </Grid>
              <Grid item style={{ minHeight: "300px" }}>
                <Pie options={optionsPie} data={qtyDocumentsPie} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6}>
        <Card className="border-radius">
          <CardContent>
            <Grid container direction={"column"} rowSpacing={4}>
              <Grid item className="col-flex">
                <span className="display-small__text-default text-center">
                  <p>
                    {t("App.homeEmission.boards.title5")}{" "}
                    {t("App.homeEmission.boards.title6")}
                  </p>
                </span>
              </Grid>
              <Grid item style={{ minHeight: "253px" }}>
                <Bar options={optionsStackedBar} data={qtyInvoiceMonths} />
              </Grid>
              <Grid item>
                <Grid
                  container
                  columnSpacing={3}
                  className="col-flex heading__text-default"
                >
                  <Grid item>
                    <Grid container columnSpacing={0.5} className="col-flex">
                      <Grid
                        item
                        style={{
                          backgroundColor: "#806AD0",
                          width: "10px",
                          height: "10px",
                        }}
                      ></Grid>
                      <Grid item>2023</Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container columnSpacing={0.5} className="col-flex">
                      <Grid
                        item
                        style={{
                          backgroundColor: "#FCB045",
                          width: "10px",
                          height: "10px",
                        }}
                      ></Grid>
                      <Grid item>2024</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { ListApplication } from "../../../services/user/deleteAndReload/applicationDeleteAndReload";
import { CODES } from "../../../consts/codes";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import moment from "moment";
import ModalCancelRequest from "../modalCancelRequest";
import ModalInfo from "../Modals/modalInfo";
import StateComponent from "../stateComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterApplicationDeleteAndReload = (props) => {
  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Schema to validate the form to filter requests of deleted and reload
   */
  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({ countryName: yup.string(), country: yup.string() })
      .required(),
  });

  /**
   * Fuentes
   */
  const fontsList = [
    {
      label: "CENt",
      value: "CENT",
    },
    {
      label: "SFTP",
      value: "SFTP",
    },
    {
      label: t("App.deleteAndReload.filters.uploadWeb"),
      value: "WEB",
    },
  ];

  /**
   * useForm para manejar los datos del formulario
   */
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      finalDate: null,
      initialDate: null,
      user: "",
      font: {
        label: "",
        value: "",
      },
      country: {
        countryName: "",
        country: "",
      },
    },
  });

  /**
   * Use State
   */
  const [country, setCountry] = useState("");
  const [commercesList, setCommercesList] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [selectedCommerces, setSelectedCommerces] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [error, setError] = useState(false);
  const [filterResponse, setFilterResponse] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [selectedFonts, setSelectedFonts] = useState([]);

  /**
   * useSelectors
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * useEffect para obtener los países
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "==============Error Get Geographies======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
  }, [dispatch, countriesStatus]);

  /**
   * Auto complete filters
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   *
   * Función para limpiar los datos que se envía desde los autocomplete de comercios y proveedores
   *
   * @param {Array} data
   * @returns los ean de los comerciantes y proveedores
   */
  const clearData = (data) => {
    let listEanTraders, listEanProviders;

    if (data.commerce && data.commerce?.length !== 0) {
      if (
        data.commerce[0].nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        const eanTradersFiltered = data.commerce.filter(
          (item) => item.nameCompany !== t("App.downloadSalesForce.selectAll")
        );

        listEanTraders = eanTradersFiltered.map((item) => item.eanCompany);
      } else {
        listEanTraders = data.commerce.map((item) => item.eanCompany);
      }
    } else {
      listEanTraders = [];
    }

    if (data.supplier && data.supplier?.length !== 0) {
      if (
        data.supplier[0].nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        const eanTradersFiltered = data.supplier.filter(
          (item) => item.nameCompany !== t("App.downloadSalesForce.selectAll")
        );

        listEanProviders = eanTradersFiltered.map((item) => item.eanCompany);
      } else {
        listEanProviders = data.supplier.map((item) => item.eanCompany);
      }
    } else {
      listEanProviders = [];
    }

    return { listEanTraders, listEanProviders };
  };

  /**
   *
   * Función para formatear las fechas de inicial y final solicitud en el formato AAAA-MM-DD
   *
   * @param {Object} initialDate
   * @param {Object} finalDate
   * @returns fechas de inicial y final solicitud formateadas o un string vacío
   */
  const formatDate = (initialDate, finalDate) => {
    let initial, final;

    if (initialDate === "" || initialDate === null) {
      initial = "";
    } else {
      initial = moment(initialDate.$d).format("YYYY-MM-DD");
    }

    if (finalDate === "" || finalDate === null) {
      final = "";
    } else {
      final = moment(finalDate.$d).format("YYYY-MM-DD");
    }

    return { initial, final };
  };

  let formFilters;

  /**
   *
   * Función para manejar los filtros de la tabla
   *
   * @param {Object} data - Datos de los filtros
   */
  const handleFilters = async (data) => {
    props.setLoadingFilters(true);

    try {
      const { listEanTraders, listEanProviders } = clearData(data);
      const { initial, final } = formatDate(data.initialDate, data.finalDate);
      const obj = {
        country: data.country.country,
        lstEansTraders: listEanTraders,
        font: data.font.value,
        lstEansProviders: listEanProviders,
        typeDocument: data.documentType,
        requestType: data.requestType,
        requestStartDate: initial,
        requestEndDate: final,
        createUser: data.user ? data.user : "",
        channel: data.font.value ? data.font.value : null,
      };

      formFilters = obj;

      props.setFilters(obj);

      props.setData([]);

      props.setHasFiltersTriggered(true);

      const listApplications = await ListApplication(obj);

      if (
        listApplications.status === CODES.COD_RESPONSE_HTTP_OK &&
        listApplications.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = listApplications.data.responseMessage;

        const processRows = setDataStructure(responseMessage);

        props.setNoFound(false);
        props.setData(processRows);
      } else if (
        listApplications.data.responseCode === CODES.COD_RESPONSE_ERROR_LIST
      ) {
        setError(true);
        setFilterResponse(listApplications);
      } else {
        props.setNoFound(true);
        props.setData([]);
      }

      props.setLoadingFilters(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Aplica los filtros para refrescar la tabla
   */
  const applyFilters = async () => {
    try {
      props.setLoadingFilters(true);

      console.log(formFilters);

      const requestDeleteAndReload = await ListApplication(formFilters);

      const responseCode = requestDeleteAndReload.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          props.setData(
            setDataStructure(requestDeleteAndReload.data.responseMessage)
          );
          break;

        default:
          props.setData([]);
          props.setNoFound(true);
          break;
      }

      props.setLoadingFilters(false);
    } catch (error) {
      console.log(
        "============== Error filterApplicationDeleteAndReload.jsx applyFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Formatea la estructura de los datos a mostrar en la tabla
   *
   * @param {Array} data - Datos a formatear
   * @returns datos formateados
   */
  const setDataStructure = (data) => {
    const statesP = [
      "PENDIENTE",
      "PENDIENTE CANCELADO",
      "FINALIZADO",
      "PROCESANDO",
      "EN PROCESO",
      "ENCOLADO POR RECARGAR",
      "CANCELADO",
      "PENDIENTE ENVIO",
    ];
    const processRows = data.map((item) => {
      return {
        applicationId: !item.idRequest ? " - " : item.idRequest,
        ally: !item.ally ? "-" : item.ally,
        applicationDate: !item.requestDate ? " - " : item.requestDate,
        initialDate: !item.initialDateReported
          ? " - "
          : item.initialDateReported,
        finalDate: !item.finalDateReported ? " - " : item.finalDateReported,
        user: !item.createUser ? " - " : item.createUser,
        applicationType: !item.requestType ? " - " : item.requestType,
        channel: !item.channel
          ? " - "
          : item.channel === "WEB"
          ? t("App.deleteAndReload.filters.uploadWeb")
          : item.channel === "CENT"
          ? "CENt"
          : item.channel,
        documentType: !item.typeDocument
          ? "-"
          : item.typeDocument === "sales"
          ? t("App.deleteAndReload.filters.typeSales")
          : item.typeDocument === "stock"
          ? t("App.deleteAndReload.filters.typeInventory")
          : t("App.deleteAndReload.filters.typeSalesStock"),

        commerce: !item.trader ? " - " : item.trader,
        supplier: !item.provider ? " - " : item.provider,
        eanProviderMain: item.eanProviderMain,
        nameProviderMain: item.nameProviderMain,
        eanProvider: item.eanProvider,
        errorDetail:
          item.state != "ERROR"
            ? {
                fileName: "",
                pathFileName: "",
                idRequest: "",
                state: item.state,
              }
            : {
                fileName: item.logFileName,
                pathFileName: item.pathLogFileName,
                idRequest: item.idRequest,
                state: item.state,
              },
        state: !item.state
          ? ""
          : item.state === "PENDIENTE CANCELADO"
          ? "CANCELADO"
          : !statesP.includes(item.state.toUpperCase())
          ? "ERROR"
          : item.state,
        cancel:
          item.state.toLowerCase() !== "pendiente" ? (
            <>
              <StateComponent value={item} />
            </>
          ) : (
            <ModalCancelRequest
              allies={false}
              data={item}
              applyFilters={applyFilters}
            />
          ),
      };
    });

    return processRows;
  };

  /**
   *
   * Función para eliminar la opción de seleccionar todos (en comercios) cuando se elimina otra opción
   *
   * @param {Array} values - Valores seleccionados
   * @param {String} reason - Razón del cambio
   * @param {boolean} includeSelectAll - Si se incluye la opción de seleccionar todos
   */
  const handleDeleteSelectAllCommerce = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option.nameCompany !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedCommerces(optionsWithoutSelectAll);
      setValue("commerce", optionsWithoutSelectAll);
    }
  };

  /**
   *
   *  Funcion para eliminar la opción de seleccionar todos (en proveedores) cuando se elimina otra opción
   *
   * @param {Array} values - Valores seleccionados
   * @param {String} reason - Razón del cambio
   * @param {boolean} includeSelectAll - Si se incluye la opción de seleccionar todos
   */
  const handleDeleteSelectAllSupplier = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option.nameCompany !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedSuppliers(optionsWithoutSelectAll);
      setValue("supplier", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para el manejo del cambio de valores de la lista de comercios
   * @param {Event} event - Evento de cambio
   * @param {Array} values - Valores seleccionados
   * @param {String} reason - Razón del cambio
   * @param {Object} detail - Detalle del cambio
   */
  const onCommerceChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const commerce of values) {
      if (commerce.nameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    // Obtener el tamaño de las listas para verificar si se debe seleccionar la opción de seleccionar todos

    // tamaño de la lista de comercios
    let sizeCommerce = commercesList.filter(
      (commerce) =>
        commerce.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // tamaño de los valores seleccionados
    let sizeValues = values.filter(
      (commerce) =>
        commerce.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se selecciona la opción de seleccionar todos o si se seleccionan todos los valores

    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeCommerce === sizeValues && reason === "selectOption")
    ) {
      setSelectedCommerces(commercesList);
      setValue("commerce", commercesList);
    } else {
      // Si se selecciona una opción diferente a seleccionar todos

      setSelectedCommerces(values);
      setValue("commerce", values);

      handleDeleteSelectAllCommerce(values, reason, includeSelectAll);
    }

    if (detail) {
      const option = detail.option;

      // Si se elimina la opción de seleccionar todos

      if (
        reason === "removeOption" &&
        option.nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedCommerces([]);
        setValue("commerce", null);
      }
    }
  };

  /**
   *
   * Función para el manejo del cambio de valores de la lista de proveedores
   *
   * @param {Event} event - Evento de cambio
   * @param {Array} values - Valores seleccionados
   * @param {String} reason - Razón del cambio
   * @param {Object} detail - Detalle del cambio
   */
  const onSupplierChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const commerce of values) {
      if (commerce.nameCompany === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    // Obtener el tamaño de las listas para verificar si se debe seleccionar la opción de seleccionar todos

    // tamaño de la lista de proveedores
    let sizeSupplier = suppliersList.filter(
      (supplier) =>
        supplier.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // tamaño de los valores seleccionados
    let sizeValues = values.filter(
      (supplier) =>
        supplier.nameCompany !== t("App.downloadSalesForce.selectAll")
    ).length;

    // Si se selecciona la opción de seleccionar todos o si se seleccionan todos los valores
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeSupplier === sizeValues && reason === "selectOption")
    ) {
      setSelectedSuppliers(suppliersList);
      setValue("supplier", suppliersList);
    } else {
      // Si se selecciona una opción diferente a seleccionar todos

      setSelectedSuppliers(values);
      setValue("supplier", values);

      handleDeleteSelectAllSupplier(values, reason, includeSelectAll);
    }

    if (detail) {
      const option = detail.option;

      // Si se elimina la opción de seleccionar todos
      if (
        reason === "removeOption" &&
        option.nameCompany === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedSuppliers([]);
        setValue("supplier", null);
      }
    }
  };

  /**
   *
   * Función para el manejo del cambio de valores de la lista de países y
   * obtener la lista de comercios y proveedores
   *
   * @param {Object} data - País seleccionado
   */
  const onCountryChange = async (data) => {
    fetchTraders(data);
    fetchSuppliers(data);
  };

  /**
   *
   * Función para obtener la lista de comercios por país
   * @param {String} country País seleccionado
   */
  const fetchTraders = async (country) => {
    try {
      const obj = {
        country: country.country,
      };

      const tradersService = await GetTradersService(obj);

      if (tradersService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          tradersService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              nameCompany: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          const completeList =
            tradersList.length > 0
              ? [
                  { nameCompany: t("App.downloadSalesForce.selectAll") },
                  ...tradersList,
                ]
              : [];

          setCommercesList(completeList);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para obtener la lista de proveedores por país
   * @param {String} country País seleccionado
   */
  const fetchSuppliers = async (country) => {
    try {
      const obj = {
        country: country.country,
      };

      const suppliersService = await GetSuppliersService(obj);

      if (suppliersService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          suppliersService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              nameCompany: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          const completeList =
            suppliersList.length > 0
              ? [
                  { nameCompany: t("App.downloadSalesForce.selectAll") },
                  ...suppliersList,
                ]
              : [];

          setSuppliersList(completeList);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para limpiar los filtros
   */
  const handleCleanFilters = () => {
    setValue("user", null);
    setCountry("");
    setValue("finalDate", null);
    setValue("initialDate", null);

    setSelectedCommerces([]);
    setSelectedSuppliers([]);
    props.setHasFiltersTriggered(false);
    reset();
  };

  /**
   * Método para actualizar el estado de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * userEffect para actualizar el estado de isDesktop basado en el tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Habilitar o deshabilitar el select de fuente
   */
  const disabledFont = () => {
    return watch("documentType") === "";
  };

  /**
   * Habilitar o deshabilitar los select de compañias/empresas
   */
  const disabledFieldsCompanies = () => {
    return watch("country")?.country === "";
  };

  /**
   * Habilitar o deshabilitar el botón de aplicar filtros
   */
  const disabledFilters = () => {
    return watch("country")?.country === "";
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      rowSpacing={2}
    >
      <Grid
        xs={12}
        sm={8}
        md={9}
        lg={9}
        item
        className="side-line-contact__bottomAlign"
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid container direction="column" gap={3}>
            <Grid
              container
              direction="row"
              width={"100%"}
              spacing={2}
              rowSpacing={3}
            >
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  name="country"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={
                        errors.country
                          ? "autoComplete__deleteAndReload__error"
                          : "autoComplete__deleteAndReload"
                      }
                      options={countries}
                      getOptionLabel={(option) => option.countryName}
                      isOptionEqualToValue={(option, value) => {
                        return option.countryName === value.countryName;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            label={t("App.deleteAndReload.filters.country")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.country}>
                          {option.countryName}
                        </li>
                      )}
                      onChange={(event, values, reason) => {
                        onChange(values);

                        setSelectedCommerces([]);
                        setSelectedSuppliers([]);

                        if (reason === "clear") {
                          setSuppliersList([]);
                          setCommercesList([]);
                        }

                        if (values !== null) {
                          onCountryChange(values);
                          setCountry(values);
                        } else {
                          setCountry({
                            country: "",
                            countryName: "",
                          });
                          onChange({
                            country: "",
                            countryName: "",
                          });
                        }
                      }}
                      value={value || null}
                    />
                  )}
                  control={control}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Autocomplete
                  name="commerce"
                  disabled={disabledFieldsCompanies()}
                  noOptionsText={t("App.validationMessages.noMatchFilter")}
                  className={errors.commerce ? "filters__error" : "filters"}
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.nameCompany === value.nameCompany
                  }
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo"
                  options={commercesList}
                  value={selectedCommerces}
                  disableCloseOnSelect
                  limitTags={3}
                  onChange={onCommerceChange}
                  getOptionLabel={(option) => option.nameCompany}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        className="autoComplete__checkbox"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nameCompany}
                    </li>
                  )}
                  renderTags={(value) => {
                    const numTags = value.length;
                    const limitTags = 2;

                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                            sx={{
                              width: "25%",
                            }}
                            key={index}
                            size="small"
                            label={option.nameCompany}
                          />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      label={t(
                        "App.applicationDeleteAndReload.filters.partner"
                      )}
                      sx={{
                        label: {
                          fontFamily: "Roboto !important",
                          fontSize: "14px !important",
                          translate: "0px -5px",
                          transformOrigin: "-45px -10px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Autocomplete
                  name="supplier"
                  disabled={disabledFieldsCompanies()}
                  noOptionsText={t("App.validationMessages.noMatchFilter")}
                  className={errors.supplier ? "filters__error" : "filters"}
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.nameCompany === value.nameCompany
                  }
                  filterOptions={filterOptions}
                  id="checkboxes-tags-demo"
                  options={suppliersList}
                  value={selectedSuppliers}
                  disableCloseOnSelect
                  limitTags={3}
                  onChange={onSupplierChange}
                  getOptionLabel={(option) => option.nameCompany}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        className="autoComplete__checkbox"
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nameCompany}
                    </li>
                  )}
                  renderTags={(value) => {
                    const numTags = value.length;
                    const limitTags = 2;

                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                            sx={{
                              width: "25%",
                            }}
                            key={index}
                            size="small"
                            label={option.nameCompany}
                          />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      label={t(
                        "App.applicationDeleteAndReload.filters.supplier"
                      )}
                      sx={{
                        label: {
                          fontFamily: "Roboto !important",
                          fontSize: "14px !important",
                          translate: "0px -5px",
                          transformOrigin: "-45px -10px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              width={"100%"}
              spacing={2}
              rowSpacing={3}
            >
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormControl className="full-width">
                  <InputLabel
                    id="documentType"
                    sx={{
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "0px -5px",
                      transformOrigin: "-50px -10px",
                      "&.Mui-focused": {
                        color: "#8C8D9D",
                      },
                    }}
                  >
                    {t("App.applicationDeleteAndReload.filters.documentType")}
                  </InputLabel>

                  <Controller
                    defaultValue=""
                    control={control}
                    name="documentType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        id="documentType"
                        labelId="documentType"
                        value={value}
                        className={
                          errors.documentType ? "filters__error" : "filters"
                        }
                        onChange={onChange}
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t(
                              "App.applicationDeleteAndReload.filters.documentType"
                            )}
                          </em>
                        </MenuItem>
                        <MenuItem value="stock">
                          <em>
                            {t("App.deleteAndReload.filters.typeInventory")}
                          </em>
                        </MenuItem>
                        <MenuItem value="sales">
                          <em>{t("App.deleteAndReload.filters.typeSales")}</em>
                        </MenuItem>
                        <MenuItem value="sales_stock">
                          <em>
                            {t("App.deleteAndReload.filters.typeSalesStock")}
                          </em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  name="font"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      name="font"
                      defaultValue
                      disabled={disabledFont()}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.font ? "filters__error" : "filters"}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      id="checkboxes-tags-demo"
                      options={fontsList}
                      value={value || null}
                      disableCloseOnSelect
                      onChange={(event, values, reason) => {
                        onChange(values);
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.deleteAndReload.filters.font")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  name="user"
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      className={errors.user ? "filters__error" : "filters"}
                      id="user"
                      label={t("App.applicationDeleteAndReload.filters.user")}
                      onChange={onChange}
                      value={value || ""}
                      sx={{
                        label: {
                          fontFamily: "Roboto !important",
                          fontSize: "14px !important",
                          translate: "0px -5px",
                          transformOrigin: "-45px -10px",
                          "&.Mui-focused": {
                            color: "#8C8D9D",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              width={"100%"}
              spacing={2}
              rowSpacing={3}
            >
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormControl className="full-width">
                  <InputLabel
                    id="applicationType"
                    sx={{
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "0px -5px",
                      transformOrigin: "-50px -10px",
                      "&.Mui-focused": {
                        color: "#8C8D9D",
                      },
                    }}
                  >
                    {t(
                      "App.applicationDeleteAndReload.filters.applicationType"
                    )}
                  </InputLabel>

                  <Controller
                    defaultValue=""
                    control={control}
                    name="requestType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        id="requestType"
                        labelId="requestType"
                        value={value}
                        style={{ width: "80% !important" }}
                        className={
                          errors.requestType ? "filters__error" : "filters"
                        }
                        onChange={onChange}
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t(
                              "App.applicationDeleteAndReload.filters.applicationType"
                            )}
                          </em>
                        </MenuItem>

                        <MenuItem value="BORRADO">
                          <em>
                            {t(
                              "App.applicationDeleteAndReload.filters.deletion"
                            )}
                          </em>
                        </MenuItem>

                        <MenuItem value="RECARGA">
                          <em>
                            {t("App.applicationDeleteAndReload.filters.reload")}
                          </em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="initialDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={
                          errors.initialDate ? "filters__error" : "filters"
                        }
                        inputFormat="YYYY-MM-DD"
                        label={t(
                          "App.applicationDeleteAndReload.filters.applicationInitialDate"
                        )}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label={t(
                              "App.applicationDeleteAndReload.filters.applicationInitialDate"
                            )}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="finalDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={
                          errors.finalDate ? "filters__error" : "filters"
                        }
                        inputFormat="YYYY-MM-DD"
                        label={t(
                          "App.applicationDeleteAndReload.filters.applicationFinalDate"
                        )}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label={t(
                              "App.applicationDeleteAndReload.filters.applicationFinalDate"
                            )}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid xs={12} md={3} sm={4} lg={3} item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={6} lg={7} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              disabled={disabledFilters()}
              className={
                disabledFilters()
                  ? "btn__applyFilter-reload-disabled"
                  : "btn__applyFilter-reload"
              }
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item xl={6} lg={5} md={12} sm={12} xs={12} width="100%">
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className="btn__deleteFilter-reload"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <ModalInfo
          responseData={filterResponse}
          open={error}
          onClose={() => setError(false)}
        />
      )}
    </Grid>
  );
};

export default FilterApplicationDeleteAndReload;

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useEffect, useState } from "react";

/**
 *  Componente encargado de mostrar las opciones de menú con icono, que corresponden
 * a las opcione de nivel configuradas con icono.
 * Para agregar opcioned de menú con iconos/ nivel 1 al role Admin, se deben agregar a la vble combinedList de sideBar.jsx
 * en la parte de la funcion fetchData donde se valida el rol del usuario
 * ---------------        PROPS ----------------------
 * setSelectOption - Guardar información de la opcion seleccionada
 * selectedOption - Opción seleccionada
 * setOpen - Mostrar la opciones de menú hijas de la opción seleccionada
 * role - Rol del usuario
 * listMenuOptions - Lista de opciones de menú del usuario en sesión
 * navigate - Función para navegar entre rutas
 * routeHome - Ruta de inicio del home para el  usuario
 * isHD - Determina si la pantalla es HD o no
 */
const MenusOptionsIconsDesktop = ({
  setSelectedOption,
  selectedOption,
  setOpen,
  role,
  listMenuOptions,
  navigate,
  routeHome,
  isHD,
  handleLogOut,
}) => {
  const { i18n, t } = useTranslation();

  const [initIcon, setInitIcon] = useState(0);

  const [finalIcon, setFinalIcon] = useState(4);

  const [finalIconHD, setFinalIconHD] = useState(6);

  // Determina si hay más opciones por mostrar
  const [moreIcons, setMoreIcons] = useState(true);

  // Determina si se muestra el botón de desplazamiento entre
  // las opciones con icono
  const [showMove, setShowMove] = useState(false);

  /**
   * Función para verificar si hay más opciones por mostrar
   * y realizar el desplazamiento de las opciones
   */
  const verifyMoreIcons = () => {
    // Actualiza los valores de los índices de las opciones a mostrar
    if (moreIcons) {
      setInitIcon(initIcon + 1);
      //valida si es HD para usar el límite de más opciones
      isHD ? setFinalIconHD(finalIconHD + 1) : setFinalIcon(finalIcon + 1);
    } else {
      setInitIcon(initIcon - 1);
      isHD ? setFinalIconHD(finalIconHD - 1) : setFinalIcon(finalIcon - 1);
    }
  };

  /**
   *
   * Determinar que si se debe mostrar el icono de navegación y cual ícono
   * mostrar según tamaño de la pantalla
   */
  useEffect(() => {
    // Validar que  la cantidad de opciones de menú son mayores al
    // limite a mostrar de opciones para pantallas que son y no son HD
    if (
      listMenuOptions.length > 0 &&
      ((isHD && listMenuOptions.length - 7 > 0) ||
        (!isHD && listMenuOptions.length - 5 > 0))
    ) {
      // Verificar si hay más opciones por mostrar y habilitar navegación hacia abajo
      changeMoreIcon();
      setShowMove(true);
    } else {
      // No mostrar icono de navegación porque no hay más opciones por mostrar
      setShowMove(false);
    }
  }, [listMenuOptions, initIcon, finalIcon, finalIconHD]);

  /**
   * Función para determinar el icono de navegación a mostrar
   */
  const changeMoreIcon = () => {
    // Verificar si hay más opciones por mostrar y habilitar navegación hacia abajo
    if (
      initIcon === 0 &&
      ((isHD && listMenuOptions.length - 1 - finalIconHD > 0) ||
        (!isHD && listMenuOptions.length - 1 - finalIcon > 0))
    ) {
      setMoreIcons(true);
    } // Verificar si se llegó al límite de opciones y habilitar navegación hacia arriba
    else if (
      ((isHD && listMenuOptions.length - 1 - finalIconHD === 0) ||
        (!isHD && listMenuOptions.length - 1 - finalIcon === 0)) &&
      initIcon > 0
    ) {
      setMoreIcons(false);
    }
  };

  /**
   * Función para realizar acciones al hacer click en una opción del menú
   * No se declaran funciones en uppercase 'Option' - ok
   * @param {object} item objeto con información de la opción de menú seleccionada

   */
  const handleOptionClick = (item) => {
    // Si el rol no es admin y la opción seleccionada no es Home
    // se habilita la visualización de las opciones hijas
    if (role !== "admin" && item.nameEnglish !== "Home") {
      setOpen(true);
    }
    // Si la opción seleccionada es Home entonces se redirige a la ruta de inicio que le
    // corresponde al usuario y oculta las opciones hijas
    else if (item.nameEnglish === "Home") {
      role === "admin"
        ? navigate("/admin/home")
        : routeHome !== "" && navigate("/user" + routeHome);
      setOpen(false);
    } else {
      setOpen(true);
    }
    setSelectedOption(item);
  };

  return (
    <Grid
      container
      width={isHD ? "96px" : "94px"}
      justifyContent={"center"}
      alignItems="center"
    >
      <Grid
        item
        width="100%"
        xs={12}
        md={12}
        lg={12}
        py={0.6}
        justifyContent={"center"}
        alignItems="center"
        textAlign={"center"}
      >
        {listMenuOptions.map(
          (item, idx) =>
            ((idx >= initIcon && idx <= finalIcon && !isHD) ||
              (idx >= initIcon && idx <= finalIconHD && isHD)) && (
              <Grid
                container
                key={idx}
                justifyContent={"center"}
                alignItems="center"
                width="100%"
                py={0.7}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent={"center"}
                    alignItems="center"
                    textAlign={"center"}
                    rowSpacing={0.5}
                  >
                    <Grid
                      item
                      textAlign={"center"}
                      justifyContent={"center"}
                      width="8rem"
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        alignItems="center"
                        textAlign={"center"}
                        width="100%"
                      >
                        <IconButton
                          disableRipple
                          sx={{
                            justifyContent: "center",
                            borderRadius: "15px",
                            textAlign: "center",
                            width: "48px",
                            height: "32px",
                            backgroundColor:
                              selectedOption?.menuOptionName ===
                              item.menuOptionName
                                ? "white"
                                : "#553AB6",
                          }}
                          color="inherit"
                          id={`menu-options-${idx}`}
                          onClick={() => {
                            handleOptionClick(item);
                          }}
                        >
                          <Tooltip
                            title={
                              item.nameEnglish === "Home"
                                ? t("App.sideBar.home")
                                : role === "admin"
                                ? t("App.sideBar.administration")
                                : i18n.language.includes("es")
                                ? item.menuOptionName
                                : item.nameEnglish
                            }
                            placement="right"
                            arrow={true}
                          >
                            <Icon
                              sx={{
                                fontSize: 22,
                                color: "white",
                                fill: "white",
                                minWidth: 0,
                                justifyContent: "center",
                              }}
                            >
                              {item.nameEnglish === "Home" ? (
                                <HomeOutlinedIcon
                                  sx={{
                                    fontSize: 22,
                                    color: "white",
                                    minWidth: 0,
                                    mr: "auto",
                                    justifyContent: "center",
                                    color:
                                      selectedOption?.nameEnglish === "Home"
                                        ? "#553AB6"
                                        : "white",
                                  }}
                                />
                              ) : role === "admin" ? (
                                <AdminPanelSettingsOutlinedIcon
                                  sx={{
                                    fontSize: 22,
                                    color:
                                      selectedOption?.menuOptionName ===
                                      "Administrar"
                                        ? "#553AB6"
                                        : "white",
                                    minWidth: 0,
                                    mr: "auto",
                                    justifyContent: "center",
                                  }}
                                />
                              ) : (
                                <img
                                  className={
                                    selectedOption?.menuOptionName ===
                                    item.menuOptionName
                                      ? "menu__icon__img__selected"
                                      : "menu__icon__img__normal"
                                  }
                                  src={`/assets/menuIcons/${item.icon}`}
                                  alt={item.menuOptionName}
                                />
                              )}
                            </Icon>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item style={{ width: "7rem" }}>
                      <p className={"icons__text_open"}>
                        {i18n.language.includes("es")
                          ? item.menuOptionName
                          : item.nameEnglish}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
      <Grid
        item
        width="100%"
        xs={12}
        md={12}
        lg={12}
        justifyContent={"center"}
        alignItems="center"
      >
        {showMove && (
          <Grid container justifyContent="center" alignItems="flex-end">
            <Grid item>
              <IconButton
                style={{ paddingLeft: "2.5rem" }}
                sx={{
                  width: "48px",
                  height: "32px",
                  justifyContent: "center",
                }}
                size="large"
                disableRipple={true}
                edge="start"
                color="inherit"
              >
                <Tooltip
                  title={t("App.sideBar.more")}
                  placement="right"
                  arrow={true}
                >
                  <Icon
                    onClick={() => {
                      verifyMoreIcons();
                    }}
                    sx={{
                      fontSize: 22,
                      color: "white",
                      fill: "white",
                      minWidth: 0,
                      justifyContent: "center",
                    }}
                  >
                    <img
                      className={"menu__icon__img__normal"}
                      src={`/assets/menuIcons/${
                        moreIcons ? "navigationDown.svg" : "navigationUp.svg"
                      }`}
                      alt={"navigation"}
                    />
                  </Icon>
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Grid container justifyContent="center" alignItems="flex-end">
          <Grid
            item
            style={{ position: "absolute", top: isHD ? "85%" : "92%" }}
          >
            <IconButton
              style={{ paddingLeft: "2.5rem" }}
              sx={{
                width: "48px",
                height: "32px",
                justifyContent: "center",
              }}
              size="large"
              disableRipple={true}
              edge="start"
              color="inherit"
            >
              <Tooltip
                title={t("App.sideBar.logout")}
                placement="right"
                arrow={true}
              >
                <ExitToAppIcon
                  onClick={handleLogOut}
                  sx={{ fontSize: 22, color: "white" }}
                ></ExitToAppIcon>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MenusOptionsIconsDesktop;

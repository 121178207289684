import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DonutsSection } from "../../../../components/common/CENCO/Reception/DonutsSection";
import { CardsSection } from "../../../../components/common/CENCO/Reception/CardsSection";
import { BoardsSection } from "../../../../components/common/CENCO/Reception/BoardsSection";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterBusinessGroup from "../../../../components/common/Filters/businessGroup/filterWithButtons";
import CustomSkeleton from "../../../../components/common/CustomSkeleton";
import NoInfoDashboard from "../../../../components/common/noInfoDashboard";
import { IndicatorsCards } from "../../indicatorsCards";
import { useSelector } from "react-redux";
import { getUser } from "../../../userSlice";

// PARA AGREGAR UN NUEVO HOME SE DEBE IR A IndicatorsCards.jsx Y
// AGREGAR LA INFORMACIÓN DEL NUEVO HOME
export const ReceptionCENFiscalHome = () => {
  /**
   * Mock
   */

  const requestInfoMock = {
    generalInfo: {
      lstUpdate: "2024-01-08 14:24",
      source: "Carvajal",
      documentsQty: "456",
      emailsReceived: "489",
    },
    quantityDonuts: [349, 789],
    quantityProcessedBar: {
      success: [12, 34, 56, 7],
      error: [24, 56, 7, 8],
      labels: ["Ene-23", "Feb-23", "Mar-23", "Apri-23"],
    },
  };

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  // TODO TODO EL PROCESAMIENTO DEL OBJETO SE HACE AQUÍ, A LOS COMPONENTES LLEGA LA INFO LIMPIA
  const [requestInfo, setRequestInfo] = useState(requestInfoMock);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingButtons, setIsLoadingButtons] = useState(true);

  const [noInfoDashboard, setNoInfoDashboard] = useState(false);

  /**
   * Handles
   */

  /**
   * Función que trae los indicadores de Home Recepcion
   * @param {*} data parámetros para traer info de home
   */
  const handleGetReceptionIndicators = async (data) => {
    try {
      buildInfoForBoards(requestInfoMock);
      setTimeout(() => {
        setIsLoading(false);
      }, [2000]);
    } catch (error) {
      console.log(
        "================= error at receptionCENFiscalHome.jsx ==================="
      );
      console.log(error);
      console.log(
        "================================================================="
      );
    }
  };

  /**
   * Construye el objeto requestInfo que contiene la información formateada a visualizar
   * @param {} data información de boards
   */
  const buildInfoForBoards = (data) => {
    setRequestInfo(data);
  };

  return (
    <Box className="box-container">
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={4} md={4} xs={12} sm={12}>
          <Grid container alignItems="center">
            <label className="display-large__primary-one">
              {t("App.homeReception.title")}
            </label>
          </Grid>
        </Grid>
        <Grid item lg={8} md={8} xs={12} sm={12} justifyContent="flex-end">
          <Grid container spacing={2} justifyContent={"flex-end"}>
            <IndicatorsCards
              excludeHome={"reception"}
              setIsLoadingButtons={setIsLoadingButtons}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          padding: "1%",
          marginBottom: "2%",
        }}
      >
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title2")}
          handleRequestIndicators={handleGetReceptionIndicators}
          setIsLoading={setIsLoading}
        />
      </Grid>
      {isLoading || isLoadingButtons ? (
        <CustomSkeleton />
      ) : !noInfoDashboard ? (
        <>
          <Grid item>
            <Grid
              container
              direction={{
                md: "row",
                sm: "column-reverse",
                xs: "column-reverse",
              }}
              columnSpacing={2}
              rowSpacing={2}
            >
              <Grid item md={8}>
                <DonutsSection t={t} requestInfo={requestInfo} />
              </Grid>
              <Grid item md={4}>
                <CardsSection t={t} requestInfo={requestInfo} />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <BoardsSection t={t} requestInfo={requestInfo} />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <NoInfoDashboard />
      )}
    </Box>
  );
};

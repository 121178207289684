import { React, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Input } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../components/common/languageSwitcher";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import ReCAPTCHA from "react-google-recaptcha";
import { recoveryPassword } from "../../services/auth/authServices";
import { REGEXP } from "../../consts/regexp";
import { useParams, useNavigate } from "react-router-dom";
import ModalInfo from "../../components/common/Modals/modalInfo";
import { CODES } from "../../consts/codes";

export const ValidateCredentials = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t("App.validationMessages.required"))
      .min(8, t("App.validationMessages.MinCharacters"))
      .max(15, t("App.validationMessages.MaxCharactersTwo"))
      .test("upperCase", t("App.validationMessages.minCapital"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/^(?=.*?[A-Z])/);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("lowerCase", t("App.validationMessages.minLowercase"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/(?=.*?[a-z])/);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("number", t("App.validationMessages.OneNumber"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/(?=.*?[0-9])/);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("specialChar", t("App.validationMessages.SpecialCharacters"), function (value) {
        if (!!value) {
          const schema = yup.string().matches(/(?=.*?[#?!@$%^&*-])/);
          return schema.isValidSync(value);
        }
        return true;
      }),
    confirmPassword: yup
      .string()
      .required(t("App.validationMessages.required"))
      .oneOf(
        [yup.ref("password"), null],t("App.validationMessages.minLowercase")), 
    code: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const checklist = console.log(watch(["password", "confirmPassword"]));

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleValidateCredentials = async (data) => {
    if (captcha.current.getValue()) {
      setIsLoading(true);
      setValidCaptcha(true);
      const obj = {
        email: params.email,
        password: data.password,
        confirmationCode: data.code,
      };
      const service = await recoveryPassword(obj);
      if (service.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        service.data.responseMessage = t("App.recoverPassword.wrongCode");
      } else {
        setIsSuccessfuly(true);
      }

      setResponseData(service);

      setIsOpenModal(true);
      setIsLoading(false);
    } else {
      setValidCaptcha(false);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showConfirmPassword: !valuesConfirmPassword.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const [acceptTerms, setacceptTerms] = useState(true);
  const [validCaptcha, setValidCaptcha] = useState(true);
  const [isSuccessfuly, setIsSuccessfuly] = useState(false);

  let captcha = useRef(null);

  const onChange = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    if (responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      navigate("/");
    }
  };

  /**
   * Responsive helpers
   */

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="validateCredentials__wrap">
      <div className="validateCredentials__leftSide">
        <Grid
          container
          item
          direction="column"
          spacing={2}
          className="inner-container"
        >
          <Grid item >
            <p
              className="display-large-two__primary-one"
              style={{ padding: "0", paddingTop: "0%"}}
            >
              {isDesktop
                ? t("App.validateCredentials.leftPanel.mainTitle")
                : t("App.validateCredentials.leftPanel.mainTitleSm")}
            </p>
          </Grid>

          <Grid item container spacing={0.5} direction="column">
            <Grid item>
              {isSuccessfuly && (
                <p className="subheading__success-color">
                  {t("App.changePasswordFT.leftPanel.successfulText")}
                </p>
              )}
            </Grid>

            <Grid item>
              <p className="subheading-one__text-black wrap-text">
                {t("App.validateCredentials.leftPanel.instructionText")}
              </p>
            </Grid>
          </Grid>

          <Grid item>
            <div className="validateCredentials__form ">
              <form onSubmit={handleSubmit(handleValidateCredentials)}>
                <Input
                  className={
                    errors.code
                      ? "validateCredentials__form-input--error"
                      : "validateCredentials__form-input"
                  }
                  disableUnderline
                  placeholder={t("App.validateCredentials.leftPanel.code")}
                  {...register("code")}
                  type="text"
                />

                {errors.code && (
                  <p className="validateCredentials__form-errorText">
                    {errors.code.message}
                  </p>
                )}
                <br />

                <Input
                  className={
                    errors.password
                      ? "validateCredentials__form-input--error"
                      : "validateCredentials__form-input"
                  }
                  disableUnderline
                  placeholder={t("App.validateCredentials.leftPanel.password")}
                  {...register(`password`, {
                    onChange: (e) => {
                      setValue(`password`, e.target.value);
                    },
                  })}
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <br />

                {errors.password && (
                  <p className="validateCredentials__form-errorText">
                    {errors.password.message}
                  </p>
                )}

                <Input
                  className={
                    errors.confirmPassword
                      ? "validateCredentials__form-input--error"
                      : "validateCredentials__form-input"
                  }
                  disableUnderline
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder={t(
                    "App.validateCredentials.leftPanel.confirmPassword"
                  )}
                  {...register("confirmPassword")}
                  type={
                    valuesConfirmPassword.showConfirmPassword
                      ? "text"
                      : "password"
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {valuesConfirmPassword.showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {errors.confirmPassword && (
                  <p className="validateCredentials__form-errorText">
                    {errors.confirmPassword.message}
                  </p>
                )}
                <br />

                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: "22px" },
                        color: "#543AB4",
                        "&.Mui-checked": {
                          color: "#543AB4",
                        },
                      }}
                    />
                  }
                  onChange={() => setacceptTerms(!acceptTerms)}
                  label={
                    <a
                      href="https://www.carvajaltys.com/politica-de-tratamiento-de-datos/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="default-text-one__link heading__primary-one"
                    >
                      {t("App.changePasswordFT.leftPanel.acceptPolicies")}
                    </a>
                  }
                />
                <br />

                <Grid
                  container
                  item
                  className="captcha-container"
                  direction="column"
                  style={{ marginTop: "2%" }}
                >
                  <Grid item>
                    <ReCAPTCHA
                      ref={captcha}
                      className="captcha-container__captcha-only"
                      sitekey="6LfkwBwgAAAAAIzpuBBjEZL8Pid8GvnYNq5EYE40"
                      onChange={onChange}
                      hl={t("App.login.leftPanel.recaptcha")}
                    />
                  </Grid>

                  <Grid item>
                    {validCaptcha ? (
                      <p className="label-invisible"></p>
                    ) : (
                      <p className="error-message">
                        {t("App.login.leftPanel.recaptchaMessage")}
                      </p>
                    )}
                  </Grid>
                </Grid>

                <button
                  className="validateCredentials__form-button"
                  type="submit"
                  disabled={acceptTerms || isLoading}
                  style={{ marginTop: "0.7rem" }}
                >
                  {!isLoading ? (
                    t("App.validateCredentials.leftPanel.button")
                  ) : (
                    <CircularProgress size={16} color="inherit" />
                  )}
                </button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>

      {checklist && (
        <div>
          <CheckIcon></CheckIcon>
        </div>
      )}

      <div className="validateCredentials__rightSide">
        <div className="language_selector__credentials">
        <LanguageSwitcher />
        </div>
        <Grid container direction="column" spacing={2}>
          <Grid item className="changePasswordFT__rightSide-instructions">
            <p className="changePasswordFT__rightSide-instructions--mainTitle">
              {t("App.changePasswordFT.rightPanel.mainTitle")}
            </p>

            <Grid className="changePasswordFT__rightSide-instructions--instructionSet">
              <p className="display-large-one__text-white">
                {t("App.changePasswordFT.rightPanel.instructionText")}
              </p>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {watch("password")?.length >= 8 &&
                  watch("password")?.length <= 15 ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-one__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionOne")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_LOWER_LETTER.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-one__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionTwo")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_UPPER_LETTER.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-one__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionThree")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_NUMBER.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-one__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionFour")}
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid style={{ display: "flex", alignItems: "center" }} item>
                  {REGEXP.ONE_SPECIAL_CHAR.test(watch("password")) ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <InfoOutlinedIcon fontSize="large" />
                  )}
                </Grid>

                <Grid item>
                  <label className="display-small-one__text-white">
                    {t("App.changePasswordFT.rightPanel.instructionFive")}
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ModalInfo
        title={t("App.recoverPassword.title")}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </div>
  );
};

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    TextField,
    useMediaQuery,
  } from "@mui/material";
  import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
  import { useTranslation } from "react-i18next";
  import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
  import React, { useEffect, useState } from "react";
  import {
    ListIndicatorType
  } from "../../../services/admin/adminServices";
  import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
  import { CODES } from "../../../consts/codes";
  import { Controller, useForm } from "react-hook-form";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
  import CheckBoxIcon from "@mui/icons-material/CheckBox";
  import { AdministrationAccessIndicators } from "../../../services/admin/adminServices";
  import { GetSuppliersService, GetTradersService } from "../../../services/user/mapping/mappingService";
  
  export const FilterNotifications = ({
    setData,
    setIsNoFilterFound,
    setNoContent,
    setIsLoadingData,
    setFilters,
    setDataStructure,
    indicatorTypeFilter,
    setOpenErrorModal,
  }) => {
    /**
     * Const
     */
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
    /**
     * Use Translation
     */
    const { t } = useTranslation();
  
    //Country selected
    const [isCountrySelected, setIsCountrySelected] = useState(false);
  
    //Fields selected limit
    const [isTypeIndicatorLimit, setIsTypeIndicatorLimit] = useState(false);
  
    //Loading fields
    const [isLoadingCountries, setIsLoadingCountries] = useState(false);
    const [isLoadingFields, setIsLoadingFields] = useState(false);

    /**
   * useMediaQuery for HD responsive
   */
    const matches = useMediaQuery("(min-width:1670px)");
    
    //List
    const [countryList, setCountryList] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companiesFilterList, setCompaniesFilterList] = useState([]);
    const [typeInsight, setTypeInsight] = useState([]);
    const [eanList, setEanList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    //Lista de opciones para tipo de notificaciones
    const listTypeNotification= [
        { label: t("App.downloadSalesForce.selectAll") },
        { label: t("App.allies.stock"), value: "stock" },
        { label: t("App.deleteAndReload.filters.typeSales"), value: "sales" },
      ];
    const typeComps = [
      {
        label: t("App.indicadors.businessPartner"),
        value: "C",
      },
      {
        label: t("App.listUsers.supplier"),
        value: "F",
      },
    ];
  
    //selectedOption
    const [country, setCountry] = useState("");
  
    //useForm
    const {
      handleSubmit,
      setValue,
      reset,
      watch,
      formState: { errors },
      control,
    } = useForm({
      defaultValues: {
        country: "",
        company: "",
        eanCompany: "",
        typeIndicator: [],
      },
    });
  
    /**
     *Limpia los filtros, resetea el objeto de filtros y aplica el no content
     */
    const handleCleanFilters = () => {
      reset();
      setFilters({
        country: "",
        eanCompany: "",
        companyName: "",
        lstTypeInsight: [],
      });
      setNoContent(true);
    };
  
    /**
     * Use Effect
     */
  
    /**
     * Fetchs countries, companies and type insights data
     */
    useEffect(() => {
      const fetchData = async () => {
        setIsLoadingCountries(true);
        //Consulta a los paises
        const getCountriesToSet = await getCountries();
        setCountryList(getCountriesToSet);
  
        //Agregamos los tipos de indicadores
        await getInsightTypes();
        setIsLoadingCountries(false);
      };
  
      fetchData();
    }, []);
  
    /**
     * Request to get countries
     * @returns countries from platform
     */
    const getCountries = async () => {
      const requestCountries = await GetGeographiesList();
  
      const responseCode = requestCountries.data.responseCode;
  
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS:
          return requestCountries.data.responseMessage;
  
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          return [];
  
        default:
          return [];
      }
    };
  
    /**
     * Este servicio permite obtener el listado de acceso a las tarjetas de indicadores para su configuracion de url
     * @returns listado de acceso a las tarjetas de indicadores
     */
    const getAccessIndicators = async (data) => {
      const requestAccessIndicators = await AdministrationAccessIndicators(data);
  
      const responseCode = requestAccessIndicators.data.responseCode;
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          return requestAccessIndicators.data.responseMessage;
  
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          return [];
  
        case CODES.COD_RESPONSE_ERROR_LIST:
          setOpenErrorModal(true);
          return [];
  
        default:
          return [];
      }
    };
  
    /**
     * Request to get companies
     * @returns list of companies from list of countries
     */
    const getCompanies = async (typeCompany) => {
      let companiesAdminRequest = {}
      //Condicional para obtener compañias tipo comercio o fabricante
      if(typeCompany=="C"){
        companiesAdminRequest = await GetTradersService(country);
      }else{
        companiesAdminRequest = await GetSuppliersService(country);
      }
  
      if (companiesAdminRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          companiesAdminRequest.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          companiesAdminRequest.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          const companies = companiesAdminRequest.data.responseMessage
          const eanList = companies.map((item) => item.eanCompany);
          const companyList = companies.map((item) => item.companyName);
          setCompaniesList(companies);
          setCompanyList(companyList);
          setEanList(eanList);
        }
      } else {
        setOpenErrorModal(true);
        setCompaniesFilterList([]);
      }
    };
  
    /**
     * Request to get type insights
     * @returns list of type insights
     */
    const getInsightTypes = async () => {
      const indicatorsType = await ListIndicatorType();
  
      const { responseMessage, responseCode } = indicatorsType.data;
  
      if (indicatorsType.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
          const indicatorType = responseMessage.map((indicator) => ({
            label: indicatorTypeFilter(indicator),
            value: indicator.indicatorType,
          }));
  
          const indicatorTypelist = [
            {
              label: t("App.downloadSalesForce.selectAll"),
              value: t("App.downloadSalesForce.selectAll"),
            },
            ...indicatorType,
          ];
  
          setTypeInsight(indicatorTypelist);
        } else {
          setOpenErrorModal(true);
        }
      } else {
        setOpenErrorModal(true);
      }
    };
  
    /**
     * Handles the input change of country field
     * @param {array} values list of selected countries
     */
    const handleCountryChange = async (values, reason) => {
      setCountry(values.country)
      //Reseteamos los demás campos
      setValue("typeIndicator", []);
      setValue("typeCompany", null);
      setValue("company", null);
      setValue("eanCompany", null);
      setCompaniesList([]);
      setEanList([]);
      setCompanyList([]);
      setIsTypeIndicatorLimit(false);
      setIsLoadingFields(true);
  
      //Si se limpia el field se settea "company" como null
      if (reason === "clear") {
        setIsCountrySelected(true);
        setIsLoadingFields(false);
      } else {
        setIsCountrySelected(true);
        setIsLoadingFields(false);
      }
    };
  
    const handleTypeCompanyChange = async (values, reason) => {
      if (reason === "clear") {
        setValue("typeIndicator", []);
        setValue("typeCompany", null);
        setValue("company", null);
      }else{
        await getCompanies(values.value)
        //Llenamos las listas de los eans y los nombres de compañias
        
      }
      
    }
  
    /**
     * Handles the input change of company field
     * @param {array} values list of selected countries
     * @param {string} reason type of selection: selectOption, removeOption
     */
    const handleCompanyChange = (values, reason) => {
      const company = companiesList.filter((item) => item.companyName === values);
  
      //Si se limpia el field se settea "eanCompany" como null
      if (reason === "clear") {
        setValue("eanCompany", null);
      } else {
        setValue("eanCompany", company[0].eanCompany);
      }
    };
  
    /**
     * Handles the input change of ean field
     * @param {array} values list of selected countries
     * @param {string} reason type of selection: selectOption, removeOption
     */
    const handleEanChange = (values, reason) => {
      const company = companiesList.filter((item) => item.eanCompany === values);
  
      //Si se limpia el field se settea "company" como null
      if (reason === "clear") {
        setValue("company", null);
      } else {
        setValue("company", company[0].companyName);
      }
    };
  
    /**
     * Handles the input change of type indicador field
     * @param {array} values list of selected countries
     */
    const handleTypeInsightChange = (values, reason, detail) => {
      if (values) {
        const withSelectAll = values.map((item) => item.value);
        const withoutSelectAll = values.filter(
          (item) => item.value !== t("App.downloadSalesForce.selectAll")
        );
        //Revisando si selectAll fue deseleccionado
        if (
          reason === "removeOption" &&
          detail.option.value === t("App.downloadSalesForce.selectAll")
        ) {
          setValue("typeIndicator", []);
          setIsTypeIndicatorLimit(false);
        }
        //Revisando si selectAll fue seleccionado
        if (
          reason === "selectOption" &&
          detail.option.value === t("App.downloadSalesForce.selectAll")
        ) {
          setValue("typeIndicator", typeInsight);
          setIsTypeIndicatorLimit(false);
        }
        //Revisando si todos los campos a excepcion de selectAll estan seleccionados
        if (
          values.length >= typeInsight.length - 1 &&
          reason === "selectOption" &&
          !withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
        ) {
          setValue("typeIndicator", typeInsight);
        }
        //Revisando si se deselecciono alguna opcion
        if (
          reason === "removeOption" &&
          values.length < typeInsight.length &&
          withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
        ) {
          setValue("typeIndicator", withoutSelectAll);
        }
      }
    };
  
    /**
     * Handles filter of admin indicators
     * @param {object} data filters to apply
     */
    const handleFilters = async (data) => {
      try {
        setIsLoadingData(true);
        setNoContent(false);
  
        const withoutSelectAll = data.typeIndicator.filter(
          (item) => item.value !== t("App.downloadSalesForce.selectAll")
        );
        const lstTypeIndicators = withoutSelectAll.map((item) => item.value);
        const obj = {
          eanProvider: data.eanCompany,
          provider: data.company,
          country: data.country.country,
          lstTypeIndicators: lstTypeIndicators,
        };
        setFilters(obj);
        const accessIndicatorsService = await getAccessIndicators(obj);
        //Llenamos los datos
        const finalArray = setDataStructure(accessIndicatorsService);
        if (finalArray.length > 0) {
          //Si el filtro trae información
          setIsNoFilterFound(false);
          setData(finalArray);
          setFilters(obj);
        } else {
          //SI no trae info
          setData([]);
          setIsNoFilterFound(true);
        }
  
        setData(finalArray);
        setIsLoadingData(false);
      } catch (e) {
        setOpenErrorModal(true);
        console.log("====== Error filterIndicators.jsx handleFilters ======");
        console.log(e);
        console.log("===========================================");
      }
    };
  
    /**
     * Función para habilitar o inhabilitar botón
     */
    const handleDisableElement = () => {
      if (watch("country")) {
        return false;
      } else {
        return true;
      }
    };
  
    /**
     * Función para habilitar o inhabilitar botón
     */
    const handleDisableElementByType = () => {
      if (watch("country")&& watch("typeCompany")) {
        return false;
      } else {
        return true;
      }
    };
  
    /**
     * Función para inhabilitar si país y compañía no son seleccionados
     */
    const handleDisableElementCompany = () => {
      if (watch("country") && watch("company") && watch("typeCompany")) {
        return false;
      } else {
        return true;
      }
    };
  
    return (
      <>
        <Accordion defaultExpanded={true} expanded={true}>
          <Grid>
            <AccordionSummary>
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                }}
              />
              <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
                {t("App.catalogue.filterName")}
              </p>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className="filter_container">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={4}
            rowSpacing={3}
            className="field_container__block "
            >
            <Grid
                item
                className="side-line__bottomAlign"
                md={9}
                sm={7}
                xs={8}
                style={{
                padding: "0% 0% 0% 0%",
                display: "flex",
                justifyContent: "center",
                maxWidth: "100%",
                }}
            >
                <form id="form-manage-bg" onSubmit={handleSubmit(handleFilters)}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="left"
                    rowSpacing={2}
                    className="filter_container"
                >
                    <Grid item className="field_container__block">
                        <Grid container direction="row" spacing={2}>
                            <Grid item mt={3}>
                                <Controller
                                    control={control}
                                    name={`country`}
                                    render={({
                                    field: { onChange, onBlur, value },
                                    }) => (
                                    <Autocomplete
                                        disabled={isLoadingCountries}
                                        options={countryList}
                                        className={
                                            matches
                                              ? errors.country
                                                ? "select__filters__errors__medium"
                                                : "select__filters__medium"
                                              : errors.country
                                              ? "select__filters__errors__xs"
                                              : "select__filters__xs"
                                          }
                                        isOptionEqualToValue={(option, value) =>
                                        option.country === value.country
                                        }
                                        getOptionLabel={(option) =>
                                        option.countryName
                                        }
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                            label={t("App.indicadors.country")}
                                            sx={{
                                                label: {
                                                fontFamily: "Roboto !important",
                                                fontSize: "14px !important",
                                                translate: "0px -5px",
                                                transformOrigin: "-45px -10px",
                                                "&.Mui-focused": {
                                                    color: "#8C8D9D",
                                                },
                                                },
                                            }}
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingCountries ? (
                                                    <CircularProgress
                                                        sx={{ color: "#4DCAFA" }}
                                                        size={15}
                                                    />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        );
                                        }}
                                        onChange={(event, values, reason) => {
                                        onChange(values);
                                        handleCountryChange(values, reason);
                                        }}
                                        value={value || null}
                                    />
                                    )}
                                />
                            </Grid>
                            <Grid item mt={3}>
                                <Controller
                                    control={control}
                                    name={`typeCompany`}
                                    render={({
                                    field: { onChange, onBlur, value },
                                    }) => (
                                    <Autocomplete
                                        disabled={handleDisableElement()}
                                        className={
                                            matches
                                              ? errors.typeCompany
                                                ? "select__filters__errors__medium"
                                                : "select__filters__medium"
                                              : errors.typeCompany
                                              ? "select__filters__errors__xs"
                                              : "select__filters__xs"
                                          }
                                        options={typeComps}
                                        value={value}
                                        onChange={(
                                        event,
                                        values,
                                        reason,
                                        detail
                                        ) => {
                                        onChange(values);
                                        handleTypeCompanyChange(
                                            values,
                                            reason,
                                            detail
                                        );
                                        }}
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                            label={t("App.adminCompanies.filters.companyType")}
                                            sx={{
                                                label: {
                                                fontFamily: "Roboto !important",
                                                fontSize: "14px !important",
                                                translate: "0px -5px",
                                                transformOrigin: "-45px -10px",
                                                "&.Mui-focused": {
                                                    color: "#8C8D9D",
                                                },
                                                },
                                            }}
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingFields ? (
                                                    <CircularProgress
                                                        sx={{ color: "#4DCAFA" }}
                                                        size={15}
                                                    />
                                                    ) : null}
                                                    {
                                                    params.InputProps
                                                        .endAdornment
                                                    }
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        );
                                        }}
                                    />
                                    )}
                                />
                            </Grid>
                            <Grid item mt={3}>
                                <Controller
                                    control={control}
                                    name={`company`}
                                    render={({
                                    field: { onChange, onBlur, value },
                                    }) => (
                                    <Autocomplete
                                        disabled={handleDisableElementByType()}
                                        className={
                                            matches
                                              ? errors.company
                                                ? "select__filters__errors__medium"
                                                : "select__filters__medium"
                                              : errors.company
                                              ? "select__filters__errors__xs"
                                              : "select__filters__xs"
                                          }
                                        options={companyList}
                                        value={value}
                                        onChange={(
                                        event,
                                        values,
                                        reason,
                                        detail
                                        ) => {
                                        onChange(values);
                                        handleCompanyChange(
                                            values,
                                            reason,
                                            detail
                                        );
                                        }}
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                            label={t("App.indicadors.company")}
                                            sx={{
                                                label: {
                                                fontFamily: "Roboto !important",
                                                fontSize: "14px !important",
                                                translate: "0px -5px",
                                                transformOrigin: "-45px -10px",
                                                "&.Mui-focused": {
                                                    color: "#8C8D9D",
                                                },
                                                },
                                            }}
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingFields ? (
                                                    <CircularProgress
                                                        sx={{ color: "#4DCAFA" }}
                                                        size={15}
                                                    />
                                                    ) : null}
                                                    {
                                                    params.InputProps
                                                        .endAdornment
                                                    }
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        );
                                        }}
                                    />
                                    )}
                                />
                            </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className="field_container__block">
                        <Grid container direction="row" spacing={2}>
                            <Grid item mt={3}>
                                <Controller
                                    control={control}
                                    name={`eanCompany`}
                                    render={({
                                    field: { onChange, onBlur, value },
                                    }) => (
                                    <Autocomplete
                                        disabled={handleDisableElement()}
                                        className={
                                            matches
                                              ? errors.eanCompany
                                                ? "select__filters__errors__medium"
                                                : "select__filters__medium"
                                              : errors.eanCompany
                                              ? "select__filters__errors__xs"
                                              : "select__filters__xs"
                                          }
                                        options={eanList}
                                        value={value}
                                        onChange={(event, values, reason, detail) => {
                                        onChange(values);
                                        handleEanChange(values, reason, detail);
                                        }}
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                            label={t("App.indicadors.ean")}
                                            sx={{
                                                label: {
                                                fontFamily: "Roboto !important",
                                                fontSize: "14px !important",
                                                translate: "0px -5px",
                                                transformOrigin: "-45px -10px",
                                                "&.Mui-focused": {
                                                    color: "#8C8D9D",
                                                },
                                                },
                                            }}
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingFields ? (
                                                    <CircularProgress
                                                        sx={{ color: "#4DCAFA" }}
                                                        size={15}
                                                    />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        );
                                        }}
                                    />
                                    )}
                                />
                            </Grid>
                            <Grid item mt={3}>
                                <Controller
                                    control={control}
                                    name={`typeIndicator`}
                                    render={({
                                    field: { onChange, onBlur, value },
                                    }) => (
                                    <Autocomplete
                                        disabled={handleDisableElement()}
                                        className={
                                            matches
                                              ? errors.typeIndicator
                                                ? "select__filters__errors__medium"
                                                : "select__filters__medium"
                                              : errors.typeIndicator
                                              ? "select__filters__errors__xs"
                                              : "select__filters__xs"
                                          }
                                        disableCloseOnSelect
                                        options={typeInsight}
                                        multiple
                                        limitTags={2}
                                        value={value}
                                        isOptionEqualToValue={(option, value) =>
                                        option.label === value.label
                                        }
                                        renderOption={(
                                        props,
                                        option,
                                        { selected }
                                        ) => (
                                        <li {...props} key={option.value + ""}>
                                            <Checkbox
                                            icon={icon}
                                            className="autoComplete__checkbox"
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            />
    
                                            {`${option.label}`}
                                        </li>
                                        )}
                                        onChange={(event, values, reason, detail) => {
                                        onChange(values);
                                        handleTypeInsightChange(
                                            values,
                                            reason,
                                            detail
                                        );
                                        }}
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                            label={t(
                                                "App.indicadors.table.typeInsight"
                                            )}
                                            sx={{
                                                label: {
                                                fontFamily: "Roboto !important",
                                                fontSize: "14px !important",
                                                translate: "0px -5px",
                                                transformOrigin: "-45px -10px",
                                                "&.Mui-focused": {
                                                    color: "#8C8D9D",
                                                },
                                                },
                                            }}
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    {isLoadingFields ? (
                                                    <CircularProgress
                                                        sx={{ color: "#4DCAFA" }}
                                                        size={15}
                                                    />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        );
                                        }}
                                        renderTags={(value, getTagProps) => {
                                        const limitTags = 2;
                                        const withoutSelectAll = value.filter(
                                            (item) =>
                                            item.value !==
                                            t("App.downloadSalesForce.selectAll")
                                        );
                                        const numTags = withoutSelectAll.length;
                                        return (
                                            <>
                                            {withoutSelectAll
                                                .slice(0, limitTags)
                                                .map((option, index) => (
                                                <Chip
                                                    className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                                    sx={{
                                                    width: "30%",
                                                    }}
                                                    key={index}
                                                    size="small"
                                                    label={`${option.label}`}
                                                />
                                                ))}
                                            {numTags > limitTags &&
                                                ` +${numTags - limitTags}`}
                                            </>
                                        );
                                        }}
                                    />
                                    )}
                                />
                            </Grid>
                            <Grid item mt={3}>
                                <Controller
                                defaultValue={null}
                                control={control}
                                name="initialDate"
                                render={({
                                field: { onChange, onBlur, value, name, ref },
                                formState,
                                }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                    inputFormat="YYYY-MM-DD"
                                    className={
                                        matches
                                        ? errors.initialDate
                                            ? "select__filters__errors__medium"
                                            : "select__filters__medium"
                                        : errors.initialDate
                                        ? "select__filters__errors__xs"
                                        : "select__filters__xs"
                                    }
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    
                                    renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={t("App.deleteAndReload.filters.initialDateReported")} // Agregar el label aquí
                                          placeholder={t("App.deleteAndReload.filters.initialDateReported")}
                                          sx={{
                                            label: {
                                              fontFamily: "Roboto !important",
                                              fontSize: "14px !important",
                                              translate: "0px -5px",
                                              transformOrigin: "-45px -10px",
                                              "&.Mui-focused": {
                                                color: "#8C8D9D",
                                              },
                                            },
                                            "& .MuiInputLabel-shrink": {
                                              transformOrigin: "top left", // Para garantizar que suba correctamente
                                            },
                                          }}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <React.Fragment>
                                                {isLoadingFields ? (
                                                  <CircularProgress sx={{ color: "#4DCAFA" }} size={15} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                            sx: {
                                                '& .MuiSvgIcon-root': {
                                                  color: "#543ab4", // Cambiar el color del ícono a #543ab4
                                                  fontSize: "20px", // Ajustar el tamaño del ícono (opcional)
                                                  position: 'relative', // Permite manipular la posición
                                                  top: '-5px', // Ajustar la posición vertical del ícono
                                                },
                                            },
                                        }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                                )}
                            />
                            </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row">
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item mt={3}>
                                    {" "}
                                    <Controller
                                    defaultValue={null}
                                    control={control}
                                    name="finalDate"
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        formState,
                                    }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="YYYY-MM-DD"
                                            className={
                                            matches
                                                ? errors.finalDate
                                                ? "select__filters__errors__medium"
                                                : "select__filters__medium"
                                                : errors.finalDate
                                                ? "select__filters__errors__xs"
                                                : "select__filters__xs"
                                            }
                                            value={value}
                                            onChange={(e) => {
                                            onChange(e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={t("App.allies.filter.finalDate")} // Agregar el label aquí
                                                  placeholder={t("App.allies.filter.finalDate")}
                                                  sx={{
                                                    label: {
                                                      fontFamily: "Roboto !important",
                                                      fontSize: "14px !important",
                                                      translate: "0px -5px",
                                                      transformOrigin: "-45px -10px",
                                                      "&.Mui-focused": {
                                                        color: "#8C8D9D",
                                                      },
                                                    },
                                                    "& .MuiInputLabel-shrink": {
                                                      transformOrigin: "top left", // Para garantizar que suba correctamente
                                                    },
                                                  }}
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                      <React.Fragment>
                                                        {isLoadingFields ? (
                                                          <CircularProgress sx={{ color: "#4DCAFA" }} size={15} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                      </React.Fragment>
                                                    ),
                                                    sx: {
                                                        '& .MuiSvgIcon-root': {
                                                          color: "#543ab4", // Cambiar el color del ícono a #543ab4
                                                          fontSize: "20px", // Ajustar el tamaño del ícono (opcional)
                                                          position: 'relative', // Permite manipular la posición
                                                          top: '-2px', // Ajustar la posición vertical del ícono
                                                        },
                                                    },
                                                }}
                                              />
                                            )}
                                        />
                                        </LocalizationProvider>
                                    )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item className="btn__group">
                <Grid
                container
                direction="row"
                columnSpacing={2}
                className="btn__group"
                >
                  <Grid item md={9} sm={8} xs={12} className="btn__container">
                    <Button
                      type="submit"
                      form="form-manage-bg"
                      disabled={handleDisableElementCompany()}
                      className={
                        handleDisableElementCompany()
                          ? "btn__applyFilter-disabled"
                          : "btn__applyFilter"
                      }
                    >
                      {t("App.listUsers.applyFilters")}
                    </Button>
                  </Grid>
                  <Grid item md={2} sm={2} xs={12} className="btn__container">
                    <Button
                      disabled={handleDisableElement()}
                      onClick={handleCleanFilters}
                      className="btn__deleteFilter"
                    >
                      {t("App.listUsers.cleanFilters")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };
  
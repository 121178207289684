import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ModalInfo from "./modalInfo";
import { CODES } from "../../../consts/codes";
import CommerceState from "../commerceState";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { BusinessRelations } from "../../../services/user/dashboard";
import CustomSkeleton from "../CustomSkeleton";

const ModalActiveCompanies = ({ userInfo, open, setOpen, infoChart }) => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseMessage, setResponseMesssage] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderSelected, setOrderSelected] = useState({});
  const [hasResults, setHasResults] = useState(false);
  const [hasFoundData, setHasFoundData] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (open && userInfo) {
      getProductsOC();
    }
  }, [open]);

  /**
   * Función para las compañias relacionadas
   */
  const getProductsOC = async () => {
    try {
      setIsLoading(true);

      const obj = {
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await BusinessRelations(obj);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setHasFoundData(responseMessage.length > 0 ? true : false);
            setData(responseMessage);
            break;

          default:
            setData([]);
            break;
        }
      } else {
        setData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setOpenErrorModal(true);
      console.log("---- ERROR getProductsOC ----");
      console.log(error);
    }
  };

  /**
   * Función para cerrar el modal
   */
  const handleCloseModal = async () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      aria-labelledby="dialog-title"
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(85,115,185,0.2)",
        },
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            height: "60%",
            width: "50%",
            maxWidth: "900px",
            maxHeight: "560px",
            boxShadow: "none",
            borderRadius: "22px",
            padding: "2rem",
          },
        },
      }}
    >
      <Grid container direction="column" gap={1}>
        <Grid item justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid container item justifyContent={"center"} gap={1}>
          <h1 className="display-large__primary-one">
            {t("App.homeDashboard.client.donuts.modalCommerce.title")}
          </h1>
          <Tooltip
            title={t("App.homeDashboard.client.donuts.modalCommerce.tooltip")}
          >
            <ErrorOutlineOutlinedIcon
              style={{ color: "#553AB6", fontSize: 20 }}
            />
          </Tooltip>
        </Grid>
        <Grid
          container
          item
          justifyContent={"center"}
          gap={1}
          marginBottom={1.5}
        >
          <p className="subheading__text-black">
            {t("App.homeDashboard.client.donuts.modalCommerce.totalCommerce") +
              ": " +
              infoChart?.labels[1]?.split(": ")[1]}
          </p>
          <p className="subheading__text-black">
            {" "}
            {t(
              "App.homeDashboard.client.donuts.modalCommerce.AssociatedCommerce"
            ) +
              ": " +
              infoChart?.labels[0]?.split(": ")[1]}
          </p>
        </Grid>
      </Grid>

      <DialogContent
        className="scroll-table full-width"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          direction="column"
          rowSpacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <CustomSkeleton />
          ) : hasFoundData ? (
            data.map((item, index) => (
              <Grid
                item
                className="register__container"
                key={index}
                borderBottom={index + 1 === data.length ? 0 : 1.5}
                borderColor="#DEE0E4"
              >
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems={"center"}
                >
                  <Grid item xs={9} md={9} lg={9} py={1} pl={1.5}>
                    <Grid
                      container
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <p className="subheading__text-black">{item.trader}</p>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <Grid
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <CommerceState value={item.relation} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <ModalInfo
        title={t("App.traceabilityExternal.modals.error.title")}
        responseData={{
          data: {
            responseMessage: t("App.traceabilityExternal.modals.error.message"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Dialog>
  );
};

export default ModalActiveCompanies;

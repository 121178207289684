import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { Box, Button, Grid, IconButton, Modal } from "@mui/material";

const ModalSeeReportCompanies = ({ data }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        className="button__primary-one"
        onClick={handleOpen}
        style={{
          padding: "0.5rem 0.1rem",
          borderRadius: "8px",
          border: "1px solid #553ab6",
          textTransform: "none",
          height: "24px",
          width: "112px",
          justifyContent: "space-between",
        }}
      >
        <p className="heading__primary-one headding-secondary__sm">
          {t("App.modalSeeCompanies.seeCompanies")}
        </p>
        {open && (
          <VisibilityOutlined sx={{ color: "#553ab6", height: "14px" }} />
        )}
        {!open && (
          <VisibilityOffOutlined sx={{ color: "#553ab6", height: "14px" }} />
        )}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal-box-seeCompanies">
          <Grid container direction="column">
            <Grid item container justifyContent="flex-end">
              <IconButton onClick={handleClose} component="span">
                <CloseIcon sx={{ color: "#543AB4", fontSize: "1.8rem" }} />
              </IconButton>
            </Grid>{" "}
            <Grid item>
              <p className="subheading__text-black">
                {t("App.listDynamicPeriodicReport.cen.table.reportCompanies1")}
                <br></br>
                {t("App.listDynamicPeriodicReport.cen.table.reportCompanies2")}
              </p>
            </Grid>
            <br />
            <Grid container className="box-list scroll-x">
              <Grid item direction="row" width="100%">
                <Grid container justifyContent={"space-between"} width="100%">
                  <Grid item xs={5} sm={6} lg={7.5}>
                    <h3 className="subheading__primary-one">
                      {t("App.modalSeeCompanies.company")}
                    </h3>
                  </Grid>
                  <Grid
                    item
                    direction="column"
                    xs={5}
                    sm={6}
                    lg={4.5}
                    justifyContent={"end"}
                  >
                    <p className="subheading__primary-one">
                      {t("App.modalSeeCompanies.ean")}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              {data.map((company) => (
                <Grid item direction="row" width="100%" height="100%">
                  <Grid
                    container
                    justifyContent={"space-between"}
                    width="100%"
                    height="100%"
                    alignItems={"center"}
                    alignContent={"center"}
                  >
                    <Grid
                      item
                      xs={5}
                      sm={6}
                      md={7}
                      lg={7.5}
                      key={company.eanCompany}
                    >
                      <p className="subheading__text-grey">
                        {company.nameCompany}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sm={6}
                      md={5.5}
                      lg={4.5}
                      justifyContent={"end"}
                      alignItems={"center"}
                      alignContent={"center"}
                      key={company.eanCompany}
                      height="100%"
                    >
                      <p className="subheading__text-grey">
                        {company.eanCompany}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <br />
            <Grid item container justifyContent="flex-end">
              <Button
                variant="contained"
                className="modal-button-action"
                onClick={handleClose}
              >
                {t("App.buttonLabels.accept")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ModalSeeReportCompanies;

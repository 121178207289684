import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const DynamicCheckBoxList = (props) => {
  const { t } = useTranslation();

  const checkBox = document.querySelectorAll(".MuiFormControlLabel-label");

  checkBox.forEach((element) => element.classList.add("subheading__text-grey"));

  const checkList = [
    {
      label: t("App.dynamicPeriodicReport.fields.check.company"),
      register: "company",
      default: true,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.merchant"),
      register: "merchant",
      default: true,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.pointOfSale"),
      register: "pointOfSale",
      default: true,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.channel"),
      register: "channel",
      default: true,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.format"),
      register: "format",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.department"),
      register: "department",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.city"),
      register: "city",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.hierarchy"),
      register: "hierarchy",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.seller"),
      register: "seller",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.tree"),
      register: "tree",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.product"),
      register: "product",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.brand"),
      register: "brand",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.colour"),
      register: "colour",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.size"),
      register: "size",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.dispatch"),
      register: "dispatch",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.standard"),
      register: "standard",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.weight"),
      register: "weight",
      default: false,
    },
    {
      label: t("App.dynamicPeriodicReport.fields.check.volume"),
      register: "volume",
      default: false,
    },
  ];

  const list = checkList;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
    >
      {list.map((item, idx) => {
        return (
          <Grid item xs={12} sm={3} key={item.register}>
            <FormControlLabel
              control={
                <Controller
                  defaultValue={item.default}
                  name={item.register}
                  control={props.control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={item.label}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

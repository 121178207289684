import {
  Button,
  Grid,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import moment from "moment";
import es from "date-fns/locale/es";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { DayOfMonthPickerSpecialFiles } from "./datOfMonthPickerSpecialFiles";

registerLocale("es", es);
export const GenerationDay = ({
  frequency,
  onChange,
  value,
  errors,
  setSelectedGenerationValue,
  setValue,
  staticFormValues,
  isEdit,
}) => {
  /**
   * Use Translation
   */

  const { t, i18n } = useTranslation();

  /**
   * Use State
   */

  // Estado para anchar el popover al elemento del estado
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  //Fecha actual cuando generationFrequency === 'Anual' (Vista calendario)
  const [currentDate, setCurrentDate] = useState(new Date());

  /**
   * useEffect
   */

  /**
   * Settea la información correspondiente al input de generationDay basado en el valor de generationFrequency (frequency) cuando cambia
   */
  useEffect(() => {
    let generationFrequency = staticFormValues.generationFrequency;
    let generationDay = staticFormValues.generationDay;

    setValue("generationDay", "");
    switch (frequency) {
      case generationFrequency[0].value:
        //Daily
        setValue("generationDay", "");
        break;
      case generationFrequency[1].value:
        //Weekly
        setValue("generationDay", generationDay.weekly[0].value);
        setSelectedGenerationValue(generationDay.weekly[0].value);
        break;
      case generationFrequency[2].value:
        //Monthly
        setValue("generationDay", 1);
        setSelectedGenerationValue(1);
        break;
      case generationFrequency[3].value:
        //Yearly
        setValue("generationDay", moment(new Date()).format("DD-MM-YYYY"));
        setSelectedGenerationValue(moment(new Date()).format("DD-MM-YYYY"));

        break;
    }
  }, [frequency]);

  /**
   * Settea la fecha actual cuando el usuario está editando
   */
  useEffect(() => {
    if (typeof isEdit === "object") {
      setCurrentDate(isEdit);
    }
  }, [isEdit]);

  /**
   * Handles
   */

  /**
   * Asigna el anchor para el popover actual
   * @param {*} event elemento actual
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Elimina el anchor para el popover
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Maneja la selección de fecha cuando generationFrequency = Anually
   * @param {*} date
   */
  const handleChangeYearlyDate = (date) => {
    onChange(moment(date).format("DD-MM-YYYY"));
    setSelectedGenerationValue(moment(date).format("DD-MM-YYYY"));
    setCurrentDate(date);
    handleClose();
  };

  /**
   * Settea la fecha actual dentro del calendario
   * @param {*} date fecha actual
   * @param {*} days número de días a agregar (default 1)
   * @returns fecha actual
   */
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  return (
    <>
      {frequency === "Diario" ? (
        <TextField
          onChange={(e) => {
            onChange(e);
          }}
          defaultValue={staticFormValues.generationDay.daily.value}
          sx={{
            label: {
              fontFamily: "Roboto !important",
              fontSize: "12px !important",
              translate: "0px -3px",
            },
          }}
          className={
            errors.generationDay
              ? "modalCreateUsers__form-input--error__medium"
              : "modalCreateUsers__form-input__medium"
          }
          value={value}
          InputProps={{
            readOnly: true,
          }}
          id="generationDay"
          name="generationDay"
          disabled
          label={staticFormValues.generationDay.daily.label}
        />
      ) : frequency === "Semanal" ? (
        <Select
          value={value}
          onChange={(e) => {
            onChange(e);
            setSelectedGenerationValue(e.target.value);
          }}
          className={
            errors.generationDay
              ? "modalCreateUsers__form-input--error__medium"
              : "modalCreateUsers__form-input__medium"
          }
          defaultValue={"Lunes"}
          id="generationDay"
          name="generationDay"
        >
          {staticFormValues.generationDay.weekly.map((dayOfWeek) => (
            <MenuItem value={dayOfWeek.value}>{dayOfWeek.label}</MenuItem>
          ))}
        </Select>
      ) : frequency === "Mensual" ? (
        <>
          <Button
            disableRipple
            disableFocusRipple
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  fontSize: 15,
                  color: "gray",
                }}
              />
            }
            onClick={handleClick}
            className={
              errors.generationDay
                ? "modalCreateUsers__form-input--error__medium fake-button"
                : "modalCreateUsers__form-input__medium fake-button"
            }
          >
            {typeof value !== "number" ? " " : value}
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                minWidth: "320px",
                minHeight: "300px",
                marginTop: "0.5%",
              },
            }}
          >
            <Grid container style={{ padding: "5%" }}>
              <DayOfMonthPickerSpecialFiles
                setSelectedGenerationValue={setSelectedGenerationValue}
                onChange={onChange}
                handleClose={handleClose}
              />
            </Grid>
          </Popover>
        </>
      ) : (
        <>
          <Button
            disableRipple
            disableFocusRipple
            endIcon={
              <ArrowDropDownIcon
                sx={{
                  fontSize: 15,
                  color: "gray",
                }}
              />
            }
            onClick={handleClick}
            className={
              errors.generationDay
                ? "modalCreateUsers__form-input--error__medium fake-button"
                : "modalCreateUsers__form-input__medium fake-button"
            }
          >
            {value}
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                minWidth: "320px",
                minHeight: "300px",
                marginTop: "0.5%",
              },
            }}
          >
            <Grid container style={{ padding: "5%" }}>
              <DatePicker
                minDate={addDays(new Date(), 1)}
                selected={currentDate}
                locale={i18n.language.includes("es") ? "es" : ""}
                onChange={handleChangeYearlyDate}
                inline
              />
            </Grid>
          </Popover>
        </>
      )}
    </>
  );
};

import { Chip, Grid, IconButton, Skeleton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterAdminCompanies } from "../../components/common/Filters/filterAdminCompanies";

import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";

import { NoSelectionComponent } from "../../components/common/noImages/noSelectionComponent";
import ModalInfo from "../../components/common/Modals/modalInfo";
import CreateCompany from "../../components/common/companyManager/createCompany";

const AdminCompanies = () => {
  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Table
   */
  const columns = [
    {
      name: "companyType",
      label: t("App.adminCompanies.table.companyType"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "eanCompany",
      label: t("App.adminCompanies.table.ean"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "companyId",
      label: t("App.adminCompanies.table.companyId"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "companyName",
      label: t("App.adminCompanies.table.companyName"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "companyCode",
      label: t("App.adminCompanies.table.companyCode"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "address",
      label: t("App.adminCompanies.table.address"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "city",
      label: t("App.adminCompanies.table.city"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "phone",
      label: t("App.adminCompanies.table.phone"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "contact",
      label: t("App.adminCompanies.table.contact"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "status",
      label: t("App.adminCompanies.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminCompanies.table.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminCompanies.table.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use State
   */
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [noContent, setNoContent] = useState(true);
  const [noFound, setNoFound] = useState(false);
  const [data, setData] = useState([]);
  const [filterApply, setFilterApply] = useState({
    country: "",
    companyName: "",
    companyId: "",
    eanCompany: "",
    status: "",
    companyType: "",
  });

  const [openModalError, setOpenModalError] = useState(false);
  const [responseDataError, setResponseDataError] = useState({});

  return (
    <Grid container direction="column">
      <Grid item style={{ marginTop: "2%" }}>
        <p className="display-large__primary-one">
          {t("App.adminCompanies.title")}
        </p>
      </Grid>

      <Grid item container justifyContent="flex-end" mb={1} mt={1}>
        <CreateCompany
          data={data}
          setData={setData}
          setIsLoadingData={setIsLoadingData}
          setNoContent={setNoContent}
          filterApply={filterApply}
        />
      </Grid>

      <Grid item className="companies_info_container full-width">
        <Grid container direction="column">
          <Grid item className="filter-container">
            <FilterAdminCompanies
              setFilterApply={setFilterApply}
              setData={setData}
              setIsLoadingData={setIsLoadingData}
              setNoContent={setNoContent}
              setOpenModalError={setOpenModalError}
              setResponseDataError={setResponseDataError}
              filterApply={filterApply}
              setNoFound={setNoFound}
            />
          </Grid>

          <Grid item>
            <Grid container direction="row" style={{ columnGap: "2%" }}>
              {filterApply.country.countryName && (
                <Grid item className="filter-tag">
                  <Chip
                    className="chip__filter"
                    label={`${t("App.adminCompanies.filters.country").replace(
                      "*",
                      ""
                    )}: ${filterApply.country.countryName}`}
                    variant="outlined"
                  />
                </Grid>
              )}

              {filterApply.companyType && (
                <Grid item className="filter-tag">
                  <Chip
                    className="chip__filter"
                    label={`${t(
                      "App.adminCompanies.filters.companyType"
                    ).replace("*", "")}: ${filterApply.companyType}`}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            style={{
              marginTop: !noContent ? "-2px" : "2%",
            }}
            className="full-width"
          >
            {!noContent ? (
              !noFound ? (
                isLoadingData ? (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    data={data}
                    columns={columns}
                    options={skeletonOptions}
                  />
                ) : (
                  <MUIDataTable
                    className="dataTable__salesForce datatable-style"
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )
              ) : (
                <NoMatchImage />
              )
            ) : (
              <NoSelectionComponent type="adminCompanies" />
            )}
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={responseDataError}
        open={openModalError}
        onClose={() => {
          setOpenModalError(false);
        }}
      />
    </Grid>
  );
};

export default AdminCompanies;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@mui/material";
import React from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { ApplyFiltersGeographies } from "../../../services/admin/geographies/geographiesService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FilterGeographies = (props) => {
  const { t } = useTranslation();

  const handleFilters = async (data) => {
    try {
      const obj = {
        countryName: data.country ? data.country : "",
        country: data.isoCode ? data.isoCode : "",
      };
      props.setNoContent(false);
      props.setFilterNotFound(false);
      props.setIsLoadingData(true);
      props.setData(props.skeletonData);

      const geographiesFilterRequest = await ApplyFiltersGeographies(obj);

      if (
        geographiesFilterRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        geographiesFilterRequest.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
      ) {
        const finalRows = geographiesFilterRequest.data.responseMessage;

        const processedRows = finalRows.map((geography) => {
          return {
            isoCode: geography.country,
            countryName: geography.countryName,
            level: geography.totalLevels,
            currency: geography.currency,
          };
        });
        if (processedRows.length === 0) {
          props.setFilterNotFound(true);
        }
        props.setData(processedRows);

        props.setFilterApply({
          country: data.country ? data.country : null,
          isoCode: data.isoCode ? data.isoCode : null,
        });

        props.setIsLoadingData(false);
      } else {
        props.setData([]);
        props.setFilterNotFound(true);
        props.setFilterApply({
          country: data.country ? data.country : null,
          isoCode: data.isoCode ? data.isoCode : null,
        });

        props.setIsLoadingData(false);
      }
    } catch (error) {
      console.log(
        "================= ERROR FILTERGEOGRAPHIES.JSX ==================="
      );
      console.log(error);
      console.log(
        "================================================================="
      );
    }
  };

  const schema = yup.object().shape({
    isoCode: yup.string(),
    country: yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  return (
    <Accordion expanded>
      <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
        <TuneOutlinedIcon
          sx={{
            fontSize: 20,
            color: "#543ab4",
          }}
        />
        <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
          {" "}
          {t("App.listUsers.filter")}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid xs={12} sm={6} md={8} item>
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Controller
                        name={"country"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value || ""}
                            inputProps={{
                              maxLength: 200,
                            }}
                            InputProps={{
                              style: {
                                fontSize: "14px",
                                fontFamily: "Roboto",
                                height: "44px",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                fontFamily: "Roboto",
                                height: "44px",
                              },
                            }}
                            className={
                              errors.country
                                ? "filter-fields-errorText"
                                : "filter-fields"
                            }
                            placeholder={t("App.geographies.filters.country")}
                            onChange={onChange}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Controller
                        name={"isoCode"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            value={value || ""}
                            placeholder={t("App.geographies.filters.isoCode")}
                            InputProps={{
                              style: {
                                fontSize: "14px",
                                fontFamily: "Roboto",
                                height: "44px",
                              },
                            }}
                            inputProps={{
                              maxLength: 3,
                            }}
                            InputLabelProps={{
                              style: {
                                fontSize: "14px",
                                fontFamily: "Roboto",
                                height: "44px",
                              },
                            }}
                            className={
                              errors.isoCode
                                ? "filter-fields-errorText"
                                : "filter-fields"
                            }
                            onChange={onChange}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <Grid
              container
              direction="row"
              className="buttonfilt-container"
              gap={2}
            >
              <Grid item>
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  className={
                    watch("isoCode") || watch("country")
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                  disabled={watch("isoCode") || watch("country") ? false : true}
                  type="submit"
                  form="hook-form"
                >
                  {t("App.userDetail.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  onClick={() => {
                    setValue("country", "");
                    setValue("isoCode", "");
                    props.setData(props.unfilteredData);
                    props.setFilterApply({
                      country: "",
                      isoCode: "",
                    });
                    props.setFilterNotFound(false);
                  }}
                  disableRipple
                  className="btn__deleteFilter"
                >
                  {t("App.userDetail.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { CODES } from "../../../consts/codes";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import LoopIcon from "@mui/icons-material/Loop";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import MUIDataTable from "mui-datatables";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";

import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";

import ModalInfo from "../../../components/common/Modals/modalInfo";
import CircularProgress from "@mui/material/CircularProgress";
import { AutoCompleteDownload } from "../../../components/common/Download/autoCompleteDownload";
import {
  GetStoresTradersList,
  DownloadStoresService,
  GetStoresDownloadedFiles,
  DownloadStoresDownloadedFiles,
} from "../../../services/user/stores/storesService";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../components/common/fileState";

export const DownloadStores = () => {
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [responseLoadSalesForce, setResponseLoadSalesForce] = useState({});
  const [tradersList, setTradersList] = useState([]);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  const [traderSelected, setTraderSelected] = useState([]);

  const [foundResults, setFoundResults] = useState(false);
  const [openSuccessLoadModal, setOpenSuccessLoadModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const userInfo = useSelector(getUser);
  //No content
  const [noStores, setNoStores] = useState(false);

  const columns = [
    {
      name: "startDate",
      label: t("App.downloadSalesForce.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "endDate",
      label: t("App.downloadSalesForce.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.downloadSalesForce.user"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "file",
      label: t("App.downloadCatalogue.downloadTable.downloadFile"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                className="default-text__linkBlue__sm"
                onClick={(event) =>
                  handleDownloadFile(value.fileName, value.pathFileName)
                }
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.salesForce.status"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];
  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,

    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };
  const skeletonOptions = {
    selectableRows: "none",
    responsive: "simple",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,

    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const handleDownloadFile = async (fileName, pathFileName) => {
    const obj = {
      fileName: fileName,
      pathFileName: pathFileName,
    };
    try {
      const fileService = await DownloadStoresDownloadedFiles(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log(
        "=============== ERROR downloadStores.JSX handleDownloadFile ====================="
      );
      console.log(error);
      console.log(
        "============================================================================="
      );
    }
  };

  const handleDownload = async (event) => {
    event.preventDefault();

    try {
      const eanTraders = traderSelected.find(
        (trader) => trader.trader === t("App.downloadSalesForce.selectAll")
      )
        ? []
        : traderSelected.map((trader) => {
            if (trader.ean) {
              return trader.ean;
            }
          });
      const pointSale = {
        userName: userInfo.firstName + " " + userInfo.lastName,
        emailUser: userInfo.email,
        eanProvider: userInfo.company.eanCompany,
        country: userInfo.company.country,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        lstEanTraders: eanTraders,
        country: userInfo.company.country,
      };

      setIsLoadingDownload(true);

      const downloadService = await DownloadStoresService(pointSale);

      if (downloadService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          downloadService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          setResponseLoadSalesForce(downloadService);
          setOpenSuccessLoadModal(true);
          setRefresh(!refresh);
          setTraderSelected([]);
        } else {
          setResponseLoadSalesForce(downloadService);
          setOpenSuccessLoadModal(true);
        }
      }

      setIsLoadingDownload(false);
    } catch (error) {
      console.log(
        "=============== ERROR downloadStores.JSX handleDownload ====================="
      );
      console.log(error);
      console.log(
        "============================================================================="
      );
    }
  };

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        setLoading(true);
        setIsLoadingRefresh(true);
        setData([]);

        //TODO: Validar xq cuando entro a este jsx se carga 2 veces

        if (mounted && userInfo) {
          //TODO: Ajustar el objeto GetStoresDownloadedFiles por integracion de grupo empresarial (yo lo ajuste creo q esta bien jajaja)

          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 11,
          };

          const filesService = await GetStoresDownloadedFiles(obj);

          if (filesService.status === CODES.COD_RESPONSE_HTTP_OK) {
            setLoading(false);

            if (filesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS) {
              const responseMessage = filesService.data.data;

              if (responseMessage.length > 0) {
                setFoundResults(true);

                const ProcessRows = responseMessage.map((item, idx) => {
                  return {
                    id: !item.idDownload ? "  " : item.idDownload,
                    startDate: !item.starDate
                      ? "  "
                      : moment(item.starDate).format("YYYY-MM-DD hh:mm"),
                    endDate: !item.endDate
                      ? "  "
                      : moment(item.endDate).format("YYYY-MM-DD hh:mm"),
                    user: !item.userLoad ? "  " : item.userLoad,
                    file: !item.fileName
                      ? "  "
                      : {
                          fileName: item.fileName,
                          pathFileName: item.pathFileName,
                        },
                    state: !item.state ? " " : item.state,
                  };
                });

                setNoStores(false);
                setData(ProcessRows);
              } else {
                setNoStores(true);
                setFoundResults(false);
              }
            }
          }
          setIsLoadingRefresh(false);
        }
      } catch (error) {
        console.log(
          "=============== ERROR downloadStores.JSX useEffect ====================="
        );
        console.log(error);
        console.log(
          "============================================================================="
        );
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [refresh, userInfo]);

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted && userInfo) {
          const obj = {
            idCompany: userInfo.company.idCompany,
          };
          const tradersService = await GetStoresTradersList(obj);
          if (tradersService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              tradersService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const responseMessage = tradersService.data.data;
              const traderList = responseMessage.map((trader) => {
                return { trader: trader.companyName, ean: trader.eanCompany };
              });

              const completeList = [
                { trader: t("App.downloadSalesForce.selectAll") },
                ...traderList,
              ];

              setTradersList(completeList);
            }
          }
        }
      } catch (error) {
        console.log(
          "=============== ERROR downloadStores.JSX useEffect ====================="
        );
        console.log(error);
        console.log(
          "============================================================================="
        );
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [refresh, userInfo]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ModalInfo
        title={t("App.stores.download")}
        responseData={responseLoadSalesForce}
        open={openSuccessLoadModal}
        onClose={() => {
          setOpenSuccessLoadModal(false);
        }}
      />

      <Grid item>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <p className="subheading__text-black">
              {t("App.salesForce.textInfo")}
            </p>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <AutoCompleteDownload
                  optionSelected={traderSelected}
                  setOptionSelected={setTraderSelected}
                  optionsList={tradersList}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={traderSelected.length > 0 ? false : true}
                  disableRipple
                  className={
                    traderSelected.length > 0
                      ? "btn__filled__blue"
                      : "btn__filled__disabled"
                  }
                  onClick={handleDownload}
                >
                  {!isLoadingDownload ? (
                    t("App.buttonLabels.download")
                  ) : (
                    <CircularProgress size={21} color="inherit" />
                  )}
                </Button>
              </Grid>

              <Grid item>
                {isLoadingDownload && (
                  <label className={"label-Primary__small"}>
                    {t("App.salesForce.paragraphUpload")}
                  </label>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className="u-marginB u-marginT"
        spacing={2}
      >
        <Grid item>
          {!noStores && (
            <Button
              disableRipple
              startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
              className="btn__outlined__blue"
              onClick={() => {
                setRefresh(!refresh);
              }}
            >
              {!isLoadingRefresh ? (
                t("App.buttonLabels.refresh")
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </Button>
          )}
        </Grid>
      </Grid>
      {foundResults && (
        <>
          <Grid item className="u-marginB">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
              </Grid>
              <Grid item>
                <p className="display-small__primary-one">
                  {t("App.downloadSalesForce.subtitle")}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="u-marginB" style={{ width: "100%" }}>
            {loading ? (
              <MUIDataTable
                className="dataTable__salesForce"
                data={data}
                columns={columns}
                options={skeletonOptions}
              />
            ) : (
              <MUIDataTable
                className="dataTable__salesForce"
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </>
      )}
      {!foundResults && (
        <Grid container className="col-flex">
          <NoInfoComponent type="download" />
        </Grid>
      )}
    </Grid>
  );
};

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetTradersService } from "../../../services/user/deleteAndReload/deleteAndReload";
import moment from "moment";
import ModalInfo from "../Modals/modalInfo";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import {
  GetListOrders,
  GetOCNumbers,
} from "../../../services/user/adminPurchaseOrder/PurcharseOrderProviderService";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterAdminPurchaseOrders = ({
  setHasContent,
  setHasFound,
  setData,
  setIsLoading,
  userInfo,
  setOpenErrorModal,
  setSelectedRows,
  setObj,
  filterData,
}) => {
  /**
   * t de useTranslation para las traducciones
   */
  const { t } = useTranslation();

  /**
   * Schema para la validación del formulario de los filtros
   */
  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({ countryName: yup.string(), country: yup.string() })
      .required(),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use State
   */
  const [countries, setCountries] = useState([]);
  const [commercesList, setCommercesList] = useState([]);
  const [eanCommerces, setEanCommerces] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadingCommerce, setIsLoadingCommerce] = useState(false);
  const [isLoadingEan, setIsLoadingEan] = useState(false);
  const [filterResponse, setFilterResponse] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [selectTraders, setSelectTraders] = useState([]);
  const [ocNumbers, setOcNumbers] = useState([]);

  const [OCSelected, setOCSelected] = useState([]);

  /**
   * useEffect para obtener el listado de países disponibles en la plataforma
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        const geographiesService = await GetGeographiesList();

        const { status, data } = geographiesService;

        if (
          status === CODES.COD_RESPONSE_HTTP_OK &&
          data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          setCountries(data.responseMessage);
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log(
          "==============Error filterAdminPurchaseOrders.jsx useEffect GetGeographies======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
  }, []);

  /**
   * Funciones para los disabled
   * 
   * disableCommerceEan: Disabled para campos de comercio y ean, dependen de pais y de consulta a BD
   * disableByCountryCommerce: Disabled para Numero OC, Date OC y botones, dependen de los campos obligatorios (pais y comercio)
   */
  const disableCommerceEan = () => !(watch("country") && !isLoadingCommerce);
  const disableByCountryCommerce = () => !(watch("country") && watch("commerce"));



  /**
   *
   * Función para filtrar la data por país, comercio, ean comercio, número OC y/o fecha OC
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      setIsLoading(true);

      const aux = {
        eanProvider: userInfo.company.eanCompany,
        country: data.country.country,
        lstEansTraders: [data.commerce.eanCommerce],
        ocDate: data.ocDate ? formatDate(data.ocDate) : null,
        lstOcNumbers: data.ocNumber
          ? data.ocNumber.find(
              (item) => item.label === t("App.downloadSalesForce.selectAll")
            )
            ? []
            : data.ocNumber.map((item) => item.label)
          : null,
      };
      setObj(aux);
      await filterData(aux);
      setSelectedRows([])
      setIsLoading(false);
    } catch (error) {
      setOpenErrorModal(true);
      setHasContent(true);
      setHasFound(false);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Formatear la fecha
   */
  const formatDate = (date) => {
    let prueba = new Date(date);
    return moment(prueba).format("YYYY-MM-DD");
  };
  /**
   *    * Función que se acciona cuando se cambia el valor del autocomplete de ocNumber para cambiar el valor del campo ocNumber

   * @param {*} event  
   * @param {*} values comercios
   * @param {*} reason tipo de selección; selectOption, removeOption
   * @param {*} detail  opción elegida
   */

  const onCommerceChange = async (values, reason, detail) => {
    setValue("ocNumber", []);
    setValue("ocDate", null);
    if(reason=="clear"){
      setValue("ean",[]);
    }else{
      setValue("ean", getValues("commerce").eanCommerce);
    }
    await getOCNumbersByCommerce();
  };

  /**
   * Función para obtener los números de OC por comercio
   */
  const getOCNumbersByCommerce = async () => {
    if (getValues("commerce")) {
      let commerce = getValues("commerce");
      commerce = [commerce.eanCommerce];
      await fetchOCNumbers(commerce);
    }
  };

  /**
   *
   * Función que se acciona cuando se cambia el valor del autocomplete de ocNumber para cambiar el valor del campo ocNumber
   *
   * @param {Event} event
   * @param {Values} values ocnumbers
   * @param {String} reason tipo de selección; selectOption, removeOption
   * @param {Object} detail opción elegida
   */
  const onOcNumberChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const ocNumber of values) {
      if (ocNumber.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeTrader = ocNumbers.filter(
      (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeTrader && reason === "selectOption")
    ) {
      setValue("ocNumber", ocNumbers);
    } else if (values.length === ocNumbers.length - 1) {
      setValue(
        "ocNumber",
        values.filter(
          (ocNumber) => ocNumber.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("ocNumber", values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("ocNumber", []);
      }
    }
  };

  /**
   *
   * Función para manejar los cambios en los campos de ean y comercio:
   * Cuando se selecciona un ean automáticamente se selecciona el comercio y viceversa
   * Cuando se selecciona más de un comercio se vacía los campos de ean y número OC
   *
   * @param {Object} object
   */
  const handleChangeEanOrCommerce = (object) => {
    const { list, reason, type } = object;
    if (reason === "clear") {
      setValue(type === "commerce" ? "ean" : "commerce", null);

      setValue("ocNumber", []);
    }

    if (list?.length > 0) {
      const eanOrCommerceSelected = list[0]?.eanCommerce || list;

      const eanOrCommerce = commercesList.filter(
        (commerce) => commerce?.eanCommerce === eanOrCommerceSelected
      );

      let eanValue = list.length === 1 ? eanOrCommerce[0]?.eanCommerce : null;
      let commerceValue = list ? eanOrCommerce[0] : null;

      type === "ean"
        ? setValue("commerce", commerceValue)
        : setValue("ean", eanValue);
    }
  };

  /**
   *
   * Función para actualizar el listado de comercios cuando se cambia el país
   *
   * @param {Object} data
   */
  const onCountryChange = async ({ country }) => {
    setCommercesList([]);
    setEanCommerces([]);
    setValue("commerce", null);
    setValue("ean", []);
    setValue("ocNumber", []);
    setValue("ocDate", null);

    setIsLoadingEan(true);
    setIsLoadingCommerce(true);

    fetchTraders(country);
  };

  /**
   *
   * Función para obtener los comercios por país
   *
   * @param {String} country
   */
  const fetchTraders = async (country) => {
    try {
      const tradersService = await GetTradersService({ country });

      if (
        tradersService.status === CODES.COD_RESPONSE_HTTP_OK &&
        tradersService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = tradersService.data.responseMessage;

        let traders = responseMessage.map((item) => {
          return {
            commerceName: item.companyName,
            eanCommerce: item.eanCompany,
          };
        });

        let eanTraders = responseMessage.map(
          (eanTrader) => eanTrader.eanCompany
        );

        setCommercesList(traders);
        setEanCommerces(eanTraders);
      } else {
        setOpenErrorModal(true);
      }

      setIsLoadingEan(false);
      setIsLoadingCommerce(false);
    } catch (error) {
      setOpenErrorModal(true);

      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para obtener los números de OC por comercio
   */
  const fetchOCNumbers = async (commerce) => {
    try {
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        lstEansTraders: commerce,
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetOCNumbers(obj);
      if (
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST &&
        status === CODES.COD_RESPONSE_SUCCESS
      ) {
        let oclist = responseMessage.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        oclist =
          oclist.length > 1
            ? [{ label: t("App.downloadSalesForce.selectAll") }, ...oclist]
            : oclist;
        setOcNumbers(oclist);
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);

      console.log("=========== fetchOCNumbers ===========");
      console.log(error);
      console.log("====================================");
    }
  };
  /**
   * Función para limpiar los campos del formulario
   */
  const handleCleanFilters = () => {
    setValue("ocDate", null);
    watch("ocDate");

    setHasContent(false);
    // setHasFiltersTriggered(false);
    reset();
  };

  /**
   * Función para actualizar el estado de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect para actualizar el estado de isDesktop basado en el tamaño de la ventana del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * Función para inhabilitar el botón de limpiar filtros
   *
   * @returns true o false
   */
  const isCleanDisabled = () => {
    const fields = watch();

    if (!fields.country) {
      return true;
    }

    return false;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        xl={9}
        lg={9}
        md={9}
        sm={8}
        xs={12}
        item
        className="side-line-contact__bottomAlign"
        rowSpacing={2}
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
          >
            <Grid
              container
              direction="row"
              rowSpacing={2}
              width="100%"
              spacing={2}
              paddingBottom={2}
            >
              <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                <Controller
                  name="country"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={errors.country ? "filters__error" : "filters"}
                      options={countries}
                      getOptionLabel={(option) => option.countryName}
                      isOptionEqualToValue={(option, value) =>
                        option.country === value.country
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            label={t("App.deleteAndReload.filters.country")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.country}>
                          {option.countryName}
                        </li>
                      )}
                      onChange={(event, values, reason) => {
                        onChange(values);

                        if (reason === "clear") {
                          setCommercesList([]);
                          setEanCommerces([]);
                          reset();
                        }

                        if (values !== null) {
                          onCountryChange(values);
                        }
                      }}
                      value={value || null}
                    />
                  )}
                  control={control}
                />
              </Grid>

              <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                <Controller
                  defaultValue=""
                  name="commerce"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={disableCommerceEan()}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.commerce ? "filters__error" : "filters"}
                      isOptionEqualToValue={(option, value) =>
                        option.eanCommerce === value.eanCommerce
                      }
                      options={commercesList}
                      value={value || null}
                      onChange={(
                        event,
                        values,
                        reason,
                        detail
                      ) => {
                        onChange(values);
                        onCommerceChange(values, reason, detail);
                      }}
                      getOptionLabel={(option) => option.commerceName}
                      renderInput={(params) => {
                        return (
                          <TextField
                          label={t("App.deleteAndReload.filters.commerce")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.eanCommerce}>
                          {option.commerceName}
                        </li>
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              rowSpacing={2}
              spacing={2}
              width="100%"
            >
              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  name="ean"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={disableCommerceEan()}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.ean ? "filters__error" : "filters"}
                      isOptionEqualToValue={(option, value) => option === value}
                      id="checkboxes-tags-demo"
                      options={eanCommerces}
                      value={value || null}
                      onChange={(e, values, reason) => {
                        const obj = { list: values, reason, type: "ean" };

                        handleChangeEanOrCommerce(obj);

                        onChange(values);
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.businessRules.table.eanCommerce")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingEan ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  name="ocNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={disableByCountryCommerce()}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={errors.ocNumber ? "filters__error" : "filters"}
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      id="checkboxes-tags-demo"
                      options={ocNumbers}
                      value={value || []}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={onOcNumberChange}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderTags={(value) => {
                        const numTags = value.length;
                        const limitTags = 2;

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: "25%",
                                }}
                                key={index}
                                size="small"
                                label={option.label}
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.adminPurchaseOrder.table.ocNumber")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingCommerce ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="ocDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={disableByCountryCommerce()}
                        className={errors.ocDate ? "filters__error" : "filters"}
                        inputFormat="YYYY-MM-DD"
                        label={t("App.adminPurchaseOrder.table.ocDate")}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={value || null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowspacing={2}
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              disabled={disableByCountryCommerce()}
              className={
                !disableByCountryCommerce()
                  ? "btn__applyFilter-reload"
                  : "btn__applyFilter-reload-disabled"
              }
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item xl={5} lg={6} md={12} sm={12} xs={12} width="100%">
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className="btn__deleteFilter-reload"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {error && (
        <ModalInfo
          responseData={filterResponse}
          open={error}
          onClose={() => setError(false)}
        />
      )}
    </Grid>
  );
};

export default FilterAdminPurchaseOrders;

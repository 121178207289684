import { Box, Grid, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MUIDataTable from "mui-datatables";
import { BoardsSection } from "../../../../components/common/CENCO/Emission/BoardsSection";
import { CardsSection } from "../../../../components/common/CENCO/Emission/CardsSection";
import { useNavigate } from "react-router-dom";
import FilterBusinessGroup from "../../../../components/common/Filters/businessGroup/filterWithButtons";
import { FunnelCard } from "../../../../components/common/CENCO/Emission/funnelCard";
import { IndicatorsCards } from "../../indicatorsCards";
import { useSelector } from "react-redux";
import { getUser } from "../../../userSlice";

// PARA AGREGAR UN NUEVO HOME SE DEBE IR A IndicatorsCards.jsx Y
// AGREGAR LA INFORMACIÓN DEL NUEVO HOME
export const EmissionCENFiscalHome = () => {
  /**
   * Const
   */

  const pieColors = [
    "#FCB045",
    "#806AD0",
    "#3BC7F4",
    "#FDE047",
    "#89C758",
    "#0FBBF1",
  ];

  //Mocks
  const mockData = [
    {
      source: "DIAN",
      lstUpdate: "10/02/2024",
      docsQuantity: { label: "2", source: "DIAN" },
    },
  ];

  const mockRequestInfo = {
    qtyDocumentsPie: {
      labels: [
        "Factura de Venta",
        "Factura Contingencia",
        "Factura de Exportación",
        "Nota Crédito",
        "Nota Débito",
        "Factura Contingencia Dian",
      ],
      datasets: [
        {
          label: "",
          data: [10, 35, 44, 56, 11, 45],
          backgroundColor: pieColors,
        },
      ],
    },
    qtyInvoiceBar: {
      labels: [
        "Ene",
        "Feb",
        "Mar",
        "Apri",
        "May",
        "Jun",
        "Jul",
        "Agos",
        "Sept",
        "Oct",
        "Nov",
        "Dic",
      ],
      datasets: [
        {
          label: "",
          data: [10, 45, 34, 56, 12, 13, 23, 45, 67, 78, 34, 3],
          //TODO TOCA ARMAR EL ARREGLO DE COLORES SEGÚN EL DEL DATO
          backgroundColor: [
            "#806AD0",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
            "#FCB045",
          ],
        },
      ],
    },
    issuedDocuments: "1.340",
    invoiceCreditPayment: "23",
    invoiceCashPayment: "850",
    valueInvoiceIssue: "850",
    invoiceWithEvents: "65%",
    invoiceTitleValue: "95",
    invoiceClaimed: "182",
    debitCreditNotes: "53",
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Table
   */

  const columns = [
    {
      name: "source",
      label: t("App.CENDashboard.billCENClient.table.columns.column1"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{ minWidth: "115px" }}>
            {t("App.CENDashboard.billCENClient.table.columns.column1")}
          </div>
        ),
      },
    },
    {
      name: "lstUpdate",
      label: t("App.CENDashboard.billCENClient.table.columns.column2"),
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div>
            <h4>{t("App.CENDashboard.billCENClient.table.columns.column2")}</h4>
            <h5 className="subheading-datatable">AAAA-MM-DD HH:MM</h5>
          </div>
        ),
      },
    },
    {
      name: "docsQuantity",
      label: t("App.CENDashboard.billCENClient.table.columns.column3"),
      options: {
        customBodyRender: (value) => (
          <Grid
            container
            columnSpacing={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item md={1}>
              {value.label}
            </Grid>
            <Grid item className="col-flex" style={{ marginBottom: "0.5%" }}>
              {" "}
              <Tooltip
                title={
                  value.source == "Carvajal"
                    ? t("App.homeEmission.table.tooltipMainSource")
                    : t("App.homeEmission.table.tooltipDIANSource")
                }
              >
                <InfoOutlinedIcon sx={{ fontSize: "23px", color: "#543AB4" }} />
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    },
  ];

  /**
   * Transmitions datatable values
   */
  const options = {
    download: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    filter: false,
    rowHover: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: t("App.salesForce.placeholder"),
    elevation: 0,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: t("App.validationMessages.noMatchFilter"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  const [data, setData] = useState(mockData);

  const [requestInfo, setRequestInfo] = useState(mockRequestInfo);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingButtons, setIsLoadingButtons] = useState(true);

  /**
   * Handles
   */

  const handleGetEmissionIndicators = async () => {};

  /**
   * Formatea la data entrante al formato de la tabla de transmisión
   * @param {*} data data entrante
   * @returns arreglo de datos formateado
   */
  const setDataStructureArray = (data) => {
    return data.map((element) => ({
      source: element.source,
      lstUpdate: element.lstUpdate,
      docsQuantity: { label: element.label, source: element.source },
    }));
  };

  return (
    <Grid container rowSpacing={3}>
      <Grid item className="full-width">
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid item className="display-large__primary-one">
            {t("App.homeEmission.title")}
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
            sm={12}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              container
              spacing={2}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <IndicatorsCards
                excludeHome={"emission"}
                setIsLoadingButtons={setIsLoadingButtons}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="full-width"
        sx={{
          width: "100%",
        }}
      >
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title2")}
          handleRequestIndicators={handleGetEmissionIndicators}
          setIsLoading={setIsLoading}
        />
      </Grid>
      <Grid item className="full-width">
        <Grid container columnSpacing={2}>
          <Grid item md={7}>
            <CardsSection requestInfo={requestInfo} t={t} />
          </Grid>
          <Grid item md={5}>
            <FunnelCard requestInfo={requestInfo} t={t} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="full-width">
        <BoardsSection t={t} requestInfo={requestInfo} />
      </Grid>
      <Grid item className="display-small__primary-one">
        {t("App.CENDashboard.billCENClient.table.transmissionsStatus")}
      </Grid>
      <Grid item className="full-width">
        <MUIDataTable
          className="body-dataTable"
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

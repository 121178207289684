import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";
import { convertFileToBase64Dynamic } from "../../../utils/functions";

/**
 * Utilizar el servicio para obtener el archivo de guia del archivos externos
 * @returns Archivo de guia
 */
export async function GetExternalFileGuide(type) {
  const token = await GetToken();
  return axios({
    method: "GET",
    url: `externals/files/downloads/guides/${type}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Obtener la lista de archivos externos cargados
 * @param {Object} data datos para filtrar la lista de archivos externos
 */
export async function GetListUploadesExternalFiles(data) {
  const token = await GetToken();

  return axios({
    method: "POST",
    data: data,
    url: `externals/files`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite utilizar el servicio de subir el archivo externo al sistema
 * @param data Archivo a subir
 */
export async function UploadExternal(data) {
  const token = await GetToken();
  const formData = new FormData();

  formData.append("file", data.fileUpload);

  const finalData = await convertFileToBase64Dynamic(formData, data);
  return axios({
    method: "POST",
    url: "externals/files/uploads",
    data: finalData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 *
 * Función para consumir el servicio que devuelve la lista de comerciantes
 * @returns resultado de la petición de axios (lista de proveedores)
 */
export async function GetListTrader() {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `companies/traders`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetListReceivers(data) {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

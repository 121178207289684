import axios from "axios";
import GetToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Utilizar el servicio para obtener los causales
 * @returns
 */
export async function GetListCausales() {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `causals`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener la guia de causales
 * @returns
 */
export async function GetGuideCausales() {
  const token = await GetToken();

  return axios({
    method: "GET",
    url: `causals/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}
import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";
import { convertFileToBase64 } from "../../../utils/functions";
import { CODES } from "../../../consts/codes";


export async function ApplyFiltersGeographies(data) 
{
  const token = await getToken();

  return axios({
    method: "POST",
    url: "geographies",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


export async function GetGeographiesList(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: "geographies",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


export async function GetCitiesPerCountryList(country) {
  const token = await getToken();
  return axios({
    method: "GET",
    baseURL: env.REACT_APP_API_URL,
    url: `geographies/cities/countries/${country}`,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


//TODO: ajustar el objeto data con los nuevos atributos donde se haga el llamado a GetGeographiesLoadList, el axios ya esta ajustado
export async function GetGeographiesLoadList(data) 
{
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite validar si una geografía puede ser eliminada
 * @param {data} Objeto que contiene los datos de validación para eliminar la gografía  
 * @returns Confirmación exitosa o fallida de la eliminación
 */
export async function ValidateDeleteGeography(data) 
{
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `geographies/companies/validations`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite eliminar una geografía específica
 * @param {data} Objeto que contiene los datos para eliminar la gografía  
 * @returns Confirmación exitosa o fallida de la eliminación
 */
export async function DeleteGeography(data) 
{
  const token = await getToken();

  return axios({
    method: "DELETE",
    url: `geographies/${data.countryCode}`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite la carga del archivo geografia
 * @param {data} Archivo de geografías
 * @returns Confirmación exitosa o fallida de la carga del archivo
 */
export async function UploadGeography(data) 
{
  const token = await getToken();

  console.log("************* format ************")
  const formData = new FormData();
  formData.append("file", data.file);
  console.log("************* fin format ************")

  const finalData = await convertFileToBase64(formData,CODES.GEOGRAPHY, data)

  return axios({
    method: "POST",
    url: "masters/uploads/geographies",
    data: finalData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite descargar una geografía específica
 * @param {data} Objeto que contiene los datos para la descarga de geografias
 * @returns Archivo csv codificado con las geografias para ese pais
 */
export async function DownloadGeographyFile(data) 
{
  const token = await getToken();

  return axios({
    method: "POST",
    url: "geographies/downloads",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


export async function DownloadGeographyLogFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "masters/downloads/logs",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite descargar la guía de geografías
 * @returns Archivo codificado en base64
 */
export async function DownloadGeographyGuide() 
{
  const token = await getToken();

  return axios({
    method: "GET",
    url: "geographies/downloads/guides",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite descargar la plantilla de geografías
 * @returns Archivo codificado en base64
 */
export async function DownloadGeographyTemplate() 
{
  const token = await getToken();

  return axios({
    method: "GET",
    url: "geographies/downloads/templates",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

//TODO: ajustar el objeto data con los nuevos atributos donde se haga el llamado a GetCatalogues, el axios ya esta ajustado
export async function GetCatalogues(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetCataloguesTemplate(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `products/downloads/templates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetCataloguesGuide(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `products/downloads/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadCatalogueFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadCatalogErrorLog(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads/logs`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetPreviewCatalog(data) {
  const { eanCompany } = data;
  const token = await getToken();
  return axios({
    method: "GET",
    url: `products/companies/${eanCompany}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}
//TODO: Ajustar el objeto GenerateDownloadCatalogueRequest por integracion de grupo empresarial (yo lo ajuste creo q esta bien)
export async function GenerateDownloadCatalogueRequest(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    timeout: 1000 * 90,
    url: `products/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}
//TODO: Francisco Diaz ajustar el objeto data con los nuevos atributos donde se haga el llamado a ListDownloadCatalogue, el axios ya esta ajustado
export async function ListDownloadCatalogue(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadCatalogueOnDownload(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

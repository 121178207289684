import { Button, Grid, IconButton, Chip, Skeleton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { FilterGeographies } from "../../../components/common/Filters/filterGeographies";
import { getUser, getUserStatus, fetchUser } from "../../userSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteGeography,
  DownloadGeographyFile,
  ValidateDeleteGeography,
} from "../../../services/admin/geographies/geographiesService";
import { CODES } from "../../../consts/codes";
import {
  getGeographies,
  getStatusGeographies,
  fetchAllGeographies,
  // resetGeographiesState,
  resetGeographiesState,
} from "../../../parts/geographies/geographiesSlice";

import { useNavigate } from "react-router-dom";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import jwt_decode from "jwt-decode";
import { NoRegistryComponent } from "../../../components/common/NoInfoImage/NoRegistry";
const AdminGeographies = () => {
  /**
   * UseTranslation
   */

  const { t } = useTranslation();

  /**
   * Table
   */

  const columns = [
    {
      name: "isoCode",
      label: t("App.geographies.table.isoCode"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "countryName",
      label: t("App.geographies.table.country"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "level",
      label: t("App.geographies.table.hierarchy"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "currency",
      label: t("App.geographies.table.currency"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "download",
      label: t("App.geographies.table.download"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <IconButton
              className="table-buttons__background"
              onClick={() => {
                handleDownloadGeography(value.pk);
              }}
            >
              <SaveAltIcon
                className="table-buttons__primary"
                fontSize="large"
              />
            </IconButton>
          ) : (
            <>{value}</>
          ),
      },
    },
    {
      name: "delete",
      label: t("App.geographies.table.delete"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          !isLoadingData ? (
            <IconButton
              onClick={() => {
                handleValidateDeleteGeography(value.country, value.countryName);
              }}
            >
              {" "}
              <DeleteOutlineOutlinedIcon
                className="table-buttons__primary"
                fontSize="large"
              />{" "}
            </IconButton>
          ) : (
            <>{value}</>
          ),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    print: false,
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "standard",

    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,

    searchOpen: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonLine = [
    {
      isoCode: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      countryName: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      level: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      currency: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      download: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      delete: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);
  const userInfoStatus = useSelector(getUserStatus);
  const geographiesStatus = useSelector(getStatusGeographies);
  const geographiesList = useSelector(getGeographies);

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use State
   */

  const [noContent, setNoContent] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [filterApply, setFilterApply] = useState({ country: "", isoCode: "" });

  const [infoDelete, setInfoDelete] = useState({});
  const [isOpenErrorDelete, setIsOpenErrorDelete] = useState(false);
  const [isOpenDeleteGeography, setIsOpenDeleteGeography] = useState(false);

  const [responseDelete, setResponseDelete] = useState({
    data: { responseMessage: "" },
  });

  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    role = jwtDecodeTokenInfo["custom:Role"];
  }

  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (geographiesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }

        switch (geographiesStatus) {
          case "succeeded":
            const processedRows = geographiesList.map((geography) => {
              return {
                isoCode: geography.country,
                countryName: geography.countryName,
                level: geography.totalLevels,
                currency: geography.currency,

                download: {
                  pk: geography.pk,
                },
                delete: {
                  country: geography.country,
                  countryName: geography.countryName,
                },
              };
            });
            setData(processedRows);
            setUnfilteredData(processedRows);
            setIsLoadingData(false);

            if (processedRows.length === 0) {
              setNoContent(true);
            }
            break;
          case "loading":
            setIsLoadingData(true);
            setData(skeletonLine);
            break;
          case "failed":
            setNoContent(true);
            break;
        }
      } catch (error) {
        console.log(
          "==============Error Get Geographies======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };
    getGeographies();
  }, [dispatch, geographiesStatus]);

  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        if (userInfoStatus === "idle") {
          dispatch(fetchUser());
        }
      } catch (error) {
        console.log("==============Error Get UserInfo======================");
        console.log(error);
        console.log("====================================");
      }
    };
    requestUserInfo();
  }, [userInfoStatus, dispatch]);

  /**
   * Handles
   */

  const handleUploadGeography = () => {
    if (role === "user") {
      navigate("/user/uploadGeography");
    } else {
      navigate("/admin/uploadGeography");
    }
  };

  const handleDownloadGeography = async (countryCode) => {
    const obj = {
      pk: countryCode,
      country: userInfo.company.country,
      idUser: `${userInfo.firstName.substring(
        0,
        3
      )}${userInfo.lastName.substring(0, 3)}`,
    };

    try {
      const downloadGeographyRequest = await DownloadGeographyFile(obj);

      if (
        downloadGeographyRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        downloadGeographyRequest.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
      ) {
        fileDownload(
          decode(downloadGeographyRequest.data.data.fileContent),
          downloadGeographyRequest.data.data.fileName
        );
      }
    } catch (error) {
      console.log(
        "============== ERROR ADMINGEOGRAPHIES.JSX ======================"
      );
      console.log(error);
      console.log(
        "================================================================"
      );
    }
  };

  const handleValidateDeleteGeography = async (country, countryName) => {
    const obj = {
      country: country,
      countryName: countryName,
    };

    const requestDeleteGeography = await ValidateDeleteGeography(obj);

    setResponseDelete(requestDeleteGeography);

    if (
      requestDeleteGeography.status === CODES.COD_RESPONSE_HTTP_OK &&
      requestDeleteGeography.data.responseCode === CODES.COD_RESPONSE_HTTP_OK
    ) {
      setInfoDelete(obj);
      setIsOpenDeleteGeography(true);
    } else {
      setIsOpenErrorDelete(true);
    }
  };

  const handleDeleteGeography = async (countryCode) => {
    const obj = {
      countryCode: countryCode,
    };

    const deleteRequest = await DeleteGeography(obj);

    if (
      deleteRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
      deleteRequest.data.responseCode === CODES.COD_RESPONSE_ERROR_DELETE
    ) {
      dispatch(resetGeographiesState());
    }
  };

  const handleAgreeDelete = async () => {
    await handleDeleteGeography(infoDelete.country);
    setIsOpenDeleteGeography(false);
  };

  const handleDisagreeDelete = () => {
    setIsOpenDeleteGeography(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <p className="display-large__moderate-blue">
          {t("App.geographies.title")}
        </p>
      </Grid>

      <Grid item>
        <FilterGeographies
          skeletonData={skeletonLine}
          setIsLoadingData={setIsLoadingData}
          unfilteredData={unfilteredData}
          setData={setData}
          setFilterApply={setFilterApply}
          setFilterNotFound={setFilterNotFound}
          setNoContent={setNoContent}
        />
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={2.5} sm={3.5} xs={11} className="geo-upload-text">
            <p className="display-small__light-blue">
              {t("App.geographies.description")}
            </p>
          </Grid>
          <Grid item md={1.7} sm={2.9} xs={12}>
            {" "}
            <Button
              startIcon={<AddIcon />}
              className="btn__filled__blue geo-upload-btn"
              onClick={handleUploadGeography}
            >
              {t("App.geographies.loadGeography")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="u-marginB" style={{ width: "100%" }}>
        <Grid container direction="row" style={{ columnGap: "2%" }}>
          {filterApply.country && (
            <Grid item>
              <Chip
                className="chip__filter"
                label={`${t("App.geographies.table.country")}: ${
                  filterApply.country
                }`}
              />
            </Grid>
          )}
          {filterApply.isoCode && (
            <Grid item>
              <Chip
                className="chip__filter"
                label={`${t("App.geographies.table.isoCode")}: ${
                  filterApply.isoCode
                }`}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        className={!noContent ? "scroll-table" : "scroll-table__nocontent"}
      >
        {!noContent ? (
          isLoadingData ? (
            <MUIDataTable
              data={data}
              columns={columns}
              options={skeletonOptions}
              className="dataTable__salesForce full-width"
            />
          ) : !filterNotFound ? (
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
              className="full-width"
            />
          ) : (
            <NoMatchImage />
          )
        ) : (
          <NoRegistryComponent
            title={t("App.geographies.noRegistryTitle")}
            description={t("App.geographies.noRegistryDesc")}
          />
        )}
      </Grid>

      <ModalInfo
        title={"No se puede eliminar la geografía"}
        responseData={responseDelete}
        open={isOpenErrorDelete}
        onClose={() => {
          setIsOpenErrorDelete(false);
        }}
      />
      <ModalDecision
        isOpen={isOpenDeleteGeography}
        setIsOpen={setIsOpenDeleteGeography}
        handleAgree={handleAgreeDelete}
        handleDisagree={handleDisagreeDelete}
        title="Eliminar geografía"
        message={responseDelete.data.responseMessage}
        agreeText={t("App.buttonLabels.accept")}
        disagreeText={t("App.buttonLabels.cancel")}
      />
    </Grid>
  );
};

export default AdminGeographies;

import {
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilterAlliesRequest } from "../../../components/common/Filters/filterAlliesRequest";

import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import MUIDataTable from "mui-datatables";
import RequestState from "../../../components/common/requestState";
import ModalCancelRequest from "../../../components/common/modalCancelRequest";
import { PageSkeleton } from "../../../components/common/Loading/pageSkeleton";
import { DownloadLogs } from "../../../services/user/deleteAndReload/applicationDeleteAndReload";
import { CODES } from "../../../consts/codes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { ListAlliesRequestService } from "../../../services/user/deleteAndReload/deleteAndReload";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import StateComponent from "../../../components/common/stateComponent";

const RequestAllies = () => {
  /**
   * Datos prueba
   */

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   *
   * Descarga el log de errores
   *
   * @param {String} fileName nombre del archivo
   * @param {String} path path del archivo
   */
  const handleDownloadLog = async (fileName, path) => {
    const obj = {
      pathLogFileName: path,
      logFileName: fileName,
    };

    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await DownloadLogs(obj);

      if (status === CODES.COD_RESPONSE_HTTP_OK)
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS:
            fileDownload(
              decode(responseMessage.fileContent),
              `${responseMessage.fileName}`
            );
            break;
          case CODES.COD_RESPONSE_ERROR_LOGIN:
            setOpenModalGeneralError(true);
            break;
          default:
            setOpenModalGeneralError(true);
            break;
        }
      else {
        setOpenModalGeneralError(true);
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Configuración de la tabla
   */
  const columns = [
    {
      name: "applicationId",
      label: t("App.applicationDeleteAndReload.table.applicationId"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "ally",
      label: t("App.allies.request.filters.ally").replace("*", ""),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "applicationDate",
      label: t("App.applicationDeleteAndReload.table.applicationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customHeadLabelRender: () => {
          const modificationDate = (
            <div>
              <h4>
                {t("App.applicationDeleteAndReload.table.applicationDate")}
              </h4>
              <h5 className="subheading-datatable">
                {t("App.homeDashboard.client.table.columns.formatDate")}
              </h5>
            </div>
          );

          return modificationDate;
        },
      },
    },
    {
      name: "user",
      label: t("App.applicationDeleteAndReload.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "documentType",
      label: t("App.applicationDeleteAndReload.table.documentType"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "processType",
      label: t("App.allies.filter.typeReload"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "commerce",
      label: t("App.allies.request.filters.commerce"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "supplier",
      label: t("App.applicationDeleteAndReload.table.supplier"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "state",
      label: t("App.applicationDeleteAndReload.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value) => <RequestState value={value} />,
      },
    },
    {
      name: "errorDetail",
      label: t("App.applicationDeleteAndReload.table.errorDetail"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: "pointer",
                }}
                className="default-text__linkBlue__sm"
                onClick={(event) => {
                  handleDownloadLog(value.fileName, value.pathFileName);
                }}
              >
                {value && value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "cancel",
      label: t("App.applicationDeleteAndReload.table.cancel"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  /**
   * Configuración de las opciones de la tabla
   */
  const options = {
    download: false,
    print: "false",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.applicationDeleteAndReload.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Configuraciones del skeleton
   */
  const optionsSkeleton = {
    download: false,
    print: "false",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.applicationDeleteAndReload.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use State
   */

  //Elementos de la view cuando no se muestra la tabla
  const [noContent, setNoContent] = useState(true);
  const [filterNotFound, setFilterNotFound] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  //Información de los chips
  const [chipsFilters, setChipsFilters] = useState({
    country: "",
    initialDate: "",
    finalDate: "",
  });

  //Información del formulario
  const [formFilters, setFormFilters] = useState({
    country: "",
    lstEansTraders: [],
    lstEansProviders: [],
    typeDocument: "",
    requestStartDate: "",
    requestEndDate: "",
    createUser: "",
  });

  //Data de la tabla
  const [data, setData] = useState([]);

  //Modal error general
  const [openModalGeneralError, setOpenModalGeneralError] = useState(false);

  /**
   * Regresa a la view de Recarga de aliados
   */
  const handleBackManageReload = () => {
    navigate(-1);
  };

  return (
    <Grid container direction="column" rowGap={4}>
      <Grid item sx={{ marginTop: "2%" }}>
        <p className="display-large__primary-one">
          {" "}
          {t("App.allies.request.title")}
        </p>
      </Grid>
      <Grid item>
        <Grid container direction="row" columnSpacing={2}>
          <Grid item>
            <IconButton
              className="btn__filled__blue"
              disableRipple
              onClick={handleBackManageReload}
              sx={{ borderRadius: "4px" }}
            >
              <ArrowBackIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Grid item className="col-start" xs={10}>
            <p className="display-small__moderate-blue">
              {t("App.allies.request.back")}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="companies_info_container"
        style={{ marginBottom: "5%" }}
      >
        <Grid container direction="column" rowSpacing={2}>
          <Grid item className="control-filters">
            <Grid container>
              <Grid item className="full-width">
                <FilterAlliesRequest
                  setData={setData}
                  setChipFilters={setChipsFilters}
                  setIsDataLoading={setIsDataLoading}
                  setFilterNotFound={setFilterNotFound}
                  setNoContent={setNoContent}
                  setFormFilters={setFormFilters}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" columnSpacing={2} rowSpacing={2}>
              {chipsFilters.country !== "" && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t("App.listUsers.countryN")}: ${
                      chipsFilters.country
                    }`}
                    variant="outlined"
                  />
                </Grid>
              )}
              {chipsFilters.initialDate !== "" && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t(
                      "App.applicationDeleteAndReload.filters.applicationInitialDate"
                    )}: ${chipsFilters.initialDate}`}
                    variant="outlined"
                  />
                </Grid>
              )}
              {chipsFilters.finalDate !== "" && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t(
                      "App.applicationDeleteAndReload.filters.applicationFinalDate"
                    )}: ${chipsFilters.finalDate}`}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ width: "99%" }}>
            {noContent ? (
              <NoSelectionComponent type="deleteAndReloadRequest" />
            ) : isDataLoading ? (
              <MUIDataTable
                className="dataTable__base"
                data={data}
                columns={columns}
                options={optionsSkeleton}
              />
            ) : filterNotFound ? (
              <Grid container className="full-width col-flex">
                <NoMatchImage />
              </Grid>
            ) : (
              <MUIDataTable
                className="dataTable__base"
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <PageSkeleton openLoading={isDataLoading} />
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          data: { responseMessage: t("App.validationMessages.systemError2") },
        }}
        open={openModalGeneralError}
        onClose={() => {
          setOpenModalGeneralError(false);
        }}
      />
    </Grid>
  );
};

export default RequestAllies;

import React, { useState } from "react";
import { Popover, IconButton, Typography, Table, TableBody, TableCell, TableRow, TableHead } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

const MultiBuzonInfo = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {/* Botón del ícono que activará el popover */}
      <IconButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ color: '#553ab6' }}
      >
        <InfoOutlinedIcon />
      </IconButton>

      {/* Popover que muestra la información detallada */}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          sx: {
            width: 'auto',
            minWidth: '300px',
            padding: '10px',
            boxShadow: 'none',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            maxHeight: {
              xs: '90px',  // para pantallas menores a 1000px
              md: '70px',  // para pantallas mayores a 1000px
            },
          },
        }}
      >
        <div>
          <Typography variant="subtitle1" sx={{ marginBottom: '1px', fontSize: '10px !important' }}>
            {t("App.multiBuzonInfo.title")}
          </Typography>

          {/* Tabla que muestra los datos del multibuzón */}
          <Table size="small" aria-label="multi-buzon-info">
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#553ab6', 
                    fontSize: '10px !important',
                    borderBottom: 'none',
                    padding: '0px !important', // Espaciado mínimo
                  }}
                >
                  {t("App.multiBuzonInfo.company")}
                </TableCell>
                <TableCell 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#553ab6', 
                    fontSize: '10px !important',
                    borderBottom: 'none',
                    padding: '0px !important', // Espaciado mínimo
                  }}
                >
                  {t("App.multiBuzonInfo.eanCompanyMain")}
                </TableCell>
                <TableCell 
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#553ab6', 
                    fontSize: '10px !important',
                    borderBottom: 'none',
                    padding: '0px !important', // Espaciado mínimo
                  }}
                >
                  {t("App.multiBuzonInfo.eanCompanyTwo")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell 
                    component="th" 
                    scope="row" 
                    sx={{ 
                      fontSize: '10px !important', 
                      borderBottom: 'none',
                      padding: '0px !important', // Espaciado mínimo
                    }}
                  >
                    {row.company}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      fontSize: '10px !important', 
                      borderBottom: 'none',
                      padding: '0px !important', // Espaciado mínimo
                    }}
                  >
                    {row.eanMain}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      fontSize: '10px !important', 
                      borderBottom: 'none',
                      padding: '0px !important', // Espaciado mínimo
                    }}
                  >
                    {row.eanSecondary}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Popover>
    </div>
  );
};

export default MultiBuzonInfo;

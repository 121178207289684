import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../../../pages/userSlice";
import { ListBusinessGroup } from "../../../../services/user/businessGroup";
import { CODES } from "../../../../consts/codes";

const FilterBusinessGroup = ({
  title,
  handleRequestIndicators,
  setIsLoading,
  typeOption = 1,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  const [isBusinessGroup, setIsBusinessGroup] = useState(false);

  /**
   * useForm y resolver para aplicar schema
   */
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ean: { eanCompany: "" },
      company: { eanCompany: "", companyName: "" },
    },
  });

  /**
   * Función para actualizar tamaño de pantalla
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect para actualizar el estado del tamaño de pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * useEffect que consulta las empresas
   */
  useEffect(() => {
    /**
     * Función que trae la data para settear los estados de companyList y eanList
     */
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const obj = {
          country: userInfo.company.country,
          eanProvider: userInfo.company.eanCompany,
          email: userInfo.email,
          typeOption: typeOption,
        };

        const listService = await ListBusinessGroup(obj);

        const { status, data } = listService;

        if (
          status === CODES.COD_RESPONSE_HTTP_OK &&
          Number(data.responseCode) === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const companyName = data.objectData.map((company) => {
            return {
              companyName: company.renameCompany,
              eanCompany: company.eanCompany,
              country: company.country,
            };
          });

          const eanCompany = data.objectData.map((company) => {
            return {
              eanCompany: company.eanCompany,
            };
          });

          if (companyName.length > 0) {
            setValue("company", companyName[0]);
            setValue("ean", eanCompany[[0]]);

            //Llamamos la petición
            await handleRequestIndicators({
              ean: eanCompany[0],
              company: companyName[0],
            });
            setCompanyList(companyName);
            setEanList(eanCompany);
            setIsBusinessGroup(true);
          } else {
            setCompanyList([]);
            setEanList([]);
            setIsBusinessGroup(false);

            await handleRequestIndicators({
              ean: { eanCompany: userInfo.company.eanCompany },
              company: userInfo.company,
            });
          }
        } else {
          setCompanyList([]);
          setEanList([]);
          setIsBusinessGroup(false);

          await handleRequestIndicators({
            ean: { eanCompany: userInfo.company.eanCompany },
            company: userInfo.company,
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (userInfo) {
      fetchData();
    }
  }, [userStatus, userInfo]);

  /**
   * Opciones para el autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Función que resetea los valores de los campos
   */
  const resetValuesWhenClear = () => {
    setValue("ean", null);
    setValue("company", null);
  };

  /**
   *
   * Función que maneja los cambios sobre el campo ean, ejecutando a su vez
   * cambios sobre el campo company según el valor seleccionado de ean
   *
   * @param {Object} values ean seleccionado
   */
  const handleChangeEan = (values) => {
    if (values) {
      if (!getValues("company")) {
        setValue("company", null);
      }

      const ean = values;

      const findCompany = companyList.find(
        (company) => company.eanCompany === ean.eanCompany
      );

      setValue("company", findCompany);
    }
  };

  /**
   *
   * Función que maneja los cambios sobre campo company
   * ejecutando también cambios sobre campo ean según el caso
   *
   * @param {Object} values empresa seleccionada
   */
  const handleChangeCompany = (values) => {
    if (values) {
      if (!getValues("ean")) {
        setValue("ean", null);
      }
      const company = values;
      setValue("ean", { eanCompany: company.eanCompany });
    }
  };

  return isBusinessGroup ? (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      disableGutters
      style={{ borderRadius: "8px" }}
    >
      <Grid item>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <SearchIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {title}
          </p>
        </AccordionSummary>

        <AccordionDetails>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid
              md={8}
              sm={8}
              item
              className="side-line"
              style={{
                padding: "2%",
              }}
            >
              <form
                id="hook-form"
                onSubmit={handleSubmit(handleRequestIndicators)}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent={isDesktop ? "flex-start" : "center"}
                  alignItems="center"
                  gap={1}
                >
                  <Grid item lg={5.5} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.company")}
                        </label>
                      </Grid>

                      <Grid item className="full-width">
                        <Controller
                          name="company"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              filterOptions={filterOptions}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={`${
                                errors.company
                                  ? "select__filters__errors"
                                  : "select__filters"
                              } full-width autoComplete__filter`}
                              options={companyList}
                              getOptionLabel={(option) => option.companyName}
                              isOptionEqualToValue={(option, value) => {
                                return option.eanCompany === value.eanCompany;
                              }}
                              renderOption={(props, option) => (
                                <li {...props} key={option.eanCompany}>
                                  {option.companyName}
                                </li>
                              )}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.company")}
                                    {...params}
                                  />
                                );
                              }}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  resetValuesWhenClear();
                                }
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeCompany(values, reason);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.indicadors.ean")}
                        </label>
                      </Grid>

                      <Grid item className="full-width">
                        <Controller
                          name="ean"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={`${
                                errors.ean
                                  ? "select__filters__errors"
                                  : "select__filters"
                              } full-width autoComplete__filter`}
                              options={eanList}
                              getOptionLabel={(option) => option.eanCompany}
                              isOptionEqualToValue={(option, value) => {
                                return option.eanCompany === value.eanCompany;
                              }}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  resetValuesWhenClear();
                                }
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.ean")}
                                    {...params}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeEan(values, reason);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid item xs={4} className="confirm__button__container">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={2}
                className="confirmation-buttons"
              >
                <Grid item>
                  <Button
                    startIcon={
                      <TuneOutlinedIcon
                        sx={{
                          fontSize: 20,
                          color: "white",
                        }}
                      />
                    }
                    disableRipple
                    disabled={watch("company") ? false : true}
                    className={
                      watch("company")
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    }
                    type="submit"
                    form="hook-form"
                  >
                    {t("App.listUsers.applyFilters")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Grid>
    </Accordion>
  ) : (
    <></>
  );
};

export default FilterBusinessGroup;

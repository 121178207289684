import { useTranslation } from "react-i18next";

const FileState = ({ value }) => {
  const { t } = useTranslation();

  const transformValue = value && !value.$$typeof ? value.toLowerCase() : "";
  let text = "";
  let textColor = "";

  switch (transformValue) {
    case "finalizado":
      text = t("App.states.finished");
      textColor = "state-colors__success";
      break;
    case "carga total":
      text = t("App.states.totalLoad");
      textColor = "state-colors__success";
      break;
    case "carga parcial":
      text = t("App.states.partialLoad");
      textColor = "state-colors__parcial";
      break;
    case "aprobado":
      text = t("App.states.approved");
      textColor = "state-colors__success";
      break;
    case "aprobado parcial":
      text = t("App.states.partialApproved");
      textColor = "state-colors__parcial";
      break;
    case "pendiente":
      text = t("App.states.pending");
      textColor = "state-colors__tertiary-color-one";
      break;
    case "en proceso":
      text = t("App.states.inProcess");
      textColor = "state-colors__very_dark_grayish_blue";
      break;
    case "rechazado por estructura":
      text = t("App.states.structuredError");
      textColor = "state-colors__error";
      break;
    case "error sistema":
      text = t("App.states.systemError");
      textColor = "state-colors__error";
      break;
    case "rechazado":
      text = t("App.states.refused");
      textColor = "state-colors__error";
      break;
    case "activo":
      text = t("App.adminCompanies.filters.active");
      textColor = "state-colors__primary-color-one";
      break;
    case "inactivo":
      text = t("App.adminCompanies.filters.inactive");
      textColor = "state-colors__tertiary-color-one";
      break;
    default:
      text = null;
      break;
  }

  return (
    <p className={`${textColor} text-transform__capitalize word-break__keep`}>
      {text}
    </p>
  );
};

export default FileState;

import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";
import { convertFileToBase64 } from "../../../utils/functions";
import { CODES } from "../../../consts/codes";

//TODO: ajustar el objeto data con los nuevos atributos donde se haga el llamado a GetMasters, el axios ya esta ajustado
export async function GetMasters(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ListDownloadMaster(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetMastersTemplate(data) {
  const token = await getToken();
  const { master } = data;
  const route = master === "0" ? "budget" : "productsreferences";
  return axios({
    method: "GET",
    url: `masters/downloads/${route}/templates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetMastersGuide(data) {
  const token = await getToken();
  const { master } = data;
  const route = master === "0" ? "budget" : "productsreferences";
  return axios({
    method: "GET",
    url: `/masters/downloads/${route}/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function UploadMastersFile(data) {
  const token = await getToken();
  const { master, obj } = data;

  const route = master === "0" ? "budgetseller" : "productsreferences";
  const idFile = master === "0" ? CODES.BUDGETS : CODES.PRODUCTSREFERENCES;

  const formData = new FormData();
  formData.append("file", obj.file);

  //Codificacion del archivo subido
  const finalData = await convertFileToBase64(formData, idFile, obj);

  return axios({
    method: "POST",
    url: `masters/uploads/${route}`,
    data: finalData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite subir el archivo de catalogos al sistema
 * @param data Archivo a subir
 */
export async function UploadCatalog(data) {
  const token = await getToken();
  const formData = new FormData();

  formData.append("file", data.file);

  const finalData = await convertFileToBase64(formData, CODES.CATALOG, data);

  return axios({
    method: "POST",
    url: "masters/uploads/catalogs",
    data: finalData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * TODO
 */
export async function DownloadMastersFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * TODO
 */
export async function DownloadMastersErrorLog(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads/logs`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadMasterFromTable(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadMastersBudget(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `budget/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadMastersProductsReferences(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `productsreferences/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import jwt_decode from "jwt-decode";
import { refreshToken } from "../services/auth/authServices";
import { CODES } from "../consts/codes";

/**
 * Genera nuevos tokens a partir del refresh token
 * @returns true si se generan nuevos tokens, false si no se generan nuevos tokens
 */
const generateRefreshToken = async () => {
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  const obj = {
    email: payloadToken.email,
    refreshToken: localStorage.getItem("refreshToken"),
  };

  const {
    status,
    data: { responseCode, token },
  } = await refreshToken(obj);

  // Si se refresca existosamente, se settean los nuevos tokens en localStorage
  if (
    status === CODES.COD_RESPONSE_HTTP_OK &&
    responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
  ) {
    const newAccessToken = token.accessToken;
    localStorage.setItem("accessToken", newAccessToken);

    const refreshToken = token.refreshToken;
    localStorage.setItem("refreshToken", refreshToken);

    const jwtTokenInfo = token.idToken;
    localStorage.setItem("infoToken", jwtTokenInfo);

    const payloadToken = token.idToken;
    localStorage.setItem("payloadToken", JSON.stringify(payloadToken));

    return true;
  }

  //Si no hay nuevos tokens, se retorna false
  return false;
};

/**
 * Trae el accessToken del localStorage y lo retorna.
 * Dado el caso, refresca el token por medio del refreshToken
 * @returns el token actual, false si no hay token
 */
const GetToken = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return false;
  }
  const { exp } = jwt_decode(token);
  const expirationTime = exp * 1000 - 60000;
  if (Date.now() >= expirationTime) {
    const getRefreshToken = await generateRefreshToken();
    if (getRefreshToken) {
      const newToken = localStorage.getItem("accessToken");
      return newToken;
    }
    //En caso de que no haya refresh en los tokens, la sesión se cerró/esta vencida
    //Entonces se limpia el localStorage y se redirige al login
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
    return false;
  }
  return token;
};

export default GetToken;

import {
  Box,
  Button,
  Grid,
  Modal,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { REGEXP } from "../../../consts/regexp";

const InsightIcons = ({ setSelectedIcon, selectedIcon }) => {
  const initialIconsState = [
    { id: 1, name: "Location on", value: "locationOn.svg", selected: false },
    {
      id: 2,
      name: "Production quantity limits",
      value: "productionQuantityLimits.svg",
      selected: false,
    },
    {
      id: 3,
      name: "Productivity",
      value: "productivity.svg",
      selected: false,
    },
    { id: 4, name: "Hub", value: "hub.svg", selected: false },
    {
      id: 5,
      name: "Data exploration",
      value: "dataExploration.svg",
      selected: false,
    },
    {
      id: 6,
      name: "Event available",
      value: "eventAvailable.svg",
      selected: false,
    },
    { id: 7, name: "Inventory", value: "inventory.svg", selected: false },
    {
      id: 8,
      name: "Local convenience store",
      value: "localConvenienceStore.svg",
      selected: false,
    },
    { id: 9, name: "Source notes", value: "sourceNotes.svg", selected: false },
    {
      id: 10,
      name: "Return analysis",
      value: "returnAnalysis.svg",
      selected: false,
    },
    { id: 11, name: "Return trade", value: "returnTrade.svg", selected: false },
    {
      id: 12,
      name: "Budget analysis",
      // Segundo ícono de "budgetAnalysis" para registrar los nuevos indicadores con este valor sin eliminar el ícono anterior
      value: "budgetAnalysis(2).svg",
      selected: false,
    },
    {
      id: 13,
      name: "Detailed budget",
      value: "detailedBudget.svg",
      selected: false,
    },
    {
      id: 14,
      name: "Document Report",
      value: "documentReport.svg",
      selected: false,
    },
    {
      id: 15,
      name: "Info Detail",
      value: "infoDetail.svg",
      selected: false,
    },
    {
      id: 16,
      name: "Principal",
      value: "principal.svg",
      selected: false,
    },
    {
      id: 17,
      name: "Response Time",
      value: "responseTime.svg",
      selected: false,
    },
    {
      id: 18,
      name: "Top Clients",
      value: "topClients.svg",
      selected: false,
    },
  ];

  const [iconList, setIconList] = useState(initialIconsState);
  const [iconListSelected, setIconListSelected] = useState(initialIconsState);
  const originalIconList = initialIconsState;
  const [open, setOpen] = useState(false);
  const [textSearch, setTextSearch] = useState("");

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Method to open the modal
   */
  const handleOpen = () => setOpen(true);

  /**
   * Method to close the modal and reset the values of the selected icon
   */
  const handleClose = () => {
    setOpen(false);

    setIconList(originalIconList);

    setSelectedIcon(null);
  };

  /**
   *
   * Method to set the values of the iconList and iconListSelected states when the user choose an icon
   *
   * @param {Object} icon
   */
  const handleChooseIcon = (icon) => {
    setIconList((current) =>
      current.map((obj) => {
        if (obj.selected) {
          return { ...obj, selected: false };
        }

        return obj;
      })
    );

    setIconList((current) =>
      current.map((obj) => {
        if (obj.id === icon.id) {
          return { ...obj, selected: true };
        }

        return obj;
      })
    );

    setIconListSelected((current) =>
      current.map((obj) => {
        if (obj.selected) {
          return { ...obj, selected: false };
        }

        return obj;
      })
    );

    setIconListSelected((current) =>
      current.map((obj) => {
        if (obj.id === icon.id) {
          return { ...obj, selected: true };
        }

        return obj;
      })
    );

    setSelectedIcon(icon.value);
  };

  /**
   *
   * Method to search an icon
   *
   * @param {Event} event
   */
  const handleSearch = (event) => {
    const value = event.target.value;
    // Validate if the value has special characters
    if (value.includes("--")) {
      event.preventDefault();
    } else {
      if (selectedIcon !== null) {
        // Filter when there is a selected icon
        setIconList(
          iconListSelected.filter((info) => {
            if (info.name.toLowerCase().includes(value.toLowerCase())) {
              return info;
            }
          })
        );
      } else {
        // Filter when there isn't a selected icon
        setTextSearch(value);
        setIconList(
          originalIconList.filter((info) => {
            if (info.name.toLowerCase().includes(value.toLowerCase())) {
              return info;
            }
          })
        );
      }
    }
  };

  /**
   * useEffect to set the values of iconList and iconListSelected states by the selected icon
   */
  useEffect(() => {
    const value = selectedIcon;

    if (value !== null) {
      // Return a list with or without the selected icon
      setIconList((current) =>
        current.map((obj) => {
          if (obj.value === selectedIcon) {
            return { ...obj, selected: true };
          }

          return obj;
        })
      );

      // Return a list with or without the selected icon
      setIconListSelected((current) =>
        current.map((obj) => {
          if (obj.value === selectedIcon) {
            return { ...obj, selected: true };
          }

          return obj;
        })
      );
    } else {
      // Reset the value of iconList state to the original list of icons
      setIconList(originalIconList);
    }
  }, [selectedIcon]);

  return (
    <>
      <Button
        className="modalCreateRoles__form__actions-buttonOutline full-width"
        startIcon={<PaletteOutlinedIcon />}
        onClick={handleOpen}
      >
        {t("App.indicatorCards.modalIcons.buttonText")}
      </Button>

      <Modal open={open} onClose={handleClose} className="btn__uploadNew">
        <Box className="modal__selectIcon choose-icons-insight">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item className="grid__container__fullW">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={11}>
                  <p className="heading-secondary__md">
                    {t("App.selectIcon.title")}
                  </p>
                </Grid>

                <Grid item xs={1}>
                  <IconButton onClick={handleClose} component="span">
                    <CloseIcon sx={{ color: "#543AB4", fontSize: "1.8rem" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className="grid__container__fullW"
              item
              style={{ marginBottom: "1rem" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <OutlinedInput
                    value={textSearch}
                    placeholder={t("App.selectIcon.searchForName")}
                    id="outlined-adornment-weight"
                    className="icon__searchBar"
                    onInput={(event) => handleSearch(event)}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                      onKeyPress: (event) => {
                        const allowedChars = REGEXP.SPECIAL_QA4;
                        if (!allowedChars.test(event.key)) {
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="grid__container__outlined">
              <Grid container style={{ justifyContent: "space-evenly" }}>
                {iconList.map((icon) => {
                  return (
                    <Grid
                      key={icon.id}
                      item
                      style={{ textAlign: "center" }}
                      className={
                        icon.selected
                          ? "icon__container__selected"
                          : "icon__container"
                      }
                    >
                      {icon.selected && <div className="icon__selected"></div>}

                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        style={{ height: "100%" }}
                        onClick={() => {
                          handleChooseIcon(icon);
                        }}
                      >
                        <Grid item>
                          <img
                            className="icon__color"
                            src={`/assets/insightAccess/${icon.value}`}
                          />
                        </Grid>

                        <Grid item>
                          <p className="label__text-default">{icon.name}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item className="grid__container__fullW">
              <Grid
                item
                container
                justifyContent="center"
                spacing={2}
                style={{ paddingTop: "3rem" }}
              >
                <Grid item>
                  <Button
                    type="button"
                    disableRipple
                    className="btn__filled__gradient"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t("App.buttonLabels.cancel")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    disableRipple
                    disabled={selectedIcon ? false : true}
                    type="submit"
                    className={
                      selectedIcon
                        ? "btn__applyFilter"
                        : "btn__applyFilter-disabled"
                    }
                    onClick={() => {
                      setIconList(iconList);
                      setOpen(false);
                    }}
                  >
                    {t("App.selectIcon.confirm")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default InsightIcons;

import { createSlice } from "@reduxjs/toolkit";

export const deleteAndReloadSlice = createSlice({
  name: "deleteAndReload",
  initialState: {
    currentAction: "2",
    reloadDocuments: [],
    reloadDocumentsStatus: "fetch",
  },
  reducers: {
    setCurrentAction(state, action) {
      const { newAction } = action.payload;
      state.currentAction = newAction;
    },
  },
  extraReducers(builder) {},
});

export const getCurrentAction = (state) => state.deleteAndReload.currentAction;

export const { setCurrentAction } = deleteAndReloadSlice.actions;

export default deleteAndReloadSlice.reducer;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import { useEffect, useReducer } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NoSelectionComponent } from "../noImages/noSelectionComponent";

const ModalPreviewCatalogue = ({ open, onClose, catalogue }) => {
  const initialState = () => [];
  const reducer = (state = initialState(), action = {}) => {
    switch (action.type) {
      case "setCatalog":
        return action.catalogue;
      case "firstLevel":
        //Lo dejo como futura referencia
        const oneOpened = state.map((product, idx) => {
          if (idx === action.index) {
            return { ...product, open: action.decision };
          } else {
            return { ...product, open: product.open };
          }
        });
        return oneOpened;

      case "secondLevel":
        //Lo dejo como futura referencia
        const parentProduct = state[action.parentIndex];
        const modifiedChildren = parentProduct.levels;
        modifiedChildren[action.index] = {
          ...modifiedChildren[action.index],
          open: action.decision,
        };

        const finalParent = {
          ...parentProduct,
          levels: modifiedChildren,
        };

        const mockCurrent = state;
        mockCurrent[action.parentIndex] = finalParent;

        return mockCurrent;

      default:
        return state;
    }
  };

  const [currentCatalogue, dispatch] = useReducer(reducer, reducer());
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: "setCatalog", catalogue: catalogue });
  }, [catalogue]);

  const renderChildren = (children, paddingLeft) => {
    return (
      <>
        {children.map((c, id) => (
          <Accordion
            key={id}
            square
            elevation={0}
            className="preview-modal__secondLevel"
            sx={{
              "&:before": {
                display: "none",
              },
              minWidth: "17rem !important",
            }}
          >
            <AccordionSummary
              expandIcon={
                c.levels.length > 0 ? (
                  <ExpandMoreIcon fontSize="large" />
                ) : (
                  <></>
                )
              }
              sx={{
                "& .MuiAccordionSummary-content": {
                  flexGrow: "0",
                  marginRight: "1%",
                },
                justifyContent: "flex-start !important",
              }}
            >
              {c.nivel}{" "}
              {c.levels.length > 0
                ? ""
                : c.totalProducts !== ""
                ? `(${c.totalProducts})`
                : ""}
            </AccordionSummary>
            {c.levels ? (
              <AccordionDetails
                sx={{
                  backgroundColor: "white !important",
                  padding: "0 !important",
                  marginLeft: `${paddingLeft}% !important`,
                  border: "none !important",
                }}
              >
                {renderChildren(c.levels, paddingLeft)}
              </AccordionDetails>
            ) : (
              <></>
            )}
          </Accordion>
        ))}
      </>
    );
  };

  const renderParent = (parent) => {
    return (
      <Accordion
        square
        elevation={0}
        className="preview-modal__firstLevel"
        disableGutters
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className="preview-modal__arrows"
              fontSize="large"
            />
          }
          sx={{
            "& .MuiAccordionSummary-content": {
              flexGrow: "0",
              marginRight: "1%",
            },
            justifyContent: "flex-start !important",
          }}
        >
          {parent.nivel}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "white !important",
            padding: "0 !important",
            marginLeft: "3.5% !important",
            border: "none !important",
          }}
        >
          {renderChildren(parent.levels, 4)}
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth={"lg"}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <label className="display-large__primary-one">
            {" "}
            {t("App.catalogue.previewModal.title")}
          </label>
          <br />

          {currentCatalogue.length > 0 ? (
            <>
              {" "}
              <p
                className="default-text_black__bold"
                style={{ marginTop: "2%" }}
              >
                {t("App.catalogue.previewModal.selectCategory")}
              </p>
              <Grid className="preview-modal__general-container">
                {currentCatalogue.map((parent) => (
                  <>{renderParent(parent)}</>
                ))}
              </Grid>
            </>
          ) : (
            <>
              <NoSelectionComponent type="catalogue" />
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <div>
            <Button className="btn__outlined__blue" onClick={handleClose}>
              {t("App.catalogue.previewModal.close")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalPreviewCatalogue;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../consts/codes";

import { GetRates, ListDownloadRate } from "../../services/user/rateService";

export const rateSlice = createSlice({
  name: "rate",
  initialState: {
    rateLoad: [],
    rateLoadStatus: "fetch",
    rateDownload: [],
    rateDownloadStatus: "fetch",
    error: null,
  },
  reducers: {
    resetRatesLoadState(state, action) {
      state.rateLoadStatus = "fetch";
    },
    resetRateDownloadState(state, action) {
      state.rateDownloadStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRateLoad.pending, (state, action) => {
        state.rateLoadStatus = "loading";
      })
      .addCase(fetchRateLoad.fulfilled, (state, action) => {
        state.rateLoadStatus = "succeeded";
        state.rateLoad = action.payload;
      })
      .addCase(fetchRateLoad.rejected, (state, action) => {
        state.rateLoadStatus = "failed";
        state.rateLoad = [];
      })
      .addCase(fetchRateDownload.pending, (state, action) => {
        state.rateDownloadStatus = "loading";
      })
      .addCase(fetchRateDownload.fulfilled, (state, action) => {
        state.rateDownloadStatus = "succeeded";
        state.rateDownload = action.payload;
      })
      .addCase(fetchRateDownload.rejected, (state, action) => {
        state.rateDownloadStatus = "failed";
      });
  },
});

export const getRateLoad = (state) => state.rate.rateLoad;
export const getRateLoadStatus = (state) => state.rate.rateLoadStatus;

export const getRateDownload = (state) => state.rate.rateDownload;
export const getRateDownloadStatus = (state) => state.rate.rateDownloadStatus;

export const { resetRatesLoadState, resetRateDownloadState } =
  rateSlice.actions;

export const fetchRateLoad = createAsyncThunk(
  "rate/fetchAllRateLoad",
  async (data) => {
    //TODO: Ajustar el objeto GetCatalogues por integracion de grupo empresarial
    const requestRateLoad = await GetRates({
      ...data,
    });

    if (
      requestRateLoad.status === CODES.COD_RESPONSE_SUCCESS &&
      requestRateLoad.data.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      return requestRateLoad.data.data;
    }
  }
);

//TODO: en esta funcion ajuste el objecto para recibir el objecto para que funione como grupo empresarial
export const fetchRateDownload = createAsyncThunk(
  "rate/fetchAllRateDownload",
  async (data) => {
    const requestRateDownload = await ListDownloadRate({
      ...data,
    });
    if (requestRateDownload.status === CODES.COD_RESPONSE_SUCCESS) {
      if (
        requestRateDownload.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        return requestRateDownload.data.data;
      }
    }
  }
);

export default rateSlice.reducer;

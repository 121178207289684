import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";
export async function GetMenuOptions(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `menus`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetLevelOptions(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `menus/levels`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetParentOption(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `menus/parent/${data.level}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetOrderFromParent(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "menus/order",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function CreateMenu(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "menus",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function UpdateMenu(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    url: "menus",
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetMenu() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: "menus/previews",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetOptionTypes(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: "menus/typeOption",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

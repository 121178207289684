import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";
import ChooseCausalComponent from "../../../pages/user/purchaseOrders/ChooseCausalComponent";
import ModalInfo from "./modalInfo";
import {
  GetProductsOC,
  RequestExceptionOC,
} from "../../../services/user/adminPurchaseOrder/PurcharseOrderProviderService";
import { CODES } from "../../../consts/codes";
import { ModalDecision } from "./modalDecision";
import {
  GetGuideCausales,
  GetListCausales,
} from "../../../services/user/adminPurchaseOrder/Causales";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import DownloadGuide from "../UploadFile/downloadGuide";
import HelpIcon from "@mui/icons-material/Help";

const ModalSeePurchaseOrderDetail = ({
  order,
  userInfo,
  open,
  setOpen,
  setTitle,
  setOpenErrorModal,
  filterData,
}) => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseMessage, setResponseMesssage] = useState({});
  const [hasFoundData, setHasFoundData] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderSelected, setOrderSelected] = useState([]);
  const [hasResults, setHasResults] = useState(false);

  // States for causal and checkboxes
  const [selectedCausal, setSelectedCausal] = useState({ value: null });
  const [isGeneral, setIsGeneral] = useState("NO");
  const [isProduct, setIsProduct] = useState("NO");

  const { t } = useTranslation();

  const [causalOptions, setCausalOptions] = useState([]);

  /**
   * Configurations for table columns
   */
  const getColumns = () => {
    const baseColumns = [
      {
        name: "eanProduct",
        label: t("App.adminPurchaseOrder.seeDetailModal.eanProduct"),
        options: { filter: true },
      },
      {
        name: "product",
        label: t("App.adminPurchaseOrder.seeDetailModal.product"),
        options: {
          filter: true,
          hint: (              
          <span style={{ textAlign: "left"}}>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextOne")}</p>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextTwo")}</p>
          </span>
          ),
        },
      },
      {
        name: "quantity",
        filter: false,
        label: t("App.adminPurchaseOrder.seeDetailModal.quantity"),
        options: { filter: false },
      },
    ];

    /**
     * Solo cuando se habilita el check de que es tipo producto se habilita la columna causal
     * con el autocomplete para seleccionar los causales
     */
    if (isProduct === "SI") {
      baseColumns.push({
        name: "causal",
        options: {
          filter: false,
          sort: false,
          customHeadLabelRender: () => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {t("App.adminPurchaseOrder.seeDetailModal.causal")}
                <br />
                <a
                  onClick={handleDownloadTemplate}
                  style={{
                    marginTop: "5px",
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  {t("App.adminPurchaseOrder.modalInfo.link")}
                </a>
              </div>
              <Tooltip
                title={
                  <span style={{ textAlign: "left" }}>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipOne")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipTwo")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipThree")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipFour")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipFive")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipSix")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipSeven")}</p>
                    <p>{t("App.adminPurchaseOrder.modalInfo.contentTooltipEight")}</p>
                  </span>
                }
                placement="bottom-end"
              >
                <HelpIcon sx={{ fontSize: "12px", marginLeft: "5px" }} />
              </Tooltip>
            </div>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const isRowSelected = selectedRows.some(
              (row) => row.dataIndex === rowIndex
            );

            return (
              <Autocomplete
                disabled={!isRowSelected}
                noOptionsText={t("App.listUsers.noOptions")}
                className={"filters-causal-total"}
                options={causalOptions}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                renderInput={(params) => (
                  <TextField
                    label={t(
                      "App.adminPurchaseOrder.seeDetailModal.placeHolderCausal"
                    )}
                    sx={{
                      label: {
                        fontFamily: "Roboto !important",
                        fontSize: "12px !important",
                        translate: "0px -8px",
                        transformOrigin: "-45px -3px",
                        "&.Mui-focused": {
                          color: "#8C8D9D",
                        },
                      },
                      input: {
                        transform: "translateY(-2px)",
                      },
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { transform: "translateY(-2px)" },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                onChange={(event, value, reason) => {
                  //Guarda en los datos de la grilla el causal seleccionado
                  const newData = [...data];
                  newData[tableMeta.rowIndex].causal = value;
                  setData(newData);
                }}
                value={value || null}
                style={{ flexGrow: 1 }} // Ensures the Autocomplete takes up the remaining space
              />
            );
          },
        },
      });
    }

    return baseColumns;
  };

  /**
   * Table options configuration
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "multiple",
    selectableRowsHeader: "true",
    rowsSelected: selectedRows.map((row) => row.index),
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      handleSelectedRow(allRowsSelected);
    },
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    searchPlaceholder: t("App.requestsPurchaseOrder.searchText"),
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",
        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  useEffect(() => {
    fetchProductsOC();
    getCausales();
  }, [open]);

  /**
   * Funcion para obtener la lista de causales
   */
  const getCausales = async () => {
    try {
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetListCausales();
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          if (t("App.adminPurchaseOrder.request") === "Solicitar") {
            const newCausalOptions = responseMessage.map((item) => ({
              value: item.idCausal,
              label: item.causalName,
            }));
            setCausalOptions(newCausalOptions);
          } else {
            const newCausalOptions = responseMessage.map((item) => ({
              value: item.idCausal,
              label: item.causalNameEnglish,
            }));
            setCausalOptions(newCausalOptions);
          }
        } else {
          setCausalOptions([]);
        }
      } else {
        setCausalOptions([]);
      }
    } catch (error) {
      console.error("Error fetching causales: ", error);
      setCausalOptions([]);
    }
  };

  /**
   * Función para descargar la guia de causales
   */
  const handleDownloadTemplate = async () => {
    try {
      const templateService = await GetGuideCausales();
      if (templateService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          templateService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(templateService.data.responseMessage.fileContent),
            templateService.data.responseMessage.fileName
          );
        }
      }
    } catch (error) {
      console.error("Error downloading guia: ", error);
    }
  };

  /**
   * Function to fetch the products of the purchase order
   */
  const fetchProductsOC = () => {
    if (userInfo) {
      getProductsOC();
    }
  };

  /**
   * Function to get the products of the purchase order
   */
  const getProductsOC = async () => {
    try {
      setIsLoading(true);
      setIsGeneral("NO");
      setIsProduct("NO");
      const obj = {
        eanTrader: order.eanTrader,
        eanProvider: userInfo.company.eanCompany,
        ocNumber: order.ocNumber,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetProductsOC(obj);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setHasFoundData(responseMessage.length > 0 ? true : false);
            // Verify that products are found
            if (responseMessage.length === 0) {
              handleCloseModal();
            } else {
              setData(responseMessage);
            }
            break;

          default:
            setData([]);
            break;
        }
      } else {
        setData([]);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("---- ERROR getProductsOC ----");
      console.log(error);
    }
  };

  /**
   * Function to handle the check state when the row is selected
   */
  const handleSelectedRow = (rowsSelected) => {
    setSelectedRows(rowsSelected);
    getSelectedRows(rowsSelected);
  };

  /**
   * Get the information of the selected rows
   * @param {*} rowsSelected
   */
  const getSelectedRows = (rowsSelected) => {
    const index = rowsSelected.map((row) => row.index);
    let list = [];
    index.forEach((element) => {
      list.push(data[element]);
    });
    setOrderSelected(list);
  };

  /**
   * Function to close the modal
   */
  const handleCloseModal = async () => {
    setData([]);
    setOpen(false);
    setIsOpenModalDecision(false);
    setSelectedRows([]);
    setOrderSelected([]);
    if (data.length === 0) {
      await filterData();
    }
  };

  /**
   * Function to update the state of isOpenModalInfo and isOpenModalInfo, and send the info for the modalInfo
   */
  const handleAgree = () => {
    formatDataRequestOC();
    requestException();
  };

  /**
   * Function to format the data for the purchase order exception
   */
  const formatDataRequestOC = () => {
    if (isProduct === "SI") {
      setOrderSelected(
        orderSelected.map((item) => {
          delete item.itemState;
          delete item.quantity;
          data.forEach((itemTable, index) => {
            if (itemTable.eanProduct === item.eanProduct) {
              if (itemTable.causal !== undefined) {
                item.idCausal = itemTable.causal.value;
              } else {
                item.idCausal = null;
              }
            }
          });
          return item;
        })
      );
    } else {
      setOrderSelected(
        orderSelected.map((item) => {
          delete item.itemState;
          delete item.quantity;
          return item;
        })
      );
    }
  };

  /**
   * Function to update the state of isOpenModalInfo to false
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  /**
   * Function to request the purchase order exception
   */
  const requestException = async () => {
    let obj = [];
    try {
      if (isProduct === "SI") {
        obj = [
          {
            eanTrader: order.eanTrader,
            eanProvider: order.eanProvider,
            ocNumber: order.ocNumber,
            country: order.country,
            lstItemOcExcludeds: orderSelected,
            userName: userInfo.firstName + " " + userInfo.lastName,
            checkProduct: isProduct,
          },
        ];
      } else if (isGeneral === "SI") {
        obj = [
          {
            eanTrader: order.eanTrader,
            eanProvider: order.eanProvider,
            ocNumber: order.ocNumber,
            country: order.country,
            lstItemOcExcludeds: orderSelected,
            userName: userInfo.firstName + " " + userInfo.lastName,
            idCausal: selectedCausal.value,
            checkGeneral: isGeneral,
          },
        ];
      } else if (isGeneral === "NO" && isProduct === "NO") {
        obj = [
          {
            eanTrader: order.eanTrader,
            eanProvider: order.eanProvider,
            ocNumber: order.ocNumber,
            country: order.country,
            lstItemOcExcludeds: orderSelected,
            userName: userInfo.firstName + " " + userInfo.lastName,
            idCausal: selectedCausal.value,
          },
        ];
      }
      const requestService = await RequestExceptionOC(obj);
      if (requestService.status === CODES.COD_RESPONSE_SUCCESS) {
        if (
          requestService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          setOrderSelected([]);
          setSelectedCausal({ value: null });
          await getProductsOC();
          setResponseMesssage(requestService);
          setTitle(t("App.adminPurchaseOrder.modal.success"));
          setIsOpenModalInfo(true);
          setIsOpenModalDecision(false);
        } else {
          setOpenErrorModal(true);
          await handleCloseModal();
        }
      } else {
        setOpenErrorModal(true);
        await handleCloseModal();
      }
    } catch (error) {
      setOpenErrorModal(true);
      await handleCloseModal();
      console.log("---- ERROR requestException ----");
      console.log(error);
    }
    setSelectedRows([]);
    setOrderSelected([]);
  };

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="dialog-title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              height: "auto",
              width: "80%",
              maxWidth: "900px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <Grid container direction="column" gap={1}>
          <Grid item justifyContent="flex-end">
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid container item direction="column" gap={1}>
            <h1 className="display-large__primary-one">
              {t("App.adminPurchaseOrder.seeDetailModal.title") + " "}

              {order?.ocNumber}
            </h1>

            <label>
              {t("App.businessRules.table.commerce")}:{" "}
              <span className="state-colors__primary-color-one">
                {order?.trader}
              </span>
            </label>
            <br></br>
            <ChooseCausalComponent
              state={selectedCausal}
              setState={setSelectedCausal}
              contentModal={t("App.adminPurchaseOrder.modalInfo.contentTooltip")}
              isModalView={true}
              isGeneral={isGeneral}
              setIsGeneral={setIsGeneral}
              isProduct={isProduct}
              setIsProduct={setIsProduct}
              hasSelectedRows={selectedRows.length > 0}
            />
          </Grid>
        </Grid>

        <DialogContent className="scroll-table full-width">
          <Grid container direction="column">
            <div className="full-width">
              <Grid container item className="full-width">
                {hasFoundData ? (
                  <MUIDataTable
                    className="dataTable__salesForce full-width"
                    data={data}
                    columns={getColumns()}
                    options={options}
                  />
                ) : (
                  <Grid item sx={{ width: "100%" }}>
                    <NoMatchImage />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            gap={1}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "15px" }}
          >
            <Grid item xs={12} sm={3} lg={1}>
              <Button
                className="btn__filled__gradient full-width"
                onClick={handleCloseModal}
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>

            <Grid item xs={12} sm={3} lg={2}>
              <Button
                className={`${
                  selectedRows.length > 0
                    ? "btn__applyFilter"
                    : "btn__applyFilter-disabled"
                } full-width`}
                disabled={!selectedRows.length > 0}
                onClick={() => setIsOpenModalDecision(true)}
              >
                {t("App.adminPurchaseOrder.seeDetailModal.requestButton")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <ModalDecision
        title={t("App.adminPurchaseOrder.seeDetailModal.modalDecisionTitle", {
          ocNumber: order?.ocNumber,
          commerce: order?.trader,
        })}
        message={t("App.adminPurchaseOrder.seeDetailModal.modalDecisionText")}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />

      <ModalInfo
        title={t("App.adminPurchaseOrder.modal.success")}
        responseData={responseMessage}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          setIsOpenModal(false);
        }}
      />
    </>
  );
};

export default ModalSeePurchaseOrderDetail;

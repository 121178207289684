import { Skeleton, Stack } from "@mui/material";
import React from "react";

export const SkeletonModalSelectList = () => {
  return (
    <Stack spacing={2}>
      <Skeleton
        className="background-colors__gradient"
        variant="rectangular"
        width={120}
        height={20}
      />
      <Stack direction="row" spacing={1}>
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={20}
          height={20}
        />
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={120}
          height={20}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={20}
          height={20}
        />
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={120}
          height={20}
        />
      </Stack>{" "}
      <Stack direction="row" spacing={1}>
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={20}
          height={20}
        />
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={120}
          height={20}
        />
      </Stack>{" "}
      <Stack direction="row" spacing={1}>
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={20}
          height={20}
        />
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={120}
          height={20}
        />
      </Stack>{" "}
      <Stack direction="row" spacing={1}>
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={20}
          height={20}
        />
        <Skeleton
          className="background-colors__gradient"
          variant="rectangular"
          width={120}
          height={20}
        />
      </Stack>
    </Stack>
  );
};

import { Grid, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../userSlice";
import { CODES } from "../../consts/codes";
import EditCard from "../../components/common/IndicatorCards/editCard";
import { FilterAdminIndicatorCards } from "../../components/common/Filters/filterAdminIndicatorCards";
import CreateCard from "../../components/common/IndicatorCards/createCard";
import { ApplyFiltersCards } from "../../services/admin/adminServices";
import { NoInfoComponent } from "../../components/common/NoInfoImage/noInfoComponent";
import ModalInfo from "../../components/common/Modals/modalInfo";

const AdminAccessInsights = () => {
  /**
   * Use state
   */
  const [data, setData] = useState([]);
  const [isNoContent, setIsNoContent] = useState(false);
  const [isNoContentFilter, setIsNoContentFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [objFilter, setObjFilter] = useState({});
  /**
   * Get the info of the user who login
   */
  const userInfo = useSelector(getUser);

  /**
   * Use Translation
   */
  const { t, i18n } = useTranslation();

  /**
   * Configurations of the MuiDataTable
   */
  const options = {
    filterType: "dropdown",
    print: false,
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,

    searchOpen: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,

        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Skeleton configurations of the MuiDataTable
   */
  const skeletonOptions = {
    filterType: "dropdown",
    print: false,
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "simple",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,

    searchOpen: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        // noMatch: <NoMatchImage />,
        noMatch: <Skeleton />,

        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Configuration of the table columns
   */
  const columns = [
    {
      name: "insightType",
      label: t("App.indicatorCards.table.insightType"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "insightName",
      label: t("App.indicatorCards.table.insightName"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "description",
      label: t("App.indicatorCards.table.description"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "order",
      label: t("App.indicatorCards.table.order"),
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "status",
      label: t("App.indicatorCards.table.status"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) =>
          value === "1" ? (
            <p className="state-colors__primary-color-one">
              {t("App.adminCompanies.filters.active")}
            </p>
          ) : (
            <p className="state-colors__tertiary-color-one">
              {t("App.adminCompanies.filters.inactive")}
            </p>
          ),
      },
    },
    {
      name: "edit",
      label: t("App.indicatorCards.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];
  /**
   * Method to set the title by indicatorType
   *
   * @param {String} indicatorType
   * @returns text in its respective language
   */
  const indicatorTypeFilter = (indicator, value) => {
    let text = "";
    let txt = "";
    const types = ["Commerce Service Level", "Provider Service Level"];
    if (indicator.product) {
      txt = i18n.language.includes("es")
        ? indicator.typeIndicatorEnglish
        : capitalize(indicator.indicatorType);
      if (indicator.product.includes("CEN")) {
        text = t("App.indicatorCards.nameInsightCEN", {
          type: txt,
        });
      } else {
        text =
          types.includes(indicator.indicatorType) &&
          !i18n.language.includes("es")
            ? t("App.indicatorCards.nameInsight2", {
                type: txt,
              })
            : t("App.indicatorCards.nameInsight", {
                type: txt,
              });
      }
    } else {
      text = indicatorName(indicator.indicatorType);
    }
    return text;
  };

  /**
   * Method to capitalize the first letter of a string
   */
  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /**
   * Obtener el nombre del tipo de indicador sin producto
   * @param {*} indicatorType
   * @returns
   */
  const indicatorName = (indicatorType) => {
    let text = "";
    switch (indicatorType) {
      case "sales":
        text = t("App.indicatorCards.saleInsights");
        break;
      case "devolution":
        text = t("App.indicatorCards.returnInsights");
        break;
      case "stock":
        text = t("App.indicatorCards.inventoryInsights");
        break;
      case "invoice":
        text = t("App.indicatorCards.invoice");
        break;
      case "budget":
        text = t("App.indicatorCards.budget");
        break;
      case "Commerce Service Level" | "serviceleveltrader":
        text = t("App.indicatorCards.traderService");
        break;
      case "Provider Service Level":
        text = t("App.indicatorCards.providerService");
        break;
      case "home":
        text = t("App.indicatorCards.home");
        break;
      case "reception":
        text = t("App.indicatorCards.reception");
        break;
      case "emission":
        text = t("App.indicatorCards.emission");
        break;
      default:
        text = t("App.indicatorCards.payroll");
        break;
    }
    return text;
  };

  /**
   * useEffect to set the data needed for the table
   */
  useEffect(() => {
    async function fetchData() {
      try {
        const obj = {
          lstTypeIndicator: [],
          state: "",
        };
        setObjFilter(obj);

        const listService = await ApplyFiltersCards(obj);
        // console.log(listService);

        const { responseCode, responseMessage } = listService.data;

        if (
          listService.status === CODES.COD_RESPONSE_HTTP_OK &&
          responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const formated = setDataStructureArray(responseMessage);

          // console.log(formated);
          setData(formated);
          setUnfilteredData(formated);
          setIsNoContent(false);
        } else {
          setIsNoContent(true);
          setOpenErrorModal(true);
        }
      } catch (error) {
        setOpenErrorModal(true);

        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
  }, [userInfo.company]);

  /**
   * Sets table data structure for the current data
   *
   * @param {Array} data
   *
   * @returns formated data
   */
  const setDataStructureArray = (data) => {
    const processedRows = data.map((insight) => {
      return {
        insightType: indicatorTypeFilter(insight),
        insightTypeEnglish: insight.indicatorType,
        insightName: i18n.language.includes("es")
          ? insight.name
          : insight.nameEnglish,
        description: i18n.language.includes("es")
          ? insight.description
          : insight.descriptionEnglish,
        order: insight.indicatorOrder,
        status: insight.state,
        product: insight.product,
        edit: (
          <EditCard
            setOpenErrorModal={setOpenErrorModal}
            insight={insight}
            indicatorTypeFilter={indicatorTypeFilter}
            dataArray={data}
            setData={setData}
            setDataStructureArray={setDataStructureArray}
            setIsNoContentFilter={setIsNoContentFilter}
          />
        ),
      };
    });

    // console.log(processedRows);

    return processedRows;
  };

  return (
    <>
      <Grid container>
        <p className="display-large__primary-one" style={{ marginTop: "2%" }}>
          {t("App.indicatorCards.title")}
        </p>
      </Grid>

      <br />

      <Grid container justifyContent="flex-end">
        <CreateCard
          indicatorTypeFilter={indicatorTypeFilter}
          setData={setData}
          setOpenErrorModal={setOpenErrorModal}
          setIsNoContentFilter={setIsNoContentFilter}
          // setIsNoContent={setIsNoContent}
          setDataStructureArray={setDataStructureArray}
          dataArray={data}
        />
      </Grid>

      <br />

      <Grid
        container
        style={{ padding: "15px", marginBottom: "15px" }}
        className="companies_info_container full-width"
        direction="column"
      >
        <Grid item style={{ marginBottom: "40px" }}>
          <FilterAdminIndicatorCards
            setDataStructureArray={setDataStructureArray}
            setIsNoContentFilter={setIsNoContentFilter}
            setIsNoContent={setIsNoContent}
            unfilteredData={unfilteredData}
            setData={setData}
            indicatorTypeFilter={indicatorTypeFilter}
            setIsLoading={setIsLoading}
            setOpenErrorModal={setOpenErrorModal}
          />
        </Grid>

        <Grid item className="full-width">
          {!isNoContent ? (
            !isNoContentFilter ? (
              isLoading ? (
                <MUIDataTable
                  className="dataTable__base"
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                />
              ) : (
                <MUIDataTable
                  className="dataTable__base"
                  data={data}
                  columns={columns}
                  options={options}
                />
              )
            ) : (
              <NoMatchImage />
            )
          ) : (
            <NoInfoComponent type="indicatorCards" />
          )}
        </Grid>
        {/* Modal para fallo de servicios */}
        <ModalInfo
          title={t("App.validationMessages.error")}
          responseData={{
            status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
            data: {
              responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
              responseMessage: t("App.validationMessages.systemError2"),
            },
          }}
          open={openErrorModal}
          onClose={() => {
            setOpenErrorModal(false);
          }}
        />
      </Grid>
    </>
  );
};

export default AdminAccessInsights;

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ModalInfo from "./Modals/modalInfo";
import { ModalDecision } from "./Modals/modalDecision";
import {
  SaveUrlAccessIndicators,
  AdministrationAccessIndicators,
} from "../../services/admin/adminServices";
import { CODES } from "../../consts/codes";
import { getUser } from "../../pages/userSlice";
import { useSelector } from "react-redux";

const ModalUpdateIndicator = ({
  open,
  setOpen,
  insight,
  setInsight,
  filters,
  setData,
  setIsLoadingData,
  setDataStructure,
  setIsNoFilterFound,
  list,
  setOpenErrorModal,
}) => {
  //Const
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Disabled
  const [isDisabledUrl, setIsDisabledUrl] = useState(false);
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [inputValue, setInputValue] = useState("");

  //Modals
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [messageModalInfo, setMessageModalInfo] = useState("");
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [titleModalDecision, setTitleModalDecision] = useState("");
  const [messageModalDecision, setMessageModalDecision] = useState("");
  const [obj, setObj] = useState({});
  const [responseInfo, setResponseInfo] = useState({});

  //userInfo
  const userInfo = useSelector(getUser);

  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Determinar si se habilita el campo de URL
   */
  const enabledField = () => {
    return (
      getValues("enabled") === "NO" ||
      getValues("enabled")?.props?.value === "NO"
    );
  };

  //useForm
  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nameInsightSpanish: "",
      descriptionSpanish: "",
      nameInsightEnglish: "",
      descriptionEnglish: "",
      enabled: "",
      url: "",
    },
  });

  /**
   * Function to set the info in the fields of the modal edit
   */
  const handleSetInformation = () => {
    setValue("nameInsightSpanish", insight.nameSpanish);
    setValue("descriptionSpanish", insight.descriptionSpanish);
    setValue("nameInsightEnglish", insight.nameEnglish);
    setValue("descriptionEnglish", insight.descriptionEnglish);
    setSelectedValue(insight.enabled ? insight.enabled : "NO");
    if (insight.enabled === "SI") {
      setIsDisabledUrl(false);
    }
    setValue("enabled", insight.enabled ? insight.enabled : "NO");
    setInputValue(insight.url ? insight.url : "");
    setValue("url", insight.url ? insight.url : "");
  };

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Function to close the modal and reset its fields
   */
  const handleClose = async () => {
    setIsDisabledUrl(true);
    setInputValue(insight.url);
    setSelectedValue(insight.enabled);
    setInsight([]);
    reset();
    setOpen(false);
  };

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect to update the state of isDesktop based on the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * useEffect to set the values
   */
  useEffect(() => {
    if (insight !== null) {
      handleSetInformation();
    }
    setIsDisabledUrl(insight.enabled === "NO" ? true : false);
  }, [insight]);

  /**
   * Este servicio permite obtener el listado de acceso a las tarjetas de indicadores para su configuracion de url
   * @returns listado de acceso a las tarjetas de indicadores
   */
  const getAccessIndicators = async (data) => {
    const requestAccessIndicators = await AdministrationAccessIndicators(data);

    const responseCode = requestAccessIndicators.data.responseCode;

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        return requestAccessIndicators.data.responseMessage;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        return [];

      default:
        return [];
    }
  };

  /**
   * Este servicio permite guardar un nuevo acceso para los diferentes indicadores de cada cliente
   * @returns guardado exitoso
   */
  const updateUrlAccessIndicators = async (data) => {
    const requestAccessIndicators = await SaveUrlAccessIndicators(data);

    const responseCode = requestAccessIndicators.data.responseCode;

    switch (responseCode) {
      case CODES.COD_RESPONSE_SUCCESS_REQUEST:
        setMessageModalInfo(t("App.indicadors.edit.success"));
        setResponseInfo({
          status: CODES.COD_RESPONSE_SUCCESS,
          data: { responseCode: CODES.COD_RESPONSE_SUCCESS },
        });
        return requestAccessIndicators.data.responseMessage;

      case CODES.COD_RESPONSE_ERROR_LOGIN:
        setMessageModalInfo(t("App.indicatorCards.modalInfo.title"));
        setResponseInfo(requestAccessIndicators);
        return requestAccessIndicators.data;

      default:
        setMessageModalInfo(t("App.validationMessages.error"));
        setResponseInfo(requestAccessIndicators);
        return requestAccessIndicators.data;
    }
  };

  /**
   * Handle to VALIDATE the URL
   * @param {event} event
   */

  const handleInputChange = (event) => {
    const value = event.target.value;
    //Validar que la URL tenga data
    if (value) {
      setIsDisabledConfirm(false);
    } else {
      setIsDisabledConfirm(true);
    }
    // Validación para bloquear caracteres especiales
    //if (/^[a-zA-Z0-9.,@]*$/.test(value)) {
    setInputValue(value);
    //}
  };

  /**
   * Handle to change the select option
   * @param {Object} data
   */

  const handleChangeSelect = (event) => {
    setSelectedValue(event.target.value);
    //Verificar si la URL esta vacia
    if (event.target.value === "SI") {
      setIsDisabledUrl(false);
    }
    //Verificar si la URL esta vacia y se Habilito el indicador
    if (event.target.value === "SI" && insight.url === null) {
      setIsDisabledConfirm(true);
    }
    //Verificar se Inhabilito el indicador
    if (event.target.value === "NO") {
      setIsDisabledConfirm(false);
      setIsDisabledUrl(true);
    }
  };

  /**
   * Handle agree to the select ModalDecision
   */
  const handleAgree = async () => {
    async function editService() {
      const editService = await updateUrlAccessIndicators(obj);

      setLoadingEdit(false);

      setIsLoadingData(true);
      const accessIndicatorsService = await getAccessIndicators(filters);

      //Llenamos los datos
      const finalArray = setDataStructure(accessIndicatorsService);
      if (finalArray.length > 0) {
        //Si el filtro trae información
        setIsNoFilterFound(false);
        setData(finalArray);
      } else {
        //SI no trae info
        setData([]);
        setIsNoFilterFound(true);
      }
    }

    await editService();

    setIsDisabledUrl(true);
    setInputValue(insight.url);
    setSelectedValue(insight.enabled);
    setInsight([]);
    await reloadDataTable();

    setOpenModalInfo(true);
    setOpenModalDecision(false);
  };

  /**
   * Handle disagree to the select ModalDecision
   */
  const handleDisagree = () => {
    setIsDisabledUrl(true);
    setInputValue(insight.url);
    setSelectedValue(insight.enabled);
    setInsight([]);
    setOpenModalDecision(false);
  };

  /**
   *
   * Function to edit an insight
   *
   * @param {Object} data
   */
  const handleEditInsight = async (data) => {
    try {
      const request = {
        pk: insight.pk,
        sk: insight.sk,
        eanProvider: insight.eanProvider,
        provider: insight.provider,
        country: insight.country,
        name: insight.name,
        indicatorType: insight.edit.indicatorType,
        idIdentificator: insight.idIdentificator,
        url: inputValue,
        enabled: selectedValue,
        user: userInfo.firstName + " " + userInfo.lastName,
      };
      setObj(request);
      setLoadingEdit(true);
      setOpen(false);
      //Verificar si se desabilito un indicador con una URl definida
      if (selectedValue === "NO" && data.url != "") {
        //Vefiricar el idioma para los mensajes de respuesta
        if (t("App.indicadors.edit.confirmMessage") === "Bueno") {
          setTitleModalDecision(
            "¿Estás seguro de inhabilitar el acceso al Indicador " +
              insight.name +
              "?"
          );
          setMessageModalDecision(
            "Una vez inhabilitado el acceso al indicador, la empresa " +
              insight.provider +
              " ya no podrá visualizar y por ende acceder al indicador " +
              insight.name +
              " en la opción de " +
              insight.indicatorType
          );
          setOpenModalDecision(true);
        } else {
          setTitleModalDecision(
            "Are you sure to disable access to Indicator " + insight.name + "?"
          );
          setMessageModalDecision(
            "Once access to the indicator is disabled, the company " +
              insight.provider +
              " will no longer be able to view and therefore access the indicator " +
              insight.name +
              " in the " +
              insight.indicatorType +
              " option"
          );
          setOpenModalDecision(true);
        }
      } else {
        const updateService = await updateUrlAccessIndicators(request);
        //Condicional de error de sistema
        if (updateService.responseCode === CODES.COD_RESPONSE_ERROR_CREATE) {
          setIsLoadingData(false);
          setOpenModalInfo(true);
          setLoadingEdit(false);
          setIsDisabledUrl(true);
          setInputValue(insight.url);
          setSelectedValue(insight.enabled);
          setInsight([]);
        } else {
          //Verificar si hay otra URL igual a la editada
          if (updateService.responseCode === CODES.COD_RESPONSE_ERROR_LOGIN) {
            setIsLoadingData(false);
            setOpenModalInfo(true);
            setLoadingEdit(false);
            setIsDisabledUrl(true);
            setInputValue(insight.url);
            setSelectedValue(insight.enabled);
            setInsight([]);
          } else {
            setIsDisabledUrl(true);
            setInputValue(insight.url);
            setSelectedValue(insight.enabled);
            setInsight([]);

            await reloadDataTable();
          }
        }
      }
    } catch (error) {
      setOpenErrorModal(true);
      setIsDisabledUrl(true);
      setInputValue(insight.url);
      setSelectedValue(insight.enabled);
      setInsight([]);
      console.log(
        "====== Error modalUpdateIndicator.jsx handleEditInsight ======"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  const reloadDataTable = async () => {
    try {
      //Cargamos la informacion de la tabla nuevamente
      setIsLoadingData(true);
      const accessIndicatorsService = await getAccessIndicators(filters);

      //Llenamos los datos
      const finalArray = setDataStructure(accessIndicatorsService);
      if (finalArray.length > 0) {
        //Si el filtro trae información
        setIsNoFilterFound(false);
        setData(finalArray);
      } else {
        //SI no trae info
        setData([]);
        setIsNoFilterFound(true);
      }
      setIsLoadingData(false);
      setOpenModalInfo(true);
      setLoadingEdit(false);
      setIsDisabledUrl(true);
    } catch (error) {
      setOpenErrorModal(true);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "100%", // Asegúrate de que no sea más ancho que la pantalla
              width: "auto", // Permite que el ancho se ajuste al contenido
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
              overflow: "hidden",
              alignContent: "center",
            },
          },
        }}
      >
        <DialogTitleContainer onClose={handleClose}>
          <Grid container direction="column" rowSpacing={1}>
            <Grid item className="display-large__primary-one">
              {t("App.indicadors.edit.title")}
              {insight.name}
            </Grid>
          </Grid>
          <br />
          <Grid container direction="column" columnSpacing={3}>
            <Grid item className="display-small__primary-one">
              {t("App.indicadors.edit.subTitle")}
              {insight.provider}
            </Grid>
          </Grid>
        </DialogTitleContainer>

        <DialogContent>
          <Grid container direction="column" className="full-width">
            <Grid item sx={{ marginTop: "5px" }}>
              <form id="edit-form" onSubmit={handleSubmit(handleEditInsight)}>
                <Grid container item direction="column" spacing={1}>
                  <Grid item container>
                    <Grid item container alignItems="center" spacing={2}>
                      <Grid
                        className="editInsigth-form"
                        item
                        container
                        spacing={2}
                        sx={{ marginBottom: "1rem" }}
                      >
                        <Grid
                          item
                          xs={11}
                          sm={11}
                          lg={4}
                          className="editInsigth-form__container"
                        >
                          <Controller
                            defaultValue=""
                            name="nameInsightSpanish"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                label={t("App.indicadors.edit.insightName")}
                                disabled={true}
                                className={`${
                                  errors.nameInsightSpanish
                                    ? "modalEditRoles__form-input--error"
                                    : "modalCreateRoles__form-input"
                                } full-width`}
                                FormHelperTextProps={{
                                  style: {
                                    margin: "-5px 0 1px 0px",
                                    color: "#e52900",
                                    fontSize: 10,
                                  },
                                }}
                                helperText={
                                  errors.nameInsightSpanish
                                    ? errors.nameInsightSpanish.message
                                    : ""
                                }
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={11} sm={11} lg={8}>
                          <Controller
                            defaultValue=""
                            name="descriptionSpanish"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                label={t("App.indicadors.edit.description")}
                                disabled={true}
                                className={`${
                                  errors.descriptionSpanish
                                    ? "modalEditRoles__form-input--error"
                                    : "modalCreateRoles__form-input"
                                } full-width`}
                                FormHelperTextProps={{
                                  style: {
                                    margin: "-5px 0 1px 0px",
                                    color: "#e52900",
                                    fontSize: 10,
                                  },
                                }}
                                helperText={
                                  errors.descriptionSpanish
                                    ? errors.descriptionSpanish.message
                                    : ""
                                }
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={2}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <Grid item container spacing={2}>
                        <Grid item xs={11} sm={11} lg={4}>
                          <Controller
                            defaultValue=""
                            name="nameInsightEnglish"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                label={t(
                                  "App.indicadors.edit.insightNameEnglish"
                                )}
                                disabled={true}
                                className={`${
                                  errors.nameInsightEnglish
                                    ? "modalEditRoles__form-input--error"
                                    : "modalCreateRoles__form-input"
                                } full-width`}
                                FormHelperTextProps={{
                                  style: {
                                    margin: "-5px 0 1px 0px",
                                    color: "#e52900",
                                    fontSize: 10,
                                  },
                                }}
                                helperText={
                                  errors.nameInsightEnglish
                                    ? errors.nameInsightEnglish.message
                                    : ""
                                }
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={11} sm={11} lg={8}>
                          <Controller
                            defaultValue=""
                            name="descriptionEnglish"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                onChange={onChange}
                                value={value}
                                label={t(
                                  "App.indicadors.edit.descriptionEnglish"
                                )}
                                disabled={true}
                                className={`${
                                  errors.descriptionEnglish
                                    ? "modalEditRoles__form-input--error"
                                    : "modalCreateRoles__form-input"
                                } full-width`}
                                FormHelperTextProps={{
                                  style: {
                                    margin: "-5px 0 1px 0px",
                                    color: "#e52900",
                                    fontSize: 10,
                                  },
                                }}
                                helperText={
                                  errors.descriptionEnglish
                                    ? errors.descriptionEnglish.message
                                    : ""
                                }
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={2}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <Grid item container spacing={2}>
                        <Grid item xs={11} sm={8} lg={4}>
                          <FormControl className="full-width">
                            <InputLabel className="modalCreateOptions__form__inputLabel ">
                              {t("App.indicadors.edit.enabled")}
                            </InputLabel>
                            <Controller
                              control={control}
                              name="enabled"
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  value={selectedValue}
                                  labelId="labelStatus"
                                  label={t("App.indicadors.edit.enabled")}
                                  className={
                                    errors.enabled
                                      ? "modal__form-input--error"
                                      : "modal__form-input"
                                  }
                                  onChange={(event, values, reason) => {
                                    onChange(values);
                                    handleChangeSelect(event);
                                  }}
                                >
                                  <MenuItem disabled>
                                    <em>{t("App.indicadors.edit.enabled")}</em>
                                  </MenuItem>
                                  <MenuItem value="SI">
                                    {t("App.indicadors.edit.statusEnabled")}
                                  </MenuItem>
                                  <MenuItem value="NO">
                                    {t("App.indicadors.edit.statusDisabled")}
                                  </MenuItem>
                                </Select>
                              )}
                            />

                            <FormHelperText
                              sx={{
                                margin: "-10px 0 0 0",
                                color: "#e52900",
                              }}
                            >
                              {errors.status ? errors.status.message : ""}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={2}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <Grid item container spacing={2}>
                        <Grid item xs={11} sm={11} lg={12}>
                          <Controller
                            defaultValue=""
                            name="url"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                onChange={(event, values, reason) => {
                                  onChange(values);
                                  handleInputChange(event);
                                }}
                                value={inputValue}
                                label={t("App.indicadors.table.URL")}
                                disabled={enabledField()}
                                className={`${
                                  errors.url
                                    ? "modalEditRoles__form-input--error"
                                    : "modalCreateRoles__form-input"
                                } full-width`}
                                FormHelperTextProps={{
                                  style: {
                                    margin: "-5px 0 1px 0px",
                                    color: "#e52900",
                                    fontSize: 10,
                                  },
                                }}
                                helperText={
                                  errors.url ? errors.url.message : ""
                                }
                                inputProps={{ maxLength: 300 }}
                                InputLabelProps={{
                                  style: {
                                    margin: "-5px 0 0 -10px",
                                    padding: "0 0 0 5px",
                                    color: "#8c8d9d",
                                    fontSize: 13,
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction={isDesktop ? "row" : "column-reverse"}
            className="col-end"
            columnGap={4}
            sx={{ padding: "1.5%" }}
            mt={-5}
          >
            <Grid item xs={11} sm={2} lg={1} className="full-width">
              <Button
                className="btn__filled__gradient full-width"
                onClick={handleClose}
              >
                {t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            <Grid item xs={11} sm={4} lg={2.2} className="full-width">
              <Button
                disabled={isDisabledConfirm}
                className={`${
                  !isDisabledConfirm
                    ? "btn__applyFilter"
                    : "btn__applyFilter-disabled"
                } full-width`}
                type="submit"
                form="edit-form"
                startIcon={!loadingEdit ? <CheckIcon /> : <></>}
              >
                {!loadingEdit ? (
                  t("App.editUser.confirmButton")
                ) : (
                  <CircularProgress size={21} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ModalDecision
        title={titleModalDecision}
        message={messageModalDecision}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setOpenModalDecision}
        isOpen={openModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
      <ModalInfo
        title={messageModalInfo}
        responseData={responseInfo}
        open={openModalInfo}
        onClose={() => {
          setOpenModalInfo(false);
        }}
      />
    </>
  );
};

export default ModalUpdateIndicator;

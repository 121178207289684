import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadBox = ({
  handleDownload,
  description,
  buttonText,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" className="downloadBox">
      <Grid item className="col-flex downloadBox__title-box">
        <p className=" subheading__text-default">{description}</p>
      </Grid>
      <Grid item>
        <Button
          disableRipple
          onClick={() => {
            handleDownload();
          }}
          className="btn__filled__blue downloadBox__button button__text-white"
          startIcon={!isLoading ? <SaveAltIcon /> : <></>}
          variant="contained"
        >
          {!isLoading ? (
            buttonText
          ) : (
            <CircularProgress size={21} color="inherit" />
          )}
        </Button>
      </Grid>

      {isLoading && (
        <Grid item className="col-flex" style={{ paddingTop: "1.5%" }}>
          <p className="subheading__moderate-blue">
            {t("App.catalogue.paragraphUpload")}
          </p>
        </Grid>
      )}
    </Grid>
  );
};

export default DownloadBox;

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CODES } from "../../../consts/codes";
import {
  ApproveOCCommerce,
  GetProductsOCCommerce,
} from "../../../services/user/adminPurchaseOrder/PurcharseOrderCommerceService";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import FileState from "../fileState";
import { ModalDecision } from "./modalDecision";
import ModalInfo from "./modalInfo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ModalSeePurchaseOrderDetailCommerce = ({
  order,
  userInfo,
  open,
  setOpen,
  setTitle,
  setOpenErrorModal,
  approveOC,
  declineOC,
  updateListRequest,
}) => {
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [hasFoundData, setHasFoundData] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCausalTitle, setIsCausalTitle] = useState(true);
  const [causalTitle, setCausalTitle] = useState("");
  const [approveProducts, setApproveProducts] = useState([]);
  const [rejectProducts, setRejectProducts] = useState([]);
  const [modalInfoTitle, setModalInfoTitle] = useState("");
  let reqApproves = [];
  let reqRejects = [];

  const [data, setData] = useState([]);

  const { t } = useTranslation();

  /**
   * Columna para la traduccion de causales
   */
  const causalNameColumn =
    t("App.adminPurchaseOrder.request") === "Solicitar"
      ? {
          name: "causalName",
          label: t("App.adminPurchaseOrder.seeDetailModal.causal"),
          options: { filter: false, searchable: false },
        }
      : {
          name: "causalNameEnglish",
          label: t("App.adminPurchaseOrder.seeDetailModal.causal"),
          options: { filter: false, searchable: false },
        };

  /**
   * Configuraciones para las columnas de la tabla
   */
  const columns = [
    {
      name: "eanProduct",
      label: t("App.adminPurchaseOrder.seeDetailModal.eanProduct"),
      options: { filter: false, sort: false },
    },
    {
      name: "product",
      label: t("App.adminPurchaseOrder.seeDetailModal.product"),
      options: {
        filter: false,
        hint: (              
          <span style={{ textAlign: "left"}}>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextOne")}</p>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextTwo")}</p>
          </span>
          ),
      },
    },
    {
      name: "quantity",
      label: t("App.adminPurchaseOrder.seeDetailModal.quantity"),
      options: { filter: false, searchable: false },
    },
  ];
  
  // Añadimos causalNameColumn si el checkProduct es "SI"
  if (order.checkProduct === "SI") {
    columns.push(causalNameColumn);
    columns.push({
      name: "actions",
      label: t("App.adminPurchaseOrderCommerce.modalSeeDetails.actions"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container justifyContent="center" alignItems="center">
            {t("App.adminPurchaseOrderCommerce.modalSeeDetails.actions")}

            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Grid item>
                <label className="label__text-grey">
                  {t("App.adminPurchaseOrderCommerce.decline")}
                </label>{" "}
                <img
                  src="/assets/actionButtons/decline.svg"
                  style={{ width: "10px" }}
                  alt="decline"
                />
              </Grid>

              <Grid item>
                <img
                  src="/assets/actionButtons/approve.svg"
                  style={{ width: "10px" }}
                  alt="approve"
                />{" "}
                <label className="label__text-grey">
                  {t("App.adminPurchaseOrderCommerce.approve")}
                </label>
              </Grid>
            </Grid>
          </Grid>
        ),
        customBodyRender: (item) => {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              key={"a" + item.eanProduct}
            >
              <Grid item>
                <IconButton onClick={(e) => handleRejected(item, e)}>
                  <img
                    src={`/assets/actionButtons/${
                      rejectProducts?.length > 0 &&
                      rejectProducts?.some(
                        (element) =>
                          JSON.stringify(element) === JSON.stringify(item)
                      )
                        ? "declineColor"
                        : "decline"
                    }.svg`}
                    id={`button-${item.eanProduct}-rejected`}
                    alt="decline"
                  />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton onClick={(e) => handleApproved(item, e)}>
                  <img
                    src={`/assets/actionButtons/${
                      approveProducts?.length > 0 &&
                      approveProducts?.some(
                        (element) =>
                          JSON.stringify(element) === JSON.stringify(item)
                      )
                        ? "approveColor"
                        : "approve"
                    }.svg`}
                    id={`button-${item.eanProduct}-approved`}
                    alt="approve"
                  />
                </IconButton>
              </Grid>
            </Grid>
          );
        },
      },
    },);
  }else{
    columns.push({
      name: "actions",
      label: t("App.adminPurchaseOrderCommerce.modalSeeDetails.actions"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container justifyContent="center" alignItems="center">
            {t("App.adminPurchaseOrderCommerce.modalSeeDetails.actions")}

            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Grid item>
                <label className="label__text-grey">
                  {t("App.adminPurchaseOrderCommerce.decline")}
                </label>{" "}
                <img
                  src="/assets/actionButtons/decline.svg"
                  style={{ width: "10px" }}
                  alt="decline"
                />
              </Grid>

              <Grid item>
                <img
                  src="/assets/actionButtons/approve.svg"
                  style={{ width: "10px" }}
                  alt="approve"
                />{" "}
                <label className="label__text-grey">
                  {t("App.adminPurchaseOrderCommerce.approve")}
                </label>
              </Grid>
            </Grid>
          </Grid>
        ),
        customBodyRender: (item) => {
          return (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              key={"a" + item.eanProduct}
            >
              <Grid item>
                <IconButton onClick={(e) => handleRejected(item, e)}>
                  <img
                    src={`/assets/actionButtons/${
                      rejectProducts?.length > 0 &&
                      rejectProducts?.some(
                        (element) =>
                          JSON.stringify(element) === JSON.stringify(item)
                      )
                        ? "declineColor"
                        : "decline"
                    }.svg`}
                    id={`button-${item.eanProduct}-rejected`}
                    alt="decline"
                  />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton onClick={(e) => handleApproved(item, e)}>
                  <img
                    src={`/assets/actionButtons/${
                      approveProducts?.length > 0 &&
                      approveProducts?.some(
                        (element) =>
                          JSON.stringify(element) === JSON.stringify(item)
                      )
                        ? "approveColor"
                        : "approve"
                    }.svg`}
                    id={`button-${item.eanProduct}-approved`}
                    alt="approve"
                  />
                </IconButton>
              </Grid>
            </Grid>
          );
        },
      },
    },);
  }

  const skeletonLine = [
    {
      eanProduct: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      product: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      quantity: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
      actions: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
    },
  ];

  /**
   * Configuraciones para las columnas si la Orden de Compra
   * ya gestionada
   */
  const columnsManaged = [
    {
      name: "eanProduct",
      label: t("App.adminPurchaseOrder.seeDetailModal.eanProduct"),
      options: { filter: false, sort: false },
    },
    {
      name: "product",
      label: t("App.adminPurchaseOrder.seeDetailModal.product"),
      options: {
        filter: false,
        hint: (              
          <span style={{ textAlign: "left"}}>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextOne")}</p>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextTwo")}</p>
          </span>
          ),
      },
    },
    {
      name: "quantity",
      label: t("App.adminPurchaseOrder.seeDetailModal.quantity"),
      options: { filter: false, searchable: false },
    },
  ];
  
  // Añadimos la columna "actions" si el estado es "APROBADO PARCIAL"
  if (order.requestState.toUpperCase() === "APROBADO PARCIAL") {
    columnsManaged.push({
      name: "actions",
      label: t("App.adminClientUsers.table.state"),
      options: {
        filter: true,
        customBodyRender: (value) => (
          <FileState value={value.itemState ? value.itemState : "Pendiente"} />
        ),
      },
    });
  }
  
  // Añadimos causalNameColumn si el checkProduct es "SI"
  if (order.checkProduct === "SI") {
    columnsManaged.push(causalNameColumn);
  }
  

  /**
   * Configuraciones para las columnas si la tabla está cargando
   */
  const columnsLoading = [
    {
      name: "eanProduct",
      label: t("App.adminPurchaseOrder.seeDetailModal.eanProduct"),
      options: { filter: false, sort: false },
    },
    {
      name: "product",
      label: t("App.adminPurchaseOrder.seeDetailModal.product"),
      options: {
        filter: false,
        hint: (              
          <span style={{ textAlign: "left"}}>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextOne")}</p>
            <p>{t("App.adminPurchaseOrder.seeDetailModal.tooltipTextTwo")}</p>
          </span>
          ),
      },
    },
    {
      name: "quantity",
      label: t("App.adminPurchaseOrder.seeDetailModal.quantity"),
      options: { filter: false, searchable: false },
    },
    {
      name: "actions",
      label:
        order.requestState !== "Pendiente"
          ? t("App.adminClientUsers.table.state")
          : t("App.adminPurchaseOrderCommerce.modalSeeDetails.actions"),
    },
    order.checkProduct === "SI"
      ? causalNameColumn
      : {
          name: "",
          options: { filter: false, sort: false, searchable: false },
        },
  ];

  useEffect(() => {
    fetchProductsOC();
  }, [open, order]);

  /**
   * useEffect para cuando data (los datos de la tabla) cambia se llama la funcion que tipo de causal es
   */
  useEffect(() => {
    setCausalOption();
  }, [data]);

  /**
   * Función para saber si se deben mostrar los causales a nivel de columna o de texto
   */
  const setCausalOption = () => {
    if (order.checkGeneral === "SI") {
      setIsCausalTitle(true);
      if (t("App.buttonLabels.confirm")==="Confirmar"){
        setCausalTitle((data[0].causalName));
      }else{
        setCausalTitle((data[0].causalNameEnglish));
      }
    } else if (order.checkProduct === "SI") {
      setIsCausalTitle(false);
    }
  };
  /**
   * Función para obtener los productos de la orden de compra
   */
  const fetchProductsOC = () => {
    setIsLoading(true);
    if (userInfo) {
      getProductsOC();
    }
  };

  /**
   * Formatear la información de la tabla
   * @param {*} data información para la tabla
   * @returns
   */
  const setDataStructure = (data) => {
    const processedRows = data.map((item, index) => {
      return {
        eanProduct: !item.eanProduct ? " - " : item.eanProduct,
        product: !item.product ? " - " : item.product,
        quantity: !item.quantity ? " - " : item.quantity,
        causalName: !item.causalName ? " - " : item.causalName,
        causalNameEnglish: !item.causalNameEnglish
          ? " - "
          : item.causalNameEnglish,
        actions: item,
      };
    });
    return processedRows;
  };

  /**
   * Función para obtener los productos de la orden de compra
   */
  const getProductsOC = async () => {
    try {
      setData(skeletonLine);
      const obj = {
        eanTrader: order.eanTrader,
        eanProvider: order.eanProvider,
        ocNumber: order.ocNumber,
        requestId: order.requestId,
      };
      const {
        status,
        data: { responseCode, responseMessage },
      } = await GetProductsOCCommerce(obj);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        switch (responseCode) {
          case CODES.COD_RESPONSE_SUCCESS_REQUEST:
            setHasFoundData(responseMessage.length > 0 ? true : false);
            setData(setDataStructure(responseMessage));

            break;

          default:
            setHasFoundData(false);
            setData([]);

            break;
        }
      } else {
        setData([]);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("---- ERROR getProductsOC ----");
      console.log(error);
    }
    setIsLoading(false);
  };

  /**
   *
   * Función que se ejecuta cuando se presiona el botón de aprobar (check) y de este modo realizar
   * el cambio de estado de isApproved y cambiar el color del botón
   *
   * @param {Number} item
   */
  const handleApproved = (item, e) => {
    e.preventDefault();
    reqRejects = rejectProducts;
    reqApproves = approveProducts;
    if (reqRejects) {
      let isInRej =
        reqRejects.length > 0
          ? reqRejects.find((element) => element.eanProduct === item.eanProduct)
          : false;
      if (isInRej) {
        reqRejects = reqRejects.filter(
          (element) => element.eanProduct !== item.eanProduct
        );
      }
    }

    reqApproves.push(item);
    reqApproves = Array.from(new Set(reqApproves));
    setRejectProducts([...reqRejects]);
    setApproveProducts([...reqApproves]);
  };

  /**
   *
   * Función que se ejecuta cuando se presiona el botón de rechazar (x) y de este modo realizar
   * el cambio de estado de isRejected y cambiar el color del botón
   *
   * @param {Number} item
   */
  const handleRejected = (item, e) => {
    e.preventDefault();
    reqRejects = rejectProducts;
    reqApproves = approveProducts;

    // Actualizar la lista de aprobados
    if (reqApproves) {
      let isInApp =
        reqApproves.length > 0
          ? reqApproves.find(
              (element) => element.eanProduct === item.eanProduct
            )
          : false;
      if (isInApp) {
        reqApproves = reqApproves.filter(
          (element) => element.eanProduct !== item.eanProduct
        );
      }
    }
    reqRejects.push(item);
    reqRejects = Array.from(new Set(reqRejects));
    setRejectProducts([...reqRejects]);
    setApproveProducts([...reqApproves]);
  };

  /**
   * Función para realizar la petición de aprobación o rechazo de cada producto de la orden de compra
   * @param {*} products productos de la orden de compra
   */
  const reqAppRequest = async (products) => {
    try {
      let updatedOC = {};
      // productos para aprobar
      setApproveProducts((product) => {
        product.map((item) => {
          delete item.quantity;
          item.itemState = "Aprobado";
          return item;
        });
      });
      // productos para rechazar
      setRejectProducts((product) => {
        product.map((item) => {
          delete item.quantity;
          item.itemState = "Rechazado";
          return item;
        });
      });
      updatedOC = {
        eanTrader: order.eanTrader,
        trader: order.trader,
        ocNumber: order.ocNumber,
        eanProvider: order.eanProvider,
        provider: order.provider,
        requestId: order.requestId,
        lstItemOcExcludeds: approveProducts.concat(rejectProducts),
        country: order.country,
        userName: userInfo.firstName + " " + userInfo.lastName,
      };

      const {
        status,
        data: { responseCode, responseMessage },
      } = await ApproveOCCommerce([updatedOC]);
      if (status === CODES.COD_RESPONSE_SUCCESS) {
        setIsOpenModalDecision(false);
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          setModalInfoTitle(responseMessage);
          setIsOpenModalInfo(true);
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      console.log("-- error Approve ----");
      console.log(error);
      console.log("---------------------");
      setOpenErrorModal(true);
    }
    await handleCloseModal();
  };

  /**
   * Configuraciones necesarias para la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    searchPlaceholder: t("App.requestsPurchaseOrder.searchText"),
    rowsPerPageOptions: [10, 15, 20],
    selectToolbarPlacement: "none",
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Cerrar modal y resetear información
   */
  const handleCloseModal = async () => {
    setOpen(false);
    await updateListRequest();

    setIsOpenModalDecision(false);
    setApproveProducts([]);
    setRejectProducts([]);
    setIsApproved(false);
    setIsRejected(false);
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo y isOpenModalInfo, cómo también enviar la info para el modalInfo
   */
  const handleAgree = async () => {
    if (
      (approveProducts && approveProducts.length > 0) ||
      (rejectProducts && rejectProducts.length > 0)
    ) {
      await reqAppRequest(approveProducts);
    }
  };
  /**
   * Función para validar si se puede habilitar el botón de confirmar
   */
  const isEnabledConfirm = () => {
    return (
      approveProducts &&
      rejectProducts &&
      approveProducts.length + rejectProducts.length === data.length
    );
  };

  /**
   * Función para actualizar el estado de isOpenModalInfo a false
   */
  const handleDisagree = () => {
    setIsOpenModalDecision(false);
  };

  return (
    <>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="dialog-title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              height: "auto",
              width: "80%",
              maxWidth: "900px",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <Grid container direction="column" gap={1}>
          <Grid item justifyContent="flex-end">
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid container item direction="column" gap={1}>
            <h1 className="display-large__primary-one">
              {t("App.adminPurchaseOrder.seeDetailModal.title")}{" "}
              {order?.ocNumber}{" "}
            </h1>

            <label>
              {t("App.adminPurchaseOrderCommerce.table.supplier")}:{" "}
              <span className="state-colors__primary-color-one">
                {order?.provider}
              </span>
            </label>
            {order?.requestState.toUpperCase() !== "PENDIENTE" &&
              order?.requestState.toUpperCase() !== "APROBADO PARCIAL" && (
                <label>
                  <span
                    className={`${
                      order.requestState.toUpperCase() === "APROBADO"
                        ? "text-colors__success"
                        : "text-colors__error-state"
                    }`}
                  >
                    {t("App.adminClientUsers.table.state")}
                    {": "} {order.requestState}
                  </span>
                </label>
              )}
            {isCausalTitle && (
              <label className="state-colors__orange">
                {t("App.adminPurchaseOrder.seeDetailModal.causal")}:{" "}
                {causalTitle}
              </label>
            )}
          </Grid>
        </Grid>

        <DialogContent className="scroll-table full-width">
          <Grid container direction="column" mt={3}>
            <div className="full-width">
              <Grid container item className="full-width">
                {hasFoundData ? (
                  <MUIDataTable
                    className="dataTable__salesForce full-width"
                    data={isLoading ? skeletonLine : data}
                    columns={
                      isLoading
                        ? columnsLoading
                        : order.requestState !== "Pendiente"
                        ? columnsManaged
                        : columns
                    }
                    options={options}
                  />
                ) : (
                  <Grid item sx={{ width: "100%" }}>
                    <NoMatchImage />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            gap={1}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "15px" }}
          >
            {order.requestState === "Pendiente" && (
              <Grid item xs={12} marginBottom={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent={"flex-start"}
                  alignItems="center"
                  className="modalCreateUsers__tooltip"
                >
                  <Grid
                    item
                    lg={0.5}
                    md={0.5}
                    sm={0.9}
                    xs={1.3}
                    height={"100%"}
                    alignContent={"start"}
                    textAlign={"center"}
                    alignItems={"center"}
                    style={{ paddingTop: "0.8%" }}
                  >
                    <InfoOutlinedIcon fontSize="large" />
                  </Grid>

                  <Grid item textAlign={"left"} md={11.5} sm={11.1} xs={10.7}>
                    <p>
                      {t(
                        "App.adminPurchaseOrderCommerce.modalSeeDetails.infoText"
                      )}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} sm={3} md={2} lg={1}>
              <Button
                className={
                  order.requestState !== "Pendiente"
                    ? "btn__applyFilter"
                    : "btn__filled__gradient full-width"
                }
                onClick={handleCloseModal}
              >
                {order.requestState !== "Pendiente"
                  ? t("App.buttonLabels.accept")
                  : t("App.buttonLabels.cancel")}
              </Button>
            </Grid>
            {order.requestState === "Pendiente" && (
              <Grid item xs={12} sm={3} md={2} lg={1}>
                <Button
                  className={`${
                    isEnabledConfirm()
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  } full-width`}
                  disabled={isEnabledConfirm() ? false : true}
                  onClick={() => {
                    setIsOpenModalDecision(true);
                  }}
                >
                  {t("App.buttonLabels.confirm")}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>

      <ModalDecision
        title={t(
          "App.adminPurchaseOrderCommerce.modalSeeDetails.modalDecisionTitle",
          { supplier: order.provider }
        )}
        message={t(
          "App.adminPurchaseOrderCommerce.modalSeeDetails.modalDecisionText",
          { ocNumber: order.ocNumber }
        )}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModalDecision}
        isOpen={isOpenModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />

      <ModalInfo
        title={modalInfoTitle}
        responseData={""}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          setIsOpenModal(false);
        }}
      />
    </>
  );
};

export default ModalSeePurchaseOrderDetailCommerce;

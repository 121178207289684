import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { GetFiltersList } from "../../../services/admin/adminServices";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FilterBusinessRules } from "../../../services/user/buesinessRules";
import { GetTradersService } from "../../../services/user/deleteAndReload/deleteAndReload";
import { FilterTransmittedValue } from "../../../services/user/transmittedValueService";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";

const FilterAdminTransmittedValue = ({
  setData,
  setDataStructure,
  setIsLoadingData,
  setFilterApply,
  setNoFilter,
  setNoContent,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commerceAndEanList, setCommerceAndEanList] = useState([]);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [selectedCommerce, setSelectedCommerce] = useState([]);
  const [selectedEan, setSelectedEan] = useState([]);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  /**
   * Icons
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "============== Error filterAdminTransmittedValue.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
    setCountryList(countries);
  }, [dispatch, countriesStatus]);

  /**
   * Coloca los valores del state de loading dependiendo del status de geographies
   */
  useEffect(() => {
    if (countriesStatus === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [countriesStatus]);

  /**
   * Method to clear the fields when the reason of autocomplete country is equal to clear
   */
  const resetValuesWhenClear = () => {
    setValue("ean", []);
    setValue("commerce", []);

    setEanList([]);
    setCompanyList([]);
    setSelectedCommerce([]);
    setSelectedEan([]);
  };

  /**
   * t of useTranslation to translate
   */
  const { t } = useTranslation();

  /**
   * Schema for the filter of type of transmitted value
   */
  const schema = yup.object().shape({
    // country: yup.string(),
    // company: yup.string(),
    // ean: yup.string(),
  });

  /**
   * options for the autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * useForm to resolver of the schema validation
   */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   *
   * Función para cambiar el estado de selectedBusinessRules en base a lo seleccionado en el autocomplete de reglas
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const onCompanyChange = (e, values, reason, detail) => {
    let includeSelectAll = false;

    for (const company of values) {
      if (company.companyName === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedCommerce(companyList);
      setValue("commerce", companyList);

      setSelectedEan([]);
      setValue("ean", []);
    } else {
      setSelectedCommerce(values);
      setValue("commerce", values);
    }

    handleDeleteSelectAllCompany(values, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.companyName === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedCommerce([]);
        setValue("commerce", []);
      }
    }
  };

  /**
   *
   * Función para cambiar el estado de selectedBusinessRules en base a lo seleccionado en el autocomplete de reglas
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const onEanChange = (e, values, reason, detail) => {
    let includeSelectAll = false;

    for (const ean of values) {
      if (ean === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedEan(eanList);
      setValue("ean", eanList);
    } else {
      setSelectedEan(values);
      setValue("ean", values);
    }

    handleDeleteSelectAllEan(values, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedEan([]);
        setValue("ean", []);
      }
    }
  };

  /**
   *
   * Función que cambia los valores de compañías y sus ean de acuerdo al país seleccionado
   *
   * @param {Array} values
   */
  const handleChangeCountry = async (values) => {
    if (values) {
      setValue("ean", []);
      setValue("commerce", []);
      setSelectedCommerce([]);
      setSelectedEan([]);
      setCompanyList([]);
      setEanList([]);

      const country = values.country;

      try {
        handleCommercesAndEanList(country);
      } catch (error) {
        console.log(
          "============== Error filterAdminTransmittedValue.jsx function handleChangeCountry ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    } else {
      setValue("ean", []);
      setValue("commerce", []);
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (compañías) cuando alguna opción es removida
   *
   * @param {Array} values
   * @param {String} reason
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAllCompany = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option.companyName !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedCommerce(optionsWithoutSelectAll);
      setValue("commerce", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (ean) cuando alguna opción es removida
   *
   * @param {Array} values
   * @param {String} reason
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAllEan = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedEan(optionsWithoutSelectAll);
      setValue("ean", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para cambiar el valor del autocomplete de ean en base a la compañía seleccionada
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const handleChangeCompany = (e, values, reason, detail) => {
    if (values) {
      if (!getValues("ean")) {
        setValue("ean", []);
      }

      const company = values;

      //Aquí ocurre el comportamiento de seleccionar un comercio y se sleecciona el ean correspondiente
      let filtered = [];
      commerceAndEanList.map((option) => {
        company.map((item) => {
          //Seleccionar ean correspondiente en base al comercio seleccionado
          if (
            option.companyName === item.companyName &&
            reason === "selectOption"
          ) {
            filtered.push(option);
          }
          //Cuando se deja una sola opción, se muestra el ean correspondiente al comercio
          else if (
            company.length === 1 &&
            reason === "removeOption" &&
            option.companyName === item.companyName
          ) {
            filtered.push(option);
          }
        });
      });

      const eanList = filtered.map((item) => item.eanCompany);

      onCompanyChange(e, values, reason, detail);

      // Se coloca los valores correspondientes o un arreglo vacío de acuerdo a si se ha seleccionado
      // la opción 'Seleccionar todos'
      setValue(
        "ean",
        watch("commerce")[0]?.companyName !==
          t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : eanList
          : []
      );
      setSelectedEan(
        watch("commerce")[0]?.companyName !==
          t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : eanList
          : []
      );
    }
  };

  /**
   *
   * Función para cambiar el valor del autocomplete de company en base al ean seleccionado
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const handleChangeEan = (e, values, reason, detail) => {
    if (values) {
      const ean = values;

      //Aquí ocurre el comportamiento de seleccionar un ean y se sleecciona el comercio correspondiente
      let filtered = [];
      commerceAndEanList.map((option) => {
        ean.map((item) => {
          //Seleccionar comercio correspondiente en base al ean seleccionado
          if (option.eanCompany === item && reason === "selectOption") {
            filtered.push({
              companyName: option.companyName,
              eanCompany: option.eanCompany,
            });
          }
          //Cuando se deja una sola opción, se muestra el comercio correspondiente al ean
          else if (
            ean.length === 1 &&
            reason === "removeOption" &&
            option.eanCompany === item
          ) {
            filtered.push({
              companyName: option.companyName,
              eanCompany: option.eanCompany,
            });
          }
        });
      });

      const companyList = filtered.map((item) => {
        return { companyName: item.companyName, eanCompany: item.eanCompany };
      });

      onEanChange(e, values, reason, detail);

      // Se coloca los valores correspondientes o un arreglo vacío de acuerdo a si se ha seleccionado
      // la opción 'Seleccionar todos'
      setValue(
        "commerce",
        watch("ean")[0] !== t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : companyList
          : []
      );
      setSelectedCommerce(
        watch("ean")[0] !== t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : companyList
          : []
      );
    }
  };

  /**
   * Method to fetch the data for the autocompletes of commerce and eanCommerce
   *
   * @param {String} country
   */
  const handleCommercesAndEanList = async (country) => {
    const commercePerCountry = await GetTradersService({ country });

    const { status, data } = commercePerCountry;

    if (
      status === CODES.COD_RESPONSE_HTTP_OK &&
      data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
    ) {
      const eanList = commercePerCountry.data.responseMessage.map(
        (item) => item.eanCompany
      );

      const companyList = commercePerCountry.data.responseMessage.map(
        (item) => {
          return { companyName: item.companyName, eanCompany: item.eanCompany };
        }
      );

      const completeCompanyList = [
        { companyName: t("App.downloadSalesForce.selectAll") },
        ...companyList,
      ];

      const completeEanList = [
        t("App.downloadSalesForce.selectAll"),
        ...eanList,
      ];

      setCommerceAndEanList(data.responseMessage);
      setEanList(completeEanList);
      setCompanyList(completeCompanyList);
    }
  };

  /**
   * Method to clear the nulls value of the data form for the filter service
   *
   * @param {Object} data
   * @returns the object without nulls
   */
  const clearNulls = (data) => {
    let eanTraders, nameTraders;

    if (data.ean[0] === t("App.downloadSalesForce.selectAll")) {
      const eanTradersFiltered = data.ean.filter(
        (item) => item !== t("App.downloadSalesForce.selectAll")
      );

      eanTraders = eanTradersFiltered.map((item) => item);
    } else if (data.commerce[0] === t("App.downloadSalesForce.selectAll")) {
      const nameTradersFiltered = data.commerce.filter(
        (item) => item.companyName !== t("App.downloadSalesForce.selectAll")
      );

      nameTraders = nameTradersFiltered.map((item) => item.eanCompany);
    } else {
      eanTraders = data.ean.map((item) => item);
      nameTraders = data.commerce.map((item) => item.companyName);
    }

    return { eanTraders, nameTraders };
  };

  /**
   * Method to filter the data of the table by country, commerces and/or eanCommerces
   *
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      setData([]);
      setNoFilter(false);
      setNoContent(false);
      setIsLoadingData(true);

      const { eanTraders, nameTraders } = clearNulls(data);

      const obj = {
        country: data.country.country,
        lstEansTraders: eanTraders[0] !== undefined ? eanTraders : [],
        lstTraders: nameTraders[0] !== undefined ? nameTraders : [],
      };

      const filterService = await FilterTransmittedValue(obj);

      if (
        filterService.status !== CODES.COD_RESPONSE_HTTP_OK ||
        filterService.data.responseCode !== CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setIsLoadingData(false);
        setNoContent(true);
        setFilterApply({ country: "" });
        setData([]);
        return;
      }

      setDataStructure(filterService.data.responseMessage);

      setFilterApply({ country: data.country.country });
      setIsLoadingData(false);
    } catch (error) {
      console.log(
        "============== Error filterAdminTransmittedValue.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Method to clear the fields of the filter
   */
  const handleCleanFilters = () => {
    setValue("country", null);
    setValue("commerce", []);
    setValue("ean", []);

    setSelectedCommerce([]);
    setSelectedEan([]);
    // setData(unfilteredData);
    setFilterApply({ country: "" });
    setNoFilter(true);
  };

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect to update the state of isDesktop by the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <TuneOutlinedIcon
          sx={{
            fontSize: 20,
            color: "#543ab4",
          }}
        />

        <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
          {t("App.listUsers.filter")}
        </p>
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            md={8}
            sm={8}
            item
            className="side-line"
            style={{
              padding: "2%",
            }}
          >
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="row"
                justifyContent={isDesktop ? "flex-start" : "center"}
                alignItems="center"
                gap={1.5}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.country")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="country"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.country
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={countryList}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) =>
                              option.country === value.country
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.listUsers.countryN")}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleChangeCountry(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminSendAllies.table.commerce")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="commerce"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            filterOptions={filterOptions}
                            disabled={
                              watch("country")
                                ? selectedEan.length > 1
                                  ? true
                                  : false
                                : true
                            }
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.company
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={companyList}
                            multiple
                            disableCloseOnSelect
                            limitTags={2}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) => {
                              // console.log(option, value);
                              return option.eanCompany === value.eanCompany;
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.eanCompany + ""}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.companyName}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.adminSendAllies.table.commerce"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 2;

                              return (
                                <>
                                  {value.slice(0, limitTags).map((option) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "20%",
                                      }}
                                      key={option.eanCompany}
                                      size="small"
                                      label={option.companyName}
                                    />
                                  ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleChangeCompany(
                                event,
                                values,
                                reason,
                                detail
                              );
                            }}
                            value={selectedCommerce}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminSendAllies.table.eanCommerce")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="ean"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled={
                              watch("country")
                                ? selectedCommerce.length > 1
                                  ? true
                                  : false
                                : true
                            }
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.ean
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={eanList}
                            multiple
                            disableCloseOnSelect
                            limitTags={2}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.adminSendAllies.table.eanCommerce"
                                  )}
                                  {...params}
                                />
                              );
                            }}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 2;

                              return (
                                <>
                                  {value.slice(0, limitTags).map((option) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "20%",
                                      }}
                                      key={option}
                                      size="small"
                                      label={option}
                                    />
                                  ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleChangeEan(event, values, reason, detail);
                            }}
                            value={selectedEan}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              className="confirmation-buttons"
            >
              <Grid item>
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  disabled={watch("country") ? false : true}
                  className={
                    watch("country")
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                  type="submit"
                  form="hook-form"
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  disabled={watch("country") ? false : true}
                  className={
                    watch("country")
                      ? "btn__deleteFilter"
                      : "btn__deleteFilter__disabled"
                  }
                  onClick={handleCleanFilters}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterAdminTransmittedValue;

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";
export async function DownloadTableFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

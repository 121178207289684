import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
export const AutoCompleteDownload = (props) => {
  /** Use Translation */
  const { t } = useTranslation();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const onOptionChange = (event, values, reason, detail) => {
    let includeSelectAll = false;

    for (const trader of values) {
      if (trader.trader === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }
    if (includeSelectAll && reason === "selectOption") {
      props.setOptionSelected(props.optionsList);
    } else {
      props.setOptionSelected(values);
    }

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.trader === t("App.downloadSalesForce.selectAll")
      ) {
        props.setOptionSelected([]);
      }
    }
  };

  return (
    <Autocomplete
      noOptionsText={t("App.validationMessages.noMatchFilter")}
      className="autoComplete__downloadSalesForce"
      multiple
      isOptionEqualToValue={(option, value) => option.trader === value.trader}
      filterOptions={filterOptions}
      id="checkboxes-tags-demo"
      value={props.optionSelected}
      options={props.optionsList}
      disableCloseOnSelect
      limitTags={3}
      onChange={onOptionChange}
      getOptionLabel={(option) => option.trader}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            className="autoComplete__checkbox"
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.trader}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          placeholder={t("App.downloadSalesForce.autoCompletePlaceHolder")}
          {...params}
        />
      )}
    />
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FilterSpecialFilesClient } from "../../../services/user/specialFilesClient";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { GetFiltersList } from "../../../services/admin/adminServices";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FilterSpecialFilesCTyS } from "../../../services/admin/specialFiles";
import formatDistanceStrictWithOptions from "date-fns/esm/fp/formatDistanceStrictWithOptions/index";
import { getCommercesList } from "../../../helpers/helpers";
import {
  fetchAllGeographies,
  getGeographies,
  getStatusGeographies,
} from "../../../parts/geographies/geographiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetSuppliersService } from "../../../services/user/deleteAndReload/deleteAndReload";

export const FilterAdminSpecialFilesCTyS = ({
  setData,
  setDataStructure,
  setIsLoadingData,
  setFilterApply,
  setNoFilter,
  setNoContent,
}) => {
  /**
   * Use state
   */
  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);
  const [isCompanyList, setIsCompanyList] = useState(false);
  const [isEanList, setIsEanList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyAndEanList, setCompanyAndEanList] = useState([]);
  const [unfilteredList, setUnfilteredList] = useState([]);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedEan, setSelectedEan] = useState([]);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  const countries = useSelector(getGeographies);
  const countriesStatus = useSelector(getStatusGeographies);

  /**
   * Icons
   */
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const resetValuesWhenClear = () => {
    setValue("ean", []);
    setValue("company", []);

    const companyList = companyAndEanList.map((item) => {
      return { companyName: item.companyName, eanCompany: item.eanCompany };
    });

    const eanList = companyList.map((item) => item.eanCompany);

    // console.log(companyList);
    // console.log(eanList);

    const completeCompanyList = [
      { companyName: t("App.downloadSalesForce.selectAll") },
      ...companyList,
    ];

    const completeEanList = [t("App.downloadSalesForce.selectAll"), ...eanList];

    setCompanyList(completeCompanyList);
    setEanList(completeEanList);
  };

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        if (countriesStatus === "fetch") {
          dispatch(fetchAllGeographies());
        }
      } catch (error) {
        console.log(
          "============== Error filterAdminSpecialFilesCTyS.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
    setCountryList(countries);
  }, [dispatch, countriesStatus]);

  /**
   * Coloca los valores del state de loading dependiendo del status de geographies
   */
  useEffect(() => {
    if (countriesStatus === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [countriesStatus]);

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Form
   */
  const schema = yup.object().shape({
    // country: yup.string(),
    // company: yup.string(),
    // ean: yup.string(),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   *
   * Función para cambiar el estado de selectedBusinessRules en base a lo seleccionado en el autocomplete de reglas
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const onCompanyChange = (e, values, reason, detail) => {
    let includeSelectAll = false;

    for (const company of values) {
      if (company.companyName === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedCompany(companyList);
      setValue("company", companyList);

      setSelectedEan([]);
      setValue("ean", []);
    } else {
      setSelectedCompany(values);
      setValue("company", values);
    }

    handleDeleteSelectAllCompany(values, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option.companyName === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedCompany([]);
        setValue("company", []);
      }
    }
  };

  /**
   *
   * Función para cambiar el estado de selectedBusinessRules en base a lo seleccionado en el autocomplete de reglas
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const onEanChange = (e, values, reason, detail) => {
    let includeSelectAll = false;

    for (const ean of values) {
      if (ean === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    if (includeSelectAll && reason === "selectOption") {
      setSelectedEan(eanList);
      setValue("ean", eanList);
    } else {
      setSelectedEan(values);
      setValue("ean", values);
    }

    handleDeleteSelectAllEan(values, reason, includeSelectAll);

    if (detail) {
      const option = detail.option;
      if (
        reason === "removeOption" &&
        option === t("App.downloadSalesForce.selectAll")
      ) {
        setSelectedEan([]);
        setValue("ean", []);
      }
    }
  };

  /**
   *
   * Función que cambia los valores de compañías y sus ean de acuerdo al país seleccionado
   *
   * @param {Array} values
   */
  const handleChangeCountry = async (values) => {
    if (values) {
      setValue("ean", []);
      setValue("company", []);
      setSelectedCompany([]);
      setSelectedEan([]);
      setCompanyList([]);
      setEanList([]);

      const country = values.country;

      try {
        const commerceListService = await GetSuppliersService({ country });

        const { status, data } = commerceListService;

        const eanList = data.responseMessage.map((item) => item.eanCompany);

        const companyList = data.responseMessage.map((item) => {
          return { companyName: item.companyName, eanCompany: item.eanCompany };
        });

        const completeCompanyList = [
          { companyName: t("App.downloadSalesForce.selectAll") },
          ...companyList,
        ];

        const completeEanList = [
          t("App.downloadSalesForce.selectAll"),
          ...eanList,
        ];

        setCompanyAndEanList(data.responseMessage);
        setEanList(completeEanList);
        setCompanyList(completeCompanyList);
      } catch (error) {
        console.log(
          "============== Error filterAdminSpecialFilesCTyS.jsx function handleChangeCountry ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    } else {
      setValue("ean", []);
      setValue("company", []);
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (compañías) cuando alguna opción es removida
   *
   * @param {Array} values
   * @param {String} reason
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAllCompany = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option.companyName !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedCompany(optionsWithoutSelectAll);
      setValue("company", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todos' (ean) cuando alguna opción es removida
   *
   * @param {Array} values
   * @param {String} reason
   * @param {boolean} includeSelectAll
   */
  const handleDeleteSelectAllEan = (values, reason, includeSelectAll) => {
    if (reason === "removeOption" && includeSelectAll) {
      const optionsWithoutSelectAll = values.filter(
        (option) => option !== t("App.downloadSalesForce.selectAll")
      );

      setSelectedEan(optionsWithoutSelectAll);
      setValue("ean", optionsWithoutSelectAll);
    }
  };

  /**
   *
   * Función para cambiar el valor del autocomplete de ean en base a la compañía seleccionada
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const handleChangeCompany = (e, values, reason, detail) => {
    if (values) {
      if (!getValues("ean")) {
        setValue("ean", []);
      }

      const company = values;

      //Aquí ocurre el comportamiento de seleccionar un comercio y se sleecciona el ean correspondiente
      let filtered = [];
      companyAndEanList.map((option) => {
        company.map((item) => {
          //Seleccionar ean correspondiente en base al comercio seleccionado
          if (
            option.companyName === item.companyName &&
            reason === "selectOption"
          ) {
            filtered.push(option);
          }
          //Cuando se deja una sola opción, se muestra el ean correspondiente al comercio
          else if (
            company.length === 1 &&
            reason === "removeOption" &&
            option.companyName === item.companyName
          ) {
            filtered.push(option);
          }
        });
      });

      const eanList = filtered.map((item) => item.eanCompany);

      onCompanyChange(e, values, reason, detail);

      // Se coloca los valores correspondientes o un arreglo vacío de acuerdo a si se ha seleccionado
      // la opción 'Seleccionar todos'
      setValue(
        "ean",
        watch("company")[0]?.companyName !==
          t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : eanList
          : []
      );
      setSelectedEan(
        watch("company")[0]?.companyName !==
          t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : eanList
          : []
      );
    }
  };

  /**
   *
   * Función para cambiar el valor del autocomplete de company en base al ean seleccionado
   *
   * @param {Event} e
   * @param {Array} values
   * @param {String} reason
   * @param {Object} detail
   */
  const handleChangeEan = (e, values, reason, detail) => {
    if (values) {
      const ean = values;

      //Aquí ocurre el comportamiento de seleccionar un ean y se sleecciona el comercio correspondiente
      let filtered = [];
      companyAndEanList.map((option) => {
        ean.map((item) => {
          //Seleccionar comercio correspondiente en base al ean seleccionado
          if (option.eanCompany === item && reason === "selectOption") {
            filtered.push({
              companyName: option.companyName,
              eanCompany: option.eanCompany,
            });
          }
          //Cuando se deja una sola opción, se muestra el comercio correspondiente al ean
          else if (
            ean.length === 1 &&
            reason === "removeOption" &&
            option.eanCompany === item
          ) {
            filtered.push({
              companyName: option.companyName,
              eanCompany: option.eanCompany,
            });
          }
        });
      });

      const companyList = filtered.map((item) => {
        return { companyName: item.companyName, eanCompany: item.eanCompany };
      });

      onEanChange(e, values, reason, detail);

      // Se coloca los valores correspondientes o un arreglo vacío de acuerdo a si se ha seleccionado
      // la opción 'Seleccionar todos'
      setValue(
        "company",
        watch("ean")[0] !== t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : companyList
          : []
      );
      setSelectedCompany(
        watch("ean")[0] !== t("App.downloadSalesForce.selectAll")
          ? filtered.length > 1
            ? []
            : companyList
          : []
      );
    }
  };

  /**
   *
   * Triggers filter service
   *
   * @param {*} data filters
   */
  const handleFilters = async (data) => {
    try {
      setIsLoadingData(true);
      setData([]);

      //Evitamos la selección de select all
      const companyFilter = data.company.filter(
        (company) =>
          company.companyName !== t("App.downloadSalesForce.selectAll")
      );
      const companyNames = companyFilter.map((item) => item.eanCompany);

      const eanFilter = data.ean.filter(
        (ean) => ean !== t("App.downloadSalesForce.selectAll")
      );
      const eans = eanFilter.map((item) => item);

      const obj = {
        country: data.country.country,
        lstProviders: data.company ? companyNames : [],
        lstEanProviders: data.ean ? eans : [],
      };

      const filterFilesService = await FilterSpecialFilesCTyS(obj);

      setNoFilter(false);

      if (
        filterFilesService.status === CODES.COD_RESPONSE_HTTP_OK &&
        filterFilesService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        setNoContent(false);

        const proccesedRows = setDataStructure(
          filterFilesService.data.responseMessage
        );

        setData(proccesedRows);
        setFilterApply({ country: data.country.countryName });
      } else {
        setNoContent(true);
        setFilterApply({ country: "" });
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log(
        "============== Error filterAdminSpecialFilesCTyS.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  const handleCleanFilters = () => {
    setValue("country", null);
    setValue("company", []);
    setValue("ean", []);
    setSelectedEan([]);
    setSelectedCompany([]);
    // setData(unfilteredData);
    setFilterApply({ country: "" });
    setNoFilter(true);
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <TuneOutlinedIcon
          sx={{
            fontSize: 20,
            color: "#543ab4",
          }}
        />

        <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
          {t("App.listUsers.filter")}
        </p>
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            md={8}
            sm={8}
            item
            className="side-line"
            style={{
              padding: "2%",
            }}
          >
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              <Grid
                container
                direction="row"
                justifyContent={isDesktop ? "flex-start" : "center"}
                alignItems="center"
                gap={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.country")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="country"
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.country
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={countryList}
                            getOptionLabel={(option) => option.countryName}
                            isOptionEqualToValue={(option, value) =>
                              option.country === value.country
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.listUsers.countryN")}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                              handleChangeCountry(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.enterpriseN")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="company"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            filterOptions={filterOptions}
                            disabled={
                              watch("country")
                                ? selectedEan.length > 1
                                  ? true
                                  : false
                                : true
                            }
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.company
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={companyList}
                            multiple
                            disableCloseOnSelect
                            limitTags={2}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) => {
                              // console.log(option, value);
                              return option.eanCompany === value.eanCompany;
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.eanCompany + ""}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.companyName}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.listUsers.enterpriseN")}
                                  {...params}
                                />
                              );
                            }}
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 2;

                              return (
                                <>
                                  {value.slice(0, limitTags).map((option) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "20%",
                                      }}
                                      key={option.eanCompany}
                                      size="small"
                                      label={option.companyName}
                                    />
                                  ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleChangeCompany(
                                event,
                                values,
                                reason,
                                detail
                              );
                            }}
                            value={selectedCompany}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.ean")}
                      </label>
                    </Grid>

                    <Grid item>
                      <Controller
                        name="ean"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disabled={
                              watch("country")
                                ? selectedCompany.length > 1
                                  ? true
                                  : false
                                : true
                            }
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.ean
                                ? "select__filters__errors"
                                : "select__filters"
                            }
                            options={eanList}
                            multiple
                            disableCloseOnSelect
                            limitTags={2}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                resetValuesWhenClear();
                              }
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option}>
                                <Checkbox
                                  icon={icon}
                                  className="autoComplete__checkbox"
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t("App.listUsers.ean")}
                                  {...params}
                                />
                              );
                            }}
                            renderTags={(value) => {
                              const numTags = value.length;
                              const limitTags = 2;

                              return (
                                <>
                                  {value.slice(0, limitTags).map((option) => (
                                    <Chip
                                      className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                      sx={{
                                        width: "20%",
                                      }}
                                      key={option}
                                      size="small"
                                      label={option}
                                    />
                                  ))}

                                  {numTags > limitTags &&
                                    ` +${numTags - limitTags}`}
                                </>
                              );
                            }}
                            onChange={(event, values, reason, detail) => {
                              onChange(values);
                              handleChangeEan(event, values, reason, detail);
                            }}
                            value={selectedEan}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              className="confirmation-buttons"
            >
              <Grid item>
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  disabled={watch("country") ? false : true}
                  className={
                    watch("country")
                      ? "btn__applyFilter"
                      : "btn__applyFilter-disabled"
                  }
                  type="submit"
                  form="hook-form"
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  disabled={watch("country") ? false : true}
                  className={
                    watch("country")
                      ? "btn__deleteFilter"
                      : "btn__deleteFilter__disabled"
                  }
                  onClick={handleCleanFilters}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

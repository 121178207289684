import { Button, Chip, Grid, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterAdminTransmittedValue from "../../components/common/Filters/filterAdminTransmittedValue";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { NoSelectionComponent } from "../../components/common/noImages/noSelectionComponent";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import EditTransmittedValue from "../../components/common/TransmittedValue/editTransmittedValue";
import CreateTransmittedValue from "../../components/common/TransmittedValue/creatreTransmittedValue";
import { NoInfoComponent } from "../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../components/common/fileState";

const AdminTransmittedValue = () => {
  const [data, setData] = useState([]);
  const [filterApply, setFilterApply] = useState({ country: "" });
  const [noFilter, setNoFilter] = useState(true);
  const [noContent, setNoContent] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { t } = useTranslation();

  /**
   * Configuration of the table columns
   */
  const columns = [
    {
      name: "commerce",
      label: t("App.businessRules.table.commerce"),
      options: { filter: false, sort: false, searchable: false },
    },
    {
      name: "eanCommerce",
      label: t("App.businessRules.table.eanCommerce"),
      options: { filter: false },
    },
    {
      name: "informationType",
      label: t("App.adminTransmittedValue.informationType"),
      options: { filter: false, searchable: false },
    },
    {
      name: "transmittedValueType",
      label: t("App.homeDashboard.client.table.columns.column5"),
      options: { filter: false, searchable: false },
    },
    {
      name: "status",
      label: t("App.userDetail.status"),
      options: {
        filter: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: { filter: false, sort: false, searchable: false },
    },
  ];

  /**
   * Configurations of the MuiDataTable
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminTransmittedValue.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Sekeleton for the MuiDataTable
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminTransmittedValue.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Sets table data structure for the current data
   *
   * @param {Array} data
   *
   * @returns formated data
   */
  const setDataStructure = (data, justSet) => {
    if (data) {
      const processedRows = data.map((transmitted) => {
        return {
          commerce: transmitted.trader,
          eanCommerce: transmitted.eanTrader,
          informationType: transmitted.typeOfInformation,
          transmittedValueType: transmitted.typeOfValueTransmitted,
          status: <FileState value={transmitted?.state?.toLowerCase()} />,
          edit: (
            <EditTransmittedValue
              transmitted={transmitted}
              setData={setData}
              dataArray={data}
              setIsLoadingData={setIsLoadingData}
              setFilterApply={setFilterApply}
              setNoFilter={setNoFilter}
              setDataStructure={setDataStructure}
            />
          ),
        };
      });

      if (justSet) {
        return processedRows;
      } else {
        setData(processedRows);
      }
    }
  };

  return (
    <Grid>
      <Grid item container>
        <h1 className="display-large__primary-one">
          {t("App.adminTransmittedValue.title")}
        </h1>
      </Grid>

      <br />
      <br />

      <Grid item container justifyContent="flex-end">
        <CreateTransmittedValue
          setData={setData}
          dataArray={data}
          setIsLoadingData={setIsLoadingData}
          setDataStructure={setDataStructure}
          setNoContent={setNoFilter}
        />
      </Grid>

      <br />
      <br />

      <Grid item container className="companies_info_container">
        <Grid item className="full-width">
          <FilterAdminTransmittedValue
            setData={setData}
            setDataStructure={setDataStructure}
            setIsLoadingData={setIsLoadingData}
            setFilterApply={setFilterApply}
            setNoFilter={setNoFilter}
            setNoContent={setNoContent}
          />
        </Grid>

        <Grid item container style={{ marginTop: "15px" }}>
          {filterApply.country && (
            <Grid item>
              <Chip
                className="chip__filter"
                label={`${t("App.listUsers.countryN")}: ${filterApply.country}`}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>

        <Grid item style={{ marginTop: "25px" }} className="full-width">
          {!noFilter ? (
            !noContent ? (
              isLoadingData ? (
                <MUIDataTable
                  className="dataTable__salesForce datatable-style"
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                />
              ) : (
                <MUIDataTable
                  className="dataTable__salesForce datatable-style"
                  data={data}
                  columns={columns}
                  options={options}
                />
              )
            ) : (
              // <NoInfoComponent type="transmittedValue" />
              <NoMatchImage />
            )
          ) : (
            <NoSelectionComponent type="buesinessRules" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminTransmittedValue;

import { Grid, Button, IconButton, grid2Classes } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CardAccessReturnsIndicators } from "../../../components/common/AccessIndicators/cardAccesReturnsIndicators";
import { getUser } from "../../userSlice";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import FilterBusinessGroup from "../../../components/common/Filters/businessGroup/filterWithButtons";
import { GetIndicators } from "../../../services/user/salesIndicatorsService";

const AccessReturnsIndicators = () => {
  /**
   * Use Translation
   */
  const { t, i18n } = useTranslation();

  /**
   * Use Selector
   */
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  const [indicators, setIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const requestReturnsIndicators = async (data) => {
    try {
      const obj = {
        eanProvider: data.ean.eanCompany,
        indicatorType: "returns",
      };

      setIsLoading(true);

      const salesIndicator = await GetIndicators(obj);

      setIndicators(salesIndicator.data.responseMessage);

      setIsLoading(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item style={{ marginTop: "5px", marginBottom: "5px" }}>
          <h1 className="display-large__primary-one">
            {t("App.returnsIndicators.title")}
          </h1>
        </Grid>
      </Grid>

      <br />

      <FilterBusinessGroup
        title={t("App.manageBusinessGroup.filter.title2")}
        handleRequestIndicators={requestReturnsIndicators}
        setIsLoading={setIsLoading}
        typeOption={2}
      />

      <br />
      <br />

      {isLoading ? (
        <CustomSkeleton />
      ) : (
        <>
          <Grid container spacing={2} justifyContent="left">
            {indicators.map((data, id) => {
              return (
                <Grid item lg={4} xs={12} sm={10}>
                  <CardAccessReturnsIndicators
                    key={id}
                    icon={data.icon}
                    title={
                      i18n.language.includes("es")
                        ? data.name
                        : data.nameEnglish
                    }
                    text={
                      i18n.language.includes("es")
                        ? data.description
                        : data.descriptionEnglish
                    }
                    buttonText={t("App.salesIndicators.buttonText")}
                    link={data.url}
                    userInfo={userInfo}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export default AccessReturnsIndicators;

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";
import { convertFileToBase64 } from "../../utils/functions";
import { CODES } from "../../consts/codes";

/**
 * Utilizar el servicio para obtener el archivo de guia del maestro de tarifas
 * @returns Archivo de guia
 */
export async function GetRatesGuide() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `rates/downloads/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener la plantilla de tarifas
 * @returns Plantilla de tarifas
 */
export async function GetRatesTemplate() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `rates/downloads/templates`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener los archivos de tarifas cargados
 * @param {*} data // Datos de la compañía y modulo a consultar
 * @returns
 */
export async function GetRates(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener los archivos de tarifas descargados
 * @param {*} data // Datos de la compañía y modulo a consultar
 * @returns
 */
export async function ListDownloadRate(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads/companies`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener el archivo de tarifas cargadi
 * @param {*} data // Path y nombre del archivo a descargar
 * @returns
 */
export async function DownloadRateFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Utilizar el servicio para obtener el archivo log de error de la carga de archivo tarifas
 * @param {*} data // Path y nombre del archivo log a descargar
 * @returns
 */
export async function DownloadRateErrorLog(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads/logs`,
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite utilizar el servicio de subir el archivo de tarifa al sistema
 * @param data Archivo a subir
 */
export async function UploadRate(data) {
  const token = await getToken();
  const formData = new FormData();

  formData.append("file", data.file);

  const finalData = await convertFileToBase64(formData, CODES.RATES, data);

  return axios({
    method: "POST",
    url: "masters/uploads/rates",
    data: finalData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite utilizar el servicio de descarga de tarifas
 */

export async function DownloadRateOnDownload(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Función que permite utilizar el servicio de generación de
 * petición de descarga de tarifas
 */

export async function GenerateDownloadRateRequest(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    timeout: 1000 * 90,
    url: `rates/downloads`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";
import { convertFileToBase64 } from "../../utils/functions";
import { CODES } from "../../consts/codes";

//TODO: Ajustar el metodo UploadSalesForceService por integracion de grupo empresarial (yo lo ajuste creo q esta bien)
export async function UploadSalesForceService(data) {
  const token = await getToken();
  const formData = new FormData();

  formData.append("file", data.file);

  const finalData = await convertFileToBase64(formData,CODES.SALESFORCE, data)

  return axios({
    method: "POST",
    url: "masters/uploads/salesforce",
    data: finalData,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

//TODO: ajustar el objeto data con los nuevos atributos donde se haga el llamado a GetSalesForceList, el axios ya esta ajustado
export async function GetSalesForceList(data) 
{
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `masters/uploads/companies`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


/**
 * Función que permite obtener el listado de archivos cargados de una compañia
 * @param {data} Objeto que contiene los parametros a buscar 
 * @returns Listado de archivos cargados
 */
export async function ApplyFiltersSalesForce(data) 
{
  const token = await getToken();
  
  return axios({
    method: "POST",
    url: "masters/uploads",
    data: data,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}


export async function GetFileStatusList() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `/status`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetSalesForceTemplate() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `salesforce/downloads/templates`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetSalesForceGuide() {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `salesforce/downloads/guides`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadSalesForceFile(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadSalesForceErrorLog(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `masters/downloads/logs`,
    data: data,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadSalesForceService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: "salesforce",
    data: data,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetPreviewSalesForce(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `salesforce/previews`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetTradersList(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `salesforce/traders`,
    baseURL:  env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetSalesForcesDownloadedFiles(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data,
    url: `masters/downloads/companies`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DownloadSalesForcesDownloadedFiles(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `masters/downloads`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import React from "react";
import Grid from "@mui/material/Grid";
const Dashboard = () => {
  return (
    <div>
      <Grid style={{ paddingLeft: "10%" }}>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <br />
          <br />
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <iframe
              title="miIframe"
              src="https://prescriptiva.cen.biz/single/?appid=8a49dc01-ce52-4268-bb0c-42667389833c&sheet=d90f4d6c-faf2-432c-8384-6865ef9d3d56&theme=horizon&opt=ctxmenu,currsel&select=$::Idioma,Espa%C3%B1ol&select=$::Unidad%20de%20Analisis,Valor"
              style={{
                boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: "15px",
                border: "none",
                width: "100%",
                height: "120vh",
              }}
            ></iframe>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: "500px",
  },
});

export const TooltipAmountOfPeriods = ({ type }) => {
  const { t } = useTranslation();

  return (
    <CustomTooltip
      className="tooltip__custom"
      title={
        <>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="tooltip__container"
          >
            <Grid item>
              <Typography
                align="left"
                sx={{ fontSize: "14px", fontWeight: "700" }}
              >
                {type === "specialFiles"
                  ? t("App.specialFilesClient.tooltip.title")
                  : t("App.dynamicPeriodicReport.period.tooltip.title")}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                align="left"
                sx={{ fontSize: "12px", color: "black" }}
              >
                {t("App.dynamicPeriodicReport.period.tooltip.daily")}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ fontSize: "12px", color: "black" }}>
                {t("App.dynamicPeriodicReport.period.tooltip.weekly")}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ fontSize: "12px", color: "black" }}>
                {t("App.dynamicPeriodicReport.period.tooltip.monthly")}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ fontSize: "12px", color: "black" }}>
                {t("App.dynamicPeriodicReport.period.tooltip.yearly")}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                align="left"
                sx={{ fontSize: "14px", fontWeight: "700" }}
              >
                {type === "specialFiles" &&
                  t("App.specialFilesClient.tooltip.subTitle")}
              </Typography>
            </Grid>
          </Grid>

          {type !== "specialFiles" && (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="tooltip__container"
            >
              <Grid item>
                <Typography
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700" }}
                >
                  {t("App.dynamicPeriodicReport.period.tooltip.titleTwo")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  align="left"
                  sx={{ fontSize: "12px", color: "black" }}
                >
                  {t("App.dynamicPeriodicReport.period.tooltip.dailyTwo")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={{ fontSize: "12px", color: "black" }}>
                  {t("App.dynamicPeriodicReport.period.tooltip.weeklyTwo")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={{ fontSize: "12px", color: "black" }}>
                  {t("App.dynamicPeriodicReport.period.tooltip.monthlyTwo")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={{ fontSize: "12px", color: "black" }}>
                  {t("App.dynamicPeriodicReport.period.tooltip.yearlyTwo")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      }
    >
      <IconButton>
        <InfoOutlinedIcon sx={{ fontSize: "22px", color: "#543AB4" }} />
      </IconButton>
    </CustomTooltip>
  );
};

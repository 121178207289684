import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import noReprocess from "../../../assets/common/noReprocess.svg";

export const NoReprocess = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      className="col-flex full-width"
      rowSpacing={2}
    >
      <Grid item className="col-flex" sx={{ width: "70%" }}>
        <img
          src={noReprocess}
          alt="Not Match Image"
          className="noMatchImage_img"
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item className="display-large__primary-one">
            {t("App.adminReprocess.noReprocess.title")}
          </Grid>
          <Grid item className="noMatchImage_description full-width">
            <p>{t("App.adminReprocess.noReprocess.lineOne")}</p>
            <p>{t("App.adminReprocess.noReprocess.lineTwo")}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

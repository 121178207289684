import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";

/**
 * Trae la lista de reprocesos del día
 */
export async function GetReprocessList(data) {
  const token = await getToken();

  return axios({
    method: "GET",
    data: data,
    url: `reprocess`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Crea una solicitud de reproceso del producto deseado
 * @param {*} data información de reproceso
 * @returns respuesta del core
 */
export async function CreateReprocessRequest(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    data: data,
    url: `reprocess`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

/**
 * Crea una solicitud de reproceso del producto deseado
 * @param {*} data información de reproceso
 * @returns respuesta del core
 */
export async function MareiguaInvoiceRequest() {
  const token = await getToken();
  const data = {
    consultId: 1234,
    documentType: 13,
    documentId: "222222222222",
  };

  return axios({
    method: "POST",
    data: data,
    url: `invoice/invoiceInfoByReceiverId`,
    baseURL: "http://localhost:8002/prescriptiva-databroker-service/v1/",
    validateStatus: null,
    headers: {
      Authorization:
        "eyJraWQiOiJ0SnNaVlhxeVlQZGM0Vlk4c1p5VEFGcDBvbDdrb3V2ZUZVMHZmQXBkK0pzPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0YzlkZDA1MS04MGY0LTRlNmQtYTMyOS0yNjMxYzE5MTk4OGYiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9FckNZTFFzMGYiLCJjbGllbnRfaWQiOiI3M3Qwbm8zM2g5MHFvaTEwa2I4dTc3N21zNiIsIm9yaWdpbl9qdGkiOiJiZTM1YzcxNC1mNWU3LTQyYjMtOGY0My05ZjY0OWMzZjk0NzIiLCJldmVudF9pZCI6IjJmYjMwM2I5LTdiOTAtNDg2Mi04ZDFiLTMwODI1MWI5N2RlNSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MjU5MTMzODYsImV4cCI6MTcyNTkxNjk4NiwiaWF0IjoxNzI1OTEzMzg2LCJqdGkiOiIxODA1MWY3My1kYTc3LTRjMGMtYTQ5ZC01MjM4OTY1N2Y2NGYiLCJ1c2VybmFtZSI6IjRjOWRkMDUxLTgwZjQtNGU2ZC1hMzI5LTI2MzFjMTkxOTg4ZiJ9.xwBHjVPriiFtUhuNR7bSUsPJWNEL2LCPvhYeCi70H7LtABGbtCj4_qyp-9PkjyVXtMXlnvIrE-zXuB2btFhMnUgbmPUTgkWidR3Wpuenp-HQ5HUIO7kJ-_sT8BoSloy-rbgNCnLIaDqXgQ7vPtxzZuNrTzgHY1YsoyoOup_9XfJorwnlCeV-lC1_zCo66LyjPjlYaZZ8bFWW7ss-PCHUeIIkzdowr9mowECPSg-bNrHsDQkdHURJm1xX9I4SDOkkXzQaEu9k1RkrBVVWhDARnq0E9_hJWlpJnJfCT_84nfuMcnJq1npY0ue_-rX1LVT6NoSbI72AL8xElvmNUmjmvQ",
    },
  });
}

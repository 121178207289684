import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { FilterCreateReprocess } from "../../../components/common/AdminReprocess/FilterCreateReprocess";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { NoReprocess } from "../../../components/common/NoReprocess/NoReprocess";
import LoopIcon from "@mui/icons-material/Loop";
import FileState from "../../../components/common/fileState";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  GetReprocessList,
  MareiguaInvoiceRequest,
} from "../../../services/user/reprocess/reprocessService";
import { CODES } from "../../../consts/codes";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const AdminReprocess = () => {
  /**
   * Mock
   */

  /**
   * Use Translation
   */

  const { t, i18n } = useTranslation();

  /**
   * Datatable info
   */

  const CustomSearchRender = (props) => {
    const { onSearch, searchText } = props;
    const handleTextChange = (event) => {
      onSearch(event.target.value);
    };

    return (
      <Grid container className="col-end" columnSpacing={1}>
        <Grid item xl={4.5} lg={6} sm={9} xs={10}>
          <TextField
            placeholder={t("App.adminReprocess.table.search")}
            value={searchText || ""}
            fullWidth
            onChange={handleTextChange}
            className="select-contact__filters__grey"
            InputProps={{
              autoComplete: "none",
              sx: {
                height: "39px",
                width: "100% !important",
              },
              startAdornment: (
                <React.Fragment>
                  <SearchIcon sx={{ color: "#9CA3AF" }} fontSize="large" />
                </React.Fragment>
              ),
            }}
          />
        </Grid>
        <Grid item className="col-flex">
          <Tooltip title={t("App.adminReprocess.table.searchTooltip")}>
            <InfoOutlinedIcon sx={{ fontSize: "23px", color: "#543AB4" }} />
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.adminReprocess.table.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    customSearchRender: (searchText, handleSearch) => {
      return (
        <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
      );
    },
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => "",
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const columns = [
    {
      name: "companyName",
      label: t("App.adminClientUsers.table.enterprise"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customHeadLabelRender: () => (
          <Grid container style={{ minWidth: "130px" }} className="col-flex">
            {t("App.adminClientUsers.table.enterprise")}
          </Grid>
        ),
      },
    },
    {
      name: "eanCompany",
      label: t("App.manageBusinessGroup.table.eanMainCompany"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container style={{ minWidth: "100px" }} className="col-flex">
            {t("App.manageBusinessGroup.table.eanMainCompany")}
          </Grid>
        ),
      },
    },
    {
      name: "productType",
      label: t("App.listUsers.product"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "frequency",
      label: t("App.adminReprocess.table.frequency"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "day",
      label: t("App.adminReprocess.table.day"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid
            container
            className="full-width col-flex"
            columnSpacing={1}
            sx={{ minWidth: "70px" }}
          >
            <Grid item>{t("App.adminReprocess.table.day")}</Grid>
            <Grid item className="col-flex">
              <Tooltip title={t("App.adminReprocess.table.tooltipDay")}>
                <InfoOutlinedIcon sx={{ fontSize: "23px", color: "#543AB4" }} />
              </Tooltip>
            </Grid>
          </Grid>
        ),
      },
    },
    {
      //TODO MANEJAR DESCARGA
      name: "fileName",
      label: t("App.adminReprocess.table.fileName"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container minWidth={190} className="col-flex">
            {t("App.adminReprocess.table.fileName")}
          </Grid>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Grid container minWidth={190}>
            {value}
          </Grid>
        ),
      },
    },
    {
      //TODO MANEJAR DESCARGA
      name: "logFileName",
      label: t("App.adminReprocess.table.logFileName"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container minWidth={190} className="col-flex">
            {t("App.adminReprocess.table.logFileName")}
          </Grid>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Grid container minWidth={190}>
            {value}
          </Grid>
        ),
      },
    },
    {
      name: "reprocessDate",
      label: t("App.adminReprocess.table.reprocessDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid
            container
            direction="row"
            minWidth={150}
            columnSpacing={1}
            className="col-flex"
          >
            <Grid item>
              {" "}
              <div style={{ minWidth: "80px" }}>
                <h4>{t("App.adminReprocess.table.reprocessDate")}</h4>

                <h5 className="subheading-datatable">
                  {t("App.homeDashboard.client.table.columns.formatDate")}
                </h5>
              </div>
            </Grid>
            <Grid item className="col-flex">
              <Tooltip title={t("App.adminReprocess.table.tooltipReprocess")}>
                <InfoOutlinedIcon sx={{ fontSize: "23px", color: "#543AB4" }} />
              </Tooltip>
            </Grid>
          </Grid>
        ),
        customBodyRender: (value) => (
          <Grid container className="col-start" paddingLeft={4.5}>
            {value}
          </Grid>
        ),
      },
    },
    {
      name: "lastUpdate",
      label: t("App.adminReprocess.table.lastUpdate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container minWidth={160} columnSpacing={1}>
            <Grid item>
              {" "}
              <div style={{ minWidth: "100px" }}>
                <h4>{t("App.adminReprocess.table.lastUpdate")}</h4>

                <h5 className="subheading-datatable">
                  {t("App.homeDashboard.client.table.columns.formatDate") +
                    " HH:MM"}
                </h5>
              </div>
            </Grid>
            <Grid item className="col-flex">
              <Tooltip title={t("App.adminReprocess.table.tooltipLastUpdate")}>
                <InfoOutlinedIcon sx={{ fontSize: "23px", color: "#543AB4" }} />
              </Tooltip>
            </Grid>
          </Grid>
        ),
        customBodyRender: (value) => (
          <Grid container className="col-start" paddingLeft={4.5}>
            {value}
          </Grid>
        ),
      },
    },
    {
      name: "state",
      label: t("App.userDetail.status"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "pk",
      label: t("App.adminReprocess.table.pk"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container style={{ minWidth: "120px" }} className="col-flex">
            {t("App.adminReprocess.table.pk")}
          </Grid>
        ),
      },
    },
    {
      name: "sk",
      label: t("App.adminReprocess.table.sk"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container style={{ minWidth: "150px" }} className="col-flex">
            {t("App.adminReprocess.table.sk")}
          </Grid>
        ),
      },
    },
    {
      name: "pathFile",
      label: t("App.adminReprocess.table.fileRoute"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container minWidth={190} className="col-flex">
            {t("App.adminReprocess.table.fileRoute")}
          </Grid>
        ),
      },
    },
    {
      name: "pathLog",
      label: t("App.adminReprocess.table.logFileRoute"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => (
          <Grid container minWidth={190} className="col-flex">
            {t("App.adminReprocess.table.logFileRoute")}
          </Grid>
        ),
      },
    },
  ];

  /**
   * Use State
   */

  const [data, setData] = useState([]);

  const [isReprocessInfo, setIsReprocessInfo] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Use Effect
   */
  //Genera la primera carga de reprocesos
  useEffect(() => {
    const fetchData = async () => {
      await handleRequestReprocessList();
    };
    fetchData();
  }, []);

  /**
   * Handles
   */

  /**
   * Trae la lista de  reprocesos del día por medio de petición
   */
  const handleRequestReprocessList = async () => {
    try {
      setIsLoading(true);
      const {
        status,
        data: { responseMessage, responseCode },
      } = await GetReprocessList();

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const formattedReprocessList = handleDataStructure(responseMessage);
        setData(formattedReprocessList);
        setIsReprocessInfo(true);
      } else {
        setIsReprocessInfo(false);
      }
    } catch (error) {
      console.log(
        "================= ERROR at adminReprocess.jsx ================="
      );
      console.log(error);
      console.log("===============================================");
      setIsReprocessInfo(false);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Settea la estructura correspondiente al formato de la tabla
   * @param {*} data información de reprocesos
   */
  const handleDataStructure = (data) => {
    let formattedList = data.map((item) => ({
      companyName: item.companyName,
      eanCompany: item.eanCompany,
      product: item.product,
      frequency: item.frequency,
      day: item.generationDay,
      fileName: item.fileName,
      logFileName: item.logFileName,
      reprocessDate: item.reprocessDate,
      lastUpdate: item.lastUpdate,
      state: item.state,
      pk: item.pk,
      sk: item.sk,
      pathFile: item.filePath,
      pathLog: item.logPath,
    }));

    return formattedList;
  };

  /**
   * Función refresca la tabla de Reprocesos
   */
  const handleRefresh = async () => {
    await handleRequestReprocessList();
  };

  const handleMareigua = async () => {
    const {
      status,
      data: { responseCode, message },
    } = await MareiguaInvoiceRequest();

    if (status === CODES.COD_RESPONSE_HTTP_OK) {
      console.log(message);
    }
  };

  return (
    <Grid container direction="column" rowSpacing={6}>
      {/* <Grid item>
        <Button onClick={handleMareigua}>Mareigua</Button>
      </Grid> */}
      <Grid item className="display-large__primary-one">
        {t("App.adminReprocess.title")}
      </Grid>
      <Grid item className="full-width" sx={{ marginBottom: "4%" }}>
        <Grid
          container
          sx={{ backgroundColor: "white", padding: "1%", borderRadius: "12px" }}
          rowSpacing={2}
          direction="column"
        >
          <Grid item>
            <Grid container columnSpacing={2} className="col-start">
              <Grid item>
                <LoopIcon
                  fontSize="large"
                  className="accordion__upload__downloadIcon"
                />
              </Grid>
              <Grid item className="display-small__primary-one">
                {t("App.adminReprocess.titleTwo")}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="full-width">
            <FilterCreateReprocess
              t={t}
              i18n={i18n}
              handleRequestReprocessList={handleRequestReprocessList}
            />
          </Grid>
          <Grid item className="col-end">
            {isReprocessInfo && (
              <Button
                className="btn__outlined__blue"
                startIcon={<AutorenewIcon />}
                onClick={handleRefresh}
              >
                {t("App.catalogue.update")}
              </Button>
            )}
          </Grid>
          <Grid item className="full-width">
            {isLoading ? (
              <CustomSkeleton />
            ) : isReprocessInfo ? (
              <MUIDataTable
                className="dataTable__salesForce datatable-style"
                data={data}
                columns={columns}
                options={options}
              />
            ) : (
              <NoReprocess />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminReprocess;

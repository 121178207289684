import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CODES } from "../../../consts/codes";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import moment from "moment";
import {
  GetListTraceabilities,
  GetListTrader,
} from "../../../services/user/tracebility/traceabilityService";

const FilterTraceExternal = ({
  userInfo,
  setHasFound,
  setHasContent,
  setHasFilter,
  handleOpenModal,
  setData,
  setOpenModalLogError,
  setIsLoading,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  /**
   * Use Translation
   */
  const { t } = useTranslation();
  /**-
   * yup
   */
  const schema = yup.object().shape({});

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  /**
   * Use requestState
   */
  const [partners, setParners] = useState([]);
  const [typeDocs, setTypeDocs] = useState([
    {
      label: t("App.downloadSalesForce.selectAll"),
      value: t("App.downloadSalesForce.selectAll"),
    },
    {
      label: t("App.traceabilityExternal.typeDoc.sales"),
      value: "sales",
    },
    {
      label: t("App.traceabilityExternal.typeDoc.inventories"),
      value: "stock",
    },
    {
      label: t("App.traceabilityExternal.typeDoc.salesStock"),
      value: "sales_stock",
    },
  ]);
  const [getStates, setStates] = useState([
    {
      label: t("App.traceabilityExternal.states.pending"),
      value: "PENDIENTE",
    },
    {
      label: t("App.traceabilityExternal.states.process"),
      value: "EN PROCESO",
    },
    {
      label: t("App.traceabilityExternal.states.totalLoad"),
      value: "CARGA TOTAL",
    },
    {
      label: t("App.traceabilityExternal.states.partialLoad"),
      value: "CARGA PARCIAL",
    },
    {
      label: t("App.traceabilityExternal.states.reject"),
      value: "RECHAZADO POR ESTRUCTURA",
    },
    {
      label: t("App.traceabilityExternal.states.error"),
      value: "ERROR SISTEMA",
    },
  ]);
  const [error, setError] = useState(false);
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const [isLoadingEan, setIsLoadingEan] = useState(false);
  const [filterResponse, setFilterResponse] = useState("");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [ocNumbers, setOcNumbers] = useState([]);

  /**
   * Función para limpiar los campos del formulario
   */
  const handleCleanFilters = () => {
    reset();
    setHasFilter(false);
    setHasFound(false);
    setHasContent(false);
  };

  /**
   *  Función para filtrar los datos
   * @param {*} data  datos del formulario
   */
  const handleFilters = async (data) => {
    try {
      setHasFilter(true);
      setHasFound(true);
      setIsLoading(true);
      const obj = {
        eanProvider: userInfo.company.eanCompany,
        lstEansTraders:
          data.partner.length === partners.length
            ? []
            : data.partner
                .filter(
                  (item) =>
                    item.companyName !== t("App.downloadSalesForce.selectAll")
                )
                .map((item) => item.eanCompany),
        lstDocumentTypes: data.typeDoc
          ? data.typeDoc.length === typeDocs.length
            ? []
            : data.typeDoc
                .map((item) => item.value)
                .filter(
                  (item) => item !== t("App.downloadSalesForce.selectAll")
                )
          : null,
        startDate: data.startDate ? formatDate(data.startDate) : null,
        endDate: data.endDate ? formatDate(data.endDate) : null,
        state: data.state?.value ? data.state.value : null,
        country: userInfo.company.country,
      };
      const {
        data: { responseCode, responseMessage },
        status,
      } = await GetListTraceabilities(obj);
      if (status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
          setData(setDataStructure(responseMessage));
        } else {
          setHasFound(false);
        }
      } else {
        setHasFound(false);
        setOpenModalLogError(true);
      }
    } catch (error) {
      setHasFound(true);
      setOpenModalLogError(true);
      console.log("----------------- Error -----------------");
      console.log(error);
      console.log("------------------------------------");
    }

    setIsLoading(false);
  };

  /**
   * Formatear la fecha
   */
  const formatDate = (date) => {
    let auxDate = new Date(date);
    return moment(auxDate).format("YYYY-MM-DD");
  };

  /**
   * Se ejecuta cuando se cambia el tipo de documento
   * @param {*} event
   * @param {*} values valores seleccionados
   * @param {*} reason tipo de acción
   * @param {*} detail
   */
  const onTypeDocChange = (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const commerce of values) {
      if (commerce.label === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeCommerce = typeDocs.filter(
      (commerce) => commerce.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values.filter(
      (commerce) => commerce.label !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCommerce && reason === "selectOption")
    ) {
      setValue("typeDoc", typeDocs);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === typeDocs.length - 1) {
      setValue(
        "typeDoc",
        values.filter(
          (commerce) => commerce.label !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("typeDoc", values);
    }

    if (detail) {
      const option = detail.option;
      // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
      if (
        reason === "removeOption" &&
        option.label === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("typeDoc", []);
      }
    }
  };

  /**
   *
   * Función que se ejecuta cuando hay un cambio en el campo de socio comercial para accionar las funciones:
   * @param {*} event
   * @param {*} values partners seleccionados
   * @param {*} reason tipo de acción
   * @param {*} detail   opción seleccionada
   *  */
  const onPartnerChange = async (event, values, reason, detail) => {
    let includeSelectAll = false;
    for (const commerce of values) {
      if (commerce.companyName === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
        break;
      }
    }

    let sizeCommerce = partners?.filter(
      (commerce) =>
        commerce.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;
    let sizeValues = values?.filter(
      (commerce) =>
        commerce.companyName !== t("App.downloadSalesForce.selectAll")
    ).length;
    // Si se escoge 'Seleccionar todo' o se colocan todos los valores en el autocomplete
    if (
      (includeSelectAll && reason === "selectOption") ||
      (sizeValues === sizeCommerce && reason === "selectOption")
    ) {
      setValue("partner", partners);
      // Si deselecciona un valor se deselecciona 'Seleccionar todo'
    } else if (values.length === partners.length - 1) {
      setValue(
        "partner",
        values.filter(
          (commerce) =>
            commerce.companyName !== t("App.downloadSalesForce.selectAll")
        )
      );
    } else {
      setValue("partner", values);
    }

    const option = detail?.option;
    // Si se deselecciona 'Seleccionar todo' se reinicia el valor del autocomplete
    if (
      (reason === "removeOption" &&
        option?.companyName === t("App.downloadSalesForce.selectAll")) ||
      (reason === "clear" && values.length === 0)
    ) {
      reset();
    }
  };

  /**
   * Obtener los socios comerciales
   */
  useEffect(() => {
    async function getSuppliers() {
      try {
        if (!userInfo) return;
        //Diferencia entre un usuario de samsung para cambiar los estados
        if (userInfo.company.eanCompany === "7707222704831") {
          setStates([
            {
              label: t("App.traceabilityExternal.states.totalLoad"),
              value: "CARGA TOTAL",
            },
            {
              label: t("App.traceabilityExternal.states.reject"),
              value: "RECHAZADO POR ESTRUCTURA",
            },
          ]);
        } else {
          setStates((states) =>
            states.sort((a, b) => a.label.localeCompare(b.label))
          );
        }
        //Ordena los tipos de documentos alfabeticamente, dejando siempre el selectAll de primero
        setTypeDocs((typeDocs) => {
          const selectAllDoc = typeDocs.find(
            (doc) => doc.value === t("App.downloadSalesForce.selectAll")
          );
          const restDocs = typeDocs.filter(
            (doc) => doc.value !== t("App.downloadSalesForce.selectAll")
          );
          restDocs.sort((a, b) => a.label.localeCompare(b.label));
          return [selectAllDoc, ...restDocs];
        });
        setIsLoadingSupplier(true);
        setIsLoadingEan(true);

        const suppliersService = await GetListTrader();

        const responseCode = suppliersService.data.responseCode;

        if (
          suppliersService.status === CODES.COD_RESPONSE_HTTP_OK &&
          responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              companyName: item.companyName,
              eanCompany: item.eanCompany,
            };
          });

          const suppliersCompleteList =
            suppliersList.length > 0
              ? [
                  { companyName: t("App.downloadSalesForce.selectAll") },
                  ...suppliersList,
                ]
              : [];

          setParners(suppliersCompleteList);
        }

        setIsLoadingSupplier(false);
        setIsLoadingEan(false);
      } catch (error) {
        console.log(
          "================= Error filterPurchaseOrderCommerce.jsx getSuppliers ==================="
        );
        console.log(error);
        console.log("====================================");
      }
    }

    getSuppliers();
  }, [userInfo]);

  /**
   *
   * Función para estructurar la data para la tabla
   *
   * @param {Array} data
   * @returns data formateada
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        traceabilityId: !item.traceabilityId ? " - " : item.traceabilityId,
        uploadDate: !item.uploadDate ? " - " : item.uploadDate,
        trader: !item.trader ? " - " : item.trader,
        eanTrader: !item.eanTrader ? " - " : item.eanTrader,
        provider: !item.provider ? " - " : item.provider,
        eanProvider: !item.eanProvider ? " - " : item.eanProvider,
        documentType: !item.documentType
          ? " - "
          : item.documentType === "sales"
          ? t("App.traceabilityExternal.typeDoc.sales")
          : item.documentType === "stock"
          ? t("App.traceabilityExternal.typeDoc.inventories")
          : t("App.traceabilityExternal.typeDoc.salesStock"),
        fileName: !item.fileName
          ? ""
          : { fileName: item.fileName, pathFileName: item.pathFileName },
        state: !item.state ? " - " : item.state,
        logError:
          item.state.toUpperCase() === "ERROR SISTEMA"
            ? "Error"
            : !item.logFileName
            ? {
                logFileName: "",
                pathLogFileName: "",
              }
            : {
                logFileName: item.logFileName,
                pathLogFileName: item.pathLogFileName,
              },

        seeDetail:
          item.state !== "Error Sistema" ? (
            <IconButton
              onClick={() => {
                handleOpenModal(item);
              }}
              component="span"
            >
              <VisibilityOutlinedIcon sx={{ color: "#553AB6", fontSize: 22 }} />
            </IconButton>
          ) : (
            ""
          ),
      };
    });
    return processRows;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        xl={9}
        lg={9}
        md={9}
        sm={8}
        xs={12}
        item
        className="side-line-contact__bottomAlign"
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
          >
            <Grid
              container
              direction="row"
              rowSpacing={2}
              width="100%"
              paddingBottom={2}
              spacing={2}
            >
              <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                <Controller
                  name="partner"
                  control={control}
                  width={"100%"}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={
                        errors.supplier
                          ? "select-contact__filters__error"
                          : "select-contact__filters"
                      }
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option?.eanCompany === value?.eanCompany
                      }
                      id="checkboxes-tags-demo"
                      options={partners}
                      value={value || []}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={onPartnerChange}
                      getOptionLabel={(option) => option?.companyName}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.companyName}
                        </li>
                      )}
                      renderTags={(value) => {
                        const numTags = value.length;
                        const limitTags = isDesktop ? 2 : 1;

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: "30%",
                                }}
                                key={index}
                                size="small"
                                label={option?.companyName}
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.traceabilityExternal.partner") + "*"}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingSupplier ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={5.85} md={6} sm={12} xs={12} width={"100%"}>
                <Controller
                  name="typeDoc"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={
                        errors.ean
                          ? "select-contact__filters__error"
                          : "select-contact__filters"
                      }
                      multiple
                      disabled={!watch("partner")?.length > 0}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      id="checkboxes-tags-demo"
                      options={typeDocs}
                      value={value || []}
                      disableCloseOnSelect
                      limitTags={3}
                      onChange={onTypeDocChange}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderTags={(value) => {
                        const numTags = value.length;
                        const limitTags = isDesktop ? 2 : 1;

                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                sx={{
                                  width: "30%",
                                }}
                                key={index}
                                size="small"
                                label={option.label}
                              />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.traceabilityExternal.docType")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingSupplier ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              rowSpacing={2}
              spacing={2}
              width="100%"
            >
              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={
                          errors.ocDate
                            ? "select-contact__filters__error"
                            : "select-contact__filters"
                        }
                        disabled={!watch("partner")?.length > 0}
                        inputFormat="YYYY-MM-DD"
                        label={t("App.traceabilityExternal.startDate")}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={watch("startDate") ? watch("startDate") : null}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  defaultValue=""
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled={!watch("partner")?.length > 0}
                        className={
                          errors.requestDate
                            ? "select-contact__filters__error"
                            : "select-contact__filters"
                        }
                        inputFormat="YYYY-MM-DD"
                        label={t("App.traceabilityExternal.endDate")}
                        sx={{
                          translate: "0px -5px",
                        }}
                        value={watch("endDate") ? watch("endDate") : null}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -4px",
                                transformOrigin: "-45px -10px",
                                color: "#8C8D9D !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item lg={3.9} sm={12} md={4} xs={12}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={!watch("partner")?.length > 0}
                      noOptionsText={t("App.validationMessages.noMatchFilter")}
                      className={
                        errors.ean
                          ? "select-contact__filters__error"
                          : "select-contact__filters"
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      id="checkboxes-tags-demo"
                      options={getStates}
                      value={value || null}
                      // disableCloseOnSelect
                      onChange={(event, values, reason, detail) => {
                        setValue("state", values);
                      }}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          label={t("App.traceabilityExternal.state")}
                          sx={{
                            label: {
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "0px -5px",
                              transformOrigin: "-45px -10px",
                              "&.Mui-focused": {
                                color: "#8C8D9D",
                              },
                            },
                          }}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingEan ? (
                                  <CircularProgress
                                    sx={{ color: "#4DCAFA" }}
                                    size={15}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              disabled={watch("partner")?.length > 0 ? false : true}
              className={
                watch("partner")?.length > 0
                  ? "btn__applyFilter-reload"
                  : "btn__applyFilter-reload-disabled"
              }
              type="submit"
              form="hook-form"
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item xl={5} lg={6} md={12} sm={12} xs={12} width="100%">
            <Button
              onClick={handleCleanFilters}
              disableRipple
              className="btn__deleteFilter-reload"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterTraceExternal;

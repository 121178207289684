import { React } from "react";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { DownloadStores } from "./downloadStores";
import StoresUpload from "./storesUpload";

export const Stores = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <p className="display-large__primary-one">{t("App.stores.title")}</p>
      </Grid>

      <Grid item className="grid__container__fullW">
        <Accordion className="accordion__upload">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid container direction="row">
              <Grid item>
                <FileDownloadOutlinedIcon className="accordion__upload__downloadIcon" />
              </Grid>
              <Grid item className="col-flex">
                <p
                  className="display-small__primary-one"
                  style={{ paddingLeft: "1rem" }}
                >
                  {t("App.stores.download")}
                </p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordion__upload__detail">
            <DownloadStores />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item className="grid__container__fullW u-marginB">
        <Accordion className="accordion__upload">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Grid container direction="row">
              <Grid item>
                <FileUploadOutlinedIcon className="accordion__upload__uploadIcon" />
              </Grid>
              <Grid item className="col-flex">
                <p
                  className="display-small__primary-one"
                  style={{ paddingLeft: "1rem" }}
                >
                  {t("App.stores.upload")}
                </p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordion__upload__detail">
            <StoresUpload />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

import { Grid, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalChangeMaster } from "../../../../components/common/Modals/modalChangeMaster";

export const MasterSelectorTraders = ({ currentMaster, setCurrentMaster }) => {
  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use State
   */
  const [openModalChangeType, setOpenModalChangeType] = useState(null);
  const [desiredType, setDesiredType] = useState(null);

  /**
   * Handles
   */

  const handleTypeChange = (event) => {
    const id = event.target.value;
    if (currentMaster !== "2") {
      setDesiredType(id);
      setOpenModalChangeType(true);
    } else {
      setCurrentMaster(id);
    }
  };

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      marginLeft={"0.09%"}
      columnSpacing={3}
      rowGap={2}
      className="grid__container__filled__rounded"
    >
      <Grid
        item
        lg={"auto"}
        md={"auto"}
        sm={7}
        className="subheading__text-black"
      >
        {t("App.masters.choose")}
      </Grid>
      <Grid item lg={3} md={3} sm={4} xs={11} className="full-width">
        <Select
          className="select__border full-width"
          onChange={handleTypeChange}
          value={currentMaster}
          id="type"
          name="type"
          labelId="type"
        >
          <MenuItem disabled value="2">
            <em> {t("App.masters.placeholder")}</em>
          </MenuItem>

          <MenuItem value="0">
            <em> {t("App.traders.masters.objectives")}</em>
          </MenuItem>
          <MenuItem value="1">
            <em> {t("App.traders.masters.penalties")}</em>
          </MenuItem>
        </Select>
      </Grid>
      <ModalChangeMaster
        open={openModalChangeType || false}
        setOpen={setOpenModalChangeType}
        desiredType={desiredType}
        setCurrentMaster={setCurrentMaster}
      />
    </Grid>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import { AlliesFilterForm } from "../Allies/alliesFilterForm";

export const FilterReloadAllies = ({
  handleSubmit,
  control,
  errors,
  setValue,
  reset,
  watch,
  setOpenModalDecision,
  setAlliesReloadForm,
  allySelected,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item className="full-width">
        <Accordion expanded={true}>
          <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ cursor: "auto !important" }}
          >
            <TuneOutlinedIcon
              sx={{
                fontSize: 20,
                color: "#543ab4",
              }}
            />

            <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
              {" "}
              {t("App.allies.filter.title")}
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <AlliesFilterForm
              handleSubmit={handleSubmit}
              control={control}
              errors={errors}
              setValue={setValue}
              reset={reset}
              watch={watch}
              setOpenModalDecision={setOpenModalDecision}
              setAlliesReloadForm={setAlliesReloadForm}
              allySelected={allySelected}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";

export async function GetTraders(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/traders`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetEanPointSales(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/eans/pointsales`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetPointSales(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/pointsales`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetFormats(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/formats`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetDepartments(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/levels1/geographies`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetCities(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/levels4/geographies`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetChannels(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/channels`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetCategories(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `/filters/dynamicreports/level1/categories`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetProducts(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/products`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetSalesForceLevelOne(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/levels1/salesforce`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetBrands(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/brands`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetColors(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/colors`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetSizes(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/sizes`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}
export async function GetSalesMan(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `filters/dynamicreports/salesman`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GenerateDynamicReport(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `dynamicreports`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetDynamicReportByEan(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `dynamicreports/pivote`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditDynamicReport(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    data: data,
    url: `dynamicreports`,
    baseURL:  env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import { Button, FormControlLabel, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoopIcon from "@mui/icons-material/Loop";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import DownloadBox from "../../../components/common/Boxes/downloadBox";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import {
  DownloadCatalogueOnDownload,
  GenerateDownloadCatalogueRequest,
} from "../../../services/user/catalogueService";
import { CODES } from "../../../consts/codes";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { getUser, getUserStatus } from "../../userSlice";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  fetchCatalogueDownload,
  getCatalogueDownload,
  getCatalogueDownloadStatus,
  resetCatalogueDownloadState,
} from "../../../parts/catalogue/catalogueSlice";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import FileState from "../../../components/common/fileState";
import FilterBusinessGroupWB from "../../../components/common/Filters/businessGroup/filterWithoutButtons";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";

const DownloadCatalogue = () => {
  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "initialUploadDate",
      label: t("App.downloadCatalogue.downloadTable.startDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "finalUploadDate",
      label: t("App.downloadCatalogue.downloadTable.endDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "user",
      label: t("App.downloadCatalogue.downloadTable.user"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "company",
      label: t("App.catalogue.table.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "file",
      label: t("App.downloadCatalogue.downloadTable.downloadFile"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            label=""
            value={value}
            control={
              <a
                style={{
                  cursor: value.state === "FINALIZADO" ? "pointer" : "default",
                }}
                className={
                  value.state === "FINALIZADO"
                    ? "default-text__linkBlue__sm"
                    : "default-text__linkBlue__sm__grey"
                }
                onClick={(event) => {
                  if (value.state === "FINALIZADO") {
                    handleDownloadFile(value.fileName, value.pathFileName);
                  }
                }}
              >
                {value.fileName}
              </a>
            }
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
    {
      name: "state",
      label: t("App.catalogue.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FileState
            value={value}
            onChange={(event) => updateValue(event.target.value)}
          />
        ),
      },
    },
  ];

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <NoMatchImage />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <Skeleton />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  //Usuario
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  //Archivos de descarga
  const catalogueDownload = useSelector(getCatalogueDownload);
  const catalogueDownloadStatus = useSelector(getCatalogueDownloadStatus);

  //Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */

  //Campo de tabla
  const [data, setData] = useState([]);
  const [noDownloadCatalogue, setNoDownloadCatalogue] = useState(false);

  //Modal respuesta de descarga
  const [downloadTitle, setDownloadTitle] = useState("");
  const [downloadResponseData, setDownloadResponseData] = useState({});
  const [isOpenDownloadedFile, setIsOpenDownloadedFile] = useState(false);

  //Carga visual
  const [isLoadingDownloadRequest, setIsLoadingDownloadRequest] =
    useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Grupo empresarial
  const [showDownload, setShowDownload] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  /**
   * useEffect para manejar la actualización de la información de la tabla
   */
  useEffect(() => {
    try {
      if (userInfo) {
        handleUpdateTable();
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }, [userStatus, dispatch, catalogueDownloadStatus]);

  /**
   * Use Effect para mostrar por default el componente de carga si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [isBusinessGroup]);

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * Función para estructurar la información a mostrar en la tabla
   *
   * @param {Object} catalogue celda de catálogo
   * @returns la información estructurada para mostrar en la tabla
   */
  const cellStructure = (catalogue) => {
    return {
      initialUploadDate: !catalogue.starDate
        ? " "
        : moment(catalogue.starDate).format("YYYY-MM-DD hh:mm"),
      finalUploadDate: !catalogue.endDate
        ? "  "
        : moment(catalogue.endDate).format("YYYY-MM-DD hh:mm"),
      user: !catalogue.userLoad ? "" : catalogue.userLoad,
      company: !catalogue.company ? "" : catalogue.company,
      file: !catalogue.fileName
        ? ""
        : {
            fileName: catalogue.fileName,
            pathFileName: catalogue.pathFileName,
            state: !catalogue.state ? "" : catalogue.state,
          },
      state: !catalogue.state ? "" : catalogue.state,
    };
  };

  /**
   *
   * Función para descargar el archivo de catálogo
   *
   * @param {String} fileName nombre del archivo
   * @param {String} path ubicación del archivo
   */
  const handleDownloadFile = async (fileName, path) => {
    const obj = {
      pathFileName: path,
      fileName: fileName,
    };
    try {
      const fileService = await DownloadCatalogueOnDownload(obj);

      if (fileService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (fileService.data.responseCode === CODES.COD_RESPONSE_HTTP_OK) {
          fileDownload(
            decode(fileService.data.data.fileContent),
            fileService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para actualizar la información de la tabla
   */
  const handleUpdateTable = async () => {
    try {
      switch (catalogueDownloadStatus) {
        case "fetch":
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 9,
          };
          dispatch(fetchCatalogueDownload(obj));
          break;
        case "succeeded":
          let newData = [];
          const records = catalogueDownload;

          if (records.length > 0) {
            const finalData = records.map((catalog) => {
              return cellStructure(catalog);
            });
            newData = finalData;
          }

          setNoDownloadCatalogue(newData.length > 0 ? false : true);
          setData(newData);
          setLoading(false);
          setIsLoadingRefresh(false);
          break;
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para refrescar la información de la tabla
   */
  const handleRefresh = () => {
    setLoading(true);
    setIsLoadingRefresh(true);
    setData([]);
    dispatch(resetCatalogueDownloadState());
  };

  /**
   *
   * Función para colocar el título correspondiente del modal basado en el responseCode
   *
   * @param {Object} response código de respuesta de la petición
   */
  const handleDisplayModal = (response) => {
    const { responseCode } = response.data;
    let title = "";

    switch (responseCode) {
      case 1005:
        title = t("App.downloadCatalogue.downloadModal.noRegistry");
        break;

      case 1006:
        title = t("App.downloadCatalogue.downloadModal.errorDownload");
        break;
      case 0:
        title = t("App.downloadCatalogue.downloadModal.initDownload");
        break;
    }

    setDownloadTitle(title);
    setDownloadResponseData(response);
    setIsOpenDownloadedFile(true);
  };

  /**
   * Función para generar una petición de la descarga del catálogo
   */
  const handleDownloadCatalogue = async () => {
    try {
      setIsLoadingDownloadRequest(true);

      const cleanSelectAll = selectedCompanies.filter(
        (company) => company.label !== t("App.downloadSalesForce.selectAll")
      );

      const obj = {
        country: userInfo.company.country,
        eanProvider: userInfo.company.eanCompany,
        provider: userInfo.company.companyName,
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        emailUser: userInfo.email,
        idUser: `${userInfo.firstName.substring(
          0,
          3
        )}${userInfo.lastName.substring(0, 3)}`,
        lstCompanies: cleanSelectAll,
      };

      const requestDownload = await GenerateDownloadCatalogueRequest(obj);

      if (
        requestDownload.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestDownload.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        dispatch(resetCatalogueDownloadState());
      }

      handleDisplayModal(requestDownload);
      setIsLoadingDownloadRequest(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      setIsLoadingDownloadRequest(false);
    }
  };

  return (
    <>
      {isBusinessGroup && (
        <FilterBusinessGroupWB
          title={t("App.manageBusinessGroup.filter.title3")}
          setShowDownload={setShowDownload}
          setSelectedCompanies={setSelectedCompanies}
        />
      )}

      <br />
      <br />

      <Grid container direction="column" rowSpacing={2}>
        {showDownload && (
          <Grid item>
            <DownloadBox
              isLoading={isLoadingDownloadRequest}
              handleDownload={handleDownloadCatalogue}
              description={t(
                "App.downloadCatalogue.downloadBox.boxDescription"
              )}
              buttonText={t("App.downloadCatalogue.downloadBox.boxButton")}
            />
          </Grid>
        )}

        <Grid
          container
          direction={isDesktop ? "row" : "column-reverse"}
          className="col-between download-middle"
        >
          {isDesktop ? (
            <>
              <Grid item className="download-middle__container">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item className="col-flex">
                    <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
                  </Grid>

                  <Grid
                    item
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <p className="display-small__primary-one">
                      {t("App.downloadSalesForce.subtitle")}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="col-end" sx={{ width: "58%" }}>
                {!noDownloadCatalogue && (
                  <Grid item className="download-middle__descrip">
                    {" "}
                    <p className="download-explanation__catalogue">
                      {t("App.downloadCatalogue.updateBox.description")}
                    </p>
                  </Grid>
                )}
                {!noDownloadCatalogue && (
                  <Grid item className="col-end">
                    <Grid container className="col-end">
                      {" "}
                      <Button
                        disableRipple
                        disabled={isLoadingRefresh ? true : false}
                        startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                        onClick={handleRefresh}
                        className="btn__outlined__blue__new download-middle__button"
                      >
                        {!isLoadingRefresh ? (
                          t("App.downloadCatalogue.updateBox.button")
                        ) : (
                          <CircularProgress size={21} color="inherit" />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item className="col-flex">
                {!noDownloadCatalogue && (
                  <Grid item className="col-end download-middle__descrip">
                    {" "}
                    <p className="download-explanation">
                      {t("App.downloadCatalogue.updateBox.description")}
                    </p>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <TableChartOutlinedIcon className="accordion__upload__tableIcon" />
                  </Grid>

                  <Grid
                    item
                    style={{
                      marginLeft: "1%",
                    }}
                  >
                    <p className="display-small__primary-one">
                      {t("App.downloadSalesForce.subtitle")}
                    </p>
                  </Grid>
                  <Grid></Grid>
                </Grid>
              </Grid>
              <Grid>
                {!noDownloadCatalogue && (
                  <Grid item className="col-end">
                    <Grid container className="col-end">
                      {" "}
                      <Button
                        disableRipple
                        disabled={isLoadingRefresh ? true : false}
                        startIcon={!isLoadingRefresh ? <LoopIcon /> : <></>}
                        onClick={handleRefresh}
                        className="btn__outlined__blue__new download-middle__button"
                      >
                        {!isLoadingRefresh ? (
                          t("App.downloadCatalogue.updateBox.button")
                        ) : (
                          <CircularProgress size={21} color="inherit" />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>

        {!noDownloadCatalogue ? (
          <Grid item className="download-table">
            <>
              {loading ? (
                <MUIDataTable
                  style={{ width: "100% !important" }}
                  data={data}
                  columns={columns}
                  options={skeletonOptions}
                  className="dataTable__salesForce"
                />
              ) : (
                <MUIDataTable
                  style={{ width: "100% !important" }}
                  data={data}
                  columns={columns}
                  options={options}
                  className="dataTable__salesForce"
                />
              )}
            </>
          </Grid>
        ) : (
          <Grid container className="col-flex">
            <NoInfoComponent type="download" />
          </Grid>
        )}

        <ModalInfo
          title={downloadTitle}
          responseData={downloadResponseData}
          open={isOpenDownloadedFile}
          onClose={() => {
            setIsOpenDownloadedFile(false);
          }}
        />
      </Grid>
    </>
  );
};

export default DownloadCatalogue;

import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
export const ToolTipDayOfGeneration = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className="grid__container__boxShadow"
      style={{ backgroundColor: "white" }}
    >
      <Grid item>
        <p className="display-small__primary-one">
          {t("App.dynamicPeriodicReport.when.frequencySummary")}
        </p>
      </Grid>

      <Grid item>
        {(props.frequency === null ||
          props.frequency === "0" ||
          props.frequency === 0) && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.when.frequencyDay")}
          </p>
        )}
        {(props.frequency === "1" || props.frequency === 1) && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.when.frequencyWeek", {
              day:
                typeof props.generationdayDayList === "string"
                  ? JSON.parse(props.generationdayDayList).label
                  : props.generationdayDayList.label,
            })}
          </p>
        )}

        {(props.frequency === "2" || props.frequency === 2) && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.when.frequencyMonth", {
              month: props.generationdayDayList.value,
            })}
          </p>
        )}
        {(props.frequency === "3" || props.frequency === 3) && (
          <p className="label__text-default">
            {t("App.dynamicPeriodicReport.when.frequencyYear", {
              year: props.generationdayDayList.label,
            })}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

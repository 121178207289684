export const REGEXP = {
  ONLY_LETTERS: /^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]+$/g,
  PHONE_NUMBER:
    /^((\[1-9]{1,4}?)|(\([0-9]{2,3}\)?)|([0-9]{2,4})?)*?[0-9]{3,4}?[0-9]{3,4}$/,
  ONLY_NUMBERS: /^[0-9]+$/,
  LETTERS_COMMAS: /^[,A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
  ONE_UPPER_LETTER: /^(?=.*?[A-Z])/,
  ONE_LOWER_LETTER: /(?=.*?[a-z])/,
  ONE_NUMBER: /(?=.*?[0-9])/,
  ONE_SPECIAL_CHAR: /(?=.*?[#?!@$%^&*-])/,
  PERCENTAGE: /^[1-9][0-9]?$|^100$/,
  LETTERS_AND_NUMBERS: /^[a-zA-ZñÑ0-9\s]+$/,
  LETTER_NUMBERS_AND_EMPTY: /^[a-zA-ZñÑ0-9\s]*$/,
  NO_SPECIALS_CHARACTERS: /^[^<>"';°´`¨]+$/,
  // no permite caracteres especiales indicados ni texto sql indicado
  SPECIAL_QA: /^(?!.*[<>\\'";-])(?!.*OR '1' = '1').*$/,
  // no permite caracteres especiales que se le indican
  NO_SOME_SPECIAL_CHARACTERS: /^[^<>";°´`\\¨?¿¡=/%!$]+$/,
  SPECIAL_QA2:
    /^(?!.*[<>'´";])(?!.*\\x1a)(?!.*\\x00)(?!.*--)(?!.*OR '1' = '1')(?!.*OR ‘1’ = ‘1’).*$/,
  SPECIAL_QA3:
    /^(?!.*[<>'";])(?!.*\\x1a)(?!.*\\x00)(?!.*--)(?!.*OR '1' = '1')(?!.*OR ‘1’ = ‘1’).*$/,
  SPECIAL_QA4:
    /^(?!.*[<>'\\´";])(?!.*\\x1a)(?!.*\\x00)(?!.*--)(?!.*OR '1' = '1')(?!.*OR ‘1’ = ‘1’).*$/,
};

import axios from "axios";
import getToken from "../../config/getToken";
import { env } from "../../env";

export async function CreateTransmissionRegistry(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `transmissions/types`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function EditTransmissionRegistry(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    url: `transmissions/types`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function FilterTransmittedValue(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: `transmissions/types/administrations`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import {
  getCurrentMaster,
  setCurrentMaster,
} from "../../../parts/masters/mastersSlice";
import { useDispatch, useSelector } from "react-redux";
import { ModalChangeMaster } from "../../../components/common/Modals/modalChangeMaster";

export const SelectMasters = () => {
  const dispatch = useDispatch();
  const currentMaster = useSelector(getCurrentMaster);
  const [openModalChangeType, setOpenModalChangeType] = useState(null);
  const [desiredType, setDesiredType] = useState(null);

  const handleTypeChange = (event) => {
    const id = event.target.value;
    if (currentMaster !== "2") {
      setDesiredType(id);
      setOpenModalChangeType(true);
    } else {
      dispatch(setCurrentMaster({ current: id }));
    }
  };
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="grid__container__filled__rounded"
      spacing={1}
    >
      <ModalChangeMaster
        open={openModalChangeType}
        setOpen={setOpenModalChangeType}
        desiredType={desiredType}
      />
      <Grid item sm={6} m>
        <p className="subheading__text-black">{t("App.masters.choose")}</p>
      </Grid>
      <Grid item className="u-marginL" sm={4}>
        <Select
          className="select__border"
          onChange={handleTypeChange}
          value={currentMaster}
          id="type"
          name="type"
          labelId="type"
        >
          <MenuItem disabled value="2">
            <em> {t("App.masters.placeholder")}</em>
          </MenuItem>

          <MenuItem value="0">
            <em> {t("App.masters.budget")}</em>
          </MenuItem>
          <MenuItem value="1">
            <em> {t("App.masters.reference")}</em>
          </MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

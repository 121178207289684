import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { GetGeographiesList } from "../../../services/admin/geographies/geographiesService";
import {
  getCompaniesAdmin,
  GetFiltersList,
} from "../../../services/admin/adminServices";
import { CODES } from "../../../consts/codes";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import EditCompany from "../companyManager/editCompany";
import FileState from "../fileState";

export const FilterAdminCompanies = ({
  setData,
  setFilterApply,
  setIsLoadingData,
  setNoContent,
  setOpenModalError,
  setResponseDataError,
  filterApply,
  setNoFound,
}) => {
  /**
   * Empty filter apply
   */

  const empty = {
    country: "",
    companyName: "",
    companyId: "",
    eanCompany: "",
    status: "",
    companyType: "",
  };

  const empty2 = {
    country: "",
    companyName: "",
    companyId: "",
    eanCompany: "",
    status: "2",
    companyType: "2",
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use Form
   */

  const schema = yup.object().shape({
    country: yup
      .object()
      .shape({ countryName: yup.string(), country: yup.string() })
      .required(),
    companyName: yup.string(),
    companyId: yup.string(),
    eanCompany: yup.string(),
    status: yup.string(),
    companyType: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: empty2,
  });
  /**
   * Funcion par activar o desactivar el boton de filtrar
   */
  const handleFilterCompanyDisabled = () => {
    if (watch("country") && watch("companyType") !== "2") {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Use State
   */

  const [loading, setLoading] = useState(true);
  const [countryList, setCountryList] = useState([]);

  /**
   * Use Effect
   */

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted) {
          setLoading(true);
          const filtersService = await GetGeographiesList();
          if (filtersService.status === CODES.COD_RESPONSE_HTTP_OK) {
            if (
              filtersService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
            ) {
              const responseMessage = filtersService.data.responseMessage;
              setCountryList(responseMessage);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  /**
   * Handles
   */
  const handleFilters = async (data) => {
    setNoContent(false);
    setData([]);
    setIsLoadingData(true);
  
    const obj = {
      country: data.country.country,
      typeCompany: data.companyType,
      eanCompany: data.eanCompany ? data.eanCompany : "",
      companyIdentificationNumber: data.companyId ? data.companyId : "",
      companyName: data.companyName ? data.companyName : "",
      state: data.status !== "2" ? +data.status : "",
    };
    try {
      const companiesAdminRequest = await getCompaniesAdmin(obj);
  
      if (companiesAdminRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          companiesAdminRequest.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST ||
          companiesAdminRequest.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          //Se settean la data en la tabla
          const dataStructure = setDataStructure(
            companiesAdminRequest.data.responseMessage
          );
  
          setData(dataStructure);
          setNoFound(false); // Restablece noFound a false cuando se encuentran datos
  
          //Setteo el filter apply
          setFilterApply({
            ...data,
            status:
              data.status !== "2"
                ? data.status === "1"
                  ? t("App.adminCompanies.filters.active")
                  : t("App.adminCompanies.filters.inactive")
                : "",
            companyType:
              data.companyType === "C"
                ? t("App.adminCompanies.filters.merchant")
                : t("App.adminCompanies.filters.maker"),
          });
        } else if (
          companiesAdminRequest.data.responseCode === 
          CODES.COD_RESPONSE_ERROR_LOGIN
        ) {
          setFilterApply({
            ...data,
            status:
              data.status !== "2"
                ? data.status === "1"
                  ? t("App.adminCompanies.filters.active")
                  : t("App.adminCompanies.filters.inactive")
                : "",
            companyType:
              data.companyType === "C"
                ? t("App.adminCompanies.filters.merchant")
                : t("App.adminCompanies.filters.maker"),
          });
  
          setNoFound(true); 
          setData([]);
        }
      } else {
        setOpenModalError(true);
        setResponseDataError(companiesAdminRequest);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOpenModalError(true);
      setResponseDataError(error);
    } finally {
      setIsLoadingData(false); 
    }
  };

  const handleCleanFilters = () => {
    reset();
    setFilterApply(empty);
    setNoContent(true);
  };

  const setDataStructure = (data) => {
    const processedRows = data.map((comp) => {
      return {
        companyType: comp.typeCompany,
        eanCompany: comp.eanCompany,
        companyId:
          comp.companyIdentificationNumber +
          (comp.checkDigit ? "-" + comp.checkDigit : ""),
        companyName: comp.companyName,
        companyCode: comp.companyCode,
        city: comp.cityName,
        address: comp.adress ? comp.adress : "",
        contact: comp.contactName ? comp.contactName : "",
        phone: comp.phone ? comp.phone : "",
        status: <FileState value={comp.state === 1 ? "activo" : "inactivo"} />,
        edit: (
          <EditCompany
            currentCompany={comp}
            setData={setData}
            data={data}
            filterApply={filterApply}
            setIsLoadingData={setIsLoadingData}
            setNoContent={setNoContent}
          />
        ),
      };
    });

    return processedRows;
  };

  return (
    <Accordion defaultExpanded={true}>
      <Grid item className="">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <TuneOutlinedIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />
          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {" "}
            {t("App.listUsers.filter")}
          </p>
        </AccordionSummary>
      </Grid>

      <AccordionDetails>
        {" "}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {" "}
          <Grid
            item
            xs={12}
            md={8}
            sm={8}
            xl={9.5}
            lg={9}
            className="side-line full-width size-select__borders"
          >
            <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
              {" "}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
              >
                <Grid item xl={3.8} lg={3.5} dm={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.listUsers.country")}
                      </label>
                    </Grid>

                    <Grid item width="100%">
                      <Controller
                        name={"country"}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={countryList}
                            getOptionLabel={(option) => option.countryName}
                            noOptionsText={t("App.listUsers.noOptions")}
                            isOptionEqualToValue={(option, value) =>
                              option.country === value.country
                            }
                            className={`${
                              errors.country
                                ? "select__filters__errors"
                                : "select__filters"
                            } size-select`}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  placeholder={t(
                                    "App.adminCompanies.selectOption"
                                  )}
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loading ? (
                                          <CircularProgress
                                            sx={{ color: "#4DCAFA" }}
                                            size={15}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              );
                            }}
                            onChange={(event, values, reason) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={3.8} lg={3.5} dm={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminCompanies.filters.companyName")}
                      </label>
                    </Grid>

                    <Grid item width="100%">
                      <Controller
                        control={control}
                        name={"companyName"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={`${
                              errors.companyName
                                ? "select__filters__errors"
                                : "select__filters"
                            } size-select`}
                            placeholder={t(
                              "App.adminCompanies.filters.companyName"
                            )}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={3.8} lg={3.5} dm={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminCompanies.filters.companyId")}
                      </label>
                    </Grid>

                    <Grid item width="100%">
                      <Controller
                        control={control}
                        name={"companyId"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            className={`${
                              errors.companyId
                                ? "select__filters__errors"
                                : "select__filters"
                            } size-select`}
                            placeholder={t(
                              "App.adminCompanies.filters.companyId"
                            )}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
                style={{ marginTop: "3%" }}
              >
                <Grid item xl={3.8} lg={3.5} dm={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminCompanies.filters.eanCompany")}
                      </label>
                    </Grid>
                    <Grid item width="100%">
                      <Controller
                        control={control}
                        name={"eanCompany"}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            className={`${
                              errors.eanCompany
                                ? "select__filters__errors"
                                : "select__filters"
                            } size-select`}
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                fontSize: 12,
                              },
                            }}
                            placeholder={t(
                              "App.adminCompanies.filters.eanCompany"
                            )}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={3.8} lg={3.5} dm={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminCompanies.filters.state")}
                      </label>
                    </Grid>
                    <Grid item width="100%">
                      <Controller
                        defaultValue="2"
                        control={control}
                        name="status"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <Select
                            inputProps={{
                              style: {
                                margin: "-5px 0 0 0 !important",
                                fontSize: 12,
                                border: "1px solid !important",
                              },
                            }}
                            value={value}
                            labelId="labelStatus"
                            label={t("App.adminCompanies.filters.state")}
                            className={`${
                              errors.status
                                ? "select__filters__errors"
                                : "select__filters"
                            } size-select`}
                            onChange={onChange}
                          >
                            <MenuItem disabled value={"2"}>
                              <em>{t("App.createUser.selectOption")}</em>
                            </MenuItem>
                            <MenuItem value={"1"}>
                              {t("App.adminCompanies.filters.active")}
                            </MenuItem>
                            <MenuItem value={"0"}>
                              {t("App.adminCompanies.filters.inactive")}
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={3.8} lg={3.5} dm={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <label className="form__label label__text-grey">
                        {t("App.adminCompanies.filters.companyType")}
                      </label>
                    </Grid>
                    <Grid item width="100%">
                      <Controller
                        control={control}
                        name="companyType"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            labelId="labelCompanyType"
                            label={t("App.adminCompanies.filters.companyType")}
                            className={`${
                              errors.companyType
                                ? "select__filters__errors"
                                : "select__filters"
                            } size-select`}
                            onChange={onChange}
                            defaultValue="2"
                          >
                            <MenuItem disabled value={"2"}>
                              <em>{t("App.createUser.selectOption")}</em>
                            </MenuItem>
                            <MenuItem value={"C"}>
                              {t("App.adminCompanies.filters.merchant")}
                            </MenuItem>
                            <MenuItem value={"F"}>
                              {t("App.adminCompanies.filters.maker")}
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} xl={2.5} dm={3} lg={3} md={4} sm={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              className="confirmation-buttons"
            >
              <Grid item className="dimentions-btn__applyFilter__container">
                <Button
                  startIcon={
                    <TuneOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: "white",
                      }}
                    />
                  }
                  disableRipple
                  disabled={handleFilterCompanyDisabled()}
                  className={`${
                    handleFilterCompanyDisabled()
                      ? "btn__applyFilter-disabled"
                      : "btn__applyFilter"
                  } size-select dimentions-btn__applyFilter`}
                  type="submit"
                  form="hook-form"
                >
                  {t("App.listUsers.applyFilters")}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  className={`btn__deleteFilter size-select dimentions-btn__cleanFilter`}
                  onClick={handleCleanFilters}
                >
                  {t("App.listUsers.cleanFilters")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

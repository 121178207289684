import React, { useState } from "react";
import { ToolTipDayOfGeneration } from "../../../components/common/DynamicReport/toolTipDayOfGeneration";
import {
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller } from "react-hook-form";
import { ToolTipPeriodSummary } from "../../../components/common/DynamicReport/toolTipPeriodSummary";

const PeriodReport = ({
  t,
  control,
  errors,
  register,
  getValues,
  setValue,
  watch,
  setPeriodNumber,
  grouping,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  periodNumber,
}) => {
  const [generationdayDayList, setGenerationDayList] = useState({
    label: t("App.dynamicPeriodicReport.when.noDay"),
    value: "",
  });

  /**
   * Funcióm para actualizar el número de periodos
   * para el reporte
   * @param {*} e
   */
  const onChangePeriodNumber = (e) => {
    const newValue = e.target.value;

    setPeriodNumber(newValue);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={2}
    >
      <Grid xl={8.85} lg={8.6} md={8} sm={12} xs={12} item>
        <Grid
          container
          direction="row"
          width="100%"
          justifyContent="flex-start"
          gap={2}
        >
          <Grid item lg={12} md={12} sm={12} xs={12} width={"100%"}>
            <Grid container direction="column">
              <Grid item>
                <label className="form__label label__text-grey">
                  {t("App.dynamicPeriodicReport.period.typeOfGroup")}{" "}
                </label>
              </Grid>
              <Grid item>
                <Controller
                  name="grouping"
                  control={control}
                  defaultValue={grouping.label}
                  disabled={true}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          margin: "-5px 0 0 0",
                          fontSize: 12,
                          color: "rgb(161, 161, 161)",
                        },
                      }}
                      className={"select-contact__filters__disabled"}
                      value={value}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} width={"100%"}>
            <Grid container direction="row" width="100%">
              <Grid item width="100%">
                <Grid container direction="column">
                  <Grid item>
                    <label className="form__label label__text-grey">
                      {t("App.dynamicPeriodicReport.period.numberPeriods")}{" "}
                    </label>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" width="100%" gap={1.75}>
                      <Grid item lg={5.85} md={6} sm={11} xs={9.75}>
                        <Controller
                          name="numberOfPeriods"
                          control={control}
                          defaultValue="1"
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              inputProps={{
                                style: {
                                  margin: "-5px 0 0 0",
                                  fontSize: 12,
                                },
                              }}
                              type="number"
                              className={
                                errors.numberOfPeriods
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                onChangePeriodNumber(e);
                              }}
                            />
                          )}
                        />
                        {errors.numberOfPeriods && (
                          <FormHelperText
                            sx={{
                              margin: "0.5px 0 1px 0px",
                              color: "#e52900",
                              fontSize: 10,
                            }}
                          >
                            {errors.numberOfPeriods.message}
                          </FormHelperText>
                        )}

                        <FormHelperText
                          sx={{
                            margin: "3px 0 1px 0px",
                            color: "#a3a3a3",
                            fontSize: 11,
                          }}
                        >
                          {t(
                            "App.listDynamicPeriodicReport.cen.create.period.indicator",
                            {
                              day: periodNumber,
                            }
                          )}{" "}
                        </FormHelperText>
                      </Grid>
                      <Grid
                        item
                        xl={0.4}
                        lg={0.4}
                        md={0.5}
                        sm={0.7}
                        xs={1.5}
                        justifyContent={"center"}
                        alignItems={"center"}
                        paddingTop={0.7}
                      >
                        <Tooltip
                          title={
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              <Grid item textAlign={"left"} paddingLeft={1}>
                                <Typography
                                  align="left"
                                  sx={{ fontSize: "14px", fontWeight: "700" }}
                                >
                                  {t(
                                    "App.dynamicPeriodicReport.period.tooltip.title"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item textAlign={"left"} paddingLeft={5}>
                                <Typography
                                  align="left"
                                  sx={{ fontSize: "14px", fontWeight: "400" }}
                                >
                                  {t(
                                    "App.listDynamicPeriodicReport.cen.create.period.tooltip"
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              fontSize: "26px",
                              color: "#543AB4",
                              width: "100%",
                            }}
                          />
                        </Tooltip>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xl={3} lg={3.19} md={3.72} sm={12} xs={12} height={"100%"}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          height={"100%"}
        >
          <Grid item width="100%" height={"100%"}>
            <ToolTipPeriodSummary
              numberPeriods={watch}
              generationdayDayList={generationdayDayList}
              grouping={grouping.value}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              amountOfPeriods={periodNumber}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item width="100%">
        <Grid
          container
          direction="row"
          justifyContent={"left"}
          alignItems="center"
          className="tooltip__info"
        >
          <Grid
            item
            lg={0.35}
            md={0.7}
            sm={0.9}
            xs={1.2}
            height={"100%"}
            alignContent={"center"}
            textAlign={"center"}
            justifyContent={"start"}
            alignItems={"center"}
            paddingTop={0.8}
          >
            <InfoOutlinedIcon fontSize="large" />
          </Grid>

          <Grid
            item
            textAlign={"left"}
            lg={11.3}
            md={11.3}
            sm={11.1}
            xs={10.8}
            paddingTop={0.2}
          >
            <p>
              {" "}
              {t("App.listDynamicPeriodicReport.cen.create.period.info", {
                day: periodNumber,
              })}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeriodReport;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../../../pages/userSlice";
import { ListBusinessGroup } from "../../../../services/user/businessGroup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getIsBusinessGroupStatus,
  getLstCompanies,
} from "../../../../parts/businessGroup/businessGroupSlice";

const FilterBusinessGroupWB = ({
  title,
  setShowDownload,
  setSelectedCompanies,
}) => {
  /**
   * Const
   */
  //Checkbox
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * t for translation
   */
  const { t } = useTranslation();

  /**
   * Use Selector
   */
  //Grupo empresarial
  const businessGroupStatus = useSelector(getIsBusinessGroupStatus);
  const lstCompanies = useSelector(getLstCompanies);

  // Usuario
  const userInfo = useSelector(getUser);

  /**
   * Use State
   */
  //Campos de formulario
  const [companyList, setCompanyList] = useState([]);
  const [eanList, setEanList] = useState([]);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Límites para deshabilitar campos
  const [isCompanyLimit, setIsCompanyLimit] = useState(false);
  const [isEANLimit, setIsEANLimit] = useState(false);

  /**
   * useForm y resolver para schema
   */
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { company: [], ean: [] },
  });

  /**
   * Función que actualiza el estado de tamaño de la pantalla
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 890);
  };

  /**
   * useEffect para actualizar tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * useEffect para traer la información de grupo empresarial
   */

  useEffect(() => {
    try {
      switch (businessGroupStatus) {
        case "succeeded":
          setDataToFields();
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(
        "==============Error filterWithout.jsx fetchBusinessGroup ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  }, [businessGroupStatus, dispatch, userInfo]);

  /**
   * Opciones para autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Settea la información de las companías sobre los campos de compania y eanCompany
   */
  const setDataToFields = () => {
    const companyName = lstCompanies.map((company) => {
      return {
        label: company.renameCompany,
        value: company.eanCompany,
        eanCompany: company.eanCompany,
        nameCompany: company.nameCompany,
        country: company.country,
      };
    });

    const eanCompany = lstCompanies.map((company) => {
      return {
        label: company.eanCompany,
        value: company.eanCompany,
        eanCompany: company.eanCompany,
        nameCompany: company.nameCompany,
        country: company.country,
      };
    });

    //Agrego el selectAll
    const companyAllSelectList =
      companyName.length > 1
        ? [
            {
              label: t("App.downloadSalesForce.selectAll"),
              value: t("App.downloadSalesForce.selectAll"),
            },
            ...companyName,
          ]
        : companyName;

    const eanAllSelectList =
      eanCompany.length > 1
        ? [
            {
              label: t("App.downloadSalesForce.selectAll"),
              value: t("App.downloadSalesForce.selectAll"),
            },
            ...eanCompany,
          ]
        : eanCompany;

    setCompanyList(companyAllSelectList);
    setEanList(eanAllSelectList);
  };

  /**
   * Maneja las acciones cuando se selecciona la opción selectAll o cuando la selección tiene comportamiento similar a selectAll
   * @param {array} list lista de opciones seleccionadas
   * @param {*} reason tipo de selección: selectOption, removeOption
   * @param {*} detail opción que ha sido clickeada
   * @param {*} type tipo de arreglo: company, ean
   * @returns true si selectAll fue seleccionado o el arreglo tuvo comportamiento similar a selectAll, false si selectAll no fue seleccionado
   */
  const handleSelectAllSelection = (list, reason, detail, type) => {
    //Casos de select

    let includeSelectAll = false;

    if (reason === "clear") {
      setValue(type, []);
      setSelectedCompanies([]);
      switch (type) {
        case "company":
          setIsCompanyLimit(false);
          setShowDownload(false);
          setValue("ean", []);
          break;
        case "ean":
          setIsEANLimit(false);
          setShowDownload(false);
          setValue("company", []);
          break;
      }

      return true;
    }

    //Reviso si la opción cliqueada es selectAll y fue deseleccionada
    if (detail) {
      const option = detail.option;

      if (
        reason === "removeOption" &&
        option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue(type, []);
        setSelectedCompanies([]);
        switch (type) {
          case "company":
            setIsCompanyLimit(false);
            setShowDownload(false);
            break;
          case "ean":
            setIsEANLimit(false);
            setShowDownload(false);
            break;
        }

        return true;
      }
    }

    //Reviso si el select all fue seleccionado
    for (const element of list) {
      if (element.value === t("App.downloadSalesForce.selectAll")) {
        includeSelectAll = true;
      }
    }

    const originalList = type === "company" ? companyList : eanList;

    //Reviso si la opción select all fue seleccionada o
    //la lista de opciones seleccionadas fueron todas
    if (
      (includeSelectAll && reason === "selectOption") ||
      (!includeSelectAll && list.length === originalList.length - 1)
    ) {
      switch (type) {
        case "company":
          setValue(type, companyList);
          setValue("ean", []);
          setSelectedCompanies(companyList);
          setIsCompanyLimit(true);
          setShowDownload(true);
          break;
        case "ean":
          setValue(type, eanList);
          setValue("company", []);
          setSelectedCompanies(eanList);
          setIsEANLimit(true);
          setShowDownload(true);
          break;
        default:
          break;
      }

      return true;
    }

    //Si la opción no fue seleccionada retorno falso
    if (!includeSelectAll) {
      return false;
    }

    //Si la opción select All se encuentra seleccionada y se deseleccionó otra opción
    if (includeSelectAll && reason === "removeOption") {
      const withoutSelectAll = list.filter(
        (element) => element.value !== t("App.downloadSalesForce.selectAll")
      );
      setValue(type, withoutSelectAll);
      if (withoutSelectAll.length === 1) {
        if (type === "company") {
          checkCompanies(withoutSelectAll);
        } else {
          checkEANS(withoutSelectAll);
        }
      }
      setSelectedCompanies(withoutSelectAll);

      return true;
    }
  };

  /**
   * Maneja los cambios sobre el campo ean según selección de compañias
   * @param {array} values compañías seleccionadas
   */
  const checkCompanies = (values) => {
    const valuesLength = values.length;

    //Guardamos las empresas seleccionadas
    setSelectedCompanies(values);

    //Si se selecciona más de una se resetea campo eanCompanies
    //Se levanta límite que deshabilita eanCompanies
    if (valuesLength > 1) {
      setValue("ean", []);
      setIsCompanyLimit(true);
      setShowDownload(true);
    } else if (valuesLength === 1) {
      //Si se selecciona una se settea el ean de la empresa
      const eanCompany = values[0];
      setValue("ean", [{ label: eanCompany.value, value: eanCompany.value }]);
      setShowDownload(true);
      setIsCompanyLimit(false);
    } else {
      setValue("ean", []);
      setIsCompanyLimit(false);
      setShowDownload(false);
    }
  };

  /**
   * Maneja los cambios sobre campo company según selección de eans
   * @param {array} values eans seleccionados
   */
  const checkEANS = (values) => {
    const valuesLength = values.length;

    //Guardamos las empresas seleccionadas
    setSelectedCompanies(values);

    if (valuesLength > 1) {
      //Cuando se selecciona más de uno se deshabilitan los campos
      setValue("company", []);
      setIsEANLimit(true);
      setShowDownload(true);
    } else if (valuesLength === 1) {
      //Si se selecciona uno se coloca el bg y empresa en los otros campos
      const eanCompany = values[0];
      const findCompany = companyList.filter(
        (company) => eanCompany.value === company.value
      );
      setShowDownload(true);
      setValue("company", findCompany);
      setIsEANLimit(false);
    } else {
      //Si queda en blanco resetea los campos
      setValue("company", []);
      setShowDownload(false);
      setIsEANLimit(false);
    }
  };

  /**
   * Maneja las acciones a realizar cuando el campo company cambia
   * @param {array} values compañías seleccionadas
   * @param {string} reason tipo de selección: selectOption, removeOption
   * @param {object} detail opción seleccionada
   * @returns para romper el flujo de ejecución
   */
  const handleCompanyChange = (values, reason, detail) => {
    const selectAllHandle = handleSelectAllSelection(
      values,
      reason,
      detail,
      "company"
    );

    if (selectAllHandle) {
      return;
    } else {
      checkCompanies(values);
    }
  };

  /**
   * Maneja los cambios sobre el campo ean
   * @param {array} values eans seleccionados
   * @param {string} reason tipo de selección: selectOption, removeOption
   * @param {object} detail opción seleccionada
   * @returns si el caso selectAll aplica
   */
  const handleEanChange = (values, reason, detail) => {
    const selectAllHandle = handleSelectAllSelection(
      values,
      reason,
      detail,
      "ean"
    );

    if (selectAllHandle) {
      return;
    } else {
      checkEANS(values);
    }
  };

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      disableGutters
      style={{ borderRadius: "8px" }}
      className="accordion__filter__master"
    >
      <Grid item>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <SearchIcon
            sx={{
              fontSize: 20,
              color: "#543ab4",
            }}
          />

          <p className="navbar__text" style={{ paddingLeft: "1rem" }}>
            {title}
          </p>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container direction="row" alignItems="center">
            <Grid md={12} sm={12} xs={12} item>
              <form id="hook-form">
                <Grid
                  container
                  direction="row"
                  justifyContent={isDesktop ? "flex-start" : "center"}
                  alignItems="center"
                  columnGap={6}
                  rowSpacing={2}
                >
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.indicadors.company")}
                        </label>
                      </Grid>

                      <Grid item className="full-width">
                        <Controller
                          name="company"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={isEANLimit}
                              filterOptions={filterOptions}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.company
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              style={{ height: "auto" }}
                              options={companyList}
                              multiple
                              limitTag={1}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => {
                                return option.value === value.value;
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.value}>
                                  <Checkbox
                                    icon={icon}
                                    className="autoComplete__checkbox"
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.indicadors.company")}
                                    {...params}
                                  />
                                );
                              }}
                              renderTags={(value, getTagProps) => {
                                const numTags = value.length;
                                const limitTags = 1;
                                return (
                                  <>
                                    {value
                                      .slice(0, limitTags)
                                      .map((option, index) => (
                                        <Chip
                                          className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                          sx={{
                                            width: "55%",
                                          }}
                                          key={index}
                                          size="small"
                                          label={`${option.label}`}
                                        />
                                      ))}
                                    {numTags > limitTags &&
                                      ` +${numTags - limitTags}`}
                                  </>
                                );
                              }}
                              onChange={(event, values, reason, detail) => {
                                onChange(values);
                                handleCompanyChange(values, reason, detail);
                              }}
                              value={value}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <label className="form__label label__text-grey">
                          {t("App.listUsers.ean")}
                        </label>
                      </Grid>

                      <Grid item className="full-width">
                        <Controller
                          name="ean"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={isCompanyLimit}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.ean
                                  ? "select-contact__filters__errors"
                                  : "select-contact__filters"
                              }
                              style={{ height: "auto" }}
                              options={eanList}
                              multiple
                              limitTag={1}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => {
                                return option.value === value.value;
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.value}>
                                  <Checkbox
                                    icon={icon}
                                    className="autoComplete__checkbox"
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    placeholder={t("App.listUsers.ean")}
                                    {...params}
                                  />
                                );
                              }}
                              renderTags={(value, getTagProps) => {
                                const numTags = value.length;
                                const limitTags = 1;
                                return (
                                  <>
                                    {value
                                      .slice(0, limitTags)
                                      .map((option, index) => (
                                        <Chip
                                          className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                          sx={{
                                            width: "50%",
                                          }}
                                          key={index}
                                          size="small"
                                          label={`${option.label}`}
                                        />
                                      ))}
                                    {numTags > limitTags &&
                                      ` +${numTags - limitTags}`}
                                  </>
                                );
                              }}
                              onChange={(event, values, reason, detail) => {
                                onChange(values);
                                handleEanChange(values, reason, detail);
                              }}
                              value={value}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Grid>
    </Accordion>
  );
};

export default FilterBusinessGroupWB;

import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ScopeComponent } from "./scopeComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getTraders,
  fetchTraders,
  getEanPointSale,
  fetchEanPointSale,
  setSelectedTrader,
  getTradersSelected,
  getEanPointSaleSelected,
  setSelectedEanPointSale,
  fetchPointSales,
  getPointSalesSelected,
  getPointSales,
  setSelectedPointSale,
  fetchFormats,
  getFormats,
  getFormatsSelected,
  setSelectedFormats,
  fetchDepartments,
  getDepartments,
  getDepartmentsSelected,
  setSelectedDepartments,
  fetchChannels,
  getChannels,
  getChannelsSelected,
  setSelectedChannel,
  fetchCities,
  getCities,
  getCitiesSelected,
  setSelectedCities,
  fetchCategories,
  getCategories,
  getCategoriesSelected,
  setSelectedCategories,
  fetchProducts,
  getProducts,
  getProductsSelected,
  setSelectedProducts,
  fetchSalesForce,
  getSalesForce,
  getSalesForceSelected,
  setSelectedSalesForceLevelOne,
  fetchBrands,
  getBrands,
  getBrandsSelected,
  setSelectedBrands,
  fetchColors,
  getColors,
  getColorsSelected,
  setSelectedColors,
  fetchSizes,
  getSizes,
  getSizesSelected,
  setSelectedSizes,
  fetchSalesMan,
  getSalesMan,
  getSalesManSelected,
  setSelectedSalesMan,
  getTradersLoadStatus,
  getEanPointSaleLoadStatus,
  getPointSalesLoadStatus,
  getFormatsLoadStatus,
  getDepartmentsStatus,
  getCitiesStatus,
  getChannelsStatus,
  getCategoriesStatus,
  getProductsStatus,
  getSalesForceStatus,
  getBrandsStatus,
  getColorsStatus,
  getSizesStatus,
  getSalesManStatus,
  resetTradersLoadState,
  resetEanPointSaleLoadState,
  resetPointSalesLoadState,
  resetFormatsLoadState,
  resetDepartmentsLoadState,
  resetCitiesLoadState,
  resetChannelsLoadState,
  resetCategoriesLoadState,
  resetProductsLoadState,
  resetSalesForceLevelOneLoadState,
  resetBrandsLoadState,
  resetColorsSaleLoadState,
  resetSizesLoadState,
  resetSalesManStatusState,
} from "../../../parts/dynamicReport/dynamicReportSlice";
import { getUser } from "../../../pages/userSlice";

export const ScopeList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //Selectores Redux

  const userInfo = useSelector(getUser);

  //Redux traders

  const traders = useSelector(getTraders);

  const tradersSelected = useSelector(getTradersSelected);

  const tradersStatus = useSelector(getTradersLoadStatus);
  //Redux eanPointsSale

  const eanPointSale = useSelector(getEanPointSale);

  const eanPointSaleSelected = useSelector(getEanPointSaleSelected);

  const eanPointSaleStatus = useSelector(getEanPointSaleLoadStatus);

  //Redux pointsSales

  const pointSales = useSelector(getPointSales);

  const pointSalesSelected = useSelector(getPointSalesSelected);

  const pointSalesStatus = useSelector(getPointSalesLoadStatus);

  //Redux Formats

  const formats = useSelector(getFormats);

  const formatsSelected = useSelector(getFormatsSelected);

  const formatsStatus = useSelector(getFormatsLoadStatus);

  //Redux Departments

  const departments = useSelector(getDepartments);

  const departmentsSelected = useSelector(getDepartmentsSelected);

  const departmentsStatus = useSelector(getDepartmentsStatus);

  //Redux Cities

  const cities = useSelector(getCities);

  const citiesSelected = useSelector(getCitiesSelected);

  const citiesStatus = useSelector(getCitiesStatus);

  //Redux Channels

  const channels = useSelector(getChannels);

  const channelsSelected = useSelector(getChannelsSelected);

  const channelsStatus = useSelector(getChannelsStatus);

  //Redux Categories

  const categories = useSelector(getCategories);

  const categoriesSelected = useSelector(getCategoriesSelected);

  const categoriesStatus = useSelector(getCategoriesStatus);

  //Redux Products

  const products = useSelector(getProducts);

  const productsSelected = useSelector(getProductsSelected);

  const productsStatus = useSelector(getProductsStatus);

  //Redux SalesForce

  const salesForce = useSelector(getSalesForce);

  const salesForceSelected = useSelector(getSalesForceSelected);

  const salesForceStatus = useSelector(getSalesForceStatus);

  //Redux Brands

  const brands = useSelector(getBrands);

  const brandsSelected = useSelector(getBrandsSelected);

  const brandsStatus = useSelector(getBrandsStatus);

  //Redux Colors

  const colors = useSelector(getColors);

  const colorsSelected = useSelector(getColorsSelected);

  const colorsStatus = useSelector(getColorsStatus);

  //Redux Sizes

  const sizes = useSelector(getSizes);

  const sizesSelected = useSelector(getSizesSelected);

  const sizesStatus = useSelector(getSizesStatus);

  //Redux Salesman

  const salesMan = useSelector(getSalesMan);

  const salesManSelected = useSelector(getSalesManSelected);

  const salesManStatus = useSelector(getSalesManStatus);

  //Selectores Selected Redux

  //estados

  const checkList = [
    {
      label: t("App.dynamicPeriodicReport.scope.merchant"),
      register: "merchant",

      default: true,
      list: traders,
      setSelected: setSelectedTrader,
      selected: tradersSelected,
      loading: tradersStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.ean"),
      register: "EanPointOfSale",
      default: true,
      list: eanPointSale,
      setSelected: setSelectedEanPointSale,
      selected: eanPointSaleSelected,
      loading: eanPointSaleStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.point"),
      register: "pointOfSale",
      default: true,
      list: pointSales,
      setSelected: setSelectedPointSale,
      selected: pointSalesSelected,
      loading: pointSalesStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.format"),
      register: "formats",
      default: true,
      list: formats,
      setSelected: setSelectedFormats,
      selected: formatsSelected,
      loading: formatsStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.department"),
      register: "department",
      default: false,
      list: departments,
      setSelected: setSelectedDepartments,
      selected: departmentsSelected,
      loading: departmentsStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.city"),
      register: "city",
      default: false,
      list: cities,
      setSelected: setSelectedCities,
      selected: citiesSelected,
      loading: citiesStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.channel"),
      register: "channel",
      default: false,
      list: channels,
      setSelected: setSelectedChannel,
      selected: channelsSelected,
      loading: channelsStatus,
    },
  ];

  const checkList2 = [
    {
      label: t("App.dynamicPeriodicReport.scope.categories"),
      register: "categories",
      default: false,
      list: categories,
      setSelected: setSelectedCategories,
      selected: categoriesSelected,
      loading: categoriesStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.hierarchy"),
      register: "hierarchy",
      default: false,
      list: salesForce,
      setSelected: setSelectedSalesForceLevelOne,
      selected: salesForceSelected,
      loading: salesForceStatus,
    },

    {
      label: t("App.dynamicPeriodicReport.scope.brand"),
      register: "brand",
      default: false,
      list: brands,
      setSelected: setSelectedBrands,
      selected: brandsSelected,
      loading: brandsStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.products"),
      register: "products",
      default: false,
      list: products,
      setSelected: setSelectedProducts,
      selected: productsSelected,
      loading: productsStatus,
    },

    {
      label: t("App.dynamicPeriodicReport.scope.sellers"),
      register: "sellers",
      default: true,
      list: salesMan,
      setSelected: setSelectedSalesMan,
      selected: salesManSelected,
      loading: salesManStatus,
    },

    {
      label: t("App.dynamicPeriodicReport.scope.colour"),
      register: "colour",
      default: false,
      list: colors,
      setSelected: setSelectedColors,
      selected: colorsSelected,
      loading: colorsStatus,
    },
    {
      label: t("App.dynamicPeriodicReport.scope.size"),
      register: "size",
      default: false,
      list: sizes,
      setSelected: setSelectedSizes,
      selected: sizesSelected,
      loading: sizesStatus,
    },
  ];

  // Redux

  //UseEffect Traders
  useEffect(() => {
    if (userInfo) {
      const getTradersService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstChannels: channelsSelected,
            lstEanProducts: getEan(productsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetTradersLoadState());
          dispatch(fetchTraders(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getTradersService();
    }
  }, [
    dispatch,
    userInfo,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
    productsSelected,
  ]);

  //UseEffect Ean Points
  useEffect(() => {
    if (userInfo) {
      const getEanPointSaleService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstEanProducts: getEan(productsSelected),
            lstChannels: channelsSelected,

            lstLevelOneCategories: categoriesSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetEanPointSaleLoadState());
          dispatch(fetchEanPointSale(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getEanPointSaleService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    productsSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect point sales
  useEffect(() => {
    if (userInfo) {
      const getPointSalesService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstFormats: formatsSelected,
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstChannels: channelsSelected,
            lstEanProducts: getEan(productsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetPointSalesLoadState());
          dispatch(fetchPointSales(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getPointSalesService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    productsSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect fORMATS
  useEffect(() => {
    if (userInfo) {
      const getFormatsService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstChannels: channelsSelected,
            lstEanProducts: getEan(productsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetFormatsLoadState());
          dispatch(fetchFormats(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getFormatsService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    productsSelected,
    brandsSelected,
    salesForceSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
    categoriesSelected,
  ]);

  //UseEffect departments
  useEffect(() => {
    if (userInfo) {
      const getDepartmentsService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstChannels: channelsSelected,
            lstEanProducts: getEan(productsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetDepartmentsLoadState());
          dispatch(fetchDepartments(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getDepartmentsService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    productsSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect channels
  useEffect(() => {
    if (userInfo) {
      const getChannelsService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,

            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstEanProducts: getEan(productsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetChannelsLoadState());
          dispatch(fetchChannels(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getChannelsService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    citiesSelected,
    categoriesSelected,
    productsSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect cities
  useEffect(() => {
    if (userInfo) {
      const getCitiesService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstEanProducts: getEan(productsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetCitiesLoadState());
          dispatch(fetchCities(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getCitiesService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    categoriesSelected,
    productsSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect categories
  useEffect(() => {
    if (userInfo) {
      const getCategoriesService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstEanProducts: getEan(productsSelected),
            lstChannels: channelsSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetCategoriesLoadState());
          dispatch(fetchCategories(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getCategoriesService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    productsSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect products
  useEffect(() => {
    if (userInfo) {
      const getProductsService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetProductsLoadState());
          dispatch(fetchProducts(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getProductsService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    salesForceSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect salesforce
  useEffect(() => {
    if (userInfo) {
      const getSalesForceService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstEanProducts: getEan(productsSelected),
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetSalesForceLevelOneLoadState());
          dispatch(fetchSalesForce(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getSalesForceService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    productsSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect brands
  useEffect(() => {
    if (userInfo) {
      const getBrandsService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstColors: colorsSelected,
            lstSizes: sizesSelected,
            lstEanProducts: getEan(productsSelected),
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetBrandsLoadState());
          dispatch(fetchBrands(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getBrandsService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    salesForceSelected,
    productsSelected,
    colorsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect colors
  useEffect(() => {
    if (userInfo) {
      const getColorsService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstBrands: brandsSelected,
            lstSizes: sizesSelected,
            lstEanProducts: getEan(productsSelected),
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetColorsSaleLoadState());
          dispatch(fetchColors(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getColorsService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    salesForceSelected,
    productsSelected,
    brandsSelected,
    sizesSelected,
    salesManSelected,
  ]);

  //UseEffect sizes
  useEffect(() => {
    if (userInfo) {
      const getSizesService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstEanProducts: getEan(productsSelected),
            lstSalesManCode: getEan(salesManSelected),
          };
          dispatch(resetSizesLoadState());
          dispatch(fetchSizes(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getSizesService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    salesForceSelected,
    productsSelected,
    brandsSelected,
    colorsSelected,
    salesManSelected,
  ]);

  //UseEffect salesman
  useEffect(() => {
    if (userInfo) {
      const getSalesManService = async () => {
        try {
          const obj = {
            eanProvider: userInfo.company.eanCompany,
            country: userInfo.company.country ? userInfo.company.country : "CO",
            lstEansTraders: getEan(tradersSelected),
            lstEansPointSales: eanPointSaleSelected,
            lstPointSales: getEan(pointSalesSelected),
            lstFormats: formatsSelected,
            lstGeographiesLevelFour: getEan(citiesSelected),
            lstGeographiesLevelOne: getEan(departmentsSelected),
            lstLevelOneCategories: categoriesSelected,
            lstChannels: channelsSelected,
            lstLevelOneSalesForce: salesForceSelected,
            lstBrands: brandsSelected,
            lstColors: colorsSelected,
            lstEanProducts: getEan(productsSelected),
            lstSizes: sizesSelected,
          };
          dispatch(resetSalesManStatusState());
          dispatch(fetchSalesMan(obj));
        } catch (error) {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        }
      };

      getSalesManService();
    }
  }, [
    dispatch,
    userInfo,
    tradersSelected,
    eanPointSaleSelected,
    pointSalesSelected,
    formatsSelected,
    departmentsSelected,
    channelsSelected,
    citiesSelected,
    categoriesSelected,
    salesForceSelected,
    productsSelected,
    brandsSelected,
    colorsSelected,
    sizesSelected,
  ]);

  const getEan = (list) => {
    return list.map((item) => {
      return item.ean;
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {checkList.map((item, idx) => {
          return (
            <Grid item key={idx} xs={12} sm={4}>
              <ScopeComponent
                data={item.list}
                item={item}
                control={props.control}
                setValue={props.setValue}
                getValues={props.getValues}
                setSelected={item.setSelected}
                selected={item.selected}
                loading={item.loading}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={4}>
          <p className="display-small__primary-one">
            {t("App.dynamicPeriodicReport.scope.label1")}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p className="display-small__primary-one">
            {t("App.dynamicPeriodicReport.scope.label2")}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p className="display-small__primary-one">
            {t("App.dynamicPeriodicReport.scope.label3")}
          </p>
        </Grid>
        {checkList2.map((item, idx) => {
          return (
            <Grid key={idx} item xs={12} sm={4}>
              <ScopeComponent
                data={item.list}
                item={item}
                control={props.control}
                setValue={props.setValue}
                getValues={props.getValues}
                setSelected={item.setSelected}
                selected={item.selected}
                loading={item.loading}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
